

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';


const LoggedinLayout = ({currentUser}) => {
  const navigate = useNavigate("/");

  const logoutClicked = async () => {
    await store.dispatch(logoutUser());
    navigate("/");
  }



  return (


    <div className="parent">
      <div className="content-cont flex-cont">
        
        <div className="side-nav">
          <ul>
            <li><Link to="" >Account Home</Link></li>
            <li><Link to="email-confirmation" >Email Confirmation</Link></li>
          </ul>



          <div className="margin-top-3 center">
            <div onClick={() => navigate(`/profile`)} className="ghost button">View Profile</div>

            <div onClick={logoutClicked} className="margin-top-1 ghost button">Logout</div>
          </div>
          
        </div>

        <div className="narrow-2 content margin margin-top-1">
          <Outlet />
        </div>
      </div>
    </div>
  )
}


function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(LoggedinLayout);