import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getAthlete } from 'redux/club/athleteActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubAthleteNav from './ClubAthleteNav';
import { Link } from 'react-router-dom';
import AthleteForm from './AthleteForm';
import { connect } from 'react-redux';

const ClubAthleteLayout = ({currentClub, children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchAthlete()
    }
  }, [id]);

  const [athlete, athleteLoading, athleteLoaded, athleteError, doFetchAthlete, setAthleteResults] = useApi();

  const fetchAthlete = () => {
    doFetchAthlete(getAthlete, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editAthleteClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchAthlete();
    }
  } 
  
  

  return (
    <>
      {athlete && editModalOpened &&
        <AthleteForm toggleModal={toggleEditModal} currentAthlete={athlete} />
      }
      <div className="narrow-2x padding-top-1">
        <div className="flex-cont align-center">
          {athlete?.avatar_url &&
            <div className="athlete-logo-cont small margin-right-2">
              <img src={athlete?.avatar_url} alt="" />
            </div>
          }
          <div>
            <Link to={`/students`} className="text-link">
              <i className="margin-right-half fas fa-angle-left"></i>
              All Students
            </Link>
            <h1>
              {athlete?.full_name}
            </h1>
            
          </div>

          <div className="flex-1"></div>


          {currentClub?.current_user_role === "admin" || currentClub?.current_user_role === "club-admin" &&
            <div>
              <i onClick={editAthleteClicked} className="ghost button fas fa-pencil-alt"></i>
            </div>
          }
        </div>
        
        <ClubAthleteNav id={id} />

        {children}


      </div>
    </>
  )

}



function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(ClubAthleteLayout);