import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubCoinItemNav from './ClubCoinItemNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getCoinItem, changeAthlete } from 'redux/club/coinItemActions';
import coinPNG from 'images/herocoin.png';
import CoinItemPurchaseForm from 'screens/club/coinItemPurchases/CoinItemPurchaseForm';



const ClubCoinItem = ({coinItem}) => {

  const { id } = useParams();

  // useEffect(() => {
  //   if (id) {
  //     fetchCoinItem()
  //   }
  // }, [id]);

  // const [coinItem, coinItemLoading, coinItemLoaded, coinItemError, doFetchCoinItem, setCoinItemResults] = useApi();

  // const fetchCoinItem = () => {
  //   doFetchCoinItem(getCoinItem, {query: {id: id}})
  // }


  // const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  // const changeAthleteClicked = () => {
  //   setAthleteModalOpened(true);
  // }
  // const toggleAthleteModal = (reload=false) => {

  //   setAthleteModalOpened(o => !o);
  //   if (reload) {
  //     fetchCoinItem();
  //   }
  // }


  const [newPurchaseModalOpened, setNewPurchaseModalOpened] = useState(false);

  const toggleNewPurchaseModal = () => {
    setNewPurchaseModalOpened(o => !o);
  }
 
  return (
    <>

      {newPurchaseModalOpened &&
        <Modal focusTrap={false} toggleModal={toggleNewPurchaseModal}>
          <CoinItemPurchaseForm coinItem={coinItem} toggleModal={toggleNewPurchaseModal} />
        </Modal>
      }

      {coinItem &&
        <>  
          <table className="vertical">

            <tr>
              <th>Price</th>
              <td>
                {coinItem.price ? 
                  <>
                    <div className="flex-cont align-center">
                      <img className="margin-right-half" style={{height: '1em'}} src={coinPNG} alt="" />
                      <div className='gold bold'>{coinItem.price}</div>
                    </div>
                  </>
                  :
                  "-"
                }
                
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                {coinItem.description}
              </td>
            </tr>
            <tr>
              <th>Inventory</th>
              <td>
                {coinItem.inventory}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                {coinItem.status?.toUpperCase()}
              </td>
            </tr>
          </table>
        </>
      }

      {coinItem &&

        <div onClick={() => setNewPurchaseModalOpened(true)} className="button  margin-top-1">
          <i className="fas fa-plus margin-right-half"></i>
          <span>New Purchase</span>
        </div>
      }

      

      
      {/* <div className="code">
        <pre>
          {JSON.stringify(coinItem, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubCoinItem;