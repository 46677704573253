

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAthletes } from 'redux/admin/athleteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';

import { createAthlete, updateAthlete, deleteAthlete, getAthlete } from 'redux/admin/athleteActions';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";

const AthleteSchema = yup.object().shape({
  // name: yup.string().required("Name is required."),
  epc: yup.string().required("EPC is required."),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  birthday: yup.date().nullable().required("Birthday is required.").typeError("Invalid birthday."),
  gender: yup.string().oneOf(['male', 'female']).required("Gender is required."),
});

const AthleteForm = ({toggleModal, currentAthlete}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: AthleteSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      athlete: {
        ...data
      }
    }
    if (currentAthlete && currentAthlete.id) {
      doFetch(updateAthlete, {payload: d});
    }
    else {
      doFetch(createAthlete, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentAthlete ? 'Edit' : 'New'} Athlete</h2>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1 padding-bottom-200px">

        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentAthlete?.id}
        />

        <div className="flex-cont">
          <label htmlFor="first_name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="First Name"
              name="first_name"
              defaultValue={currentAthlete?.first_name}
            />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="last_name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Last Name"
              name="last_name"
              defaultValue={currentAthlete?.last_name}
            />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="birthday"> 
            <DayPickerInput 
              className="full" 
              inputProps={{placeholder: "Birthday YYYY-MM-DD", name: `birthday`, ref: register}} 
              type="text"  
            />
            {errors.birthday && <div className="field-error">{errors.birthday.message}</div>}
          </label>
          <label htmlFor="gender"> 
            <label>
              <input type="radio" ref={register} name={`gender`}  value="female" defaultChecked={true}  />
              <span>Female</span>
            </label>
            <br />
            <label>
              <input type="radio" ref={register} name={`gender`} value="male"  />
              <span>Male</span>
            </label>
            {errors.gender && <div className="field-error">{errors.gender.message}</div>}
          </label>
        </div>
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Tag EPC"
              name="epc"
              defaultValue={currentAthlete?.epc}
            />
            {errors.epc && <div className="field-error">{errors.epc.message}</div>}
          </label>
        </div>
      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminAthletes = ({currentUser}) => {

  let { id } = useParams();

  const [paramsId, setParamsId] = useState(id);


  const [athletes, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedAthlete, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedAthlete] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingAthlete, setEditingAthlete] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchAthletes();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingAthlete(null);
    }
  }, [modalOpen])

  useEffect(() => {

    fetchAthletes();
    
  }, [id]);

  const fetchAthletes = () => {
    doFetch(getAthletes, {query: {tab: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingAthlete, setDeletingAthlete] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchAthletes();
    }
  }


  const editClicked = athlete => {
    setEditingAthlete(athlete);
    toggleModal(false);
  }

  const deleteClicked = athlete => {
    setDeletingAthlete(athlete);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingAthlete) {
      doDeleteFetch(deleteAthlete, {query: {id: deletingAthlete.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  

  const changeTab = (tab) => {
    // setSearchParams({ q: tab });
  }

  
  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <AthleteForm currentAthlete={editingAthlete} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <h1 className="flex-1">
          Athletes
        </h1>
        <div>
          <div onClick={() => toggleModal(false)} className="button">New Athlete</div>
        </div>
        
      </div>
      

      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink to={`/athletes`}>All</NavLink></li>
          <li><NavLink to={`/athletes/needs-account`}>Needs Account</NavLink></li>
        </ul>
      </div>



{/* 
      <div className="padding-1 margin-bottom-2 flex-cont align-center profile-nav">
        <div className="nav-item margin-right-1">
          <Link to="/admin/athletes">All</Link>
        </div>
        <div className="nav-item">
          <Link to="/admin/athletes/needs-account">Needs Account</Link>
        </div>
      </div> */}

      

      <div className="margin-bottom-2"></div>



      {loading &&
        <div className="spinner"></div>
      }

      {athletes &&
        <table className="basic">
          {athletes.map(athlete => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div className="ghost button">
                    <Link to={`/athletes/view/${athlete.id}`}><i className="fas fa-eye"></i></Link>

                    
                  </div>
                </td>
                <td className="bold fit nowrap">{athlete.full_name}</td>
                <td>{athlete.epc ? athlete.epc : "-"}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(athlete)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(athlete)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Athlete</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingAthlete?.full_name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !athletes &&
        <em>No athletes.</em>
      }


      


    </>
  )
}


export const AdminAthlete = () => {

  const [athlete, loading, loaded, error, doFetch, setResult] = useApi(); 

  let { id } = useParams();

  useEffect(() => {
    fetchAthlete();
  }, [])

  const fetchAthlete = () => {
    doFetch(getAthlete, {query: {id: id}});
  }

  // const onSubmit = async data => {
  //   let d = {
  //     charity: {
  //       ...data
  //     }
  //   }
  // }

  return (
    <>
      {loading &&
        <div className="spinner"></div>
      }

      {athlete &&
        <>  
          <h1>{athlete.full_name}</h1>


          <h2>Pledge Drive Subscriptions</h2>

          <div className="margin-bottom-1"></div>

          
          {athlete.pledge_drive_subs &&
            <table className="basic">
              {athlete.pledge_drive_subs.map(sub => 
                <tr>
                  <td className="fit nowrap">{sub.club_name}</td>
                  <td className="fit nowrap">{sub.charity_name}</td>
                  <td className="fit nowrap">{sub.pledge_drive_name}</td>
                  <td className="">{sub.code}</td>
                </tr>
              )}
            </table>
          }

          {!athlete.pledge_drive_subs &&
            <em>No pledge drive subscriptions.</em>
          }


        </>

      }
    </>
  )
}


export default AdminAthletes;