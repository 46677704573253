  

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getCoinItemPurchases } from 'redux/club/coinItemPurchaseActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { Link, useParams } from 'react-router-dom';
import { createCoinItemPurchase, updateCoinItemPurchase, deleteCoinItemPurchase } from 'redux/club/coinItemPurchaseActions';
import CoinItemPurchaseForm from './CoinItemPurchaseForm';
// import ClubMerchProductsNav from '../coinItems/ClubMerchProductsNav';
import { DateTime } from 'luxon';


const ClubCoinItemPurchases = ({coinItem}) => {

  // const { id } = useParams();

  const [coinItemPurchases, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedCoinItemPurchase, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedCoinItemPurchase] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingCoinItemPurchase, setEditingCoinItemPurchase] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchCoinItemPurchases();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingCoinItemPurchase(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (true || !coinItemPurchases) {
      fetchCoinItemPurchases();
    }
  }, [coinItem]);

  const fetchCoinItemPurchases = () => {
    doFetch(getCoinItemPurchases, {query: {coin_item_id: coinItem?.id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingCoinItemPurchase, setDeletingCoinItemPurchase] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchCoinItemPurchases();
    }
  }


  const editClicked = coinItemPurchase => {
    setEditingCoinItemPurchase(coinItemPurchase);
    toggleModal();
  }

  const deleteClicked = coinItemPurchase => {
    setDeletingCoinItemPurchase(coinItemPurchase);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingCoinItemPurchase) {
      doDeleteFetch(deleteCoinItemPurchase, {query: {id: deletingCoinItemPurchase.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  

  const [newPurchaseModalOpened, setNewPurchaseModalOpened] = useState(false);

  const toggleNewPurchaseModal = (shouldReload=true) => {
    setNewPurchaseModalOpened(o => !o);

    if (shouldReload) {
      fetchCoinItemPurchases();
    }
  }
  
  
  return (
    <>
      <div className="padding-top-1">

      {newPurchaseModalOpened &&
        <Modal focusTrap={false} toggleModal={toggleNewPurchaseModal}>
          <CoinItemPurchaseForm coinItem={coinItem} toggleModal={toggleNewPurchaseModal} />
        </Modal>
      }

      {coinItem &&

        <div className="flex-cont align-center">
          <div onClick={() => setNewPurchaseModalOpened(true)} className="margin-right-1 button">
            <i className="fas fa-plus margin-right-half"></i>
            <span>New Purchase</span>
          </div>

          {loading &&
            <div className="spinner"></div>
          }

        </div>

        
      }
      



      {/* <ClubMerchProductsNav /> */}

      

      {coinItemPurchases &&
        <table className="basic margin-top-1">
          <tr>
            <th>Date</th>
            <th>Student</th>
            <th className='nowrap'>Coins Paid</th>
            <th>Staff</th>
            <th></th>

          </tr>
          {coinItemPurchases.map(coinItemPurchase => {
            const coinItem = coinItemPurchase.coin_item;

            return (
              <tr>
                <td className="fit nowrap">
                  {DateTime.fromISO(coinItemPurchase?.created_at)?.toLocaleString(DateTime.DATETIME_SHORT)}
                </td>
                <td  className="fit nowrap">
                  {coinItemPurchase?.athlete_name}
                </td>
                <td  className="center fit nowrap">
                  {coinItemPurchase?.price_paid}
                </td>
                <td  className="fit nowrap bold">
                  {coinItemPurchase?.added_by_name}
                </td>

                
                

                <td>
                  {/* <pre>
                    {JSON.stringify(coinItemPurchase, null, 2)}
                  </pre> */}
                </td>
              
              </tr>
            )
          })}
        </table>
      }


      {!loading && (!coinItemPurchases || coinItemPurchases.length === 0) &&
        <em>No results.</em>
      }


      

      </div>  
    </>
  )
}


export default ClubCoinItemPurchases;