import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getRace } from 'redux/club/raceActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubRaceNav from './ClubRaceNav';
import { DateTime } from 'luxon';
import RaceForm from './RaceForm';

const ClubRaceLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchRace()
    }
  }, [id]);

  const [race, raceLoading, raceLoaded, raceError, doFetchRace, setRaceResults] = useApi();

  const fetchRace = () => {
    doFetchRace(getRace, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editRaceClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchRace();
    }
  } 

  return (
    <>
      {race && editModalOpened &&
        <RaceForm toggleModal={toggleEditModal} currentRace={race} />
      }
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {race?.avatar_url &&
            <div className="team-logo-cont small margin-right-2">
              <img src={race?.avatar_url} alt="" />
            </div>
          }
          {raceLoading && <div className="loading"></div> }
          <div>
            <h1 className='no-margin-bottom'>
              {race?.race_type_human}
            </h1>
            <div className="bold">
              {race?.start_datetime_iso ? DateTime.fromISO(race.start_datetime_iso)?.toLocaleString({weekday: 'short', ...DateTime.DATETIME_MED}) : "-"}
            </div>

          </div>
          

          <div className="flex-1"></div>

          <div>
            <i onClick={editRaceClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubRaceNav id={id} />


        {children}
        {/* {React.cloneElement(children, {race: race})} */}

        {/* <div className="code">
          <pre>
            {JSON.stringify(race, null, 2)}
          </pre>
        </div> */}


      </div>
    </>
  )

}



export default ClubRaceLayout;