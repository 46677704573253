import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubMerchProductNav from './ClubMerchProductNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getMerchProduct, changeAthlete } from 'redux/club/merchProductActions';


const ClubMerchProduct = ({}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchMerchProduct()
    }
  }, [id]);

  const [merchProduct, merchProductLoading, merchProductLoaded, merchProductError, doFetchMerchProduct, setMerchProductResults] = useApi();

  const fetchMerchProduct = () => {
    doFetchMerchProduct(getMerchProduct, {query: {id: id}})
  }


  const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  const changeAthleteClicked = () => {
    setAthleteModalOpened(true);
  }
  const toggleAthleteModal = (reload=false) => {

    setAthleteModalOpened(o => !o);
    if (reload) {
      fetchMerchProduct();
    }
  }
 
  return (
    <>


      {merchProduct &&
        <>  
          <table className="vertical">

            <tr>
              <th>Price</th>
              <td>
                {merchProduct.price ? 
                  parseFloat(merchProduct.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                  :
                  "-"
                }
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                {merchProduct.description}
              </td>
            </tr>
            <tr>
              <th>Inventory</th>
              <td>
                {merchProduct.inventory_count}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                {merchProduct.status?.toUpperCase()}
              </td>
            </tr>
          </table>

          




        </>

      }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(merchProduct, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubMerchProduct;