

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RunMonitorBottomNav from './RunMonitorBottomNav';
import { getMyTeam } from '../../../redux/club/teamActions';
import { useApi } from '../../../api';
import { Outlet } from 'react-router-dom';

const RunMonitorLayout = ({currentTeam, currentClub, currentUser, children}) => {



  return (

    <>
      <RunMonitorBottomNav />

      {currentClub && currentUser &&
        <div className="parent">
          <div className="content-cont flex-cont">
            <div className="narrow-2 content margin">

              
              <Outlet />
            </div>

          </div>
        </div>
      }

    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(RunMonitorLayout);