import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { DateTime } from "luxon";
import { getMyCurrentRaceLogs } from 'redux/athlete/athleteActions';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { useOutletContext } from 'react-router-dom';
import SharingCard from './SharingCard';

const ProfileActivity = ({currentAthlete}) => {
  const {toggleShareModal} = useOutletContext();
  const {setSelectedAward} = useOutletContext();
  const {hasActiveCampaign} = useOutletContext();
  const {campaignLoading} = useOutletContext();

  const [raceLogs, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchMyRaceLogs();
  }, []);

  const fetchMyRaceLogs = () => {
    doFetch(getMyCurrentRaceLogs);
  };

  

  return (
    <>
      <h1 className="mobile-center">Activities</h1>


      <SharingCard campaignLoading={campaignLoading} hasActiveCampaign={hasActiveCampaign} toggleShareModal={toggleShareModal} />

      <div className="margin-bottom-2"></div>
      {loading &&
        <div className="mobile-center">
          <div className="spinner"></div>
        </div>
        
      }

      {loaded && !loading && (!raceLogs || raceLogs.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-stopwatch blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">Run your first race and see your results here!</div>
        </div>
      }
      

      {raceLogs && raceLogs.map( (log, logIndex) => {
        return(
          <div className="card no-hover margin-bottom-1">
            <div className="flex-cont margin-bottom-1">
              <div className="flex-1 mobile-center labelx bold uppercase font-08">
                {/* {log.created_at && DateTime.fromISO(log.created_at).toLocaleString(DateTime.DATETIME_MED)} */}
                {log?.start_datetime}
              </div>
            </div>

            <div className="flex-cont flex-wrap justify-center center overflow-scroll">
              <div className="flex-cont flex-wrapx center justify-center align-center margin-bottom-1 padding-right-1 margin-right-1 border-rightx">
                <div className="number-contx margin-bottom-1 margin-right-1 ">
                  <div className="font-1-25 bold-900">
                    {log.total_laps}
                  </div>
                  <div className="labelx gray bold font-08">
                    LAPS
                  </div>
                </div>
                
                <div className="margin-right-1 margin-bottom-1 number-contx">
                  <div className="font-1-25 bold-900">
                    {log.total_miles}
                  </div>
                  <div className="labelx gray bold font-08">
                    MILES
                  </div>
                </div> 

                <div className="number-contx margin-bottom-1">
                  <div className="font-1-25 bold-900">
                    {log.total_time}
                  </div>
                  <div className="labelx gray bold  font-08">
                    TIME
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <table className="margin-top-0">
                {log.laps && log.laps.map( (lap, index) => {
                  return(
                    <tr>
                      <td className="padding-right-05 gray font-075 bold">
                        {lap.best &&
                          <i className="fas fa-star"></i>
                        }
                      </td>
                      <td className="padding-right-1 gray font-075 bold">{index+1}</td>


                      <td className="padding-right-1">{lap.time}</td>

                      <td className="gray">{lap.miles} mi</td>

                      <td>
                        <div className="margin-left-1">
                          {lap?.award_group?.award_athletes?.map(award_athlete => {
                            return (
                              <div className='pointer hover-shadow' onClick={() => setSelectedAward(award_athlete)}>
                                <div className="relative">
                                  {award_athlete?.award?.badge &&
                                    <img src={award_athlete?.award?.badge?.image_url} style={{height: '16px'}} />
                                  }
                                  {!award_athlete?.award?.badge &&
                                    <i className="fas fa-certificate"></i>
                                  }
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        
                      </td>

                    </tr>

                    
                  )
                })}
              </table>

              </div>

            </div>
            

            
            {/* <pre>
              {JSON.stringify(log, null, 2)}
            </pre> */}
            
          </div>
        )
      })} 
    </>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
  };
} 

export default connect(mapState)(ProfileActivity);