


import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { NavLink, Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useApi } from '../../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { createPledger } from 'redux/user/pledgeActions';
import PledgeSidebar from './PledgeSidebar';

import { loadStripe } from "@stripe/stripe-js";
import { 
  Elements,
  CardElement,
  useStripe,
  useElements 
} from "@stripe/react-stripe-js";

const testIfNumber = (value) => {
  return !isNaN(value);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const stripePromise = loadStripe("pk_test_51H3POlG6MXyb29gzTQP735E2Jf7LcS6A4cwmxeYCnxSghwGPSut4AIgbyz5YOoLVW45jlcUoc7KbWw9RXOTDl4UJ00mfKkl5nE");


const PledgerSchema = yup.object().shape({
  pledger_name: yup.string().trim().required("Please enter your name."),
  pledger_email: yup.string().trim().email("Please enter a valid email.").required("Please enter your email."),
});

// const PLEDGE_CODE = process.env.NODE_ENV === "development" ? "ypOVkC-ALnFELSmzM4CXEg" : "https://mastersportsrunclub.herokuapp.com";

const Checkout = () => {
  return (
    <Elements stripe={stripePromise}>
      <ClubPledgePreviewPayment />
    </Elements>
  );
};

const ClubPledgePreviewPayment = () => {

  let { id } = useParams();
  const navigate = useNavigate("/");

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PledgerSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    // const payloadData = {
    //   pledge: {
    //     ...data,
    //     pledger_cents_per_mile: parseFloat(data.pledge_amount * 100),
    //     pledger_max_cents: parseFloat(data.pledge_max * 100)
    //   }
    // }
    // doFetch(createPledger, {query: {code: code}, payload: payloadData});
  }

  useEffect(() => {
    if (loaded) {
      // navigate(`/pledge/${code}/payment-setup?email=${result.email}`);
    }
  }, [loaded]);



  const [succeeded, setSucceeded] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {

  }, []);

  const cardStyle = {
    style: {
      base: {
        border: '2px solid gray',
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setStripeError(event.error ? event.error.message : "");
  };


  useEffect(() => {
    if (succeeded) {
      // navigate(`/pledge/${code}/thanks`);
    }
  }, [succeeded]);


  return (
    <Elements stripe={stripePromise}>
      <div style={{
        backgroundColor: 'black',
        color: 'white',
        padding: '1em',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 100
      }}>
        This is a preview of the pledge page. 

        <div onClick={() => navigate(`/campaigns/${id}`)} className="margin-left-1 button">
          Go Back
        </div>
      </div>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>

        <div className="narrow relative">

          <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
            <div style={{minWidth: '400px'}} className="margin-top-4 col-1 flex-2 padding-2 margin-right-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="margin-bottom-2"></div>

                <div className="bold font-2 margin-bottom-2">
                  Customize your pledge
                </div>

                <div className="flex-cont justify-center center margin-bottom-2">
                  <div className="flex-1 center">
                    $<input defaultValue={10} ref={register} style={{marginRight: '1em', width: '8em'}} type="text" name="pledge_amount" id=""/>
                    {errors.pledge_amount && <div className="field-error">{errors.pledge_amount.message}</div>}
                    <div className="margin-bottom-1"></div>
                    Pledged for every mile Zoey runs.
                  </div>

                  <div className="flex-1 center">
                    $<input defaultValue="1000" ref={register} style={{marginRight: '1em', width: '8em'}} type="text" name="pledge_max" id=""/>

                    {errors.pledge_max && <div className="field-error">{errors.pledge_max.message}</div>}
                    <div className="margin-bottom-1"></div>
                    
                    Maximum amount to pledge.
                  </div>
                </div>

                

                <div className="flex-cont">
                  <label htmlFor="pledger_name">
                    <span>Message to Andy</span>
                    <input ref={register} type="text" placeholder="" name="pledger_message" />
                  </label>
                </div>


                {true &&
                  <>

                    <div className="flex-cont">
                      <label htmlFor="pledger_name">
                        <span>Payment Details</span>
                        <div className="editable-cont">
                          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                        </div>
                      </label>
                    </div>
                    
                    <div className="margin-top-2"></div>
                    {/* <button
                      disabled={processing || disabled || succeeded}
                      id="submit"
                    >
                      <span id="button-text">
                        {processing ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          "Pay"
                        )}
                      </span>
                    </button> */}
                    {/* Show any error that happens when processing the payment */}
                    {stripeError && (
                      <div className="card-error field-error" role="alert">
                        {stripeError}
                      </div>
                    )}
                    {/* Show a success message upon completion */}
                    <p className={succeeded ? "result-message" : "result-message hidden"}>
                      Payment succeeded, see the result in your
                      <a
                        href={`https://dashboard.stripe.com/test/payments`}
                      >
                        {" "}
                        Stripe dashboard.
                      </a> Refresh the page to pay again.
                    </p>
                  </>
                }


                <div className="padding-1">
                  I authorise Hero Run to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with Maranatha Christian Schools.
                </div>

                <div className="font-1-5 justify-center align-center flex-cont">
                  {/* <input type="submit" value="Continue" className="button" id="submit" 
                  // disabled={processing || disabled || succeeded} 
                  /> */}
                  <button
                    disabled={false}
                    id="submit"
                  >
                    <span id="button-text">
                      {(loading || processing) ? (
                        <div className="spinner" id="spinner"></div>
                      ) : (
                        "Submit"
                      )}
                    </span>
                  </button>
                </div>
              </form>
            </div>

            <PledgeSidebar showButton={false} />
          </div>

        </div>

        

      </div>
    </Elements>
  )
}

export default Checkout;