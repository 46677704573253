

import React, { useEffect, useState } from 'react';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { useApi } from '../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';


import { getBands } from 'redux/admin/bandActions';


import { API_URL } from '../../api.js';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

import { Link } from 'react-router-dom';

import { getClubs } from 'redux/admin/clubActions';

const uppy = Uppy({
  meta: { type: 'avatar' },
  //restrictions: { maxNumberOfFiles: 1 },
  autoProceed: true
})


uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const RaceFileSchema = yup.object().shape({
  // name: yup.string().required("Name is required."),
  file: yup.string().required("Please upload a .CSV file."),
});



const AdminHome = ({currentUser}) => {

  const [bands, loading, loaded, error, doFetch, setResult] = useApi();

  const [clubs, clubsLoading, clubsLoaded, clubsError, doClubsFetch, setClubsResult] = useApi(); 


  useEffect(() => {
    fetchBands();
    fetchClubs();
  }, [])

  const fetchBands = () => {
    doFetch(getBands);
  };

  const fetchClubs = () => {
    doClubsFetch(getClubs);
  };

  



  return (

    <>
      <h1>Admin Dashboard</h1>

      {/* <Link to="/club-dashboard" className="margin-right-1 button">Club Dashboard</Link>
      <Link to="/timer-dashboard" className="button">Timer Dashboard</Link> */}

      {/* <div className="flex-cont">
        {clubs && clubs.map(club => {
          return(
            <Link to={`/clubs/view/${club.id}`}>
              <div className="card center">
                <div style={{fontSize: '4rem', backgroundImage: `url( ${club?.avatar_url} )`}} className="avatar-cont">
                </div>
                <div className="bold center">{club.name}</div>
              </div>
            </Link>
      
          )
        })}
      </div> */}








      

    </>


  )
}


export default AdminHome;