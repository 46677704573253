import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

const UpgradeConfirmation = ({currentUser, athletes}) => {

  return (
    <div className="narrow margin-top-2">
      <h1>Thank you for paying your material fee.</h1>

      <div className="margin-top-2">
        <Link to ="/" className="button">
          Back to profile
          <i className="fas fa-chevron-right margin-left-1"></i>
        </Link>
      </div>
{/* 
      <div className="button font-1">
        <span>Go to profile</span>
        <i className="fas fa-chevron-right margin-left-1"></i>
        
      </div> */}

      <div className="margin-bottom-2"></div>

      {/*  */}
    </div>
  )
}

  


export default UpgradeConfirmation;
