import React, { useState, useEffect } from 'react';
import ClubAccountNav from './ClubAccountNav';
import { connect } from 'react-redux';
import ClubForm from '../ClubForm';
import Modal from '../../../components/Modal';

const ClubAccountHome = ({currentClub, children}) => {

  const [editModalOpen, setEditModalOpen] = useState(false);

  const toggleEditModal = (reload = false) => {
    setEditModalOpen(o => !o);
  }

  return (
    <>
      {editModalOpen &&
        <Modal toggleModal={toggleEditModal}>
          <ClubForm currentClub={currentClub} toggleModal={toggleEditModal} />
        </Modal>
      }
      <div className="padding-top-1">

        {!currentClub && 
          <div className="spinnner"></div>
        }

        {currentClub &&
          <>
            <div className="flex-cont align-start">
              <div style={{backgroundImage: `url("${currentClub.avatar_url}")`}} className="avatar-cont"></div>
              <div className="flex-1"></div>
              <div onClick={toggleEditModal} className="button">
                <i className="fas fa-pencil-alt font-09 margin-right-half"></i>
                Edit
              </div>
            
              
            </div>
            <h2>{currentClub?.name}</h2>

            {currentClub.street &&
              <div>
                <div>{currentClub.street}</div>
                <div>{currentClub.city}, {currentClub.state} {currentClub.zipcode}</div>
              </div>
            }
            {/* <pre>
              {JSON.stringify(currentClub, null, 2)}
            </pre> */}
          </>
        }
        
      </div>
    </>
  )

}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(ClubAccountHome);