import React, { useState } from 'react';
import logo7 from 'images/herorun_words3.svg';
import miniLogo from 'images/hr_logo2.svg';
import arrowLogo from 'images/arrow_logo1.svg';
import { NavLink, Link, Outlet, useLocation } from 'react-router-dom';
import store from 'redux/store';
import { connect } from 'react-redux';
import useScrollPosition from 'hooks/useScrollPosition';
import { logoutUser } from 'redux/session/sessionActions';
import { useNavigate } from 'react-router-dom';
import { unsetCurrentAthlete, unauthParent } from 'redux/session/sessionActions';



const MainNav = ({currentClub, currentUser, unreadNotiListables, pages}) => {

  const [hamOpened, setHamOpened] = useState(false); 
  const [scrollPosition, isTop] = useScrollPosition();

  const hamClicked = () => {
    setHamOpened(o => !o);
  }

  const [submenuOpened, setSubmenuOpened] = useState(false);

  const navigate = useNavigate("/");

  const logoutClicked = async () => {
    await store.dispatch(logoutUser());
    navigate("/");
  }

  const activeNavPages = () => {
    return pages?.filter(page => page.active && page.on_nav)
  }

  const switchProfileClicked = () => {
    store.dispatch(unsetCurrentAthlete())
    store.dispatch(unauthParent())
  }



  return(
    <nav className={`main ${isTop ? '' : 'scrolled'}`}>


      <div className="desktop-only narrow-2 flex-cont align-center full">
        <div className="logo-cont">
          <Link to="/">
            <img src={logo7} alt="Logo" />
          </Link>
        </div>

        <div className={`ham-menu-cont ${hamOpened ? 'opened' : ''}`}>
          
        </div>
        <div className={`ham-menu ${hamOpened ? 'opened' : ''}`}>
          <i onClick={hamClicked} className="ham-close fas fa-times"></i>

          <ul>
            
            <li>
              <Link onClick={hamClicked} to="/">Dashboard</Link>
            </li>
            <li>
              <Link onClick={hamClicked} to="/activity">Activity</Link>
            </li>
            <li>
              <Link onClick={hamClicked} to="/fundraising">Fundraising</Link>
            </li>
            
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            {/* <li>
              <Link to="/club-search">Clubs</Link>
            </li> */}
          </ul>
        </div>

        

        <div className="ham-cont">          
          {currentUser &&
            
            <i onClick={hamClicked} className="fas fa-bars"></i>
          }
          {!currentUser &&
            <Link to="/get-started">
              <div className="ghost button">Get Started</div>
            </Link>
            
          }
        </div>

    
        <div className="nav-links-cont">

          {currentUser ? (
              <>

              {currentUser && currentUser.role === "admin" &&

                <>
                  
                  <Link className="text-linkx" to={`/`}>
                    <div className="ghost button margin-right-0">Admin Dashboard</div>
                  </Link>
                </>

                }
                
                {/* <Link className="text-linkx" to={`/club-search`}>
                  <div className="ghost button margin-right-0">Clubs</div>
                </Link> */}

                
                <Link className="margin-left-2 margin-right-2" to={`/notifications`}>
                  <div className="margin-left-1">
                  
                    <div className="noti-bell-cont">
                      <i className="fas fa-bell font-1-25"></i>

                      {unreadNotiListables && unreadNotiListables.length > 0 &&
                        
                          <div className="noti-count pulser red">
                            
                          </div>
                        
                      }
                    </div>
                    
                  </div>
                </Link>

                {currentUser && currentUser.role === "club" &&
                
                  <div onClick={() => setSubmenuOpened(o => !o)} className={`${submenuOpened ? 'opened' : ''} nav-submenu-cont relative flex-cont align-center`}>

                    <div className="nav-submenu-clicker">
                      <i className="fas fa-user font-1-25"></i>
                      <i className="fas fa-caret-down margin-left-05"></i>
                    </div>

                    {/* <div className="margin-right-half avatar-cont small" style={{backgroundImage: `url(${currentUser.avatarURL})`}}>
                      <i className="fas fa-user"></i>

                    </div> */}
                    {/* <div>Dashboard</div> */}

                    {submenuOpened &&
                      <div className='nav-submenu'>

                        <div className="border-bottom flex-cont align-center full padding-bottom-half margin-bottom-half">
                          <div className="circle-cont font-2 margin-right-half"></div>

                          <div className="flex-1">
                            <div className="bold nowrap">
                              {currentUser?.first_name} {currentUser?.last_name}
                            </div>
                            {currentClub &&
                              <div className="nowrap bold uppercase lightgray font-075">
                                {currentClub.friendly_role}
                              </div>
                            }
                          </div>
                          
                        </div>
                        <ul>
                          <li>
                            <div onClick={logoutClicked} className="ghost button">
                              Logout
                            </div>
                          </li>
                        </ul>
                      </div>
                    }
                  </div>
                                    
                }

                {currentUser && ["admin", "customer"].includes(currentUser.role) &&
              
                  
                    <div onClick={() => setSubmenuOpened(o => !o)} className={`${submenuOpened ? 'opened' : ''} nav-submenu-cont relative flex-cont align-center`}>

                      <div className="nav-submenu-clicker">
                        <i className="fas fa-user font-1-25"></i>
                        <i className="fas fa-caret-down margin-left-05"></i>
                      </div>

                      {/* <div className="margin-right-half avatar-cont small" style={{backgroundImage: `url(${currentUser.avatarURL})`}}>
                        <i className="fas fa-user"></i>

                      </div> */}
                      {/* <div>Dashboard</div> */}

                      {submenuOpened &&
                        <div className='nav-submenu'>
                          <ul>
                            <li>
                              <NavLink className="ghost button" to={`/${currentUser.role === "admin" ? "profile" : ""}`}>
                                Profile
                              </NavLink>
                            </li>
                            {(currentUser.role === "customer" || currentUser.role === "admin") &&
                              <li>
                                <div className="ghost button" onClick={switchProfileClicked}>
                                  Switch Profile
                                </div>
                              </li>
                            }
                            <li>
                              <div onClick={logoutClicked} className="ghost button">
                                Logout
                              </div>
                            </li>
                          </ul>
                        </div>
                      }
                    </div>
                    
                    


                }
              </>
            ) : (
              <>
                {activeNavPages()?.map(page => <Link to={`/${page.slug}`} className="text-link">{page.title}</Link>)}
                <Link to="/login" className="text-link">Login</Link>
                {/* <Link to="/join" className="button">Join Now</Link> */}
                
              </>
            )
          }
        </div>
      </div>

      <div className="mobile-only flex-cont align-center justify-center full">


          
          

          <div className="mobile-nav-left">

            {currentUser &&
              <>
                <div className="mobile-nav-item">
                  <NavLink to="/account">
                    <i className="fas fa-cog"></i>
                  </NavLink>
                </div>

                <div className="mobile-nav-item">
                  <NavLink to="/club-search">
                    <i className="fas fa-search"></i>
                  </NavLink>
                </div>
              </>
            }
            {!currentUser &&
              <Link className="ghost button" to="login">
                Login
              </Link>
            }
            
          </div>
          
          <div className="mobile-nav-logo-cont">
            <Link to="/">
              <img src={miniLogo} alt="Logo" />
            </Link>
          </div>
          

          <div className="mobile-nav-right">
            {currentUser &&
              <>
                {currentUser.role === "admin" &&
                  <div className="mobile-nav-item">
                    <NavLink to="/">
                      <i className="fas fa-tachometer-alt"></i>
                    </NavLink>
                  </div>
                }

                <div className="mobile-nav-item">
                  <NavLink to="/notifications">
                    <i className="fas fa-bell"></i>
                  </NavLink>
                </div>

                {currentUser && ["admin", "customer"].includes(currentUser.role) &&
                  <div className="mobile-nav-item">
                    <NavLink to={`/${currentUser.role === "admin" ? "profile" : ""}`}>
                      <i className="fas fa-user"></i>
                      <i className="fas fa-caret-down margin-left-05">  </i>
                    </NavLink>
                  </div>
                }
              </>
            }
            {!currentUser &&
              <Link className="ghost button" to="join">
                Join
              </Link>
            }
          </div>
        

      </div>

      
   
    </nav>
  )
}

function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
  };
} 

export default connect(mapState)(MainNav);
