import React, { useState, useEffect } from 'react';
import { useCombobox } from 'downshift'
import { useApi } from '../api';
import { getClubs } from 'redux/clubs/clubActions';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

// import MOCK_DATA from './MOCK_DATA'; 

const Clubs = ({}) => {

  const [fuse, setFuse] = useState(null);

  const [clubs, loading, loaded, error, doFetch, setClubs] = useApi();

  const [results, setResults] = useState([]);

  const [search, setSearch] = useState("");


  useEffect(() => {
    if (loaded) {
      setResults(clubs.slice(0, 10));

      const f = new Fuse(clubs, {
        keys: ['name']
      });

      setFuse(f)


    }
  }, [loaded]);

  useEffect(() => {
    if (clubs) {

      if (search) {
        setResults(fuse.search(search).slice(0, 10));
      }
      else {
        setResults(clubs.slice(0, 10));
      }
      
    }
  }, [search]);



  useEffect(() => {
    doFetch(getClubs);
  }, [])



  return (
    <div className="narrow-2 margin-top-1">
      <h1>Clubs</h1>

      <input placeholder="Start typing..." className="font-1-25" onChange={e => setSearch(e.target.value)} type="text" name="search" id=""/>

      
      <ul className="search">
        {results && results.map(result => 
          
          {
            const iii = result.item ? result.item : result;

            return(
              <Link to={`/club/${iii.slug}`}>
                <li>
                    <div className="margin-right-1 avatar-cont" style={{fontSize: '3em', backgroundImage: `url("${iii.avatar_url}")`}}></div>

                    <div>{iii.name}</div>
                </li>
              </Link>
            );
          }
        )}
      </ul>




     
    </div>
  )
}

export default Clubs; 