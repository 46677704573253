import { andyFetch } from '../../api';

export const getExistingPledge = (params) => {
  return async (dispatch, getState) => {

    const url = `pledges/existing`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getExistingPledge error", e);
    }
  }
};

export const getPledgePayments = (params) => {
  return async (dispatch, getState) => {

    const url = `pledges/pledge_payments`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgePayments error", e);
    }
  }
};


export const getPledgePage = (params) => {
  return async (dispatch, getState) => {

    const url = `pledges/pledge_page`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgePage error", e);
    }
  }
};

export const getPledgeProgress = (params) => {
  return async (dispatch, getState) => {

    const url = `pledges/progress`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeProgress error", e);
    }
  }
};


export const getPledgeByCode = (params) => {
  return async (dispatch, getState) => {

    const url = `pledges/by_code`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeByCode error", e);
    }
  }
};

export const forgotPledge = (params) => {
  return async (dispatch, getState) => {

    const url = `pledges/forgot_pledge`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("forgotPledge error", e);
    }
  }
};


export const getPledgeDriveSubs = (params) => {
  return async (dispatch, getState) => {

    const url = `/athletes/my_pledge_drive_subs`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDriveSubs error", e);
    }
  }
};


export const createPledge = (params) => {

  return async (dispatch, getState) => {

    const url = "pledges";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPledge error.');
      }
    }
    catch (e) {
      console.log("createPledge error", e);
    }
  }
};


export const updatePledge = (params) => {

  return async (dispatch, getState) => {

    const url = `pledges/update_pledge`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePledge error.');
      }
    }
    catch (e) {
      console.log("updatePledge error", e);
    }
  }
};


export const createPledger = (params) => {
  return async (dispatch, getState) => {
    const url = "pledges/create_pledger";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('createPledger error.');
      }
    }
    catch (e) {
      console.log("createPledger error", e);
    }
  }
};