import { andyFetch } from 'api';


export const getMyClubEvents = (params) => {
  return async (dispatch, getState) => {
    const url = "club/run_monitor/club_events/my_club_events";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubEvents error", e);
    }
  }
};
