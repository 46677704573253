import React, { useState, useEffect } from 'react';
import { useOutletContext, useLocation, Link, useParams } from 'react-router-dom';
import { useApi } from '../../api';
import { getPledgeProgress, getPledgePayments } from '../../redux/user/pledgeActions';
import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import Bar from '../../components/Bar';
import CountUp from '../../components/CountUp';
import PledgeTable from './PledgeTable';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const centsToDollars = (cents) => {
  if (cents === undefined || cents === null || isNaN(parseFloat(cents))) {
    return 0.0
  }

  return parseFloat(cents) / 100.00

}

const PledgeProgress = () => {

  let { pledge_code } = useParams();
  let pledgeCode = pledge_code;


  const [pledgePage] = useOutletContext();

  const [pledgeProgress, pledgeProgressLoading, pledgeProgressLoaded, pledgeProgressError, doPledgeProgressFetch, setPledgeProgressResult] = useApi(); 

  const [payments, paymentsLoading, paymentsLoaded, paymentsError, doPaymentsFetch, setPaymentsResult] = useApi(); 

  useEffect(() => {
    if (pledgeCode) {
      
      fetchPledgeProgress();
      fetchPayments();
    }
  }, [pledgeCode]);

  const fetchPledgeProgress = () => {
    doPledgeProgressFetch(getPledgeProgress, {query: {pledge_code: pledgeCode}});
  }

  const fetchPayments = () => {
    doPaymentsFetch(getPledgePayments, {query: {pledge_code: pledgeCode}});
  }

  const pledge = pledgeProgress?.pledge;

  const formattedTime = (timeHash) => {
    const hours = parseFloat(timeHash.hours);
    const minutes = parseFloat(timeHash.minutes);
    const seconds = parseFloat(timeHash.seconds);
    const ms = parseFloat(timeHash.ms);

    return (
      <>
        <div className="flex-cont align-center">
          <div className="padding-right-1 border-right margin-right-1">
            <div>{hours}</div>
            <div className='margin-top-half font-1 bold uppercase'>hours</div>
          </div>
          <div className="padding-right-1 border-right margin-right-1">
            <div>{minutes}</div>
            <div className='margin-top-half font-1 bold uppercase'>minutes</div>
          </div>
          <div className="">
            <div>{seconds}</div>
            <div className='margin-top-half font-1 bold uppercase'>seconds</div>
          </div>
        </div>
      </>
    )
  }

  return (

    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>
        
        <div className="narrow-2 relative">
          {pledgePage && pledgeProgress &&

            <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
              <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
                
                <div className="blue font-3 bold-200 margin-bottom-3">
                  {pledgePage.athlete_first_name}'s Progress
                </div>

                <div className="rounded-box padding-2 margin-bottom-0 margin-1">
                  <div className="flex-cont">
                    <div className="margin-bottom-half blue bold-200 font-2">
                      <div>
                        <CountUp delay={500} number={parseFloat(pledge.total_cents_earned)} type="money" />
                        {/* {(parseFloat(pledge.total_cents_earned)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} */}
                      </div>
                    </div>
                    <div className="flex-1"></div>
                    <div className="margin-bottom-half blue bold-200 font-2">
                      <div>
                      {(parseFloat(pledge.pledger_max_cents)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </div>
                    </div>
                  </div>
                  <Bar height={25} full progress={(parseFloat(pledge.total_cents_earned) / parseFloat(pledge.pledger_max_cents)) * 100.00 } />

                  <div className="flex-cont">
                    <div>
                      <div className="blue margin-left-half bold margin-top-half">
                        EARNED
                      </div> 
                    </div>
                    <div className="flex-1"></div>
                    <div className="text-right">
                      <div className="blue margin-right-half bold margin-top-half">
                        PLEDGED
                      </div> 
                    </div>
                  </div>
                </div>

                <div className="flex-cont flex-wrap">
                  <div className="flex-1 center rounded-box padding-2 margin-1">
                    <div className="margin-bottom-half blue bold-200 font-3">
                      {parseFloat(parseFloat(pledge.total_miles)?.toFixed(1))}
                    </div>
                    <div className="bold blue">
                      TOTAL MILES
                    </div>
                  </div>

                  <div className="center flex-1 rounded-box padding-2 margin-1">
                    <div className="nowrap margin-bottom-half blue bold-200 font-3">
                      {/* {pledge.total_seconds} */}
                      {formattedTime(pledge.time_hash)}
                    </div>
                    {/* <div className="bold blue">
                      ACTIVITY TIME
                    </div> */}
                  </div>
                </div>

                <div className="rounded-box padding-2 margin-bottom-0 margin-1">
                  <div className="margin-bottom-2 flex-cont align-center">
                    <div className="flex-1 bold font-1-25">
                      Your Pledge
                    </div>

                    <Link to={`/pledge/edit?pledge_code=${pledge.code}`} className="button ghost">
                      Edit Pledge
                    </Link>
                  </div>
                  
                  <PledgeTable pledge={pledge} />
                </div>

                <div className="rounded-box padding-2 margin-1 margin-top-2">
                  <div className="bold font-1-25 margin-bottom-2">Payment History</div>
                  
                  
                  {paymentsLoading && <div className="spinner"></div> }
                  {!paymentsLoading && (!payments || payments.length === 0) &&
                    <em>You don't have any payments for this pledge yet.</em>
                  }
                  {payments && 
                    <table className='basic'>
                      <tr>
                        <th>Date</th>
                        <th>Miles Ran</th>
                        <th>Charged</th>
                        <th>Card</th>
                        <th></th>
                        <th>Receipt Code</th>
                      </tr>
                      {payments.map(payment =>
                          <tr>
                            <td className="fit nowrap">
                              {payment.created_at}
                            </td>
                            <td>
                              {parseFloat(parseFloat(payment.miles).toFixed(1))} mi.
                            </td>
                            <td>
                              {(parseFloat(payment.cents_paid)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </td>
                            <td>
                              <span className="bold">{payment.card_brand?.toUpperCase()}</span> **** {payment.card_last4}
                            </td>
                            <td>
                              {payment.stripe_error}
                            </td>
                            <td>
                              {payment.code}
                            </td>
                          </tr>
                        )
                      }

                    </table>
                  }
                  
                 
                </div>

                {/* <div>
                  <pre>
                    {JSON.stringify(pledgeProgress, null, 2)}
                  </pre>
                </div> */}
              </div>

              <PledgeSidebar showButton={false} pledgePage={pledgePage} isPreview={false} />
            </div>
          }

          {/* <div style={{marginTop: '100px'}} className="narrow center">

            <pre>
              {JSON.stringify(pledgeCode, null, 2)}
            </pre>

            <h1 className="font-4">
              Thank you for your pledge!
            </h1>


            <h2>We will email you with updates on {pledgePage.athlete_first_name}'s progress.</h2>


          </div> */}

        </div>

      </div>


    </>

 
  )
}

export default PledgeProgress;