import { createRef, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';


export function useVisibility(
  offset = 0,
  throttleMilliseconds = 100,
) {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = createRef();

  const checkIt = () => {
    
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
  }

  const onScroll = throttle(() => {
    checkIt();
  }, throttleMilliseconds);

  useEffect(() => {

    checkIt();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return { isVisible, currentElement };
}