

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar, FileInput } from '@uppy/react'

import { importRosterFile, previewRosterFile, confirmRosterFile } from 'redux/club/clubActions';
import { useNavigate, Outlet, useLocation } from "react-router-dom"

// const uppy = Uppy({
//   meta: { type: 'roster' },
//   restrictions: { 
//     // maxNumberOfFiles: 2,
//     allowedFileTypes: ['.xlsx']
//   },
//   autoProceed: true
// })

 
// uppy.use(AwsS3Multipart, { 
//   companionUrl: API_URL
// });

const ClubSchema = yup.object().shape({
  // name: yup.string().required("Name is required."),
  file: yup.string(),
  // .required("Please upload a .CSV file."),
});


const OnboardingUploadRoster = ({currentClub}) => {

  const navigate = useNavigate();

  const uppy = useRef(undefined);
  if (uppy.current === undefined) {
    uppy.current = new Uppy({
      meta: { type: 'roster' },
      restrictions: { 
        // maxNumberOfFiles: 2,
        allowedFileTypes: ['.xlsx']
      },
      autoProceed: true
    });
    
    uppy.current.use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
  }

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: ClubSchema
  });




  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const [importedRoster, importedRosterLoading, importedRosterLoaded, importedRosterError, doImportedRosterFetch, setImportedRosterResult] = useApi();

  useEffect(() => {

    uppy.current.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.current.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.current.on('upload-success', function (file, response) {
      
      var uploadedFileData = {
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      }

      setFileURL(response.uploadURL);



      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })

    return () => uppy?.current?.close();
  }, []);



  const getRosterURL = () => {
    return fileURL ? fileURL : currentClub?.roster_url
  }

  const onSubmit = (data) => {

    const payload = {
      club: {
        ...data,
      }
    }
    doImportedRosterFetch(importRosterFile, {payload: payload})
  }

  useEffect(() => {
    if (importedRosterLoaded) {
      
      navigate(`/onboarding/confirm-roster/${importedRoster.id}`);
    }
  }, [importedRosterLoaded]);


  
  return(
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">1</div>
        <h1 className="flex-cont align-center no-margin-bottom">
          Import Roster
        </h1>
        <h2 className="no-margin-top">Upload Roster Spreadsheet</h2>

        <p>Please upload an Excel (.xlsx) file of your school's roster</p>
        <p>
          <a className="text-link" href="https://herorun.s3.us-west-1.amazonaws.com/Hero+Run+Roster+Template-3.xlsx">Our roster template</a> should be used to as a guide to format your roster.
        </p>



          <div className={`${uploadLoading ? 'disabled' : ''} button uppy-file-select-cont margin-top-3`}>
            <div>Select file...</div>
            {/* {!uploadLoading &&
              <div>Select file...</div>
            }
            {uploadLoading &&
              <div className="spinner"></div>
            } */}
          <input ref={register} type="hidden" name="file" value={JSON.stringify(file)} />
          
          <DragDrop
            className="andy"
            uppy={uppy?.current}
            locale={{
              strings: {
                dropHereOr: 'Drop here or %{browse}',
                browse: 'browse'
              }
            }}
          />
        </div>
        <StatusBar uppy={uppy?.current} />

        {file &&
          <div className="margin-top-1 padding-1">
            Uploaded File: <span className="bold">{file?.metadata?.filename}</span>
          </div>
        }

        {file &&
          <div className="flex-cont align-center">
            <div className="flex-1"></div>
            {importedRosterLoading &&
              <div className="spinner margin-right-1"></div>
            }
            <button disabled={importedRosterLoading} type="submit" className={`button ${importedRosterLoading ? 'disabled' : ''}`}>
              <div>Save and Continue</div>
              <i className="fas fa-chevron-right margin-left-1"></i>
            </button>
          </div>
        }

      </div>
      </form>
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingUploadRoster);