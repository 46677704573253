

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm, Controller } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'

import { createPledgeDrive } from 'redux/club/pledgeDriveActions';
import { DateTime } from "luxon";
import { getMyClub } from 'redux/club/clubActions';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"
import DatePicker from 'react-date-picker';
import '../../../styles/Calendar.scss';
import PledgeDriveForm from '../pledgeDrives/PledgeDriveForm';

const PledgeDriveSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  start_date: yup.string().nullable().required("Start date is required."),
  end_date: yup.string().nullable().required("End date is required."),
});

const OnboardingCampaigns = ({currentClub}) => {

  const navigate = useNavigate();

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const { watch, register, errors, handleSubmit, getValues, control } = useForm({
    validationSchema: PledgeDriveSchema
  });

  const onSubmit = (data) => {
    const payload = {
      pledge_drive: {
        ...data
      }
    }
    doFetch(createPledgeDrive, {payload: payload})
  }

  const hasErrors = () => (errors && Object.keys(errors).length !== 0);
  const buttonDisabled = () => (hasErrors() || loading);

  useEffect(() => {
    if (loaded) {
      navigate('/');
    }
  }, [loaded]);

  const currentPledgeDrive = null;

  
  return(
    <>
      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">4</div>
        <h1 className="no-margin-bottom">Campaigns</h1>
        <h2 className="no-margin-top">Create a campaign for your school.</h2>


        <form className="margin-top-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-cont">
            
            <label htmlFor="name"> 
              <span>Campaign Name</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Campaign name"
                name="name"
                defaultValue={currentPledgeDrive?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>


          <div className="flex-cont">
            <label htmlFor="start_date"> 
              <span>Start Date</span>
              <input 
                type="date" 
                ref={register}
                name="start_date"
                defaultValue={currentPledgeDrive?.start_date ? DateTime.fromISO(currentPledgeDrive?.start_date).toUTC().toISODate() : null}
              />
              {errors.start_date && <div className="field-error">{errors.start_date.message}</div>}
            </label>
            <label htmlFor="end_date"> 
              <span>End Date</span>
              <input 
                type="date" 
                ref={register}
                name="end_date"
                defaultValue={currentPledgeDrive?.end_date ? DateTime.fromISO(currentPledgeDrive?.end_date).toUTC().toISODate() : null}
              />
              {errors.end_date && <div className="field-error">{errors.end_date.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="start_date"> 
              <span>Distance Goal</span>

              <div className="flex-cont align-center relative">
                <input 
                  type="number" 
                  min="0"
                  max="999999"
                  step="0.01"
                  ref={register}
                  className="right-padding"
                  name="distance_goal"
                  defaultValue={currentPledgeDrive?.distance_goal}
                />
                <div className="inner-label right number">mi.</div>
              </div>
              {errors.start_date && <div className="field-error">{errors.start_date.message}</div>}
            </label>
            <label htmlFor="end_date"> 
              <span>Pledge Goal</span>
              <div className="flex-cont align-center relative">
                <div className="inner-label left">$</div>
                <input 
                  type="number" 
                  min="0"
                  max="999999"
                  step="0.01"
                  ref={register}
                  className="left-padding"
                  name="pledged_goal"
                  defaultValue={currentPledgeDrive?.pledged_goal}
                />
              </div>
              {errors.end_date && <div className="field-error">{errors.end_date.message}</div>}
            </label>
            <label htmlFor="end_date"> 
              <span>Earned Goal</span>
              <div className="flex-cont align-center relative">
                <div className="inner-label left">$</div>
                <input 
                  type="number" 
                  min="0"
                  max="999999"
                  step="0.01"
                  ref={register}
                  className="left-padding"
                  name="earned_goal"
                  defaultValue={currentPledgeDrive?.earned_goal}
                />
              </div>
              
              {errors.end_date && <div className="field-error">{errors.end_date.message}</div>}
            </label>
          </div>
 

        <div className="lign-center flex-cont">
          <button className={`${loading ? 'disabled' : ''} button`}>
            Save
            {loading && <div className="spinner button-spinner"></div>}
          </button>
        </div>

      </form>

      </div>
     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingCampaigns);