

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getPledges } from 'redux/admin/pledgeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createPledge, updatePledge, deletePledge } from 'redux/admin/pledgeActions';

const PledgeSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipcode: yup.string().required("Zipcode is required."),
  id: yup.string().nullable(),
  lap_distance: yup.number().required("Lap distance is required."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});


const PledgeForm = ({toggleModal, currentPledge}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: PledgeSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [pledgesResult, pledgesLoading, pledgesLoaded, pledgesError, doFetchPledges, setPledgeResults] = useApi();

  const onSubmit = async data => {
    let d = {
      pledge: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentPledge && currentPledge.id) {
      doFetch(updatePledge, {payload: d});
    }
    else {
      doFetch(createPledge, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentPledge ? 'Edit' : 'New'} Pledge</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentPledge?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentPledge?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="street"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Street"
                name="street"
                defaultValue={currentPledge?.street}
              />
              {errors.street && <div className="field-error">{errors.street.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="city"> 
              <input 
                type="text" 
                ref={register}
                placeholder="City"
                name="city"
                defaultValue={currentPledge?.city}
              />
              {errors.city && <div className="field-error">{errors.city.message}</div>}
            </label>
            <label htmlFor="state"> 
              <input 
                type="text" 
                ref={register}
                placeholder="State"
                name="state"
                defaultValue={currentPledge?.state}
              />
              {errors.state && <div className="field-error">{errors.state.message}</div>}
            </label>
            <label htmlFor="zipcode"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Zipcode"
                name="zipcode"
                defaultValue={currentPledge?.zipcode}
              />
              {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
            </label>


            
          </div>


          <div className="flex-cont">
            <label htmlFor="lap_distance"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Lap Distance (miles)"
                name="lap_distance"
                defaultValue={currentPledge?.lap_distance}
              />
              {errors.lap_distance && <div className="field-error">{errors.lap_distance.message}</div>}
            </label>
          </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminPledges = ({currentUser}) => {

  const [pledges, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedPledge, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledge] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPledge, setEditingPledge] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPledges();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPledge(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!pledges) {
      fetchPledges();
    }
  }, []);

  const fetchPledges = () => {
    doFetch(getPledges);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPledge, setDeletingPledge] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPledges();
    }
  }


  const editClicked = pledge => {
    setEditingPledge(pledge);
    toggleModal();
  }

  const deleteClicked = pledge => {
    setDeletingPledge(pledge);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPledge) {
      doDeleteFetch(deletePledge, {query: {id: deletingPledge.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <PledgeForm currentPledge={editingPledge} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Pledges</h1>
        </div>
        <div>
          {/* <div onClick={toggleModal} className="button">New Pledge</div> */}
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {pledges &&
        <table className="basic">
          {pledges.map(pledge => {
            return (
              <tr>
                <td className="bold fit nowrap">{pledge.pledger_name}</td>
                <td className="fit nowrap">{pledge.pledger_email}</td>
                <td className="fit nowrap">{pledge.dollars_pledged} mi/lap</td>
                <td className="fit nowrap">{pledge.dollars_max} max</td>
                <td>{pledge.pledger_message}</td>
                <td className="fit nowrap">
                  {/* <div onClick={() => editClicked(pledge)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div> */}

                  <div onClick={() => deleteClicked(pledge)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Pledge</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingPledge?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!pledges || pledges.length === 0) &&
        <em>No pledges.</em>
      }


      


    </>
  )
}


export default AdminPledges;