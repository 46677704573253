import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getTeam } from 'redux/club/teamActions';
import Modal from 'components/Modal';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import { useForm } from 'react-hook-form';
import ClubTeamNav from './ClubTeamNav';

import { getTeamOwnerships, createTeamOwnerships, createTeamOwnership, destroyTeamOwnership, inviteTeamOwnership, updateTeamOwnership, } from 'redux/club/teamOwnershipActions';

import { getTeamAdminUsers } from '../../../redux/club/userActions';

const ClubTeamManagers = () => {
  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTeam();
    }
  }, [id]);

  const [team, teamLoading, teamLoaded, teamError, doFetchTeam, setTeamResults] = useApi();

  const fetchTeam = () => {
    doFetchTeam(getTeam, {query: {id: id}})
  }

  const [editingTO, setEditingTO] = useState(null);
  const toggleEditingTO = () => {
    setEditingTO(null);
  }

  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const toggleRemoveModal = () => setRemoveModalOpened(o => !o);

  const [removeResult, removeLoading, removeLoaded, removeError, doRemoveFetch, setRemoveResult] = useApi(); 
  const [updateResult, updateLoading, updateLoaded, updateError, doUpdateFetch, setUpdateResult] = useApi(); 

  const confirmRemoveClicked = () => {
    doRemoveFetch(destroyTeamOwnership, {payload: {id: editingTO.id}})
  }
  useEffect(() => {
    if (removeLoaded) {
      setRemoveModalOpened(false);
      setEditingTO(null);
      fetchTeam();
    }
  }, [removeLoaded]);

  useEffect(() => {
    if (!editingTO) {
      setRemoveModalOpened(false);
    }
  }, [editingTO]);

  const handleUpdateSubmit = (data) => {
    doUpdateFetch(updateTeamOwnership, {payload: data})
  }
  useEffect(() => {
    if (updateLoaded) {
      setRemoveModalOpened(false);
      setEditingTO(null);
      fetchTeam();
    }
  }, [updateLoaded]);


  const [addTeamOwnershipOpened, setAddTeamOwnershipOpened] = useState(false);

  const toggleAddTeamOwnership = (reload=false) => {
    setAddTeamOwnershipOpened(o => !o);

    if (reload) {
      fetchTeam();
    }
  }



  return (
    <>

      {editingTO && <>
        <Modal toggleModal={toggleEditingTO}>
          <form onSubmit={handleSubmit(handleUpdateSubmit)}>
            <input ref={register()} type="hidden" name="id" value={editingTO.id} />
            <div className="modal-header flex-cont align-center">
              <h2 className="flex-1">Edit Manager</h2>

              <div onClick={toggleEditingTO} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>
            <div className="padding-1">

              <div className="bold">
                {editingTO.user_full_name}
              </div>
              <div>
                {editingTO.user_email}
              </div>
              <div className="inline-block margin-top-1">
                <label>
                  <span>Role</span>
                  <select name="role" ref={register({required: "Please select a role."})} style={{minWidth: '15em'}} defaultValue={editingTO.role}>
                    <option value="teacher">Teacher</option>
                    <option value="team-admin">Parent/Administrator</option>
                  </select>
                </label>
              </div>

              
            </div>
            <div className="modal-footer">
              <div className="flex-cont align-center">
                <button disabled={updateLoading} type="submit" className={`button`}>
                  Save
                  {updateLoading &&
                    <div className="spinner button-spinner"></div>
                  }
                </button>
                <div className="flex-1">
                </div>
                <div onClick={toggleRemoveModal} className="button ghost danger">Remove</div>
                
              </div>
            </div>

            {removeModalOpened && editingTO &&
              <Modal toggleModal={toggleRemoveModal} focusTrap={false} size="small">
                <div className="padding-2">
                  Are you sure you want to remove {editingTO.user_full_name} from this team?
                </div>

                <div className="modal-footer">
                  <div className="flex-cont align-center">

      
                    <div onClick={toggleRemoveModal} className="ghost button">
                      Cancel
                    </div>
                    <div className="flex-1"></div>
                    <div disabled={removeLoading} onClick={confirmRemoveClicked} className="button danger">
                      Remove
                      {removeLoading &&
                        <div className="spinner button-spinner"></div>
                      }
                    </div>
                    
                  </div>
                </div>
              </Modal>
            }

          </form>
        </Modal>

        
        </>
      }

      

      {addTeamOwnershipOpened &&
        <AddTeamOwnershipModal toggleModal={toggleAddTeamOwnership} team={team} />
      }

      <div onClick={toggleAddTeamOwnership} className="button margin-bottom-2">
        <i className="fas fa-plus margin-right-half"></i>
        Add Manager
      </div>


      <table className="basic">
        {team?.team_ownerships?.map(teamOwnership => 
          <tr>
            <td className="fit nowrap bold">{teamOwnership.user_full_name}</td>
            <td className="fit nowrap">{teamOwnership.friendly_role?.toUpperCase()}</td>
            <td className="">{teamOwnership.user_email}</td>
            <td className="fit nowrap">
              <i className="margin-right-1 ghost button fas fa-pencil-alt" onClick={() => setEditingTO(teamOwnership)}></i>
              <i className="ghost button fas fa-trash" onClick={() => setEditingTO(teamOwnership)}></i>
            </td>
          </tr>  
        )}

      </table>

      {id &&
        <InviteForm fetchTeam={fetchTeam} teamID={id} />
      }
      


  
    </>
  )

}

const AddTeamOwnershipModal = ({toggleModal, team}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });

  const [updateResult, updateLoading, updateLoaded, updateError, doUpdateFetch, setUpdateResult] = useApi(); 

  const [teamAdminUsers, loading, loaded, error, doFetch, setResult] = useApi(); 

  const fetchTeamAdminUsers = () => {
    doFetch(getTeamAdminUsers);
  }

  useEffect(() => {
    fetchTeamAdminUsers();
  }, [])

  const onSubmit = data => {
    // alert(JSON.stringify(data, null, 2))

    doUpdateFetch(createTeamOwnership, {payload: data})
  }

  useEffect(() => {
    if (updateLoaded) {
      toggleModal(true);
    }
  }, [updateLoaded])

  return (
    <Modal toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <input ref={register()} type="hidden" name="team_id" value={team.id} />

        <div className="modal-header flex-cont align-center">
          <h1 className="flex-1">Transfer Team Admin to {team.name}</h1>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="padding-1">

          {loading && <div className="spinner"></div>  }
          {teamAdminUsers &&
            <>
              <label>
                <span>Team Admin</span>
                <select name="user_id" ref={register({required: "Please select a team admin."})} defaultValue={""}>
                  <option value="">Select team admin:</option>
                  {teamAdminUsers?.map(teamAdminUser =>
                    <option value={teamAdminUser.id}>  
                      {teamAdminUser.last_name}, {teamAdminUser.first_name}
                    </option>
                  )}
                </select>
              </label>

              <label>
                <span>Role</span>
                <select name="role" ref={register({required: "Please select a role."})} style={{minWidth: '15em'}} defaultValue={""}>
                  <option value="" disabled>Select role:</option>
                  <option value="teacher">Teacher</option>
                  <option value="team-admin">Parent/Administrator</option>
                </select>
              </label>
            </>
          }
        </div>
        <div className="modal-footer flex-cont align-center">
          <button className="button">Save</button>
          <div className="flex-1"></div>
        </div>
      </form>
    </Modal>
  )
}


const InviteForm = ({fetchTeam, teamID}) => {

  const { reset, watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });

  const [inviteResult, inviteLoading, inviteLoaded, inviteError, doInviteFetch, setInviteResult] = useApi(); 

  const onSubmit = data => {
    const p = {
      ...data,
      team_slug: teamID
    }

    doInviteFetch(inviteTeamOwnership, {payload: p});

  }

  useEffect(() => {
    if (inviteLoaded) {
      reset();
      fetchTeam();
    }
  }, [inviteLoaded])

  return (
    <div className="card no-hover margin-top-3">
      <h3 style={{marginTop: 0}}>Invite Managers to Team</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-cont align-center">
          <label htmlFor="">
            <span>First Name</span>
            <input ref={register({
              required: "Please enter first name."
            })} type="text" name="first_name" id="" />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="">
            <span>Last Name</span>
            <input ref={register({
              required: "Please enter a last name."
            })} type="text" name="last_name" id="" />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
          <label htmlFor="">
            <span>Email</span>
            <input ref={register({
              required: "Please enter an email."
            })} type="text" name="email" id="" />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>
          <label htmlFor="">
            <span>Role</span>
            <select ref={register({
              required: "Please select role."
            })} name="role">
              <option value="teacher">Teacher</option>
              <option value="team-admin">Parent/Administrator</option>
            </select>
            {errors.role && <div className="field-error">{errors.role.message}</div>}
          </label>

          <button disabled={inviteLoading}>
            <i className="margin-right-half fas fa-plus"></i>
            Invite
            {inviteLoading &&
              <div className="spinner button-spinner"></div>
            }
          </button>
        </div>
        
      </form>
    </div>
  )
}



const ManagerRow = ({teamOwnership}) => {

  const [isEditing, setIsEditing] = useState(false);



  return (
    <>
      {!isEditing &&
        <tr>
          <td className="fit nowrap bold">{teamOwnership.user_full_name}</td>
          <td className="fit nowrap">{teamOwnership.role?.toUpperCase()}</td>
          <td className="">{teamOwnership.user_email}</td>
          <td className="fit nowrap">
            <i className="margin-right-1 ghost button fas fa-pencil-alt" onClick={() => setIsEditing(o => !o)}></i>
            <i className="ghost button fas fa-trash" onClick={() => setIsEditing(o => !o)}></i>
          </td>
        </tr>  
      }
      {isEditing &&
        <tr>
          <td className="fit nowrap bold">
            {teamOwnership.user_full_name}
          </td>
          <td className="fit nowrap">
            <div className="inline-block">
              <select style={{minWidth: '15em'}} defaultValue={teamOwnership.role}>
                <option value="teacher">Teacher</option>
                <option value="manager">Parent/Administrator</option>
              </select>
            </div>
            
          </td>
          <td className="">{teamOwnership.user_email}</td>
          <td className="fit nowrap">
            <div className="margin-right-1 button" onClick={() => setIsEditing(o => !o)}>Save</div>
            <div className="button ghost" onClick={() => setIsEditing(o => !o)}>Cancel</div>
          </td>
        </tr>
    
      }

    </>
  )

}

export default ClubTeamManagers;