import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { DateTime } from "luxon";
import { getMyRaceLogs, getAthlete } from 'redux/user/userActions';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';

const ProfileActivity = () => {


  const [raceLogs, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchMyRaceLogs();
  }, []);

  const fetchMyRaceLogs = async () => {
    doFetch(getMyRaceLogs);
  };

  return (
    <>
      <h2>Activities</h2>

      <div className="margin-bottom-2"></div>

      {loading &&
        <div className="spinner"></div>
      }

      {!loading && (!raceLogs || raceLogs.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-stopwatch blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">Run your first race and see your results here!</div>
        </div>
      }
      

      {raceLogs && raceLogs.map( (log, logIndex) => {
        return(
          <div className="card margin-bottom-1">

            <div className="flex-cont margin-bottom-1">
              <div className="label">
                {/* {log.created_at && DateTime.fromISO(log.created_at).toLocaleString(DateTime.DATETIME_MED)} */}
                {log?.start_datetime}
              </div>
            </div>

            

            

            <div className="flex-cont align-center margin-bottom-1">
              <div className="number-cont">
                <div className="number font-1-5 bold-900">
                  {log.total_laps}
                </div>
                <div className="label">
                  LAPS
                </div>
              </div>
              
              <div className="margin-left-2 number-cont">
                <div className="number font-1-5 bold-900">
                  {log.total_miles}
                </div>
                <div className="label">
                  MILES
                </div>
              </div> 

              <div className="margin-left-2 number-cont">
                <div className="number font-1-5 bold-900">
                  {log.total_time}
                </div>
                <div className="label">
                  TIME
                </div>
              </div>
            </div>

            <div className="flex-cont margin-bottom-1">
              { [...Array(3).keys()].map( i => {
                if (logIndex === 0) {
                  return (
                    <div className="badge margin-right-2 font-075">
                      <i className="fas fa-medal"></i>
                    </div>
                    
                  )
                }

                return null;
              })}
            </div>

            <table className="margin-top-1">
              {log.laps && log.laps.map( (lap, index) => {
                return(
                  <tr>
                    <td className="padding-right-05 gray font-075 bold">
                      {lap.best &&
                        <i className="fas fa-star"></i>
                      }
                    </td>
                    <td className="padding-right-1 gray font-075 bold">{index+1}</td>


                    <td className="padding-right-1">{lap.time}</td>

                    <td className="gray">{lap.miles} mi</td>

                  </tr>

                  
                )
              })}
            </table>
            
            
          </div>
        )
      })} 
    </>
  )
}

export default ProfileActivity;