import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getCurrentSchoolYear } from 'redux/club/schoolYearActions';
import ClubSchoolYearNav from './ClubSchoolYearNav';

import ClubSchoolYearForm from './ClubSchoolYearForm';

const ClubSchoolYearLayout = ({children}) => {


  useEffect(() => {
    fetchCurrentSchoolYear();

    
  }, []);

  const [schoolYear, schoolYearLoading, schoolYearLoaded, schoolYearError, doFetchSchoolYear, setSchoolYear] = useApi();

  const fetchCurrentSchoolYear = () => {
    doFetchSchoolYear(getCurrentSchoolYear)
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editSchoolYearClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchCurrentSchoolYear();
    }
  } 



  return (
    <>
      {schoolYear && editModalOpened &&
        <ClubSchoolYearForm toggleModal={toggleEditModal} currentSchoolYear={schoolYear} />
      }
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {schoolYearLoading && <div className="loading"></div> }
          <h1>
            {schoolYear?.name}
          </h1>

          <div className="flex-1"></div>

          <div>
            {schoolYear &&
              <i onClick={editSchoolYearClicked} className="ghost button fas fa-pencil-alt"></i>
            }
          </div>
        </div>
        

        <ClubSchoolYearNav  />

        {React.cloneElement(children, {schoolYear: schoolYear, schoolYearLoading: schoolYearLoading})}

      </div>
    </>
  )

}



export default ClubSchoolYearLayout;