import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { DragDrop, StatusBar } from '@uppy/react'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'

import Modal from '../../../components/Modal';
import { API_URL } from '../../../api.js';
import { createTeam, updateTeam, deleteTeam } from 'redux/club/teamActions';


const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const TeamSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  grade: yup.string().required("Grade is required.").oneOf(["Pre-K", "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"]),
  avatar: yup.string(),
});

const TeamForm = ({currentTeam, toggleModal}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: TeamSchema
  });

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deleteTeamClicked = () => {
    setDeleteModalOpened(true);
  }

  useEffect(() => {
    return () => {
      setDeleteModalOpened(false);
    }
  }, []);

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [teamsResult, teamsLoading, teamsLoaded, teamsError, doFetchTeams, setTeamResults] = useApi();

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);


  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);


  const getAvatarURL = () => {
    return fileURL ? fileURL : currentTeam?.avatar_url
  }



  const onSubmit = async data => {
    let d = {
      team: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentTeam && currentTeam.id) {
      doFetch(updateTeam, {payload: d});
    }
    else {
      doFetch(createTeam, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  
  return (
    <Modal toggleModal={toggleModal}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentTeam ? 'Edit' : 'New'} Team</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">
          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentTeam?.id}
          />

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>Team Name</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentTeam?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>

            <label htmlFor="grade"> 
              <span>Grade</span>
              <select 
                ref={register}
                name="grade"
                defaultValue={currentTeam?.grade}
              >
                <option disabled selected>Select grade</option>
                {["Pre-K", "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"].map(grade => {
                  return (
                    <option value={grade}>{grade}</option>
                  )
                })}

              </select>
              {errors.grade && <div className="field-error">{errors.grade.message}</div>}
            </label>
          </div>


          <div className="margin-top-1 card no-hover uppy-dd-cont">
            <div className="margin-bottom-2">
              <img style={{height: '200px'}} src={getAvatarURL()} alt="" />
              {/* {uploadLoading ? (
                  <div className="flex-cont align-center justify-center" style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.6)', position: 'absolute'}}>
                    <div>
                      <div className="spinner"></div>
                    </div>
                    
                  </div>
                ) : (
                  <div style={{fontSize: '1rem'}} className="hover-overlay">
                    EDIT
                  </div>
                )
              } */}
            </div>
            
            <br/>

            <div className="button ghost">Edit Team Logo</div>

            <input ref={register} type="hidden" name="avatar" value={file} />
            <DragDrop
              className="andy"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: 'Drop here or %{browse}',
                  browse: 'browse'
                }
              }}
            />
        </div>
        <StatusBar uppy={uppy} />
        <div className="field-error">
          {errors?.avatar?.message}
        </div>


          

        </div>

        <div className="modal-footer align-center flex-cont">
          <input type="submit" value="Save" className="button" />
        </div>

      </form>
      

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={deleteModalOpened}>
          <div className="padding-1">
            Are you sure you want to delete #{currentTeam.name}? This cannot be undone.
          </div>
        </Modal>
      }
    </Modal>
  )
}

export default TeamForm;