import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';

import { getAthletes, getCoinTransactions, createAthlete, updateAthlete, deleteAthlete, getAthlete, acceptMembership, rejectMembership } from 'redux/club/athleteActions';
import { DateTime } from 'luxon';
import coinSVG from 'images/coin.svg';
import ChangeTeamForm from './ChangeTeamForm';
import ChangeBandForm from './ChangeBandForm';
import GiveCoinsForm from './GiveCoinsForm';
import { connect } from 'react-redux';

const isPositive = (str) => {
  if (!str) return false;

  const parsed = parseFloat(str);

  if (parsed === undefined || parsed === null || isNaN(parsed)) return false;

  return parsed >= 0;
}


export const ClubAthlete = ({currentClub}) => {

  const [athlete, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [coinTransactions, coinTransactionsLoading, coinTransactionsLoaded, coinTransactionsError, doCoinTransactionsFetch, setCoinTransactionsResult] = useApi(); 


  let { id } = useParams();

  useEffect(() => {
    fetchAthlete();
    fetchCoinTransactions();
  }, [])

  const fetchAthlete = () => {
    doFetch(getAthlete, {query: {id: id}});
  }

  const fetchCoinTransactions = () => {
    doCoinTransactionsFetch(getCoinTransactions, {query: {id: id}});
  }

  // const onSubmit = async data => {
  //   let d = {
  //     charity: {
  //       ...data
  //     }
  //   }
  // }

  const [teamModalOpened, setTeamModalOpened] = useState(false);
  const changeTeamClicked = () => {
    setTeamModalOpened(true);
  }
  const toggleTeamModal = (reload=false) => {

    setTeamModalOpened(o => !o);
    if (reload) {
      fetchAthlete();
    }
  }

  const [bandModalOpened, setBandModalOpened] = useState(false);
  const changeBandClicked = () => {
    setBandModalOpened(true);
  }
  const toggleBandModal = (reload=false) => {

    setBandModalOpened(o => !o);
    if (reload) {
      fetchAthlete();
    }
  }

  const [coinModalOpened, setCoinModalOpened] = useState(false);
  const changeCoinClicked = () => {
    setCoinModalOpened(true);
  }
  const toggleCoinModal = (reload=false) => {

    setCoinModalOpened(o => !o);
    if (reload) {
      fetchAthlete();
      fetchCoinTransactions();
    }
  }

  return (
    <>

      {loading &&
        <div className="spinner"></div>
      }

      {athlete &&
        <>  
          <table className="vertical">
            <tr>
              <th>Team</th>
              <td>
                <Link className="text-link margin-right-2" to={`/teams/${athlete.club_team_slug}`}>
                  {athlete.club_team_name}
                </Link>

                {currentClub?.current_user_role === "admin" || currentClub?.current_user_role === "club-admin" &&
                  <div onClick={changeTeamClicked} className="outline button">
                    Change
                  </div>
                }
              </td>
            </tr>
            <tr>
              <th>Parent</th>
              <td>{athlete.parent_name} - {athlete.parent_email}</td>
            </tr>
            <tr>
              <th>Hero Band</th>
              <td>
                {athlete.band &&
                  <div className="flex-cont align-center">
                    <Link className="text-link margin-right-1" to={`/hero-bands/${athlete.band.code}`}>
                      {athlete.band.code}
                    </Link>

                    <div className="bold margin-right-2 uppercase">
                      {athlete.band.status}
                    </div>

                    <div onClick={changeBandClicked} className="outline button">
                      Change
                    </div>
                  </div>
                  
                }
                {!athlete.band &&
                  <div className="flex-cont align-center">
                    <div className="margin-right-1">
                      No hero band assigned.
                    </div>
                    <div onClick={changeBandClicked} className="outline button">
                      Assign Band
                    </div>
                  </div>
                  
                }
              </td>
            </tr>
          </table>

          <div className="margin-top-3 card no-hover full">
            <h2 className="flex-cont align-center no-margin-vertical">
              Coin Ledger

              
            </h2>

            <div className="font-1-5 bold gold flex-cont align-center margin-bottom-2 margin-top-half">
              <img className="margin-right-half" style={{width: '1em'}} src={coinSVG} alt="" />
              <div>
                {athlete?.coins?.toLocaleString()}
              </div>
              <div onClick={changeCoinClicked} className="font-1 gold button outline margin-left-1">
                New Transaction
              </div>
            </div>

            {coinTransactionsLoading && <div className="spinner"></div> }
            {!coinTransactionsLoading && !coinTransactions &&
              <em>No coin transactions found.</em>
            }

            <table className="basic">
              {coinTransactions?.map(coinTransaction => {
                return (
                  <tr key={coinTransaction.id}>
                    <td className="fit nowrap">
                      {coinTransaction.created_at ? DateTime.fromISO(coinTransaction.created_at).toLocaleString(DateTime.DATETIME_SHORT) : "---"}
                    </td>
                    <td style={{color: `${isPositive(coinTransaction.coins_earned) ? 'green' : 'red'}`}} className="text-right fit nowrap bold">
                      {isPositive(coinTransaction.coins_earned) &&
                        <>+</>
                      }
                      {coinTransaction.coins_earned?.toLocaleString()}
                    </td>
                    <td className="gray fit nowrap text-right">
                      {coinTransaction.coins_before?.toLocaleString()}
                      
                    </td>
                    <td className="fit nowrap">
                      <i className="lightgray fas fa-long-arrow-alt-right"></i>
                    </td>
                    <td className="gray fit nowrap text-right">
                      {coinTransaction.coins_after?.toLocaleString()}
                    </td>
                    
                    <td className="fit nowrap">
                      {coinTransaction.description}
                    </td>
                    <td className="fit nowrap">
                      {coinTransaction.added_by_name}
                    </td>
                    <td></td>
                  </tr>
                )
              })}
            </table>

            
          </div>

          

          {/* <pre>
            {JSON.stringify(athlete, null, 2)}
          </pre> */}

          {teamModalOpened &&
            <ChangeTeamForm currentAthlete={athlete} toggleModal={toggleTeamModal} />
          }

          {bandModalOpened &&
            <ChangeBandForm currentAthlete={athlete} toggleModal={toggleBandModal} />
          }

          {coinModalOpened &&
            <GiveCoinsForm currentAthlete={athlete} toggleModal={toggleCoinModal} />
          }




        </>

      }
    </>
  )
}




function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(ClubAthlete);