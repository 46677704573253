
// import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';



const initialState = {
  globalErrors: [],
  racingModalOpened: false,

  raceStarted: false,
  raceStartedAt: Date.now(),

  intervalID: null,
  raceType: null,
  // Date.now().toString(),
};

const uiReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "ADD_GLOBAL_ERROR":
      return {
        ...state,
        globalErrors: [...state.globalErrors, action.msg]
      }
    case "REMOVE_GLOBAL_ERROR":
      return {
        ...state,
        globalErrors: state.globalErrors.filter( (val, i) => i !== action.i )
      }
    case "CLEAR_GLOBAL_ERRORS":
      return {
        ...state,
        globalErrors: []
      }
    case "TOGGLE_RACING_MODAL":
      return {
        ...state,
        racingModalOpened: !state.racingModalOpened
      }
    case "TOGGLE_START_RACE":
      return {
        ...state,
        raceStartedAt: state.raceStarted ? state.raceStartedAt : Date.now(),
        raceStarted: !state.raceStarted,
        raceType: action.raceType ? action.raceType : null,
        
      }
    case "UPDATE_RACE_START":
      return {
        ...state,
        raceStartedAt: Date.now()
      }
    case "SET_RACE_INTERVAL_ID":
      return {
        ...state,
        intervalID: action.id
      }
    case "CLEAR_RACE_INTERVAL_ID":
      return {
        ...state,
        intervalID: null,
      }
    default :
      return state
  }
}

export default uiReducer;