import { andyFetch } from '../../api';

export const getTracks = (params) => {
  return async (dispatch, getState) => {

    const url = "club/tracks";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTracks error", e);
    }
  }
};

export const getTrack = (params) => {

  return async (dispatch, getState) => {

    const url = `club/tracks/${params?.query?.id}`;


    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getTrack error.');
      }
    }
    catch (e) {
      console.log("getTrack error", e);
    }
  }
};


export const createTrack = (params) => {

  return async (dispatch, getState) => {

    const url = "club/tracks";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createTrack error.');
      }
    }
    catch (e) {
      console.log("createTrack error", e);
    }
  }
};


export const updateTrack = (params) => {

  return async (dispatch, getState) => {

    const url = `club/tracks/${params.payload.track.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateTrack error.');
      }
    }
    catch (e) {
      console.log("updateTrack error", e);
    }
  }
};


export const deleteTrack = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/tracks/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteTrack error.');
      }
    }
    catch (e) {
      console.log("deleteTrack error", e);
    }
  }
};

