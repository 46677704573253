import React, {useState, useEffect} from 'react'
import { getAwardAthletes } from '../../redux/athlete/awardAthleteActions';
import { getBadgeAthletes, getBadges } from '../../redux/athlete/badgeActions';
import { connect } from 'react-redux';
import { useApi } from '../../api';
import coinSVG from 'images/coin.svg';
import { DateTime } from 'luxon';

import { useOutletContext } from 'react-router-dom';

import Pagination from 'components/Pagination';

const ProfileAwards = ({}) => {

  const {setSelectedBadge, testAlert} = useOutletContext();

  const [page, setPage] = useState(1);

  const [awardAthletes, awardAthletesLoading, awardAthletesLoaded, awardAthletesError, doAwardAthletesFetch, setAwardAthletesResult, pagy] = useApi(); 

  const [badges, badgesLoading, badgesLoaded, badgesError, doBadgesFetch, setBadges] = useApi();

  const handlePageChange = (p) => {
    setPage(p);
  };
  
  useEffect(() => {
    fetchBadges();
  }, []);

  useEffect(() => {
    fetchAwardAthletes();
  }, [page]);

  
  const fetchBadges = () => {
    doBadgesFetch(getBadges);
  }; 

  const fetchAwardAthletes = () => {
    doAwardAthletesFetch(getAwardAthletes, {query: {page: page}});
  }






  return (
    <>
      <h1 className='mobile-center'>Awards</h1>

      <div className="card no-hover transform-test margin-bottom-2">
        <div className="flex-cont align-center bold font-1-25 blue margin-bottom-1">
          <i className="fas fa-certificate margin-right-half"></i>
          Badges
        </div>
        {badges?.map(badge => {
          // const badge = badgeHash.badge;
          // const badgeAthletes = badgeHash.badge_athletes;


          return(
            <div onClick={() => setSelectedBadge(badge)} className="badge2-cont padding-1">

              <div className="badge2 relative">
                <img src={badge.image_url} alt="" />
                {/* {badgeAthletes.length > 0 &&
                  <div className="count">
                    <i className='fas fa-times'></i>
                    <div className="count-number">
                      {badgeAthletes.length}
                    </div>
                  </div>
                } */}
              </div>

              <div className="blue bold">
                {badge.name}
              </div>
              <div>
                <i style={{marginRight: '4px'}} className='font-05 gray fas fa-times'></i>
                <span className="font-075 gray bold">{badge.count}</span>
              </div>
            </div>
          )
        })}
      </div>

      <div className="card no-hover transform-test">
        <div className="flex-cont align-center bold font-1-25 blue margin-bottom-2">
          <i className="fas fa-star margin-right-half"></i>
          Achievements
        </div>

        <div>
          <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        </div>
        
        <div className="overflow-scroll full">
          <table className="basic">
            {awardAthletes?.map(awardAthlete => {

              const award = awardAthlete.award;


              return (
                <tr key={awardAthlete.id}>
                  <td className="fit nowrap">

                    <div className="bold">
                      {award.name}
                    </div>
                    <div className="mobile-only">
                      {award.description}
                    </div>
                    <div className="font-075 gray mobile-only">
                      {DateTime.fromISO(awardAthlete.created_at).toLocaleString(DateTime.DATE_MED)}
                    </div>
                    
                  </td>

                  <td className="fit nowrap">
                    <img style={{height: '18px'}} src={awardAthlete?.badge?.image_url} />
                  </td>
                  <td className="fit nowrap">

                    <div className="flex-cont align-center">
                      <img style={{width: '16px'}} src={coinSVG} alt="" />

          
                      <div className="margin-left-half blue bold font-1">
                        +{award.coins}
                      </div>
                    
                      
                    </div>
                    
                  </td>
                  <td>
                    <div className="desktop-only">
                      {award.description}
                    </div>
                    
                  </td>
                  <td className="bold fit nowrap">
                    {DateTime.fromISO(awardAthlete.created_at).toLocaleString(DateTime.DATE_MED)}
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
        
      </div>


      {/* <div className="code">
        <pre>
          {JSON.stringify(awardAthletes, null, 2)}
        </pre>
      </div> */}
    </>
  );
}
 
export default ProfileAwards;