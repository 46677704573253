import React, { useState, useEffect } from 'react';
import { useOutletContext, useLocation, Link } from 'react-router-dom';
import { useApi } from '../../api';
import { getPledgeByCode } from '../../redux/user/pledgeActions';
import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import PledgeTable from './PledgeTable';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const PledgeThanks = () => {

  let query = useQuery();
  let pledgeCode = query.get("pledge_code");


  const [pledgePage] = useOutletContext();


  const [pledge, pledgeLoading, pledgeLoaded, pledgeError, doPledgeFetch, setPledgeResult] = useApi(); 

  useEffect(() => {
    if (pledgeCode) {
      
      fetchPledge();
    }
  }, [pledgeCode]);

  const fetchPledge = () => {
    doPledgeFetch(getPledgeByCode, {query: {pledge_code: pledgeCode}});
  }

  return (

    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>
        
        <div className="narrow relative">
          {pledgePage && pledge &&

            <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
              <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">

                <div className="blue font-3 bold-200 margin-bottom-2">
                  Thank you for your pledge!
                </div>

                <div className="bold font-1-25 margin-bottom-1">
                  We will email you with updates on {pledgePage.athlete_first_name}'s progress.
                </div>
                

                <div className="margin-bottom-3">
                  <Link to={`/pledge/progress/${pledge.code}`} className="button">
                    View Current Progress
                    <i className="font-1 fas fa-arrow-right margin-left-half"></i>
                  </Link>
                </div>
                

                <div className="rounded-box padding-2">
                  {pledgeLoading && <div className="spinner"></div> }
                  <PledgeTable pledge={pledge} />
                </div>

              </div>

              <PledgeSidebar showButton={false} pledgePage={pledgePage} isPreview={false} />
            </div>
          }

          {/* <div style={{marginTop: '100px'}} className="narrow center">

            <pre>
              {JSON.stringify(pledgeCode, null, 2)}
            </pre>

            <h1 className="font-4">
              Thank you for your pledge!
            </h1>


            <h2>We will email you with updates on {pledgePage.athlete_first_name}'s progress.</h2>


          </div> */}

        </div>

      </div>


    </>

 
  )
}

export default PledgeThanks;