import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { useApi } from '../api';
import { createUser } from 'redux/user/userActions';
import { connect } from 'react-redux';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';


const SignupSchema = yup.object().shape({
  email: yup.string().required("Email is required."),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),

});

const GetStarted = ({currentUser}) => {

  let navigate = useNavigate();

  const [meas, setMeas] = useState('metric');
  const [vvv, setVvv] = useState();

  const [type, setType] = useState(null);


  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: SignupSchema
  });

  const [loggedInUser, loading, loaded, error, doFetch, setResult] = useApi();


  const onSubmit = async data => {
    // const response = await Api(session, `${process.env.REACT_APP_API_URL}/signup`, {
    //   verb: 'post',
    //   params: data
    // })
    
    // session.loginUser(response);
    // navigate('/profile');
    // history.push("/profile");

    // alert('here 0')

    let d = {
      user: {
        ...data
      }
    }


    doFetch(createUser, {payload: d});

  }

  useEffect(() => {
    if (currentUser && loaded) {
      navigate(`/profile`);
      // history.push("/profile");
    }
  }, [loaded]);


  const typeClicked = (t) => {
    setType(t);
  }

  const continueClicked = () => {
    if (type === "club") {
      navigate(`club`)
    }
    else if (type === "parent") {
      navigate(`parent`)
    }
    
  }




  
  return(
    <div className="narrow">
      <h1 className="margin-top-2 center">Please select your account type.</h1>
      {/* <div className="margin-bottom-3 center gray">Step 1 of 3</div> */}

      <form onSubmit={handleSubmit(onSubmit)}>

        <div>

          <div className="flex-cont align-center center flex-wrap">

            <div className="margin-vertical-2 flex-1">
              <div onClick={() => typeClicked("parent")} className={`card pointer ${type === 'parent' ? 'selected' : ''}`}>
                <div className="font-3 bold">Parent</div>
              </div>
            </div>

            <div className="margin-vertical-2 gray bold margin-left-1 margin-right-1">
              OR
            </div>

            <div className="margin-vertical-2 flex-1">
              <div onClick={() => typeClicked("club")}  className={`card pointer ${type === 'club' ? 'selected' : ''}`}>
                <div className="font-3 bold">Club/School</div>
              </div>
            </div>

          </div>

          <div className="margin-top-3 center ">
            <button onClick={continueClicked} disabled={!type} className="button">Continue</button>
          </div>

          


        </div>

        {/* <div className="flex-cont">
          <label htmlFor="email"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Email"
              name="email"
              defaultValue={`${Math.random().toString(36).substring(7)}@example.com`}
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>
        </div>
        
        <div className="flex-cont">
          <label htmlFor="firstName"> 
            <input 
              type="text" 
              ref={register}
              placeholder="First Name"
              name="first_name" 
              defaultValue={Math.random().toString(36).substring(7)}
            />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="lastName"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Last Name"
              name="last_name"
              defaultValue={Math.random().toString(36).substring(7)}
            />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="password"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Password"
              name="password"
              defaultValue="111111"
            />
            {errors.password && <div className="field-error">{errors.password.message}</div>}
          </label>
          <label htmlFor="password_confirmation"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Retype Password"
              name="password_confirmation"
              defaultValue="111111"
            />
            {errors.password_confirmation && <div className="field-error">{errors.password_confirmation.message}</div>}
          </label>
        </div> */}


        {/* <input type="submit" value="Join" /> */}
      </form>
    </div>
    
  )
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(GetStarted);
