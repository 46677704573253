

import React, { useEffect, useState } from 'react';
import { getCurrentPledgeDrive } from '../../../redux/club/pledgeDriveActions';
import { useApi } from '../../../api';
import { Link } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import { AreaChart, ComposedChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {getMileTimes} from '../../../redux/club/statsActions';
import Chart from './Chart';
 


const ClubStatsHome = ({currentUser}) => {

  const [stats, loading, loaded, error, doFetch, setResult] = useApi(); 

  const [currentCampaign, currentCampaignLoading, currentCampaignLoaded, currentCampaignError, doCurrentCampaignFetch, setCurrentCampaignResult] = useApi();

  useEffect(() => {
    doCurrentCampaignFetch(getCurrentPledgeDrive);
    doFetch(getMileTimes);
  }, []);

  return (
    <>
      <div className="narrow-2x padding-top-1x">

        <h1 className="no-margin-bottom">Campaign Stats</h1>

        <div className="margin-top-half">
          {currentCampaignLoading && <div className="spinner"></div> }

          {currentCampaign &&
            <div>
              
              <div className="font-1-5">
                {currentCampaign?.name}
              </div>
              <div className="gray bold no-margin" >
                {DateTime.fromISO(currentCampaign.start_date).toLocaleString(DateTime.DATE_FULL)} - {DateTime.fromISO(currentCampaign.end_date).toLocaleString(DateTime.DATE_FULL)}
              </div>
            </div>
          }

        </div>

       

        {/* <div>
          <pre>
            {JSON.stringify(stats, null, 2)}
          </pre>
        </div> */}
        {currentCampaign &&
          <div className="margin-top-3">
            <div className="card no-hover">
              <div className="bold font-1-5">Timed Mile Progression</div>
              <div className="margin-top-3" style={{height: '300px',}}>
                <Chart mileData={stats} />
              </div>
            </div>
          </div>
        }

        

        
        


        

      

      </div>
    </>
  )
}


export default ClubStatsHome;