import React from 'react';


const MerchProductCard = ({imageUrl, merchProduct}) => {


  // if (!merchProduct) return null;


  return (
    <div className="card inline-block">

      <img style={{width: '200px'}} src={imageUrl || merchProduct?.featured_image_url} alt="" />
      
      <div className="bold">{merchProduct?.name}</div>
      <div>{merchProduct?.description}</div>
      <div>{parseFloat(merchProduct?.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>

      
    </div>
  )
}

export default MerchProductCard;