

import React, { useEffect } from 'react';





const TimerHome = ({currentUser}) => {

  return (
    <>
      <h1>Timer Dashboard</h1>
      <div>
        
      </div>
    </>
  )
}


export default TimerHome;