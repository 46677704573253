import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import store from 'redux/store';

import { resendInvite } from 'redux/user/userActions';

import { useApi } from '../api';

import Spinner from 'components/Spinner';
import SubmitButton from 'components/SubmitButton';

const queryString = require('query-string');



const ResendInviteSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
});

const NewPasswordSchema = yup.object().shape({
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
});


export const InviteSent = () => {

  return (
    <div className="center really-narrow">
      <h1>Invite Resent</h1>

      <div className="margin-top-1">An email has been sent with a link to complete your invitation.</div>
    </div>
  )
}
export const InviteNew = () => {


  return (
    <div className="center really-narrow">

    </div>
  )
}
export const InviteSuccess = () => {

  const navigate = useNavigate();

  return (
    <div className="center really-narrow">
      <h1>Success</h1>

      <div>
        Your password has been successfully changed. You can now login with your new password.
      </div>
      <div>
        <div onClick={() => navigate('/login')} className="margin-top-2 button">Login</div>
      </div>
    </div>
  )
}



const ResendInvite = ({currentUser}) => {

  const navigate = useNavigate();

  const { register, errors, handleSubmit } = useForm({
    validationSchema: ResendInviteSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [result2, loading2, loaded2, error2, doFetch2, setResult2] = useApi(); 

  let location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (currentUser) {
      navigate(`/`);
    }
  }, [])



  const tryResendInvite = async data => {
    doFetch(resendInvite, {query: {email: params.email}, payload: data});
  }

  useEffect(() => {
    if (loaded) {
      navigate(`/resend-invite/sent`);
    }
  }, [loaded, navigate, currentUser]);

  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }
  
  return(
    <div className="center really-narrow">
      <h1>Resend Invite</h1>
      {/* {loading &&
        <Spinner />
      }
      {loading2 &&
        <Spinner />
      } */}
      {/* <div>{JSON.stringify(currentUser)}</div> */}

      <div>
        <form onSubmit={handleSubmit(tryResendInvite)}>

          <div className="flex-cont">
            <label htmlFor="email"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Email"
                name="email"
              />
              {errors.email && <div className="field-error">{errors.email.message}</div>}
            </label>
          </div>


          <div className="margin-top-1 center">

            {/* <div>{JSON.stringify(errors)}</div> */}

            <button className="button" disabled={false || loading || hasErrors()} type="submit">
              {loading ? (
                <Spinner />
              ) : (
                <span>Submit</span>
              )
              }
              
            </button>
          </div>


        </form>
      </div>
    </div>
    
  )
};

const mapState = state => ({
  currentUser: state.sessionState.currentUser,
});

export default connect(mapState)(ResendInvite);