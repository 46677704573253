import React from 'react';

const centsToDollars = (cents) => {
  if (cents === undefined || cents === null || isNaN(parseFloat(cents))) {
    return 0.0
  }

  return parseFloat(cents) / 100.00

}

const PledgeTable = ({pledge}) => {

  return (
    <>
      {pledge &&
        <table className="vertical">
          <tr>
            <th>Pledge Code</th>
            <td>{pledge.code}</td>
          </tr>
          <tr>
            <th>Pledge Date</th>
            <td>{pledge.created_at}</td>
          </tr>
          <tr>
            <th>Your Name</th>
            <td>{pledge.pledger_name}</td>
          </tr>
          <tr>
            <th>Your Email</th>
            <td>{pledge.pledger_email}</td>
          </tr>
          <tr>
            <th>Student Name</th>
            <td>{pledge.athlete_name}</td>
          </tr>

          <tr>
            <th>Message</th>
            <td>{pledge.pledger_message}</td>
          </tr>
          <tr>
            <th>Pledge Per Mile</th>
            <td>{centsToDollars(pledge.pledger_cents_per_mile).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
          </tr>
          <tr>
            <th>Pledge Max</th>
            <td>{centsToDollars(pledge.pledger_max_cents).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
          </tr>
          <tr>
            <th>Current Card</th>
            <td>{pledge.card_brand?.toUpperCase()} **** {pledge.card_last4}</td>
          </tr>
        </table>
      }
    </>
  )
}

export default PledgeTable;