

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getUsers } from 'redux/admin/userActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';

import { createUser, updateUser, deleteUser, getUser } from 'redux/admin/userActions';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";

const UserSchema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  role: yup.string(),
});

const UserForm = ({toggleModal, currentUser}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: UserSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      user: {
        ...data
      }
    }
    if (currentUser && currentUser.id) {
      doFetch(updateUser, {payload: d});
    }
    else {
      doFetch(createUser, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentUser ? 'Edit' : 'New'} User</h2>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1 padding-bottom-200px">

        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentUser?.id}
        />

        <div className="flex-cont">
          <label htmlFor="first_name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="First Name"
              name="first_name"
              defaultValue={currentUser?.first_name}
            />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="last_name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Last Name"
              name="last_name"
              defaultValue={currentUser?.last_name}
            />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="email"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Email"
              name="email"
              defaultValue={currentUser?.email}
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="role"> 
            
            <select name="role" ref={register} defaultValue={currentUser?.role}>
              {["customer", "club", "timer", "admin"].map(role => 
                <option  value={role}>{role}</option>
              )}
            </select>

            {errors.role && <div className="field-error">{errors.role.message}</div>}
          </label>
        </div>
        
      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminUsers = ({currentUser}) => {

  let { id } = useParams();

  const [paramsId, setParamsId] = useState(id);


  const [users, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedUser, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedUser] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchUsers();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingUser(null);
    }
  }, [modalOpen])

  useEffect(() => {

    fetchUsers();
    
  }, [id]);

  const fetchUsers = () => {
    doFetch(getUsers, {query: {tab: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchUsers();
    }
  }


  const editClicked = user => {
    setEditingUser(user);
    toggleModal(false);
  }

  const deleteClicked = user => {
    setDeletingUser(user);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingUser) {
      doDeleteFetch(deleteUser, {query: {id: deletingUser.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  

  const changeTab = (tab) => {
    // setSearchParams({ q: tab });
  }

  
  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <UserForm currentUser={editingUser} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <h1 className="flex-1">
          Users
        </h1>
        <div>
          <div onClick={() => toggleModal(false)} className="button">New User</div>
        </div>
        
      </div>
      

      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink to={`/users`}>All</NavLink></li>
          <li><NavLink to={`/users/customers`}>Customers</NavLink></li>
          <li><NavLink to={`/users/clubs`}>Clubs</NavLink></li>
          <li><NavLink to={`/users/timers`}>Timers</NavLink></li>
          <li><NavLink to={`/users/admin`}>Admin</NavLink></li>
        </ul>
      </div>



{/* 
      <div className="padding-1 margin-bottom-2 flex-cont align-center profile-nav">
        <div className="nav-item margin-right-1">
          <Link to="/admin/users">All</Link>
        </div>
        <div className="nav-item">
          <Link to="/admin/users/needs-account">Needs Account</Link>
        </div>
      </div> */}

      

      <div className="margin-bottom-2"></div>



      {loading &&
        <div className="spinner"></div>
      }

      {users &&
        <table className="basic">
          {users.map(user => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div className="ghost button">
                    <Link to={`/users/view/${user.id}`}><i className="fas fa-eye"></i></Link>

                    
                  </div>
                </td>
                <td className="bold fit nowrap">{user.full_name}</td>
                <td>{user.email}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(user)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(user)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete User</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingUser?.full_name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !users &&
        <em>No users.</em>
      }


      


    </>
  )
}


export const AdminUser = () => {

  const [user, loading, loaded, error, doFetch, setResult] = useApi(); 

  let { id } = useParams();

  useEffect(() => {
    fetchUser();
  }, [])

  const fetchUser = () => {
    doFetch(getUser, {query: {id: id}});
  }

  // const onSubmit = async data => {
  //   let d = {
  //     charity: {
  //       ...data
  //     }
  //   }
  // }

  return (
    <>
      {loading &&
        <div className="spinner"></div>
      }

      {user &&
        <>  
          <h1>{user.full_name}</h1>


          <h2>Pledge Drive Subscriptions</h2>

          <div className="margin-bottom-1"></div>

          
          {user.pledge_drive_subs &&
            <table className="basic">
              {user.pledge_drive_subs.map(sub => 
                <tr>
                  <td className="fit nowrap">{sub.club_name}</td>
                  <td className="fit nowrap">{sub.charity_name}</td>
                  <td className="fit nowrap">{sub.pledge_drive_name}</td>
                  <td className="">{sub.code}</td>
                </tr>
              )}
            </table>
          }

          {!user.pledge_drive_subs &&
            <em>No pledge drive subscriptions.</em>
          }


        </>

      }
    </>
  )
}


export default AdminUsers;