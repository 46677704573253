import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';

import Modal from '../../../components/Modal';
import { API_URL } from '../../../api.js';
import { createBand, updateBand, deleteBand } from 'redux/club/bandActions';



const BandForm = ({currentBand, toggleModal}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
  });

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deleteBandClicked = () => {
    setDeleteModalOpened(true);
  }

  useEffect(() => {
    return () => {
      setDeleteModalOpened(false);
    }
  }, []);

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [bandsResult, bandsLoading, bandsLoaded, bandsError, doFetchBands, setBandResults] = useApi();

  useEffect(() => {
  
  }, []);

  const onSubmit = async data => {
    let d = {
      band: {
        ...data
      }
    }
    if (currentBand && currentBand.id) {
      doFetch(updateBand, {payload: d});
    }
    else {
      doFetch(createBand, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  
  return (
    <Modal toggleModal={toggleModal}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentBand ? 'Edit' : 'New'} Band</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">
          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentBand?.id}
          />

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>Code (printed on band)</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Code"
                name="code"
                defaultValue={currentBand?.code}
              />
              {errors.code && <div className="field-error">{errors.code.message}</div>}
            </label>

            <label htmlFor="name"> 
              <span>EPC</span>
              <input 
                type="text" 
                ref={register}
                placeholder="EPC"
                name="epc"
                defaultValue={currentBand?.epc}
              />
              {errors.epc && <div className="field-error">{errors.epc.message}</div>}
            </label>


          </div>
        </div>

        <div className="modal-footer align-center flex-cont">
          <input type="submit" value="Save" className="button" />
        </div>

      </form>
      

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={deleteModalOpened}>
          <div className="padding-1">
            Are you sure you want to delete #{currentBand.code}? This cannot be undone.
          </div>
        </Modal>
      }
    </Modal>
  )
}

export default BandForm;