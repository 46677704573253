

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getPledgeDrives } from 'redux/club/pledgeDriveActions';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import PledgeDriveForm from './PledgeDriveForm';
import { DateTime } from 'luxon';


const ClubPledgeDrives = ({currentUser}) => {

  const [pledgeDrives, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchPledgeDrives();
  }, []);

  const fetchPledgeDrives = () => {
    doFetch(getPledgeDrives);
  }

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPledgeDrives();
    }
  }

  return (
    <>
      <div className="padding-top-1">

      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <PledgeDriveForm currentPledgeDrive={null} toggleModal={toggleModal} />
        </Modal>
      }

      <div className="flex-cont">
        <div className="flex-1">
          <h1>Campaigns</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Campaign</div>
        </div>
      </div>
    

      {loading &&
        <div className="spinner"></div>
      }

      {pledgeDrives &&
        <table className="basic">
          {pledgeDrives.map(pledgeDrive => {
            return (
              <tr>
                <td className="bold fit nowrap">
                  <Link to={`/campaigns/${pledgeDrive.id}`} className="text-link">
                    {pledgeDrive.name}
                  </Link>
                </td>
                <td className="fit nowrap">
                  {pledgeDrive.charity_name}
                </td>
                <td className={`fit nowrap ${pledgeDrive.active ? "bold" : "gray"}`}>
                  {pledgeDrive.active ? "ACTIVE" : "NOT ACTIVE"}
                </td>
                <td className="fit nowrap">
                  {DateTime.fromISO(pledgeDrive.start_date)?.toLocaleString(DateTime.DATE_SHORT)} - {DateTime.fromISO(pledgeDrive.end_date)?.toLocaleString(DateTime.DATE_SHORT)} 
                </td>
                <td className="fit nowrap">
                  {(parseFloat(pledgeDrive.cents_pledged)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  <span className="gray"> pledged</span>
                </td>
                <td className="fit nowrap">
                  {(parseFloat(pledgeDrive.cents_earned)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  <span className="gray"> earned</span>
                </td>
                <td>

                </td>
              </tr>
            )
          })}
        </table>
      }

      {!loading && (!pledgeDrives || pledgeDrives.length === 0) &&
        <em>No campaigns added.</em>
      }

      </div>
    </>
  )
}


export default ClubPledgeDrives;