

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getPledges } from 'redux/club/pledgeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import { connect } from 'react-redux';
import { createStripeAccount, createAccountLink, getAccount } from 'redux/club/clubActions';
import store from 'redux/store';
import Pledge from 'screens/pledge/Pledge';


const PledgeSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipcode: yup.string().required("Zipcode is required."),
  id: yup.string().nullable(),
  lap_distance: yup.number().required("Lap distance is required."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});





const ClubPledge = ({currentClub}) => {

  const [club, loading, loaded, error, doFetch, setResult] = useApi(); 

  const tryCreateStripeAccount = () => {
    doFetch(createStripeAccount);
  };

  const [accountLink, accountLinkLoading, accountLinkLoaded, accountLinkError, doAccountLinkFetch, setAccountLinkResult] = useApi(); 

  const tryCreateAccountLink = () => {
    doAccountLinkFetch(createAccountLink);
  };

  const [account, accountLoading, accountLoaded, accountError, doAccountFetch, setAccountResult] = useApi(); 

  useEffect(() => {

    if (!currentClub) {
      return
    }

    if (currentClub.stripe_account_id) {
      doAccountFetch(getAccount);
      doAccountLinkFetch(createAccountLink);
    }
    else {
      doFetch(createStripeAccount);
    }
  }, [currentClub]);

  const [somethingLoading, setSomethingLoading] = useState(false);

  useEffect(() => {
    setSomethingLoading(loading || accountLoading || accountLinkLoading)
  }, [loading, accountLoading, accountLinkLoading])


  const [status, setStatus] = useState(null);


  useEffect(() => {
    if (!currentClub?.stripe_account_id) {
      return
    }

    if (!account) {
      setStatus("no account");
    }
    else {
      if (account.charges_enabled) {
        setStatus("enabled");
      }
      else if (account.details_submitted) {
        setStatus("submitted");
      }
      else {
        setStatus("new");
      }
      
    }


  }, [account, currentClub])






  return (
    <>
      <div className="narrow-2 padding-top-1">


        <Pledge />

      </div>
    </>
  )
}



function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(ClubPledge);