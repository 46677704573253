

import React, { useEffect, useState } from 'react';

import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { Link } from 'react-router-dom';

import PeTeacherLayout from './peTeacher/PeTeacherLayout';


const ClubLayout = ({currentClub, currentUser, children}) => {

  const logoutClicked = async () => {
    await store.dispatch(logoutUser());
    navigate("/");
  }

  // const navigate = useNavigate("/");
  const navigate = useNavigate();

  
  const [newCurrentUser, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    if (currentUser) {
      fetchCurrentUser();
    }  
  }, []);

  const fetchCurrentUser = () => {
    doFetch(reupdateUser);
  };

  useEffect(() => {
    if (loaded) {

      if (!newCurrentUser || !["admin", "club"].includes(currentUser.role)) {
        navigate('/');
      }
    }
    
  }, [loaded]);


  const [sideNavOpened, setSideNavOpened] = useState(false);

  const toggleSideNav = () => {
    setSideNavOpened(o => !o);
  }

  const [warningOpen, setWarningOpen] = useState(true);

  const closeWarning = () => {
    setWarningOpen(false);
  }





  if (loading) {
    return (
      <div className="padding-1 center flex-cont justify-center">
        <div className="spinner"></div> 
      </div>
    );
  }

  if (currentClub && currentClub.status === "pending") {
    return (
      <>
        <div className="narrow padding-top-2">
          <div className="center card no-hover">
            <div className="bold font-2">Your application for {currentClub.name} is pending.</div>
            <div className="margin-top-1 font-1-5">You will receive updates at {currentClub.email} when your application status changes.</div>

          </div>

          <div className="center margin-top-1">
            <div onClick={logoutClicked} className="ghost button">LOGOUT</div>
          </div>
        </div>
      </>
    )
  }

  if (currentClub && currentClub.status === "rejected") {
    return (
      <>
        <div className="narrow padding-top-2">
          <div className="center card no-hover">
            <div className="bold font-2">Your application for {currentClub.name} has been rejected.</div>
          </div>

          <div className="center margin-top-1">
            <div onClick={logoutClicked} className="ghost button">LOGOUT</div>
          </div>
        </div>
      </>
    )
  }



  return (

    <>
    
    <ClubBottomNav />

    {loaded &&
      <div className="parent">
        <div className="content-cont flex-cont">


          {currentClub && currentClub.onboarding_step === "done" && !currentClub.has_current_campaign && warningOpen &&
            <div className="flex-cont justify-center" style={{zIndex: '500', position: 'fixed', bottom: '50px', right: '30px'}}>
              <div style={{backgroundColor: 'rgba(0,0,0,.9)', color: 'white'}} className="shadow  no-hover card">
                You currently do not have an active campaign set up. 
                
                <Link to="/campaigns" className="margin-left-2 button">
                  Manage Campaigns
                </Link>
              </div>

              <div onClick={closeWarning} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', background: 'white', fontSize: '40px', width: '1em', height: '1em', position: 'relative', right: '-.33em', top: '-.33em'}}>
                <i  style={{marginTop: '.1em', fontSize: '.5em', position: 'relative'}} className="fas fa-times blue"></i>
              </div>
            </div>
          }

          <div className="narrow-2 content margin">
            <Outlet />
          </div>

        </div>
      </div>
    }

    </>
  )
}
  

function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ClubLayout);