

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getTracks } from 'redux/club/trackActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Modal from 'components/Modal';

import { createTrack, updateTrack, deleteTrack } from 'redux/club/trackActions';

const TrackSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipcode: yup.string().required("Zipcode is required."),
  id: yup.string().nullable(),
  how_many_laps: yup.number().required("Number of laps is required."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});


const TrackForm = ({toggleModal, currentTrack}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: TrackSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [tracksResult, tracksLoading, tracksLoaded, tracksError, doFetchTracks, setTrackResults] = useApi();

  const onSubmit = async data => {
    let d = {
      track: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentTrack && currentTrack.id) {
      doFetch(updateTrack, {payload: d});
    }
    else {
      doFetch(createTrack, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentTrack ? 'Edit' : 'New'} Track</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentTrack?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentTrack?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="street"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Street"
                name="street"
                defaultValue={currentTrack?.street}
              />
              {errors.street && <div className="field-error">{errors.street.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="city"> 
              <input 
                type="text" 
                ref={register}
                placeholder="City"
                name="city"
                defaultValue={currentTrack?.city}
              />
              {errors.city && <div className="field-error">{errors.city.message}</div>}
            </label>
            <label htmlFor="state"> 
              <input 
                type="text" 
                ref={register}
                placeholder="State"
                name="state"
                defaultValue={currentTrack?.state}
              />
              {errors.state && <div className="field-error">{errors.state.message}</div>}
            </label>
            <label htmlFor="zipcode"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Zipcode"
                name="zipcode"
                defaultValue={currentTrack?.zipcode}
              />
              {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="how_many_laps"> 
              <input 
                type="text" 
                ref={register}
                placeholder="How many laps equals a mile?"
                name="how_many_laps"
                defaultValue={currentTrack?.how_many_laps}
              />
              <span className="margin-left-1">laps</span>
              {errors.how_many_laps && <div className="field-error">{errors.how_many_laps.message}</div>}
            </label>
          </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const ClubCommunication = ({currentUser, currentClub}) => {

  const [tracks, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedTrack, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedTrack] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingTrack, setEditingTrack] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchTracks();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingTrack(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!tracks) {
      fetchTracks();
    }
  }, []);

  const fetchTracks = () => {
    doFetch(getTracks);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingTrack, setDeletingTrack] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchTracks();
    }
  }


  const editClicked = track => {
    setEditingTrack(track);
    toggleModal();
  }

  const deleteClicked = track => {
    setDeletingTrack(track);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingTrack) {
      doDeleteFetch(deleteTrack, {query: {id: deletingTrack.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      <div className="padding-top-1 narrow-2">
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <TrackForm currentTrack={editingTrack} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Communication</h1>
        </div>
        <div>
          {/* <div onClick={toggleModal} className="button">New Track</div> */}
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      <div className="overflow-hidden card box" style={{backgroundPosition: 'center', height: '300px', width: '500px', backgroundImage: 'url("https://fyzeak.s3-us-west-1.amazonaws.com/120158874_3537678446289436_7216831924879481392_o.jpg")'}}>

        <div className="bg-color"></div>



        <div className="full-height relative center flex-cont column justify-end">
          <div className="full">
            <div className="avatar-cont medium" style={{backgroundImage: `url(${currentClub?.avatar_url})`}}></div>
            <div className="bebas white-text bold font-2">
              SUPPORT BRAYDEN AT MARANTHA
            </div>
            <div className="bebas white-text bold font-4">
              MAKE A PLEDGE NOW
            </div>
          </div>
        </div>
 
      </div>  

      

      </div>  
    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(ClubCommunication);