import { andyFetch } from '../../api';

export const getAwardRuleLaps = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/award_rule_laps";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAwardRuleLaps error", e);
    }
  }
};

export const getAwardRuleLap = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/award_rule_laps/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAwardRuleLap error", e);
    }
  }
};



export const createAwardRuleLap = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/award_rule_laps";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createAwardRuleLap error.');
      }
    }
    catch (e) {
      console.log("createAwardRuleLap error", e);
    }
  }
};


export const updateAwardRuleLap = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/award_rule_laps/${params.payload.award_rule_lap.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAwardRuleLap error.');
      }
    }
    catch (e) {
      console.log("updateAwardRuleLap error", e);
    }
  }
};


export const deleteAwardRuleLap = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/award_rule_laps/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAwardRuleLap error.');
      }
    }
    catch (e) {
      console.log("deleteAwardRuleLap error", e);
    }
  }
};

