import { andyFetch } from '../../api';

export const getPledgeDriveSubs = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/pledge_drive_subs";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDriveSubs error", e);
    }
  }
};


export const createPledgeDriveSub = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/pledge_drive_subs";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPledgeDriveSub error.');
      }
    }
    catch (e) {
      console.log("createPledgeDriveSub error", e);
    }
  }
};


export const updatePledgeDriveSub = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/pledge_drive_subs/${params.payload.pledge_drive_sub.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePledgeDriveSub error.');
      }
    }
    catch (e) {
      console.log("updatePledgeDriveSub error", e);
      return
    }
  }
};


export const deletePledgeDriveSub = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/pledge_drive_subs/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePledgeDriveSub error.');
      }
    }
    catch (e) {
      console.log("deletePledgeDriveSub error", e);
    }
  }
};

