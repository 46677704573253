import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getPledgeDrive, deletePledgeDrive } from 'redux/club/pledgeDriveActions';
import ClubPledgeDriveNav from './ClubPledgeDriveNav';

import PledgeDriveForm from './PledgeDriveForm';

const ClubPledgeDriveLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchPledgeDrive()
    }
  }, [id]);

  const [pledgeDrive, pledgeDriveLoading, pledgeDriveLoaded, pledgeDriveError, doFetchPledgeDrive, setPledgeDrive] = useApi();
  const [deletedPledgeDrive, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledgeDrive] = useApi(); 



  const fetchPledgeDrive = () => {
    doFetchPledgeDrive(getPledgeDrive, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editPledgeDriveClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchPledgeDrive();
    }
  } 

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPledgeDrive();
    }
  }

  const deleteClicked = pledgeDrive => {
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (pledgeDrive) {
      doDeleteFetch(deletePledgeDrive, {query: {id: pledgeDrive.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);


  return (
    <>
      {pledgeDrive && editModalOpened &&
        <PledgeDriveForm toggleModal={() => toggleEditModal(true)} currentPledgeDrive={pledgeDrive} />
      }
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {pledgeDriveLoading && <div className="loading"></div> }
          <h1>
            {pledgeDrive?.name}
          </h1>

          <div className="flex-1"></div>

          <div>
            <i onClick={editPledgeDriveClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubPledgeDriveNav id={id} />

        {/* {children} */}
        {React.cloneElement(children, {fetchPledgeDrive: fetchPledgeDrive, pledgeDrive: pledgeDrive})}

        {/* <div className="code">
          <pre>
            {JSON.stringify(pledgeDrive, null, 2)}
          </pre>
        </div> */}


      </div>
    </>
  )

}



export default ClubPledgeDriveLayout;