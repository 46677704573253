import { andyFetch } from '../../api';

export const getInspos = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/inspos";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getInspos error", e);
    }
  }
};



export const createInspo = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/inspos";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createInspo error.');
      }
    }
    catch (e) {
      console.log("createInspo error", e);
    }
  }
};


export const updateInspo = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/inspos/${params.payload.inspo.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateInspo error.');
      }
    }
    catch (e) {
      console.log("updateInspo error", e);
    }
  }
};


export const deleteInspo = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/inspos/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteInspo error.');
      }
    }
    catch (e) {
      console.log("deleteInspo error", e);
    }
  }
};

