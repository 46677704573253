


const initialState = {
  currentClub: null,
  currentTeam: null,
};

const clubReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "SET_CURRENT_CLUB":
      return {
        ...state,
        currentClub: action.club,
      }
    case "SET_CURRENT_TEAM":
      return {
        ...state,
        currentTeam: action.team,
      }
    default :
      return state
  }
}

export default clubReducer;