import { andyFetch } from '../../api';

export const setCurrentAthlete = (params) => {
  return dispatch => {
    dispatch({
      type: 'SET_CURRENT_ATHLETE', 
      currentAthlete: params
    })
  }
}
export const unsetCurrentAthlete = () => {
  return dispatch => {
    dispatch({
      type: 'UNSET_CURRENT_ATHLETE'
    })
  }
}


export const unauthParent = () => {
  return dispatch => {
    localStorage.removeItem('parentAuthed');


    dispatch({
      type: 'UNAUTH_PARENT'
    })
  }
}

export const authParent = (params) => {
  return async (dispatch, getState) => {

    const url = "auth/auth_parent";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        const { data } = json;

        localStorage.setItem('parentAuthed', 'true');
        dispatch({ type: 'AUTH_PARENT'});
        return data;
      } else {

        localStorage.removeItem('parentAuthed');
        dispatch({ type: 'UNAUTH_PARENT' });
        throw new Error('Auth parent error.');
      }
    }
    catch (e) {
      console.log("authParent error", e);
    }
    
  }
};

export const reupdateAthlete = (params) => {
  return async (dispatch, getState) => {

    const url = "auth/update_athlete";

    try {

      const response = await andyFetch(url, params, "GET");

      if (response.ok) {


        const json = await response.json();



        const { authToken, data } = json;

  
        if (!data) {
          dispatch({ type: 'UNSET_CURRENT_ATHLETE' });
          throw new Error('Invalid athlete.');
        }


  
        localStorage.setItem('currentAthlete', JSON.stringify(data));
        dispatch({ type: 'SET_CURRENT_ATHLETE', currentAthlete: data});


        return data;
      } else {
        dispatch({ type: 'UNSET_CURRENT_ATHLETE' });
        throw new Error('Change athlete error.');
      }
    }
    catch (e) {
      console.log("changeAthlete error", e);
    }
    
  }
};



export const changeAthlete = (params) => {
  return async (dispatch, getState) => {

    const url = "auth/change_athlete";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {

        const json = await response.json();
        const { authToken, data } = json;
  
        if (!data) {
          dispatch({ type: 'UNSET_CURRENT_ATHLETE' });
          throw new Error('Invalid athlete.');
        }

        // dispatch({
        //   type: 'SET_NOTI_POPUPS', 
        //   notis: data.popup_notis
        // });
        dispatch({
          type: 'SET_NOTI_LISTABLES', 
          readNotiListables: data.read_listable_notis,
          unreadNotiListables: data.unread_listable_notis,
        });
  
        localStorage.setItem('currentAthlete', JSON.stringify(data));
        dispatch({ type: 'SET_CURRENT_ATHLETE', currentAthlete: data});

        // alert(JSON.stringify(data, null, 2))

        return data;
      } else {
        dispatch({ type: 'UNSET_CURRENT_ATHLETE' });
        throw new Error('Change athlete error.');
      }
    }
    catch (e) {
      console.log("changeAthlete error", e);
    }
    
  }
};



export const updateCurrentUser = (params) => {
  return dispatch => {
    dispatch({
      type: 'SET_CURRENT_USER', 
      currentUser: params
    })
  }
}


export const reupdateUser = (params) => {
  return async (dispatch, getState) => {

    const url = "auth/update_user";

    // alert('0')

    try {
      // alert('1')
      const response = await andyFetch(url, params, "GET");

      console.log("RESPONSE 0", response);
      if (response.ok) {
        // alert('2')
        const json = await response.json();
        const { authToken, data } = json;
  
        if (!data) {
          // alert('3')
          // dispatch({ type: 'UNSET_CURRENT_USER' });
          throw new Error('Invalid user.');
        }

        // dispatch({
        //   type: 'SET_NOTI_POPUPS', 
        //   notiPopups: data.popup_notis
        // });

        
        dispatch({
          type: 'SET_NOTI_LISTABLES', 
          readNotListables: data.read_listable_notis,
          unreadNotiListables: data.unread_listable_notis,
        });

        // alert('4')
  
        localStorage.setItem('currentUser', JSON.stringify(data));
        localStorage.setItem('authToken', JSON.stringify(authToken));
        dispatch({ type: 'SET_CURRENT_USER', currentUser: data, authToken: authToken});

        return data;
      } else {
        // alert('5')
        // dispatch({ type: 'UNSET_CURRENT_USER' });
        throw new Error('Login error.');
      }
    }
    catch (e) {
      // alert('06')
      // dispatch({ type: 'UNSET_CURRENT_USER' });
      console.log("loginUser error", e);
    }
    
    // dispatch({
    //   type: "ADD_ERRORS",
    //   errors: errors
    // })
  }
};




export const loginUser = (params) => {
  return async (dispatch, getState) => {

    const url = "auth/login";

    try {
      // alert(JSON.stringify(params));
      const response = await andyFetch(url, params, "POST");
      // alert('here 0');

      console.log("RESPONSE 0", response);
      if (response.ok) {
        // alert('here 1');
        const json = await response.json();
        const { authToken, data } = json;
  
        if (!data) {
          dispatch({ type: 'UNSET_CURRENT_USER' });
          throw new Error('Invalid user.');
        }

        // dispatch({
        //   type: 'SET_NOTI_POPUPS', 
        //   notis: data.popup_notis
        // });
        dispatch({
          type: 'SET_NOTI_LISTABLES', 
          readNotiListables: data.read_listable_notis,
          unreadNotiListables: data.unread_listable_notis,
        });
  
        localStorage.setItem('currentUser', JSON.stringify(data));
        localStorage.setItem('authToken', JSON.stringify(authToken));
        dispatch({ type: 'SET_CURRENT_USER', currentUser: data, authToken: authToken});

        // dispatch(hideFullscreenLoading());

        return data;
      } else {
        dispatch({ type: 'UNSET_CURRENT_USER' });
        // dispatch(hideFullscreenLoading());

        // alert('here 2');
        throw new Error('Login error.');
      }
    }
    catch (e) {
      console.log("loginUser error", e);
    }
    
    // dispatch({
    //   type: "ADD_ERRORS",
    //   errors: errors
    // })
  }
};


export const logoutUser = () => {
  return async (dispatch, getState) => {

    const url = "auth/logout";

    try {
      const response = await andyFetch(url, {}, "POST");

      if (response.ok) {
        dispatch({ type: 'UNSET_CURRENT_USER' });
        localStorage.setItem('currentUser', null);
        localStorage.setItem('authToken', null);
      } else {
        dispatch({ type: 'UNSET_CURRENT_USER' });
        dispatch({ type: 'UNSET_CURRENT_ATHLETE' });
        localStorage.setItem('currentUser', null);
        localStorage.setItem('authToken', null);



        throw new Error('Logout error.');
      }
    }
    catch (e) {
      console.log("logoutUser error", e);
    }
    

  }
};


