import { andyFetch } from '../../api';

export const deleteLap = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/laps/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteRace error.');
      }
    }
    catch (e) {
      console.log("deleteRace error", e);
    }
  }
};