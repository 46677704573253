

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getClubEvents } from 'redux/club/clubEventActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { API_URL } from '../../../api.js';
import Modal from 'components/Modal';

import { Link } from 'react-router-dom';

import { createClubEvent, updateClubEvent, deleteClubEvent } from 'redux/club/clubEventActions';
import { getRunThemes } from 'redux/club/runThemeActions';
import { getTeams } from 'redux/club/teamActions';
import { getMonitorUsers } from 'redux/club/userActions';
import { DateTime, Settings } from 'luxon'




const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})




const ClubEventForm = ({toggleModal, currentClubEvent}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    // validationSchema: EventSchema
  });



  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [clubEventsResult, clubEventsLoading, clubEventsLoaded, clubEventsError, doFetchEvents, setEventResults] = useApi();
  const [deletedEvent, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedEvent] = useApi(); 
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [monitorUsers, monitorUsersLoading, monitorUsersLoaded, monitorUsersError, doFetchMonitorUsers, setMonitorUserResults] = useApi();
  const [teams, teamsLoading, teamsLoaded, teamsError, doFetchTeams, setTeamsResults] = useApi();
  const [runThemes, runThemesLoading, runThemesLoaded, runThemesError, doFetchRunThemes, setRunThemesResults] = useApi();

  const [selectedEventType, setSelectedEventType] = useState(currentClubEvent?.kind || null);
  const watchedTheme = watch('run_theme_id');



  const getTheme = (themeID) => {
    if (themeID == null || !runThemes || runThemes.length === 0) return null;

    return runThemes.find(runTheme => runTheme.id?.toString() === themeID?.toString())
  }

  useEffect(() => {

    if (watchedTheme) {

      const theme = getTheme(watchedTheme);

      if (theme) {
        setValue("title", theme.name);
        setValue("description", theme.description);
      }

      
    }
    else {
      // setValue("title", null);
      // setValue("description", null);
    }
    
  }, [watchedTheme])

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);
  }
  const deleteClicked = () => {
    // setDeletingEvent(currentClubEvent);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (currentClubEvent) {

      doDeleteFetch(deleteClubEvent, {payload: {id: currentClubEvent.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
      toggleModal(true);
    }
  }, [deleteLoaded]);

  useEffect(() => {
    fetchMonitorUsers();
    fetchTeams();
    fetchRunThemes();
  }, []);

  const fetchMonitorUsers = () => {
    doFetchMonitorUsers(getMonitorUsers);
  }
  const fetchTeams = () => {
    doFetchTeams(getTeams);
  }
  const fetchRunThemes = () => {
    doFetchRunThemes(getRunThemes);
  }


  const onSubmit = async data => {


    let d = {
      club_event: {
        ...data
      }
    }


    // return 

    if (currentClubEvent && currentClubEvent.id) {
      doFetch(updateClubEvent, {payload: d});
    }
    else {
      doFetch(createClubEvent, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const existingTeamIDs = currentClubEvent?.club_event_teams?.map(clt => clt.team_id) || [];

  const [teamIDs, setTeamIDs] = useState(existingTeamIDs);

  const teamClicked = teamID => {
    setTeamIDs(o => {
      if (o.includes(teamID)) {
        return o.filter(id => id !== teamID);
      }
      else {
        return [...o, teamID];
      }
    });
  }

  

  const eventTypeClicked = type => {
    setSelectedEventType(type)
  }

  useEffect(() => {
    setValue("kind", selectedEventType);
  }, [selectedEventType]);

  useEffect(() => {
    
    if (runThemesLoaded && currentClubEvent) {
      setValue("kind", currentClubEvent?.kind);
      setValue("run_theme_id", currentClubEvent?.run_theme_id);
    }
  }, [currentClubEvent, runThemesLoaded]);

  const initialStartDate = DateTime.fromISO(currentClubEvent?.start_date).toISODate();
  const initialStartTime = DateTime.fromISO(currentClubEvent?.start_date)
    .toISOTime({includeOffset: false, includePrefix: false, suppressSeconds: true, suppressMilliseconds: true});

  const initialEndDate = DateTime.fromISO(currentClubEvent?.end_date).toISODate();
  const initialEndTime = DateTime.fromISO(currentClubEvent?.end_date)
    .toISOTime({includeOffset: false, includePrefix: false, suppressSeconds: true, suppressMilliseconds: true});

    
  const toggleSelectAll = () => {
    if (!teams?.length) return;

    if (teamIDs.length) {
      setTeamIDs([]);
    }
    else {
      setTeamIDs(teams.map(t => t.id));
    }
  }

  const getDefaultThemeSelectValue = () => {

    const watchedID = getTheme(watchedTheme)?.id
    if (watchedID != null) return watchedID;

    return currentClubEvent?.run_theme_id;
  }

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentClubEvent ? 'Edit' : 'New'} Event</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        {/* <pre>
          {JSON.stringify(currentClubEvent, null, 2)}
        </pre> */}

        <input 
          type="hidden" 
          name="kind" 
          ref={register()}
          defaultValue={currentClubEvent?.kind}
        />

        
        

        <div className="margin-bottom-4">
        <label htmlFor="">
          <span>Select Event Type:</span>

          <div className="font-1-25">
            <div 
              onClick={() => eventTypeClicked("run")} 
              className={`${selectedEventType === "run" ? 'selected' : ''} pointer multi-select margin-right-half`}
            >
              Run Event
            </div>

            <div 
              onClick={() => eventTypeClicked("nonrun")} 
              className={`${selectedEventType === "nonrun" ? 'selected' : ''} pointer multi-select margin-right-half`}
            >
              Non-run Event
            </div>
          </div>

        


        </label>

        </div>

        {/* <pre>
          theme: {JSON.stringify(getTheme(watchedTheme), null, 2)}
        </pre>
        <pre>
          kind: {JSON.stringify(selectedEventType, null, 2)}
        </pre> */}
        
        {/* <div>
          Current: 
          <pre>
            {JSON.stringify(currentClubEvent, null, 2)}
          </pre>
        </div> */}

        {selectedEventType !== null && <>


        <input 
          type="hidden" 
          name="id" 
          ref={register()}
          defaultValue={currentClubEvent?.id}
        />
        <input 
          type="hidden" 
          name="timezone" 
          ref={register()}
          defaultValue={currentClubEvent?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
        />


      {selectedEventType === "run" && 
        <>
          {getTheme(watchedTheme)?.avatar_url && 
            <img src={getTheme(watchedTheme)?.avatar_url} style={{width: '100px'}} />
          }
        </>
      }

      

      {selectedEventType === "run" && <>
        <div className="flex-cont">
          <label htmlFor="monitor_user_id"> 
            <span>Theme (optional)</span>
            {runThemesLoading &&
              <div className="spinner"></div>
            }
            {runThemes &&
              <select ref={register()} name="run_theme_id">
                <option value="">No theme</option>
                {runThemes.map(runTheme => <option value={runTheme.id}>{runTheme.name}</option>)}
              </select>
            }
            {errors.run_theme_id && <div className="field-error">{errors.run_theme_id.message}</div>}
          </label>
        </div>
        </>}



        <div className="flex-cont">
          <label className="flex-1 margin-right-2" htmlFor="start_date">
            <span>Start</span>

        

            <div className="flex-cont">
              <label>
                <input defaultValue={initialStartDate} className="margin-right-half" ref={register(
                  {
                    required: "Start date is required."
                  }
                )} type="date" name="start_date_string" id="" />
                {errors?.start_date_string && <div className="field-error">{errors.start_date_string.message}</div>}
              </label>

              <label htmlFor="">
                <input defaultValue={initialStartTime} ref={register(
                  {
                    required: "Start time is required."
                  }
                )} type="time" name="start_time_string" id="" />

                {errors?.start_time_string && <div className="field-error">{errors.start_time_string.message}</div>}
              </label>
            </div>
          </label>

          <label className="flex-1" htmlFor="end_date">
            <span>End</span>

            <div className="flex-cont">

              <label htmlFor="
              ">
                <input defaultValue={initialEndDate} className="margin-right-half"  ref={register(
                  {
                    required: "End date is required."
                  }
                )} type="date" name="end_date_string" id="" />
                {errors?.end_date_string && <div className="field-error">{errors.end_date_string.message}</div>}

              </label>

              <label htmlFor="">
                <input defaultValue={initialEndTime} ref={register({
                    required: "End time is required."
                  })} type="time" name="end_time_string" id="" />
                {errors?.end_time_string && <div className="field-error">{errors.end_time_string.message}</div>}
              </label>
              
              
            </div>
            
          </label>
        </div>
        

        <div className="flex-cont">
          <label htmlFor="Title"> 
            <input 
              type="text" 
              ref={register({
                required: "Title is required."
              })}
              placeholder="Title"
              name="title"
              defaultValue={currentClubEvent?.title}
            />
            {errors.title && <div className="field-error">{errors.title.message}</div>}
          </label>
        </div>

        <div className={selectedEventType === "run" ? "flex-cont" : "hidden"}>
          <label htmlFor="Description"> 
            <textarea 
              ref={register()}
              placeholder="Description"
              name="description"
              defaultValue={currentClubEvent?.description}
              disabled={selectedEventType !== "run"}
            />
            {errors.description && <div className="field-error">{errors.description.message}</div>}
          </label>
        </div>
        

        {true && <>

          <div className="flex-cont">
            <label htmlFor="monitor_user_id"> 
              <span>Monitor</span>
              {monitorUsersLoading &&
                <div className="spinner"></div>
              }
              {monitorUsers &&
                <select defaultValue={currentClubEvent?.monitor_user_id} ref={register({
                  required: false
                })} name="monitor_user_id">
                  <option value="" disabled selected>Select monitor</option>
                  {monitorUsers.map(monitorUser => <option value={monitorUser.id}>{monitorUser.full_name}</option>)}
                </select>
              }
              {errors.monitor_user_id && <div className="field-error">{errors.monitor_user_id.message}</div>}
            </label>
          </div>

        

        {/* <duv className="button" onClick={() => alert(JSON.stringify(getValues(), null, 2))}>
          
        </duv> */}

        <div className="flex-contx margin-top-1">

          <label htmlFor="club_event_teams_attributes[]"> 
            <span>Participating Teams</span>

            <div className="margin-top-1">
            {teamsLoading &&
              <div className="spinner"></div>
            }


            {teams?.length &&
              <div className="margin-bottom-1">
                {!teamIDs?.length &&
                  <div onClick={toggleSelectAll} className="button outline">Select all</div>
                }
                {!!teamIDs?.length &&
                  <div  onClick={toggleSelectAll} className="button outline">Deselect all</div>
                }
              </div>
            }
            
            
          

            {teams?.map((team, index) => 

              {
                const existingTeam = currentClubEvent?.club_event_teams.find(cet => cet.team_id === team.id);
                const teamSelected = teamIDs.includes(team.id);
                
                return(
                  <div 
                    key={team.id}
                    onClick={() => teamClicked(team.id)} 
                    className={`${teamIDs.includes(team.id) ? 'selected' : ''} pointer multi-select margin-right-half`}
                  >
                    {/* <div>id: {existingTeam?.id}</div>
                    <div>teamSelected: {JSON.stringify(teamSelected)}</div> */}

                    {(existingTeam || teamSelected) &&
                      <>

                        <input 
                          ref={register()} 
                          type="hidden" 
                          defaultValue={team.id} 
                          name={`club_event_teams_attributes[${index}].team_id`} 
                        />
                        <input 
                          ref={register()} 
                          type="hidden" 
                          defaultValue={existingTeam?.id} 
                          name={`club_event_teams_attributes[${index}].id`} 
                        />
                        <input 
                          ref={register()} 
                          type="hidden" 
                          defaultValue={teamSelected ? null : "1"} 
                          name={`club_event_teams_attributes[${index}]._destroy`} 
                        />
                      </>
                    }
                    
                    {/* {(!existingTeam && teamSelected) ? (
                        <input 
                          ref={register()} 
                          type="hidden" 
                          defaultValue={team.id} 
                          name={`club_event_teams_attributes[${index}].team_id`} 
                        />
                      ) : (
                        <>
                          <input 
                            ref={register()} 
                            type="hidden" 
                            defaultValue={existingTeam?.id} 
                            name={`club_event_teams_attributes[${index}].id`} 
                          />
                          <input 
                            ref={register()} 
                            type="hidden" 
                            defaultValue={teamIDs.includes(team.id) ? "0" : "1"} 
                            name={`club_event_teams_attributes[${index}]._destroy`} 
                          />
                        </>
                      )
                    } */}
                    

                    
                    
                    {/* {teamIDs.includes(team.id) &&
                      <>
                        <i className="lightgray fas fa-times margin-right-half"></i>
                      </>
                    }
                    {!teamIDs.includes(team.id) &&
                      <input ref={register()} type="hidden" defaultValue={"1"} name={`club_event_teams_attributes[${index}]._destroy`} />
                    } */}
                    {team.name}

                    
                  </div>
                );
              }
            )}
            </div>
          </label>
        </div>

        </> }

        </> }
      </div>

      

      <div className="modal-footer align-center flex-cont">
        <input disabled={!!!selectedEventType} type="submit" value="Save" className="button" />
        <div className="flex-1"></div>
        
        {currentClubEvent &&
          <div onClick={deleteClicked} className="ghost button danger">
            Delete
          </div>
        }
      </div>


      

    </form>
      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
        <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Event</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">
            Are you sure you want to delete this event?
          </div>

          

          <div className='modal-footer flex-cont align-items-center'>

            {deleteLoading &&
              <div className="center flex-1">
                <div className="spinner"></div>
              </div>
            }
            {!deleteLoading && 
              <>
                <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
                <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
              </>
            }
          </div>
        </Modal>
      }

    </>
  )

}



export default ClubEventForm;