import { andyFetch } from '../../api';

// createBadgeAthlete, getBadges, destroyBadgeAthlete, updateBadgeAthlete

export const destroyBadgeAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = `club/badges/destroy_badge_athlete`;

    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateBadge error.');
      }
    }
    catch (e) {
      console.log("updateBadge error", e);
    }
  }
};


export const updateBadgeAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = `club/badges/update_badge_athlete`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateBadge error.');
      }
    }
    catch (e) {
      console.log("updateBadge error", e);
    }
  }
};


export const createBadgeAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = "club/badges/create_badge_athlete";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createBadge error.');
      }
    }
    catch (e) {
      console.log("createBadgeAthlete error", e);
    }
  }
};

export const getBadgeAthletes = (params) => {
  return async (dispatch, getState) => {

    const url = "club/badges/badge_athletes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getBadgeAthletes error", e);
    }
  }
};



export const getBadges = (params) => {
  return async (dispatch, getState) => {

    const url = "club/badges";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getBadges error", e);
    }
  }
};


export const createBadge = (params) => {

  return async (dispatch, getState) => {

    const url = "club/badges";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createBadge error.');
      }
    }
    catch (e) {
      console.log("createBadge error", e);
    }
  }
};


export const updateBadge = (params) => {

  return async (dispatch, getState) => {

    const url = `club/badges/${params.payload.badge.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateBadge error.');
      }
    }
    catch (e) {
      console.log("updateBadge error", e);
    }
  }
};


export const deleteBadge = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/badges/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteBadge error.');
      }
    }
    catch (e) {
      console.log("deleteBadge error", e);
    }
  }
};

