import { andyFetch } from '../../api';

export const getMerchRequests = (params) => {
  return async (dispatch, getState) => {

    const url = "club/merch_requests";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMerchRequests error", e);
    }
  }
};



export const createMerchRequest = (params) => {

  return async (dispatch, getState) => {

    const url = "club/merch_requests";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createMerchRequest error.');
      }
    }
    catch (e) {
      console.log("createMerchRequest error", e);
    }
  }
};


export const updateMerchRequest = (params) => {

  return async (dispatch, getState) => {

    const url = `club/merch_requests/${params.payload.merch_request.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateMerchRequest error.');
      }
    }
    catch (e) {
      console.log("updateMerchRequest error", e);
    }
  }
};

export const getMerchRequest = (params) => {
  return async (dispatch, getState) => {
    const url = `club/merch_requests/${params.query.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getMerchRequest error.');
      }
    }
    catch (e) {
      console.log("getMerchRequest error", e);
    }
  }
};

export const deleteMerchRequest = (params) => {
  
  return async (dispatch, getState) => {

    const url = `club/merch_requests/${params.query.id}`;

    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteMerchRequest error.');
      }
    }
    catch (e) {
      console.log("deleteMerchRequest error", e);
    }
  }
};

