import { andyFetch } from '../../api';

export const getDashboardStats = (params) => {
  return async (dispatch, getState) => {
    const url = "parent/athletes/dashboard_stats";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getDashboardStats error", e);
    }
  }
};

export const getPledgeStats = (params) => {
  return async (dispatch, getState) => {
    const url = "parent/athletes/pledge_stats";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeStats error", e);
    }
  }
};