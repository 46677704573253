

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getSponsors } from 'redux/club/pledgeDriveActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Modal from 'components/Modal';
import { DateTime } from 'luxon';

import SponsorForm from './SponsorForm';
// import { createPledgeDriveSponsor, updatePledgeDriveSponsor, deletePledgeDriveSponsor } from 'redux/club/pledgeDriveSponsorActions';

const ClubPledgeDriveSponsors = ({currentUser}) => {
  const { id } = useParams();


  const [page, setPage] = useState(1);
  const [sponsors, loading, loaded, error, doFetch, setResult, pagy] = useApi(); 
  const [deletedPledge, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledge] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPledge, setEditingPledge] = useState(null);

  const handlePageChange = (p) => {
    setPage(p);
  };

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchSponsors();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPledge(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (id) {
      fetchSponsors();
    }
    
    
  }, [page, id]);

  const fetchSponsors = () => {
    doFetch(getSponsors, {query: {id: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPledge, setDeletingPledge] = useState(null);
  

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchSponsors();
    }
  }


  const editClicked = pledge => {
    setEditingPledge(pledge);
    toggleModal();
  }

  const deleteClicked = pledge => {
    setDeletingPledge(pledge);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPledge) {
      // doDeleteFetch(deletePledge, {query: {id: deletingPledge.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      <div onClick={toggleModal} className="button margin-bottom-2">
        <i className="fas fa-plus margin-right-half font-08"></i>
        Add Sponsor
      </div>


      {modalOpen &&
        <SponsorForm pledgeDriveID={id} toggleModal={toggleModal} currentSponsor={editingPledge} />
      }


      <div className="narrow-2 padding-top-1">


      {loading &&
        <div className="spinner"></div>
      }

      {sponsors &&
        <table className="basic full">
          {sponsors.map(sponsor => {
            return (
              <tr>
                <td className="fit nowrap">
                  <a href={sponsor.link_url}>
                    <img src={sponsor.image_url} style={{width: "200px"}} />
                  </a>
                  
                </td>
                <td className="fit nowrap">{sponsor.name}</td>
                <td className="fit">{sponsor.tagline}</td>
                <td className="">{sponsor.content}</td>
                <td className="fit">{sponsor.link_url}</td>
                <td className="fit nowrap">
                  {sponsor?.is_title && <div className="bold">TITLE SPONSOR</div>}
                </td>
                <td className="fit nowrap">
                  {sponsor.amount ? 
                    parseFloat(sponsor.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    :
                    "-"
                  }
                </td>
                
                <td></td>

                <td className="fit nowrap">
                  <i onClick={() => editClicked(sponsor)} className="ghost button fas fa-pencil-alt"></i>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Remove Sponsor</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">
            Are you sure you want to remove this sponsor?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!sponsors || sponsors.length === 0) &&
        <em>No results.</em>
      }


      

      </div>
    </>
  )
}


export default ClubPledgeDriveSponsors;