

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const ClubPledgeDriveNav = ({id}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/campaigns/${id}`}>Dashboard</NavLink></li>
        <li><NavLink end to={`/campaigns/${id}/pledges`}>Pledges</NavLink></li>
        <li><NavLink end to={`/campaigns/${id}/sponsors`}>Sponsors</NavLink></li>
        <li><NavLink end to={`/campaigns/${id}/material-fee`}>Material Fee Payments</NavLink></li>
      </ul>
    </div>
  )
}

export default ClubPledgeDriveNav;