import React, { useState, useEffect } from 'react';
import ClubAccountNav from './ClubAccountNav';


const ClubAccountLayout = ({children}) => {

  return (
    <>
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          <h1>
            Account
          </h1>
        </div>
        

        <ClubAccountNav />

        {children}
      </div>
    </>
  )

}



export default ClubAccountLayout;