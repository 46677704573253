
import React, { useState, useEffect } from 'react';
import logo7 from 'images/logo7.png';
import { Link, Outlet, useLocation, NavLink } from 'react-router-dom';
import store from 'redux/store';
import { connect } from 'react-redux';
import BottomNav from 'screens/BottomNav';

const AdminBottomNav = ({currentUser}) => {

  const [prefix, setPrefix] = useState('/');

  useEffect(() => {
    if (currentUser.role === "admin") {
      setPrefix('/club-dashboard/');
    }
  }, [currentUser]);




  return (
    <BottomNav  type="admin">
      <ul>
        <li >
          <NavLink to="/">
            <i className="fas fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li >
          <NavLink to="/notifications">
            <i className="fas fa-bell"></i>
            <span>Notifications</span>
          </NavLink>
        </li>
        <li >
          <NavLink to="/account">
            <i className="fas fa-cog"></i>
            <span>Account</span>
          </NavLink>
        </li>


        <li className="apex  margin-top-1">
          <div className="title">
            <i className="fas fa-th"></i>
            <span>CONTENT</span> 
          </div>
          <ul>
            <li><NavLink to="pages">Pages</NavLink></li>
            <li><NavLink to="inspiration">Inspiration</NavLink></li>
          </ul>
        </li>

        <li className="apex  margin-top-1">
          <div className="title">
            <i className="fas fa-tshirt"></i>
            <span>MERCH</span> 
          </div>
          <ul>
            <li><NavLink to="merch-products">Products</NavLink></li>
            <li><NavLink to="merch-requests">Requests</NavLink></li>
          </ul>
        </li>
        

        <li className="apex  margin-top-1">
          <div className="title">
            <i className="fas fa-certificate"></i>
            <span>AWARDS</span> 
          </div>
          <ul>
            <li><NavLink to="awards/login" >Awards</NavLink></li>
            <li><NavLink to="badges" >Badges</NavLink></li>
          </ul>
        </li>

        <li className="apex  margin-top-1">
          <div className="title">
            <i className="fas fa-school"></i>
            <span>AVATAR</span> 
          </div>
          <ul>
            <li><NavLink to="avatar-part-categories" >Categories</NavLink></li>
            <li><NavLink to="palettes" >Color Palettes</NavLink></li>
            <li><NavLink to="avatar-parts" >Parts</NavLink></li>
          </ul>
        </li>


        <li className="apex  margin-top-1">
          <div className="title">
            <i className="fas fa-school"></i>
            <span>CLUBS</span>  
          </div>
          <ul>
            <li><NavLink to="hero-bands" >Hero Bands</NavLink></li>
            <li><NavLink to="palettes" >Palettes</NavLink></li>
            <li><NavLink to="avatar-parts" >Avatar Parts</NavLink></li>
            <li><NavLink to="clubs" >Clubs</NavLink></li>
            <li><NavLink to="teams" >Teams</NavLink></li>
            <li><NavLink to="machines" >Machines</NavLink></li>
            <li><NavLink to="charities" >Charities</NavLink></li>
            <li><NavLink to="pledge-drives" >Pledge Drives</NavLink></li>
            <li><NavLink to="pledge-drive-subs" >Pledge Drive Subs</NavLink></li>
            <li><NavLink to="pledges" >Pledges</NavLink></li>
          </ul>
        </li>


        <li className="apex">
          <div className="title">
            <i className="fas fa-running"></i>
            <span>RACES</span> 
          </div>
          <ul>
            <li><NavLink to="races" >Races</NavLink></li>
            <li><NavLink to="race-files" >Race Files</NavLink></li>
            <li><NavLink to="races-logs" >Race Logs</NavLink></li>
            <li><NavLink to="tracks" >Tracks</NavLink></li>
            <li><NavLink to="run-themes" >Run Themes</NavLink></li>
          </ul>
        </li>


        <li className="apex">
          <div className="title">
            <i className="fas fa-users"></i>
            <span>USERS</span> 
          </div>
          <ul>
            <li><NavLink to="users" >Users</NavLink></li>
            <li><NavLink to="athletes" >Athletes</NavLink></li>
            <li><NavLink to="purchases" >Purchases</NavLink></li>
          </ul>
        </li>


        

        <li className="apex">
          <div className="title">
            <i className="fas fa-dollar-sign"></i>
            <span>FINANCES</span> 
          </div>
          <ul>
            <li><NavLink to="products" >Products</NavLink></li>
            <li><NavLink to="subscriptions" >Subscriptions</NavLink></li>
            <li><NavLink to="payments" >Payments</NavLink></li>
          </ul>
        </li>



      </ul>
    </BottomNav>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
  };
} 

export default connect(mapState)(AdminBottomNav);