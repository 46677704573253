
import React from 'react';

import AthleteSetup from 'screens/AthleteSetup';

const ProfileNoAthletes = () => {

  return(
    <AthleteSetup includeHeader={true} />
  )

}

export default ProfileNoAthletes;