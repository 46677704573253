import { andyFetch } from '../../api';

export const getRunThemes = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/run_themes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRunThemes error", e);
    }
  }
};

export const getRunTheme = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/run_themes/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRunTheme error", e);
    }
  }
};


export const createRunTheme = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/run_themes";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createRunTheme error.');
      }
    }
    catch (e) {
      console.log("createRunTheme error", e);
    }
  }
};


export const updateRunTheme = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/run_themes/${params?.payload?.run_theme?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateRunTheme error.');
      }
    }
    catch (e) {
      console.log("updateRunTheme error", e);
    }
  }
};


export const deleteRunTheme = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/run_themes/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteRunTheme error.');
      }
    }
    catch (e) {
      console.log("deleteRunTheme error", e);
    }
  }
};

