import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

const UpgradeConfirmation = ({currentUser, athletes}) => {

  return (
    <div className="narrow margin-top-2">
      <h1>Welcome to the Club!</h1>

      <div className="button font-1">
        <span>Go to profile</span>
        <i className="fas fa-chevron-right margin-left-1"></i>
        
      </div>

      <div className="margin-bottom-2"></div>

      <table className="basic">
        <tr>
          <td>Test</td>
          <td>Test 2</td>
        </tr>
        <tr>
          <td>Test</td>
          <td>Test 2</td>
        </tr>
        <tr>
          <td>Test</td>
          <td>Test 2</td>
        </tr>
        <tr>
          <td>Test</td>
          <td>Test 2</td>
        </tr>
        <tr>
          <td>Test</td>
          <td>Test 2</td>
        </tr>
      </table>
    </div>
  )
}

  


export default UpgradeConfirmation;
