import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import { updateSchoolYear } from "redux/club/schoolYearActions";
import { useForm } from 'react-hook-form';
import { useApi } from '../../../api';

import { API_URL } from '../../../api.js';
import { createMaterialFeeWaiver, updateMaterialFeeWaiver, deleteMaterialFeeWaiver } from 'redux/club/materialFeeWaiverActions';

import AutoComplete from 'components/AutoComplete';

const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div>{item.athlete_name}</div>
      {/* <div className="uppercase margin-left-1 margin-right-1 bold">{item.status}</div> */}
    </div>
  )
}

const SchoolYearWaiverForm = ({schoolYearFees, currentWaiver, toggleModal}) => {

  const { setFocus, setValue, watch, register, errors, handleSubmit, getValues } = useForm({
    shouldFocusError: false,
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      material_fee_waiver: {
        ...data
      }
    }

    if (currentWaiver && currentWaiver.id) {
      // alert(JSON.stringify(d));
      doFetch(updateMaterialFeeWaiver, {payload: d});
    }
    else {
      doFetch(createMaterialFeeWaiver, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [deletedPledgeDrive, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledgeDrive] = useApi(); 
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deleteMaterialFeeWaiverClicked = () => {
    setDeleteModalOpened(true);
  }

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpened(o => !o);
  }

  const confirmDestroy = () => {
    if (currentWaiver) {
      doDeleteFetch(deleteMaterialFeeWaiver, {query: {id: currentWaiver.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleModal(true);
      // navigate('/campaigns')
    }
  }, [deleteLoaded]);

  useEffect(() => {
    return () => {
      setDeleteModalOpened(false);
    }
  }, []);


  const renderRow = item => <Row item={item} />

  
  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentWaiver ? 'Edit' : 'New'} Material Fee Waiver</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">

        <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentWaiver?.id}
          />

          <AutoComplete 
            setFocus={setFocus}
            setValue={setValue}
            defaultValue={currentWaiver?.school_year_fee_id}
            defaultSearch={currentWaiver?.school_year_fee?.athlete_name}
            name={"school_year_fee_id"}
            items={schoolYearFees}
            fullName={"FULL NAME"}
            register={register}
            errors={errors}
            label="Search students:"
            labelFn={(item) => `${item.athlete_name}` }
            valueFn={(item) => item.id }
            searchableAttributes={["athlete_name"]}
            labelComponent={renderRow}
          />
          

        </div>

        <div className="modal-footer align-center flex-cont">
          <input type="submit" value="Save" className="button" />
          <div className="flex-1"></div>
          {currentWaiver &&
            <div onClick={deleteMaterialFeeWaiverClicked} className="ghost danger button">Remove</div>
          }
        </div>


      </form>

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={deleteModalOpened}>
          <div className="padding-1">
            Are you sure you want to remove this waiver? This cannot be undone.
          </div>
          <div className="modal-footer flex-cont align-center">
            <div onClick={toggleDeleteModal} className="ghost button">Cancel</div>
            <div className="flex-1"></div>
            <div onClick={confirmDestroy} className="button danger">Yes, delete</div>
          </div>
        </Modal>
      }

    </Modal>
  )
}

export default SchoolYearWaiverForm;