

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const ClubTeamNav = ({id}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/tracks/${id}`}>Dashboard</NavLink></li>
      </ul>
    </div>
  )
}

export default ClubTeamNav;