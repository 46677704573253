import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi } from '../../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { DragDrop, StatusBar } from '@uppy/react'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'

import Modal from '../../../components/Modal';
import { API_URL } from '../../../api.js';
import { createRace, updateRace, deleteRace } from 'redux/club/raceActions';


const RaceForm = ({currentRace, toggleModal}) => {

  const navigate = useNavigate('/');

  const { watch, register, errors, handleSubmit, getValues } = useForm({

  });

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deletingRace, setDeletingRace] = useState(null);

  const deleteRaceClicked = () => {
    setDeleteModalOpened(true);
  }

  useEffect(() => {
    return () => {
      setDeleteModalOpened(false);
    }
  }, []);

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [racesResult, racesLoading, racesLoaded, racesError, doFetchRaces, setRaceResults] = useApi();
  const [deletedRace, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedRace] = useApi(); 

  
  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpened(o => !o);
  }

  const onSubmit = async data => {
    let d = {
      race: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentRace && currentRace.id) {
      doFetch(updateRace, {payload: d});
    }
    else {
      doFetch(createRace, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const confirmDestroy = () => {
    if (currentRace) {
      doDeleteFetch(deleteRace, {query: {id: currentRace.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleModal(true);
      navigate(`/activities`)
    }
  }, [deleteLoaded]);

  
  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentRace ? 'Edit' : 'New'} Race</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">
          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentRace?.id}
          />

        </div>

        <div className="modal-footer align-center flex-cont">
          {/* <input type="submit" value="Save" className="button" /> */}

          {/* <button disabled={deleteLoading || loading} className={`${(deleteLoading || loading) ? 'disabled' : ''} button`}>
            Save
            {loading &&
              <div className="spinner button-spinner"></div>
            }
          </button> */}

          <div onClick={toggleModal} className="ghost button">
            Cancel
          </div>

          
          <div className="flex-1"></div>
          {/* <div onClick={deleteRaceClicked} className="ghost danger button">Remove</div> */}

          <div onClick={deleteRaceClicked} disabled={deleteLoading || loading} className={`${(loading || deleteLoading) ? 'disabled' : ''} button ghost danger`}>
            <span>Remove</span>
            
          </div>
        </div>

      </form>
      

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={toggleDeleteModal}>
          <div className="padding-2">
            Are you sure you want to delete this race? This cannot be undone.
          </div>
          <div className="modal-footer flex-cont align-center">
            <div onClick={toggleDeleteModal} className="ghost button">Cancel</div>
            <div className="flex-1"></div>
            <div onClick={confirmDestroy} className="button danger">Yes, delete</div>
          </div>
        </Modal>
      }
    </Modal>
  )
}

export default RaceForm;