

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { createPage, updatePage } from 'redux/admin/pageActions';
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import { useNavigate } from "react-router-dom"
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import '../../styles/TipTap.scss';
import TipTap from './TipTap';
import { API_URL } from '../../api.js';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput } from '@uppy/react'

const PageSchema = yup.object().shape({
  // id: yup.string(),
  // title: yup.string().required("Title is required."),
  // content: yup.string().required("Content is required."),

  // active: yup.string(),
});

export const PageForm = ({toggleModal, currentPage}) => {
  const navigate = useNavigate();
  const { control, watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PageSchema
  });

  const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);


  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: '',
    onUpdate() {
      setValue('content', this.getHTML());
      console.log(this.getHTML())
    },
  })

  useEffect(() => {
    if (currentPage) {
      editor.commands.setContent(currentPage?.content);
      setValue('content', currentPage?.content);
    }
  }, [currentPage])

  useEffect(() => {
    const u = Uppy({
      meta: { type: 'image' },
      autoProceed: true
    })
    u.use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
    setUppy(u);

    
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy]);


  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    const v = getValues();
    let d = {
      page: {
        ...v
      }
    }
    if (currentPage && currentPage.id) {
      doFetch(updatePage, {payload: d});
    }
    else {
      doFetch(createPage, {payload: d});
    }
  }


  useEffect(() => {
    
    if (loaded) {
      navigate('/pages')
    }
  }, [loaded]);


  const finalUrl = fileURL ? fileURL : currentPage?.image_url; 


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="code">
        <pre>
          {JSON.stringify(currentPage, null, 2)}
        </pre>
      </div> */}

      <input type="hidden" name="id" ref={register} defaultValue={currentPage?.id} />

      <div className="flex-cont">
        <label htmlFor="title"> 
          <input 
            type="text" 
            ref={register}
            placeholder="Title"
            name="title"
            defaultValue={currentPage?.title}
          />
          {errors.title && <div className="field-error">{errors.title.message}</div>}
        </label>
      </div>

      <div className="flex-cont">
        <label htmlFor="active"> 
          <input 
            type="checkbox" 
            ref={register}
            name="active"
            defaultChecked={currentPage?.active}
          />
          {errors.active && <div className="field-error">{errors.active.message}</div>}

          <span>Active?</span>
        </label>
        
      </div>

      <div className="flex-cont">
        <label htmlFor="on_nav"> 
          <input 
            type="checkbox" 
            ref={register}
            name="on_nav"
            defaultChecked={currentPage?.on_nav}
          />
          {errors.on_nav && <div className="field-error">{errors.on_nav.message}</div>}

          <span>On Top Nav?</span>
        </label>
        
      </div>
      <div className="flex-cont">
      <label htmlFor="on_footer"> 
          <input 
            type="checkbox" 
            ref={register}
            name="on_footer"
            defaultChecked={currentPage?.on_footer}
          />
          {errors.on_footer && <div className="field-error">{errors.on_footer.message}</div>}

          <span>On Footer?</span>
        </label>
      </div>

      <div className="flex-cont">
        {uppy && 
          <>
            <FileInput pretty={true} uppy={uppy}
              locale={
                {
                  strings: {
                    chooseFiles: "Choose banner image"
                  }
                }
              }
            />
            <StatusBar uppy={uppy} />
          </>
        }
      </div>
      

      <div style={{backgroundImage: `url(${finalUrl})`}} className="margin-vertical-1 page-banner">

      </div>

      <input ref={register} type="hidden" name="image" value={file} />


      <div className="card no-hover margin-top-3">
        

        <Controller
            name='content'
            control={control}
            valueName="value"
            onChange={([e]) => e}
            as={<TipTap editor={editor}/>}
        />

        {/* <div onClick={() => console.log(getValues())} className="button">

        </div> */}
      </div>

      


      <div className="margin-top-3 align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>
      

    </form>
  )

}

export default PageForm;