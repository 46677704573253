import { andyFetch } from '../../api';

export const getProducts = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/products";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getProducts error", e);
    }
  }
};


export const createProduct = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/products";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createProduct error.');
      }
    }
    catch (e) {
      console.log("createProduct error", e);
    }
  }
};


export const updateProduct = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/products/${params.payload.product.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateProduct error.');
      }
    }
    catch (e) {
      console.log("updateProduct error", e);
    }
  }
};


export const deleteProduct = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/products/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteProduct error.');
      }
    }
    catch (e) {
      console.log("deleteProduct error", e);
    }
  }
};

