

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getInspos } from 'redux/admin/inspoActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { createInspo, updateInspo, deleteInspo } from 'redux/admin/inspoActions';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../api.js';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput } from '@uppy/react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Masonry from 'react-masonry-css'
import InspoCard from '../club/inspos/InspoCard.js';

const InspoSchema = yup.object().shape({
  title: yup.string(),
  content: yup.string(),
});


const InspoForm = ({toggleModal, currentInspo}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: InspoSchema
  });

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);
  const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [title, setTitle] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: "",
  })

  useEffect(() => {
    const u = Uppy({
      meta: { type: 'image' },
      autoProceed: true
    })
    u.use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
    setUppy(u);
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy]);
  


  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [insposResult, insposLoading, insposLoaded, insposError, doFetchInspos, setInspoResults] = useApi();

  const onSubmit = async data => {
    let d = {
      inspo: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentInspo && currentInspo.id) {
      doFetch(updateInspo, {payload: d});
    }
    else {
      doFetch(createInspo, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const finalUrl = fileURL ? fileURL : currentInspo?.image_url

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const deleteClicked = () => {
    toggleDeleteModal();
  }
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteInspo, {query: {id: currentInspo.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
    
  }, [deleteLoaded])

  return (
    <>
    {deleteModalOpened &&
      <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
        <div className="padding-1">
          Are you sure you want to delete this?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className="button red">
            Delete
          </div>
        </div>
      </Modal>
    }
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentInspo ? 'Edit' : 'New'} Inspiration</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentInspo?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="title"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Title"
              name="title"
              onChange={e => setTitle(e.target.value)}
              defaultValue={currentInspo?.title}
            />
            {errors.title && <div className="field-error">{errors.title.message}</div>}
          </label>
        </div>

        {/* <EditorContent editor={editor} /> */}

        <div className="flex-cont">
          <label htmlFor="content"> 
            <textarea 
              ref={register}
              placeholder="Content"
              name="content"
              defaultValue={currentInspo?.content}
            />
            {errors.content && <div className="field-error">{errors.content.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          {uppy && 
            <>
              <FileInput uppy={uppy} />
              <StatusBar uppy={uppy} />
            </>
          }
        </div>


        <div>
          <InspoCard title={title} url={finalUrl} />
        </div>



        <input ref={register} type="hidden" name="image" value={file} />

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        <div className="flex-1"></div>

        {currentInspo &&
          <div onClick={deleteClicked} className="button red">
            Delete
          </div>
        }
      </div>

    </form>
    </>
  )

}


// const InspoCard = ({inspo, url, title, content, onEditClicked, onViewClicked}) => {

//   const [expanded, setExpanded] = useState(false);

//   return (
//     <div  style={{backgroundImage: `url("${url}")`}} className={`inspo-cont ${expanded && 'expanded'}`}> 
//       <div className="tools">
//         <div onClick={() => onViewClicked(inspo)} className="tool">
//           <i className="fas fa-eye"></i>
//         </div>
//         <div onClick={() => onEditClicked(inspo)} className="tool">
//           <i className="fas fa-pencil-alt"></i>
//         </div>
        
//       </div>
//       <div className="glow"></div>
//       <img src={url} alt="" className="dummy" />
//       <div className="title">
//         {title}
//       </div>
//     </div>
//   )
// }






const AdminInspos = ({currentUser}) => {

  const [inspos, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedInspo, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedInspo] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingInspo, setEditingInspo] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingInspo, setViewingInspo] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);
    if (reload) {
      fetchInspos();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingInspo(null);
    }
  }, [modalOpen]);


  const toggleViewModal = (reload = false) => {
    setViewModalOpen(o => !o);
    // if (reload) {
    //   fetchInspos();
    // }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingInspo(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (!inspos) {
      fetchInspos();
    }
  }, []);

  const fetchInspos = () => {
    doFetch(getInspos);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingInspo, setDeletingInspo] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchInspos();
    }
  }


  const editClicked = inspo => {
    setEditingInspo(inspo);
    toggleModal();
  }

  const deleteClicked = inspo => {
    setDeletingInspo(inspo);
    toggleDeleteModal();
  }

  const viewClicked = (inspo) => {
    setViewingInspo(inspo);
    toggleViewModal();
  }

  const confirmDestroy = () => {
    if (deletingInspo) {
      doDeleteFetch(deleteInspo, {query: {id: deletingInspo.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  
  const myBreakpointsAndCols = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const andy = () => {
    setModalOpen(o => !o);
  }

  // const andy = "Help"
  return (
    <>
      {modalOpen &&
        <Modal focusTrap={true} toggleModal={toggleModal}>
          <InspoForm currentInspo={editingInspo} toggleModal={toggleModal} />
        </Modal>
      }

      {viewModalOpen &&
        <Modal focusTrap={false} toggleModal={toggleViewModal}>

          
          <div className="flex-cont">
            <div className="flex-1">
              <img style={{width: '100%'}} src={viewingInspo?.image_url} alt="" />
            </div>

            <div className="flex-1 padding-3">
              <div className="flex-cont">
                <h2 className="flex-1">{viewingInspo?.title}</h2>
                <div onClick={toggleViewModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>
              </div>
              
              
              <div>
                {viewingInspo?.content}
              </div>
            </div>


          </div>
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Inspiration</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Inspiration</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {inspos?.map(inspo => {
            return (
              <InspoCard onViewClicked={viewClicked} onEditClicked={editClicked} inspo={inspo} url={inspo.image_url} title={inspo.title} content={inspo.content} />
            )
          })
        }
      </Masonry>
      

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Inspo</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingInspo?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !inspos &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminInspos;