

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getPledgeDrives } from 'redux/club/pledgeDriveActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Modal from 'components/Modal';
import PledgeDriveForm from './PledgeDriveForm';
import { createPledgeDrive, updatePledgeDrive, deletePledgeDrive } from 'redux/club/pledgeDriveActions';
import { getCharities } from 'redux/club/charityActions';
import { DateTime } from "luxon";
import { getMyClub } from 'redux/club/clubActions';


const PledgeDriveschema = yup.object().shape({
  name: yup.string().required("Name is required."),
  active: yup.boolean(),
  // charity_id: yup.string().required("Must select a charity."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});


const ClubPledgeDriveForm = ({toggleModal, currentPledgeDrive}) => {

  const navigate = useNavigate('/');

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PledgeDriveschema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [deletedPledgeDrive, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledgeDrive] = useApi(); 
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deletePledgeDriveClicked = () => {
    setDeleteModalOpened(true);
  }

  const onSubmit = async data => {
    let d = {
      pledge_drive: {
        ...data
      }
    }

    if (currentPledgeDrive && currentPledgeDrive.id) {

      doFetch(updatePledgeDrive, {payload: d});
    }
    else {
      doFetch(createPledgeDrive, {payload: d});
    }
  }

  const [club, clubLoading, clubLoaded, clubError, doClubFetch, setClub] = useApi(); 
  const fetchClub = () => {
    doClubFetch(getMyClub);
  };

  useEffect(() => {
    if (clubLoaded) {
      toggleModal(true);
    }
  }, [clubLoaded]);

  useEffect(() => {
    if (loaded) {
      fetchClub();
    }
  }, [loaded]);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpened(o => !o);
  }

  const confirmDestroy = () => {
    if (currentPledgeDrive) {
      doDeleteFetch(deletePledgeDrive, {query: {id: currentPledgeDrive.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleModal(true);
      navigate('/campaigns')
    }
  }, [deleteLoaded]);


  const pledgeDrive = currentPledgeDrive;

  return (
    <Modal toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentPledgeDrive ? 'Edit' : 'New'} Campaign</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">


          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentPledgeDrive?.id}
          />
          

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>Campaign Name</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentPledgeDrive?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <div className="flex-1">
            <label htmlFor="end_date"> 
              <span>Material Fee</span>
              <div className="flex-cont align-center relative">
                <div className="inner-label left">$</div>
                <input 
                  type="number" 
                  min="0"
                  max="999999"
                  step="0.01"
                  ref={register}
                  className="left-padding"
                  name="material_fee"
                  defaultValue={currentPledgeDrive?.material_fee}
                />
              </div>
              {errors.end_date && <div className="field-error">{errors.end_date.message}</div>}
            </label>
            <div className="gray margin-bottom-2">
              You can waive this fee for some families in the Material Fee tab.
            </div>
            </div>

          </div>


          <div className="flex-cont">
            <label htmlFor="start_date"> 
              <span>Start Date</span>
              <input 
                type="date" 
                ref={register}
      
                name="start_date"
                defaultValue={currentPledgeDrive?.start_date ? DateTime.fromISO(currentPledgeDrive?.start_date).toUTC().toISODate() : null}
              />
              {errors.start_date && <div className="field-error">{errors.start_date.message}</div>}
            </label>
            <label htmlFor="end_date"> 
              <span>End Date</span>
              <input 
                type="date" 
                ref={register}
      
                name="end_date"
                defaultValue={currentPledgeDrive?.end_date ? DateTime.fromISO(currentPledgeDrive?.end_date).toUTC().toISODate() : null}
              />
              {errors.end_date && <div className="field-error">{errors.end_date.message}</div>}
            </label>
          </div>

          <div className="bold font-1-25 margin-bottom-1 margin-top-1">Student Goals</div>

          <div className="flex-cont">
            <label htmlFor="athlete_distance_goal"> 
              <span>Distance Goal for  each student</span>

              <div className="flex-cont align-center relative">
                <input 
                  type="number" 
                  min="0"
                  max="999999"
                  step="0.01"
                  ref={register}
                  className="right-padding"
                  name="athlete_distance_goal"
                  defaultValue={currentPledgeDrive?.athlete_distance_goal}
                />
                <div className="inner-label right number">mi.</div>
              </div>
              {errors.athlete_distance_goal && <div className="field-error">{errors.athlete_distance_goal.message}</div>}
            </label>
            <label htmlFor="athlete_pledged_goal"> 
              <span>Pledge Goal for each student</span>
              <div className="flex-cont align-center relative">
                <div className="inner-label left">$</div>
                <input 
                  type="number" 
                  min="0"
                  max="999999"
                  step="0.01"
                  ref={register}
                  className="left-padding"
                  name="athlete_pledged_goal"
                  defaultValue={currentPledgeDrive?.athlete_pledged_goal}
                />
              </div>
              {errors.athlete_pledged_goal && <div className="field-error">{errors.athlete_pledged_goal.message}</div>}
            </label>
          </div>

          <div className="bold font-1-25 margin-bottom-1 margin-top-1">Campaign Goals</div>

          <div className="flex-cont">
   
              <label htmlFor="distance_goal"> 
                <span>Total Distance Goal</span>
                <div className="flex-cont align-center relative">
                  <div className="inner-label left">$</div>
                  <input 
                    type="number" 
                    min="0"
                    max="999999"
                    step="0.01"
                    ref={register}
                    className="left-padding"
                    name="earned_goal"
                    defaultValue={currentPledgeDrive?.distance_goal}
                  />
                </div>
                
                {errors.distance_goal && <div className="field-error">{errors.distance_goal.message}</div>}
              </label>
              <label htmlFor="pledged_goal"> 
                <span>Total Pledge Goal</span>
                <div className="flex-cont align-center relative">
                  <div className="inner-label left">$</div>
                  <input 
                    type="number" 
                    min="0"
                    max="999999"
                    step="0.01"
                    ref={register}
                    className="left-padding"
                    name="pledged_goal"
                    defaultValue={currentPledgeDrive?.pledged_goal}
                  />
                </div>
                
                {errors.earned_goal && <div className="field-error">{errors.earned_goal.message}</div>}
              </label>
              <label htmlFor="earned_goal"> 
                <span>Total Earnings Goal</span>
                <div className="flex-cont align-center relative">
                  <div className="inner-label left">$</div>
                  <input 
                    type="number" 
                    min="0"
                    max="999999"
                    step="0.01"
                    ref={register}
                    className="left-padding"
                    name="earned_goal"
                    defaultValue={currentPledgeDrive?.earned_goal}
                  />
                </div>
                
                {errors.earned_goal && <div className="field-error">{errors.earned_goal.message}</div>}
              </label>
     
          </div>

          

          <h3 className="margin-top-2">Pledge Page</h3>

          <div className="flex-cont">
            <label htmlFor="page_title"> 
              <span>Pledge Page Title</span>
              <div className="flex-cont align-center relative">
                <input 
                  type="text" 
                  ref={register}
                  name="page_title"
                  defaultValue={currentPledgeDrive?.page_title}
                />
              </div>
              
              {errors.page_title && <div className="field-error">{errors.page_title.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="page_content"> 
              <span>Pledge Page Message</span>
              <div className="flex-cont align-center relative">
                <input 
                  type="text" 
                  ref={register}
                  name="page_content"
                  defaultValue={currentPledgeDrive?.page_content}
                />
              </div>
              
              {errors.page_content && <div className="field-error">{errors.page_content.message}</div>}
            </label>
          </div>

          {/* <div className="flex-cont align-end">
            <label htmlFor="charity_id">
              <span>Charity</span>
              {charitiesLoading &&
                <div className="spinner"></div>
              }
              {charities &&
                <select defaultValue={currentPledgeDrive?.charity_id} onChange={charitySelectChanged} ref={register} name="charity_id">
                  <option value="" disabled selected>Select your option</option>
                  {charities.map(charity => <option value={charity.id}>{charity.name}</option>)}
                </select>
              }
              {errors.charity_id && <div className="field-error">{errors.charity_id.message}</div>}
            </label>
          </div> */}

          {/* <div className="flex-cont">
            <label htmlFor="active">
              
              <input defaultChecked={currentPledgeDrive?.active} type="checkbox" name="active" ref={register} id=""/>
              <span className="margin-left-1">Active</span>

              {errors.active && <div className="field-error">{errors.active.message}</div>}
            </label>
          </div> */}


        </div>

        <div className="modal-footer align-center flex-cont">
          <button className={`${loading ? 'disabled' : ''} button`}>
            Save
            {loading && <div className="spinner button-spinner"></div>}
          </button>
          <div className="flex-1"></div>
          {currentPledgeDrive &&
            <div onClick={deletePledgeDriveClicked} className="ghost danger button">Remove</div>
          }
        </div>

      </form>

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={toggleDeleteModal}>
          <div className="padding-2">
            Are you sure you want to delete {currentPledgeDrive.name}? This cannot be undone.
          </div>
          <div className="modal-footer flex-cont align-center">
            <div onClick={toggleDeleteModal} className="ghost button">Cancel</div>
            <div className="flex-1"></div>
            <div onClick={confirmDestroy} className="button danger">Yes, delete</div>
          </div>
        </Modal>
      }
    </Modal>  
  )

}

export default ClubPledgeDriveForm;