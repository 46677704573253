





import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getPages } from 'redux/admin/pageActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createPage, updatePage, deletePage, getPage} from 'redux/admin/pageActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'

import SVG from './SVG';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

const AdminPages = ({currentUser}) => {

  let { id } = useParams();
  const navigate = useNavigate();

  const [pages, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedPage, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPage] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPage, setEditingPage] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPages();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPage(null);
    }
  }, [modalOpen])

  useEffect(() => {
    fetchPages();
  }, [id]);


  const fetchPages = () => {
    doFetch(getPages, {query: {tab: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPage, setDeletingPage] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingPage, setViewingPage] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPages();
    }
  }
  const editClicked = page => {
    setEditingPage(page);
    toggleModal();
  }

  const deleteClicked = page => {
    setDeletingPage(page);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPage) {
      doDeleteFetch(deletePage, {query: {id: deletingPage.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);



  return (
    <>
      <div className="flex-cont">
        <div className="flex-1"><h1>Pages</h1></div>
        <div>
          <Link to={`new`} className="button">New Page</Link>
        </div>
      </div>


      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink exact to={`/pages`}>All</NavLink></li>
        </ul>
      </div>


      {loading &&
        <div className="spinner"></div>
      }

      {pages &&
        <table className="basic">
          {pages.map(page => {
            return (
              <tr>
                <td className="fit nowrap">

                </td>
                <td className="bold fit nowrap">
                  <Link className="text" to={`/pages/edit/${page.id}`}>
                    {page.title}
                  </Link>
                </td>

                <td>
                </td>




                <td className="fit nowrap">
                  <Link to={`/${page.slug}`} className="ghost button">
                    <i className="fas fa-eye"></i>
                  </Link>
                  <Link to={`edit/${page.id}`} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </Link>

                  <div onClick={() => deleteClicked(page)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table> 
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Page</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingPage?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {!loading && !pages &&
        <em>No results.</em>
      }


      


    </>
  )
}



export default AdminPages;