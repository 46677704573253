

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getInspos } from 'redux/club/inspoActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { createInspo, updateInspo, deleteInspo } from 'redux/club/inspoActions';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../../api'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput, useUppy } from '@uppy/react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Masonry from 'react-masonry-css'
import InspoCard from './InspoCard'

const InspoSchema = yup.object().shape({
  title: yup.string(),
  content: yup.string(),
});


const InspoForm = ({toggleModal, currentInspo}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: InspoSchema
  });

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);
  // const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [title, setTitle] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: "",
  })

  const uppy = useUppy(() => {
    return new Uppy({
      meta: { type: 'image' },
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1
      }
    }).use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
  })

  useEffect(() => {
    
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy]);
  


  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [insposResult, insposLoading, insposLoaded, insposError, doFetchInspos, setInspoResults] = useApi();

  const onSubmit = async data => {
    let d = {
      inspo: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentInspo && currentInspo.id) {
      doFetch(updateInspo, {payload: d});
    }
    else {
      doFetch(createInspo, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const finalUrl = fileURL ? fileURL : currentInspo?.image_url

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const deleteClicked = () => {
    toggleDeleteModal();
  }
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteInspo, {query: {id: currentInspo.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
    
  }, [deleteLoaded])

  return (
    <>
    {deleteModalOpened &&
      <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
        <div className="padding-1">
          Are you sure you want to delete this?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className="button red">
            Delete
          </div>
        </div>
      </Modal>
    }
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentInspo ? 'Edit' : 'New'} Inspiration</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentInspo?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="title"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Title"
              name="title"
              onChange={e => setTitle(e.target.value)}
              defaultValue={currentInspo?.title}
            />
            {errors.title && <div className="field-error">{errors.title.message}</div>}
          </label>
        </div>

        {/* <EditorContent editor={editor} /> */}

        <div className="flex-cont">
          <label htmlFor="content"> 
            <textarea 
              ref={register}
              placeholder="Content"
              name="content"
              defaultValue={currentInspo?.content}
            />
            {errors.content && <div className="field-error">{errors.content.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          {uppy && 
            <>
              <FileInput 
                uppy={uppy} 
                locale={{
                  strings: {
                    chooseFiles: "Choose image"
                  }
                }}
              />
              <StatusBar uppy={uppy} />
            </>
          }
        </div>


        <div>
          <InspoCard showTools={false} title={title} url={finalUrl} />
        </div>



        <input ref={register} type="hidden" name="image" value={file} />

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        <div className="flex-1"></div>

        {currentInspo &&
          <div onClick={deleteClicked} className="button red">
            Delete
          </div>
        }
      </div>

    </form>
    </>
  )

}



export default InspoForm;