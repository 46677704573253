import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getAthletes } from 'redux/club/athleteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';
import { destroyClubMembership, createAthlete, updateAthlete, deleteAthlete, getAthlete, acceptMembership, rejectMembership } from 'redux/club/athleteActions';


import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";

const AthleteSchema = yup.object().shape({
  // name: yup.string().required("Name is required."),
  epc: yup.string().required("EPC is required."),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  birthday: yup.date().nullable().required("Birthday is required.").typeError("Invalid birthday."),
  gender: yup.string().oneOf(['male', 'female']).required("Gender is required."),
  parent_email: yup.string().email().required('Parent email is required.')
});

const AthleteForm = ({toggleModal, currentAthlete}) => {

  let navigate = useNavigate();

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: AthleteSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      athlete: {
        ...data
      }
    }
    if (currentAthlete && currentAthlete.id) {
      doFetch(updateAthlete, {payload: d});
    }
    else {
      doFetch(createAthlete, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [removeModalOpened, setRemoveModalOpened] = useState(false);

  const removeClicked = () => {
    setRemoveModalOpened(true);
  }

  const [removeResult, removeLoading, removeLoaded, removeError, doRemoveFetch, setRemoveResult] = useApi();

  const removeConfirmClicked = () => {
    doRemoveFetch(destroyClubMembership, {query: {athlete_id: currentAthlete.id}});
  }

  const toggleRemoveModal = () => {
    setRemoveModalOpened(false);
  }

  useEffect(() => {
    if (removeLoaded) {
      navigate('/students')
    }
  }, [removeLoaded])

  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <div className="modal-header flex-cont align-center">
        <h1>
          {currentAthlete?.full_name}
        </h1>
        <div className="flex-1"></div>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        <div onClick={removeClicked} className="button danger">
          Remove from School
        </div>
      </div>

      {removeModalOpened &&
        <Modal size="small" focusTrap={false} toggleModal={toggleRemoveModal}>
          <div className="padding-2">
            Are you sure you want to remove {currentAthlete.full_name} from this school? This cannot be undone.
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={() => toggleRemoveModal()} className="button ghost">Cancel</div>
            <div className="flex-1"></div>
            <div onClick={removeConfirmClicked} className="button danger">
              Yes, remove.
            </div>
          </div>
        </Modal>
      }
    </Modal>
  )

}

export default AthleteForm;


// <form onSubmit={handleSubmit(onSubmit)}>
{/* <div className="modal-header flex-cont align-center">
<h2 className="flex-1">Add Athlete</h2>
<div onClick={() => toggleModal(false)} className="ghost button close">
  <i className="fas fa-times"></i>
</div>
</div>

<div className="padding-1 padding-bottom-200px">

<input 
  type="hidden" 
  name="id" 
  ref={register}
  defaultValue={currentAthlete?.id}
/>

<div className="flex-cont">
  <label htmlFor="first_name"> 
    <input 
      type="text" 
      ref={register}
      placeholder="First Name"
      name="first_name"
      defaultValue={currentAthlete?.first_name}
    />
    {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
  </label>
  <label htmlFor="last_name"> 
    <input 
      type="text" 
      ref={register}
      placeholder="Last Name"
      name="last_name"
      defaultValue={currentAthlete?.last_name}
    />
    {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
  </label>
</div>

<div className="flex-cont">
  <label htmlFor="birthday"> 
    <DayPickerInput 
      className="full" 
      inputProps={{placeholder: "Birthday YYYY-MM-DD", name: `birthday`, ref: register}} 
      type="text"  
    />
    {errors.birthday && <div className="field-error">{errors.birthday.message}</div>}
  </label>
  <label htmlFor="gender"> 
    <label>
      <input type="radio" ref={register} name={`gender`}  value="female" defaultChecked={true}  />
      <span>Female</span>
    </label>
    <br />
    <label>
      <input type="radio" ref={register} name={`gender`} value="male"  />
      <span>Male</span>
    </label>
    {errors.gender && <div className="field-error">{errors.gender.message}</div>}
  </label>
</div>


<div className="flex-cont">
  <label htmlFor="name"> 
    <input 
      type="text" 
      ref={register}
      placeholder="Tag EPC"
      name="epc"
      defaultValue={currentAthlete?.epc}
    />
    {errors.epc && <div className="field-error">{errors.epc.message}</div>}
  </label>
</div>
</div>

<div className="modal-footer align-center flex-cont">
<input type="submit" value="Save" className="button" />
</div>

</form> */}