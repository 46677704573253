

import React, { useState, useEffect } from 'react';
import { useOutletContext, NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { getDashboardStats, getMyWeek } from 'redux/athlete/athleteActions';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';

import SponsorBox from './SponsorBox';
import TeamBox from './TeamBox';
import Ring from 'components/Ring';
import Bar from 'components/Bar';
import CountUp from 'components/CountUp';
import SharingCard from './SharingCard';
import { useVisibility } from 'components/useVisibility';

import ProfileWeek from './ProfileWeek';

const Box = ({progress}) => {

  const [startCount, setStartCount] = useState(false);
  const { currentElement, isVisible } = useVisibility(-100);

  useEffect(() => {
    // alert(isVisible);
    if (isVisible && !startCount) {
      setStartCount(true);
    } 
  }, [isVisible]);


  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (startCount) {

      const interval = setInterval(() => {

        // console.log('counter', counter);
        // console.log('progress', progress);



          setCounter(counter => {

            if (counter < progress) {
              return(counter + 1);
            }
            else {
              clearInterval(interval);
              return(counter);
            }
            
          });
      }, 1800/progress);

      return () => {
        // alert('here');
        clearInterval(interval);
      };
    }
  }, [startCount]);

  return (
    <div ref={currentElement} className="align-center justify-center card span-1-v grid-item night box flex-column">
      <div className="margin-top-1 moon ring-cont">
        <Ring radius={90} stroke={10} progress={progress} />
      </div>

      

      <div className="margin-top-2 number white-text">{counter}%</div>
      <div className="number-label white-text">around the moon</div>
    </div>
  )
}

const NewBox = ({athlete}) => {

  const [flipped, setFlipped] = useState(false);


  return (
    <div onClick={() => setFlipped(f => !f)} className={`flippable ${flipped ? 'flipped' : ''} align-center justify-center grid-item span-1-v box`}>
      <div className="box-face box-face-front">
        <div className="number">{athlete ? athlete.total_laps : "-"}</div>
        <div className="number-label">LAPS</div>
      </div>
      <div className="box-face box-face-back"><div className="number">LAPS</div></div>
    </div>
  )
}

const ProfileHome = ({currentUser, currentAthlete}) => {
  const {toggleShareModal} = useOutletContext();
  const {hasActiveCampaign} = useOutletContext();
  const {campaignLoading} = useOutletContext();

  let location = useLocation();
  let background = location.state && location.state.background;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [stats, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [week, weekLoading, weekLoaded, weekError, doWeekFetch, setWeekResult] = useApi(); 

  const navigate = useNavigate("/");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchDashboardStats();
    fetchMyWeek();
  }, []);

  const fetchDashboardStats = async () => {
    doFetch(getDashboardStats);
  };

  const fetchMyWeek = () => {
    doWeekFetch(getMyWeek);
  };



  return (
    <>


        <div className="margin-top-3x margin-bottom-3x flex-cont flex-wrap align-center mobile-center mobile-justify-center">
          <h1  className='mobile-center'>Dashboard</h1>
          <i className="desktop-only fas fa-circle margin-left-1 margin-right-1 lightgray font-05"></i>
          
            {loading && <div className="margin-left-1 spinner"></div>}
            {stats &&
              <>
                <div className="mobile-only margin-left-1"></div>
                <div className="gray bold font-1-5">
                  {stats?.pledge_drive_name}
                </div>
              </>
            }
          
        </div>

            {/* <pre>
              {JSON.stringify(currentAthlete, null, 2)}
            </pre> */}

        
          <SharingCard campaignLoading={campaignLoading} hasActiveCampaign={hasActiveCampaign} toggleShareModal={toggleShareModal} />
          {week &&
            <div className="margin-bottom-3">
              <ProfileWeek week={week} />
            </div>
            
          }
          

          {/* <div className="card">
            <pre>
              {JSON.stringify(week, null, 2)}
            </pre>
          </div> */}
        
          <div className="grid-layout" >

          {/* {loading && <div className="spinner"></div> } */}
          {/* style={{maxWidth: '950px'}} */}

          {/* <div className={`align-center justify-center grid-item span-1-v box`}>
            <div className="box-face box-face-front">
              <div className="number">{athlete ? athlete.total_laps : "-"}</div>
              <div className="number-label">LAPS</div>
            </div>
            <div className="box-face box-face-back"><div className="number">LAPS</div></div>
          </div> */}

            <div className="no-hover no-click align-center justify-center card grid-item span-1-v box">
              
              <div style={{position: 'absolute'}}>
                <div className="number">
                  <CountUp number={parseFloat(stats?.total_miles)}/>
                </div>
                <div className="number-label">
                  {(stats && stats.total_miles === 1.0) ? "MILE" : "MILES" }
                </div>
              </div>
            </div>

            <div className="no-hover no-click align-center justify-center card grid-item span-1-v box">
              
              <div style={{position: 'absolute'}}>
                <div className="number">
                  {stats?.total_time != null ? stats?.total_time : "-"}
                </div>
                <div className="number-label">
                  ACTIVITY TIME
                </div>
              </div>
            </div>

            <div className="no-hover no-click align-center justify-center card grid-item span-1-v box">
              
              <div style={{position: 'absolute'}}>
                <div className="number">
                  {stats?.avg_mile_time != null ? stats?.avg_mile_time : "-"}
                </div>
                <div className="number-label">
                  AVG. TIMED MILE
                </div>
              </div>
            </div>

            

            {/* <div className="align-center justify-center card grid-item span-1-v box">
              <div style={{position: 'absolute'}}>
                <div className="number">
                  {stats ? (stats.total_earned/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
                </div>
                <div className="number-label">EARNED</div>
              </div>
            </div>


            <div className="align-center justify-center card grid-item span-1-v box">
              <div style={{position: 'absolute'}}>
                <div className="number">
                  {stats ? (stats.total_pledged/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
                </div>
                <div className="number-label">PLEDGED</div>
              </div>
            </div> */}

            <SponsorBox />

            <div className="no-click no-hover align-center justify-start card grid-item span-1-v span-3-h box">
              <div className="full">
                
                <div className="text-left margin-bottom-1 number-label">DISTANCE GOAL</div>
                <Bar full progress={stats ? (100*stats.total_miles/stats.athlete_distance_goal) : null} />

                <div className="text-left number-label margin-top-1">
                  {stats?.total_miles || "-"} / {stats?.athlete_distance_goal || "-"} mi
                </div>
              </div>
            </div>

            <div className="no-click no-hover align-center justify-start card grid-item span-1-v span-3-h box">
              <div className="full">
                
                <div className="text-left margin-bottom-1 number-label">PLEDGE GOAL</div>
                <Bar full progress={stats ? (100*(stats.total_pledged/(stats.pledged_goal*100.0))) : null} />

                <div className="text-left number-label margin-top-1">
                  {stats ? (parseFloat(stats.total_pledged)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"} / {stats ? (parseFloat(stats.pledged_goal)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
                </div>
              </div>
            </div>

            <div className="no-click no-hover flex-column align-center justify-center card grid-item box span-1-v">

                  <div className="flex-cont align-center justify-center track">
                    <div className="number">
                      {stats ? Math.round(100.00 * stats.total_miles/26.2)/100.00 : "-"}
                    </div>
                  </div>
                  <div className="margin-top-1 number-label">MARATHONS</div>
            
            </div>

            {/* <Box progress={62}  /> */}
            {/* <div className="align-center justify-center card grid-item night box">
              <div className="earth ring-cont">
                <Ring radius={110} stroke={10} progress={(1 / 10.0) * 100.0} />
              </div>
            </div> */}



            <TeamBox />

              
            





            {/* <div className="flex-column align-center justify-center card grid-item box span-1-v">
              <div>
                <i className="fas fa-gift"></i>
              </div>
              <div className="full margin-top-1">
                <Bar progress={79} />

                <div className="number-label margin-top-1">
                  79/100 mi.
                </div>
              </div>

              

              
                
            </div>

            <div className="align-center justify-center card grid-item grasss box">
              <div>
                <i className="fas fa-dollar-sign"></i>

                <div className="margin-bottom-2"></div>

                <div className="number">$178</div>
                <div className="number-label">RAISED</div>
              </div>
            </div>

            <div className="flex-column card grid-item span-3-v box">

              <h2>Leaderboards</h2>
              <table className="basic">
                {[,1,2,3,4,5,6,7,8,9,10].map(i => {
                  return (
                    <tr>
                      <td>#{i}</td>
                      <td>Andy Simon</td>
                    </tr>
                  )
                })}
                
              </table>
            </div> */}



            
          </div>
        





          

          
          
    

          {/* {currentUser &&
            <div className="profile-body">

              

              {raceLogs && raceLogs.map( (log, logIndex) => {
                return(
                  <div className="card">

                    <div className="flex-cont margin-bottom-1">
                      <div className="label">
                        {log.created_at && DateTime.fromISO(log.created_at).toLocaleString(DateTime.DATETIME_MED)}
                      </div>
                    </div>

                    

                    

                    <div className="flex-cont align-center margin-bottom-1">
                      <div className="number-cont">
                        <div className="number font-1-5 bold-900">
                          {log.total_laps}
                        </div>
                        <div className="label">
                          LAPS
                        </div>
                      </div>
                      
                      <div className="margin-left-2 number-cont">
                        <div className="number font-1-5 bold-900">
                          {log.total_miles}
                        </div>
                        <div className="label">
                          MILES
                        </div>
                      </div>

                      <div className="margin-left-2 number-cont">
                        <div className="number font-1-5 bold-900">
                          {log.total_time}
                        </div>
                        <div className="label">
                          TIME
                        </div>
                      </div>
                    </div>

                    <div className="flex-cont margin-bottom-1">
                      { [...Array(3).keys()].map( i => {
                        if (logIndex === 0) {
                          return (
                            <div className="badge margin-right-2 font-075">
                              <i className="fas fa-medal"></i>
                            </div>
                            
                          )
                        }

                        return null;
                      })}
                    </div>

                    <table className="margin-top-1">
                      {log.laps && log.laps.map( (lap, index) => {
                        return(
                          <tr>
                            <td className="padding-right-05 gray font-075 bold">
                              {lap.best &&
                                <i className="fas fa-star"></i>
                              }
                            </td>
                            <td className="padding-right-1 gray font-075 bold">{index+1}</td>


                            <td className="padding-right-1">{lap.time}</td>

                            <td className="gray">{lap.miles} mi</td>

                          </tr>

                         
                        )
                      })}
                    </table>
                    
                    
                  </div>
                )
              })} */}

              



              {/* <Outlet />
            </div> */}
          {/* }
        </div>
      </div> */}
    </>
  );
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
  };
} 

export default connect(mapState)(ProfileHome);
