import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { createPledge, getPledgePage } from 'redux/user/pledgeActions';
import { getPledgePagePreview } from '../../../redux/club/pledgeActions';
import { useApi } from '../../../api';
import Bar from 'components/Bar';
import CountUp from 'components/CountUp';
import Modal from 'components/Modal';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { loadStripe } from "@stripe/stripe-js";
import { 
  Elements,
  CardElement,
  useStripe,
  useElements 
} from "@stripe/react-stripe-js";

import PledgeSidebar from './PledgeSidebar';
import Pledge from '../../pledge/Pledge';



const ClubPledgePreview = () => {

  const navigate = useNavigate();

  let { id } = useParams();

  const [pledgePage, pledgePageLoading, pledgePageLoaded, pledgePageError, doPledgePageFetch, setPledgePageResult] = useApi(); 

  useEffect(() => {
    if (id) {
      fetchPledgePage();
    }
  }, [id])

  const fetchPledgePage = () => {
    if (id) {
      doPledgePageFetch(getPledgePagePreview, {query: {pledge_drive_id: id}});
    }
    
  }


  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(o => !o);
  }

  const [dollars, setDollars] = useState(5);
  
  const isPreview = true;

  return (

    <>

      

      
      <div style={{
        backgroundColor: 'black',
        color: 'white',
        padding: '1em',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 100
      }}>
        This is a preview of the pledge page. 

        <div onClick={() => navigate(`/campaigns/${id}`)} className="margin-left-1 button">
          Go Back
        </div>
      </div>

      <Outlet context={[pledgePage, isPreview]}/>




    </>

 
  )
}

export default ClubPledgePreview;