

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createRaceFile, updateRaceFile, deleteRaceFile, getRaceFiles, processRaceFile } from 'redux/admin/raceFileActions';


import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'

import { API_URL } from '../../api.js';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    maxNumberOfFiles: 1,
    allowedFileTypes: ['.csv']
  },
  autoProceed: true
})


uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const RaceFileSchema = yup.object().shape({
  // name: yup.string().required("Name is required."),
  file: yup.string().required("Please upload a .CSV file."),
  // .required("Please upload a .CSV file."),
});





const RaceFileForm = ({toggleModal, currentRaceFile}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: RaceFileSchema
  });

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const [raceFilesResult, raceFilesLoading, raceFilesLoaded, raceFilesError, doFetchRaceFiles, setRaceFileResults] = useApi();

  

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const onSubmit = async data => {
    let d = {
      race_file: {
        ...data
      }
    }
    // alert(JSON.stringify(d));
    doFetch(createRaceFile, {payload: d});

  }


  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentRaceFile ? 'Edit' : 'New'} RaceFile</h2>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input ref={register} type="hidden" name="file" value={file} />

        <DragDrop
          className="andy"
          uppy={uppy}
          locale={{
            strings: {
              // Text to show on the droppable area.
              // `%{browse}` is replaced with a link that opens the system file selection dialog.
              dropHereOr: 'Drop here or %{browse}',
              // Used as the label for the link that opens the system file selection dialog.
              browse: 'browse'
            }
          }}
        />

        <StatusBar uppy={uppy} />

        <div className="field-error">
          {errors?.file?.message}
        </div>

        {fileURL &&
          <div className="margin-top-1">
            <a href={fileURL}>DOWNLOAD</a>
          </div>
        }


      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminRaceFiles = ({currentUser}) => {

  const [raceFiles, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedRaceFile, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedRaceFile] = useApi(); 

  const [processResult, processLoading, processLoaded, processError, doProcessFetch, setProcessResult] = useApi();

  const [modalOpen, setModalOpen] = useState(false);
  const [editingRaceFile, setEditingRaceFile] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    // alert('here');

    if (reload) {
      fetchRaceFiles();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingRaceFile(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!raceFiles) {
      fetchRaceFiles();
    }
  }, []);

  const fetchRaceFiles = () => {
    doFetch(getRaceFiles);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRaceFile, setDeletingRaceFile] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingRaceFile, setViewingRaceFile] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchRaceFiles();
    }
  }

  const toggleViewModal = () => {
    setViewModalOpen(o => !o);
  }


  const editClicked = raceFile => {
    setEditingRaceFile(raceFile);
    toggleModal(false);
  }

  const deleteClicked = raceFile => {
    setDeletingRaceFile(raceFile);
    toggleDeleteModal();
  }

  const viewClicked = raceFile => {
    setViewingRaceFile(raceFile);
    toggleViewModal();
  }

  const confirmDestroy = () => {
    if (deletingRaceFile) {
      doDeleteFetch(deleteRaceFile, {query: {id: deletingRaceFile.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);



  

  const processFile = (id) => {
    doProcessFetch(processRaceFile, {query: {id: id}});

    setTimeout(() => {
      fetchRaceFiles();
    }, 1000);
  }
  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <RaceFileForm currentRaceFile={editingRaceFile} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1"><h1>Race Files</h1></div>
        <div><div onClick={() => toggleModal(false)} className="button">New Race File</div></div>
      </div>
      
      {loading &&
        <div className="spinner"></div>
      }

      {raceFiles &&
        <table className="basic">
          {raceFiles.map(raceFile => {
            return (
              <tr>
                {/* <td>
                  {raceFile.laps && raceFile.laps.map( lap => {
                    return(
                      <div>
                        {JSON.stringify(lap)}
                      </div>
                    )
                  })}
                </td> */}

                <td className="bold fit nowrap">
                  {raceFile?.race_name}
                </td>
                
                <td className="font-075 fit nowrap">{raceFile.processed ? "PROCESSED" : "NOT PROCESSED"}</td>
                <td className="fit nowrap">
                  {raceFile.fileURL &&
                    <a target="_blank" href={raceFile.fileURL}>DOWNLOAD</a>
                  }
                </td>
                <td></td>
                <td className="bold fit nowrap">
                  <div onClick={() => viewClicked(raceFile)} className="ghost button">VIEW</div>
                </td>
                <td className="bold fit nowrap">
                  {raceFile.fileURL &&
                    <div onClick={() => processFile(raceFile.id)} className="ghost button">PROCESS</div>
                  }
                </td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(raceFile)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(raceFile)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Race File</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete this race file?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {viewModalOpen && viewingRaceFile &&
        <Modal toggleModal={toggleViewModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Race File</h1>
              <div onClick={toggleViewModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">
            <h2>Laps</h2>
            <div className="margin-bottom-1"></div>
            {viewingRaceFile.laps &&
              <table className="basic">
                {viewingRaceFile.laps.map(lap => {
                  return(
                    <tr>
                      <td className="bold fit nowrap">{lap.athlete_name}</td>
                      <td className="fit nowrap">{lap.time}</td>
                      <td className="fit nowrap">{lap.epc}</td>
                      <td></td>
                    </tr>
                  )
                })}
              </table>
            
            }
          </div>

          {/* <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div> */}
        </Modal>
      }

      {!loading && !raceFiles &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminRaceFiles; 