

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { createClubOwnerships } from 'redux/club/clubOwnershipActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom"


const PaletteSchema = yup.object().shape({
  invites: yup.array().compact().of(
    yup.object().shape({
      first_name: yup.string().required("First name is required."),
      last_name: yup.string().required("Last name is required."),
      email: yup.string().required("Email is required.").matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Email must be valid."),
      role: yup.string().required("Role is required."),
    })
  )
});


const UserFields = ({currentUser, setValue, removeUser, hide, fieldName, register, errors, i, index, q}) => {

  const roles = {
    "School Admin": "club-admin",
    "Team Admin": "team-admin",
    "PE Teacher": "pe-teacher",
    "Run Monitor": "run-monitor"
  }


  return (
    <div key={index}>
    {/* {i}
    {index}
    {fieldName} */}

    {/* <div className="code">
      <pre>
        {JSON.stringify(currentUser, null, 2)}
      </pre>
    </div> */}
    <fieldset className={hide ? 'hidden' : ''} name={fieldName} key={fieldName}>      
        <div className="flex-cont align-center margin-bottom-2">

          <label className="relative no-margin" for={`${fieldName}first_name`}>
            <input 
              name={`${fieldName}first_name`} 
              ref={register()}
              type="text" 
              placeholder="First Name"
              style={{marginRight: '10px'}}
              // defaultValue={currentUser?.first_name}
            />
            {errors?.first_name && <div className="field-error">{errors?.first_name?.message}</div>}
          </label>
          <label className="relative no-margin" for={`${fieldName}last_name`}>
            <input 
              name={`${fieldName}last_name`} 
              ref={register()}
              type="text" 
              placeholder="Last Name"
              style={{marginRight: '10px'}}
              // defaultValue={currentUser?.first_name}
            />  
            {errors?.last_name && <div className="field-error">{errors?.last_name?.message}</div>}
          </label>

          <label className="relative no-margin" for={`${fieldName}email`}>
            <input 
              name={`${fieldName}email`} 
              ref={register()}
              type="text" 
              placeholder="Email"
              style={{marginRight: '10px'}}
              // defaultValue={currentUser?.first_name}
            />  
            {errors?.email && <div className="field-error">{errors?.email?.message}</div>}
          </label>

          <label className="relative no-margin" for={`${fieldName}role`}>
            <select name={`${fieldName}role`}  ref={register()}>
              {Object.entries(roles).map(([label, value]) => 
                <option value={value}>{label}</option>
              )}
            </select>
            {errors?.role && <div className="field-error">{errors?.role?.message}</div>}
          </label>

          <div className="ghost button" onClick={() => removeUser(index)}>
            <i className="fas fa-times"></i>
          </div>  
        </div>


        {/* <div className="code">
          <pre>
            ERRORS: {JSON.stringify(errors, null, 2)}
          </pre>
        </div> */}

        


        {/* <div className="flex-cont">
          <div className="flex-1"></div>
          
            <div className="text-link" onClick={() => removeUser(index)}>REMOVE</div>
        
        </div> */}
      
      
    </fieldset>
    </div>
  )
}



export const ClubStaffForm = ({redirectPath, toggleModal, currentPalette}) => {
  const navigate = useNavigate();
  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    validationSchema: PaletteSchema
  });

  const [indexes, setIndexes] = useState([0]);
  const [removedIndexes, setRemovedIndexes] = useState([]);
  const [counter, setCounter] = useState(0);
  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  
  const addUser = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter + 1]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeUser = (index) => {
    const fieldName = `invites[${index}].`;
    // setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    unregister(`invites[${index}].id`)
    // setValue(`${fieldName}[_destroy]`, true);
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
  };


  const onSubmit =  data => {

    doFetch(createClubOwnerships, {payload: data});
    
  }


  useEffect(() => {
    if (loaded) {
      if (redirectPath) {
        navigate(redirectPath)
      } 
      else {
        navigate('/staff')
      }
      
    }
  }, [loaded])

  const users = null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="code">
        <pre>
          {JSON.stringify(errors, null, 2)}
        </pre>
      </div> */}

      {/* <div onClick={() => alert(JSON.stringify(getValues(), null, 2))} className="button">Get Values</div> */}

      {indexes.map((index, i) => {
        const fieldName = `invites[${index}].`;
        const q = (users && users[index]) ? users[index] : {}
        const hide = removedIndexes.includes(index);
        const myErrors = errors?.invites?.[index];

        if (hide) return null;

        return (
          
          <UserFields 
            key={index}
            setValue={setValue}
            removeUser={removeUser} 
            hide={hide} 
            fieldName={fieldName} 
            register={register} 
            errors={myErrors}
            i={i}
            index={index} 
            q={q} 
            currentUser={q}
          />


        );
      })}
      <div className="margin-top-0">
        <div className="ghost button" onClick={addUser}>
          +
          ADD STAFF
        </div>
      </div>








      <div className="margin-top-3 align-center flex-cont">
        <input type="submit" value="Send Invites" className="button" />
      </div>
      

    </form>
  )

}

export default ClubStaffForm;