import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getTeam } from 'redux/club/teamActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubTeamNav from './ClubTeamNav';

import TeamForm from './TeamForm';

const ClubTeamLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTeam()
    }
  }, [id]);

  const [team, teamLoading, teamLoaded, teamError, doFetchTeam, setTeamResults] = useApi();

  const fetchTeam = () => {
    doFetchTeam(getTeam, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editTeamClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchTeam();
    }
  } 
  
  

  return (
    <>
      {team && editModalOpened &&
        <TeamForm toggleModal={toggleEditModal} currentTeam={team} />
      }
      <div className="narrow-2x padding-top-1">
        <div className="flex-cont align-center">
          {team?.avatar_url &&
            <div className="team-logo-cont small margin-right-2">
              <img src={team?.avatar_url} alt="" />
            </div>
          }
          <h1>
            {team?.name}
          </h1>

          <div className="flex-1"></div>

          <div>
            <i onClick={editTeamClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubTeamNav id={id} />

        {children}


      </div>
    </>
  )

}



export default ClubTeamLayout;