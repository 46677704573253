

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getAwardRuleLap } from 'redux/admin/awardRuleLapActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import ClubStaffForm from './ClubStaffForm';


export const ClubStaffEdit = () => {
  let { id } = useParams();
  const [awardRuleLap, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAwardRuleLap();
  }, [id]);

  const fetchAwardRuleLap = () => {
    doFetch(getAwardRuleLap, {query: {id: id}});
  }


  return (
    <>

      <h1>Edit Lap Award</h1>

      {/* <div className="code">
        <pre>
          {JSON.stringify(awardRuleLap, null, 2)}
        </pre>
      </div> */}
      <ClubStaffForm currentAwardRuleLap={awardRuleLap} />
    </>
  )
}

const ClubStaffNew = () => {

  return (
    <>
      <h2 className="no-margin-top">Invite Staff Members</h2>

      <ul>
        <li>
          <strong>School Admins</strong> have full access to all aspects of the school's Hero Run account, including financial information.
        </li>
        <li>
          <strong>Team Admins</strong> can view and manage competitions and view class/team leaderboards. They can be a teacher or parent.
        </li>
        <li>
          <strong>PE Teachers</strong> can start activities and have access to fitness stats for all students.
        </li>
        <li>
          <strong>Run Monitors</strong> can start free runs. 
        </li>
      </ul>

      <ClubStaffForm />
 
    </>
  )
}




export default ClubStaffNew;