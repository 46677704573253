import { andyFetch } from '../../api';

export const getAthletes = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/athletes";

    try {
      const response = await andyFetch(url, params, "GET");
      console.log(response);
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        console.log(response);

        throw ({error: true, statusCode: response.status, statusText: response.statusText})
      }
    }
    catch (e) {
      console.log(e);
      console.log("getAthletes error", e);

      return e;
    }
  }
};

export const getAthlete = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/athletes/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAthlete error", e);
    }
  }
};


export const createAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/athletes";

    try {

      const response = await andyFetch(url, params, "POST");

      console.log("Response 2", response)


      if (response.ok) {
        console.log("R 1a");
        const json = await response.json();
        console.log("R 1b", json);
        return json.data;

      } else {
        console.log("R 2");
        throw new Error('createAthlete error.');
      }
    }
    catch (e) {
      console.log("R 3");
      // return {error: e.toString()}
      console.log("createAthlete error", e);
    }
  }
};


export const updateAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/athletes/${params.payload.athlete.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAthlete error.');
      }
    }
    catch (e) {
      console.log("updateAthlete error", e);
    }
  }
};


export const deleteAthlete = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/athletes/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAthlete error.');
      }
    }
    catch (e) {
      console.log("deleteAthlete error", e);
    }
  }
};

