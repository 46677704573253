

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { getClubs } from 'redux/admin/clubActions';
import { getMachines, createMachine, updateMachine, deleteMachine } from 'redux/admin/machineActions';
import MachineForm from './MachineForm';

const MachineSchema = yup.object().shape({
  club_id: yup.string().required("Club is required."),

});






const AdminMachines = ({currentUser}) => {

  const [machines, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedMachine, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedMachine] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingMachine, setEditingMachine] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchMachines();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingMachine(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!machines) {
      fetchMachines();
    }
  }, []);

  const fetchMachines = () => {
    doFetch(getMachines);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingMachine, setDeletingMachine] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchMachines();
    }
  }


  const editClicked = machine => {
    setEditingMachine(machine);
    toggleModal();
  }

  const deleteClicked = machine => {
    setDeletingMachine(machine);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingMachine) {
      doDeleteFetch(deleteMachine, {query: {id: deletingMachine.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);


  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <MachineForm currentMachine={editingMachine} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Machines</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Machine</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {machines &&
        <table className="basic">
          {machines.map(machine => {
            return (
              <tr>
                <td className="bold fit nowrap">{machine.code}</td>
                <td className="fit nowrap">{machine.club?.name}</td>
                <td className="fit nowrap">
                  <a target="_blank" className="text-link margin-right-1" href={machine.lease_url}>{machine.lease?.metadata?.filename}</a> 
                </td>
                <td></td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(machine)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(machine)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Machine</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingMachine?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !machines &&
        <em>No machines.</em>
      }


      


    </>
  )
}


export default AdminMachines;