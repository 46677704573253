import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getAthletes } from 'redux/club/athleteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';
import { changeBand, giveCoins } from 'redux/club/athleteActions';
import { getBands } from '../../../redux/club/bandActions';
import coinSVG from 'images/coin.svg';


const GiveCoinsForm = ({toggleModal, currentAthlete}) => {

  let navigate = useNavigate();

  const { setValue, watch, register, errors, handleSubmit, getValues } = useForm({

  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [bands, bandsLoading, bandsLoaded, bandsError, doBandsFetch, setBandsResult] = useApi(); 

  
  const onSubmit = async data => {
    const d = {
      ...data
    };

    doFetch(giveCoins, {payload: d, query: {athlete_id: currentAthlete.id}});
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);



  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input ref={register()} type="hidden" name="athlete_id" value={currentAthlete.id} />
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentAthlete.full_name} - Coin Transaction</h2>

          <div onClick={() => toggleModal(false)} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="padding-1">

          <div className="flex-cont">
            <label className=''>
              <span>Amount</span>
              <br/>
              <div className="flex-cont align-center relative">
                <input ref={register()} className='medium left-padding left-padding-coin' step={1}  type="number" name="earned" id="" />
                <div className="inner-label left">
                  <img className="margin-right-half" style={{paddingTop: '4px', width: '1em'}} src={coinSVG} alt="" />
                </div>
              </div>
            </label>
          </div>
          <div className="flex-cont">
            <label className=''>
              <span>Memo</span>
              <br/>
              <div className="flex-cont align-center relative">
                <input ref={register()} type="text" name="description" id="" />
              </div>
            </label>
          </div>

          


        </div>
        <div className="modal-footer flex-cont align-center">
          <button className="button">Save</button>
          <div className="flex-1"></div>
        </div>
      </form>


    </Modal>
  )

}

export default GiveCoinsForm;