import { andyFetch } from '../../api';

export const getPayments = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/payments";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPayments error", e);
    }
  }
};


export const createPayment = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/payments";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPayment error.');
      }
    }
    catch (e) {
      console.log("createPayment error", e);
    }
  }
};


export const updatePayment = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/payments/${params.payload.payment.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePayment error.');
      }
    }
    catch (e) {
      console.log("updatePayment error", e);
    }
  }
};


export const deletePayment = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/payments/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePayment error.');
      }
    }
    catch (e) {
      console.log("deletePayment error", e);
    }
  }
};

