import { andyFetch } from '../../api';

export const sendUserInvites = (params) => {

  return async (dispatch, getState) => {

    const url = "club/user_invites/send_invites";

    try {
      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("sendUserInvites error", e);
    }
  }
};