

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { getClubs } from 'redux/admin/clubActions';
import { getMachines, createMachine, updateMachine, deleteMachine } from 'redux/admin/machineActions';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../api'
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput, useUppy } from '@uppy/react'

const MachineSchema = yup.object().shape({
  club_id: yup.string().required("Club is required."),

});

const MachineForm = ({toggleModal, currentMachine}) => {

  const { setValue, watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: MachineSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [machinesResult, machinesLoading, machinesLoaded, machinesError, doFetchMachines, setMachineResults] = useApi();
  const [clubs, clubsLoading, clubsLoaded, clubsError, doFetchClubs, setClubResults] = useApi();

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState(currentMachine?.lease);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    doFetchClubs(getClubs);
  }, []);

  const onSubmit = async data => {
    let d = {
      machine: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentMachine && currentMachine.id) {
      doFetch(updateMachine, {payload: d});
    }
    else {
      doFetch(createMachine, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [selectedClub, setSelectedClub] = useState(null);

  const clubSelectChanged = (e) => {
    const id = e.target.value;
    const t = clubs.find(t => t.id.toString() === id.toString());
    setSelectedClub(t);
  }


  const uppy = useUppy(() => {
    return new Uppy({
      // meta: { type: 'image' },
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.pdf']
      }
    }).use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
  })

  useEffect(() => {
    return () => uppy?.current?.close();
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = {
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        }
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }


    
  }, [uppy]);

  const removeFile = () => {
    
    setFile("");
    setFileURL(null);
    setValue("lease", null);
    setValue("remove_lease", "true");
    uppy.reset();
  }

  const filePresent = file || currentMachine?.lease_url;

  const getChooseFiles = () => {
    return (file) ? "Upload different file" : "Choose lease file"
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentMachine ? 'Edit' : 'New'} Machine</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">



        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentMachine?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="club_id">
            <span>Club</span>
            {clubsLoading &&
              <div className="spinner"></div>
            }
            {clubs &&
              <select defaultValue={currentMachine?.club?.id} onChange={clubSelectChanged} ref={register} name="club_id">
                <option value="" disabled selected>Select your option</option>
                {clubs.map(club => <option value={club.id}>{club.name}</option>)}
              </select>
            }
            {errors.club_id && <div className="field-error">{errors.club_id.message}</div>}
          </label>
        </div>

        {/* <div>
          <pre>
            {JSON.stringify(currentMachine, null, 2)}
          </pre>
        </div> */}

        <div className="flex-cont">
          <label htmlFor="lease">
            <span>Lease</span>
            <div className="margin-bottom-1"></div>

            <div className="flex-cont align-center">
              {file && 
                <>
                  {(currentMachine?.lease && file.id === currentMachine?.lease?.id) ? (
                      <a target="_blank" className="text-link margin-right-1" href={currentMachine?.lease_url}>{file?.metadata?.filename}</a> 
                    ) : (
                      <div className="bold margin-right-1">{file?.metadata?.filename}</div>
                    )
                  }
                </>
              }

                
              {uppy &&
                <>
                  <FileInput 
                    uppy={uppy} 
                    className="outline button"
                    inputName={"lease"}
                    locale={{
                      strings: {
                        chooseFiles: "Choose file"
                      }
                    }}
                  />
                  
                </>
              }
              <div>
                {(file) && <div onClick={removeFile} className="margin-left-half button ghost danger">Remove File</div> }
              </div>

            </div>
            <div>
              <StatusBar uppy={uppy} />
            </div>
            
            
          </label>
        </div>

        <input style={{display: 'none'}} ref={register} type="text" name="lease" value={file ? JSON.stringify(file) : null} />
        <input style={{display: 'none'}} ref={register} type="text" name="remove_lease" defaultValue="" />


        

          
          

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}


export default MachineForm;