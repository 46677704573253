import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { DateTime } from "luxon";
import { getMyClubs } from 'redux/user/userActions';
import { cluboutUser } from 'redux/session/sessionActions';
import store from 'redux/store';

import { Link } from 'react-router-dom';

const ProfileActivity = () => {


  const [clubs, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchMyClubs();
  }, []);

  const fetchMyClubs = async () => {
    doFetch(getMyClubs);
  };

  return (
    <>
      <h2 className="mobile-center">Clubs</h2>

      <div className="margin-bottom-2"></div>

      {loading &&
        <div className="spinner"></div>
      }

      {!loading && (!clubs || clubs.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-flag blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">You are not a member of any clubs.</div>
          <div className="margin-top-2">
            <Link to="/club-search" className="button">Apply to a Club</Link>
          </div>
        </div>
      }
      

      {clubs && clubs.map( (club, clubIndex) => {
        return(
          <div className="card flex-cont align-center">
            <div className="margin-right-1 bold">{club.athlete_name}</div>
            <div className="flex-1 margin-right-1">{club.club_name}</div>

            <div>{club.status}</div>
            
          </div>
        )
      })} 
    </>
  )
}

export default ProfileActivity;