import { andyFetch } from '../../api';

export const getMyNotis = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/my_notis";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        const data = json.data;


        // dispatch({
        //   type: 'SET_NOTI_POPUPS', 
        //   notis: data
        // });

        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getMyCurrentRaceLogs = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/current_race_logs";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getDashboardStats = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/dashboard_stats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getMyWeek = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/my_week";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTeamStats = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/team_stats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getSponsorStats = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/sponsor_stats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getPledgeStats = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/pledge_stats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getCareerStats = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/athletes/career_stats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getCoinTransactions = (params) => {
  return async (dispatch, getState) => {

    const url = `athlete/athletes/coin_transactions`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();

        return {
          json: json,
          fullResponse: response,
        }

        // alert(JSON.stringify(json, null, 2))
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCoinTransactions error", e);
    }
  }
}


