
import React, { useEffect, useState } from 'react';
import coinSVG from 'images/coin.svg';
import 'styles/Noti.scss';

const Coin =({}) => {

  return (
    <div class="coinx" style={{

    }}>
       <div class="coin__front" style={{
        backgroundImage: `url(${coinSVG})`
      }}></div>    
      
       <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>

        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div><div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>
        <div className="coin-edge"></div>

       <div class="coin__back"
       style={{
        backgroundImage: `url(${coinSVG})`
      }}></div>
      <div class="coin__shadow"
       style={{
        
      }}></div> 
    </div>
  )
}

export default Coin;