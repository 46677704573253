import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubBandNav from './ClubBandNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getBand, changeAthlete } from 'redux/club/bandActions';
import { DateTime } from 'luxon';

const ClubBand = ({}) => {

  const { code } = useParams();

  useEffect(() => {
    if (code) {
      fetchBand()
    }
  }, [code]);

  const [band, bandLoading, bandLoaded, bandError, doFetchBand, setBandResults] = useApi();

  const fetchBand = () => {
    doFetchBand(getBand, {query: {code: code}})
  }


  const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  const changeAthleteClicked = () => {
    setAthleteModalOpened(true);
  }
  const toggleAthleteModal = (reload=false) => {

    setAthleteModalOpened(o => !o);
    if (reload) {
      fetchBand();
    }
  }
 
  return (
    <>


      {band &&
        <>  
          <table className="vertical">
            <tr>
              <th>Student</th>
              <td>

                <div className="flex-cont align-center">
                  {band.athlete_id &&
                    <Link className="text-link margin-right-1" to={`/students/${band.athlete_slug}`}>
                      {band.athlete_name}
                    </Link>
                  }
                  {!band.athlete_id &&
                    <div className="margin-right-1">
                      <em>No student assigned.</em>
                    </div>
                  }
                  <span className="margin-left-halfx bold">
                    {band.status.toUpperCase()} 
                  </span>
                  <div onClick={changeAthleteClicked} className="outline button margin-left-2">
                    Change
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Code</th>
              <td>{band.code}</td>
            </tr>
            <tr>
              <th>EPC</th>
              <td>
                {band.epc}
              </td>
            </tr>
            {/* <tr>
              <th>Status</th>
              <td>{band.status?.toUpperCase()}</td>
            </tr> */}
          </table>

          

          {/* <pre>
            {JSON.stringify(band, null, 2)}
          </pre> */}

          {athleteModalOpened &&
            <ChangeAthleteForm currentBand={band} toggleModal={toggleAthleteModal} />
          }




        </>

      }

      <div className="margin-top-3">

        <h3>History</h3>

        <table className="basic">
          {band?.band_athletes?.map(bandAthlete => 
            <tr>
              <td className="fit nowrap">{bandAthlete.start_datetime ? DateTime.fromISO(bandAthlete.start_datetime).toLocaleString(DateTime.DATETIME_SHORT) : "---"}</td>
              <td className="fit nowrap">{bandAthlete.end_datetime ? DateTime.fromISO(bandAthlete.end_datetime).toLocaleString(DateTime.DATETIME_SHORT) : "---"}</td>
              <td>{bandAthlete.athlete_name}</td>
            </tr>
          
          )}
        </table>
        
      </div>
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(band, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div>{item.first_name} {item.last_name}</div>
      {/* <div className="uppercase margin-left-1 margin-right-1 bold">{item.status}</div> */}
    </div>
  )
}

const ChangeAthleteForm = ({toggleModal, currentBand}) => {

  let navigate = useNavigate();

  const { setValue, watch, register, errors, handleSubmit, getValues } = useForm({

  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [athletes, athletesLoading, athletesLoaded, athletesError, doAthletesFetch, setAthletesResult] = useApi(); 

  useEffect(() => {
    fetchAthletes();
  }, []);

  const fetchAthletes = () => {
    doAthletesFetch(getAthletes, {query: {no_pagy: true}});
  }
  

  const onSubmit = async data => {
    const d = {
      ...data
    };

    doFetch(changeAthlete, {payload: d});
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const renderRow = item => <Row item={item} />

  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input ref={register()} type="hidden" name="id" value={currentBand?.id} />
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentBand.code}</h2>

          <div onClick={() => toggleModal(false)} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="padding-1">


          {/* <div onClick={() => alert(JSON.stringify(getValues(), null, 2))} className="button">

          </div> */}

          <label className="margin-bottom-2">
            <span className="margin-right-half">
              Status
            </span>
            <select defaultValue={currentBand.status} ref={register({required: "Status is required"})} name="status" id="status">
              {/* <option value="">Select status:</option> */}
              {currentBand?.statuses?.map(status =>
                <option value={status}>{status.toUpperCase()}</option>  
              )}


            </select>
          </label>



          <label className="margin-bottom-2">
            <span className="margin-right-half">
              Current Student:
            </span>
            <div className="">
              {currentBand.athlete_id ? (
                  <>{currentBand.athlete_name}</>
                ) : (
                  <em>No student assigned.</em>
                )
              }
              
            </div>
          </label>
          
          <AutoComplete 
            setValue={setValue}
            defaultValue={currentBand?.athlete_id}
            defaultSearch={currentBand?.athlete_name}
            name={"athlete_id"}
            items={athletes}
            fullName={"FULL NAME"}
            register={register}
            errors={errors}
            label="Assign to:"
            labelFn={(item) => `${item.first_name} ${item.last_name}` }
            valueFn={(item) => item.id }
            searchableAttributes={["first_name", "last_name"]}
            labelComponent={renderRow}
          />
        </div>
        <div className="modal-footer flex-cont align-center">
          <button className="button">Save</button>
          <div className="flex-1"></div>
          {/* <div onClick={toggleRemoveModal} className="danger ghost button">Remove</div> */}
        </div>
      </form>


    </Modal>
  )

}


export default ClubBand;