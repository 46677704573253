import React, { useState, useEffect, } from 'react';
import  chroma from "chroma-js";
import { renderToString } from 'react-dom/server'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { API_URL } from '../../api.js';


const Test = ({html}) => {
  return (
    <>
      {html}
    </>
  )
}

const getThumbnail = (svg) => {
  // USER: Set either a width OR height (will scale evenly)
  const WIDTH = 100;
  const HEIGHT = null;

  // Grab the SVG and remove any existing viewBox
  // const svg = document.querySelector('svg');
  svg.removeAttribute('viewBox');

  // Set default coordinates
  const coords = {
    top: Infinity,
    left: Infinity,
    bottom: -Infinity,
    right: -Infinity
  }; 

  // Filter SVG to visible elements
  const filterize = newSvg => {
    const newest = [...newSvg.children].filter(x => x.tagName !== 'defs' && x.tagName !== 'style' && x.tagName !== 'title' && x.tagName !== 'desc');
    if ((newest.length === 1 && newest.childElementCount > 0) || newest[0].tagName === 'g') {
      return filterize(newest[0]);  
    }  

    return newest.filter(x => (x.getBoundingClientRect().top !== 0 && x.getBoundingClientRect().left !== 0 && x.getBoundingClientRect().bottom !== 0 && x.getBoundingClientRect().right !== 0));
    
  } 

  // Get coordinates of SVG elements
  filterize(svg).forEach(x => {
    const {top, left, bottom, right} = x.getBoundingClientRect();
    if (top < coords.top) {
      coords.top = x.getBoundingClientRect().top;
    }
    if (left < coords.left) {
      coords.left = x.getBoundingClientRect().left;
    }
    if (right > coords.right) {
      coords.right = x.getBoundingClientRect().right;
    }
    if (bottom > coords.bottom) {
      coords.bottom = x.getBoundingClientRect().bottom;
    }
  }); 

  // Set viewBox based on coordinates
  svg.setAttribute('viewBox', `${coords.left.toFixed(2)} ${coords.top.toFixed(2)} ${(coords.right - coords.left).toFixed(2)} ${(coords.bottom - coords.top).toFixed(2)}`);

  // Set given width OR height
  WIDTH && svg.setAttribute('width', WIDTH);
  HEIGHT && svg.setAttribute('height', HEIGHT);  

  return svg.outerHTML;

}

const SVGChild = ({register, viewBox, left, top, scale, rotate, layer, html, layerType, baseColor='gray'}) => {
  const [color, setColor] = useState('gray');

  const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  
  useEffect(() => {
    const u = Uppy({
      meta: { type: 'test_svg[]' },
      restrictions: { 
        // maxNumberOfFiles: 2,
        allowMultipleUploads: true,
        allowedFileTypes: ['.svg']
      },
      autoProceed: true
    })

    u.use(AwsS3Multipart, { 
      companionUrl: API_URL
    });

    setUppy(u);
  }, [])

  useEffect(() => {

    if (layerType === 'base') {
      setColor(baseColor);
    }
    else if (layerType === 'highlights') {
      setColor(chroma(baseColor).brighten(3));
    }
    else if (layerType === 'shadows') {
      setColor(chroma(baseColor).darken(1.4));
    }
    else if (layerType === 'outline') {
      setColor(chroma(baseColor).darken(3));
    }

  }, [layerType, baseColor, setColor]);


  const addFile = () => {

    const h = toHtml();
    console.log("H", h)
    let blob = new Blob([h], {type: 'image/svg+xml'});

    uppy.addFile({
      name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + ".svg",
      type: blob.type,
      data: blob // changed blob -> data
    })
    console.log("BLOB", blob);


    return true;


  }

  const toHtml = () => {
    // const h = renderToString(<SVGChild viewBox={getViewBox(svg)} rotate={rotate} left={left} top={top} scale={scale} layer={layer} baseColor={baseColor} layerType={e.id} html={e.outerHTML} />)
    
    let tmp = document.createElement("div");
    
    let svgString = `<svg viewBox='${viewBox}' id='${layerType}' xmlns='http://www.w3.org/2000/svg'></svg>`;
    tmp.innerHTML = svgString;

    let svgElem = tmp.querySelector('svg');
    svgElem.innerHTML = html;

    console.log('svgString', svgString)
    console.log("svgElem", svgElem)
    console.log("---TO HTML", svgElem.outerHTML)

    return svgElem.outerHTML
  }

  useEffect(() => {
    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);  
        setFile(uploadedFileData);
      })
  
    }
  }, [uppy])

  useEffect(() => {
    if (html && uppy) {
      addFile();
    }
  }, [html, uppy])




  return (
    <>
      <input ref={register} type="hidden" name={`test_svg.${layerType}`} value={file} />
      <svg 
        style={{
          left: `${left}%`,
          top: `${top}%`,
          width: `${scale}%`,
          transform: `rotate(${rotate}deg)`,
          zIndex: layer+1000, 
        }} 
        viewBox={viewBox} 
        fill={color} 
        xmlns="http://www.w3.org/2000/svg" 
        id={layerType} 
        dangerouslySetInnerHTML={{__html: html}}
    >


      </svg>
    </>
  )
}

export const SVG = ({uppy, register, baseColor, url, left, top, scale, rotate, avatarScale, layer}) => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then(res => res.text())
        .then(setSvg)
        .catch(setIsErrored)
        .then(() => setIsLoaded(true))
    }
  }, [url]);


  const [childPaths, setChildPaths] = useState([]);


  useEffect(() => {
    if (svg) {
      setChildPaths(svgDOM(svg));
    }
  }, [svg]);

  // useEffect(() => {
  //   if (childPaths && childPaths.length !== 0) {
  //     addFiles();
  //   }
  // }, [childPaths]);

  // const addFiles = () => {
  //   [...childPaths].forEach((e, i) => {
  //     const h = toHtml(e);
  //     let blob = new Blob([h], {type: 'image/svg+xml'});

  //     uppy.addFile({
  //       name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + ".svg",
  //       type: blob.type,
  //       data: blob // changed blob -> data
  //     })
  //     // console.log("BLOB", blob);
  //   })

  //   return true;


  // }

  // const toHtml = (e) => {
  //   const h = renderToString(<SVGChild viewBox={getViewBox(svg)} rotate={rotate} left={left} top={top} scale={scale} layer={layer} baseColor={baseColor} layerType={e.id} html={e.outerHTML} />)
    
  //   return h;
  // }

  const removeHTML = (str) => { 
    if (!svg) return "";
    var tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.firstChild.innerHTML;
  }

  const getViewBox = (str) => {
    if (!svg) return "";
    var tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.firstChild.getAttribute("viewBox");
  }

  const svgDOM = (str) => { 
    if (!svg || !str) return [];
    var tmp = document.createElement("div");
    tmp.innerHTML = str;

    console.log("tmp?.firstChild", tmp?.firstChild)

    console.log(tmp?.firstChild?.children)
    return [...tmp?.firstChild?.children];
  }

  const layers = () => {
    if (!svg) return "";
    const s = svgDOM(svg);
    const array = s.querySelector('#main_group');

    return s;
  }
  
  



  return (
    <>
      {svg &&
        <>
          {/* <div>
            {svgDOM(svg) && svgDOM(svg).length !== 0 && svgDOM(svg).length}
          </div> */}
          {/* <div className="code">
            {[...childPaths].map((e, i) => 
              <div>
                {JSON.stringify(e.outerHTML, null, 2)}
              </div>
            )}
          </div> */}

            {[...childPaths].map((e, i) => {
              return(
                <>
                  {/* <input ref={register()} name={`svgs[].layer_type`} type="text" value={e.id} /> */}
                  <SVGChild register={register} viewBox={getViewBox(svg)} rotate={rotate} left={left} top={top} scale={scale} layer={layer} baseColor={baseColor} layerType={e.id} html={e.outerHTML} />
                </>
              )

            }
            
            )

            }
          {/* <svg 
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${scale}%`,
              transform: `rotate(${rotate}deg)`,
              zIndex: layer+1000, 
            }} 
            viewBox={getViewBox(svg)} 
            fill={'purple'} 
            xmlns="http://www.w3.org/2000/svg" 
          >
            
          </svg> */}
        </>
      }
    </>
  )
}

const AvatarBase = () => {

  const url = "https://fyzeak.s3-us-west-1.amazonaws.com/base_avatar_4.svg";

  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    if (url) {
      fetch(url)
      .then(res => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))
    }
      
  }, [url]);

  const removeHTML = (str) => { 
    if (!svg || isErrored) return "";
    var tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.firstChild.innerHTML;
  }

  const getViewBox = (str) => {
    if (!svg || isErrored) return "";
    var tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.firstChild.getAttribute("viewBox");
  }

  return (
    <>
      {svg &&
        <svg viewBox={getViewBox(svg)} fill={'purple'} dangerouslySetInnerHTML={{ __html: removeHTML(svg) }} xmlns="http://www.w3.org/2000/svg" >
{/* width="1118.35432" height="3033.21382" viewBox="0 0 1118.35432 3033.21382" */}
        </svg>
      }
        {/* <div 
            className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
            dangerouslySetInnerHTML={{ __html: svg }}
        />

      <svg xmlns="http://www.w3.org/2000/svg" width="1118.35432" height="3033.21382" viewBox="0 0 1118.35432 3033.21382">
        <use xlinkHref={url}>
        </use>
      </svg> */}
    </>
  )
}


export default AvatarBase;