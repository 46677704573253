

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getClubs } from 'redux/admin/clubActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createClub, updateClub, deleteClub, getClub, acceptClub, rejectClub } from 'redux/admin/clubActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import { ClubForm } from './AdminClubs';



export const AdminClub = () => {

  const [club, loading, loaded, error, doFetch, setResult] = useApi(); 

  let { id } = useParams();

  useEffect(() => {
    fetchClub();
  }, [])

  const fetchClub = () => {
    doFetch(getClub, {query: {id: id}});
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [editingClub, setEditingClub] = useState(null);

  const editClicked = club => {
    setEditingClub(club);
    toggleModal();
  }

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchClub();
    }
  }
  

  // const onSubmit = async data => {
  //   let d = {
  //     charity: {
  //       ...data
  //     }
  //   }
  // }

  return (
    <>
      {loading &&
        <div className="spinner"></div>
      }

      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <ClubForm currentClub={editingClub} toggleModal={toggleModal} />
        </Modal>
      }

      {club &&
        <>  
          <div className="flex-cont flex-wrap">
            <div className="flex-1">
                <div style={{fontSize: '10rem', backgroundImage: `url( ${club?.avatar_url} )`}} className="avatar-cont">
                </div>
                <h1 className="no-margin-bottom">{club.name}</h1>
                <div>{club.full_address}</div>
            </div>

            <div>
              <div onClick={() => editClicked(club)} className="button">
                <i className="fas fa-pencil-alt margin-right-half"></i>

                <span>Edit Club</span>
              </div>
            </div>
            
          </div>

          <div className="margin-top-1"></div>
          <div className="font-1 bold">
            ${club.upgrade_price}/upgrade
          </div>
          


          


          {/* <h2>Tracks</h2>

          <div className="margin-bottom-1"></div>

          
          {club.tracks &&
            <table className="basic">
              {club.tracks.map(track => 
                <tr>
                  
                  <td className="fit nowrap">
                    <Link to={`/tracks/${track.id}`}></Link>
                    {track.name}
                  </td>
                </tr>
              )}
            </table>
          }

          {!club.tracks &&
            <em>No tracks.</em>
          } */}


        </>

      }
    </>
  )
}


export default AdminClub;