

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAvatarParts } from 'redux/admin/avatarPartActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createAvatarPart, updateAvatarPart, deleteAvatarPart, getAvatarPart} from 'redux/admin/avatarPartActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'

import SVG from './SVG';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';


const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.svg']
  },
  autoProceed: true
})
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})
const AvatarPartSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
});



const AdminAvatarParts = ({currentUser}) => {

  let { id } = useParams();

  const [avatarParts, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedAvatarPart, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedAvatarPart] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingAvatarPart, setEditingAvatarPart] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchAvatarParts();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingAvatarPart(null);
    }
  }, [modalOpen])

  useEffect(() => {
    // salert(id);

      fetchAvatarParts();
    
  }, [id]);


  const fetchAvatarParts = () => {
    //  alert('here');
    doFetch(getAvatarParts, {query: {tab: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingAvatarPart, setDeletingAvatarPart] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingAvatarPart, setViewingAvatarPart] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchAvatarParts();
    }
  }


  const editClicked = avatarPart => {
    setEditingAvatarPart(avatarPart);
    toggleModal();
  }

  const deleteClicked = avatarPart => {
    setDeletingAvatarPart(avatarPart);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingAvatarPart) {
      doDeleteFetch(deleteAvatarPart, {query: {id: deletingAvatarPart.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  const viewClicked = (avatarPart) => {
    setViewingAvatarPart(avatarPart);
    toggleViewModal();
  };

  const toggleViewModal = () => {
    setViewModalOpen(o => !o);
  }



  return (
    <>
      <div className="flex-cont">
        <div className="flex-1"><h1>Avatar Parts</h1></div>
        <div>
          <Link to={`new`} className="button">New Avatar Part</Link>
        </div>
      </div>


      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink exact to={`/avatarParts`}>All</NavLink></li>
        </ul>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {avatarParts &&
        <table className="basic">
          {avatarParts.map(avatarPart => {
            return (
              <tr>
                <td className="fit nowrap">

                  <div style={{width: "60px"}}>
                    <div className="andy-cont">
                      {avatarPart?.images?.map(image => 
                        <>
                          <SVG layerType={image.kind} url={image.image_url} /> 
                        </> 
                      )}
                    </div>
                  </div>
                  
                </td>

                <td className="bold fit nowrap">
                  <Link className="text" to={`edit/${avatarPart.id}`}>
                    {avatarPart.name}
                  </Link>
                </td>



                <td>
                  
                  
                </td>




                <td className="fit nowrap">
                  <Link to={`edit/${avatarPart.id}`} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </Link>

                  <div onClick={() => deleteClicked(avatarPart)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table> 
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Avatar Part</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingAvatarPart?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {viewModalOpen &&
        <Modal toggleModal={toggleViewModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">{viewingAvatarPart?.name}</h1>
              <div onClick={toggleViewModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">
            <h2>Charities</h2>
            <div className="margin-bottom-1"></div>
            {viewingAvatarPart.charities &&
              <table className="basic">
                {viewingAvatarPart.charities.map(charity => 
                  <tr>
                    <td className="fit nowrap">
                      <Link to={`/charities/${charity.id}`}></Link>
                      {charity.name}
                    </td>
                  </tr>
                )}
              </table>
            }

            {!viewingAvatarPart.charities &&
              <em>No charities.</em>
            }
          </div>

        </Modal>
      }

      {!loading && !avatarParts &&
        <em>No results.</em>
      }


      


    </>
  )
}



export default AdminAvatarParts;