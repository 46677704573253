import { andyFetch } from '../../api';


export const getMyTeam = (params) => {
  return async (dispatch, getState) => {
    const url = `club/team_admin/teams/my_team`
    
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        dispatch({ type: 'SET_CURRENT_TEAM', team: json.data});
        
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMyTeam error", e);
    }
  }
}

export const getTeam = (params) => {
  return async (dispatch, getState) => {
    const url = `club/teams/${params?.query?.id}`
    
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeams error", e);
    }
  }
}

export const getTeams = (params) => {
  return async (dispatch, getState) => {

    const url = "club/teams";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeams error", e);
    }
  }
};


export const createTeam = (params) => {

  return async (dispatch, getState) => {

    const url = "club/teams";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createTeam error.');
      }
    }
    catch (e) {
      console.log("createTeam error", e);
    }
  }
};


export const updateTeam = (params) => {

  return async (dispatch, getState) => {

    const url = `club/teams/${params.payload.team.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateTeam error.');
      }
    }
    catch (e) {
      console.log("updateTeam error", e);
    }
  }
};


export const deleteTeam = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/teams/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteTeam error.');
      }
    }
    catch (e) {
      console.log("deleteTeam error", e);
    }
  }
};

