import React, { useState, useEffect } from 'react';
import { useCombobox } from 'downshift'
import { useApi } from '../api';
import { getClubs } from '../redux/clubs/clubActions';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

// import MOCK_DATA from './MOCK_DATA'; 

const ClubSelect = ({selectedClubID, fieldName, setValue, setClubSelectOpened, focusedAthleteIndex, setSelectedClubID}) => {

  const [fuse, setFuse] = useState(null);

  const [clubs, loading, loaded, error, doFetch, setClubs] = useApi();

  const [results, setResults] = useState([]);

  const [search, setSearch] = useState("");


  const [selectedID, setSelectedID] = useState(null);


  useEffect(() => {
    if (loaded) {

      if (search) {
        setResults(clubs.slice(0, 10));
      }
      else {
        setResults([]);
      }
      

      const f = new Fuse(clubs, {
        keys: ['name']
      });

      setFuse(f)


    }
  }, [loaded]);

  useEffect(() => {
    if (clubs) {

      if (search) {
        setResults(fuse.search(search).slice(0, 10));
      }
      else {
        setResults([]);
        // setResults(clubs.slice(0, 10));
      }
      
    }
  }, [search]);

  useEffect(() => {

    if (selectedClubID) {
      setSearch(selectedClubID.name);
    }

  }, [selectedClubID])



  useEffect(() => {
    doFetch(getClubs);
  }, []);


  const rowClicked = id => {
    setSelectedID(id);
    
  }

  useEffect(() => {
    

    if (selectedID) {
      setValue(`${fieldName}[club_id]`, selectedID.id);
      setValue(`${fieldName}[club_name]`, selectedID.name);
      setSelectedClubID(selectedID); 
      setClubSelectOpened(false);
    }

  }, [selectedID]);


  return (
    <div className="narrow-2 margin-top-1">
      {/* <h1>ClubsX</h1>

      <div>ATHLETE INDEX: {focusedAthleteIndex}</div> */}

      <input defaultValue={selectedClubID ? selectedClubID.name : ""} placeholder="Start typing..." className="font-1-25" onChange={e => setSearch(e.target.value)} type="text" name="search" id=""/>

      
      <ul className="search">
        {results && results.map(result => 
          
          {
            const iii = result.item ? result.item : result;

            return(
                <li className="pointer" onClick={() => rowClicked(iii)}>
                    <div className="margin-right-1 avatar-cont" style={{fontSize: '3em', backgroundImage: `url("${iii.avatar_url}")`}}></div>

                    <div>{iii.name}</div>
                </li>
            );
          }
        )}
      </ul>




     
    </div>
  )
}

export default ClubSelect; 