import React from 'react';
import { FRONTEND_URL } from '../../api';

const SharingCard = ({toggleShareModal, hasActiveCampaign, campaignLoading}) => {

  return (
    <div className="card no-hover margin-bottom-2 sharing-card">
      <div className="bg"></div>

      <div className="content">

        {campaignLoading && 
          <div className="spinner white"></div>
        }

        {hasActiveCampaign && !campaignLoading &&
          <>
            <div className="font-2 bold white-text">
              Invite friends and family to pledge
            </div>

            <div onClick={toggleShareModal} className="shadow button white margin-top-3 font-1-25">
              Share
            </div>
          </>
        }
        {!hasActiveCampaign && !campaignLoading &&
          <>
            <div className="font-2 bold white-text">
              Your school doesn't have an active campaign at this time.
            </div>

            <div className="font-1-5 white-text margin-top-1">
              Please check back later!
            </div>
          </>
        }
        
      </div>
      
      
    </div>
  )
}

export default SharingCard;