


import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, NavLink, Outlet, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { createPledger } from 'redux/user/pledgeActions';
import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import SubmitButton from '../../components/SubmitButton';


const PledgerSchema = yup.object().shape({
  pledger_name: yup.string().trim().required("Please enter your name."),
  pledger_email: yup.string().trim().email("Please enter a valid email.").required("Please enter your email."),
});

const PledgeDonorSetup = () => {

  const [pledgePage] = useOutletContext();

  let { code } = useParams();
  const navigate = useNavigate("/");

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PledgerSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  // const [loaded, setLoaded] = useState(false);

  const onSubmit = async data => {
    const payloadData = {
      pledge: {
        ...data,
        pledger_cents_per_mile: parseFloat(data.pledge_amount * 100),
        pledger_max_cents: parseFloat(data.pledge_max * 100)
      }
    }
    doFetch(createPledger, {query: {code: code}, payload: payloadData});
  }

  useEffect(() => {
    if (loaded && result) {

      if (!result.pledger_already_exists) {
        navigate(`/pledge/${code}/payment-setup?email=${result.email}`);
      }
      else {
        navigate(`/pledge/${code}/verify?email=${result.email}`);
      }
      
    }
  }, [loaded, result]);


  return (
    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>

        <div className="narrow relative">

          <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
            <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="blue font-3 bold-200 margin-bottom-3">
                  Supporting {pledgePage.athlete_first_name} {pledgePage.athlete_last_name} and {pledgePage.club_name}
                </div>

                <div className="bold font-1-5 margin-bottom-2">
                  Please provide a few details
                </div>

                <div className="flex-cont">
                  <label htmlFor="pledger_name">
                    <span>Your name</span>
                    <input ref={register} placeholder="Name" type="text" name="pledger_name" id=""/>
                    {errors.pledger_name && <div className="field-error">{errors.pledger_name.message}</div>}
                  </label>
                  <label htmlFor="pledger_email">
                    <span>Your email</span>
                    <input  ref={register} placeholder="Email" type="text" name="pledger_email" id=""/>
                    {errors.pledger_email && <div className="field-error">{errors.pledger_email.message}</div>}
                  </label>
                </div>

                

                
                <div className="margin-top-2 font-1-5 justify-center align-center flex-cont">
                  {/* <input type="submit" value="Continue" className="button" id="submit" 
                  // disabled={processing || disabled || succeeded} 
                  /> */}

                  <SubmitButton label={
                    <React.Fragment>
                      <span>Continue</span>
                      <i className="font-1 fas fa-arrow-right margin-left-half"></i>
                    </React.Fragment>
                  } disabled={(errors && Object.keys(errors).length !== 0)} loading={loading} />
                </div>

                <div className="margin-top-3 center margin-bottom-4 margin-top-half">
                  <Link to="/find-pledge" className="text-link">
                    Already have a pledge code?
                  </Link>
                </div>
              </form>
            </div>

            <PledgeSidebar pledgePage={pledgePage}  isPreview={false} showButton={false} />
          </div>

        </div>

        

      </div>
    </>
  )
}

export default PledgeDonorSetup;