import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'styles/Noti.scss';
// import chest from 'images/chest.png';

import CountUp from 'components/CountUp';
import { removeNotiPopup, removeAllNotiPopups } from 'redux/noti/notiActions';
import store from 'redux/store';

import 'styles/Coin.scss';

import LoginNoti from './notis/LoginNoti';
import LapNoti from './notis/LapNoti';



const Noti = ({index, noti, toggleNoti, current, notiPopups}) => {

  const [started, setStarted] = useState(false);

  const [hidden, setHidden] = useState(false);

  // useEffect(() => {

  //   if (current) {
  //     setTimeout(() => {
  //       setStarted(true);
  //     }, 10);
  //   }
  //   else {
  //     setStarted(false);
  //   }

    
  // }, [current]);

  useEffect(() => {

    // alert('here');

    setStarted(true);
    // setTimeout(() => {
    //   setStarted(true);
    // }, 10);
  

    
  }, []);

  const hideNoti = () => {
    setStarted(false);


    setTimeout(() => {
      store.dispatch(removeNotiPopup(noti.id));

      // setNotis(old => old.filter(n => n.id?.toString() !== noti.id?.toString()))
    }, 300);
    

    // store.dispatch(removeNotiPopup(noti.id))
  }

  const hideAllNotis = () => {
    setStarted(false);
    setTimeout(() => {
      store.dispatch(removeAllNotiPopups());
    }, 300);
  }

  const components = {
    LoginNoti: LoginNoti,
    LapNoti: LapNoti,
  };

  

  const notiComponent = (noti) => {
    const SpecificNotiComponent = components[noti?.kind];
    return <SpecificNotiComponent noti={noti} hideNoti={hideNoti} hideAllNotis={hideAllNotis} />;
  }


  return (
    <>

      <div className={`noti-master`}>
      
      <div className={`${started ? 'start' : ''} noti-cont`}>
        {notiComponent(noti)}

        {/* <pre>
          {JSON.stringify(notiPopups, null, 2)}
        </pre> */}
      </div>
      </div>
    </>
  )
}

const Notis = ({showNotis, fetchedNotis, currentUser, notiPopups, notisLoaded}) => {
  

  const [currentNoti, setCurrentNoti] = useState(notiPopups?.[0]);

  const [startBG, setStartBG] = useState(false);
  const [hideBG, setHideBG] = useState(true);

  const [initialized, setInitialized] = useState(false);



  useEffect(() => {

    if (!notisLoaded || !initialized) return;



    if (notiPopups) {
      setCurrentNoti(notiPopups[0]);
    }

    // // alert(notiPopups.length);

    if (!notiPopups || notiPopups.length === 0) {
      setStartBG(false);
      setHideBG(true);
      // setTimeout(() => {
      //   // alert(JSON.stringify(notis, null, 2))
      //   setHideBG(true);
      // }, 300);
    }
    else {
      setStartBG(true);
      setHideBG(false);
    }
    
  }, [notiPopups, initialized, notisLoaded]);
  

  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <>

      {!hideBG &&
      <>
        <div className={`noti-guard`}></div>
        <div className={`noti-bg ${startBG ? "start" : ""}`}>

          {/* <pre>
            {JSON.stringify(currentNoti, null, 2)}
          </pre> 
          <br />
          <pre>
            {JSON.stringify(notiPopups, null, 2)}
          </pre> */}
          
          {/* <br/>
          <br/>
          <pre>
            {JSON.stringify(currentNoti, null, 2)}
          </pre> */}
          
        </div>
        </>
      }

      
      
      {currentNoti &&
        <Noti notiPopups={notiPopups} key={currentNoti.id} index={0} current={true} noti={currentNoti} />
      }
      {/* {notiPopups.map((noti, index) => {
        return <Noti index={index} current={currentNoti && currentNoti.id === noti.id} noti={noti} />
      })} */}

    </>
  )
}


const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    notiPopups: state.notiState.notiPopups
  };
} 

export default connect(mapState)(Notis);