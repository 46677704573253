

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getUsers } from 'redux/club/userActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';

import { createUser, updateUser, deleteUser, getUser } from 'redux/club/userActions';


const ClubUsers = ({currentUser}) => {

  let { id } = useParams();
  const [paramsId, setParamsId] = useState(id);
  const [users, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchUsers();
  }, [id]);

  const fetchUsers = () => {
    doFetch(getUsers, {query: {tab: id}});
  }


  const changeTab = (tab) => {
    // setSearchParams({ q: tab });
  }

  return (
    <>
      <div className="flex-cont">
        <h1 className="flex-1">
          Users
        </h1>
      </div>
      
      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink to={`/club/users`}>All</NavLink></li>
        </ul>
      </div>

      <div className="margin-bottom-2"></div>

      {loading &&
        <div className="spinner"></div>
      }

      {users &&
        <table className="basic">
          {users.map(user => {
            return (
              <tr>
                <td className="bold fit nowrap">{user.full_name}</td>
                <td className="fit nowrap">{user.status}</td>
                <td>{user.email}</td>
                <td className="fit nowrap">
                </td>
              </tr>
            )
          })}
        </table>
      }

      {!loading && !users &&
        <em>No results.</em>
      }

    </>
  )
}

export default ClubUsers;