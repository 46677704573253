

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';

import { createMerchRequest, updateMerchRequest, deleteMerchRequest } from 'redux/club/merchRequestActions';
import MerchProductCard from '../merchProducts/MerchProductCard';

const MerchRequestForm = ({toggleModal, currentMerchRequest, currentMerchProduct}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    // validationSchema: MerchRequestSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  // const [merchRequestsResult, merchRequestsLoading, merchRequestsLoaded, merchRequestsError, doFetchMerchRequests, setMerchRequestResults] = useApi();

  const onSubmit = async data => {
    let d = {
      merch_request: {
        ...data
      }
    }

    if (currentMerchRequest && currentMerchRequest.id) {
      doFetch(updateMerchRequest, {payload: d});

    }
    else {
      doFetch(createMerchRequest, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);


  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const deleteClicked = () => {
    toggleDeleteModal();
  }
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteMerchRequest, {query: {id: currentMerchRequest.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
    
  }, [deleteLoaded])

  return (
    <>
    {deleteModalOpened &&
      <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
        <div className="padding-1">
          Are you sure you want to delete this?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className="button red">
            Delete
          </div>
        </div>
      </Modal>
    }
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentMerchRequest ? 'Edit' : 'New'} Merch Request</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentMerchRequest?.id}
        />
        <input 
          type="hidden" 
          name="merch_product_id" 
          ref={register}
          defaultValue={currentMerchProduct?.id || currentMerchRequest?.merch_product_id}
        />
        

        <div className="margin-bottom-3">
          <MerchProductCard merchProduct={currentMerchProduct} />
        </div>

        {/* <EditorDescription editor={editor} /> */}


        <div className="flex-contx">
          <label className="flex-cont align-center" htmlFor="count"> 
            <input 
              type="number" 
              min={0}
              step={1}
              ref={register}
              placeholder="Count"
              className="auto"
              name="count"
              defaultValue={currentMerchRequest?.count}
            />
            {errors.count && <div className="field-error">{errors.count.message}</div>}
          </label>
        </div>
      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        <div className="flex-1"></div>

        {currentMerchRequest &&
          <div onClick={deleteClicked} className="button red">
            Delete
          </div>
        }
      </div>

    </form>
    </>
  )

}



export default MerchRequestForm;