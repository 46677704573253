import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { 
  Elements,
  CardElement,
  useStripe,
  useElements 
} from "@stripe/react-stripe-js";
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { getSubDetails, getMaterialFeeDetails, updateMaterialFeePayment } from 'redux/user/userActions';
import { useNavigate } from 'react-router-dom';
// Make sure to call loadStripe outside of a component’s render to avoid

// recreating the Stripe object on every render.

// loadStripe is initialized with your real test publishable API key.


const promise = loadStripe("pk_test_51H3POlG6MXyb29gzTQP735E2Jf7LcS6A4cwmxeYCnxSghwGPSut4AIgbyz5YOoLVW45jlcUoc7KbWw9RXOTDl4UJ00mfKkl5nE");

const Upgrade = ({currentUser, athletes}) => {

  const [materialFeeDetails, loading, loaded, error, doFetch, setSubDetails] = useApi();


  useEffect(() => {

    doFetch(getMaterialFeeDetails);

  }, []);


  return (

    <div className="narrow margin-top-2">
      <h1>Material Fee</h1>

      {materialFeeDetails?.material_fee_is_paid &&
        <div className="bold font-1-5">
          You have already paid your material fee.
        </div>
      }

      {!materialFeeDetails?.material_fee_is_paid && 
        <>

          <div className="margin-bottom-3">
            <h3>Details</h3>

            {loading && <div className="spinner"></div> }

            {loaded && materialFeeDetails &&
              <table className="basic">
                {materialFeeDetails?.athletes?.map(athlete => {
                  return(
                    <tr>
                      <td className="fit nowrap">{athlete.name}</td>
                      <td className="fit nowrap">{athlete.club_name} - {athlete.campaign_name}</td>
                      <td>{parseFloat(athlete.material_fee).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                    </tr>
                  );
                })}
                <tr className="bold">
                  <td></td>
                  <td className="text-right">Total:</td>
                  <td>{parseFloat(materialFeeDetails.total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                </tr>
              </table>
              }
          </div>

          {loaded && materialFeeDetails && 
            <div className="card no-hover">
              <Elements stripe={promise}>
                <UpgradeForm />
              </Elements>
            </div>
          }
        </>}
      
    </div>
  );
};

const UpgradeForm = () => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate("/");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch(`${process.env.REACT_APP_BACKEND_URL}/payments/create_payment_intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setClientSecret(data.clientSecret);
      });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };




  const [materialFeeResult, materialFeeResultLoading, materialFeeResultLoaded, materialFeeResultError, doMaterialFeeFetch, setMaterialFee] = useApi();





  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      return
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }

    const payloadData = {
      
      
    }
    doMaterialFeeFetch(updateMaterialFeePayment, {payload: payloadData});
  };

  useEffect(() => {
    if (materialFeeResultLoaded) {
      navigate("/material-fee/confirmation");
    }
  }, [materialFeeResultLoaded]);

  return (
    
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <div className="margin-top-2"></div>
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
    </form>
  );
}



function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    athletes: state.sessionState.currentUser.athletes,
  };
} 

export default connect(mapState)(Upgrade);
