

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getSchoolYearFees } from 'redux/club/schoolYearFeeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Modal from 'components/Modal';
import { DateTime } from 'luxon';

import SchoolYearWaiverForm from './SchoolYearWaiverForm';
// import { createSchoolYearWaiver, updateSchoolYearWaiver, deleteSchoolYearWaiver } from 'redux/club/schoolYearWaiverActions';


const ClubSchoolYearFees = ({currentUser}) => {


  const { id } = useParams();


  const [page, setPage] = useState(1);
  const [deletedPledge, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledge] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPledge, setEditingPledge] = useState(null);


  const [schoolYearFees, schoolYearFeesLoading, schoolYearFeesLoaded, schoolYearFeesError, doSchoolYearFeesFetch, setSchoolYearFeesResult] = useApi(); 

  useEffect(() => {
    if (!schoolYearFees) {
      fetchSchoolYearFees();
    }
  }, []);

  const fetchSchoolYearFees = () => {
    doSchoolYearFeesFetch(getSchoolYearFees, {
      query: {
        current: true
      }
    });
  }

  const handlePageChange = (p) => {
    setPage(p);
  };

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {

    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPledge(null);
    }
  }, [modalOpen])



  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPledge, setDeletingPledge] = useState(null);
  

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {

    }
  }




  const editClicked = pledge => {
    setEditingPledge(pledge);
    toggleModal();
  }

  const deleteClicked = pledge => {
    setDeletingPledge(pledge);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPledge) {
      // doDeleteFetch(deletePledge, {query: {id: deletingPledge.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  

  if (schoolYearFeesLoaded && !schoolYearFees) {
    return(
      <div>This campaign does not have any participating students. Please add students to your school to continue.</div>
    )
    
  }
    
  

  return (
    <>
      
      {schoolYearFeesLoading &&
        <div className="spinner"></div>
      }
      

      {!schoolYearFeesLoading && schoolYearFees &&
        <table className="basic">
          <th className='fit nowrap'>Student</th>
          <th className='fit nowrap'>Material Fee</th>
          <th className='fit nowrap'>Amount Paid</th>
          <th></th>
          <th></th>
          {schoolYearFees.map(schoolYearFee => {
            return (
              <tr>
                <td className="bold fit nowrap">{schoolYearFee.athlete_name}</td>
                <td className="fit nowrap">
                  {parseFloat(schoolYearFee.actual_fee_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td className="fit nowrap">
                  {parseFloat(schoolYearFee.paid_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </td>
                <td>
                  {schoolYearFee.is_paid ? (
                      <div style={{color: 'green'}} className='bold'>
                        PAID
                      </div>
                    ) : (
                      <div style={{color: 'red'}} className='bold'>
                        NOT PAID
                      </div>
                    )
                  }
                  
                  </td>

                  <td>
                    {/* <pre>
                      {JSON.stringify(schoolYearFee, null, 2)}
                      {JSON.stringify(schoolYearFee.material_fee_waiver, null, 2)}
                    </pre> */}
                  </td>
                <td className="fit nowrap">
                </td>
              </tr>
            )
          })}
        </table>
      }

     

      {!schoolYearFeesLoading && (!schoolYearFees || schoolYearFees.length === 0) &&
        <em>No results.</em>
      }




    </>
  )
}


export default ClubSchoolYearFees;