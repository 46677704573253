

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getBands } from 'redux/admin/bandActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { createBand, updateBand, deleteBand } from 'redux/admin/bandActions';

const BandSchema = yup.object().shape({
  code: yup.string().required("Code is required."),
  color: yup.string(),


});


const BandForm = ({toggleModal, currentBand}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: BandSchema
  });

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);

  useEffect(() => {
    setValue(`color`, color);
  }, [color]);

  useEffect(() => {
    if (currentBand?.color) {
      setColor(currentBand?.color)
    }
  }, [currentBand])

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [bandsResult, bandsLoading, bandsLoaded, bandsError, doFetchBands, setBandResults] = useApi();

  const onSubmit = async data => {
    let d = {
      band: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentBand && currentBand.id) {
      doFetch(updateBand, {payload: d});
    }
    else {
      doFetch(createBand, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentBand ? 'Edit' : 'New'} Band</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentBand?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="code"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Code"
              name="code"
              defaultValue={currentBand?.code}
            />
            {errors.code && <div className="field-error">{errors.code.message}</div>}
          </label>
        </div>

        <div className="inline-block relative flex-cont align-center">
          {pickerOpened &&

              <div style={{left: 0, top: '100%', position: 'absolute'}}>
                <HexColorPicker style={{boxShadow: '0 0 50px rgba(0,0,0,.5)', zIndex: 100}} color={color} onChange={setColor} />
                <div onClick={() => setPickerOpened(o => !o)} style={{borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 101, position: 'absolute', bottom: 'calc(100% + 10px)',  right: 'calc(100% + 10px)', background: 'white', width: '25px', height: '25px'}}>
                  <i className="fas fa-times"></i>
                </div>
              </div>

            
          }
          <div>
            <div onClick={() => setPickerOpened(o => !o)} 
              style={{marginRight: '10px', boxShadow: '0 15px 50px rgba(0,0,0,.25)', cursor: 'pointer', borderRadius: '50%', display:'inline-block', width: '50px', height: '50px', backgroundColor: color}}>
            </div>
          </div>
          
          <input 
            name={`color`} 
            ref={register()}
            type="text" 
            placeholder="Color"
            onChange={e => setColor(e.target.value)}
            value={color}
            style={{marginRight: '10px'}}
            defaultValue={currentBand?.color}
          />

        </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminBands = ({currentUser}) => {

  const [bands, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedBand, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedBand] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingBand, setEditingBand] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchBands();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingBand(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!bands) {
      fetchBands();
    }
  }, []);

  const fetchBands = () => {
    doFetch(getBands);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingBand, setDeletingBand] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchBands();
    }
  }


  const editClicked = band => {
    setEditingBand(band);
    toggleModal();
  }

  const deleteClicked = band => {
    setDeletingBand(band);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingBand) {
      doDeleteFetch(deleteBand, {query: {id: deletingBand.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <BandForm currentBand={editingBand} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Hero Bands</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Band</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {bands &&
        <table className="basic">
          {bands.map(band => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div
                  
                  style={{marginRight: '10px', boxShadow: '0 15px 50px rgba(0,0,0,.25)', borderRadius: '50%', display:'inline-block', width: '50px', height: '50px', backgroundColor: band.color}}>

                  </div>
                </td>
                <td className="bold fit nowrap">
                  {band.status === "assigned" ? band.last_first : "UNASSIGNED"}
                </td>
                <td>{band.code}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(band)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(band)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Band</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingBand?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !bands &&
        <em>No hero bands.</em>
      }


      


    </>
  )
}


export default AdminBands;