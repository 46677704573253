

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getTracks } from 'redux/club/trackActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { Link } from 'react-router-dom';
import { createTrack, updateTrack, deleteTrack } from 'redux/club/trackActions';
import TrackForm from './TrackForm';


const ClubTracks = ({currentUser}) => {

  const [tracks, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedTrack, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedTrack] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingTrack, setEditingTrack] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchTracks();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingTrack(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!tracks) {
      fetchTracks();
    }
  }, []);

  const fetchTracks = () => {
    doFetch(getTracks);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingTrack, setDeletingTrack] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchTracks();
    }
  }


  const editClicked = track => {
    setEditingTrack(track);
    toggleModal();
  }

  const deleteClicked = track => {
    setDeletingTrack(track);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingTrack) {
      doDeleteFetch(deleteTrack, {query: {id: deletingTrack.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      <div className="padding-top-1">
      {modalOpen && 
        <Modal toggleModal={toggleModal}>
          <TrackForm currentTrack={editingTrack} toggleModal={toggleModal} />
        </Modal>
      }

      <div className="flex-cont">
        <div className="flex-1">
          <h1>Tracks</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Track</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {tracks &&
        <table className="basic">
          {tracks.map(track => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div style={{fontSize: '3rem', backgroundImage: `url( ${track?.avatar_url} )`}} className="team-logo small">
                  
                  </div>
                </td>
                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/tracks/${track.id}`}>
                    {track.name}
                  </Link>
                </td>
                <td className="fit nowrap">{track.lap_distance} mi/lap</td>
                {/* <td>{track.full_address}</td> */}
                <td></td>

                {/* <td className="fit nowrap">
                  <div onClick={() => editClicked(track)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                </td> */}
              
              </tr>
            )
          })}
        </table>
      }


      {!loading && !tracks &&
        <em>No tracks.</em>
      }


      

      </div>  
    </>
  )
}


export default ClubTracks;