import { andyFetch } from '../../api';

export const getAwards = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/awards";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAwards error", e);
    }
  }
};


export const createAward = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/awards";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createAward error.');
      }
    }
    catch (e) {
      console.log("createAward error", e);
    }
  }
};


export const updateAward = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/awards/${params.payload.award.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAward error.');
      }
    }
    catch (e) {
      console.log("updateAward error", e);
    }
  }
};


export const deleteAward = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/awards/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAward error.');
      }
    }
    catch (e) {
      console.log("deleteAward error", e);
    }
  }
};

