import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import Modal from '../../../components/Modal';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubTeamNav from './ClubTeamNav';
import { useForm } from 'react-hook-form';
import { getAthletes } from 'redux/club/athleteActions';
import { getTeam, getTeams } from 'redux/club/teamActions';
import { transferTeamEnrollment, getTeamEnrollments, updateTeamEnrollment, destroyTeamEnrollment } from 'redux/club/teamEnrollmentActions';


const ClubTeamRoster = () => {
  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTeam()
    }
    fetchTeams();
    
  }, [id]);


  const [team, teamLoading, teamLoaded, teamError, doFetchTeam, setTeamResults] = useApi();
  const [teams, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [teamEnrollments, teamEnrollmentsLoading, teamEnrollmentsLoaded, teamEnrollmentsError, doFetchEnrollments, setEnrollmentsResults] = useApi();

  useEffect(() => {
    if (team) {
      fetchTeamEnrollments();
    }
  }, [team]);

  const fetchTeam = () => {
    doFetchTeam(getTeam, {query: {id: id}})
  }
  const fetchTeams = () => {
    doFetch(getTeams);
  }
  const fetchTeamEnrollments = () => {
    doFetchEnrollments(getTeamEnrollments, {query: {team_id: team.id}});
  }

  const [editingTeamEnrollment, setEditingTeamEnrollment] = useState(null);

  const toggleEditingTeamEnrollment = () => setEditingTeamEnrollment(null);

  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const toggleRemoveModal = () => setRemoveModalOpened(o => !o);

  const [removeResult, removeLoading, removeLoaded, removeError, doRemoveFetch, setRemoveResult] = useApi(); 
  const [updateResult, updateLoading, updateLoaded, updateError, doUpdateFetch, setUpdateResult] = useApi(); 

  const confirmRemoveClicked = () => {
    doRemoveFetch(destroyTeamEnrollment, {payload: {id: editingTeamEnrollment.id}})
  }
  useEffect(() => {
    if (removeLoaded) {
      setRemoveModalOpened(false);
      setEditingTeamEnrollment(null);
      fetchTeam();
    }
  }, [removeLoaded]);

  useEffect(() => {
    if (!editingTeamEnrollment) {
      setRemoveModalOpened(false);
    }
  }, [editingTeamEnrollment]);

  const handleUpdateSubmit = (data) => {
    doUpdateFetch(updateTeamEnrollment, {payload: data})
  }
  useEffect(() => {
    if (updateLoaded) {
      setRemoveModalOpened(false);
      setEditingTeamEnrollment(null);
      fetchTeam();
    }
  }, [updateLoaded]);

  const [addAthleteOpened, setAddAthleteOpened] = useState(false);

  const toggleAddAthlete = (reload=false) => {
    setAddAthleteOpened(o => !o);

    if (reload) {
      fetchTeam();
    }
  }
  

  return (
    <>
      {editingTeamEnrollment &&
        <Modal toggleModal={toggleEditingTeamEnrollment}>
          <form onSubmit={handleSubmit(handleUpdateSubmit)}>
            <input ref={register()} type="hidden" name="id" value={editingTeamEnrollment.id} />
            <div className="modal-header flex-cont align-center">
              <h1 className="flex-1">{editingTeamEnrollment?.athlete_name}</h1>

              <div onClick={toggleEditingTeamEnrollment} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>
            <div className="padding-1">
              <label>
                <span>Move to:</span>
                <select name="team_id" ref={register({required: "Please select a team."})} defaultValue={team.id}>
                  {teams?.map(team =>
                    <option value={team.id}>  
                      {team.name}
                    </option>
                  )}
                </select>
              </label>
            </div>
            <div className="modal-footer flex-cont align-center">
              <button className="button">Save</button>
              <div className="flex-1"></div>
              <div onClick={toggleRemoveModal} className="danger ghost button">Remove</div>
            </div>
          </form>

          {removeModalOpened &&
            <Modal toggleModal={toggleRemoveModal} focusTrap={false} size="small">
              <div className="padding-2">
                Are you sure you want to remove {editingTeamEnrollment.athlete_name} from this team?
              </div>

              <div className="modal-footer">
                <div className="flex-cont align-center">

    
                  <div onClick={toggleRemoveModal} className="ghost button">
                    Cancel
                  </div>
                  <div className="flex-1"></div>
                  <div disabled={removeLoading} onClick={confirmRemoveClicked} className="button danger">
                    Remove
                    {removeLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                  
                </div>
              </div>
            </Modal>
          }
        </Modal>
      
      }

      {addAthleteOpened &&
        <AddAthleteModal toggleModal={toggleAddAthlete} team={team} />
      }

      <div onClick={toggleAddAthlete} className="button margin-bottom-2">
        <i className="fas fa-plus margin-right-half"></i>
        Add Student
      </div>

      <table className="basic">
        {teamEnrollments?.map(teamEnrollment => 
          <tr>
            <td className="fit nowrap bold">{teamEnrollment.athlete_name}</td>
            <td></td>
            <td className="fit nowrap">
              <i onClick={() => setEditingTeamEnrollment(teamEnrollment)} className="ghost button fas fa-pencil-alt"></i>
            </td>
          </tr>  
        
        )}

      </table>
    </>
  )

}

const AddAthleteModal = ({toggleModal, team}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });

  const [updateResult, updateLoading, updateLoaded, updateError, doUpdateFetch, setUpdateResult] = useApi(); 

  const [athletes, loading, loaded, error, doFetch, setResult] = useApi(); 
  const fetchAthletes = () => {
    doFetch(getAthletes);
  }

  useEffect(() => {
    fetchAthletes();
  }, [])

  const onSubmit = data => {
    // alert(JSON.stringify(data, null, 2))

    doUpdateFetch(transferTeamEnrollment, {payload: data})
  }

  useEffect(() => {
    if (updateLoaded) {
      toggleModal(true);
    }
  }, [updateLoaded])

  return (
    <Modal toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>

        <input ref={register()} type="hidden" name="team_id" value={team.id} />

        <div className="modal-header flex-cont align-center">
          <h1 className="flex-1">Transfer Student to {team.name}</h1>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="padding-1">
          {loading && <div className="spinner"></div>  }
          {athletes &&
            <label>
              <span>Student</span>
              <select name="athlete_id" ref={register({required: "Please select a student."})} defaultValue={""}>
                <option value="">Select student:</option>
                {athletes?.map(athlete =>
                  <option value={athlete.id}>  
                    {athlete.last_name}, {athlete.first_name}
                  </option>
                )}
              </select>
            </label>
          }
        </div>
        <div className="modal-footer flex-cont align-center">
          <button className="button">Save</button>
          <div className="flex-1"></div>
        </div>
      </form>
    </Modal>
  )
}

export default ClubTeamRoster;