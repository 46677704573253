

import React, { useState, useEffect } from 'react';
import { useVisibility } from './useVisibility';

const CountUp = ({delay=0, startNumber=0, number, isMoney, type, decimalPlaces=2}) => {

  const parsedNumber = parseFloat(number);
  const [startCount, setStartCount] = useState(false);
  const { currentElement, isVisible } = useVisibility(-50);



  useEffect(() => {
    if (isVisible && !startCount && !isNaN(parsedNumber)) {
      setTimeout(() => {
        setStartCount(true);
      }, delay);
      
    } 
  }, [isVisible, parsedNumber]);

  // const [counter, setCounter] = useState(
  //   // parseFloat(startNumber.toFixed(decimalPlaces)).toLocaleString()
  // );

  const [counter, setCounter] = useState(parseFloat(startNumber));
  
  useEffect(() => {
    if (!isNaN(parsedNumber)) {
      setCounter(formatNumber(parseFloat(startNumber)))
    }
    
  }, [parsedNumber])


  const formatNumber = (n) => {
    if (type === "money") {
      // return `$${(n/100).toFixed(2)}`;
      return (parseFloat(n)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    else if (type === "pct") {
      return `${Math.round(n)}%`
    }
    else {
      return parseFloat(parseFloat(n).toFixed(decimalPlaces)).toLocaleString();
    }
  }

  useEffect(() => {
    if (startCount) {

      // alert('here');

      
      let duration = 1000;
      let end = parseFloat(number);
      let start = parseFloat(startNumber);
      let range = end - start;
      let current = start;
      let stepTime = 20.0;
      let initInc = range / (duration/stepTime);
      let increment = initInc;

      let text = formatNumber(current);

      formatNumber(current);


      const interval = setInterval(() => {
        current += increment;
        text = formatNumber(current);

        setCounter(counter => text);

        if (current >= end) {
          current = end;
          text = formatNumber(current);
          setCounter(counter => text);
          clearInterval(interval);
        }
      }, stepTime);

      return () => {
        clearInterval(interval);
      };
    }
  }, [startCount]);

  if (!number) {
    return (
      <span>
        -
      </span>
    )
  }



  return (
    <span ref={currentElement}>
      {counter}
    </span>
  )
}

export default CountUp;