import React, { useState, useEffect, useRef, Suspense,useLayoutEffect } from 'react';
import { Canvas, useFrame, extend, useLoader, useBox, useThree } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three'
// Make sure to call loadStripe outside of a component’s render to avoid
import Man2 from './Man2.js';
import Spider from './Spider.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import Walk from './Walk.js';
import { Scene } from 'three';

import Bar from '../components/Bar';
// recreating the Stripe object on every render.
extend({ OrbitControls })
// loadStripe is initialized with your real test publishable API key.







const CameraControls = () => {  
  const {    
    camera,    
    gl: { domElement }, 
    gl, 
    
    scene,

  } = useThree();

  useEffect(() => {
    console.log('scene', scene);

    if (scene) {
      // scene.background = new THREE.Color( 'lime' );;
    }
  }, [scene]);


  useEffect(() => {
    console.log('gl', gl);

    if (gl) {
      gl.encoding = THREE.sRGBEncoding;
      gl.outputEncoding = THREE.sRGBEncoding;

      gl.shadowMap.enabled = true;
      gl.antiAlias = true;
      gl.shadowMapType = THREE.PCFSoftShadowMap;
    }


    
  }, [gl, camera]);



  const controls = useRef();  
  useFrame((state, delta) => {
    controls.current.update();




    // camera.position.set(camera.position.x - .001, camera.position.y - .001, camera.position.z - .001);
  });
  
  useEffect(() => {
    console.log('controls', controls);



    controls.current.target.set(0, .4, 0 );
   }, [controls]);
  
  return <orbitControls ref={controls} args={[camera, domElement]} />;


};



const Test3d = (props) => {

  const [parentScene, setParentScene] = useState(null);


  // const light = useRef()

  // // // Set up state for the hovered and active state
  // const [hovered, setHover] = useState(false)
  // const [active, setActive] = useState(false)

  // // // Rotate mesh every frame, this is outside of React without overhead
  // useFrame(() => (canvas.current.camera.zoom = canvas.current.camera.zoom + 0.01))
  


  const { gl, camera } = useThree();

  // const [hatVisible, setHatVisible] = useState(true);


  return (
    <div>
      {/* <div className="card no-hover">
        Test 3D
      </div> */}


      {/* <div onClick={() => setHatVisible(o => !o)} className="button">
        Toggle Hat
      </div> */}

      {/* {parentScene &&
        <div>{JSON.stringify(Object.keys(parentScene.animations))}</div>
      } */}

      {/* {parentScene && 
        <div>
          GATO: {JSON.stringify(Object.keys(parentScene.nodes.Lumbar))}

          GATO: {JSON.stringify(parentScene.nodes.Lumbar.children)}
        </div>
      } */}
{/* 
      <div>
        {JSON.stringify(parentScene)}
      </div> */}

      

      <Canvas style={{top: '0px', left: '10px', position: 'relative', width: '200px', height: '300px'}}

        camera={{
          position: [0, .4, 1.33],
          aspect: .7,
          fov: 40,
          near: .1,
          far: 5000,
          up: [0,1,0],
          zoom: 1
          
        }}
        // onCreated={({ camera }) => camera.lookAt(0, .5, 0 )}
       >



        <CameraControls />


        {/* <axesHelper /> */}

        <directionalLight color={0xffffff} intensity={2} castShadow={true} position={[2.5,7.5,5]} 


            shadowCameraLeft={-1}
            shadowCameraRight={1}
            shadowCameraTop={1}
            shadowCameraBottom={-1}
            shadowCameraNear={.1}
            shadowCameraFar={40}

            shadowMapHeight={1024}
            shadowMapWidth={1024}



        >
          {/* <mesh
          castShadow={false}
          >
            <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
            <meshStandardMaterial attach="material" color={'white'} />
          </mesh> */}
        </directionalLight>

        <directionalLight color={'orange'} intensity={.25} castShadow={false} position={[-.5,0,0]}>
          {/* <mesh
          castShadow={false}
          >
            <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
            <meshStandardMaterial attach="material" color={'purple'} />
          </mesh> */}
        </directionalLight>

        <directionalLight color={'white'} intensity={.1} castShadow={false} position={[-.25,.75,.5]}>
          {/* <mesh
          castShadow={false}
          >
            <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
            <meshStandardMaterial attach="material" color={'cyan'} />
          </mesh> */}
        </directionalLight>

         <hemisphereLight skyColor={0xffeeb1} groundColor={'white'} intensity={1} />

{/*         
        <mesh
          position={[.5,1.5,1.5]}
          castShadow={true}
        >
          <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
          <meshStandardMaterial attach="material" color={'white'} />
        </mesh> */}
        

        <mesh
          position={[0,0,0]}
          receiveShadow={true}
          rotation={[- Math.PI / 2, 0, 0]}
        >
          
          <planeBufferGeometry attach="geometry" args={[5, 5]} />
          <shadowMaterial  opacity={.5} attach="material"  />
        </mesh>

        

        {/* <directionalLight color={'white'} intensity={1} castShadow={true} position={[0,.5,.5]}>
          <mesh
          castShadow={true}
          >
            <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
            <meshStandardMaterial attach="material" color={'white'} />
          </mesh>
        </directionalLight> */}

        


        {/* <mesh
          position={[1,0,1]}
          castShadow={true}
        >
          
          <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
          <meshStandardMaterial attach="material" color={'white'} />
        </mesh> */}

        {/* <ambientLight  intensity={.33} /> */}
       
        

        <Suspense fallback={null}>
          <Walk />

        </Suspense>


      </Canvas>
      
    </div>
  );
};




export default Test3d;
