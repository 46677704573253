import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';


const Row = ({labelComponent, listRef, index, item, labelFn, valueFn, highlightIndex, itemClicked}) => {
  const itemRef = useRef(null);
  const isHighlighted = () => {
    return highlightIndex !== null && index !== null && highlightIndex === index
  }

  useEffect(() => {

    if (itemRef && itemRef.current && isHighlighted()) {
      // console.log(itemRef)
      itemRef.current.scrollIntoView()
    }
    
  }, [highlightIndex])

  return (
    <div 
      ref={itemRef} 
      key={valueFn(item)} 
      className={`item ${highlightIndex === index ? 'highlighted' : ''}`} 
      onClick={() => itemClicked(item)}
    >
      {labelComponent !== undefined &&
        <>
          {labelComponent(item)}
        </>
      }
      
      
    </div>  
  )
}


const AutoComplete = ({labelComponent, searchableAttributes, label, labelFn, valueFn, setValue, defaultSearch, defaultValue, name, items, register, errors}) => {

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const [search, setSearch] = useState(defaultSearch ? defaultSearch : "");
  const [filtered, setFiltered] = useState(items);
  const [listOpened, setListOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setListOpened(false);
    }
  }; 

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const formattedSearchableAttributes = (item) => {
    if (!searchableAttributes) return null;
    return searchableAttributes.map(a => item[a]?.toString().toUpperCase().replace(/ /g, ''))
  }


  useEffect(() => {
    if (search) {
      const formattedSearch = search.toUpperCase().replace(/ /g, '');
      const f = items?.filter(item => 
        {
          const attrs = formattedSearchableAttributes(item);
          if (!attrs) return false;
          return attrs.some(attr => attr.includes(formattedSearch)) || attrs.join('').includes(formattedSearch); 
        }
      )
      setFiltered(f)
    }
    else {
      setFiltered(items)
    }
  }, [search, items, listOpened]);

  useEffect(() => {
    if (selectedItem) {
      setSearch(labelFn(selectedItem));
      if (inputRef?.current) {
        inputRef.current.blur();
      }
    }    
  }, [selectedItem]);

  const itemClicked = (item) => {
    setListOpened(false);
    setSelectedItem(item);
    setSearch(`${labelFn(item)}`);
    // alert(`${name} ${valueFn(item)}`);
    setValue(name, valueFn(item));
    
  }

  useEffect(() => {
    if (!listOpened) {
      if (selectedItem) {
        setSearch(labelFn(selectedItem));
      }
    }
  }, [listOpened])

  const inputFocused = () => {
    // alert('gere')

    if (!listOpened) {
      setListOpened(true);
    }
    
  }

  const [highlightIndex, setHighlightIndex] = useState(0);

  const checkKeyDown = (e) => { 
    if (!filtered || !listOpened) return;
    const keyCode = e.keyCode ? e.keyCode : e.which; 
    
    if (keyCode === 40) { 
      setHighlightIndex(o => Math.min(filtered.length-1, o+1));
    }
    else if (keyCode === 38) {
      setHighlightIndex(o => Math.max(0, o-1));
    } 
    else if (keyCode === 13) {
      setListOpened(false);
      const item = filtered[highlightIndex];
      setSelectedItem(item);
      setSearch(`${labelFn(item)}`);
      setValue(name, valueFn(item));
      
    } 
  };

  const hasErrors = errors && Object.keys(errors)?.length > 0;

  return (
    <label className={`${hasErrors ? 'has-errors' : ''}`}>
      <span>{label}</span>
    <div key={name} ref={wrapperRef} className="autocomplete">


      <div className="menu-cont">

        <div className="flex-cont align-center">

          
          <input 
            
            onFocus={inputFocused} 
            ref={inputRef}
            type="text" 
            name="item_search" 
            id="" 
            autoComplete="off"
            onKeyDown={(e) => checkKeyDown(e)} 
            defaultValue={defaultSearch}
            placeholder="Search..."
            value={search} 
            className={`${hasErrors ? 'has-errors' : ''}`}  
            onChange={e => setSearch(e.target.value)} 
          />

          <input
            style={{display: 'none'}}
            type="text"
            name={name} 
            id={name} 
            defaultValue={defaultValue} 
            ref={register({
              required: "Required."
            })}
          />
        </div>

        {listOpened &&
          <div className="menu">
            {filtered?.map((item, index) =>
              <Row 
                valueFn={valueFn} 
                labelFn={labelFn} 
                key={`item-row-${valueFn(item)}`} 
                index={index} 
                item={item} 
                highlightIndex={highlightIndex} 
                itemClicked={itemClicked} 
                labelComponent={labelComponent}
              />
            )}
          </div>
        }
      </div>
    </div>
    </label>
  )
}

export default AutoComplete;
