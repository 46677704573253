import { andyFetch } from '../../api';

export const getCurrentSchoolYearWaivers = (params) => {
  return async (dispatch, getState) => {

    const url = `club/school_years/current_material_fee_waivers`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSchoolYear error", e);
    }
  }
};




export const getCurrentSchoolYear = (params) => {
  return async (dispatch, getState) => {

    const url = "club/school_years/current";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSchoolYears error", e);
    }
  }
};

export const getSchoolYear = (params) => {
  return async (dispatch, getState) => {

    const url = `club/school_years/${params?.query?.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSchoolYear error", e);
    }
  }
};


export const getSchoolYears = (params) => {
  return async (dispatch, getState) => {

    const url = "club/school_years";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSchoolYears error", e);
    }
  }
};


export const createSchoolYear = (params) => {

  return async (dispatch, getState) => {

    const url = "club/school_years";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createSchoolYear error.');
      }
    }
    catch (e) {
      console.log("createSchoolYear error", e);
    }
  }
};


export const updateCurrentSchoolYear = (params) => {

  return async (dispatch, getState) => {

    const url = `club/school_years/update_current`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateSchoolYear error.');
      }
    }
    catch (e) {
      console.log("updateSchoolYear error", e);
    }
  }
};


export const deleteSchoolYear = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/school_years/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteSchoolYear error.');
      }
    }
    catch (e) {
      console.log("deleteSchoolYear error", e);
    }
  }
};

