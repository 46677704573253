

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAwardRuleLogin } from 'redux/admin/awardRuleLoginActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import AdminAwardsLoginForm from './AdminAwardsLoginForm';


export const AdminAwardsLoginEdit = () => {
  let { id } = useParams();
  const [awardRuleLogin, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAwardRuleLogin();
  }, [id]);

  const fetchAwardRuleLogin = () => {
    doFetch(getAwardRuleLogin, {query: {id: id}});
  }


  return (
    <>

      <h1>Edit Login Award</h1>

      {/* <div className="code">
        <pre>
          {JSON.stringify(awardRuleLogin, null, 2)}
        </pre>
      </div> */}
      <AdminAwardsLoginForm currentAwardRuleLogin={awardRuleLogin} />
    </>
  )
}

const AdminAwardsLoginNew = () => {

  return (
    <>
      <h1>New Login Award</h1>

      <AdminAwardsLoginForm />
    </>
  )
}




export default AdminAwardsLoginNew;