

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { createPalette, updatePalette } from 'redux/admin/paletteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import { useNavigate } from "react-router-dom"


const PaletteSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required("Name is required."),
  active: yup.string(),
  colors_attributes: yup.array().compact().of(
    yup.object().shape({
      id: yup.string(),
      _destroy: yup.string(),
      hex: yup.string(),
      name: yup.string(),
    })
  )
});


const ColorFields = ({currentColor, setValue, removeColor, hide, fieldName, register, errors, i, index, q}) => {

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);

  useEffect(() => {
    setValue(`${fieldName}hex`, color);
  }, [color]);

  useEffect(() => {
    if (currentColor?.hex) {
      setColor(currentColor.hex)
    }
  }, [currentColor])

  return (
    <div key={index}>
    {/* {i}
    {index}
    {fieldName} */}

    {/* <div className="code">
      <pre>
        {JSON.stringify(currentColor, null, 2)}
      </pre>
    </div> */}
    <fieldset className={hide ? 'hidden' : ''} name={fieldName} key={fieldName}>      
        <input 
          name={`${fieldName}[id]`} 
          type="hidden" 
          ref={register()}
          defaultValue={currentColor?.id}
        />
        <input 
          name={`${fieldName}[_destroy]`} 
          type="hidden" 
          ref={register()}
        />


        <div className="inline-block relative flex-cont align-center">
          {pickerOpened &&

              <div style={{left: 0, top: '100%', position: 'absolute'}}>
                <HexColorPicker style={{boxShadow: '0 0 50px rgba(0,0,0,.5)', zIndex: 100}} color={color} onChange={setColor} />
                <div onClick={() => setPickerOpened(o => !o)} style={{borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 101, position: 'absolute', bottom: 'calc(100% + 10px)',  right: 'calc(100% + 10px)', background: 'white', width: '25px', height: '25px'}}>
                  <i className="fas fa-times"></i>
                </div>
              </div>

            
          }
          <div>
            <div onClick={() => setPickerOpened(o => !o)} 
              style={{marginRight: '10px', boxShadow: '0 15px 50px rgba(0,0,0,.25)', cursor: 'pointer', borderRadius: '50%', display:'inline-block', width: '50px', height: '50px', backgroundColor: color}}>
            </div>
          </div>
          
          <input 
            name={`${fieldName}hex`} 
            ref={register()}
            type="text" 
            placeholder="Color"
            onChange={e => setColor(e.target.value)}
            value={color}
            style={{marginRight: '10px'}}
            defaultValue={currentColor?.hex}
          />
          <input 
            type="text" 
            placeholder="Label"
            name={`${fieldName}name`} 
            ref={register()}
            defaultValue={currentColor?.name}
            style={{marginRight: '10px'}}
          />  

          <div className="ghost button" onClick={() => removeColor(index)}>
            <i className="fas fa-times"></i>
          </div>  
        </div>


        {/* <div className="code">
          <pre>
            {JSON.stringify(errors, null, 2)}
          </pre>
        </div> */}

        


        {/* <div className="flex-cont">
          <div className="flex-1"></div>
          
            <div className="text-link" onClick={() => removeColor(index)}>REMOVE</div>
        
        </div> */}
      
      
    </fieldset>
    </div>
  )
}



export const PaletteForm = ({toggleModal, currentPalette}) => {
  const navigate = useNavigate();
  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PaletteSchema
  });

  const [indexes, setIndexes] = useState(currentPalette?.colors ? currentPalette?.colors.map((p, index) => index) : [0]);
  const [removedIndexes, setRemovedIndexes] = useState([]);
  const [counter, setCounter] = useState(currentPalette?.colors ? currentPalette?.colors.length : 0);
  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    if (currentPalette && currentPalette.colors) {
      setIndexes(currentPalette?.colors.map((p, index) => index))
      setCounter(currentPalette?.colors?.length)
    }
  }, [currentPalette])
  
  const addColor= () => {
    setIndexes(prevIndexes => [...prevIndexes, counter + 1]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeColor = (index) => {
    const fieldName = `colors_attributes[${index}].`;
    // setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setValue(`${fieldName}[_destroy]`, true);
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
  };


  const onSubmit = async data => {
    let d = {
      color_palette: {
        ...data
      }
    }
    if (currentPalette && currentPalette.id) {
      doFetch(updatePalette, {payload: d});
    }
    else {
      doFetch(createPalette, {payload: d});
    }
  }


  useEffect(() => {
    if (loaded) {
      navigate('/palettes')
    }
  }, [loaded])

  const colors = currentPalette?.colors;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="code">
        <pre>
          {JSON.stringify(currentPalette, null, 2)}
        </pre>
      </div> */}

      <input type="hidden" name="id" ref={register} defaultValue={currentPalette?.id} />

      <div className="flex-cont">
        <label htmlFor="name"> 
          <input 
            type="text" 
            ref={register}
            placeholder="Name"
            name="name"
            defaultValue={currentPalette?.name}
          />
          {errors.name && <div className="field-error">{errors.name.message}</div>}
        </label>
      </div>


      {indexes.map((index, i) => {
        const fieldName = `colors_attributes[${index}].`;
        const q = (colors && colors[index]) ? colors[index] : {}
        const hide = removedIndexes.includes(index);

        return (
          
          <ColorFields 
            key={index}
            setValue={setValue}
            removeColor={removeColor} 
            hide={hide} 
            fieldName={fieldName} 
            register={register} 
            errors={errors}
            i={i}
            index={index} 
            q={q} 
            currentColor={q}
          />


        );
      })}
      <div className="center margin-top-2">
        <div className="ghost button" onClick={addColor}>
          +
          ADD COLOR
        </div>
      </div>








      <div className="margin-top-3 align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>
      

    </form>
  )

}

export default PaletteForm;