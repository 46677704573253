import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getBand } from 'redux/club/bandActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubBandNav from './ClubBandNav';

import BandForm from './BandForm';

const ClubBandLayout = ({children}) => {

  const { code } = useParams();

  useEffect(() => {
    if (code) {
      fetchBand()
    }
  }, [code]);

  const [band, bandLoading, bandLoaded, bandError, doFetchBand, setBandResults] = useApi();

  const fetchBand = () => {
    doFetchBand(getBand, {query: {code: code}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editBandClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchBand();
    }
  } 


  
  
  

  return (
    <>
      {band && editModalOpened &&
        <BandForm toggleModal={toggleEditModal} currentBand={band} />
      }
      <div className="narrow-2x padding-top-1">
        <div className="flex-cont align-center">
          {band?.avatar_url &&
            <div className="band-logo-cont small margin-right-2">
              <img src={band?.avatar_url} alt="" />
            </div>
          }
          <h1>
            {band?.code}
          </h1>

          <div className="flex-1"></div>

          {/* <div>
            <i onClick={editBandClicked} className="ghost button fas fa-pencil-alt"></i>
          </div> */}
        </div>
        

        <ClubBandNav code={code} />

        {children}
        {/* {React.cloneElement(children, {band: band})} */}


      </div>
    </>
  )

}



export default ClubBandLayout;