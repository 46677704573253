import { andyFetch } from '../../api';


export const createMaterialFeeWaiver = (params) => {

  return async (dispatch, getState) => {

    const url = "club/material_fee_waivers";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createMaterialFeeWaiver error.');
      }
    }
    catch (e) {
      console.log("createMaterialFeeWaiver error", e);
    }
  }
};


export const updateMaterialFeeWaiver = (params) => {

  return async (dispatch, getState) => {

    const url = `club/material_fee_waivers/${params.payload.material_fee_waiver.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateMaterialFeeWaiver error.');
      }
    }
    catch (e) {
      console.log("updateMaterialFeeWaiver error", e);
    }
  }
};


export const deleteMaterialFeeWaiver = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/material_fee_waivers/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteMaterialFeeWaiver error.');
      }
    }
    catch (e) {
      console.log("deleteMaterialFeeWaiver error", e);
    }
  }
};
