import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import '../../styles/TipTap.scss';
import React from 'react';



const TipTapMenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <div className="tip-tap-menu-cont margin-bottom-1">
      <div
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active button' : 'button'}
      >
        bold
      </div>
      <div
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active button' : 'button'}
      >
        italic
      </div>
      <div
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active button' : 'button'}
      >
        strike
      </div>
      <div
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={editor.isActive('code') ? 'is-active button' : 'button'}
      >
        code
      </div>
      {/* <div onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </div>
      <div onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </div> */}
      <div
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? 'is-active button' : 'button'}
      >
        paragraph
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active button' : 'button'}
      >
        h1
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active button' : 'button'}
      >
        h2
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active button' : 'button'}
      >
        h3
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active button' : 'button'}
      >
        h4
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active button' : 'button'}
      >
        h5
      </div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active button' : 'button'}
      >
        h6
      </div>
      <div
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active button' : 'button'}
      >
        bullet list
      </div>
      <div
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active button' : 'button'}
      >
        ordered list
      </div>
      <div
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active button' : 'button'}
      >
        code block
      </div>
      <div
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active button' : 'button'}
      >
        blockquote
      </div>
      <div className="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </div>
      <div className="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </div>
      <div className="button" onClick={() => editor.chain().focus().undo().run()}>
        undo
      </div>
      <div className="button"  onClick={() => editor.chain().focus().redo().run()}>
        redo
      </div>
    </div>
  )
}

export default TipTapMenuBar;