import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; 
import { useApi } from '../api';
import { getPage, applyToPage } from 'redux/pages/pageActions';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';
import * as yup from "yup";


const PageLayout = ({currentUser}) => {
  let { slug } = useParams();

  const [page, loading, loaded, error, doFetch, setPage] = useApi();

  useEffect(() => {
    fetchPage();
  }, [slug]);

  const fetchPage = () => {
    doFetch(getPage, {query: {slug: slug}});
  }

  return (
    <>
      <div>

        <div style={{backgroundImage: `url(${page?.image_url}`}} className="page-banner"></div>
      </div>

      <div className="narrow">

        

        {loading &&
          <div className="center">
            <div className="spinner"></div>
          </div>
          
        }
        <div>
          {page &&
            <>
              <h1>{page.title}</h1>

              <div className="margin-top-1" dangerouslySetInnerHTML={{__html: page?.content}}>
              </div>
            </>
          }
        </div>
      </div>

      
    </>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(PageLayout);
