import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';

import { getAthlete } from 'redux/club/athleteActions';
import { getBadgeAthletes, createBadgeAthlete, getBadges, destroyBadgeAthlete, updateBadgeAthlete } from 'redux/club/badgeActions';


import { DateTime } from 'luxon';
import Modal from 'components/Modal';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import { useForm } from 'react-hook-form';
import ClubAthleteNav from './ClubAthleteNav';




const ClubAthleteBadges = () => {
  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });
  const { id } = useParams();
  const [editingBadgeAthlete, setEditingBadgeAthlete] = useState(null);
  const [athlete, athleteLoading, athleteLoaded, athleteError, doFetchAthlete, setAthleteResults] = useApi();

  const fetchAthlete = () => {
    doFetchAthlete(getAthlete, {query: {id: id}})
  }

  const [badgeAthletes, badgeAthletesLoading, badgeAthletesLoaded, badgeAthletesError, doFetchBadgeAthletes, setBadgeAthletesResults] = useApi();

  const fetchBadgesAthletes = () => {
    doFetchBadgeAthletes(getBadgeAthletes, {query: {slug: id}})
  }

  useEffect(() => {
    if (id) {
      fetchAthlete();
      fetchBadgesAthletes();
    }
  }, [id]);


  const [addBadgeAthleteOpened, setAddBadgeAthleteOpened] = useState(false);

  const toggleAddBadgeAthlete = (reload=false) => {
    setAddBadgeAthleteOpened(o => !o);

    if (reload) {
      fetchAthlete();
      fetchBadgesAthletes();
    }
  }

  useEffect(() => {
    if (!addBadgeAthleteOpened) {
      setEditingBadgeAthlete(null);
    }
  }, [addBadgeAthleteOpened])

  const editClicked = badgeAthlete => {
    setEditingBadgeAthlete(badgeAthlete);
    setAddBadgeAthleteOpened(true);
  }



  return (
    <>

      {/* {editingBadgeAthlete && 
        <>
          <AddBadgeAthleteModal toggleModal={toggleAddBadgeAthlete} athlete={athlete} badgeAthlete={editingBadgeAthlete} />
        </>
      } */}

      

      {addBadgeAthleteOpened &&
        <AddBadgeAthleteModal toggleModal={toggleAddBadgeAthlete} athlete={athlete} badgeAthlete={editingBadgeAthlete} />
      }

      <div onClick={toggleAddBadgeAthlete} className="button margin-bottom-2">
        <i className="fas fa-plus margin-right-half"></i>
        Give Badge
      </div>

      <table className="basic">
        {badgeAthletes?.map(badgeAthlete => 
          <tr>
            <td className="fit">
              <img style={{width: '50px'}} src={badgeAthlete.badge?.image_url} alt="" />
            </td>
            <td className="fit nowrap bold">{badgeAthlete.badge?.name}</td>
            <td className="">{badgeAthlete.badge?.description}</td>
            <td className="">{badgeAthlete.note}</td>
            <td className="full">
              {DateTime.fromISO(badgeAthlete.created_at).toLocaleString(DateTime.DATE_FULL)}
              {/* {badgeAthlete.created_at} */}
            </td>
            <td className="fit nowrap">
              <i className="margin-right-1 ghost button fas fa-pencil-alt" onClick={() => editClicked(badgeAthlete)}></i>
              {/* <i className="ghost button fas fa-trash" onClick={() => setEditingBadgeAthlete(badgeAthlete)}></i> */}
            </td>
          </tr>  
        )}

      </table>

  
      


  
    </>
  )

}

const AddBadgeAthleteModal = ({toggleModal, athlete, badgeAthlete=null}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });

  const [updateResult, updateLoading, updateLoaded, updateError, doUpdateFetch, setUpdateResult] = useApi(); 
  const [badges, loading, loaded, error, doFetch, setResult] = useApi(); 

  
  const [removeResult, removeLoading, removeLoaded, removeError, doRemoveFetch, setRemoveResult] = useApi(); 
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const toggleRemoveModal = () => setRemoveModalOpened(o => !o);

  const confirmRemoveClicked = () => {
    doRemoveFetch(destroyBadgeAthlete, {payload: {id: badgeAthlete.id}})
  }
  useEffect(() => {
    if (removeLoaded) {

      toggleModal(true);
      setRemoveModalOpened(false);
      // fetchAthlete();
    }
  }, [removeLoaded]);

  const fetchBadges = () => {
    doFetch(getBadges);
  }

  useEffect(() => {
    fetchBadges();
  }, [])

  const onSubmit = data => {
    
    const payload = {
      badge_athlete: {
        ...data,
      }
    }
    doUpdateFetch(createBadgeAthlete, {payload: payload})
  }

  useEffect(() => {
    if (updateLoaded) {
      toggleModal(true);
    }
  }, [updateLoaded]);

  const [badgeModalOpened, setBadgeModalOpened] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(false);

  const selectBadgeClicked = () => {
    setBadgeModalOpened(o => !o);
  }

  const toggleBadgeModal = () => {
    setBadgeModalOpened(o => !o);
  }

  useEffect(() => {
    if (badgeAthlete) {
      // setValue('color_palette_id', currentAvatarPartCat?.palette?.id)
      setSelectedBadge(badgeAthlete?.badge);
    }
  }, [badgeAthlete]);

  useEffect(() => {
    if (selectedBadge) {
      // alert(selectedBadge.id)
      setValue('badge_id', selectedBadge.id)
    }
  }, [selectedBadge]);


  

  return (
    <>
      
      <Modal toggleModal={toggleModal}>
        <form onSubmit={handleSubmit(onSubmit)}>


          <input 
            ref={register()} 
            type="hidden" 
            name="athlete_id" 
            value={athlete.id} 
          />

          <input 
            ref={register()} 
            type="hidden" 
            name="id" 
            value={badgeAthlete?.id} 
          />

          <div className="modal-header flex-cont align-center">
            <h1 className="flex-1">
              {!badgeAthlete &&
                <>
                  Give Badge to {athlete.full_name}
                </>
              }
              {badgeAthlete &&
                <>
                  Edit Badge
                </>
              }
              
            </h1>
            <div onClick={toggleModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="padding-1">

            {loading && <div className="spinner"></div>  }


            <div className="flex-cont">
              <label htmlFor="award_attributes.coins"> 
                <span>Memo</span>
                <input 
                  type="text" 
                  ref={register}
                  name="note"
                  defaultValue={badgeAthlete?.note}
                />
                {errors?.note && <div className="field-error">{errors?.note?.message}</div>}
              </label>
            </div>
                  

            <div className="margin-bottom-2">
            <h2 className="margin-top-2">Badge</h2>
            <div className="margin-bottom-1">
              
            </div>
            <div onClick={selectBadgeClicked} className="outline button">{selectedBadge ? 'Change' : 'Select'} Badge</div>

            <input ref={register({
                required: "Please select a badge."
              })} 
              type="hidden" 
              name="badge_id"
            />

            {errors.badge_id && <div className="field-error">{errors.badge_id.message}</div>}

            {selectedBadge &&
              <div className="margin-top-1">
                <div className="card no-hover inline-flex align-center">
                  <img style={{width: '100px'}} src={selectedBadge.image_url} alt="" />
                  <div className="margin-left-1 bold">
                    {selectedBadge.name}
                  </div>
                  <div className="margin-left-1">
                    {selectedBadge.description}
                  </div>
                </div>
              </div>
            }

            {badgeModalOpened &&
              <BadgeModal toggleModal={toggleBadgeModal} setSelectedBadge={setSelectedBadge} />
            }
          </div>
          </div>
          <div className="modal-footer flex-cont align-center">
            <button className="button">Save</button>
            <div className="flex-1">
            </div>
            <div onClick={toggleRemoveModal} className="button ghost danger">Remove</div>
          </div>

          {removeModalOpened && badgeAthlete &&
            <Modal toggleModal={toggleRemoveModal} focusTrap={false} size="small">
              <div className="padding-2">
                Are you sure you want to remove this badge from {athlete.full_name}?
              </div>

              <div className="modal-footer">
                <div className="flex-cont align-center">

    
                  <div onClick={toggleRemoveModal} className="ghost button">
                    Cancel
                  </div>
                  <div className="flex-1"></div>
                  <div disabled={removeLoading} onClick={confirmRemoveClicked} className="button danger">
                    Remove
                    {removeLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                  
                </div>
              </div>
            </Modal>
          }
        </form>
      </Modal>
    </>
  )
}


export const BadgeModal = ({toggleModal, setSelectedBadge}) => {
  const [badges, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchBadges();
  }, []);


  const fetchBadges = () => {
    doFetch(getBadges);
  }

  const badgeClicked = (badge) => {
    setSelectedBadge(badge);
    toggleModal();
  }

  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <div className='modal-header flex-cont align-center'>
          <h1 className="flex-1">Select Badge</h1>
          <div onClick={toggleModal} className="button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

      <div className="padding-1">
        {badges &&
          <table className="basic">
            {badges.map(badge => {
              return (
                <tr>

                  <td className="fit nowrap">
                    <div onClick={() => badgeClicked(badge)} className="small button">
                      Select
                    </div>
                  </td>

                  <td className="fit nowrap">
                    <img style={{width: '75px'}} src={badge.image_url} alt="" />
                  </td>

                  <td className="bold fit nowrap">
                    <div className="bold">
                      {badge.name}
                    </div>
                  </td>

                  <td>
                    {badge.description}
                  </td>

                </tr>
              )
            })}
          </table> 
        }
      </div>

      <div className='modal-footer flex-cont align-items-center'>
        <div onClick={toggleModal} className="button ghost">Cancel</div>
      </div>
    </Modal>
  )
}



export default ClubAthleteBadges;