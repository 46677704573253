import { andyFetch } from '../../api';

export const getAthletes = (params) => {

  return async (dispatch, getState) => {

    const url = "club/athletes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        // return json.data;

        return {
          json: json,
          fullResponse: response,
        }


      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAthletes error", e);
    }
  }
};

export const getAthlete = (params) => {
  return async (dispatch, getState) => {

    const url = `club/athletes/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAthlete error", e);
    }
  }
};

export const getCoinTransactions = (params) => {
  return async (dispatch, getState) => {

    const url = `club/athletes/${params.query.id}/coin_transactions`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();

        // alert(JSON.stringify(json, null, 2))
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCoinTransactions error", e);
    }
  }
};


export const createAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = "club/athletes";

    try {

      const response = await andyFetch(url, params, "POST");

      console.log("Response 2", response)


      if (response.ok) {
        console.log("R 1a");
        const json = await response.json();
        console.log("R 1b", json);
        return json.data;

      } else {
        console.log("R 2");
        throw new Error('createAthlete error.');
      }
    }
    catch (e) {
      console.log("R 3");
      // return {error: e.toString()}
      console.log("createAthlete error", e);
    }
  }
};


export const updateAthlete = (params) => {

  return async (dispatch, getState) => {

    const url = `club/athletes/${params.payload.athlete.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAthlete error.');
      }
    }
    catch (e) {
      console.log("updateAthlete error", e);
    }
  }
};

export const acceptMembership = (params) => {

  return async (dispatch, getState) => {

    const url = `club/athletes/accept`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('acceptMembership error.');
      }
    }
    catch (e) {
      console.log("acceptMembership error", e);
    }
  }
};

export const rejectMembership = (params) => {

  return async (dispatch, getState) => {

    const url = `club/athletes/reject`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('acceptMembership error.');
      }
    }
    catch (e) {
      console.log("acceptMembership error", e);
    }
  }
};

export const deleteAthlete = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/athletes/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAthlete error.');
      }
    }
    catch (e) {
      console.log("deleteAthlete error", e);
    }
  }
};


export const destroyClubMembership = (params) => {
  return async (dispatch, getState) => {
    const url = `club/athletes/destroy_club_membership`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('destroyClubMembership error.');
      }
    }
    catch (e) {
      console.log("destroyClubMembership error", e);
    }
  }
};

export const changeTeam = (params) => {
  return async (dispatch, getState) => {
    const url = `club/athletes/${params?.query?.athlete_id}/change_team`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('destroyClubMembership error.');
      }
    }
    catch (e) {
      console.log("destroyClubMembership error", e);
    }
  }
};

export const changeBand = (params) => {
  return async (dispatch, getState) => {
    const url = `club/athletes/${params?.query?.athlete_id}/change_band`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('changeBand error.');
      }
    }
    catch (e) {
      console.log("changeBand error", e);
    }
  }
};

export const giveCoins = (params) => {
  return async (dispatch, getState) => {
    const url = `club/athletes/${params?.query?.athlete_id}/give_coins`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('giveCoins error.');
      }
    }
    catch (e) {
      console.log("giveCoins error", e);
    }
  }
};