import React, {useEffect, useState} from 'react';
import { useApi } from '../../api';
import { getSponsorStats } from 'redux/athlete/athleteActions';


import { useVisibility } from 'components/useVisibility';

import { useOutletContext } from 'react-router-dom';



const Sponsor = ({pledge, index}) => {
  const { currentElement, isVisible } = useVisibility(-200);
  const [startCount, setStartCount] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (isVisible && !startCount) {
      setStartCount(true);
    } 
  }, [startCount, isVisible])


  const [pp, setPp] = useState(0);


  useEffect(() => {
    if (startCount) {
      setTimeout(() => { 
        setStartAnimation(true);
      }, (250*(index+1)));
    } 
  }, [startCount]);

  return (
    <div ref={currentElement} className={`sponsor-box2-cont ${startAnimation ? 'is-visible' : ''}`}>
      <div className="sponsor-box2">
        <i className="fas fa-user"></i>
      </div>
      <div className="dollars">
        {(pledge.pledger_max_cents/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </div>
      <div  className="font-08 name gray bold">{pledge.pledger_name}</div>
    </div>
  )
}

const SponsorBox = ({}) => {

  const {hasActiveCampaign} = useOutletContext();
  

  const [stats, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    fetchSponsorStats();
  }, []);

  const fetchSponsorStats = async () => {
    doFetch(getSponsorStats);
  };

  return (
    <div className="no-click no-hover align-center justify-center card grid-item span-1-v span-6-h box">
      <div className="relative">
          <div className="number-label">SPONSOR GOAL</div>

          {loading && <div className="spinner"></div> }

          <div className="sponsor-cont">

            {hasActiveCampaign && <>
              {stats?.pledges?.map((pledge, index) => {
                return (
                  <>
                  
                    <Sponsor pledge={pledge} index={index} />
                  </>
                  
                )
              })}
              {stats?.pledges?.length < 5 && [...Array((5-stats?.pledges?.length)).keys()].map( i => {
                return (
                  <div className="sponsor-box2-cont empty">
                    <div className="sponsor-box2 ">
                      
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                )
              })}
            </>}

           

            {!hasActiveCampaign &&
              <>
                {[...Array(5).keys()].map( i => {
                  return (
                    <div className="sponsor-box2-cont empty">
                      <div className="sponsor-box2 ">
                        
                        <i className="fas fa-user"></i>
                      </div>
                    </div>
                  )
                })}
              </>
            
            }

            

            
{/*             
            <div className="sponsor-box2-cont">
              <div className="sponsor-box2">
                <i className="fas fa-user"></i>
                
              </div>
              <div className="dollars">
                $50
              </div>
              <div className="name">
                Grandma Ann
              </div>
            </div>

            <div className="sponsor-box2-cont">
              <div className="sponsor-box2">
                <i className="fas fa-user"></i>
              </div>
              <div className="dollars">
                $80
              </div>
              <div className="name">
                Aunt Meg
              </div>
            </div>

            <div className="sponsor-box2-cont empty">
              <div className="sponsor-box2 ">
                <i className="fas fa-user"></i>
              </div>
            </div>

            <div className="sponsor-box2-cont empty">
              <div className="sponsor-box2 ">
                <i className="fas fa-user"></i>
              </div>
            </div> */}

        </div>
        </div>


      </div>
  )
}

export default SponsorBox;