

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { createCoinItemPurchase } from 'redux/club/coinItemPurchaseActions';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../../api'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import { useEditor, EditorDescription } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import coinPNG from 'images/herocoin.png';
import { getAthletes } from 'redux/club/athleteActions';
import AutoComplete from 'components/AutoComplete';

const CoinItemPurchaseForm = ({toggleModal, coinItem, currentPurchase}) => {


  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    // validationSchema: CoinItemSchema
  });


  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [athletes, athletesLoading, athletesLoaded, athletesError, doAthletesFetch, setAthletesResult] = useApi(); 

  useEffect(() => {
    fetchAthletes();
  }, []);

  const fetchAthletes = () => {
    doAthletesFetch(getAthletes, {query: {no_pagy: true}});
  }

  const onSubmit = async data => {
    let d = {
      coin_item_purchase: {
        ...data
      }
    }

    doFetch(createCoinItemPurchase, {payload: d});
    
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);


  const renderRow = item => <Row price={coinItem?.price} item={item} />


  return (
    <>
    
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">New Purchase</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1 padding-bottom-4">

        <input 
          type="hidden" 
          name="coin_item_id" 
          ref={register}
          defaultValue={coinItem?.id}
        />
      

      <div className="bold font-1-25">{coinItem?.name}</div>
      <div className="flex-cont align-center margin-bottom-1">
        <img className="margin-right-half" style={{height: '1em'}} src={coinPNG} alt="" />
        <div className='gold bold'>{coinItem.price}</div>
      </div>
      
      <AutoComplete 
        setValue={setValue}
        defaultValue={currentPurchase?.athlete_id}
        defaultSearch={currentPurchase?.athlete_name}
        name={"athlete_id"}
        items={athletes}
        fullName={"FULL NAME"}
        register={register}
        errors={errors}
        label="Student:"
        labelFn={(item) => `${item.first_name} ${item.last_name}` }
        valueFn={(item) => item.id }
        searchableAttributes={["first_name", "last_name"]}
        labelComponent={renderRow}
      />

      {errors.athlete_id && <div className="field-error">{errors.athlete_id.message}</div>}






      </div>

      <div className="modal-footer align-center flex-cont">
        <div onClick={toggleModal} className="ghost button">Cancel</div>
        <div className="flex-1"></div>
        
        <button className={`${loading ? 'disabled' : ''} button`}>
          Save
          {loading && <div className="spinner button-spinner"></div>}
        </button>

      </div>

    </form>
    </>
  )

}

const Row = ({item, price=0}) => {

  const canAfford = item.coins <= price;

  return (
    <div className={`${canAfford ? 'opacity-5' : ''} flex-cont`}>
      {/* <div className="flex-cont flex-wrap margin-right-1" style={{width: '5em', background: 'lime'}} >{item.last_name}, {item.first_name} </div> */}

      <div className='flex-1 padding-right-1'  style={{whiteSpace: 'initial', background: 'none'}} >
        {item.last_name}, {item.first_name} 
        {/* a */}
        </div>


        <div className="flex-cont align-center" style={{whiteSpace: 'nowrap', background: 'none'}}>
          <img className="margin-right-half" style={{height: '1em'}} src={coinPNG} alt="" />
          <div className="bold gold">{item.coins}</div>
        </div>

 

      
      
      {/* <div className="uppercase margin-left-1 margin-right-1 bold">{item.status}</div> */}
    </div>
  )
}

export default CoinItemPurchaseForm;