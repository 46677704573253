

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { getClubs } from 'redux/admin/clubActions';
import { getAvatarPartCats, updateAvatarPartCat, createAvatarPartCat, deleteAvatarPartCat } from 'redux/admin/avatarPartCatActions';
import { PaletteModal } from './AdminAvatarPartNew';


const AvatarPartCatSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  has_body_color: yup.boolean(),
  color_palette_id: yup.string(),

});


const AvatarPartCatForm = ({toggleModal, currentAvatarPartCat}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: AvatarPartCatSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [avatarPartCatsResult, avatarPartCatsLoading, avatarPartCatsLoaded, avatarPartCatsError, doFetchAvatarPartCats, setAvatarPartCatResults] = useApi();


  const onSubmit = async data => {
    let d = {
      avatar_part_cat: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentAvatarPartCat && currentAvatarPartCat.id) {
      doFetch(updateAvatarPartCat, {payload: d});
    }
    else {
      doFetch(createAvatarPartCat, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);


  
  const isBodyColorChecked = watch('has_body_color');

  useEffect(() => {
    if (isBodyColorChecked) {

    }
  }, [isBodyColorChecked])

  const [selectedPalette, setSelectedPalette]= useState(null);

  const [color, setColor] = useState('gray');

  useEffect(() => {
    if (selectedPalette?.colors) {
      setColor(selectedPalette.colors[0].hex)
    }
  }, [selectedPalette])

  const [paletteModalOpened, setPaletteModalOpened] = useState(false);

  const togglePaletteModal = () => {
    setPaletteModalOpened(o => !o);
  }

  useEffect(() => {
    if (selectedPalette) {
      setValue('color_palette_id', selectedPalette.id)
    }
  }, [selectedPalette])


  useEffect(() => {
    if (currentAvatarPartCat) {
      // setValue('color_palette_id', currentAvatarPartCat?.palette?.id)
      setSelectedPalette(currentAvatarPartCat?.palette);
    }
  }, [currentAvatarPartCat])


  return (
    <> 
      {paletteModalOpened &&
        <PaletteModal setSelectedPalette={setSelectedPalette} toggleModal={togglePaletteModal} />
      }
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentAvatarPartCat ? 'Edit' : 'New'} Avatar Part Category</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        {/* <div className="code">
          <pre>
            {JSON.stringify(currentAvatarPartCat, null, 2)}
          </pre>
        </div>
        <div className="code">
          <pre>
            {JSON.stringify(selectedPalette, null, 2)}
          </pre>
        </div> */}
        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentAvatarPartCat?.id}
        />

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentAvatarPartCat?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>
        <div className="flex-cont">
          <label htmlFor="has_body_color">
            
            <input defaultChecked={currentAvatarPartCat ? currentAvatarPartCat.has_body_color : true} type="checkbox" name="has_body_color" ref={register} id=""/>
            <span className="margin-left-1">Uses Body Color?</span>

            {errors.has_body_color && <div className="field-error">{errors.has_body_color.message}</div>}
          </label>
        </div>

        <input type="hidden" ref={register} name="color_palette_id"/>

        {true &&
          <label>
            <span>PALETTE</span>
            <div>
            {selectedPalette &&
              <div className="margin-top-1 flex-contx align-center">
                <div className="bold fit nowrap">
                  <div className="bold margin-bottom-half">
                    {selectedPalette.name}
                  </div>
                </div>



                <div style={{width: '10em'}}>
                  {selectedPalette?.colors?.map(c =>
                  
                    <div onClick={() => setColor(c.hex)}
                      style={{
                        marginRight: '5px', 
                        boxShadow: '0 3px 10px rgba(0,0,0,.25)', 
                        cursor: 'pointer', 
                        borderRadius: '50%', 
                        display:'inline-block', 
                        width: '30px', 
                        height: '30px', 
                        backgroundColor: c.hex,

                        border: color === c.hex ? '3px solid rgba(255,255,255.75)' : 'none',
                      }}>
                    </div>
                  )}   
                </div>

              </div>
              
            }
            <div onClick={togglePaletteModal} className="button ghost">
              {selectedPalette ? 'Change' : 'Select'} Palette
            </div>
            </div>
          </label>          
        }

      </div>
      
      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
    </>
  )

}




const AdminAvatarPartCats = ({currentUser}) => {

  const [avatarPartCats, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedAvatarPartCat, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedAvatarPartCat] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingAvatarPartCat, setEditingAvatarPartCat] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchAvatarPartCats();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingAvatarPartCat(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!avatarPartCats) {
      fetchAvatarPartCats();
    }
  }, []);

  const fetchAvatarPartCats = () => {
    doFetch(getAvatarPartCats);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingAvatarPartCat, setDeletingAvatarPartCat] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchAvatarPartCats();
    }
  }


  const editClicked = avatarPartCat => {
    setEditingAvatarPartCat(avatarPartCat);
    toggleModal();
  }

  const deleteClicked = avatarPartCat => {
    setDeletingAvatarPartCat(avatarPartCat);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingAvatarPartCat) {
      doDeleteFetch(deleteAvatarPartCat, {query: {id: deletingAvatarPartCat.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);




  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <AvatarPartCatForm currentAvatarPartCat={editingAvatarPartCat} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Avatar Part Categories</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Category</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {avatarPartCats &&
        <table className="basic">
          {avatarPartCats.map(avatarPartCat => {
            return (
              <tr>
                <td onClick={() => editClicked(avatarPartCat)} className="fit nowrap text-link">
                  {avatarPartCat?.name}
                </td>
                <td>
                  {avatarPartCat?.palette?.colors?.map(color =>
                    
                    <div
                      style={{marginRight: '10px', boxShadow: '0 15px 50px rgba(0,0,0,.25)', cursor: 'pointer', borderRadius: '50%', display:'inline-block', width: '20px', height: '20px', backgroundColor: color.hex}}>
                    </div>
                  )}   
                </td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(avatarPartCat)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>



                  <div onClick={() => deleteClicked(avatarPartCat)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete AvatarPartCat</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingAvatarPartCat?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!avatarPartCats || avatarPartCats.length === 0) &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminAvatarPartCats;