import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

const ClubWeek = ({week}) => {


  return (
    <Link to="/calendar" className="flex-cont dash-week-cont card" style={{background: 'white', padding: '.5em'}}>
      {week && week.length !== 0 && week?.map(day =>

        <div className="day-cont flex-1">
          <div className="dates">
            <div>
              <span className="bold">{DateTime.fromISO(day.datetime_string)?.toFormat('ccc')} </span>
              <span> {DateTime.fromISO(day.datetime_string)?.toFormat('L/d')}  </span>
            </div>
          </div>
          <div  className="dash-day">
            <div>
              {day?.events?.map(event => 
                <div className="event">{event.title || "Event"}</div>  
              )}
            </div>
          </div>
        </div>
      )}
    </Link>
  )
}

export default ClubWeek;