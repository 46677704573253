import { andyFetch } from '../../api';

export const getPalettes = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/palettes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPalettes error", e);
    }
  }
};

export const getPalette = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/palettes/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPalette error", e);
    }
  }
};


export const createPalette = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/palettes";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPalette error.');
      }
    }
    catch (e) {
      console.log("createPalette error", e);
    }
  }
};


export const updatePalette = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/palettes/${params.payload.color_palette.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePalette error.');
      }
    }
    catch (e) {
      console.log("updatePalette error", e);
    }
  }
};


export const deletePalette = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/palettes/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePalette error.');
      }
    }
    catch (e) {
      console.log("deletePalette error", e);
    }
  }
};

