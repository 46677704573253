

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';

import Ring from 'components/Ring';
import Bar from 'components/Bar';

import 'styles/Profile.scss';

import store from 'redux/store';
import { connect } from 'react-redux';

import EmailConfBanner from 'components/EmailConfBanner';

import ProfileNoAthletes from './ProfileNoAthletes';
import Notis from './Notis';
import ProfileBottomNav from 'screens/profile/ProfileBottomNav';

import { Canvas, useFrame } from 'react-three-fiber'

import { changeAthlete, authParent } from '../../redux/session/sessionActions';

import { useApi } from '../../api';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { logoutUser } from 'redux/session/sessionActions';

import SubmitButton from '../../components/SubmitButton';
import MaterialFeeBox from './MaterialFeeBox';

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

const ParentAuthSchema = yup.object().shape({
  password: yup.string().required("Please enter your password.")
});

const SelectProfile = ({parentAuthed, currentUser, currentAthlete, sessionState}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: ParentAuthSchema
  });

  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [athlete, athleteLoading, athleteLoaded, athleteError, doAthleteFetch, setAthlete] = useApi(); 
  const [parent, parentLoading, parentLoaded, parentError, doParentFetch, setPrent] = useApi(); 

  const [parentModalOpened, setParentModalOpened] = useState(false);

  const [clickedAthlete, setClickedAthlete] = useState(null);


  const athleteClicked = (athlete) => {
    // setSelectedAthlete(athlete);
    setClickedAthlete(athlete);


    const data = {
      athlete_id: athlete?.id
    }
    doAthleteFetch(changeAthlete, {payload: data});
  }

  const parentClicked = () => {
    toggleParentModal();
  }

  const toggleParentModal = () => {
    setParentModalOpened(o => !o);
  }



  const selectAthleteSubmitted = async () => {
    const data = {
      athlete_id: selectedAthlete?.id
    }
    doAthleteFetch(changeAthlete, {payload: data});
  }

  const onSubmit = async data => {
    const d = {
      ...data
    };

    doParentFetch(authParent, {payload: d});
  }

  // useEffect(() => {

  // }, [parentLoaded]);

  const logoutClicked = async () => {
    await store.dispatch(logoutUser());
    // navigate("/");
  }

  const profileUrl = (index) => {
    if (index === 0) {
      return "https://vignette.wikia.nocookie.net/fortnite/images/0/0c/Recruit_4_%28Chapter_2%29_-_Outfit_-_Fortnite.png/revision/latest?cb=20191016000452"
      // "https://vignette.wikia.nocookie.net/fortnite/images/d/da/Recruit_5_-_Outfit_-_Fortnite.png/revision/latest?cb=20180607182055"
    }
    else if (index === 1) {
      return "https://vignette.wikia.nocookie.net/fortnite/images/5/5b/Recruit_1_%28Chapter_2%29_-_Outfit_-_Fortnite.png/revision/latest?cb=20191016000133"
    }
    else if (index === 2) {
      return "https://vignette.wikia.nocookie.net/fortnite/images/e/e5/Recruit_8_-_Outfit_-_Fortnite.png/revision/latest?cb=20180607182057"
    }
    else if (index === 3) {
      return "https://vignette.wikia.nocookie.net/fortnite/images/0/0c/Recruit_4_%28Chapter_2%29_-_Outfit_-_Fortnite.png/revision/latest?cb=20191016000452"
    }
    else if (index === 4) {
      return "https://vignette.wikia.nocookie.net/fortnite/images/9/9f/Recruit_2_-_Outfit_-_Fortnite.png/revision/latest?cb=20180607182053"
    }
  }




  return (
    <div className="narrow center">

      <h1>{currentUser?.last_name} Family</h1>

      <MaterialFeeBox />
      <h2>Select Profile:</h2>

      {/* {currentUser && currentUser.athletes && currentUser.athletes.map((athlete, index) => {
        return (
          <div onClick={() => athleteClicked(athlete)}   className={`profile-card-cont pointer ${selectedAthlete && selectedAthlete.id === athlete.id ? 'selected' : ''}`}>
            <div className="profile-card-color"></div>

            {athlete?.avatar_url &&
              <div style={{backgroundImage: `url(${athlete?.avatar_url  })`}} className="profile-card-image"></div>
            }
            {!athlete?.avatar_url &&
              <i className="fas fa-user"></i>
            }


            {true && <div className="absolutex loading font-3"></div> }
          
            

            <div className="profile-card-name">{athlete.first_name}</div>
          </div>
        )
      })} */}

      <div className="select-profile margin-top-2">
        {currentUser && currentUser.athletes && currentUser.athletes.map((athlete, index) => {
          return (
            <div onClick={() => athleteClicked(athlete)}   className={`relative profile-card-cont2 pointer ${selectedAthlete && selectedAthlete.id === athlete.id ? 'selected' : ''}`}>

              <div className='profile-circle'>

                {athlete?.avatar_url &&
                  <div style={{backgroundImage: `url(${athlete?.avatar_url  })`}} className=""></div>
                }
                {!athlete?.avatar_url &&
                  <i className="fas fa-user"></i>
                }


                {athleteLoading && (clickedAthlete?.id?.toString() === athlete.id.toString()) &&  <div className="absolute spinner font-3 dark-blue"></div> }
              </div>
              
              

              <div className="name">{athlete.first_name}</div>
            </div>
          )
        })}

        <div onClick={parentClicked}  className="pointer profile-card-cont2">
          <div className='profile-circle'>
            <i className="fas fa-lock"></i>
          </div>
          <div className="name">Parent</div>
        </div>
      </div>


      {/* <div onClick={parentClicked}  className="pointer profile-card-cont parent-card">
        <div className="profile-card-color"></div>
        <i className="fas fa-lock"></i>
        <div className="profile-card-name">Parent</div>
      </div> */}

      {false &&
      <div className="flex-cont full justify-center margin-top-3">
        {/* <div onClick={parentClicked} className="button ghost margin-right-1">
          <i className="fas fa-lock margin-right-1"></i>Parent Profile
        </div> */}
        <div onClick={logoutClicked} className="button ghost margin-right-1">
          Logout
        </div>
      </div>
      }
      

      {selectedAthlete &&
        <div onClick={selectAthleteSubmitted} className="margin-top-2 font-2 button">
          Continue as {selectedAthlete.first_name}
          <i className="margin-left-2 fas fa-chevron-right"></i>
        </div>
      }

      {/* <div>
        {JSON.stringify(parentAuthed)}
      </div> */}

      {parentModalOpened &&
        <Modal size="small" toggleModal={toggleParentModal}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="modal-header flex-cont align-center">
                <h2 className="flex-1">Enter your password to continue.</h2>

                <div onClick={toggleParentModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>

              </div>

              <div className="padding-1">
                <div className="flex-cont">
                  <label htmlFor="pw"> 
                    <input 
                      type="password" 
                      ref={register}
                      placeholder="Password"
                      name="password"
                    />
                    {errors.password && <div className="field-error">{errors.password.message}</div>}
                  </label>
                </div>

                {/* <div>
                  {JSON.stringify(errors)}
                </div> */}
              </div>

              <div className="modal-footer">
                {/* <input ref={register} type="submit" value="Continue" /> */}

                <SubmitButton label="Continue" disabled={parentLoading} loading={parentLoading} />
              </div>

            </div>
          </form>
        </Modal>
      }

      
    </div>
  )
};


const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
    sessionState: state.sessionState,
    parentAuthed: state.sessionState.parentAuthed,
  };
} 

export default connect(mapState)(SelectProfile);