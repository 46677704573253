

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAvatarPart } from 'redux/admin/avatarPartActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';


import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'

import SVG from './SVG';



const AdminAvatarPartView = ({currentUser}) => {

  let { id } = useParams();
  const [avatarPart, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAvatarPart();
  }, [id]);

  const fetchAvatarPart = () => {

    doFetch(getAvatarPart, {query: {id: id}});
  }

  return (
    <>
      <div className="andy-cont">
        {avatarPart?.images?.map(image => 
          
          <SVG layerType={image.kind} url={image.image_url} />  
        )}
      </div>

    </>
  )
}



export default AdminAvatarPartView;