import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { createPledge, getPledgePage } from 'redux/user/pledgeActions';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { loadStripe } from "@stripe/stripe-js";
import { 
  Elements,
  CardElement,
  useStripe,
  useElements 
} from "@stripe/react-stripe-js";

import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';



const Pledge = ({ }) => {

  const [pledgePage, isPreview] = useOutletContext();
  

  return (
    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>


      <div style={{
        // background: 'linear-gradient(180deg, rgba(0,117,179,1) 0%, rgba(11,179,207,1) 100%)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '200px',
      }}></div>

        <div className="narrow relative">

        

        

        {pledgePage && 


          <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>

            <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">


              <div className="blue font-3 bold-200 margin-bottom-3">

                {pledgePage.athlete_first_name &&
                  <>
                    Supporting {pledgePage.athlete_first_name} {pledgePage.athlete_last_name} and {pledgePage.club_name}
                  </>
                }
                {!pledgePage.athlete_first_name &&
                  <>
                    Supporting [Student Name] and {pledgePage.club_name}
                  </>
                }
                
              </div>

              <div className="margin-top-1 bold font-2 margin-bottom-2">
                {pledgePage.page_title}
              </div>

              <div>
                {pledgePage.page_content}
              </div>

              {/* <div className="margin-top-3">
                <Link to={`donor-setup`} className="button font-1-5">
                  Start Pledge
                  <i className="font-1 fas fa-arrow-right margin-left-half"></i>
                </Link>
              </div> */}
              
            </div>

            <PledgeSidebar pledgePage={pledgePage} isPreview={isPreview} />


          </div>
        
         
        }

        </div>
        
      </div>




    </>

 
  )
}

export default Pledge;