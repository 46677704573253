

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm, Controller } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'
import { createCompetition } from 'redux/club/competitionActions';
import { getPledgeDrives } from 'redux/club/pledgeDriveActions';
import { getTeams } from 'redux/club/teamActions';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"
import DatePicker from 'react-date-picker';
import '../../../styles/Calendar.scss';


const CompetitionSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  start_date: yup.string().nullable().required("Start date is required."),
  end_date: yup.string().nullable().required("End date is required."),
  pledge_drive_id: yup.string().required("Campaign is required."),
  kind: yup.string().required("Type is required."),
  competition_teams_attributes: yup.array().compact(v => v.team_id==="" || v.team_id===null).of(yup.object().shape({
    team_id: yup.string(),
  }))
});

const OnboardingCompetitions = ({currentClub}) => {

  const navigate = useNavigate();

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const { watch, register, errors, handleSubmit, getValues, control } = useForm({
    validationSchema: CompetitionSchema,
    mode: 'onChange'
  });

  const onSubmit = (data) => {
    const payload = {
      competition: {
        ...data
      }
    }
    // alert(JSON.stringify(payload, null, 2));
    doFetch(createCompetition, {payload: payload})
  }

  const hasErrors = () => (errors && Object.keys(errors).length !== 0);
  const buttonDisabled = () => (hasErrors() || loading);

  useEffect(() => {
    if (loaded) {
      navigate('/');
    }
  }, [loaded]);

  const [campaigns, campaignsLoading, campaignsLoaded, campaignsError, doCampaignsFetch, setCampaignsResult] = useApi();

  useEffect(() => {
    fetchCampaigns();
  }, [])

  const fetchCampaigns = () => {
    doCampaignsFetch(getPledgeDrives)
  }




  const [teams, teamsLoading, teamsLoaded, teamsError, doTeamsFetch, setTeamsResult] = useApi();

  useEffect(() => {
    fetchTeams();
  }, [])

  const fetchTeams = () => {
    doTeamsFetch(getTeams)
  }

  const [selectedTeams, setSelectedTeams] = useState([]);


  const checkBoxClicked = id => {
    if (teamSelected(id)) {
      setSelectedTeams(oldArray => oldArray.filter( elem => elem !== id));
    }
    else {
      setSelectedTeams(oldArray => [...oldArray, id]);
    }
  }

  const teamSelected = id => {
    return selectedTeams.includes(id)
  }

  const toggleAll = () => {
    if (!teams) return;

    if (allSelected()) {
      setSelectedTeams([]);
    }
    else {
      setSelectedTeams(teams.map(t => t.id));
    }
  }

  const allSelected = () => {
    if (!selectedTeams || selectedTeams.length === 0 || !teams) {
      return false;
    }
    return selectedTeams.length === Object.keys(teams).length;
  }

  const [selectedType, setSelectedType] = useState("points");
  
  return(
    <>
      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">5</div>
        <h1 className="no-margin-bottom">Competitions</h1>
        <h2 className="no-margin-top"></h2>

        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card no-hover margin-top-3">
            <label htmlFor="">
              <span className="label-text">Campaign</span>
              <br/>
              {campaignsLoading &&
                <div className="spinner"></div>
              }
              {campaigns &&
                <select ref={register} name="pledge_drive_id">
                  <option value="" disabled selected>Select a campaign</option>
                  {campaigns.map(campaign => <option value={campaign.id}>{campaign.name}</option>)}
                </select>
              }
              {errors.pledge_drive_id && <div className="field-error">{errors.pledge_drive_id.message}</div>}
            </label>



            <label htmlFor="">
              <span className="label-text">Name</span>
              <input ref={register}  type="text" name="name" id=""/>
              
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>

            <div className="flex-cont">
              <label htmlFor="">
                <span className="label-text">Start Date</span>
                <br/>
                <Controller
                  name='start_date'
                  control={control}
                  valueName="value"
                  onChange={([e]) => e}
                  as={<DatePicker />}
                />
                {errors.start_date && <div className="field-error">{errors.start_date.message}</div>}
              </label>
              <label htmlFor="">
                <span className="label-text">End Date</span>
                <br/>
                <Controller
                  name='end_date'
                  control={control}
                  valueName="value"
                  onChange={([e]) => e}
                  as={<DatePicker />}
                />
                {errors.end_date && <div className="field-error">{errors.end_date.message}</div>}
              </label>
            </div>

            <h3 className="margin-top-2">Competition Type</h3>

            <div className="flex-cont">
              {/* <div onClick={() => alert(JSON.stringify(getValues(), null, 2))} className="button">Get</div> */}
              <input value={selectedType} type="hidden" name="kind" ref={register} />
              <div onClick={() => setSelectedType('points')}  className={`bold blue center card pointer flex-1 margin-right-1 ${selectedType === "points" ? 'selected' :''}`}>
                Most Points
              </div>
              <div onClick={() => setSelectedType('miles')}  className={`bold blue center card pointer flex-1 margin-right-1 ${selectedType === "miles" ? 'selected' :''}`}>
                Most Miles
              </div>
              <div onClick={() => setSelectedType('money')} className={`bold blue center card pointer flex-1 margin-right-1 ${selectedType === "money" ? 'selected' :''}`}>
                Most Raised
              </div>
            </div>



            <h3 className="margin-top-2">Prize for Winner</h3>

            <div className="flex-cont">
              <label htmlFor="">
                <span className="label-text"></span>
                <input ref={register}  type="text" name="prize" id=""/>
                {errors.prize && <div className="field-error">{errors.prize.message}</div>}
              </label>
            </div>


            <h3 className="margin-top-2">Participating Teams</h3>


            

            {teamsLoading &&
              <div className="spinner"></div>
            }
            <table className="basic">
              <tr>
                <th>
                  <div onClick={toggleAll} className={`${allSelected() ? 'checked' : ''} check-box`}>
                    <div className="check">
                      {/* <i className="fas fa-check"></i> */}
                    </div>
                  </div>
                </th>
                <th>
                  Select All
                </th>
              </tr>
              {teams && teams.map((team, index) => {
                return (
                  <tr>
                    <td className="fit">
                      <input ref={register} value={team.id} type="hidden" name={`competition_teams_attributes[${index}].team_id`} />
                      <input ref={register} value={teamSelected(team.id) ? 0 : 1} type="hidden" name={`competition_teams_attributes[${index}]._destroy`} />
                      <div onClick={() => checkBoxClicked(team.id)} className={`${teamSelected(team.id) ? 'checked' : ''} check-box`}>
                        <div className="check">
                          {/* <i className="fas fa-check"></i> */}
                        </div>
                      </div>
                    </td>
                    <td>{team.name}</td>
                  </tr>
                )
              })}
            </table>
            

            
        </div>

        <div className="flex-cont margin-top-3 margin-bottom-3">
          <div className="flex-1"></div>

          {loading &&
            <div className="spinner margin-right-1"></div>
          }

          <button type="submit" className={`${buttonDisabled() ? 'disabled' : ''} button`}>
            Create Competition
          </button>
        </div>

        </form>
        


      </div>
     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingCompetitions);