import { useState, useEffect } from "react";
import store from './redux/store';
import { NavLink, Link, Outlet, useNavigate } from 'react-router-dom';
import { addError } from './redux/ui/uiActions';

export const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3021" : "https://hero-run-api.onrender.com";

export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;


const queryString = require('query-string');

export const useApi = (skip=false) => {



  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [refreshIndex, setRefreshIndex] = useState(0);
  const [apiFunction, setApiFunction] = useState(null);
  const [query, setQuery] = useState(null);
  const [payload, setPayload] = useState(null);
  const [params, setParams] = useState({});

  const [pagy, setPagy] = useState(null);



  const doFetch = (f, p={}) => {
    setApiFunction(() => f);
    setParams(p);
    setRefreshIndex(refreshIndex + 1);
  };

  const ttt = async (cancelled) => {

    setLoading(!loading);

    try {

      setLoading(true);


      console.log("R 0a");

      const response = await store.dispatch(apiFunction(params));

      console.log("R 0b");
      
      console.log("Response 10", response);


      // if (!response) {
      //   // throw new Error("There was an error.")
      //   // setLoading(false);
      //   // setError(e);
      //   return false;
      // }

      if (!response || (response && response.error)) {
        setLoading(false);
        setError(response.error);

        // alert(JSON.stringify(response.error));
        setLoaded(false);


      }
      else {

        if (!cancelled) {

          

          // setResult(response);

          if (response.json) {
            // alert(JSON.stringify(response.json, null, 2));
            setResult(response.json.data);
          }
          else {
            setResult(response);
          }


          if (response.fullResponse) {
          

            const fullResponse = response.fullResponse;

            if (fullResponse.headers.get('Current-Page')) {
              setPagy({
                currentPage: parseInt(fullResponse.headers.get('Current-Page')),
                pageItems: parseInt(fullResponse.headers.get('Page-Items')),
                totalCount: parseInt(fullResponse.headers.get('Total-Count')),
                totalPages: parseInt(fullResponse.headers.get('Total-Pages')),
              })

              
            }
          }

          if (response.signal) {
            alert('signalled')
          }


          setLoading(false);
          setLoaded(true);
        }
      }



      


      // if (ok) {
      //   alert('here');
      //   if (!cancelled) {

          
      //     setResult(response);
      //     setLoading(false);
      //     setLoaded(true);
      //   }
      // } else {
      //   const e = await response.json();
      //   console.log(`doFetch throw`, response);
        
      //   throw e.message;
      // }


      
    }
    catch (e) {
      
      setLoading(false);
      setError(e);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    setLoaded(false);

    let cancelled = false;
    if (!apiFunction || skip) {
      setResult(null);
      setLoading(false);
      
    } 
    else {
      ttt(cancelled);
    } 
    
    return () => {
      
      cancelled = true;
    };
  }, [refreshIndex]);


  return [result, loading, loaded, error, doFetch, setResult, pagy];
}

// export const buildFetch = (url, {query=null, payload=null}, method="GET") => {
//   const fullUrl = `${API_URL}/${url}.json?${queryString.stringify(query)}`;

//   const controller = new AbortController();
//   const signal = controller.signal;
//   // alert(JSON.stringify(query));

//   const token = store.getState().sessionState.authToken;
//   const currentAthleteID = store.getState().sessionState.currentAthlete?.id || "";

//   let options = {
//     signal: signal,
//     method: method, 
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authorization': token,
//       'X-CURRENT-ATHLETE-ID': currentAthleteID
//     }
//   };

//   if (payload && method !== 'GET') {
//     options.body = JSON.stringify(payload);
//   }

// }

export const andyFetch = async (url, {controller=null, query=null, payload=null}, method="GET") => {
  const fullUrl = `${API_URL}/${url}.json?${queryString.stringify(query)}`;

  // const controller = new AbortController();
  const signal = controller?.signal;
  // alert(JSON.stringify(query));

  const token = store.getState().sessionState.authToken;
  const currentAthleteID = store.getState().sessionState.currentAthlete?.id || "";

  let options = {
    signal: signal,
    method: method, 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token,
      'X-CURRENT-ATHLETE-ID': currentAthleteID
    }
  };

  if (payload && method !== 'GET') {
    options.body = JSON.stringify(payload);
  }

  let response;

  try {
    response = await fetch(fullUrl, options);
    console.log(`Response 20`, response);

    if (response.ok) {
      // alert('here');
      return response;
    } else {
      console.log('here')
      const e = await response.json();
      console.log(`throw`, response);
      console.log(`throw 2`, e);

      if (e.status === 401) {

      }
      throw e.message;
    }
  }
  catch (e) {
    let msg = e;

    if (e?.name == "AbortError") return;
    
    if (!msg) {
      msg = "There was an error. Please try again."
    }

    msg = msg.toString();

    console.log(e);


    store.dispatch(addError(msg));

    return response;
  }
};