

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getRaces } from 'redux/club/raceActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { API_URL } from '../../api.js';
import Modal from 'components/Modal';

import { createRace, updateRace, deleteRace, getRealLaps, addLap } from 'redux/club/raceActions';
import RaceTime from 'components/RaceTime';
import store from 'redux/store';
import { toggleStartRace } from 'redux/ui/uiActions';
import { connect, Provider } from 'react-redux';

import { Link, Outlet, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})





const RaceSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  avatar: yup.string(),

});



const LapRow = ({lap}) => {

  const [start, setStart] = useState(false);



  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 50);
  }, []);

  return (
    <div className={`flex-cont lap-test ${start ? 'start' : ''}`}>
      <div className="flex-1 bold margin-right-half">
        {lap.epc}
      </div>
      <div className="flex-1">
        {lap.time}
      </div>
      <div className="flex-1x">
        {lap.miles} mi.
      </div>
    
    </div>
  )
}



const ClubTagDemo = ({raceStarted, raceStartedAt, raceType, existingLaps}) => {

  const [modalRaceType, setModalRaceType] = useState(null);
  const [raceTypeModalOpened, setRaceTypeModalOpened] = useState(false);

  const [laps, loading, loaded, error, doFetch, setResult] = useApi(); 

  const [lapQueue, setLapQueue] = useState([]);

  const [fetchInterval, setFetchInterval] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    startClicked();
    fetchLaps();


    // const interval = setInterval(fetchLaps, 1000);
    // setFetchInterval(interval);

    return () => {

      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
      
    }
  }, []);

  const fetchLaps = () => {
    doFetch(getRealLaps);
  }

  useEffect(() => {

    if (laps) {
      setLapQueue(o => [...o, ...laps]);
    }

    
  }, [laps]);

  useEffect(() => {

    if (lapQueue && lapQueue.length > 0) {

      lapQueue.forEach((lap, index) => {

        setTimeout(() => {
          store.dispatch(addLap(lap));
        }, index * 200);

        
      })

      setLapQueue([]);
      
    }

    
  }, [lapQueue]);



  useEffect(() => {
    if (loaded) {
      console.log('loaded');
      console.log(laps);  
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }

      if (true) {
        // currentClub?.reader_is_on
        setFetchInterval(setInterval(fetchLaps, 1000));
      }
  
    }
    
  }, [loaded]);


  const toggleModal = (type=null) => {
    setModalRaceType(type);
    setRaceTypeModalOpened(o => !o);
  }

  const startClicked = () => {
    store.dispatch(toggleStartRace());
  }

  const [real, setReal] = useState(false);

  const realTagReadClicked = () => {
    toggleModal("Tag Read Demo");
    setReal(true);
  }

  const stopClicked = () => {
    store.dispatch(toggleStartRace());
    navigate('/live-run');
  }



  return (
    <div className="narrow-2 padding-top-1">


      {raceTypeModalOpened &&
        <Modal toggleModal={toggleModal} focusTrap={false}>
          <div>
              <div className="modal-header flex-cont align-center">
              <h2 className="flex-1">{modalRaceType}</h2>
              <div onClick={toggleModal} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

            <div className="padding-1">
              <div className="margin-top-1">
                {!raceStarted &&
                  <div className="button" onClick={startClicked}>
                    START 
                    <span className="margin-left-05 uppercase"> {modalRaceType}</span>
                  </div>
                }
                {raceStarted &&
                  <div className="button" onClick={startClicked}>
                    STOP
                    <span className="margin-left-05 uppercase"> {modalRaceType}</span>
                  </div>
                }
              </div>

            </div>
          </div>
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1 className="flex-cont align-center">
            <div className={`${raceStarted ? 'blue' : 'standby'} pulser font-2  margin-right-1`}></div>
            Tag Read Demo

            
            
          </h1>
        </div>
      </div>

      <div>
        <div className="flex-cont align-center margin-bottom-3">
          <i className="margin-right-1 blue font-1-5 fas fa-check-circle"></i>
          <div>Connected to <span className="bold">Eagle Hero Base</span></div>
        </div>
      </div>

      {raceStarted &&
        <>

        <div className="bold gray font-1-5 uppercase">{raceType}</div>
        <RaceTime width={3} includeH={true} includeMS={true} color={{color: 'black'}} />

        <div className="margin-top-1 margin-bottom-2">
          {!raceStarted &&
            <div className="button" onClick={() => store.dispatch(toggleStartRace())}>
              START RACE
            </div>
          }
          {raceStarted &&
            <div className="button" onClick={() => stopClicked()}>
              STOP RACE
            </div>
          }
        </div>

        {/* <div>{JSON.stringify(lapQueue)}</div> */}

        <div className="really-narrowx">
          <div style={{padding: '1rem 2rem', background: 'rgba(0,0,0,0)', borderRadius: '1rem', height: '600px', overflowX: 'visible', overflowY: 'auto'}} className="cardx full">
            {existingLaps && existingLaps.length > 0 && existingLaps.map(lap => {
              return (
                <div style={{marginBottom: '.25em', paddingBottom: '.25em', borderBottom: '1px solid rgba(0,0,0,.05)'}} key={lap.test_id}>
                  <LapRow  lap={lap} />
                </div>
              )
            })}

          </div>
        </div>

        

        

        
  

        
        </>
      }
      {!raceStarted &&
        <>
          <div className="font-1 margin-top-0 margin-bottom-1">
            Select a run type:
          </div>

          <div className="flex-cont">
            <div onClick={() => toggleModal("Timed Mile")} className="pointer flex-1x">
              <div className="card margin-right-1 center">
                <i className="fas fa-stopwatch blue font-3"></i>
                <div className="margin-top-1"></div>
                <div className="bold font-1">Timed Mile</div>
              </div>
            </div>
            
            <div onClick={() => toggleModal("General Laps")} className="pointer flex-1x">
              <div className="card margin-left-1 center">
              <i className="fas fa-circle-notch blue font-3"></i>
              <div className="margin-top-1"></div>
              <div className="bold font-1 ">General Laps</div>
              </div>
            </div>

            <div onClick={() => toggleModal("Themed Run")} className="pointer flex-1x">
              <div className="card margin-left-1 center">
              <i className="fas fa-flag blue font-3"></i>
              <div className="margin-top-1"></div>
              <div className="bold font-1 ">Themed Run</div>
              </div>
            </div>

            <div onClick={realTagReadClicked} className="pointer flex-1x">
              <div className="card margin-left-1 center">
              <i className="fas fa-tags blue font-3"></i>
              <div className="margin-top-1"></div>
              <div className="bold font-1 ">Tag Read Demo</div>
              </div>
            </div>
          </div>
          
        </>
      
      }

      


      

  

      

      

      
      

      {/* <div>
        {JSON.stringify(raceStarted)}
      </div>
      <div>
        {JSON.stringify(raceStartedAt)}
      </div> */}
    </div>
  )
}


function mapState(state) {
  return { 
    raceStarted: state.uiState.raceStarted,
    raceStartedAt: state.uiState.raceStartedAt,
    raceType: state.uiState.raceType,
    existingLaps: state.raceState.existingLaps,
  };
} 

export default connect(mapState)(ClubTagDemo);
