import { andyFetch } from '../../api';

export const getSchoolYearFees = (params) => {
  return async (dispatch, getState) => {

    const url = "club/school_year_fees";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSchoolYearFees error", e);
    }
  }
};