import React from 'react';
import { Duration } from 'luxon';

const TimedMileTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <div className="">{`${label}`}</div>
        <div className="bold margin-top-half">{Duration.fromMillis(payload[0].value*1000.00).toFormat('mm:ss')}</div>
      </div>
    );
  }

  return null;
};

export default TimedMileTooltip;