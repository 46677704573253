import React, { useEffect, useState } from 'react';
import Coin from './Coin';
import CountUp from '../../../components/CountUp';
import coinSVG from 'images/coin.svg';


const LapNoti = ({noti, hideNoti, hideAllNotis}) => {

  const [started, setStarted] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setStarted(true);
    }, 500)


    return(() => {
      timeout = null;
    })
  }, [])


  return (
    <div className="noti-content">
      <div className="padding-1">
        <div className="noti-inner-content">
          <div className="flex-cont align-center justify-center">
            <div className="flex-cont column align-center">
              {/* <div className="starburst-cont">
                <div className="starburst"></div>
                <div className="feather"></div>
              </div> */}

              {noti.award_athlete?.award?.badge?.image_url &&
                <img className='badge-coin' src={noti.award_athlete?.award?.badge?.image_url} alt="" />
              }
              {!noti.award_athlete?.award?.badge?.image_url &&
                <Coin />
              }

              

              <div className="center noti-body">
                {/* <pre>
                  {JSON.stringify(noti, null, 2)}
                </pre> */}

                
                <div className="font-2 bold blue noti-title margin-bottom-1">
                  {/* Daily Login */}
                  {noti.title}
                </div>

                {/* <div className="boldx gray margin-bottom-3 margin-top-1">
                  {noti.description}
                </div> */}

                <div className={`coins-added ${started ? 'started' : ''}`} >
                  <div className="margin-bottom-1 inline-block bold font-1 margin-right-1 gold" style={{
                      borderRadius: '99999px',
                      background: '#f6b904',
                      color: 'white',
                      padding: '.25em 1em'
                    }}>
                      +{noti.coins_after - noti.coins_before}
                  </div>
                </div>

                
                  
                
                <div style={{
                  // borderRadius: '99999px',
                  // background: 'gold',
                  // color: 'white'
                }} className="inline-flex bold font-2 gold flex-contx align-center justify-center margin-right-4x">
                  <div className="flex-1 flex-cont" style={{justifyContent: 'flex-end'}}>
                    <img src={coinSVG} style={{justifySelf: 'flex-end', height: '.75em'}} className="margin-right-half" />
                  </div>

                  

                  <div className="flex-1 flex-cont" style={{justifyContent: 'flex-start'}}>
                    <CountUp decimalPlaces={0} delay={500} startNumber={noti.coins_before} number={noti.coins_after} />
                  </div>
                  
                </div>

                <div style={{background: 'limex'}}>

                  <table className="margin-top-3 noti-award-list">
                    {noti.award_athlete_group?.award_athletes?.map(awardAthlete => 
                      <tr>
                        <td className="fitx nowrap gold bold font-1 text-right">

                          <div className="flex-cont align-center">
                            <img src={coinSVG} style={{height: '.7rem'}} className="margin-right-halfx" />

                            <div>
                              {awardAthlete.coins_earned}
                            </div>
                          </div>
                          
                        </td>
                        <td>
                          <div className="bold">{awardAthlete.award?.name}</div>
                          {awardAthlete.award?.description &&
                            <div className='gray font-09'>{awardAthlete.award?.description}</div>
                          }

                        </td>
                        <td className="fit nowrap">
                          {awardAthlete.badge &&
                            <img src={awardAthlete.badge?.image_url} style={{height: '50px'}} />
                          }
                        </td>
                      </tr>
                      
                    )}

                  </table>
                  
                </div>

                <div className="bottom-buttons-cont">
                  <div className="noti-button">
                    <div onClick={hideNoti} className="font-1-5 wide button">Okay!</div>
                  </div>

                  <div className="dismiss-all">
                    <div onClick={hideAllNotis} className="margint-top- 1font-1-25 wide ghost white button">Dismiss all</div>
                  </div>
                </div>

                


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LapNoti;