

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getBadges } from 'redux/admin/badgeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../api.js';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput } from '@uppy/react'
import { createBadge, updateBadge, deleteBadge } from 'redux/admin/badgeActions';

const BadgeSchema = yup.object().shape({
  

});


const BadgeForm = ({toggleModal, currentBadge}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: BadgeSchema
  });

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);
  const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [title, setTitle] = useState("");


  useEffect(() => {
    const u = Uppy({
      meta: { type: 'image' },
      autoProceed: true
    })
    u.use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
    setUppy(u);
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy]);
  


  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [badgesResult, badgesLoading, badgesLoaded, badgesError, doFetchBadges, setBadgeResults] = useApi();

  const onSubmit = async data => {
    let d = {
      badge: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentBadge && currentBadge.id) {
      doFetch(updateBadge, {payload: d});
    }
    else {
      doFetch(createBadge, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const finalUrl = fileURL ? fileURL : currentBadge?.image_url

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const deleteClicked = () => {
    toggleDeleteModal();
  }
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteBadge, {query: {id: currentBadge.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
    
  }, [deleteLoaded])

  return (
    <>
    {deleteModalOpened &&
      <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
        <div className="padding-1">
          Are you sure you want to delete this badge?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className="button red">
            Delete
          </div>
      </div>
      </Modal>
    }
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentBadge ? 'Edit' : 'New'} Inspiration</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentBadge?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              onChange={e => setTitle(e.target.value)}
              defaultValue={currentBadge?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        {/* <EditorContent editor={editor} /> */}

        <div className="flex-cont">
          <label htmlFor="description"> 
            <textarea 
              ref={register}
              placeholder="Description"
              name="description"
              defaultValue={currentBadge?.description}
            />
            {errors.description && <div className="field-error">{errors.description.message}</div>}
          </label>
        </div>

        {/* <div className="card">
        <img src={currentBadge?.image_url} alt="" />
        
        <br />

        <img src={currentBadge?.thumbnail_url} alt="" />
        </div> */}

        <div className="flex-cont">
          {uppy && 
            <>
              <FileInput uppy={uppy} />
              <StatusBar uppy={uppy} />
            </>
          }
        </div>

        <div className="margin-1 card inline-block">
          <img style={{width: '75px'}} src={finalUrl} alt="" />
          <input ref={register} type="hidden" name="image" value={file} />
        </div>
        
      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        <div className="flex-1"></div>

        {currentBadge &&
          <div onClick={deleteClicked} className="button red">
            Delete
          </div>
        }
      </div>

    </form>
    </>
  )

}




const AdminBadges = ({currentUser}) => {

  const [badges, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedBadge, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedBadge] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingBadge, setEditingBadge] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchBadges();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingBadge(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!badges) {
      fetchBadges();
    }
  }, []);

  const fetchBadges = () => {
    doFetch(getBadges);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingBadge, setDeletingBadge] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchBadges();
    }
  }


  const editClicked = badge => {
    setEditingBadge(badge);
    toggleModal();
  }

  const deleteClicked = badge => {
    setDeletingBadge(badge);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingBadge) {
      doDeleteFetch(deleteBadge, {query: {id: deletingBadge.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <BadgeForm currentBadge={editingBadge} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Badges</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Badge</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {badges &&
        <table className="basic">
          {badges.map(badge => {
            return (
              <tr>
                <td className="fit">
                  <img style={{width: '50px'}} src={badge.thumbnail_url} alt="" />
                  
                </td>
                <td className="bold fit nowrap">{badge.name}</td>
                <td>
                  {badge.description}
                </td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(badge)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(badge)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Badge</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingBadge?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !badges &&
        <em>No badges.</em>
      }


      


    </>
  )
}


export default AdminBadges;