


import React, { useEffect, useState } from 'react';
import { getCurrentPledgeDrive } from '../../../redux/club/pledgeDriveActions';
import { useApi } from '../../../api';
import { Link } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import { AreaChart, ComposedChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {getMileTimes} from '../../../redux/club/statsActions';
import TimedMileTooltip from './TimedMileTooltip';

 


const Chart = ({mileData}) => {
  const demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  if (!mileData) return <></>;

  return (
    <>

      
      
      
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={mileData}
          margin={{ 
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#0075B3" stopOpacity={0.5}/>
              <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0.1}/>
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} stroke="#DDD" />
          <XAxis dataKey="name" />
          <YAxis domain={[300, 'auto']} tickFormatter={value => Duration.fromMillis(value*1000.00).toFormat('mm:ss')} />
          <Tooltip 
            content={<TimedMileTooltip />}
          />  
          <Area 
            // isAnimationActive={false} 
            type="monotone" 
            dataKey="time" 
            stroke={false} 
            fillOpacity={1} 
            fill="url(#colorUv)" />
          <Line 
            type="monotone" 
            dataKey="time" 
            strokeWidth={6} 
            stroke="#0075B3" 
            dot={false} 
            legendType="none"
            activeDot={{ r: 8 }} 
            strokeLinecap="round"
            // isAnimationActive={false}
          />
          
        </ComposedChart>
        
      </ResponsiveContainer>

    </>
  );

}

export default Chart;