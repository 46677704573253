

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useApi } from '../api';
import { confirmEmail, resendConfirmEmail } from 'redux/user/userActions';
import store from 'redux/store';
import { connect } from 'react-redux';



const queryString = require('query-string');


const EmailConfirmation = ({currentUser}) => {
  const navigate = useNavigate("/");
  let location = useLocation();
  const params = queryString.parse(location.search);

  const [user, loading, loaded, error, doFetch, setResult] = useApi(); 
  

  useEffect(() => {

    if (!loaded && !loading && params && params.token && params.email) {
      doConfirmEmail();
    }

  }, []);

  useEffect(() => {
    if (loaded) {

      if (currentUser) {
        navigate(`/profile`);
      }
      else {
        navigate('/');
      }

      
    }
  }, [loaded]);


  const [resendResult, resendLoading, resendLoaded, resendError, doResendFetch, setResendResult] = useApi(); 
  const [resent, setResent] = useState(false);

  const resendClicked = () => {
    doResendFetch(resendConfirmEmail, {payload: {email: params.email}});
  }

  useEffect(() => {
    if (resendLoaded) {
      setResent(true);
    }
  }, [resendLoaded]);


  const doConfirmEmail = async () => {
    doFetch(confirmEmail, {payload: {email: params.email, token: params.token}});
  };

  useEffect(() => {
    if (loaded || resendLoaded) {
      setResent(false);
    }
  }, [loaded, resendLoaded]);


  return (
    <div className="narrow-2">
      <h1>Email Confirmation</h1>

      {(loading || resendLoading) &&
        <div className="spinner"></div>
      }



      {resendLoaded &&

        <>
          <div className="margin-bottom-1">Another confimration email has been sent to your email address.</div>
        </>

      }
      {!resendLoaded && (error || resendError) &&
        <>
          <div className="margin-bottom-1">There was an error confirming your email address. Please try again by clicking RESEND LINK below.</div>

          <div onClick={resendClicked} className="button">RESEND LINK</div>
        </>
      
      }
      

    
    </div>
    
  )
}


function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(EmailConfirmation);