import React, { useEffect, useState } from 'react'
import { getCoinTransactions } from 'redux/athlete/athleteActions';
import { connect } from 'react-redux';
import { useApi } from '../../api';
import coinSVG from 'images/coin.svg';
import { DateTime } from 'luxon';
import Pagination from 'components/Pagination';

const ProfileCoins = ({currentAthlete}) => {

  const [coinTransactions, coinTransactionsLoading, coinTransactionsLoaded, coinTransactionsError, doCoinTransactionsFetch, setCoinTransactionsResult, pagy] = useApi(); 

  const [page, setPage] = useState(1);


  const fetchCoinTransactions = () => {
    doCoinTransactionsFetch(getCoinTransactions, {query: {page: page}});
  }

  useEffect(() => {
    fetchCoinTransactions();
  }, [page]);

  const handlePageChange = (p) => {
    setPage(p);
  };



  return (
    <>
      <h1 className='mobile-center'>Hero Coins</h1>

      

      <div className="card no-hover">
        <div className="flex-cont align-center bold font-1-25 blue margin-bottom-2">
          <i className="fas fa-coins margin-right-half"></i>
          History
        </div>

        <div>
          <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        </div>

        <div className="overflow-scroll">
          <table className="basic">
            {coinTransactions?.map(coinTransaction => {
              return (
                <tr>
                  <td className="fit nowrap">

                    <div className="flex-cont align-center">
                      <img style={{width: '20px'}} src={coinSVG} alt="" />

                      {coinTransaction.coins_earned < 0 &&
                        <div className="margin-left-half red-text bold font-1-25">
                          -{coinTransaction.coins_earned}
                        </div>
                      }
                      {coinTransaction.coins_earned >= 0 &&
                        <div className="margin-left-half blue bold font-1-25">
                          +{coinTransaction.coins_earned}
                        </div>
                      }
                      
                    </div>
                    
                  </td>
                  <td className='full'>
                    {coinTransaction.description}
                  </td>
                  <td className="fit nowrap">
                    {DateTime.fromISO(coinTransaction.created_at).toLocaleString(DateTime.DATE_MED)}
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
      </div>

      {/* <div>
        <pre>
          {JSON.stringify(coinTransactions, null, 2)}
        </pre>
      </div> */}
    </>
  );
}
 
const mapState = (state) => {
  return { 
    currentAthlete: state.sessionState.currentAthlete,
  };
} 

export default connect(mapState)(ProfileCoins);