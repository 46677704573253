


import React, { useEffect, useState } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../../api';

import { createPledge, getPledgePage } from 'redux/user/pledgeActions';
import { getPledgePagePreview } from '../../redux/club/pledgeActions'

import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PledgeLayout = ({children, isPreview=false}) => {


  let { id, code, pledge_code } = useParams();

  let query = useQuery();
  let queryPledgeCode = query.get('pledge_code');

  const [pledgePage, pledgePageLoading, pledgePageLoaded, pledgePageError, doPledgePageFetch, setPledgePageResult] = useApi(); 

  useEffect(() => {

    fetchPledgePage();
    
  }, []);

  const fetchPledgePage = () => {
    if (true) {

      if (isPreview) {
        doPledgePageFetch(getPledgePagePreview, {query: {pledge_drive_id: id}});
      }
      else {


        doPledgePageFetch(getPledgePage, {query: {pledge_code: queryPledgeCode ? queryPledgeCode : pledge_code, code: code}});
      }
      

    }
    
  }


  return (
    <>
      {/* <div className="banner-slantrthgegr">
        <div style={{backgroundImage: `url("https://fyzeak.s3-us-west-1.amazonaws.com/120158874_3537678446289436_7216831924879481392_o.jpg")`, backgroundPosition: 'center', height:  '300px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} className="banner-cont"></div>
      </div> */}
{/* 
      <Outlet pledgePage={pledgePage} /> */}
{/* 
      <pre>
        pledgePage: {JSON.stringify(pledgePage, null, 2)}
      </pre>

      <pre>
        children: {JSON.stringify(Object.keys(children), null, 2)}
      </pre> */}

      {/* <pre>
        pledgePage: {JSON.stringify(pledgePage, null, 2)}
      </pre>     */}
{/* is_accepting_pledges */}

      {pledgePageLoading &&
        <div className="center margin-top-3">
          <div className="spinner"></div>
        </div>
      }
      {!pledgePageLoading && pledgePage &&
        <>
          {pledgePage.is_accepting_pledges &&
            <Outlet  context={[pledgePage]} />
          }
          {!pledgePage.is_accepting_pledges &&
            <PledgeNotReady pledgePage={pledgePage} />
          }
        </>
      }

      
    </>
  )
}

const PledgeNotReady = ({pledgePage}) => {

  return (
    <div className="parent" style={{
      backgroundColor: 'white',
      paddingBottom: '500px',
      paddingTop: '50px',
      position: 'relative',
    }}>


      <div style={{
        // background: 'linear-gradient(180deg, rgba(0,117,179,1) 0%, rgba(11,179,207,1) 100%)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '200px',
      }}></div>

      <div className="narrow relative">
        <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
          <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
            <div className="blue font-3 bold-200 margin-bottom-3">
              {pledgePage.club_name} is not accepting pledges yet. Please check back soon!
            </div>
          </div>
          <PledgeSidebar showButton={false} pledgePage={pledgePage} isPreview={false} />
        </div>
      </div>

    </div>
  )
}

export default PledgeLayout;