

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const ClubCoinItemsNav = () => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/coin-items`}>Coin Items</NavLink></li>
        {/* <li><NavLink end to={`/coin-items/my-orders`}>My Orders</NavLink></li>
        <li><NavLink end to={`/coin-items/my-products`}>My Products</NavLink></li> */}
      </ul>
    </div>
  )
}

export default ClubCoinItemsNav;  