import React, {useEffect, useState} from 'react';
import { useApi } from '../../api';
import { getSponsorStats, getTeamStats } from 'redux/athlete/athleteActions';


import { useVisibility } from 'components/useVisibility';



const Sponsor = ({pledge, index}) => {
  const { currentElement, isVisible } = useVisibility(-200);
  const [startCount, setStartCount] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (isVisible && !startCount) {
      setStartCount(true);
    } 
  }, [startCount, isVisible])


  const [pp, setPp] = useState(0);


  useEffect(() => {
    if (startCount) {
      setTimeout(() => { 
        setStartAnimation(true);
      }, (250*(index+1)));
    } 
  }, [startCount]);

  return (
    <div ref={currentElement} className={`sponsor-box2-cont ${startAnimation ? 'is-visible' : ''}`}>
      <div className="sponsor-box2">
        <i className="fas fa-user"></i>
      </div>
      <div className="dollars">
        {(pledge.pledger_max_cents/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </div>
      <div className="name">{pledge.pledger_name}</div>
    </div>
  )
}

const TeamBox = ({}) => {

  const [stats, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    fetchTeamStats();
  }, []);

  const fetchTeamStats = async () => {
    doFetch(getTeamStats);
  };

  return (
    <div className="no-click no-hover flex-column card grid-item span-1-v span-4-h box">
      

      {!!!stats &&
        <>
          <div className="number-label margin-bottom-1">TEAM STATS</div>
          <div className="number">-</div>
        </>
        
      }
      {stats && 
        <>
          <div className="number-label margin-bottom-1">{stats?.team_name}</div>

          <div>
            {stats?.athletes?.map((athlete, i) => {
              return (
                <div style={{
                  borderRadius: '50%',
                  background: 'rgba(0,0,0,.1)',
                  height: '2em',
                  width: '2em',
                  margin: '.25em',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'

                }}>
                  <div className="bold font-075">
                    {athlete.first_name.slice(0, 1)}{athlete.last_name.slice(0, 1)}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      }      

    </div>
  )
}

export default TeamBox;




