import { andyFetch } from '../../api';

export const getSubscriptions = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/subscriptions";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSubscriptions error", e);
    }
  }
};


export const createSubscription = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/subscriptions";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createSubscription error.');
      }
    }
    catch (e) {
      console.log("createSubscription error", e);
    }
  }
};


export const updateSubscription = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/subscriptions/${params.payload.subscription.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateSubscription error.');
      }
    }
    catch (e) {
      console.log("updateSubscription error", e);
    }
  }
};


export const deleteSubscription = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/subscriptions/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteSubscription error.');
      }
    }
    catch (e) {
      console.log("deleteSubscription error", e);
    }
  }
};

