import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getAthletes } from 'redux/club/athleteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';
import { changeBand } from 'redux/club/athleteActions';
import { getBands } from '../../../redux/club/bandActions';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";
import AutoComplete from '../../../components/AutoComplete';

const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div className='flex-1'>{item.code}</div>
      <div className="uppercase margin-left-1 margin-right-1 bold">{item.status}</div>
    </div>
  )
}

const ChangeBandForm = ({toggleModal, currentAthlete}) => {

  let navigate = useNavigate();

  const { setValue, watch, register, errors, handleSubmit, getValues } = useForm({

  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [bands, bandsLoading, bandsLoaded, bandsError, doBandsFetch, setBandsResult] = useApi(); 

  useEffect(() => {
    fetchBands();
  }, []);

  const fetchBands = () => {
    doBandsFetch(getBands);
  }
  

  const onSubmit = async data => {
    const d = {
      ...data
    };

    doFetch(changeBand, {payload: d, query: {athlete_id: currentAthlete.id}});
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const renderRow = item => <Row item={item} />



  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input ref={register()} type="hidden" name="athlete_id" value={currentAthlete.id} />
        <div className="modal-header flex-cont align-center">
          <h1 className="flex-1">{currentAthlete.full_name} - Change Band</h1>

          <div onClick={() => toggleModal(false)} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="padding-1">

        <label className="margin-bottom-2">
            <span className="margin-right-half">
              Current Band:
            </span>
            <div className="">
              {currentAthlete?.band?.code ? (
                  <>{currentAthlete?.band?.code}</>
                ) : (
                  <em>No band assigned.</em>
                )
              }
              
            </div>
          </label>
          
          <AutoComplete 
            setValue={setValue}
            defaultValue={currentAthlete?.band?.code}
            defaultSearch={currentAthlete?.band?.code}
            name={"code"}
            items={bands}
            fullName={"FULL NAME"}
            register={register}
            errors={errors}
            label="Select Band"
            labelFn={(item) => `${item.code}`}
            labelComponent={renderRow}
            valueFn={(item) => item.code }
            searchableAttributes={["code"]}
          />
          
          {/* <label>
            {bandsLoading && <div className="spinner"></div>}
            {bands &&
              <>
                <span>Select Band</span>
                <select name="band_id" ref={register({required: "Please select a band."})} defaultValue={currentAthlete.band_id}>
                  {bands?.map(band =>
                    <option value={band.code}>  
                      {band.code}
                    </option>
                  )}
                </select>
              </>
            }
          </label> */}
        </div>
        <div className="modal-footer flex-cont align-center">
          <button className="button">Save</button>
          <div className="flex-1"></div>
          {/* <div onClick={toggleRemoveModal} className="danger ghost button">Remove</div> */}
        </div>
      </form>


    </Modal>
  )

}

export default ChangeBandForm;