import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { createPledge, getPledgePage } from 'redux/user/pledgeActions';
import { getPledgePagePreview } from '../../../redux/club/pledgeActions';
import { useApi } from '../../../api';
import Bar from 'components/Bar';
import CountUp from 'components/CountUp';
import Modal from 'components/Modal';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { loadStripe } from "@stripe/stripe-js";
import { 
  Elements,
  CardElement,
  useStripe,
  useElements 
} from "@stripe/react-stripe-js";




const PledgeSidebar = ({pledgePage, isPreview=false, showButton=true}) => {

  const navigate = useNavigate();

  let { id, code } = useParams();




  return (

    <>

      {/* <pre>
        pledgePage: {JSON.stringify(pledgePage, null, 2)}
      </pre> */}

      {pledgePage && 
          <div className="flex-1 padding-1 rounded-box" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            alignSelf: 'flex-end',
          }}>

          <div className='margin-top-2' style={{
            borderRadius: '50%',
            overflow: 'hidden',
            width: '150px',
            height: '150px'
          }}>

            <img style={{
              width: '100%'
            }} src={pledgePage.club_logo} />
          </div>

          <div style={{lineHeight: 1}} className='center padding-top-1 bold-900 font-1-5'>

            {pledgePage.campaign_name}

          </div>

          <div className="center grayx bold section font-1 margin-top-half margin-bottom-2">
            {pledgePage.start_date} to {pledgePage.end_date}
          </div>

          

          {pledgePage?.title_sponsor && 
            <>
              <div className="margin-bottom-1 gray">PRESENTED BY</div>
              <div>

                <a target="_blank" href={pledgePage.title_sponsor.link_url}>
                  <img src={pledgePage.title_sponsor.image_url} style={{height: "100px"}} />
                </a>
                
              </div>
            </>
            
          }

          {/* <div className="center blue section font-1-25 margin-top-2 margin-bottom-2">
            Support <span className="bold">Andy Simon</span> and <span className="bold">{pledgePage.club_name}</span> by pledging today.
          </div> */}

          

          {showButton &&

            <div className="margin-top-3">
              <Link to={`donor-setup`} className={`${isPreview === true ? 'disabled' : ''} button font-1-5`}>
                Start Pledge
                <i className="font-1 fas fa-arrow-right margin-left-half"></i>
              </Link>
            </div>
          }

        </div>
      }
    </>

 
  )
}

export default PledgeSidebar;