import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getAthletes } from 'redux/club/athleteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';
import { changeTeam } from 'redux/club/athleteActions';
import { getTeams } from '../../../redux/club/teamActions';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";


const ChangeTeamForm = ({toggleModal, currentAthlete}) => {

  let navigate = useNavigate();

  const { watch, register, errors, handleSubmit, getValues } = useForm({

  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [teams, teamsLoading, teamsLoaded, teamsError, doTeamsFetch, setTeamsResult] = useApi(); 

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = () => {
    doTeamsFetch(getTeams);
  }
  

  const onSubmit = async data => {
    const d = {
      ...data
    };

    doFetch(changeTeam, {payload: d, query: {athlete_id: currentAthlete.id}});
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);



  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input ref={register()} type="hidden" name="athlete_id" value={currentAthlete.id} />
        <div className="modal-header flex-cont align-center">
          <h1 className="flex-1">{currentAthlete.full_name} - Change Team</h1>

          <div onClick={() => toggleModal(false)} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="padding-1">
          
          <label>
            {teamsLoading && <div className="spinner"></div>}
            {teams &&
              <>
                <span>Move to:</span>
                <select name="team_id" ref={register({required: "Please select a team."})} defaultValue={currentAthlete.team_id}>
                  {teams?.map(team =>
                    <option value={team.id}>  
                      {team.name}
                    </option>
                  )}
                </select>
              </>
            }
          </label>
        </div>
        <div className="modal-footer flex-cont align-center">
          <button className="button">Save</button>
          <div className="flex-1"></div>
          {/* <div onClick={toggleRemoveModal} className="danger ghost button">Remove</div> */}
        </div>
      </form>


    </Modal>
  )

}

export default ChangeTeamForm;


// <form onSubmit={handleSubmit(onSubmit)}>
{/* <div className="modal-header flex-cont align-center">
<h2 className="flex-1">Add Athlete</h2>
<div onClick={() => toggleModal(false)} className="ghost button close">
  <i className="fas fa-times"></i>
</div>
</div>

<div className="padding-1 padding-bottom-200px">

<input 
  type="hidden" 
  name="id" 
  ref={register}
  defaultValue={currentAthlete?.id}
/>

<div className="flex-cont">
  <label htmlFor="first_name"> 
    <input 
      type="text" 
      ref={register}
      placeholder="First Name"
      name="first_name"
      defaultValue={currentAthlete?.first_name}
    />
    {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
  </label>
  <label htmlFor="last_name"> 
    <input 
      type="text" 
      ref={register}
      placeholder="Last Name"
      name="last_name"
      defaultValue={currentAthlete?.last_name}
    />
    {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
  </label>
</div>

<div className="flex-cont">
  <label htmlFor="birthday"> 
    <DayPickerInput 
      className="full" 
      inputProps={{placeholder: "Birthday YYYY-MM-DD", name: `birthday`, ref: register}} 
      type="text"  
    />
    {errors.birthday && <div className="field-error">{errors.birthday.message}</div>}
  </label>
  <label htmlFor="gender"> 
    <label>
      <input type="radio" ref={register} name={`gender`}  value="female" defaultChecked={true}  />
      <span>Female</span>
    </label>
    <br />
    <label>
      <input type="radio" ref={register} name={`gender`} value="male"  />
      <span>Male</span>
    </label>
    {errors.gender && <div className="field-error">{errors.gender.message}</div>}
  </label>
</div>


<div className="flex-cont">
  <label htmlFor="name"> 
    <input 
      type="text" 
      ref={register}
      placeholder="Tag EPC"
      name="epc"
      defaultValue={currentAthlete?.epc}
    />
    {errors.epc && <div className="field-error">{errors.epc.message}</div>}
  </label>
</div>
</div>

<div className="modal-footer align-center flex-cont">
<input type="submit" value="Save" className="button" />
</div>

</form> */}