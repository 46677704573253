

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getAwardRuleLap } from 'redux/admin/awardRuleLapActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import { getClubOwnership, sendInvite, updateClubOwnership, destroyClubOwnership } from 'redux/club/clubOwnershipActions';
import ClubStaffNav from './ClubStaffNav';

import Modal from 'components/Modal';



export const ClubStaffView = () => {
  let { slug } = useParams();
  const navigate = useNavigate();

  const { watch, register, errors, handleSubmit, getValues, setValue, unregister } = useForm({
    // validationSchema: PaletteSchema
  });


  const [clubOwnership, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [inviteResult, inviteLoading, inviteLoaded, inviteError, doInviteFetch, setInviteResult] = useApi(); 


  useEffect(() => {
    fetchClubOwnerShip();
  }, [slug]);

  const fetchClubOwnerShip = () => {
    doFetch(getClubOwnership, {query: {id: slug}});
  }

  const inviteClicked = () => {
    doInviteFetch(sendInvite, {payload: {slug: slug, id: clubOwnership.id}})
  }

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [removeResult, removeLoading, removeLoaded, removeError, doRemoveFetch, setRemoveResult] = useApi(); 
  const [updateResult, updateLoading, updateLoaded, updateError, doUpdateFetch, setUpdateResult] = useApi(); 

  const toggleRemoveModal = () => {
    setRemoveModalOpen(o => !o)
  }

  const toggleEditModal = () => {
    setEditModalOpen(o => !o)
  }

  const confirmRemoveClicked = () => {
    doRemoveFetch(destroyClubOwnership, {payload: {id: clubOwnership.id}})
  }
  useEffect(() => {
    if (removeLoaded) {
      navigate('/staff')
    }
  }, [removeLoaded]);

  const handleUpdateSubmit = (data) => {
    // alert(JSON.stringify(data, null, 2))
    doUpdateFetch(updateClubOwnership, {payload: data})
  }
  useEffect(() => {
    if (updateLoaded) {
      fetchClubOwnerShip();
      toggleEditModal();
    }
  }, [updateLoaded]);

  const roles = {
    "School Admin": "club-admin",
    "Team Admin": "team-admin",
    "PE Teacher": "pe-teacher",
    "Run Monitor": "run-monitor"
  }

  return (
    <>


      


      {loading &&
        <div className="spinner"></div>
      }
      {clubOwnership &&
        <>

          {removeModalOpen &&
            <Modal focusTrap={false} toggleModal={toggleRemoveModal} size="small">

              <div className="padding-2">
                Are you sure you want to remove {clubOwnership.first_name} {clubOwnership.last_name} from your school?
              </div>
              
        
              <div className="modal-footer align-center flex-cont">
                <div onClick={toggleRemoveModal} disabled={removeLoading} className="button ghost">
                  Cancel
                </div>
                <div className="flex-1"></div>
                <div disabled={removeLoading} onClick={confirmRemoveClicked} className="button danger">
                  Remove
                  {removeLoading &&
                    <div className="spinner button-spinner"></div>
                  }
                </div>
              </div>
            </Modal>
          }

          {editModalOpen &&
            <Modal toggleModal={toggleEditModal} size="small">
              <form onSubmit={handleSubmit(handleUpdateSubmit)}>

                <input type="hidden" name="id" ref={register()} defaultValue={clubOwnership.id} />
                <div className='modal-header flex-cont align-center'>
                  <h1 className="flex-1">Edit Staff Member</h1>
                  <div onClick={toggleEditModal} className="ghost button close">
                    <i className="fas fa-times"></i>
                  </div>
                </div>

                <div className="padding-2">

                  <div className="margin-bottom-2">
                    <div className="bold">{clubOwnership.first_name} {clubOwnership.last_name}</div>
                    <div>{clubOwnership.email}</div>
                  </div>
                
                
                  <select defaultValue={clubOwnership.role} ref={register()} name="role" id="role">
                    {Object.entries(roles).map(([label, value]) => 
                      <option value={value}>{label}</option>
                    )}
                  </select>
                </div>
                
          
                <div className="modal-footer align-center flex-cont">
                  <button disabled={updateLoading} type="submit" className={`button`}>
                    Save
                    {updateLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </button>
                  <div className="flex-1"></div>
                  <div onClick={toggleEditModal} disabled={removeLoading} className="button ghost">
                    Cancel
                  </div>
                  
                  
                </div>
              </form>
            </Modal>
          }   

          <div className="flex-cont">
            <div className="flex-1">
              <h2 className="no-margin-top">{clubOwnership.first_name} {clubOwnership.last_name}</h2>
              <div>
                <div className="font-1 inline-block">
                  <div className={`role-cont ${clubOwnership.role}`}>
                    {clubOwnership.friendly_role}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div onClick={toggleEditModal} className="ghost button margin-right-half">Edit</div>
              <div onClick={toggleRemoveModal} className="ghost button">Remove</div>
            </div>
          </div>
          
         

          

          <div className="inline-block card no-hover margin-top-3">
            {clubOwnership.user_status === "invited" &&
              <>
                <div>
                  Invited {clubOwnership.invite_sent_at}
                </div>
                

                <div onClick={inviteClicked} className={`margin-top-2 button ${inviteLoading && 'disabled'}`}>
                  Send invite again

                  {inviteLoading &&
                    <div className="spinner button-spinner"></div>
                  }
                </div>
              </>
            }
            {clubOwnership.user_status === "created" &&
              <>
                <div>
                  Joined
                </div>

              </>
            }
          </div>
          



         

        </>
      }
      {/* <div className="code">
        <pre>
          {JSON.stringify(clubOwnership, null, 2)}
        </pre>
      </div> */}

    </>
  )
}

export default ClubStaffView;