import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import '../../styles/TipTap.scss';
import React from 'react';
import TipTapMenuBar from './TipTapMenuBar';
const Tiptap = ({editor}) => {


  return (
    <>
      <TipTapMenuBar editor={editor} />
      <EditorContent editor={editor} />
    </>
  )
}

export default Tiptap