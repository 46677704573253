import React, { useContext, useEffect, useState } from 'react';

import './styles/App.scss';
import { Link, Outlet, useLocation } from 'react-router-dom';
// import { useLocation } from 'react-router';

import store from './redux/store';



import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';


import ForgotPledge from './screens/pledge/ForgotPledge';
import FindPledge from './screens/pledge/FindPledge';
import EditPledge from './screens/pledge/EditPledge';
import PledgeProgress from './screens/pledge/PledgeProgress';
import SupportScreen from './screens/SupportScreen';
import HomeScreen from './screens/HomeScreen';
import Login from './screens/Login';
import Join from './screens/Join';
import ResendInvite, {InviteSent, InviteNew, InviteSuccess} from './screens/ResendInvite';
import PasswordReset, {PWSent, PWNew, PWSuccess} from './screens/PasswordReset';
import UserInvite from './screens/UserInvite';
import AthleteSetup from './screens/AthleteSetup';
import GetStarted from './screens/GetStarted';
import ProfileHome from './screens/profile/ProfileHome2';
import ProfileAwards from './screens/profile/ProfileAwards';
import ProfileBadges from './screens/profile/ProfileBadges';
import ProfileCoins from './screens/profile/ProfileCoins';
import ProfileLayout from './screens/profile/ProfileLayout2';
import ProfileActivity from './screens/profile/ProfileActivity';
import ProfileFundraising from './screens/profile/ProfileFundraising';
import ProfileClubs from './screens/profile/ProfileClubs';
import ProfileCareer from './screens/profile/ProfileCareer';
import ProfileMaterialFee from './screens/profile/ProfileMaterialFee';

import ParentLayout from './screens/parent/ParentLayout2';
import ParentHome from './screens/parent/ParentHome2';
import ParentActivity from './screens/parent/ParentActivity';
import ParentFundraising from './screens/parent/ParentFundraising';
import ParentClubs from './screens/parent/ParentClubs';
import ParentCareer from './screens/parent/ParentCareer';

import EmailConfirmation from './screens/EmailConfirmation';
import Footer from './Footer';
import UpgradeSetup from './screens/UpgradeSetup';

import NotFound from './NotFound';
import Notifications from './screens/Notifications';

import { connect } from 'react-redux';
import AuthorizedRoute from './AuthorizedRoute';
  
import { removeError } from './redux/ui/uiActions';

import ClubTagDemo from './screens/club/ClubTagDemo';
import ClubLayout from './screens/club/ClubLayout';
import ClubHome   from './screens/club/ClubHome';
import ClubLiveRun   from './screens/club/ClubLiveRun';
import ClubLaps  from './screens/club/ClubLaps';
import AdminTeams from './screens/admin/AdminTeams';
import AdminProducts from './screens/admin/AdminProducts';
import AdminSubscriptions from './screens/admin/AdminSubscriptions';
import AdminPayments from './screens/admin/AdminPayments';

import ClubPledgePreview from './screens/club/pledgeDrives/PledgePagePreview';
import ClubPage from './screens/ClubPage';

import Clubs from './screens/Clubs';


import TimerLayout from './screens/timer/TimerLayout';
import TimerHome   from './screens/timer/TimerHome';
import TimerRaces  from './screens/timer/TimerRaces';


import AdminLayout from './screens/admin/AdminLayout';
import AdminHome   from './screens/admin/AdminHome';
import AdminRaceFiles   from './screens/admin/AdminRaceFiles';
import AdminUsers   from './screens/admin/AdminUsers';
import AdminAthletes, { AdminAthlete }   from './screens/admin/AdminAthletes';
import AdminRaceLogs   from './screens/admin/AdminRaceLogs';
import AdminTracks   from './screens/admin/AdminTracks';
import AdminBands   from './screens/admin/AdminBands';
import AdminInspos from './screens/admin/AdminInspos';
import AdminBadges   from './screens/admin/AdminBadges';




import AdminMachines  from './screens/admin/AdminMachines';
import AdminClubs   from './screens/admin/AdminClubs';
import AdminRunThemes   from './screens/admin/AdminRunThemes';
import AdminClub  from './screens/admin/AdminClub';
import AdminRaces   from './screens/admin/AdminRaces';
import AdminPurchases   from './screens/admin/AdminPurchases';
import AdminCharities   from './screens/admin/AdminCharities';
import AdminPledgeDrives   from './screens/admin/AdminPledgeDrives';
import AdminPledgeDriveSubs   from './screens/admin/AdminPledgeDriveSubs';
import AdminPledges   from './screens/admin/AdminPledges';
import AdminAvatarParts from './screens/admin/AdminAvatarParts';
import AdminAvatarPartCats from './screens/admin/AdminAvatarPartCats';
import AdminAvatarPartNew from './screens/admin/AdminAvatarPartNew';
import { AdminAvatarPartEdit } from './screens/admin/AdminAvatarPartNew';
import AdminAvatarPartView from './screens/admin/AdminAvatarPartView';

import AdminPalettes from './screens/admin/AdminPalettes';
import AdminPalettesNew from './screens/admin/AdminPalettesNew';
import { AdminPalettesEdit } from './screens/admin/AdminPalettesNew';
import AdminPalettesView from './screens/admin/AdminPalettesView';

import AdminPages from './screens/admin/AdminPages';
import AdminPagesNew from './screens/admin/AdminPagesNew';
import { AdminPagesEdit } from './screens/admin/AdminPagesNew';
import AdminPagesView from './screens/admin/AdminPagesView';

import ClubCoinItem from './screens/club/coinItems/ClubCoinItem';
import ClubCoinItemPurchases from './screens/club/coinItemPurchases/ClubCoinItemPurchases';
import ClubCoinItems   from './screens/club/coinItems/ClubCoinItems';
import ClubCoinItemLayout from './screens/club/coinItems/ClubCoinItemLayout';



import ClubMerchProduct from './screens/club/merchProducts/ClubMerchProduct';
import ClubMerchProducts   from './screens/club/merchProducts/ClubMerchProducts';
import ClubMerchRequests from './screens/club/merchRequests/ClubMerchRequests';
import ClubMerchProductLayout from './screens/club/merchProducts/ClubMerchProductLayout';

import AdminMerchProduct from './screens/admin/merchProducts/AdminMerchProduct';
import AdminMerchProducts   from './screens/admin/merchProducts/AdminMerchProducts';
import AdminMerchProductLayout from './screens/admin/merchProducts/AdminMerchProductLayout';

import AdminMerchRequest from './screens/admin/merchRequests/AdminMerchRequest';
import AdminMerchRequests   from './screens/admin/merchRequests/AdminMerchRequests';
import AdminMerchRequestLayout from './screens/admin/merchRequests/AdminMerchRequestLayout';


import AdminAwards  from './screens/admin/AdminAwards';
import AdminAwardsLoginNew from './screens/admin/AdminAwardsLoginNew';
import { AdminAwardsLoginEdit } from './screens/admin/AdminAwardsLoginNew';
// import AdminAwardsLoginView from './screens/admin/AdminAwardsLoginView';
import AdminAwardsLapNew from './screens/admin/AdminAwardsLapNew';
import { AdminAwardsLapEdit } from './screens/admin/AdminAwardsLapNew';
// import AdminAwardsLapView from './screens/admin/AdminAwardsLapView';


import Pledge from './screens/pledge/Pledge';
import PledgeLayout from './screens/pledge/PledgeLayout';
import PledgeDonorSetup from './screens/pledge/PledgeDonorSetup';
import PledgePaymentSetup from './screens/pledge/PledgePaymentSetup';
import PledgeThanks from './screens/pledge/PledgeThanks';


import OnboardingAssignTeachers   from './screens/club/onboarding/OnboardingAssignTeachers';
import OnboardingStaff   from './screens/club/onboarding/OnboardingStaff';
import OnboardingUploadRoster   from './screens/club/onboarding/OnboardingUploadRoster';
import OnboardingConfirmRoster   from './screens/club/onboarding/OnboardingConfirmRoster';
import OnboardingSuccessRoster   from './screens/club/onboarding/OnboardingSuccessRoster';
import OnboardingCampaigns  from './screens/club/onboarding/OnboardingCampaigns';
import OnboardingCompetitions  from './screens/club/onboarding/OnboardingCompetitions';
import OnboardingParentInvites  from './screens/club/onboarding/OnboardingParentInvites';
import OnboardingTrack  from './screens/club/onboarding/OnboardingTrack';
import OnboardingStripe  from './screens/club/onboarding/OnboardingStripe';
import OnboardingDone from './screens/club/onboarding/OnboardingDone';

import ClubPledgeDrive from './screens/club/pledgeDrives/ClubPledgeDrive';
import ClubPledgeDriveSponsors from './screens/club/pledgeDrives/ClubPledgeDriveSponsors';

import ClubPledgeDrives   from './screens/club/pledgeDrives/ClubPledgeDrives';
import ClubPledgeDriveLayout from './screens/club/pledgeDrives/ClubPledgeDriveLayout';
import ClubPledges from './screens/club/pledgeDrives/ClubPledges';
import ClubPledge from './screens/club/pledgeDrives/ClubPledge';

import ClubTrack from './screens/club/tracks/ClubTrack';
import ClubTracks   from './screens/club/tracks/ClubTracks';
import ClubTrackLayout from './screens/club/tracks/ClubTrackLayout';

import ClubInspo from './screens/club/inspos/ClubInspo';
import ClubInspos   from './screens/club/inspos/ClubInspos';
import ClubInspoLayout from './screens/club/inspos/ClubInspoLayout';

import ClubRace from './screens/club/races/ClubRace';
import ClubRaceLaps from './screens/club/races/ClubRaceLaps';
import ClubRaces   from './screens/club/races/ClubRaces';
import ClubRaceLayout from './screens/club/races/ClubRaceLayout';



import ClubUsers   from './screens/club/ClubUsers';
import ClubImportAthletes   from './screens/club/athletes/ClubImportAthletes';
import ClubImportPreviewAthletes from './screens/club/athletes/ClubImportPreviewAthletes';
import ClubAthletes   from './screens/club/athletes/ClubAthletes';
import ClubAthlete   from './screens/club/athletes/ClubAthlete';
import ClubAthleteBadges   from './screens/club/athletes/ClubAthleteBadges';
import ClubAthleteLayout   from './screens/club/athletes/ClubAthleteLayout';
import ClubCharities   from './screens/club/ClubCharities';

import ClubMileTiming   from './screens/club/ClubMileTiming';
import ClubGeneralLaps   from './screens/club/ClubGeneralLaps';
import ClubCommunication  from './screens/club/ClubCommunication';
import ClubStripe  from './screens/club/account/ClubStripe';
// import ClubPledge from './screens/club/pledgeDrives/ClubPledge';

import ClubTeam from './screens/club/teams/ClubTeam';
import ClubTeams   from './screens/club/teams/ClubTeams';
import ClubTeamRoster from './screens/club/teams/ClubTeamRoster';
import ClubTeamManagers from './screens/club/teams/ClubTeamManagers';
import ClubTeamLayout from './screens/club/teams/ClubTeamLayout';
import ClubPledgePreviewDonorSetup from './screens/club/pledgeDrives/ClubPledgePreviewDonorSetup'
import ClubPledgePreviewPayment from './screens/club/pledgeDrives/ClubPledgePreviewPayment'

import ClubAccountLayout from './screens/club/account/ClubAccountLayout';
import ClubAccountHome   from './screens/club/account/ClubAccountHome';
import ClubAccountMachines   from './screens/club/account/ClubAccountMachines';
import ClubAccountStripe from './screens/club/account/ClubAccountStripe';

import ClubSchoolYearLayout   from './screens/club/schoolYear/ClubSchoolYearLayout';
import ClubSchoolYear from './screens/club/schoolYear/ClubSchoolYear';
import ClubSchoolYearWaivers   from './screens/club/schoolYear/ClubSchoolYearWaivers';
import ClubSchoolYearFees   from './screens/club/schoolYear/ClubSchoolYearFees';



import ClubBand from './screens/club/bands/ClubBand';
import ClubBands   from './screens/club/bands/ClubBands';
import ClubBandLayout from './screens/club/bands/ClubBandLayout';

import ClubStaffLayout from './screens/club/staff/ClubStaffLayout';
import ClubStaff from './screens/club/staff/ClubStaff';
import ClubStaffNew from './screens/club/staff/ClubStaffNew';
import ClubStaffView from './screens/club/staff/ClubStaffView';

import ClubCalendarLayout from './screens/club/calendar/ClubCalendarLayout';
import ClubCalendarHome from './screens/club/calendar/ClubCalendarHome';

import ClubStatsLayout from './screens/club/stats/ClubStatsLayout';
import ClubStatsHome from './screens/club/stats/ClubStatsHome';
import ClubStatsTeams from './screens/club/stats/ClubStatsTeams';
import ClubStatsAthletes from './screens/club/stats/ClubStatsAthletes';

import AccountLayout from './screens/account/AccountLayout';
import AccountHome   from './screens/account/AccountHome';
import AccountEmailConf   from './screens/account/AccountEmailConf';

import LoggedinLayout from './screens/LoggedinLayout';
import { reupdateUser } from "./redux/session/sessionActions";
import { useApi } from './api';

import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

import MainNav from './screens/MainNav';
import BottomNav from './screens/BottomNav';

import ClubBottomNav from './screens/club/ClubBottomNav';
import ProfileBottomNav from './screens/profile/ProfileBottomNav';
import AdminBottomNav from './screens/admin/AdminBottomNav';

import ClubJoin from './screens/ClubJoin';

import Checkout from './screens/Checkout';
import Upgrade from './screens/profile/Upgrade';
import UpgradeConfirmation from './screens/profile/UpgradeConfirmation';
import MaterialFeeConfirmation from './screens/profile/ProfileMaterialFeeConfirmation';
import PageLayout from './screens/PageLayout';

import Test3d from './screens/Test3d';

import { getPages } from './redux/pages/pageActions';
import { getMyClub } from './redux/club/clubActions';

import PeTeacherLayout from './screens/club/peTeacher/PeTeacherLayout';
import PeTeacherHome from './screens/club/peTeacher/PeTeacherHome';

import TeamAdminLayout from './screens/club/teamAdmin/TeamAdminLayout';
import TeamAdminHome from './screens/club/teamAdmin/TeamAdminHome';
import TeamAdminAthletes   from './screens/club/teamAdmin/TeamAdminAthletes';


import RunMonitorLayout from './screens/club/runMonitor/RunMonitorLayout';
import RunMonitorHome from './screens/club/runMonitor/RunMonitorHome';
import PledgeVerify from 'screens/pledge/PledgeVerify';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // alert('scroll');
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const LoadingScreen = ({debug}) => {
  return(
    <div className="center padding-3">
      <div className="spinner"></div>

      {/* <pre>
        {JSON.stringify(debug, null, 2)}
      </pre> */}
    </div>
  )
}


const App = ({parentAuthed, pages, currentAthlete, currentClub, currentUser, authToken, globalErrors, unreadNotiListables}) => {

  const [newCurrentUser, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [pagesResult, pagesLoading, pagesLoaded, pagesError, doPagesFetch, setPagesResult] = useApi(); 

  const [club, clubLoading, clubLoaded, clubError, doClubFetch, setClub] = useApi(); 

  const [started, setStarted] = useState(true);



  useEffect(() => {
    // fetchClub();
  }, []);

  const fetchClub = () => {
    doClubFetch(getMyClub);
  };



  useEffect(() => {

    fetchPages();

    // if (currentUser) {
    //   fetchCurrentUser();
    // }
    // else {
    //   store.dispatch({ type: 'UNSET_CURRENT_USER' });
    //   localStorage.setItem('currentUser', null);
    //   localStorage.setItem('authToken', null);
    // }

    // alert('here 2');
    
  }, []);

  useEffect(() => {
    // alert(JSON.stringify(currentUser, null, 2));
    if (currentUser && currentUser.role !== "admin" && currentUser.role !== "customer") {
      fetchClub();
    }

    if (!currentUser) {
      setStarted(false);
    }
  }, [currentUser]);

  

  const fetchPages = () => {
    doPagesFetch(getPages);
  }

  const fetchCurrentUser = () => {
    doFetch(reupdateUser);
  };

  useEffect(() => {
    if (loaded || clubLoaded) {
      setStarted(false);
    }
  }, [loaded, clubLoading]);
 


  // if (window.location.hostname === "herorun.com") {
  //   return(
  //     <div className="center margin-top-4 really-narrow">
  //       <h1>Under Construction</h1>
  //     </div>
  //   )
  // }

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(started || clubLoading || loading)
  }, [started, clubLoading, loading])

  const loadingDebug = () => {
    return {
      started: started,
      clubLoading: clubLoading,
      loading: loading,
      loaded: loaded,
      clubLoaded: clubLoaded
    }
  }

  // const isLoading = started || clubLoading || loading;


  return (
    <Router>
      <ScrollToTop />
      <div className="App">

        
        

        <div className="global-error-cont">
          { globalErrors && globalErrors.map( (error, i) => {
            return(
              
                <div className="error flex-cont align-center">
                  <div className="flex-1 margin-right-1">
                    {error}
                  </div>
                  <div onClick={() => store.dispatch(removeError(i))} className="ghost button white-danger">
                    <i className="fas fa-times"></i>
                  </div>  
                </div>
              
            )
          })}
        </div>


       
       {/* <BottomNav /> */}
       <MainNav pages={pages} />
       
       {/* {currentUser && currentUser.role==="club" && currentClub && currentClub.status === "accepted" &&
          <ClubBottomNav />
       } */}
       {/* {currentUser && currentUser.role==="customer" &&
          <ProfileBottomNav />
       } */}
       {/* {currentUser && currentUser.role==="admin" &&
          
       } */}


        

        <main>
      


          <Routes>
            <React.Fragment>

            {isLoading &&
              <Route path="*" element={<LoadingScreen debug={loadingDebug()} />} />
            }

            <Route path="/3d" element={<Test3d />} />
            <Route path="/support" element={<SupportScreen />} />

{/* 
            <Route path="/admin-dashboard" element={<AdminLayout><Outlet /></AdminLayout>}>
              <Route path="/" element={<AdminHome />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="race-files" element={<AdminRaceFiles />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="users/:id" element={<AdminUsers />} />
              <Route path="athletes" element={<AdminAthletes />} />
              <Route path="athletes/:id" element={<AdminAthletes />} />
              <Route path="athletes/view/:id" element={<AdminAthlete />} />
              <Route path="tracks" element={<AdminTracks />} />

              <Route path="races" element={<AdminRaces />} />
              <Route path="races-logs" element={<AdminRaceLogs />} />
              <Route path="purchases" element={<AdminPurchases />} />
              <Route path="teams" element={<AdminTeams />} />
              <Route path="clubs" element={<AdminClubs />} />
              <Route path="clubs/:id" element={<AdminClub />}/>

              <Route path="charities" element={<AdminCharities />} />
              <Route path="pledge-drives" element={<AdminPledgeDrives />} />
              <Route path="pledge-drive-subs" element={<AdminPledgeDriveSubs />} />

              <Route path="pledges" element={<AdminPledges />} />
            </Route> */}

            {/* {pages?.map(page =>
                <Route path={`${page.slug}`} element={<PageLayout />} />
              )
            } */}
            
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout2" element={<Test3d />} />

            {!isLoading &&
              <Route path={`:slug`} element={<PageLayout />} />
            }

            {!currentUser && !isLoading &&
              <Route path="/" element={<HomeScreen />} />
            }





            {currentUser && currentUser.role === "customer" &&
              <React.Fragment>

                {parentAuthed &&
                  <React.Fragment>
                    <Route path="/upgrade" element={<Upgrade />} />
                    <Route path="/material-fee" element={<ProfileMaterialFee />} />
                    <Route path="/" element={<ParentLayout />}>
                      
                      <Route path="" element={<ParentHome />} />
                      <Route path="career" element={<ParentCareer />} />
                      {/* <Route path="clubs" element={<ProfileClubs />} /> */}
                      <Route path="activity" element={<ParentActivity />} />
                      <Route path="fundraising" element={<ParentFundraising />} />
                    </Route>

                    <Route path="notifications" element={<Notifications />} />
                    <Route path="account" element={<AccountHome />} />  
                  </React.Fragment>
                
                }
                {!parentAuthed &&
                  <Route path="/" element={<ProfileLayout />}>
                    <Route path="" element={<ProfileHome />} />
                    <Route path="career" element={<ProfileCareer />} />
                    {/* <Route path="clubs" element={<ProfileClubs />} /> */}
                    <Route path="activity" element={<ProfileActivity />} />
                    <Route path="awards" element={<ProfileAwards />} />
                    <Route path="badges" element={<ProfileBadges />} />
                    <Route path="coins" element={<ProfileCoins />} />
                    <Route path="fundraising" element={<ProfileFundraising />} />
                  </Route>
                }
                <Route path="/upgrade" element={<Upgrade />} />
                <Route path="/material-fee" element={<ProfileMaterialFee />} />
                {/* <Route path="/" element={<ProfileLayout><ProfileHome /></ProfileLayout>} /> */}
                

                <Route path="notifications" element={<Notifications />} />
                <Route path="account" element={<AccountHome />} />

                <Route path="club-search" element={<Clubs />} />
              </React.Fragment>
            }
            {currentUser && currentUser.role === "admin" &&
              <React.Fragment>
                <Route path="/" element={<AdminLayout><AdminHome /></AdminLayout>} />
                <Route path="/" element={<AdminLayout><Outlet /></AdminLayout>}>

                  <Route path="notifications" element={<Notifications />} />
                  <Route path="race-files" element={<AdminRaceFiles />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="users/:id" element={<AdminUsers />} />
                  <Route path="athletes" element={<AdminAthletes />} />
                  <Route path="athletes/:id" element={<AdminAthletes />} />
                  <Route path="athletes/view/:id" element={<AdminAthlete />} />
                  <Route path="tracks" element={<AdminTracks />} />
                  <Route path="badges" element={<AdminBadges />} />
                  <Route path="awards/:tab" element={<AdminAwards />} />
                  <Route path="awards/login/new" element={<AdminAwardsLoginNew />} />
                  <Route path="awards/login/edit/:id" element={<AdminAwardsLoginEdit />} />
                  <Route path="awards/lap/new" element={<AdminAwardsLapNew />} />
                  <Route path="awards/lap/edit/:id" element={<AdminAwardsLapEdit />} />
                  <Route path="hero-bands" element={<AdminBands />} />
                  <Route path="inspiration" element={<AdminInspos />} />
                  <Route path="machines" element={<AdminMachines />} />

                  <Route path="races" element={<AdminRaces />} />
                  <Route path="run-themes" element={<AdminRunThemes />} />
                  <Route path="avatar-part-categories" element={<AdminAvatarPartCats />} />
                  <Route path="avatar-parts" element={<AdminAvatarParts />} />
                  <Route path="avatar-parts/view/:id" element={<AdminAvatarPartView />} />
                  <Route path="avatar-parts/new" element={<AdminAvatarPartNew />} />
                  <Route path="avatar-parts/edit/:id" element={<AdminAvatarPartEdit />} />


                  


                  <Route path="merch-products" element={<AdminMerchProducts />} />
                  <Route path="merch-products/:id" element={<AdminMerchProductLayout><AdminMerchProduct /></AdminMerchProductLayout>} />
                  <Route path="merch-products/:id" element={<AdminMerchProductLayout><Outlet /></AdminMerchProductLayout>} >
                    <Route path="merch-products/:id/" element={<AdminMerchProduct />} />
                  </Route>

                  <Route path="merch-requests" element={<AdminMerchRequests />} />
                  <Route path="merch-requests/:id" element={<AdminMerchRequestLayout><AdminMerchRequest /></AdminMerchRequestLayout>} />
                  <Route path="merch-requests/:id" element={<AdminMerchRequestLayout><Outlet /></AdminMerchRequestLayout>} >
                    <Route path="merch-requests/:id/" element={<AdminMerchRequest />} />
                  </Route>


                  <Route path="palettes" element={<AdminPalettes />} />
                  <Route path="palettes/view/:id" element={<AdminPalettesView />} />
                  <Route path="palettes/new" element={<AdminPalettesNew />} />
                  <Route path="palettes/edit/:id" element={<AdminPalettesEdit />} />

                  <Route path="pages" element={<AdminPages />} />
                  <Route path="pages/view/:id" element={<AdminPagesView />} />
                  <Route path="pages/new" element={<AdminPagesNew />} />
                  <Route path="pages/edit/:id" element={<AdminPagesEdit />} />
                  
                  <Route path="races-logs" element={<AdminRaceLogs />} />
                  <Route path="purchases" element={<AdminPurchases />} />

                  <Route path="clubs" element={<AdminClubs />} />
                  <Route path="clubs/tab/:id" element={<AdminClubs />} />
                  <Route path="clubs/view/:id" element={<AdminClub />}/>

                  <Route path="teams" element={<AdminTeams />} />

                  <Route path="charities" element={<AdminCharities />} />
                  <Route path="pledge-drives" element={<AdminPledgeDrives />} />
                  <Route path="pledge-drive-subs" element={<AdminPledgeDriveSubs />} />
                  <Route path="account" element={<AccountHome />} />
                  <Route path="pledges" element={<AdminPledges />} />

                  <Route path="products" element={<AdminProducts />} />
                  <Route path="subscriptions" element={<AdminSubscriptions />} />
                  <Route path="payments" element={<AdminPayments />} />

                  <Route path="club-search" element={<Clubs />} />
                </Route>

                <Route path="/admin-dashboard" element={<AdminLayout><Outlet /></AdminLayout>}>
                  <Route path="/admin-dashboard" element={<AdminHome />} />
                  <Route path="race-files" element={<AdminRaceFiles />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="users/:id" element={<AdminUsers />} />
                  <Route path="athletes" element={<AdminAthletes />} />
                  <Route path="athletes/:id" element={<AdminAthletes />} />
                  <Route path="athletes/view/:id" element={<AdminAthlete />} />
                  <Route path="tracks" element={<AdminTracks />} />
                  <Route path="badges" element={<AdminBadges />} />
                  <Route path="awards/:tab" element={<AdminAwards/>} />
                  <Route path="inspiration" element={<AdminInspos />} />
                  <Route path="hero-bands" element={<AdminBands />} />
                  <Route path="races" element={<AdminRaces />} />
                  <Route path="races-logs" element={<AdminRaceLogs />} />
                  <Route path="purchases" element={<AdminPurchases />} />

                  <Route path="clubs" element={<AdminClubs />} />
                  <Route path="clubs/view/:id" element={<AdminClub />}/>
                  <Route path="teams" element={<AdminTeams />} />
                  <Route path="charities" element={<AdminCharities />} />
                  <Route path="pledge-drives" element={<AdminPledgeDrives />} />
                  <Route path="pledge-drive-subs" element={<AdminPledgeDriveSubs />} />
                  <Route path="account" element={<AccountHome />} />
                  <Route path="pledges" element={<AdminPledges />} />

                  <Route path="products" element={<AdminProducts />} />
                  <Route path="subscriptions" element={<AdminSubscriptions />} />
                  <Route path="payments" element={<AdminPayments />} />

                  <Route path="club-search" element={<Clubs />} />
                </Route>
              </React.Fragment>
            }
          
            {currentUser && currentUser.role === "club" && currentClub &&
              <React.Fragment>
                {currentClub.current_user_role === "run-monitor" &&
                  <React.Fragment>
                    <Route path="/" element={<RunMonitorLayout />} >
                      <Route path="" element={<RunMonitorHome />} />
                      <Route path="calendar" element={<ClubCalendarLayout><ClubCalendarHome /></ClubCalendarLayout>} />
                      <Route path="calendar" element={<ClubCalendarLayout><Outlet /></ClubCalendarLayout>} >
                        <Route path="calendar/" element={<ClubCalendarHome />} />
                      </Route>
                    </Route>
                  </React.Fragment>
                }
                {currentClub.current_user_role === "team-admin" &&
                  <React.Fragment>
                    <Route path="/" element={<TeamAdminLayout><TeamAdminHome /></TeamAdminLayout>} />
                    <Route path="/" element={<TeamAdminLayout><Outlet /></TeamAdminLayout>} >
                      <Route path="/" element={<TeamAdminHome />} />

                      <Route path="stats" element={<ClubStatsLayout><ClubStatsHome /></ClubStatsLayout>} />
                      <Route path="stats" element={<ClubStatsLayout><Outlet /></ClubStatsLayout>} >
                        <Route path="stats/" element={<ClubStatsHome />} />
                        <Route path="teams" element={<ClubStatsTeams />} />
                        <Route path="students" element={<ClubStatsAthletes />} />
                      </Route>

                      <Route path="calendar" element={<ClubCalendarLayout><ClubCalendarHome /></ClubCalendarLayout>} />
                      <Route path="calendar" element={<ClubCalendarLayout><Outlet /></ClubCalendarLayout>} >
                        <Route path="calendar/" element={<ClubCalendarHome />} />
                      </Route>

                      <Route path="teams" element={<ClubTeams />} />
                      <Route path="teams/:id" element={<ClubTeamLayout><ClubTeam /></ClubTeamLayout>} />
                      <Route path="teams/:id" element={<ClubTeamLayout><Outlet /></ClubTeamLayout>} >
                        <Route path="teams/:id/" element={<ClubTeam />} />
                        <Route path="roster" element={<ClubTeamRoster />} />
                        <Route path="managers" element={<ClubTeamManagers />} />
                      </Route>

                      <Route path="tracks" element={<ClubTracks />} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><ClubTrack /></ClubTrackLayout>} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><Outlet /></ClubTrackLayout>} >
                        <Route path="tracks/:id/" element={<ClubTrack />} />
                      </Route>

                      <Route path="hero-bands" element={<ClubBands />} />
                      <Route path="hero-bands/:code" element={<ClubBandLayout><ClubBand /></ClubBandLayout>} />
                      <Route path="hero-bands/:code" element={<ClubBandLayout><Outlet /></ClubBandLayout>} >
                        <Route path="hero-bands/:code/" element={<ClubBand />} />
                      </Route>


                      <Route path="students" element={<TeamAdminAthletes />} />
                      {/* <Route path="students" element={<ClubAthletes />} /> */}
                      <Route path="students/import" element={<ClubImportAthletes />} />
                      <Route path="students/import/preview/:id" element={<ClubImportPreviewAthletes />} />
                      <Route path="students/:id" element={<ClubAthleteLayout><ClubAthlete /></ClubAthleteLayout>} />
                      <Route path="students/:id" element={<ClubAthleteLayout><Outlet /></ClubAthleteLayout>} >
                        <Route path="students/:id/" element={<ClubAthlete />} />
                        <Route path="badges" element={<ClubAthleteBadges />} />
                      </Route>
                    </Route>
                  </React.Fragment>
                }
                {currentClub.current_user_role === "pe-teacher" &&
                  <React.Fragment>
                    <Route path="/" element={<PeTeacherLayout><PeTeacherHome /></PeTeacherLayout>} />
                    <Route path="/" element={<PeTeacherLayout><Outlet /></PeTeacherLayout>} >
                      <Route path="/" element={<PeTeacherHome />} />

                      <Route path="stats" element={<ClubStatsLayout><ClubStatsHome /></ClubStatsLayout>} />
                      <Route path="stats" element={<ClubStatsLayout><Outlet /></ClubStatsLayout>} >
                        <Route path="stats/" element={<ClubStatsHome />} />
                        <Route path="teams" element={<ClubStatsTeams />} />
                        <Route path="students" element={<ClubStatsAthletes />} />
                      </Route>

                      <Route path="calendar" element={<ClubCalendarLayout><ClubCalendarHome /></ClubCalendarLayout>} />
                      <Route path="calendar" element={<ClubCalendarLayout><Outlet /></ClubCalendarLayout>} >
                        <Route path="calendar/" element={<ClubCalendarHome />} />
                      </Route>

                      <Route path="teams" element={<ClubTeams />} />
                      <Route path="teams/:id" element={<ClubTeamLayout><ClubTeam /></ClubTeamLayout>} />
                      <Route path="teams/:id" element={<ClubTeamLayout><Outlet /></ClubTeamLayout>} >
                        <Route path="teams/:id/" element={<ClubTeam />} />
                        <Route path="roster" element={<ClubTeamRoster />} />
                        <Route path="managers" element={<ClubTeamManagers />} />
                      </Route>

                      <Route path="tracks" element={<ClubTracks />} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><ClubTrack /></ClubTrackLayout>} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><Outlet /></ClubTrackLayout>} >
                        <Route path="tracks/:id/" element={<ClubTrack />} />
                      </Route>

                      <Route path="hero-bands" element={<ClubBands />} />
                      <Route path="hero-bands/:code" element={<ClubBandLayout><ClubBand /></ClubBandLayout>} />
                      <Route path="hero-bands/:code" element={<ClubBandLayout><Outlet /></ClubBandLayout>} >
                        <Route path="hero-bands/:code/" element={<ClubBand />} />
                      </Route>

                      <Route path="students" element={<ClubAthletes />} />
                      <Route path="students/import" element={<ClubImportAthletes />} />
                      <Route path="students/import/preview/:id" element={<ClubImportPreviewAthletes />} />
                      <Route path="students/:id" element={<ClubAthleteLayout><ClubAthlete /></ClubAthleteLayout>} />
                      <Route path="students/:id" element={<ClubAthleteLayout><Outlet /></ClubAthleteLayout>} >
                        <Route path="students/:id/" element={<ClubAthlete />} />
                        <Route path="badges" element={<ClubAthleteBadges />} />
                      </Route>
                    </Route>
                  </React.Fragment>
                }
                {currentClub.current_user_role === "club-admin" &&
                  <React.Fragment>
                    <Route path="/pledge-preview" element={<ClubPledgePreview />}>
                      <Route path=":id" element={<Pledge />} />
                    </Route>
                    {/* <Route path="/pledge-preview/:id/donor-setup" element={<ClubPledgePreviewDonorSetup />} />
                    <Route path="/pledge-preview/:id/payment-setup" element={<ClubPledgePreviewPayment />} /> */}
                    {/* <Route path="/" element={<ClubLayout><ClubHome /></ClubLayout>} /> */}
                    <Route path="/" element={<ClubLayout />} >
                      <Route path="" element={<ClubHome />} />

                      

                      <Route path="onboarding/upload-roster" element={<OnboardingUploadRoster />} />
                      <Route path="onboarding/confirm-roster/:id" element={<OnboardingConfirmRoster />} />
                      <Route path="onboarding/roster-success" element={<OnboardingSuccessRoster />} />
                      <Route path="onboarding/assign-teachers" element={<OnboardingAssignTeachers />} />
                      <Route path="onboarding/faculty-and-staff" element={<OnboardingStaff />} />
                      <Route path="onboarding/campaigns" element={<OnboardingCampaigns />} />
                      <Route path="onboarding/competitions" element={<OnboardingCompetitions/>} />
                      <Route path="onboarding/parent-invites" element={<OnboardingParentInvites/>} />
                      <Route path="onboarding/track" element={<OnboardingTrack />} />
                      <Route path="onboarding/stripe" element={<OnboardingStripe />} />
                      <Route path="onboarding/done" element={<OnboardingDone/>} />

                      <Route path="notifications" element={<Notifications />} />


                      <Route path="activities" element={<ClubRaces />} />
                      <Route path="activities/:id" element={<ClubRaceLayout><ClubRace /></ClubRaceLayout>} />
                      <Route path="activities/:id" element={<ClubRaceLayout><Outlet /></ClubRaceLayout>} >
                        <Route path="activities/:id/" element={<ClubRace />} />
                        <Route path="laps" element={<ClubRaceLaps />} />
                      </Route>
                      
                      
                      <Route path="laps" element={<ClubLaps />} />
                      <Route path="live-run" element={<ClubLiveRun />} />
                      <Route path="tag-read-demo" element={<ClubTagDemo />} />
                      <Route path="timed-mile" element={<ClubMileTiming />} />
                      <Route path="general-laps" element={<ClubGeneralLaps />} />
                      <Route path="stripe-setup" element={<ClubAccountStripe />} />
                      <Route path="teams" element={<ClubTeams />} />
                      {/* <Route path="teams/:id" element={<ClubTeam />} />
                      <Route path="teams/:id/roster" element={<ClubTeamRoster />} />
                      <Route path="teams/:id/managers" element={<ClubTeamManagers/>} /> */}

                      <Route path="tracks" element={<ClubTracks />} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><ClubTrack /></ClubTrackLayout>} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><Outlet /></ClubTrackLayout>} >
                        <Route path="tracks/:id/" element={<ClubTrack />} />
                      </Route>


                      <Route path="coin-items" element={<ClubCoinItems />} />
                      <Route path="coin-items/my-orders" element={<ClubMerchRequests />} />
                      <Route path="coin-items/my-products" element={<ClubCoinItems />} />
                      <Route path="coin-items/:id" element={<ClubCoinItemLayout><ClubCoinItem /></ClubCoinItemLayout>} />
                      <Route path="coin-items/:id/purchases" element={<ClubCoinItemLayout><ClubCoinItemPurchases /></ClubCoinItemLayout>} />
                      <Route path="coin-items/:id" element={<ClubCoinItemLayout><Outlet /></ClubCoinItemLayout>} >
                        <Route path="coin-items/:id/" element={<ClubCoinItem />} />
                        <Route path="purchases" element={<ClubCoinItemPurchases />} />
                      </Route>




                      
                      <Route path="merch-products" element={<ClubMerchProducts />} />
                      <Route path="merch-products/my-orders" element={<ClubMerchRequests />} />
                      <Route path="merch-products/my-products" element={<ClubMerchProducts />} />
                      <Route path="merch-products/:id" element={<ClubMerchProductLayout><ClubMerchProduct /></ClubMerchProductLayout>} />
                      <Route path="merch-products/:id" element={<ClubMerchProductLayout><Outlet /></ClubMerchProductLayout>} >
                        <Route path="merch-products/:id/" element={<ClubMerchProduct />} />
                      </Route>


                      <Route path="inspiration" element={<ClubInspos />} />
                      <Route path="inspiration/:id" element={<ClubInspoLayout><ClubInspo /></ClubInspoLayout>} />
                      <Route path="inspiration/:id" element={<ClubInspoLayout><Outlet /></ClubInspoLayout>} >
                        <Route path="inspiration/:id/" element={<ClubInspo />} />
                      </Route>

                      <Route path="school-year/waivers" element={<ClubSchoolYearLayout><ClubSchoolYearWaivers /></ClubSchoolYearLayout>} />
                      <Route path="school-year/fee-payments" element={<ClubSchoolYearLayout><ClubSchoolYearFees /></ClubSchoolYearLayout>} />
                      <Route path="school-year" element={<ClubSchoolYearLayout><ClubSchoolYear /></ClubSchoolYearLayout>} />
                      <Route path="school-year" element={<ClubSchoolYearLayout><Outlet /></ClubSchoolYearLayout>} >
                        {/* <Route path="school-year/waivers" element={<ClubSchoolYearWaivers />} />
                        <Route path="school-year/waiversx" element={<ClubSchoolYear />} /> */}
                      </Route>


                      <Route path="students" element={<ClubAthletes />} />
                      <Route path="students/import" element={<ClubImportAthletes />} />
                      <Route path="students/import/preview/:id" element={<ClubImportPreviewAthletes />} />
                      <Route path="students/:id" element={<ClubAthleteLayout><ClubAthlete /></ClubAthleteLayout>} />
                      <Route path="students/:id" element={<ClubAthleteLayout><Outlet /></ClubAthleteLayout>} >
                        <Route path="students/:id" element={<ClubAthlete />} />
                        <Route path="badges" element={<ClubAthleteBadges />} />
                      </Route>

                      <Route path="teams/:id" element={<ClubTeamLayout><ClubTeam /></ClubTeamLayout>} />
                      <Route path="teams/:id" element={<ClubTeamLayout><Outlet /></ClubTeamLayout>} >
                        <Route path="teams/:id/" element={<ClubTeam />} />
                        <Route path="roster" element={<ClubTeamRoster />} />
                        <Route path="managers" element={<ClubTeamManagers />} />
                      </Route>

                      


                      <Route path="account" element={<ClubAccountLayout><ClubAccountHome /></ClubAccountLayout>} />
                      <Route path="account" element={<ClubAccountLayout><Outlet /></ClubAccountLayout>} >
                        <Route path="account/" element={<ClubAccountHome />} />
                        <Route path="stripe" element={<ClubAccountStripe/>} />
                        <Route path="hero-bases" element={<ClubAccountMachines />} />
                      </Route>

                      <Route path="hero-bands" element={<ClubBands />} />
                      <Route path="hero-bands/:code" element={<ClubBandLayout><ClubBand /></ClubBandLayout>} />
                      <Route path="hero-bands/:code" element={<ClubBandLayout><Outlet /></ClubBandLayout>} >
                        <Route path="hero-bands/:code/" element={<ClubBand />} />
                      </Route>


                      <Route path="staff" element={<ClubStaffLayout><ClubStaff /></ClubStaffLayout>} />
                      <Route path="staff" element={<ClubStaffLayout><Outlet /></ClubStaffLayout>} >
                        <Route path="staff/" element={<ClubStaff />} />
                        <Route path=":tab" element={<ClubStaff />} />
                        <Route path="view/:slug" element={<ClubStaffView />} />
                        <Route path="new" element={<ClubStaffNew />} />
                      </Route>

                      <Route path="calendar" element={<ClubCalendarLayout><ClubCalendarHome /></ClubCalendarLayout>} />
                      <Route path="calendar" element={<ClubCalendarLayout><Outlet /></ClubCalendarLayout>} >
                        <Route path="calendar/" element={<ClubCalendarHome />} />
                      </Route>


                      

                      
                      <Route path="users" element={<ClubUsers />} />
                      <Route path="users/:id" element={<ClubUsers />} />
                      
                      <Route path="causes" element={<ClubCharities />} />
                      
                      <Route path="campaigns" element={<ClubPledgeDrives />} />
                      <Route path="campaigns/:id" element={<ClubPledgeDriveLayout><ClubPledgeDrive /></ClubPledgeDriveLayout>} />
                      <Route path="campaigns/:id" element={<ClubPledgeDriveLayout><Outlet /></ClubPledgeDriveLayout>} >
                        <Route path="campaigns/:id/" element={<ClubPledgeDrive />} />
                        <Route path="sponsors" element={<ClubPledgeDriveSponsors />} />
                        {/* <Route path="material-fee" element={<ClubMaterialFee />} /> */}
                        <Route path="pledges" element={<ClubPledges />} />

                        <Route path="pledges/:code" element={<ClubPledge />} />
                      </Route>

                      


                      <Route path="account" element={<AccountHome />} />
                      <Route path="pledges" element={<ClubPledges />} />
                      <Route path="communication" element={<ClubCommunication />} />
                      <Route path="club-search" element={<Clubs />} />
                    </Route>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            {currentUser && currentUser.role === "timer" &&
              <React.Fragment>
                <Route path="/" element={<TimerLayout><TimerHome /></TimerLayout>} />
                <Route path="/timer-dashboard" element={<TimerLayout><Outlet /></TimerLayout>}>
                  <Route path="/" element={<TimerHome />} />
                  <Route path="races" element={<TimerRaces />} />
                </Route>
              </React.Fragment>
            }
{/* 
          <Route path="pledge/:code" element={<ClubPledgeDriveLayout><ClubPledgeDrive /></ClubPledgeDriveLayout>} /> */}
            <Route path="/find-pledge" element={<FindPledge />} />
            <Route path="/forgot-pledge-code" element={<ForgotPledge />} />
            <Route path="pledge" element={<PledgeLayout />} >
              <Route path="progress/:pledge_code" element={<PledgeProgress />} />
              <Route path="edit" element={<EditPledge />} />
              <Route path=":code" element={<Pledge />} />
              <Route path=":code/donor-setup" element={<PledgeDonorSetup />} />
              <Route path=":code/verify" element={<PledgeVerify />} />
              <Route path=":code/payment-setup" element={<PledgePaymentSetup />} />
              
              
              <Route path=":code/thanks" element={<PledgeThanks />} />
            </Route>

            <Route path="tracks" element={<ClubTracks />} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><ClubTrack /></ClubTrackLayout>} />
                      <Route path="tracks/:id" element={<ClubTrackLayout><Outlet /></ClubTrackLayout>} >
                        <Route path="tracks/:id/" element={<ClubTrack />} />
                      </Route>

            
            {/* <Route path="campaigns/:id" element={<ClubPledgeDriveLayout><ClubPledgeDrive /></ClubPledgeDriveLayout>} />
              <Route path="campaigns/:id" element={<ClubPledgeDriveLayout><Outlet /></ClubPledgeDriveLayout>} >
                <Route path="campaigns/:id/" element={<ClubPledgeDrive />} />
                <Route path="pledges" element={<ClubPledges />} />

                <Route path="pledges/:code" element={<ClubPledge />} />
              </Route> */}

            {/* <Route path="/pledge/:code/donor-setup" element={<Pledge />} /> */}
            {/* <Route path="/" element={<HomeScreen />} /> */}
            <Route path="user-invite" element={<UserInvite />} />


            <Route path="resend-invite" element={<ResendInvite />} />
            <Route path="resend-invite/sent" element={<InviteSent />} />
            <Route path="resend-invite/new" element={<InviteNew />} />
            <Route path="resend-invite/success" element={<InviteSuccess />} />

            <Route path="password-reset" element={<PasswordReset />} />
            <Route path="password-reset/sent" element={<PWSent />} />
            <Route path="password-reset/new" element={<PWNew />} />
            <Route path="password-reset/success" element={<PWSuccess />} />


            <Route path="club/:slug" element={<ClubPage />} />
            
            <Route path="login" element={<Login />} />
            <Route path="join" element={<Join />} />

            <Route path="get-started" element={<GetStarted />} />

            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />

            {/* <Route path="get-started/club" element={<ClubJoin />} />
            <Route path="get-started/parent" element={<Join />} />
            <Route path="get-started/account-details/:type" element={<Join />} /> */}

            <Route path="join/club" element={<ClubJoin />} />
            <Route path="join/parent" element={<Join />} />
            <Route path="join/account-details/:type" element={<Join />} />

            <Route path="/join/athlete-setup" element={<AthleteSetup />} />
            <Route path="/join/upgrade" element={<Upgrade isJoin={true} />} />
            <Route path="/upgrade/confirmation" element={<UpgradeConfirmation />} />
            <Route path="/material-fee/confirmation" element={<MaterialFeeConfirmation />} />
            {/* <AuthorizedRoute roles={["customer", "admin"]} path="">
              <Route path="/join/athlete-setup" element={<AthleteSetup />} />
              <Route path="/join/upgrade" element={<Upgrade isJoin={true} />} />
              <Route path="/upgrade/confirmation" element={<UpgradeConfirmation />} />
            </AuthorizedRoute> */}


            <Route path="email-confirmation" element={<EmailConfirmation />} />
            



            



            <Route path="/profile" element={<ProfileLayout><Outlet /></ProfileLayout>}>
              <Route path="profile/" element={<ProfileHome />} />
              <Route path="clubs" element={<ProfileClubs />} />
              <Route path="activity" element={<ProfileActivity />} />
              <Route path="fundraising" element={<ProfileFundraising />} />
            </Route>

            

            <Route path="/club-dashboard" element={<ClubLayout><Outlet /></ClubLayout>} >
              <Route path="club-dashboard/" element={<ClubHome />} />
              <Route path="teams" element={<ClubTeams />} />
              <Route path="users" element={<ClubUsers />} />
              <Route path="users/:id" element={<ClubUsers />} />
              <Route path="athletes" element={<ClubAthletes />} />
              <Route path="athletes/:id" element={<ClubAthletes />} />
              <Route path="causes" element={<ClubCharities />} />
              <Route path="pledge-drives" element={<ClubPledgeDrives />} />
              <Route path="pledges" element={<ClubPledges />} />
            </Route>


            <Route path="/timer-dashboard" element={<TimerLayout><Outlet /></TimerLayout>}>
              <Route path="timer-dashboard/" element={<TimerHome />} />
              <Route path="races" element={<TimerRaces />} />
            </Route>


            {/* <Route path="/account" element={<AccountLayout />}>
              <Route path="/" element={<AccountHome />} />
              <Route path="/email-confirmation" element={<AccountEmailConf />} />
            </Route> */}

            {!isLoading &&
              <Route path="*" element={<NotFound />} />
            }
            
            </React.Fragment>
          </Routes> 

        </main>

        <Footer pages={pages} />
      </div>
    </Router>
  );
}

function mapState(state) {
  return { 
    parentAuthed: state.sessionState.parentAuthed,
    currentAthlete: state.sessionState.currentAthlete,
    currentUser: state.sessionState.currentUser,
    currentClub: state.sessionState.currentClub,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
    currentClub: state.clubState.currentClub,
    pages: state.pageState.pages,
  };
} 

export default connect(mapState)(App);
