

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';


const AccountHome = ({currentUser}) => {

  const navigate = useNavigate("/");

  const logoutClicked = async () => {
    await store.dispatch(logoutUser());
    navigate("/");
  }


  return (
    <div className="narrow-2 padding-top-1">
      <h1>Account</h1>


      <div className="margin-top-1">
        <div onClick={logoutClicked} className="button wide">LOGOUT</div>
      </div>

    </div>
  )
}


export default AccountHome;