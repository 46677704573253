import { andyFetch } from '../../api';


export const createPledgeDriveSponsor = (params) => {

  return async (dispatch, getState) => {

    const url = "club/pledge_drive_sponsors";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPledgeDriveSponsor error.');
      }
    }
    catch (e) {
      console.log("createPledgeDriveSponsor error", e);
    }
  }
};


export const updatePledgeDriveSponsor = (params) => {

  return async (dispatch, getState) => {

    const url = `club/pledge_drive_sponsors/${params.payload.pledge_drive_sponsor.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePledgeDriveSponsor error.');
      }
    }
    catch (e) {
      console.log("updatePledgeDriveSponsor error", e);
    }
  }
};


export const deletePledgeDriveSponsor = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/pledge_drive_sponsors/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePledgeDriveSponsor error.');
      }
    }
    catch (e) {
      console.log("deletePledgeDriveSponsor error", e);
    }
  }
};
