

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAvatarParts } from 'redux/admin/avatarPartActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createAvatarPart, updateAvatarPart, deleteAvatarPart, getAvatarPart} from 'redux/admin/avatarPartActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import  chroma from "chroma-js";

const SVG = ({layer=0, top=0, left=0, scale=100, rotate=0, url, layerType, baseColor='gray'}) => {
  const [color, setColor] = useState('gray');
  
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {

    if (layerType === 'base') {
      setColor(baseColor);
    }
    else if (layerType === 'highlights') {
      setColor(chroma(baseColor).brighten(3));
    }
    else if (layerType === 'shadows') {
      setColor(chroma(baseColor).darken(1.4));
    }
    else if (layerType === 'outline') {
      setColor(chroma(baseColor).darken(3));
    }

  }, [layerType, baseColor, setColor]);

  useEffect(() => {
    if (url) {
      fetch(url)
      .then(res => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))
    }

      
  }, [url]);

  const removeHTML = (str) => { 
    if (!svg || isErrored) return "";
    var tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.firstChild.innerHTML;
  }

  const getViewBox = (str) => {
    if (!svg || isErrored) return "";
    var tmp = document.createElement("div");
    tmp.innerHTML = str;

    console.log(tmp?.firstChild)

    if (tmp && tmp.firstChild && tmp.firstChild.getAttribute("viewBox")) {
      return tmp?.firstChild?.getAttribute("viewBox")
    }
    return "";
  }


  return (
    <>
    {/* <div 
       
        className={`svg svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
        dangerouslySetInnerHTML={{ __html: svg }}
    /> */}

    {svg &&
      <svg 
        viewBox={getViewBox(svg)} 
        fill={color} 
        dangerouslySetInnerHTML={{ __html: removeHTML(svg) }} 
        xmlns="http://www.w3.org/2000/svg"
        style={{
          left: `${left}%`,
          top: `${top}%`,
          width: `${scale}%`,
          transform: `rotate(${rotate}deg)`,
          zIndex: layer+1000, 
        }} 
      >
      </svg>
    }
 


    </>
  )
}

    

export default SVG;