
import React, { useState, useEffect } from 'react';
import logo7 from 'images/logo7.png';
import { Link, Outlet, useLocation, NavLink } from 'react-router-dom';
import store from 'redux/store';
import { connect } from 'react-redux';
import BottomNav from 'screens/BottomNav';


const ProfileBottomNav = ({currentUser}) => {

  const [prefix, setPrefix] = useState('/');

  useEffect(() => {
    if (currentUser.role === "admin") {
      setPrefix('/profile/');
    }
  }, [currentUser]);




  return (
    <div className="mobile-only">
    <BottomNav type="profile">
      <ul>
        <li >
          <NavLink to={prefix}>
            <i className="fas fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/awards`}>
            <i className="fas fa-medal"></i>
            <span>Awards</span>
          </NavLink>
        </li>

        <li >
          <NavLink to={`${prefix}activity`}>
            <i className="fas fa-stopwatch"></i>
            <span>Activities</span>
          </NavLink>
        </li>

        <li >
        <NavLink to={`${prefix}fundraising`}>
            <i className="fas fa-heart"></i>
            <span>Fundraising</span>
          </NavLink>
        </li>

        <li >
        <NavLink to={`${prefix}career`}>
            <i className="fas fa-flag"></i>
            <span>Career</span>
          </NavLink>
        </li>

        

        <li>
          <NavLink to={`${prefix}notifications`}>
            <i className="fas fa-bell"></i>
            <span>Notifications</span>  
          </NavLink>
        </li>


        <li>
          <NavLink to={`${prefix}account`}>
            <i className="fas fa-cog"></i>
            <span>Account</span>
          </NavLink>
        </li>

      </ul>
    </BottomNav>
    </div>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
  };
} 

export default connect(mapState)(ProfileBottomNav);