

import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';

import Ring from 'components/Ring';
import Bar from 'components/Bar';
import coinSVG from 'images/coin.svg';
import 'styles/Profile.scss';

import store from 'redux/store';
import { connect } from 'react-redux';
import { getPledgeStats } from 'redux/parent/athleteActions';
// import EmailConfBanner from 'ParentNoAthletes/EmailConfBanner';
import ParentNoAthletes from './ParentNoAthletes';
import Notis from './Notis';
import SelectProfile from '../profile/SelectProfile';
import ParentBottomNav from 'screens/parent/ParentBottomNav';
import { unsetCurrentAthlete, unauthParent } from '../../redux/session/sessionActions';
import Test3d from 'screens/Test3d';
import PortraitBox from 'screens/PortraitBox';
import { useApi } from '../../api';


const ParentLayout = ({parentAuthed, currentUser, currentAthlete, children}) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [pledgeStats, pledgeStatsLoading, pledgeStatsLoaded, pledgeStatsError, doPledgeStatsFetch, setPledgeStats] = useApi(); 

  useEffect(() => {
    // alert('here')
    fetchPledgeStats();
  }, []);

  const fetchPledgeStats = async () => {
    doPledgeStatsFetch(getPledgeStats);
  };


  useEffect(() => {
    
    if (!currentUser || !["admin", "customer"].includes(currentUser.role)) {
      navigate('/');
    }

    if (currentUser && currentUser.role === "customer" && location.pathname === '/profile') {
      navigate('/');
    }
  }, [currentAthlete]);

  if (!currentUser || !["admin", "customer"].includes(currentUser.role)) {
    return (
      <>
      </>
    )
  }


  if (currentUser && (!currentUser.athletes || currentUser.athletes.length === 0)) {
    return (
      <ParentNoAthletes />
    )
  }


  if (!parentAuthed && !currentAthlete) {
   
    return (
      <SelectProfile />
    )
  }



  

  return (
    <div className="parent" style={{paddingBottom: '400px'}}>

      {/* <EmailConfBanner currentUser={currentUser} /> */}



      {/* <Notis /> */}

      {/* {currentUser && currentUser.role==="admin" &&
        <ParentBottomNav />
      } */}

      <ParentBottomNav />

      
      <div style={{background: 'rgb(203, 240, 255)', position: 'absolute', left: 0, top: '65px', width: '100%', height: '225px'}}>
      </div>



      <div style={{background: ''}} className="relative narrow-2 padding-top-4">
    
          <div className="narrow-2">


            <div className="full flex-cont flex-wrap">

              <div style={{background: ''}} className="profile-avatar-column center column">
                <div className="athlete-avatar-cont margin-bottom-1">
                  <i className="fas fa-user"></i>
                </div>

                  <div className="margin-bottom-2 blue font-1-25 bold">{currentUser.last_name} Family</div>


                  {/* <div className="ghost button">
                    Switch Profile
                  </div> */}
  
              </div>

  

      



            
              <div style={{background: ''}}  className="full profile-right-column relative">

                <div className="full flex-wrap pledge-bar-cont flex-cont justify-center align-center flex-1 margin-bottom-2 margin-top-2">
                          

                  
                  <div className="margin-right-0 blue bold">
                    <div className="font-1-5x">
                      {pledgeStats ? (pledgeStats.earned/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
                    </div>
                    <div className="font-08">EARNED</div>
                  </div>
                  <div className="flex-1 margin-right-1 margin-left-1">
                    <Bar 
                      label={pledgeStats?.pledge_drive_name}
                      height={28} 
                      progress={pledgeStats ? (parseFloat(pledgeStats.earned)/parseFloat(pledgeStats.pledged))*100 : null} 
                      gem="dark-blue-bar" 
                    />
                  </div>
                  <div className="margin-right-0 blue bold">

                    <div className="font-1-5x">
                      {pledgeStats ? (pledgeStats.pledged/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
                    </div>
                    <div className="font-08">PLEDGED</div>
                  </div>
                </div>
                  

                  <div className="profile-content flex-1 full">
                    <div className="full">
                      <div className="flex-cont profile-nav desktop-only">
                        <ul >
                            <li>
                              <NavLink to={`/`}>
                                <i className="fas fa-th-large"></i>
                                <span>Dashboard</span>
                              </NavLink>
                            </li>
                            {/* <li>
                              <NavLink to={`/awards`}>
                                <i className="fas fa-medal"></i>
                                <span>Awards</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={`/activity`}>
                                <i className="fas fa-stopwatch"></i>
                                <span>Activity</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={`/fundraising`}>
                                <i className="fas fa-heart"></i>
                                <span>Fundraising</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={`/career`}>
                                <i className="fas fa-flag"></i>
                                <span>Career</span>
                              </NavLink>
                            </li> */}
                          </ul>
                      </div>
                    </div>

                    {/* <div className='code'>
                      <pre>
                        {JSON.stringify(currentAthlete, null, 2)}
                      </pre>
                    </div> */}
                    <Outlet  context={{}} />
                    {/* {React.cloneElement(children, {setSelectedBadge: setSelectedBadge})} */}
                  </div>
                </div>
        </div>
      </div> 
    </div>

    {/* <div className='margin-top-4 code'>
      <pre>
        {JSON.stringify(currentUser, null, 2)}
      </pre>
    </div> */}
    </div>


  );
};

const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
    parentAuthed: state.sessionState.parentAuthed,
  };
} 

export default connect(mapState)(ParentLayout);