import { andyFetch } from '../../api';




export const getAwardAthletes = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/award_athletes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        
        return {
          json: json,
          fullResponse: response,
        }
        
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};