import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import store from 'redux/store';

import { changePassword, resetPassword, checkResetToken } from 'redux/user/userActions';

import { useApi } from '../api';

import Spinner from 'components/Spinner';
import SubmitButton from 'components/SubmitButton';

const queryString = require('query-string');



const PasswordResetSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
});

const NewPasswordSchema = yup.object().shape({
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
});


export const PWSent = () => {

  return (
    <div className="center really-narrow">
      <h1>Email Sent</h1>

      <div className="margin-top-1">A link to reset your password has been sent to your email.</div>
    </div>
  )
}
export const PWNew = () => {

  const navigate = useNavigate();

  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }

  const { register, errors, handleSubmit } = useForm({
    validationSchema: NewPasswordSchema
  });

  let location = useLocation();
  const params = queryString.parse(location.search);

  const [result, loading, loaded, error, doFetch, setResult] = useApi(); 


  const [checkResult, checkLoading, checkLoaded, checkError, doCheckFetch, setCheckResult] = useApi(); 


  useEffect(() => {
    tryCheckReset();
  }, []);

  useEffect(() => {
    if (checkError) {
      navigate('/password-reset');
    }
  }, [checkError, checkLoaded]);

  useEffect(() => {
    if (loaded) {
      navigate('/password-reset/success');
    }
  }, [loaded]);

  const tryCheckReset = () => {
    doCheckFetch(checkResetToken, {query: {email: params.email, token: params.token}});
  };

  const tryNewPassword = async data => {
    doFetch(changePassword, {query: {email: params.email}, payload: data});
  }

  return (
    <div className="center really-narrow">
      <h1>New Password</h1>

      {/* <div>{JSON.stringify(params)}</div> */}

      {checkLoading &&
        <div className="center spinner"></div>
      }
      {!checkLoading && checkLoaded &&

        <>

          {/* <div>{JSON.stringify(checkLoaded)}</div> */}

          <form onSubmit={handleSubmit(tryNewPassword)}>

            <div className="flex-cont">
              <label htmlFor="password"> 
                <input 
                  type="password" 
                  ref={register}
                  placeholder="Password"
                  name="password"
                />
                {<div className="field-error">{errors?.password?.message}</div>}
              </label>
            </div>
            <div className="flex-cont">   
              <label htmlFor="password_confirmation"> 
                <input 
                  type="password" 
                  ref={register}
                  placeholder="Retype Password"
                  name="password_confirmation"
                />
                {<div className="field-error">{errors?.password_confirmation?.message}</div>}
              </label>
            </div>


            <div className="margin-top-1 center">

              {/* <div>{JSON.stringify(errors)}</div> */}

              <button className="button" disabled={false || loading || hasErrors()} type="submit">
                {loading ? (
                  <Spinner />
                ) : (
                  <span>Submit</span>
                )
                }
                
              </button>
            </div>




        </form>

      </>
      }
    </div>
  )
}
export const PWSuccess = () => {

  const navigate = useNavigate();

  return (
    <div className="center really-narrow">
      <h1>Success</h1>

      <div>
        Your password has been successfully changed. You can now login with your new password.
      </div>
      <div>
        <div onClick={() => navigate('/login')} className="margin-top-2 button">Login</div>
      </div>
    </div>
  )
}



const PasswordReset = ({currentUser}) => {

  const navigate = useNavigate();

  const { register, errors, handleSubmit } = useForm({
    validationSchema: PasswordResetSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [result2, loading2, loaded2, error2, doFetch2, setResult2] = useApi(); 

  let location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (currentUser) {
      navigate(`/`);
    }
  }, [])



  const tryPasswordReset = async data => {
    doFetch(resetPassword, {query: {email: params.email}, payload: data});
  }

  useEffect(() => {
    if (loaded) {
      navigate(`/password-reset/sent`);
    }
  }, [loaded, navigate, currentUser]);





  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }



  // if (loading) {
  //   return <Spinner />
  // }

  // if (loading2) {
  //   return <Spinner />
  // }

  
  return(
    <div className="center really-narrow">
      <h1>Password Reset</h1>
      {/* {loading &&
        <Spinner />
      }
      {loading2 &&
        <Spinner />
      } */}
      {/* <div>{JSON.stringify(currentUser)}</div> */}

      <div>
        <form onSubmit={handleSubmit(tryPasswordReset)}>

          <div className="flex-cont">
            <label htmlFor="email"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Email"
                name="email"
              />
              {errors.email && <div className="field-error">{errors.email.message}</div>}
            </label>
          </div>


          <div className="margin-top-1 center">

            {/* <div>{JSON.stringify(errors)}</div> */}

            <button className="button" disabled={false || loading || hasErrors()} type="submit">
              {loading ? (
                <Spinner />
              ) : (
                <span>Submit</span>
              )
              }
              
            </button>
          </div>


        </form>
      </div>
    </div>
    
  )
};

const mapState = state => ({
  currentUser: state.sessionState.currentUser,
});

export default connect(mapState)(PasswordReset);