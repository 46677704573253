

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getRunThemes } from 'redux/admin/runThemeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createRunTheme, updateRunTheme, deleteRunTheme, getRunTheme} from 'redux/admin/runThemeActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'



import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';


const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})




const RunThemeSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
});




export const RunThemeForm = ({toggleModal, currentRunTheme}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: RunThemeSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [runThemesResult, runThemesLoading, runThemesLoaded, runThemesError, doFetchRunThemes, setRunThemeResults] = useApi();


  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);


  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);

  const onSubmit = async data => {
    let d = {
      run_theme: {
        ...data
      }
    }

    
    if (currentRunTheme && currentRunTheme.id) {
      // alert('here')
      doFetch(updateRunTheme, {payload: d});
    }
    else {
      // alert('here 2')
      doFetch(createRunTheme, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);


  const getAvatarURL = () => {
    return fileURL ? fileURL : currentRunTheme?.avatar_url
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentRunTheme ? 'Edit' : 'New'} Run Theme</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        <div>
          <pre>
            {JSON.stringify(errors, null, 2)}
          </pre>
        </div>


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentRunTheme?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentRunTheme?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>


          <div className="flex-cont">
            <label htmlFor="description"> 
              <textarea 
                type="text" 
                ref={register}
                placeholder="Description"
                name="description"
                defaultValue={currentRunTheme?.description}
              />
              {errors.description && <div className="field-error">{errors.description.message}</div>}
            </label>
          </div>

        <div className="margin-top-1 card uppy-dd-cont">
          <div className="avatar-cont margin-bottom-2" style={{backgroundImage: `url(${getAvatarURL()})`}}>
            {!getAvatarURL() &&
              <i className="fas fa-school"></i>
            }
            {uploadLoading ? (
                <div className="flex-cont align-center justify-center" style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.6)', position: 'absolute'}}>
                  <div className="spinner"></div>
                </div>
              ) : (
                <div style={{fontSize: '1rem'}} className="hover-overlay">
                  EDIT
                </div>
              )
            }
          </div>

          <br/>

          <div className="button ghost">Edit Run Theme Image</div>

          <input ref={register} type="hidden" name="avatar" value={file} />
          <DragDrop
            className="andy"
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: 'Drop here or %{browse}',
                browse: 'browse'
              }
            }}
          />
        </div>
        <StatusBar uppy={uppy} />
        <div className="field-error">
          {errors?.avatar?.message}
        </div>


      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}


const AdminRunThemes = ({currentUser}) => {

  let { id } = useParams();

  const [runThemes, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedRunTheme, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedRunTheme] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingRunTheme, setEditingRunTheme] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchRunThemes();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingRunTheme(null);
    }
  }, [modalOpen])

  useEffect(() => {
    // salert(id);

      fetchRunThemes();
    
  }, [id]);


  const fetchRunThemes = () => {
    //  alert('here');
    doFetch(getRunThemes, {query: {tab: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRunTheme, setDeletingRunTheme] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingRunTheme, setViewingRunTheme] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchRunThemes();
    }
  }


  const editClicked = runTheme => {
    setEditingRunTheme(runTheme);
    toggleModal();
  }

  const deleteClicked = runTheme => {
    setDeletingRunTheme(runTheme);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingRunTheme) {
      doDeleteFetch(deleteRunTheme, {query: {id: deletingRunTheme.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  const viewClicked = (runTheme) => {
    setViewingRunTheme(runTheme);
    toggleViewModal();
  };

  const toggleViewModal = () => {
    setViewModalOpen(o => !o);
  }



  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <RunThemeForm currentRunTheme={editingRunTheme} toggleModal={toggleModal} />
        </Modal>
      }



      

      <div className="flex-cont">
        <div className="flex-1"><h1>Run Themes</h1></div>
        <div><div onClick={toggleModal} className="button">New Run Theme</div></div>
      </div>


      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink exact to={`/runThemes`}>All</NavLink></li>
        </ul>
      </div>

      




      {loading &&
        <div className="spinner"></div>
      }

      {runThemes &&
        <table className="basic">
          {runThemes.map(runTheme => {
            return (
              <tr>
                {/* <td className="fit nowrap">
                  <div className="ghost button">
                    <i className="fas fa-eye"></i>
                  </div>
                </td> */}

                <td className="fit nowrap">
                  <Link to={`/runThemes/view/${runTheme.id}`}>
                    <div style={{fontSize: '3rem', backgroundImage: `url( ${runTheme?.avatar_url} )`}} className="avatar-cont small">
                    </div>
                  </Link>
                </td>

                <td className="bold fit nowrap">
                  <Link className="text" to={`/runThemes/view/${runTheme.id}`}>
                    {runTheme.name}
                  </Link>
                </td>



                <td></td>




                <td className="fit nowrap">
                  <div onClick={() => editClicked(runTheme)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(runTheme)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table> 
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete RunTheme</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingRunTheme?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {viewModalOpen &&
        <Modal toggleModal={toggleViewModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">{viewingRunTheme?.name}</h1>
              <div onClick={toggleViewModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">
            <h2>Charities</h2>
            <div className="margin-bottom-1"></div>
            {viewingRunTheme.charities &&
              <table className="basic">
                {viewingRunTheme.charities.map(charity => 
                  <tr>
                    <td className="fit nowrap">
                      <Link to={`/charities/${charity.id}`}></Link>
                      {charity.name}
                    </td>
                  </tr>
                )}
              </table>
            }

            {!viewingRunTheme.charities &&
              <em>No charities.</em>
            }
          </div>

        </Modal>
      }

      {!loading && !runThemes &&
        <em>No runThemes.</em>
      }


      


    </>
  )
}



export default AdminRunThemes;