

import React, { useEffect } from 'react';





const AdminPurchases = ({currentUser}) => {

  return (
    <h1>Purchases</h1>
  )
}


export default AdminPurchases;