import React from 'react';
import '../styles/Modal.scss';
import FocusTrap from 'focus-trap-react';
import { useEffect } from 'react';

const Modal = ({fixedContent=null, overflow='', bgColor="", size="medium", focusTrap=true, toggleModal, children}) => {

  const ttt = () => {
    // alert('ttt')
    toggleModal();
  }
  
  useEffect(() => {
    // alert(JSON.stringify(toggleModal, null, 2))
    console.log('toggleModal', toggleModal);
  }, [toggleModal]);


  const Wrapper = focusTrap ? FocusTrap : React.Fragment




  return(
    <>

    <Wrapper allowOutsideClick={true}>
      <div>
      <div onClick={ttt} className={`modal-bg bg-${bgColor}`}>
      </div>
      <div className="modal-cont">
        {fixedContent}
        <div className={`modal-content ${size} overflow-${overflow}`}>
          <div className="modal-inner-content">

            
            <div>
              {children}
            </div>
 
          
          </div>
        </div>
        </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Modal;