


const initialState = {
  existingLaps: []
};

const raceReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "ADD_LAP":
      return {
        ...state,
        existingLaps: [action.lap, ...state.existingLaps, ]
      }
    default :
      return state
  }
}

export default raceReducer;