import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { useApi } from '../api';
import { createUser } from 'redux/user/userActions';
import { connect } from 'react-redux';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';
import Spinner from 'components/Spinner';

const SignupSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});

const Join = ({currentUser}) => {

  let navigate = useNavigate();
  let params = useParams();

  const [meas, setMeas] = useState('metric');
  const [vvv, setVvv] = useState();


  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: SignupSchema
  });

  const [loggedInUser, loading, loaded, error, doFetch, setResult] = useApi();


  const onSubmit = async data => {
    // const response = await Api(session, `${process.env.REACT_APP_API_URL}/signup`, {
    //   verb: 'post',
    //   params: data
    // })
    
    // session.loginUser(response);
    // navigate('/profile');
    // history.push("/profile");

    // alert('here 0')

    let d = {
      user: {
        ...data
      }
    }


    doFetch(createUser, {payload: d});
    
  }

  useEffect(() => {

    // alert(loaded);
    if (currentUser && loaded) {
      // navigate(`/profile`);
      // history.push("/profile");
      

      navigate(`/join/athlete-setup`);
    }
  }, [loaded]);

  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }

  if (currentUser) {
    return(
      <div className="full center margin-top-2">
        <Spinner />
      </div>
    )
  }



  
  return(
    <div className="flex-1 really-narrow margin-top-2">
      <h1 className="center">Join</h1>
      {/* <div className="margin-bottom-3 center gray">Step 1 of 3</div> */}

      <form onSubmit={handleSubmit(onSubmit)}>


        <div className="flex-cont">
          <label htmlFor="email"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Email"
              name="email"
              defaultValue={`${Math.random().toString(36).substring(7)}@example.com`}
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>
        </div>
        
        <div className="flex-cont">
          <label htmlFor="firstName"> 
            <input 
              type="text" 
              ref={register}
              placeholder="First Name"
              name="first_name" 
              defaultValue={Math.random().toString(36).substring(7)}
            />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="lastName"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Last Name"
              name="last_name"
              defaultValue={Math.random().toString(36).substring(7)}
            />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="password"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Password"
              name="password"
              defaultValue="111111"
            />
            {errors.password && <div className="field-error">{errors.password.message}</div>}
          </label>
          <label htmlFor="password_confirmation"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Retype Password"
              name="password_confirmation"
              defaultValue="111111"
            />
            {errors.password_confirmation && <div className="field-error">{errors.password_confirmation.message}</div>}
          </label>
        </div>

        <div className="margin-top-2 center">
          {/* <input type="submit" value="Continue" /> */}

          <button className="button" disabled={false || loading || hasErrors()} type="submit">
            {loading ? (
              <div className="spinner"></div>
            ) : (
              <span>Continue</span>
            )
            }
            
          </button>
        </div>

        <div className="flex-cont justify-center align-center margin-top-2 center">
          Already have an account? 
          <div onClick={() => navigate('/login')} className="margin-left-05 ghost button">Login</div>
        </div>
        
      </form>
    </div>
    
  )
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(Join);
