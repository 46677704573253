

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'

import { createClubUser} from 'redux/club/clubActions';
import { getClubOwnerships} from 'redux/club/clubOwnershipActions';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"
import { resetIdCounter } from 'downshift';
import ClubStaffForm from '../staff/ClubStaffForm';

const UserSchema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  role: yup.string(),
});

const OnboardingSuccessRoster = ({currentClub}) => {

  const navigate = useNavigate();

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [result2, loading2, loaded2, error2, doFetch2, setResult2] = useApi();

  const { reset, watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: UserSchema
  });

  useEffect(() => {
    fetchClubOwnerships();
  }, []);

  const fetchClubOwnerships = () => {
    doFetch2(getClubOwnerships);
  }

  useEffect(() => {
    if (loaded) {
      fetchClubOwnerships();
      reset()
    }
  }, [loaded]);


  const onSubmit = (data) => {
    const payload = {
      club_user: {
        ...data
      }
    }
    doFetch(createClubUser, {payload: payload})
  }

  
  return(
    <>
      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">2</div>
        <h1 className="no-margin-bottom">Faculty and Staff</h1>
        <h2 className="no-margin-top">Invite faculty staff members</h2>
        


        <ul>
          <li>
            <strong>School Admins</strong> have full access to all aspects of the school's Hero Run account, including financial information.
          </li>
          <li>
            <strong>Team Admins</strong> can view and manage competitions and view class/team leaderboards. They can be a teacher or parent.
          </li>
          <li>
            <strong>PE Teachers</strong> can start activities and have access to fitness stats for all students.
          </li>
          <li>
            <strong>Run Monitors</strong> can start free runs. 
          </li>
        </ul>

        


        <div className="card no-hover margin-top-3">
          <h3 className="no-margin">Invite Additional Staff</h3>

          <ClubStaffForm redirectPath="/" />
          {/* <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-cont flex-wrap align-center">
            <label htmlFor="">
              <span className="label-text">Role</span>
              <select name="role" ref={register}>
                {["Administrator", "Teacher", "Monitor"].map(role => 
                  <option value={role.toLowerCase()}>{role}</option>
                )}
              </select>
              {errors.role && <div className="field-error">{errors.role.message}</div>}
            </label>
            <label htmlFor="">
              <span className="label-text">First Name</span>
              <input ref={register}  type="text" name="first_name" id=""/>
              {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
            </label>
            <label htmlFor="">
              <span className="label-text">Last Name</span>
              <input ref={register}  type="text" name="last_name" id=""/>
              {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
            </label>
            <label htmlFor="">
              <span className="label-text">Email</span>
              <input ref={register}  type="text" name="email" id=""/>
              {errors.email && <div className="field-error">{errors.email.message}</div>}
            </label>
            

            {loading && <div className="spinner"></div>}
            {!loading &&
              <button disabled={loading} type="submit" className="button">
                <i className="fas fa-plus"></i>
              </button>
            }
          </div>
          </form> */}
        </div>

        <div className="margin-top-3"></div>
        {loading2 && <div className="spinner"></div>}
  
        <div className="card no-hover margin-top-1">

          <h3 className="no-margin">Current Staff</h3>
          <table className="basic">
            <tr>
              <th>Role</th>
              <th  className="fit nowrap">First Name</th>
              <th  className="fit nowrap">Last Name</th>
              <th>Email</th>
            </tr>
            {result2?.map(cu => 
              <tr>
                <td className="fit nowrap">
                  <div className={`role-cont ${cu.role}`}>
                    {cu.friendly_role?.toUpperCase()}
                  </div>
                </td>
                <td className="fit nowrap">{cu.first_name}</td>
                <td className="fit nowrap">{cu.last_name}</td>
                <td>{cu.email}</td>
              </tr>
            )}
          </table>
        </div>
        

        
        {/* <div className="code">
          <pre>
            {JSON.stringify(result2, null, 2)}
          </pre>
        </div> */}
      </div>

     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingSuccessRoster);