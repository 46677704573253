import { andyFetch } from '../../api';

export const getAvatarParts = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/avatar_parts";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAvatarParts error", e);
    }
  }
};



export const getAvatarPart = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/avatar_parts/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAvatarPart error", e);
    }
  }
};


export const createAvatarPart = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/avatar_parts";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createAvatarPart error.');
      }
    }
    catch (e) {
      console.log("createAvatarPart error", e);
    }
  }
};


export const updateAvatarPart = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/avatar_parts/${params.payload.avatar_part.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAvatarPart error.');
      }
    }
    catch (e) {
      console.log("updateAvatarPart error", e);
    }
  }
};


export const deleteAvatarPart = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/avatar_parts/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAvatarPart error.');
      }
    }
    catch (e) {
      console.log("deleteAvatarPart error", e);
    }
  }
};

