

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getMerchProducts } from 'redux/admin/merchProductActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { createMerchProduct, updateMerchProduct, deleteMerchProduct } from 'redux/admin/merchProductActions';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../../api'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput, useUppy } from '@uppy/react'
import { useEditor, EditorDescription } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Masonry from 'react-masonry-css'
import MerchProductCard from './MerchProductCard'

const MerchProductSchema = yup.object().shape({
  name: yup.string(),
  description: yup.string(),
});


const MerchProductForm = ({toggleModal, currentMerchProduct}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    // validationSchema: MerchProductSchema
  });

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);
  // const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [name, setName] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    description: "",
  })

  const uppy = useUppy(() => {
    return new Uppy({
      // meta: { type: 'image' },
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.png', '.jpg', '.jpeg']
      }
    }).use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
  })

  useEffect(() => {
    
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy]);
  


  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [merchProductsResult, merchProductsLoading, merchProductsLoaded, merchProductsError, doFetchMerchProducts, setMerchProductResults] = useApi();

  const onSubmit = async data => {
    let d = {
      merch_product: {
        ...data
      }
    }


    
    if (currentMerchProduct && currentMerchProduct.id) {
      doFetch(updateMerchProduct, {payload: d});

    }
    else {
      doFetch(createMerchProduct, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const finalUrl = fileURL ? fileURL : currentMerchProduct?.featured_image_url;
  const featuredImage = currentMerchProduct?.merch_product_images?.[0];

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const deleteClicked = () => {
    toggleDeleteModal();
  }
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteMerchProduct, {query: {id: currentMerchProduct.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
    
  }, [deleteLoaded])

  return (
    <>
    {deleteModalOpened &&
      <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
        <div className="padding-1">
          Are you sure you want to delete this?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className="button red">
            Delete
          </div>
        </div>
      </Modal>
    }
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentMerchProduct ? 'Edit' : 'New'} Merch Product</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentMerchProduct?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              onChange={e => setName(e.target.value)}
              defaultValue={currentMerchProduct?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        {/* <EditorDescription editor={editor} /> */}

        <div className="flex-cont">
          <label htmlFor="description"> 
            <textarea 
              ref={register}
              placeholder="Description"
              name="description"
              defaultValue={currentMerchProduct?.description}
            />
            {errors.description && <div className="field-error">{errors.description.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label className="flex-cont align-center" htmlFor="price"> 
            <div className="bold">$</div>
            <input 
              type="number" 
              min={0}
              step={0.01}
              ref={register}
              placeholder="Price"
              className="auto"
              name="price"
              defaultValue={currentMerchProduct?.price}
            />
            {errors.price && <div className="field-error">{errors.price.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          {uppy && 
            <>
              <FileInput 
                uppy={uppy} 
                className="outline button"
                inputName={"merch_product_images_attributes[][image]"}
                locale={{
                  strings: {
                    chooseFiles: "Choose image"
                  }
                }}
              />
              <StatusBar uppy={uppy} />
            </>
          }
        </div>


        <div>
          <MerchProductCard showTools={false} name={name} imageUrl={finalUrl} />
        </div>



        <input ref={register} type="hidden" name="merch_product_images_attributes.0.image" value={file} />
        <input ref={register} type="hidden" name="merch_product_images_attributes.0.id" value={featuredImage?.id} />
      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        <div className="flex-1"></div>

        {currentMerchProduct &&
          <div onClick={deleteClicked} className="button red">
            Delete
          </div>
        }
      </div>

    </form>
    </>
  )

}



export default MerchProductForm;