import React, { useEffect, useState } from 'react';

import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useApi } from '../../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubStaffNav from 'screens/club/staff/ClubStaffNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';


const ClubStaffLayout = ({currentClub, currentUser, children}) => {

  return (

    <div className="narrow-2x padding-top-1">

      <div className="flex-cont align-center">
        
        <div className="flex-1">
          <h1>Staff</h1>
        </div>
        <div>
          <Link to={`/staff/new`} className="button">Invite Staff Members</Link>
        </div>
        
      </div>
      

    
      <div className="margin-bottom-2">
        <ClubStaffNav />
      </div>
      

      {children}


      {/* <div className="parent">
        <div className="content-cont flex-cont">

          <div className="narrow-2 content padding-top-1">
            {children}
          </div>

        </div>
      </div> */}
      

    </div>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ClubStaffLayout);