
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { useForm } from 'react-hook-form';
import * as yup from "yup";

import { Link } from 'react-router-dom';

import { useApi } from '../../api';

import { updateMyClub, getMyClub } from 'redux/club/clubActions';
import { getCurrentPledgeDrive } from 'redux/club/pledgeDriveActions';

import { API_URL } from '../../api.js';
import { DateTime, Settings } from 'luxon'
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';



const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const ClubSchema = yup.object().shape({
  // name: yup.string().required("Name is required."),
  avatar: yup.string(),
  // .required("Please upload a .CSV file."),
});


const ClubForm = ({currentClub, toggleModal}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: ClubSchema
  });

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const [updatedClub, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);

  const onSubmit = async data => {
    const d = {
      club: data
    };


    doFetch(updateMyClub, {payload: d});
    toggleModal();
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);



  const getAvatarURL = () => {
    return fileURL ? fileURL : currentClub?.avatar_url
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">Edit Club</h2>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1 padding-bottom-200px">

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentClub?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        <div className="flex-cont flex-wrap">
          <label htmlFor="email"> 
            <span>Email</span>
            <input 
              type="text" 
              ref={register}
              name="email"
              defaultValue={currentClub?.email}
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>

          <label htmlFor="phone"> 
            <span>Phone</span>
            <input 
              type="text" 
              ref={register}
              name="phone"
              defaultValue={currentClub?.phone}
            />
            {errors.phone && <div className="field-error">{errors.phone.message}</div>}
          </label>
        </div>

          <div className="flex-cont">
            <label htmlFor="street"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Street"
                name="street"
                defaultValue={currentClub?.street}
              />
              {errors.street && <div className="field-error">{errors.street.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="city"> 
              <input 
                type="text" 
                ref={register}
                placeholder="City"
                name="city"
                defaultValue={currentClub?.city}
              />
              {errors.city && <div className="field-error">{errors.city.message}</div>}
            </label>
            <label htmlFor="state"> 
              <input 
                type="text" 
                ref={register}
                placeholder="State"
                name="state"
                defaultValue={currentClub?.state}
              />
              {errors.state && <div className="field-error">{errors.state.message}</div>}
            </label>
            <label htmlFor="zipcode"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Zipcode"
                name="zipcode"
                defaultValue={currentClub?.zipcode}
              />
              {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
            </label>
          </div>


        <div className="margin-top-1 card uppy-dd-cont">
          <div className="avatar-cont margin-bottom-2" style={{backgroundImage: `url(${getAvatarURL()})`}}>
            {!getAvatarURL() &&
              <i className="fas fa-school"></i>
            }
            {uploadLoading ? (
                <div className="flex-cont align-center justify-center" style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.6)', position: 'absolute'}}>
                  <div className="spinner"></div>
                </div>
              ) : (
                <div style={{fontSize: '1rem'}} className="hover-overlay">
                  EDIT
                </div>
              )
            }
          </div>

          <br/>

          <div className="button ghost">Edit Club Image</div>

          <input ref={register} type="hidden" name="avatar" value={file} />
          <DragDrop
            className="andy"
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: 'Drop here or %{browse}',
                browse: 'browse'
              }
            }}
          />
        </div>
        <StatusBar uppy={uppy} />
        <div className="field-error">
          {errors?.avatar?.message}
        </div>


      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  );
  
}

export default ClubForm;