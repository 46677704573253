import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPledgeDrive, changeAthlete } from 'redux/club/pledgeDriveActions';
import { DateTime } from 'luxon';

const ClubSchoolYear = ({schoolYear, schoolYearLoading}) => {

 
  return (
    <>

    {schoolYearLoading && <div className="spinner"></div> }

    {schoolYear &&

      <table className="vertical">
        <tr>
          <th>Material Fee</th>
          <td>
            {schoolYear?.fee_amount != null ? parseFloat(schoolYear.fee_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"} per student
          </td>
        </tr>
        <tr>
          <th>Paid</th>
          <td>
            {schoolYear.total_paid_count} / {schoolYear.total_student_count} students have paid 
          </td>
        </tr>
        <tr>
          <th>Total Collected</th>
          <td>
            {parseFloat(schoolYear.total_paid_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / {parseFloat(schoolYear.total_fee_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
          </td>
        </tr>

        <tr><td></td><td></td></tr>

        <tr>
          <th>Start</th>
          <td>
            {schoolYear.start_datetime ? DateTime.fromISO(schoolYear.start_datetime).toLocaleString(DateTime.DATE_MED) : "-"}
          </td>
        </tr>
        <tr>
          <th>End</th>
          <td>
            {schoolYear.end_datetime ? DateTime.fromISO(schoolYear.end_datetime).toLocaleString(DateTime.DATE_MED) : "-"}
          </td>
        </tr>
      </table>
    }
{/* 
{!schoolYearSubsLoading && schoolYearSubs &&
        <table className="basic">
          <th className='fit nowrap'>Student</th>
          <th className='fit nowrap'>Material Fee</th>
          <th className='fit nowrap'>Amount Paid</th>
          <th></th>
          <th></th>
          {schoolYearSubs.map(schoolYearSub => {
            return (
              <tr>
                <td className="bold fit nowrap">{schoolYearSub.athlete_name}</td>
                <td className="fit nowrap">
                  {parseFloat(schoolYearSub.material_fee).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td className="fit nowrap">
                  {parseFloat(schoolYearSub.material_fee_paid_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </td>
                <td>
                  {schoolYearSub.material_fee_is_paid ? (
                      <div style={{color: 'green'}} className='bold'>
                        PAID
                      </div>
                    ) : (
                      <div style={{color: 'red'}} className='bold'>
                        NOT PAID
                      </div>
                    )
                  }
                  
                  </td>
                <td className="fit nowrap">
                </td>
              </tr>
            )
          })}
        </table>
      }

     

      {!schoolYearSubsLoading && (!schoolYearSubs || schoolYearSubs.length === 0) &&
        <em>No results.</em>
      } */}

      {/* <pre>
        {JSON.stringify(schoolYear, null, 2)}
      </pre> */}
    </>
  )

}

export default ClubSchoolYear;