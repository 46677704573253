

import React, { useEffect, useState } from 'react';
import { getCurrentPledgeDrive } from '../../../redux/club/pledgeDriveActions';
import { useApi } from '../../../api';
import { Link } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import { AreaChart, ComposedChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {getMileTimes, getAthleteMileProgression, getAthleteMileTimes} from '../../../redux/club/statsActions';

 
// import Chart from './Chart';
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <div className="">{`${label}`}</div>
        <div className="bold margin-top-half">{Duration.fromMillis(payload[0].value*1000.00).toFormat('mm:ss')}</div>
      </div>
    );
  }

  return null;
};

const AthleteChart = ({mileData}) => {
  const demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  if (!mileData) return <></>;

  return (
    <>
      
      
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={mileData}
          margin={{ top: 8, left: 8, right:8, bottom: 8}}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#0075B3" stopOpacity={0.5}/>
              <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0.1}/>
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} stroke="#DDD" />
          <XAxis 
            dataKey="name" 
            tickFormatter={value => ""} 
            width={0}
            height={0}
          />
          <YAxis 
            domain={[240, 'auto']} 
            // tickFormatter={value => ""}
            // tickMargin={0}
            // tick={false}
            // margin={null} 
            axisLine={false}
            // padding={{left: 0, right: 0, top: 0, bottom: 0}}
            width={0}
            height={0}
          /> 
          <Tooltip 
            content={<CustomTooltip />}
            // formatter={value => Duration.fromMillis(value*1000.00).toFormat('mm:ss')}
            // formatter={(value, name, props) => ["formatted value", "formatted name"] 
          />  
          {/* <Legend /> */}
          <Area 
            type="monotone" 
            dataKey="time" 
            stroke={false} 
            strokeWidth={2} 
            fillOpacity={1} 
            fill="url(#colorUv)" 
            isAnimationActive={false}
          />
          <Line 
            type="monotone" 
            dataKey="time" 
            strokeWidth={6} 
            stroke="#0075B3" 
            dot={false} 
            legendType="none"
            activeDot={{ r: 8 }} 
            strokeLinecap="round"
            isAnimationActive={false}
          />
          
        </ComposedChart>
        
      </ResponsiveContainer>

    </>
  );

}

const AthleteCard = ({stat, index}) => {

  // const [stats, loading, loaded, error, doFetch, setResult] = useApi(); 

  // const athleteID = stat?.athlete_id;

  // useEffect(() => {
  //   doFetch(getAthleteMileProgression, {query: {athlete_id: athleteID}});
  // }, []);



  return (
    <div className="flex-1 card margin-bottom-1 no-hover margin-right-1">
      <div className="flex-cont">
        <div className="margin-right-2">
          <div className="font-weight-300 font-2 blue">
            #{index+1}
          </div>
          <div className="bold">{stat.athlete_name}</div>

          <div>{Duration.fromMillis(stat.last*1000.00).toFormat('mm:ss')}</div>
        </div>

        <div style={{minWidth: '300px', height: '100px'}}>
          {/* <div>
            <pre>
              {JSON.stringify(stats, null, 2)}
            </pre>
          </div> */}
          {/* <div>{stat.athlete_id}</div> */}
          {/* {loading && <div className="spinner"></div> } */}
          {stat?.data &&
            <AthleteChart mileData={stat?.data} />
          }
          
        </div>
      </div>
      
    </div>
  )
}


const ClubStatsAthletes = ({currentUser}) => {

  const [stats, loading, loaded, error, doFetch, setResult] = useApi(); 

  const [currentCampaign, currentCampaignLoading, currentCampaignLoaded, currentCampaignError, doCurrentCampaignFetch, setCurrentCampaignResult] = useApi();

  useEffect(() => {
    doCurrentCampaignFetch(getCurrentPledgeDrive);
    doFetch(getAthleteMileTimes);
  }, []);

  return (
    <>
      <div className="narrow-2x padding-top-1x">


        <h1>Student Stats</h1>

        {currentCampaignLoading && <div className="spinner"></div> }

        {currentCampaign &&
          <div>
            <div className="">
              <div className="text-link font-1-5">
                {currentCampaign?.name}
              </div>
            </div>
            <div className="bold no-margin margin-bottom-1" >
              {DateTime.fromISO(currentCampaign.start_date).toLocaleString(DateTime.DATE_FULL)} - {DateTime.fromISO(currentCampaign.end_date).toLocaleString(DateTime.DATE_FULL)}
            </div>
          </div>
        }

        {/* <div>
          <pre>
            {JSON.stringify(stats, null, 2)}
          </pre>
        </div> */}

        <div className="margin-top-3" >
          {/* <Chart mileData={stats} /> */}

          {loading &&
            <div className="spinner"></div>
          }
          <div className="flex-cont flex-wrap">
            {stats?.map((stat, index) => 
              <AthleteCard stat={stat} index={index} />
            )}
          </div>
        </div>
        


        

      

      </div>
    </>
  )
}


export default ClubStatsAthletes;