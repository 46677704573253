export const addError = (msg) => {
  return {
    type: 'ADD_GLOBAL_ERROR',
    msg
  }
}

export const removeError = (i) => {
  return {
    type: 'REMOVE_GLOBAL_ERROR',
    i
  }
}

export const toggleStartRace = (raceType) => {
  return {
    type: 'TOGGLE_START_RACE',
    raceType
  }
}


export const setRaceIntervalID = (id) => {
  return {
    type: 'SET_RACE_INTERVAL_ID',
    id
  }
}

export const clearRaceIntervalID = () => {
  return {
    type: 'CLEAR_RACE_INTERVAL_ID',
  }
}