

import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { getDashboardStats } from 'redux/parent/athleteActions';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';

import { changeAthlete, authParent } from '../../redux/session/sessionActions';
import Ring from 'components/Ring';
import Bar from 'components/Bar';
import CountUp from 'components/CountUp';
import coinSVG from 'images/coin.svg';
import { unsetCurrentAthlete, unauthParent } from 'redux/session/sessionActions';

import { useVisibility } from 'components/useVisibility';

import SharingCard from '../profile/SharingCard';
import ShareModal from '../profile/ShareModal';
import MaterialFeeBox from 'screens/profile/MaterialFeeBox';

const Box = ({progress}) => {

  const [startCount, setStartCount] = useState(false);
  const { currentElement, isVisible } = useVisibility(-100);

  useEffect(() => {
    // alert(isVisible);
    if (isVisible && !startCount) {
      setStartCount(true);
    } 
  }, [isVisible]);


  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (startCount) {

      const interval = setInterval(() => {

        // console.log('counter', counter);
        // console.log('progress', progress);



          setCounter(counter => {

            if (counter < progress) {
              return(counter + 1);
            }
            else {
              clearInterval(interval);
              return(counter);
            }
            
          });
      }, 1800/progress);

      return () => {
        // alert('here');
        clearInterval(interval);
      };
    }
  }, [startCount]);

  return (
    <div ref={currentElement} className="align-center justify-center card span-1-v grid-item night box flex-column">
      <div className="margin-top-1 moon ring-cont">
        <Ring radius={90} stroke={10} progress={progress} />
      </div>

      

      <div className="margin-top-2 number white-text">{counter}%</div>
      <div className="number-label white-text">around the moon</div>
    </div>
  )
}

const NewBox = ({athlete}) => {

  const [flipped, setFlipped] = useState(false);


  return (
    <div onClick={() => setFlipped(f => !f)} className={`flippable ${flipped ? 'flipped' : ''} align-center justify-center grid-item span-1-v box`}>
      <div className="box-face box-face-front">
        <div className="number">{athlete ? athlete.total_laps : "-"}</div>
        <div className="number-label">LAPS</div>
      </div>
      <div className="box-face box-face-back"><div className="number">LAPS</div></div>
    </div>
  )
}

const ProfileHome = ({currentUser}) => {
  let location = useLocation();
  let background = location.state && location.state.background;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [raceLogs, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [athlete, athleteLoading, athleteLoaded, athleteError, doAthleteFetch, setAthlete] = useApi(); 

  const [dashboardStats, dashboardStatsLoading, dashboardStatsLoaded, dashboardStatsError, doDashboardStatsFetch, setDashboardStats] = useApi(); 

  const navigate = useNavigate("/");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);


  const fetchDashboardStats = async () => {
    doDashboardStatsFetch(getDashboardStats);
  };


  const athleteClicked = (athleteID) => {
    // setSelectedAthlete(athlete);
    // alert('here')
    const data = {
      athlete_id: athleteID
    }
    doAthleteFetch(changeAthlete, {payload: data});
  }

  useEffect(() => {
    if (athleteLoaded) {
      // store.dispatch(unsetCurrentAthlete())
      store.dispatch(unauthParent())
    }
  }, [athleteLoaded])

  // const switchProfileClicked = (athleteID) => {
  //   store.dispatch(unsetCurrentAthlete())
  //   store.dispatch(unauthParent())
  // }
  const [shareModalOpened, setShareModalOpened] = useState(false);

  const toggleShareModal = () => {
    setShareModalOpened(o => !o);
  }


  return (
    <>
      {shareModalOpened &&
        <ShareModal toggleModal={toggleShareModal} />
      }

      
      <MaterialFeeBox />



      <div className="margin-top-3"></div>
      {dashboardStatsLoading && <div className="spinner"></div> }

      {dashboardStats?.pledge_stats?.map(stat => {


        return (
          <div onClick={() => athleteClicked(stat.athlete_id)} className="card pointer">

            <div className="margin-bottom-1 flex-cont align-center">
              <div className="bold font-1-5 flex-1x margin-right-1">
                {stat.name}
              </div>
              <div className="flex-cont align-center margin-right-half">
                <img className="margin-right-half" style={{paddingTop: '4px', width: '1em'}} src={coinSVG} alt="" />
                <div className="bold gold font-1-25">
                  {stat.coins}
                </div>
              </div>
              <div className="flex-1">

              </div>
              <div>
                <i className="gray fas fa-chevron-right">

                </i>
              </div>
            </div>
            


            <Bar progress={(parseFloat(stat.current_pledge_drive_sub?.cents_earned) / parseFloat(stat.current_pledge_drive_sub?.cents_max_pledged)) * 100.00} />


            <div className="flex-cont font-075 bold blue margin-top-05">
              <div>
                {stat.current_pledge_drive_sub?.dollars_earned}
              </div>
              <div className="flex-1"></div>
              <div>
                {stat.current_pledge_drive_sub?.dollars_pledged}
              </div>
            </div>

            {stat.current_pledge_drive_sub &&
              <div className="flex-cont align-center margin-top-1">
                <div className="flex-1 border-right center padding-1">
                  <div className="font-2 thin">
                    {stat.current_pledge_drive_sub?.total_miles}
                  </div>
                  <div className="bold gray font-075 margin-top-05">MILES</div>
                </div>
                <div className="flex-1 border-right  center padding-1">
                  <div className="font-2 thin">
                    {stat.current_pledge_drive_sub?.total_seconds}
                  </div>
                  <div className="bold gray font-075 margin-top-05">TIME</div>
                </div>
                <div className="flex-1 center padding-1">
                  <div className="font-2 thin">
                    {stat.current_pledge_drive_sub?.pledges?.length}
                  </div>
                  <div className="bold gray font-075 margin-top-05">PLEDGES</div>
                </div>
              </div>
            }
            {!stat.current_pledge_drive_sub && 
              <div className="boldx centerx margin-top-1">
                School doesn't have an active campaign.
              </div>
            }
            
          </div>
        )
      })}
      {/* <div className="code">
        <pre>
          {JSON.stringify(dashboardStats, null, 2)}
        </pre>
      </div> */}

    </>
  );
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ProfileHome);
