import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { useApi } from '../api';
import { createClubUser } from 'redux/club/userActions';
import { connect } from 'react-redux';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';

const SignupSchema = yup.object().shape({
  club_name: yup.string().required("Club name is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipcode: yup.string().required("Zipcode is required."),
  phone: yup.string().required("Phone is required."),
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});

const ClubJoin = ({currentUser}) => {

  let navigate = useNavigate();
  let params = useParams();

  const [meas, setMeas] = useState('metric');
  const [vvv, setVvv] = useState();


  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: SignupSchema
  });

  const [loggedInUser, loading, loaded, error, doFetch, setResult] = useApi();


  const onSubmit = async data => {
    let d = {
      user: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation
      },
      club: {
        name: data.club_name,
        street: data.street,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        phone: data.phone,
        email: data.email,
      }
    }
    doFetch(createClubUser, {payload: d});
  }

  useEffect(() => {

    // alert(loaded);
    if (currentUser && loaded) {
      // navigate(`/profile`);
      // history.push("/profile");
      

      navigate(`/join/athlete-setup`);
    }
  }, [loaded]);

  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }




  
  return(
    <div className="really-narrow">

      <h1 className="margin-top-2 center">Club Application</h1>
      {/* <div className="margin-bottom-3 center gray">Step 1 of 3</div> */}

      <form onSubmit={handleSubmit(onSubmit)}>


        <div className="flex-cont">
          <label htmlFor="club_name"> 
            <span>Club Name</span>
            <input 
              type="text" 
              ref={register}
              name="club_name"
            />
            {errors.club_name && <div className="field-error">{errors.club_name.message}</div>}
          </label>
        </div>


        <div className="flex-cont">
          <label htmlFor="street"> 
            <span>Street</span>
            <input 
              type="text" 
              ref={register}
              name="street"
            />
            {errors.street && <div className="field-error">{errors.street.message}</div>}
          </label>
        </div>
        <div className="flex-cont flex-wrap">
          <label htmlFor="city">  
            <span>City</span>
            <input 
              type="text" 
              ref={register}
              name="city"
            />
            {errors.city && <div className="field-error">{errors.city.message}</div>}
          </label>
          <label htmlFor="state">  
            <span>State</span>
            <input 
              type="text" 
              ref={register}
              name="state"
            />
            {errors.state && <div className="field-error">{errors.state.message}</div>}
          </label>
          <label htmlFor="zipcode"> 
            <span>Zipcode</span> 
            <input 
              type="text" 
              ref={register}
              name="zipcode"
            />
            {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
          </label>
        </div>
        
        <div className="flex-cont flex-wrap">
          <label htmlFor="email"> 
            <span>Email</span>
            <input 
              type="text" 
              ref={register}
              name="email"
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>

          <label htmlFor="phone"> 
            <span>Phone</span>
            <input 
              type="text" 
              ref={register}
              name="phone"
            />
            {errors.phone && <div className="field-error">{errors.phone.message}</div>}
          </label>
        </div>
        
        <div className="flex-cont flex-wrap">
          <label htmlFor="firstName"> 
            <span>First Name</span>
            <input 
              type="text" 
              ref={register}
              name="first_name" 
            />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="lastName"> 
            <span>Last Name</span>
            <input 
              type="text" 
              ref={register}
              name="last_name"
            />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
        </div>

        <div className="flex-cont flex-wrap">
          <label htmlFor="password"> 
            <span>Password</span>
            <input 
              type="password" 
              ref={register}
              name="password"
            />
            {errors.password && <div className="field-error">{errors.password.message}</div>}
          </label>
          <label htmlFor="password_confirmation"> 
            <span>Retype Password</span>
            <input 
              type="password" 
              ref={register}
              name="password_confirmation"
            />
            {errors.password_confirmation && <div className="field-error">{errors.password_confirmation.message}</div>}
          </label>
        </div>

        <div className="margin-top-2 center">
          {/* <input type="submit" value="Continue" /> */}

          <button className="button" disabled={false || loading || hasErrors()} type="submit">
            {loading ? (
              <div className="spinner"></div>
            ) : (
              <span>Continue</span>
            )
            }
            
          </button>
        </div>

        <div className="flex-cont justify-center align-center margin-top-2 center">
          Already have an account? 
          <div onClick={() => navigate('/login')} className="margin-left-05 ghost button">Login</div>
        </div>
        
      </form>
    </div>
    
  )
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ClubJoin);
