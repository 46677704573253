import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { DateTime } from "luxon";
import { getMyCareers } from 'redux/user/userActions';
import { careeroutUser } from 'redux/session/sessionActions';
import { getCareerStats } from 'redux/athlete/athleteActions';
import store from 'redux/store';
import { VictoryContainer, VictoryTheme, VictoryChart, VictoryBar, VictoryAxis, VictoryLine, VictoryArea } from "victory";
import { Link } from 'react-router-dom';
import ProfileMileChart from './ProfileMileChart';


const ProfileActivity = () => {


  const [careers, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchMyCareers();
  }, []);

  const fetchMyCareers = async () => {
    doFetch(getCareerStats);
  };

  const barData = [
    { x: "2015-16",   y: Math.ceil(Math.random()*100)},
    { x: "2016-17", y: Math.ceil(Math.random()*100)},
    { x: "2017-18", y: Math.ceil(Math.random()*100)},
    { x: "2018-19", y: Math.ceil(Math.random()*100)},
    { x: "2019-20", y: Math.ceil(Math.random()*100)},
    { x: "2020-21", y: Math.ceil(Math.random()*100)},
  ];

  return (
    <>
      <h1 className="mobile-center">Career</h1>

      {loading && <div className="spinner"></div> }

      {/* <pre>
        {JSON.stringify(careers, null, 2)}
      </pre> */}

      {careers &&
        <div className="grid-layout">

          <div className="align-center justify-center card grid-item span-1-v box span-3-h">
            <div className="relative">
              <div className="number">
                {careers.fastest_mile}
              </div>
              <div className="number-label">FASTEST MILE</div>
            </div>
          </div>
          <div className="align-center justify-center card grid-item span-1-v box span-1-h">
            <div className="relative">
              <div className="number">
                {careers.average_mile}
              </div>
              <div className="number-label">AVG MILE</div>
            </div>
          </div>



          <div className="align-center justify-center card grid-item span-1-v box span-1-h">
            <div className="relative">
              <div className="number">
                {careers.total_miles}
              </div>
              <div className="number-label">TOTAL MILES</div>
            </div>
          </div>

          <div className="align-center justify-center card grid-item span-1-v box span-1-h">
            <div className="relative">
              <div className="number">
                {careers.total_time}
              </div>
              <div className="number-label">ACTIVITY TIME</div>
            </div>
          </div>

          <div className="align-center justify-center card grid-item span-1-v box span-1-h">
            <div className="relative">
              <div className="number">
                {(parseFloat(careers.total_cents_earned)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </div>
              <div className="number-label">TOTAL EARNED</div>
            </div>
          </div>

          <div className="align-center justify-center card grid-item span-1-v box span-1-h">
            <div className="relative">
              <ProfileMileChart mileData={careers?.mile_times?.[0]?.data} />  
              <div className="number-label">TIMED MILE PROGRESSION</div>
            </div>
          </div>

          {/* <div className="no-hover align-center justify-center card no-padding grid-item box span-6-h">
            <div>
            <div className="number-label">TIMED MILE PROGRESSION</div>
          
            <ProfileMileChart mileData={careers?.mile_times?.[0]?.data} />  
            </div>
          </div> */}
        </div>
      }

      {/* <div className="margin-bottom-2"></div>

      {loading &&
        <div className="spinner"></div>
      }

      {!loading && (!careers || careers.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-flag blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">You are not a member of any careers.</div>
          <div className="margin-top-2">
            <Link to="/career-search" className="button">Apply to a Career</Link>
          </div>
        </div>
      }
      

      {careers && careers.map( (career, careerIndex) => {
        return(
          <div className="card flex-cont align-center">
            <div className="margin-right-1 bold">{career.athlete_name}</div>
            <div className="flex-1 margin-right-1">{career.career_name}</div>

            <div>{career.status}</div>
            
          </div>
        )
      })}  */}
    </>
  )
}

export default ProfileActivity;