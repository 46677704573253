

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const ClubStaffNav = ({}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/staff`}>All</NavLink></li>
        <li><NavLink to={`/staff/school-admin`}>School Admin</NavLink></li>
        <li><NavLink to={`/staff/team-admin`}>Team Admin</NavLink></li>
        <li><NavLink to={`/staff/pe-teachers`}>PE Teachers</NavLink></li>
        <li><NavLink to={`/staff/run-monitors`}>Run Monitors</NavLink></li>
      </ul>
    </div>
  )
}

export default ClubStaffNav;