import { andyFetch } from '../../api';

export const getTeamOwnerships = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_ownerships";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeamOwnerships error", e);
    }
  }
};

export const createTeamOwnerships = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_ownerships/bulk_create";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeamOwnerships error", e);
    }
  }
};

export const createTeamOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_ownerships";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeamOwnerships error", e);
    }
  }
};

export const destroyTeamOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = `club/team_ownerships/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "DELETE");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("destroyteamOwnership error", e);
    }
  }
};

export const inviteTeamOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_ownerships/invite";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("inviteTeamOwnership error", e);
    }
  }
};

export const updateTeamOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = `club/team_ownerships/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("updateTeamOwnership error", e);
    }
  }
};