import React from 'react';


const SupportScreen = () => {

  return (
    <div className="narrow margin-top-4">
      <h1>Support</h1>
      Please email <strong>andy@herorun.com</strong> for all support inquiries.
    </div>
  )
}

export default SupportScreen;