import { andyFetch } from '../../api';


export const getClubEvents = (params) => {
  return async (dispatch, getState) => {
    const url = "club/club_events";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubEvents error", e);
    }
  }
};

export const getClubEvent = (params) => {
  return async (dispatch, getState) => {
    const url = `club/club_events/${params?.query?.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubEvents error", e);
    }
  }
};

export const createClubEvent = (params) => {
  return async (dispatch, getState) => {
    const url = "club/club_events";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubEvents error", e);
    }
  }
};


export const updateClubEvent = (params) => {
  return async (dispatch, getState) => {
    const url = `club/club_events/${params?.payload?.club_event?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubEvents error", e);
    }
  }
};
export const deleteClubEvent = (params) => {
  return async (dispatch, getState) => {
    const url = `club/club_events/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "DELETE");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("destroyClubEvent error", e);
    }
  }
};