

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { useForm } from 'react-hook-form';
import * as yup from "yup";

import { Link } from 'react-router-dom';

import { useApi } from '../../api';
import CountUp from '../../components/CountUp.js';
import { updateMyClub, getMyClub } from 'redux/club/clubActions';
import { getCurrentPledgeDrive } from 'redux/club/pledgeDriveActions';

import { API_URL } from '../../api.js';
import { DateTime, Settings } from 'luxon'
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

import Bar from 'components/Bar';
import { VictoryContainer, VictoryTheme, VictoryChart, VictoryBar, VictoryAxis } from "victory";
import events from './calendar/events.js';
import ClubForm from './ClubForm';
import ClubWeek from './ClubWeek';

const Step = ({currentClub, step, description, url}) => {

  const getStage = () => {
    if (currentClub.onboarding_step === (step-1)) return 'current'
    if (currentClub.onboarding_step >= (step-1)) return 'past'
    return 'future'
  }

  const stage = getStage();


  return (
    <div className={`${currentClub.onboarding_step < (step-1) ? 'future' : 'current'} step flex-cont align-center`}>
      <div className="circle-number margin-right-2">{step}</div>
      <div className="flex-1">{description}</div>

      {stage === 'past' &&
        <div className="flex-cont align-center">
          <i className="fas fa-check margin-right-1"></i>
          {/* <Link to={url} className="ghost button">View</Link> */}
        </div>
      }
      {stage === 'current' &&
        <Link to={url} className="button">Start</Link>
      }
    </div>
  )
}



const ClubHome = ({currentClub, currentUser, raceStarted}) => {

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    doFetch(getMyClub);
  }, []);


  if (!currentClub) {
    return (
      <div className="center">
        <div className="spinner"></div>
      </div>
    )
  }

  

  return (
    <>
      <div className="uni-banner"></div>

      <div className="club-home-cont">
        <div className="flex-cont flex-wrap justify-center align-centerx">
          <div className="center margin-right-1">
            <div style={{backgroundImage: `url("${currentClub?.avatar_url}")`}} className="avatar-cont font-10 avatar-shadow ">
              {currentClub && !currentClub.avatar_url &&
                <i className="fas fa-school"></i>
              }
            </div>
            <div style={{maxWidth: '15rem'}} className="avatar-name margin-top-1 bold font-1-25 margin-bottom-1 blue">{currentClub?.name}</div>
          </div>
          <div className="flex-1 margin-top-1">
            <ClubWeek week={currentClub?.week} />
          </div>
          
        </div>
        
        {/* <div className="club-banner-cont flex-cont">
          <div className="banner"> 
            <div className="bg"></div>
          </div>


            <div className="align-end flex-1 padding-1 flex-cont flex-wrap center justify-center align-center">
              <div className="club-avatar-cont mobile-full center">
                <div style={{backgroundImage: `url("${currentClub?.avatar_url}")`}} className="avatar-cont font-7-5 ">
                  
                  {currentClub && !currentClub.avatar_url &&
                    <i className="fas fa-school"></i>
                  }
                </div>
                <div className="avatar-name margin-top-1 bold font-1-15">{currentClub?.name}</div>
              </div>


      

            <div className="flex-1"></div>
            <div className="flex-contx"> */}
              {/* <Link to={`/club/${currentClub?.slug}`} className="ghost button margin-right-1">View Club Page</Link> */}
              {/* <div onClick={() => toggleEditModal()} className="button">Edit Club</div> */}
            {/* </div>
          </div>

          

      </div> */}

      

      {currentClub?.onboarding_step === "done" &&
        <>
          <TestClubDash currentUser={currentUser} raceStarted={raceStarted} currentClub={currentClub} />
        </>
      }

      {currentClub?.onboarding_step !== "done"  &&
        <div className="narrow-2 margin-top-3 margin-bottom-3">

          {loading &&
            <div className="spinner"></div>
          }
          {!loading &&
            <>
              <h1 className="no-margin-bottom">Welcome to Hero Run</h1>
              <div className="margin-top-1">
                To get started, please complete the steps below.
              </div>


              <div className="card no-hover steps-cont margin-top-2">
                <Step currentClub={currentClub} step={1} description="Import your school's roster" url="onboarding/upload-roster" />
                <Step currentClub={currentClub} step={2} description="Add faculty and staff" url="onboarding/faculty-and-staff" />
                {/* <Step currentClub={currentClub} step={3} description="Assign teachers to classes" url="onboarding/assign-teachers" /> */}
                <Step currentClub={currentClub} step={3} description="Create a campaign" url="onboarding/campaigns" />
                {/* <Step currentClub={currentClub} step={4} description="Add competitions" url="onboarding/competitions" /> */}
                <Step currentClub={currentClub} step={4} description="Add track" url="onboarding/track" />
                <Step currentClub={currentClub} step={5} description="Setup fundraising" url="/onboarding/stripe" />
                <Step currentClub={currentClub} step={6} description="Invite parents to join Hero Run" url="onboarding/parent-invites" />



            

                {/* <div className={`${currentClub.onboarding_step < 1 ? 'future' : 'current'} step flex-cont align-center`}>
                  <div className="circle-number margin-right-2">2</div>
                  <div className="flex-1">Add faculty and staff.</div>
                  <Link to="onboarding/faculty-and-staff" className="button">Start</Link>
                </div>
                <div className={`${currentClub.onboarding_step < 2 ? 'future' : 'current'} step flex-cont align-center`}>
                  <div className="circle-number margin-right-2">3</div>
                  <div className="flex-1">Create a campaign.</div>
                  <Link to="onboarding/campaigns" className="button">Start</Link>
                </div>
                <div className={`${currentClub.onboarding_step < 3 ? 'future' : 'current'} step flex-cont align-center`}>
                  <div className="circle-number margin-right-2">4</div>
                  <div className="flex-1">Add competitions.</div>
                  <Link to="onboarding/competitions" className="button">Start</Link>
                </div>
                <div className={`${currentClub.onboarding_step < 4 ? 'future' : 'current'} step flex-cont align-center`}>
                  <div className="circle-number margin-right-2">5</div>
                  <div className="flex-1">Invite parents to join Hero Run!</div>
                  <Link to="onboarding/invitations" className="button">Start</Link>
                </div> */}
              </div>
            </>
          }
          
        </div>
      
      }

      
      {/* <div className="margin-top-4 grid-layout padding-1">

        <div className="align-center justify-center card grid-item span-1-v box">
          <div>
            <div className="number">{currentClub?.amount_pledged}</div>
            <div className="number-label">PLEDGED</div>
          </div>
        </div>
        <div className="align-center justify-center card grid-item span-1-v box">
          <div>
            <div className="number">{currentClub?.amount_earned}</div>
            <div className="number-label">EARNED</div>
          </div>
        </div>
        <div className="align-center justify-center card grid-item span-1-v box">
          <div>
            <div className="number">{currentClub?.total_miles}</div>
            <div className="number-label">MILES</div>
          </div>
        </div>

        <div className="align-center justify-center card grid-item span-2-v box">
          <div>
            <div className="margin-bottom-1 number-label">PLEDGED LEADERBOARD</div>

            <table className="basic">
              {currentClub?.pledged_leaders && currentClub.pledged_leaders.map((athlete, i) => {
                return (
                  <tr>
                    <td>#{i+1}</td>
                    <td>{athlete.name}</td>
                    <td>{athlete.pledged}</td>
                  </tr>
                )
              })}
            </table>
          </div>
        </div>


        <div className="align-center justify-center card grid-item span-2-v box">
          <div>
            <div className="margin-bottom-1 number-label">EARNED LEADERBOARD</div>

            <table className="basic">
              {currentClub?.pledged_leaders && currentClub.pledged_leaders.map((athlete, i) => {
                return (
                  <tr>
                    <td>#{i+1}</td>
                    <td>{athlete.name}</td>
                    <td>{athlete.earned}</td>
                  </tr>
                )
              })}
            </table>
          </div>
        </div>





      </div> */}
      </div>
    </>
  )
}

const TestClubDash = ({currentUser, currentClub, raceStarted}) => {

  const [currentCampaign, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    doFetch(getCurrentPledgeDrive);
  }, []);

  const pledged = [
    {name: "Andy Simon", pledged: "$950"},
    {name: "Andy Simon", pledged: "$950"},
    {name: "Andy Simon", pledged: "$950"},
    {name: "Andy Simon", pledged: "$950"},
    {name: "Andy Simon", pledged: "$950"},
  ];
  const earned = [
    {name: "Andy Simon", earned: "$950"},
    {name: "Andy Simon", earned: "$950"},
    {name: "Andy Simon", earned: "$950"},
    {name: "Andy Simon", earned: "$950"},
    {name: "Andy Simon", earned: "$950"},
  ];
  const teams = [
    {name: "Mrs. Smith", earned: "$10,000"},
    {name: "Mr. Roberts", earned: "$8,900"},
    {name: "Andy Simon", earned: "$6,300"},
    {name: "Andy Simon", earned: "$2,000"},
    {name: "Andy Simon", earned: "$1,950"},
  ];

  const barData = [
    { x: "K",   y: 100},
    { x: "1st", y: 200},
    { x: "2nd", y: 300},
    { x: "3rd", y: 400},
    { x: "4th", y: 500},
    { x: "5th", y: 600},
    { x: "6th", y: 333},
  ];
  const mileBarData = [
    { x: "K",   y: Math.ceil(Math.random()*100)},
    { x: "1st", y: Math.ceil(Math.random()*100)},
    { x: "2nd", y: Math.ceil(Math.random()*100)},
    { x: "3rd", y: Math.ceil(Math.random()*100)},
    { x: "4th", y: Math.ceil(Math.random()*100)},
    { x: "5th", y: Math.ceil(Math.random()*100)},
    { x: "6th", y: Math.ceil(Math.random()*100)},
  ];


  return(
    <>
      <div className="margin-top-1 narrow-2">
      {/* <h2>2020-21 School Year</h2> */}

      
      {raceStarted &&
        <Link to='live-run'>
          <div className="center justify-center  padding-1 full flex-cont align-center no-hover">
            <div className="pulser blue margin-right-half"></div>
            <div className="bold margin-left-05">A run is currently live.</div>  
            <div className="margin-left-2 button">
              <span>VIEW LIVE RUN</span>  
              <i className="fas fa-chevron-right margin-left-05"></i>
            </div>
          </div>
        </Link>
      }
      {!raceStarted &&
        <>
        </>
      }



      {/* <div className="code">
        <pre>
          {JSON.stringify(currentCampaign, null, 2)}
        </pre>
      </div> */}

      {loading &&
        <div className="spinner"></div>
      }

    {/* <div>
      CURRENT USER:
      <pre>
        {JSON.stringify(currentClub, null, 2)}
      </pre>
    </div> */}

      

      {currentCampaign && 
        <>

          <div className="flex-cont margin-top-2 align-center justify-center margin-bottom-3 ">

            <div>

              <div className="center no-margin margin-top-2 margin-bottom-half">
                <Link className="text-link font-2" to={`/campaigns/${currentCampaign.id}`}>
                  {currentCampaign?.name}
                </Link>
              </div>

              <div className="gray bold center no-margin margin-bottom-2" >
                {DateTime.fromISO(currentCampaign.start_date).toLocaleString(DateTime.DATE_FULL)} - {DateTime.fromISO(currentCampaign.end_date).toLocaleString(DateTime.DATE_FULL)}
              </div>
            </div>

          {currentCampaign?.title_sponsor && 
            <div className='margin-left-3 padding-left-3 center' style={{borderLeft: '1px solid lightgray'}}>
              <div className="margin-bottom-1 gray">PRESENTED BY</div>
              <div>

                <a target="_blank" href={currentCampaign.title_sponsor.link_url}>
                  <img src={currentCampaign.title_sponsor.image_url} style={{height: "100px"}} />
                </a>
                
              </div>
            </div>
            
          }
          </div>

          <div className="no-hover card full">
            {/* <Bar progress={currentCampaign ? (currentCampaign.cents_earned / currentCampaign.cents_max_pledged)*100.00 : 0} /> */}

            <div className="flex-cont">
                    <div className="margin-bottom-half blue bold-200 font-2">
                      <div>
                        <CountUp delay={500} number={parseFloat(currentCampaign.cents_earned)} type="money" />
                        {/* {(parseFloat(pledge.total_cents_earned)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} */}
                      </div>
                    </div>
                    <div className="flex-1"></div>
                    <div className="margin-bottom-half blue bold-200 font-2">
                      <div>
                      {(parseFloat(currentCampaign.cents_max_pledged)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </div>
                    </div>
                  </div>
                  <Bar height={25} full progress={currentCampaign ? (currentCampaign.cents_earned / currentCampaign.cents_max_pledged)*100.00 : 0} />

                  <div className="flex-cont">
                    <div>
                      <div className="blue margin-left-half bold margin-top-half">
                        EARNED
                      </div> 
                    </div>
                    <div className="flex-1"></div>
                    <div className="text-right">
                      <div className="blue margin-right-half bold margin-top-half">
                        PLEDGED
                      </div> 
                    </div>
                  </div>
          </div>

          <div className="flex-cont flex-wrap padding-1 flex-grid" style={{paddingBottom: 0}}>

            <div style={{flex: 1}}>
              <div className="grid-item align-center justify-center card grid-item box margin-bottom-half no-bgx">
                <div className="full">
                  <div className="font-1-5 margin-bottom-1 bold">
                    Distance Goal
                  </div>
                  <Bar progress={100.00 * currentCampaign?.total_distance / currentCampaign?.distance_goal} />
                  {/* <div className="number-label margin-top-1">
                    {currentCampaign?.total_distance} / {currentCampaign?.distance_goal} mi.
                  </div> */}

                  <div className="flex-cont align-center number-label margin-top-1">
                    {/* {currentCampaign.cents_pledged / 100.00} / {currentCampaign.cents_max_pledged / 100.00} */}
                    <div>
                      {!isNaN(parseFloat(currentCampaign.cents_max_pledged)) ? 
                        Math.round(parseFloat(currentCampaign.total_distance)).toLocaleString('en-US')
                        :
                        "-"
                      }
                    </div>
                    <div className="lightgray margin-left-half margin-right-half">/</div>
                    <div>
                      {!isNaN(parseFloat(currentCampaign.pledged_goal)) ? 
                        parseFloat(currentCampaign.distance_goal).toLocaleString('en-US') + " mi"
                        :
                        "-"
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{flex: 2}}>

              <div  className="align-center justify-center card grid-item box no-bgx">
                <div className="full">
                  <div className="font-1-5 margin-bottom-1 bold">
                    Pledge Goal
                  </div>
                  <Bar progress={currentCampaign.pledged_goal ? 100.00 * (currentCampaign.cents_max_pledged / (currentCampaign.pledged_goal * 100)) : 0} />
                  <div className="flex-cont align-center number-label margin-top-1">
                    {/* {currentCampaign.cents_pledged / 100.00} / {currentCampaign.cents_max_pledged / 100.00} */}
                    <div>
                      {!isNaN(parseFloat(currentCampaign.cents_max_pledged)) ? 
                        parseFloat(currentCampaign.cents_max_pledged/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                        :
                        "-"
                      }
                    </div>
                    <div className="lightgray margin-left-half margin-right-half">/</div>
                    <div>
                      {!isNaN(parseFloat(currentCampaign.pledged_goal)) ? 
                        parseFloat(currentCampaign.pledged_goal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                        :
                        "-"
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            

          </div>

          <div className="flex-cont flex-wrap padding-1 flex-grid" style={{paddingTop: 0}}>

            {/* <div className="flex-1">
              <div className="grid-item">
                <div className="align-center justify-center card grid-itemx box margin-bottom-half">
                  <div className="full">
                    TEST
                  </div>
                </div>
              </div>
            </div> */}

            <div className="flex-1">

     
                
                <div className="align-center justify-center card grid-item box">
                  <div className="full">
                  <div className="blue font-3 font-weight-300 font-narrow">{currentCampaign.avg_athlete_distance}</div>
                  <div className="bold gray margin-top-half">AVG. MILES/STUDENT</div>
                    {/* <div className="font-1-5 margin-bottom-1 bold">
                      Distance Average
                    </div> */}
                    {/* <Bar progress={100.00 * currentCampaign?.avg_athlete_distance / currentCampaign?.avg_athlete_distance_goal} /> */}
                    {/* div.
                    <div className="number-label margin-top-1">
                      {currentCampaign?.avg_athlete_distance} mi./student
                    </div> */}
                  </div>
                </div>
        
              

              <div className="align-center justify-center card no-padding grid-item box">
                <div>
                <div className="font-1 blue bold margin-bottom-1 flex-cont">

                  <i className="fas fa-users margin-right-half"></i>
                  <div>TEAM DISTANCE</div> 
                  
                  </div>
              
                {/* <VictoryChart
                  theme={VictoryTheme.grayscale}
                  domainPadding={10}
                  containerComponent={<VictoryContainer style={{width: 'auto'}} />}
                  
                >
                  <VictoryAxis crossAxis />
                  <VictoryAxis crossAxis dependentAxis tickFormat={(t) => `${t}`} />

                  <VictoryAxis dependentAxis crossAxis style={{
                    grid: {stroke: "rgba(0,0,0,.2)"},
                  }} />
                  <VictoryBar
                    style={{ data: { fill: "#53b3d5" } }}
                    cornerRadius={4}
                    data={currentCampaign.team_miles}
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 1000 }
                    }}
                  />
                </VictoryChart>     */}

                <table className="basic leaderboard">
                  {currentCampaign?.team_distance_leaders?.map((sub, i) => {
                    return (
                      <tr>
                        <td><div className="rank">{i+1}</div></td>
                        <td>{sub.x}</td>
                        <td className="nowrap fit gray font-08">
                          {sub.y} mi.
                        </td>
                      </tr>
                    )
                  })}
                  
                </table>
                {(!currentCampaign?.team_distance_leaders || !currentCampaign?.team_distance_leaders?.length) &&
                  <em className="gray">No data.</em>
                }
                </div>
              </div>

              <div className="align-center justify-center card grid-item box">
              <div>
                <div className="margin-bottom-1 font-1 blue bold">
                  <i className="fas fa-user margin-right-half"></i>
                  STUDENT DISTANCE
                  
                  </div>

                <table className="leaderboard basic">
                  {currentCampaign?.distance_leaders?.map((sub, i) => {
                    return (
                      <tr>
                        <td><div className="rank">{i+1}</div></td>
                        <td>{sub.name}</td>
                        <td className="nowrap fit gray font-08">
                          {sub.distance} mi.
                        </td>

                        {/* <td>{JSON.stringify(sub, null, 2)}</td> */}
                      </tr>
                    )
                  })}
                  {(!currentCampaign?.distance_leaders || !currentCampaign?.distance_leaders?.length) &&
                    <em className="gray">No data.</em>
                  }
                </table>
              </div>
            </div>


            </div>


            <div className="flex-1">


              

              <div className="align-center justify-center card no-padding grid-item box">
              <div>
              <div className="margin-bottom-1 blue bold">TEAM PLEDGES</div>
            
              {/* <VictoryChart
                theme={VictoryTheme.grayscale}
                domainPadding={10}
                containerComponent={<VictoryContainer style={{width: 'auto'}} />}
                
              >
                <VictoryAxis crossAxis />
                <VictoryAxis crossAxis dependentAxis tickFormat={(t) => `$${t}`} />

                <VictoryAxis dependentAxis crossAxis style={{
                  grid: {stroke: "rgba(0,0,0,.2)"},
                }} />
                <VictoryBar
                  style={{ data: { fill: "#53b3d5" } }}
                  cornerRadius={4}
                  data={barData}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 }
                  }}
                />
              </VictoryChart>     */}
                <table className="basic leaderboard">
                  {currentCampaign?.team_pledged_leaders?.map((sub, i) => {
                    return (
                      <tr>
                        <td><div className="rank">{i+1}</div></td>
                        <td>{sub.x}</td>
                        <td  className="nowrap fit gray font-08">
                          {!isNaN(parseFloat(sub.y)) ? 
                            parseFloat(sub.y).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                            :
                            "-"
                          }

                        </td>
                      </tr>
                    )
                  })}
                  
                </table>
                {(!currentCampaign?.team_pledged_leaders || !currentCampaign?.team_pledged_leaders?.length) &&
                  <em className="gray">No data.</em>
                }
              </div>
            </div>

            <div className="align-center justify-center card grid-item box">
              <div>
                <div className="margin-bottom-1 blue bold">STUDENT PLEDGES</div>

                <table className="basic leaderboard">
                  {currentCampaign?.pledged_leaders.map((sub, i) => {
                    return (
                      <tr>
                        <td><div className="rank">{i+1}</div></td>
                        <td>{sub.name}</td>
                        <td className="nowrap fit gray font-08">{sub.pledged}</td>
                      </tr>
                    )
                  })}
                  
                </table>
                {(!currentCampaign?.pledged_leaders || !currentCampaign?.pledged_leaders?.length) &&
                  <em className="gray">No data.</em>
                }
              </div>
            </div>

            </div>

            

            
            <div className="flex-1">

              {/* <div className="align-center justify-center card grid-item box">
                <div className="full">
                  <div className="font-1-5 margin-bottom-1 bold">
                    Earned Goal
                  </div>
                  <Bar progress={currentCampaign.earned_goal ? 100.00 * (currentCampaign.cents_earned / (currentCampaign.earned_goal * 100.00)) : 0} />
                  <div className="number-label margin-top-1">
                    <div className="flex-cont align-center number-label margin-top-1">
                      <div>
                        {!isNaN(parseFloat(currentCampaign.cents_earned)) ? 
                          parseFloat(currentCampaign.cents_earned/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                          :
                          "-"
                        }
                      </div>
                      <div className="lightgray margin-left-half margin-right-half">/</div>
                      <div>
                        {!isNaN(parseFloat(currentCampaign.earned_goal)) ? 
                          parseFloat(currentCampaign.earned_goal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                          :
                          "-"
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="align-center justify-center card no-padding grid-item box">
              <div>
              <div className="margin-bottom-1 blue bold">TEAM EARNINGS</div>
            
              {/* <VictoryChart
                theme={VictoryTheme.grayscale}
                domainPadding={10}
                containerComponent={<VictoryContainer style={{width: 'auto'}} />}
                
              >
                <VictoryAxis crossAxis />
                <VictoryAxis crossAxis dependentAxis tickFormat={(t) => `$${t}`} />

                <VictoryAxis dependentAxis crossAxis style={{
                  grid: {stroke: "rgba(0,0,0,.2)"},
                }} />
                <VictoryBar
                  style={{ data: { fill: "#53b3d5" } }}
                  cornerRadius={4}
                  data={barData}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 }
                  }}
                />
              </VictoryChart>     */}

                <table className="basic leaderboard">
                  {currentCampaign?.team_earned_leaders?.map((sub, i) => {
                    return (
                      <tr>
                        <td><div className="rank">{i+1}</div></td>
                        <td>{sub.x}</td>
                        <td className="nowrap fit gray font-08">
                          {!isNaN(parseFloat(sub.y)) ? 
                            parseFloat(sub.y).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                            :
                            "-"
                          }

                        </td>
                      </tr>
                    )
                  })}
                  
                </table>
                {(!currentCampaign?.team_earned_leaders || !currentCampaign?.team_earned_leaders?.length) &&
                  <em className="gray">No data.</em>
                }
              </div>
            </div>

            <div className="align-center justify-center card grid-item box">
              <div>
                <div className="margin-bottom-1 blue bold">EARNED LEADERBOARD</div>


                <table className="basic leaderboard">
                  {currentCampaign?.earned_leaders.map((sub, i) => {
                    return (
                      <tr>
                        <td>
                          <div className="rank">
                            {i+1}
                          </div>
                        </td>
                        <td>{sub.name}</td>
                        <td className="nowrap fit gray font-08">{sub.earned}</td>
                      </tr>
                    )
                  })}
                  {(!currentCampaign?.earned_leaders || !currentCampaign?.earned_leaders?.length) &&
                    <em className="gray">No data.</em>
                  }
                </table>
              </div>
            </div>



            </div>
            
            
            

            
            {/* <div className="align-center justify-center card grid-item box">
              <div>
                <div className="number">$40,000</div>
                <div className="number-label">TOTAL PLEDGED</div>
              </div>
            </div>
            <div className="align-center justify-center card grid-item box">
              <div>
                <div className="number">$32,450</div>
                <div className="number-label">TOTAL EARNED</div>
              </div>
            </div>
            <div className="align-center justify-center card grid-item box">
              <div>
                <div className="number">570</div>
                <div className="number-label">TOTAL MILES</div>
              </div>
            </div> */}

            

            


          

            {/* <div className="align-center justify-center card grid-item box">
              <div>
                <div className="margin-bottom-1 number-label">TEAM LEADERBOARD</div>

                <table className="basic">
                  {teams.map((athlete, i) => {
                    return (
                      <tr>
                        <td>#{i+1}</td>
                        <td>{athlete.name}</td>
                        <td>{athlete.earned}</td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </div> */}
            





          </div>

        </>
      }
      
      </div>

    </>
        

      
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    raceStarted: state.uiState.raceStarted,
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ClubHome);