

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const ClubBandNav = ({code}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/hero-bands/${code}`}>Dashboard</NavLink></li>
      </ul>
    </div>
  )
}

export default ClubBandNav;