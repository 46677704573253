import { andyFetch } from '../../api';

export const getMachines = (params) => {
  return async (dispatch, getState) => {

    const url = "club/machines";

    try {
      const response = await andyFetch(url, params, "GET");



      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMachines error", e);
    }
  }
};


export const createMachine = (params) => {

  return async (dispatch, getState) => {

    const url = "club/machines";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createMachine error.');
      }
    }
    catch (e) {
      console.log("createMachine error", e);
    }
  }
};


export const updateMachine = (params) => {

  return async (dispatch, getState) => {

    const url = `club/machines/${params.payload.machine.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateMachine error.');
      }
    }
    catch (e) {
      console.log("updateMachine error", e);
    }
  }
};


export const deleteMachine = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/machines/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteMachine error.');
      }
    }
    catch (e) {
      console.log("deleteMachine error", e);
    }
  }
};

