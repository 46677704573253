

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getCharities } from 'redux/club/charityActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createCharity, updateCharity, deleteCharity } from 'redux/club/charityActions';


const Charitieschema = yup.object().shape({
  name: yup.string().required("Name is required."),
  active: yup.boolean(),

});


const CharityForm = ({toggleModal, currentCharity}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: Charitieschema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();


  const onSubmit = async data => {
    let d = {
      charity: {
        ...data
      }
    }

    if (currentCharity && currentCharity.id) {
      doFetch(updateCharity, {payload: d});
    }
    else {
      doFetch(createCharity, {payload: d});
    }
  }


  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentCharity ? 'Edit' : 'New'} Charity</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentCharity?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentCharity?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>


        <div className="flex-cont">
          <label htmlFor="active">
            
            <input defaultChecked={currentCharity?.active} type="checkbox" name="active" ref={register} id=""/>
            <span className="margin-left-1">Active</span>

            {errors.active && <div className="field-error">{errors.active.message}</div>}
          </label>
        </div>


      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminCharities = ({currentUser}) => {

  const [charities, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedCharity, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedCharity] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingCharity, setEditingCharity] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchCharities();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingCharity(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!charities) {
      fetchCharities();
    }
  }, []);

  const fetchCharities = () => {
    doFetch(getCharities);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingCharity, setDeletingCharity] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchCharities();
    }
  }


  const editClicked = charity => {
    setEditingCharity(charity);
    toggleModal();
  }

  const deleteClicked = charity => {
    setDeletingCharity(charity);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingCharity) {
      doDeleteFetch(deleteCharity, {query: {id: deletingCharity.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <CharityForm currentCharity={editingCharity} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Causes</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Charity</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {charities &&
        <table className="basic">
          {charities.map(charity => {
            return (
              <tr>
                <td className="bold fit nowrap">{charity.name}</td>
                <td className="fit nowrap">{charity.club_name}</td>
                <td>{charity.active ? "ACTIVE" : "NOT ACTIVE"}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(charity)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(charity)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Charity</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingCharity?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!charities || charities.length === 0) &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminCharities;