import { andyFetch } from '../../api';

export const getAwardRuleLogins = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/award_rule_logins";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAwardRuleLogins error", e);
    }
  }
};

export const getAwardRuleLogin = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/award_rule_logins/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAwardRuleLogin error", e);
    }
  }
};



export const createAwardRuleLogin = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/award_rule_logins";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createAwardRuleLogin error.');
      }
    }
    catch (e) {
      console.log("createAwardRuleLogin error", e);
    }
  }
};


export const updateAwardRuleLogin = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/award_rule_logins/${params.payload.award_rule_login.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAwardRuleLogin error.');
      }
    }
    catch (e) {
      console.log("updateAwardRuleLogin error", e);
    }
  }
};


export const deleteAwardRuleLogin = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/award_rule_logins/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAwardRuleLogin error.');
      }
    }
    catch (e) {
      console.log("deleteAwardRuleLogin error", e);
    }
  }
};

