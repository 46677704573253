

import React from 'react';


const NotFound = () => {
  return (
    <div className="center really-narrow">
      <div className="margin-top-4"></div>
      <i className="fas fa-exclamation-circle font-5"></i>
      <div className="mar gin-top-2"></div>
      <h1>Page not found.</h1>
    </div>
  )
}

export default NotFound;