

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Outlet, useLocation } from "react-router-dom"


import { useApi } from './api';
import { reupdateUser } from "./redux/session/sessionActions";
import store from './redux/store';


// const Redirect = () => {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     alert('redirect')
//   }, [pathname]);

//   return null;
// }


const AuthorizedRoute = ({roles, children}) => {
  const { pathname } = useLocation();
  const [bootingUp, setBootingUp] = useState(true);

  const [newCurrentUser, loading, loaded, errors, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchCurrentUser();

    // alert('authroute');
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, [pathname]);

  const fetchCurrentUser = () => {
    doFetch(reupdateUser);
  };

  const navigate = useNavigate();

  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    

    if (loaded) {
      console.log('newCurrentUser', newCurrentUser);
      console.log('roles', roles);
      console.log('newCurrentUser.role', newCurrentUser?.role);

      const a = !!(newCurrentUser && newCurrentUser.role && roles && roles.includes(newCurrentUser.role)  );
      setIsAuthed(a);
      if (!a) {
        navigate('/');
      }

      setBootingUp(false);
    }
    else {
      setIsAuthed(false);
      // navigate('/');
    }


  }, [loaded]);

  useEffect(() => {
    if (errors) {
      setBootingUp(false);
      navigate('/');
      setIsAuthed(false);
    }
  }, [errors]);

  useEffect(() => {
    if (newCurrentUser) {
      setBootingUp(false);
    }
  }, [newCurrentUser]);

  // return (
  //   <>
  //     {/* <Redirect /> */}
  //     <Outlet />
  //   </>
  // )



  return(
    <>
        <Outlet />

    </>
  );
  

}

const mapState = state => ({
  currentUser: state.sessionState.currentUser,
});

export default connect(mapState)(AuthorizedRoute);