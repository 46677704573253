

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'

import { getUsers } from 'redux/club/userActions';
import { sendUserInvites } from 'redux/club/userInviteActions';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"
import { resetIdCounter } from 'downshift';


const OnboardingParentInvites = ({currentClub}) => {

  const navigate = useNavigate();

  const [users, usersLoading, usersLoaded, usersError, doUsersFetch, setUsersResult] = useApi();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    doUsersFetch(getUsers);
  }

  useEffect(() => {
    if (usersLoaded) {

    }
  }, [usersLoaded]);

  const [invites, invitesLoading, invitesLoaded, invitesError, doInvitesFetch, setInvitesResult] = useApi();

  const sendInvitesClicked = () => {

    doInvitesFetch(sendUserInvites);
  }

  useEffect(() => {
    if (invitesLoaded) {
      navigate('/onboarding/done');
    }
    
  }, [invitesLoaded])



  
  return(
    <>
      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">6</div>
        <h1 className="no-margin-bottom">Parent Invites</h1>
        <h2 className="no-margin-top">Invite parents to Hero Run</h2>
        

        <div className="margin-top-3"></div>
        {usersLoading && <div className="spinner"></div>}
  
        <div className="card no-hover margin-top-1">
          <table className="basic">
            <tr>
              <th>First</th>
              <th>Last</th>
              <th>Email</th>
            </tr>
            {users?.map(user => 
              <tr>
                
                <td className="fit nowrap">{user.first_name}</td>
                <td className="fit nowrap">{user.last_name}</td>
                <td>{user.email}</td>
              </tr>
            )}
          </table>
        </div>

        <div className="flex-cont margin-top-3 margin-bottom-3">
          <div className="flex-1"></div>

          {invitesLoading &&
            <div className="spinner margin-right-1"></div>
          }

          <div onClick={sendInvitesClicked} className="button" className={`${invitesLoading ? 'disabled' : ''} button`}>
            Send Invites
          </div>
        </div>
        

        
        {/* <div className="code">
          <pre>
            {JSON.stringify(users, null, 2)}
          </pre>
        </div> */}
      </div>

     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingParentInvites);