import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPledgeDrive, changeAthlete } from 'redux/club/pledgeDriveActions';
import { DateTime } from 'luxon';

const ClubPledgeDrive = ({pledgeDrive, fetchPledgeDrive}) => {

  const { id } = useParams();

  useEffect(() => {
    fetchPledgeDrive();
  }, [])

  // useEffect(() => {
  //   if (id) {
  //     fetchPledgeDrive()
  //   }
  // }, [id]);

  // const [pledgeDrive, pledgeDriveLoading, pledgeDriveLoaded, pledgeDriveError, doFetchPledgeDrive, setPledgeDriveResults] = useApi();

  // const fetchPledgeDrive = () => {
  //   doFetchPledgeDrive(getPledgeDrive, {query: {id: id}})
  // }


  // const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  // const changeAthleteClicked = () => {
  //   setAthleteModalOpened(true);
  // }
  // const toggleAthleteModal = (reload=false) => {

  //   setAthleteModalOpened(o => !o);
  //   if (reload) {
  //     fetchPledgeDrive();
  //   }
  // }
 
  return (
    <>


      {pledgeDrive &&
        <>  
          <table className="vertical">

            <tr>
              <th>Start Date</th>
              <td>
                {pledgeDrive?.start_date ? DateTime.fromISO(pledgeDrive?.start_date).toUTC().toLocaleString() : "No start date."}
              </td>
            </tr>
            <tr>
              <th>End Date</th>
              <td>
                {pledgeDrive?.end_date ? DateTime.fromISO(pledgeDrive?.end_date)?.toLocaleString() : "No end date."}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{pledgeDrive.active ? "ACTIVE" : "INACTIVE"}</td>
            </tr>

            <tr><td></td><td></td></tr>
            <tr>
              <th>Distance Goal for Each Student</th>
              <td>
                {pledgeDrive?.athlete_distance_goal} mi.
              </td>
            </tr>
            <tr>
              <th>Pledge Goal for Each Student</th>
              <td>
                {(parseFloat(pledgeDrive.athlete_pledged_goal)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                {/* {(parseFloat(pledgeDrive.cents_max_pledged)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}  / {(parseFloat(pledgeDrive.pledged_goal)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} */}
              </td>
            </tr>

            <tr><td></td><td></td></tr>

            <tr>
              <th>Total Distance Goal</th>
              <td>
                {pledgeDrive?.total_distance} / {pledgeDrive?.athlete_distance_goal} mi.
              </td>
            </tr>
            <tr>
              <th>Total Pledges Goal</th>
              <td>
                {(parseFloat(pledgeDrive.cents_max_pledged)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}  / {(parseFloat(pledgeDrive.pledged_goal)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
              </td>
            </tr>
            <tr>
              <th>Total Earnings Goal</th>
              <td>
                {(parseFloat(pledgeDrive.cents_earned)/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / {(parseFloat(pledgeDrive.earned_goal)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </td>
            </tr>
            <tr><td></td><td></td></tr>
            <tr>
              <th>Material Fee</th>
              <td>{(parseFloat(pledgeDrive.material_fee)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
            </tr>
            
          </table>


          <h3 className='margin-top-2'>Pledge Page</h3>
          <div className="gray">The page is used for donors to pledge money to students. <Link to={`/pledge-preview/${id}`} className="text-link">View Pledge Page</Link></div>

          <table className="vertical margin-top-2">

            <tr>
              <th>Page Title</th>
              <td>
                {pledgeDrive.page_title ? pledgeDrive.page_title : "-"}
              </td>
            </tr>
            <tr>
              <th>Page Message</th>
              <td>
                {pledgeDrive.page_content ? pledgeDrive.page_content : "-"}
              </td>
            </tr>
            <tr>
              <th>Title Sponsor</th>
              <td>
                <img src={pledgeDrive.title_sponsor?.image_url} style={{width: "200px"}} />
                
                {/* {JSON.stringify(pledgeDrive.title_sponsor)} */}
              </td>
            </tr>
          </table>

          

          




        </>

      }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(pledgeDrive, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubPledgeDrive;