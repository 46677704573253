import React from 'react';
import { Link } from 'react-router-dom';

const OnboardingDone = () => {
  return (
    <div className="margin-3">
      <div className="center card no-hover">
        <h1>Onboarding Complete!</h1>

        <h2>You can now start using Hero Run</h2>

        <Link to="/" className="button margin-top-3">
          Continue
        </Link>
      </div>

    </div>
  )
}

export default OnboardingDone;