

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { createAward, updateAward } from 'redux/admin/awardActions';
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import Modal from '../../components/Modal';
import { useNavigate } from "react-router-dom"

import { getBadges } from '../../redux/admin/badgeActions';


const AwardSchema = yup.object().shape({
  // id: yup.string(),
  // title: yup.string().required("Title is required."),
  // content: yup.string().required("Content is required."),

  // active: yup.string(),
});

export const BadgeModal = ({toggleModal, setSelectedBadge}) => {
  const [badges, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchBadges();
  }, []);


  const fetchBadges = () => {
    doFetch(getBadges);
  }

  const badgeClicked = (badge) => {
    setSelectedBadge(badge);
    toggleModal();
  }

  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <div className='modal-header flex-cont align-center'>
          <h1 className="flex-1">Select Badge</h1>
          <div onClick={toggleModal} className="button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

      <div className="padding-1">
        {badges &&
          <table className="basic">
            {badges.map(badge => {
              return (
                <tr>

                  <td className="fit nowrap">
                    <div onClick={() => badgeClicked(badge)} className="small button">
                      Select
                    </div>
                  </td>

                  <td className="fit nowrap">
                    <img style={{width: '75px'}} src={badge.image_url} alt="" />
                  </td>

                  <td className="bold">
                    <div className="bold" to={`/badges/edit/${badge.id}`}>
                      {badge.name}
                    </div>
                  </td>
                </tr>
              )
            })}
          </table> 
        }
      </div>

      <div className='modal-footer flex-cont align-items-center'>
        <div onClick={toggleModal} className="button ghost">Cancel</div>
      </div>
    </Modal>
  )
}

export const AdminAwardFields = ({currentAward, register, errors, setValue}) => {

  const [badgeModalOpened, setBadgeModalOpened] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(false);

  const selectBadgeClicked = () => {
    setBadgeModalOpened(o => !o);
  }

  const toggleBadgeModal = () => {
    setBadgeModalOpened(o => !o);
  }

  useEffect(() => {
    if (selectedBadge) {
      setValue('award_attributes.badge_id', selectedBadge.id)
    }
  }, [selectedBadge]);

  useEffect(() => {
    if (currentAward) {
      // setValue('color_palette_id', currentAvatarPartCat?.palette?.id)
      setSelectedBadge(currentAward?.badge);
    }
  }, [currentAward]);

  return (
    <>
      {badgeModalOpened &&
        <BadgeModal toggleModal={toggleBadgeModal} setSelectedBadge={setSelectedBadge} />
      }
      <input type="hidden" name="award_attributes.id" ref={register} defaultValue={currentAward?.id} />

      <div className="flex-cont">
        <label htmlFor="award_attributes.name"> 
          <input 
            type="text" 
            ref={register}
            placeholder="Name"
            name="award_attributes.name"
            defaultValue={currentAward?.name}
          />
          {errors?.award_attributes?.name && <div className="field-error">{errors?.award_attributes?.name?.message}</div>}
        </label>
      </div>

      <div className="flex-cont">
        <label htmlFor="award_attributes.description"> 
          <textarea 
            ref={register}
            placeholder="Description"
            name="award_attributes.description"
            defaultValue={currentAward?.description}
          />
          {errors?.award_attributes?.description && <div className="field-error">{errors?.award_attributes?.description.message}</div>}
        </label>
      </div>

      <div className="flex-cont">
        
        <label htmlFor="award_attributes.coins"> 
          <span>Coins Awarded</span>
          <input 
            type="number" 
            ref={register}
            name="award_attributes.coins"
            defaultValue={currentAward?.coins}
          />
          {errors?.award_attributes?.coins && <div className="field-error">{errors.award_attributes.coins.message}</div>}
        </label>

        <label htmlFor="award_attributes.max_earned_count"> 
          <span>Max times earned (leave blank for infinity)</span>
          <input 
            type="number" 
            ref={register}
            name="award_attributes.max_earned_count"
            defaultValue={currentAward?.max_earned_count}
          />
          {errors?.award_attributes?.max_earned_count && <div className="field-error">{errors.award_attributes.max_earned_count.message}</div>}
        </label>

        {currentAward &&

          <label htmlFor="max_earned_count"> 
            <span>Earned Count reset on start of next:</span>
            <select 
              ref={register}
              name="award_attributes.earned_count_reset_on"
              defaultValue={currentAward?.earned_count_reset_on}
            >
              <option value="">Select option:</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
              <option value="campaign">Campaign</option>

            </select>
            {errors?.award_attributes?.earned_count_reset_on && <div className="field-error">{errors.award_attributes.earned_count_reset_on.message}</div>}
          </label>
        }
        {!currentAward &&
          <label htmlFor="max_earned_count"> 
            <span>Earned Count reset on start of next:</span>
            <select 
              ref={register}
              name="award_attributes.earned_count_reset_on"
              defaultValue={""}
            >
              <option value="">Select option:</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
              <option value="campaign">Campaign</option>

            </select>
            {errors?.award_attributes?.earned_count_reset_on && <div className="field-error">{errors.award_attributes.earned_count_reset_on.message}</div>}
          </label>

        }
      </div>

      <div className="margin-bottom-2">
        <h2>Badge</h2>
        <div onClick={selectBadgeClicked} className="ghost button">{selectedBadge ? 'Change' : 'Select'} Badge</div>

        <input ref={register} type="hidden" name="award_attributes.badge_id" />

        {selectedBadge &&
          <div className="margin-top-1">
            <div className="card no-hover inline-flex align-center">
              <img style={{width: '100px'}} src={selectedBadge.image_url} alt="" />
              <div className="margin-left-1">
                {selectedBadge.name}
              </div>
            </div>
          </div>
        }
      </div>
      
    </>
  )

}

export default AdminAwardFields;