

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getCurrentSchoolYearWaivers } from 'redux/club/schoolYearActions';
import { getSchoolYearFees } from 'redux/club/schoolYearFeeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Modal from 'components/Modal';
import { DateTime } from 'luxon';

import SchoolYearWaiverForm from './SchoolYearWaiverForm';
// import { createSchoolYearWaiver, updateSchoolYearWaiver, deleteSchoolYearWaiver } from 'redux/club/schoolYearWaiverActions';


const ClubSchoolYearWaivers = ({currentUser}) => {


  const { id } = useParams();


  const [page, setPage] = useState(1);
  const [waivers, waiversLoading, waiversLoaded, waiversError, doWaiversFetch, setWaiversResult, pagy] = useApi(); 
  const [deletedPledge, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledge] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPledge, setEditingPledge] = useState(null);


  const [schoolYearFees, schoolYearFeesLoading, schoolYearFeesLoaded, schoolYearFeesError, doSchoolYearFeesFetch, setSchoolYearFeesResult] = useApi(); 

  useEffect(() => {
    if (!schoolYearFees) {
      fetchSchoolYearFees();
      // alert('here')
    }
    fetchWaivers()
  }, []);

  const fetchSchoolYearFees = () => {
    doSchoolYearFeesFetch(getSchoolYearFees, {
      query: {
        current: true
      }
    });
  }

  const handlePageChange = (p) => {
    setPage(p);
  };

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchWaivers();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPledge(null);
    }
  }, [modalOpen])


  const fetchWaivers = () => {
    doWaiversFetch(getCurrentSchoolYearWaivers);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPledge, setDeletingPledge] = useState(null);
  

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchWaivers();
    }
  }

  useEffect(() => {
    // alert(waiversLoading)
  }, [waiversLoading])


  const editClicked = pledge => {
    setEditingPledge(pledge);
    toggleModal();
  }

  const deleteClicked = pledge => {
    setDeletingPledge(pledge);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPledge) {
      // doDeleteFetch(deletePledge, {query: {id: deletingPledge.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  

  if (schoolYearFeesLoaded && !schoolYearFees) {
    return(
      <div>This campaign does not have any participating students. Please add students to your school to continue.</div>
    )
    
  }
    
  

  return (
    <>
      
      
      <div className="cardx no-hover margin-bottom-2">





        {schoolYearFees &&
          <div onClick={toggleModal} className="button margin-bottom-2">
            <i className="fas fa-plus margin-right-half font-08"></i>
            Add Waiver
          </div>
        }

        {waiversLoading && 
          <div className="spinner"></div>
        }
        {waivers && !waiversLoading &&

          <table className="basic">
            <tr>
              <th className='fit nowrap'>Student</th>
              <th className='fit nowrap'>Adjusted Fee</th>
              <th></th>
            </tr>
            {waivers?.map(waiver => (
              <tr>
                <td className='fit nowrap'>{waiver.school_year_fee?.athlete_name}</td>
                <td className='fit nowrap'>{parseFloat(waiver.new_fee).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                <td></td>
                {/* <td>
                  <pre>
                    {JSON.stringify(waiver, null, 2)}
                  </pre>
                </td> */}
              </tr>
            ))}
          </table>
        }

      </div>

      <pre>
          {JSON.stringify(waivers, null, 2)}
        </pre>
 
      


      {modalOpen &&
        <SchoolYearWaiverForm schoolYearFees={schoolYearFees} schoolYearID={id} toggleModal={toggleModal} currentWaiver={editingPledge} />
      }

    </>
  )
}


export default ClubSchoolYearWaivers;