

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PeTeacherBottomNav from './PeTeacherBottomNav';



const PeTeacherLayout = ({currentClub, currentUser, children}) => {

  return (

    <>
      <PeTeacherBottomNav />

      {currentClub && currentUser &&
        <div className="parent">
          <div className="content-cont flex-cont">
            <div className="narrow-2 content margin">
              {children}
            </div>

          </div>
        </div>
      }

    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(PeTeacherLayout);