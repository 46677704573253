import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useApi } from '../../api';
import { DateTime } from "luxon";
import { getMyCareers } from 'redux/user/userActions';
import { careeroutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { VictoryContainer, VictoryTheme, VictoryChart, VictoryBar, VictoryAxis, VictoryLine, VictoryArea } from "victory";
import { Link } from 'react-router-dom';

const ProfileActivity = () => {


  const [careers, loading, loaded, error, doFetch, setResult] = useApi(); 

  // useEffect(() => {
  //   fetchMyCareers();
  // }, []);

  // const fetchMyCareers = async () => {
  //   doFetch(getMyCareers);
  // };

  const barData = [
    { x: "2015-16",   y: Math.ceil(Math.random()*100)},
    { x: "2016-17", y: Math.ceil(Math.random()*100)},
    { x: "2017-18", y: Math.ceil(Math.random()*100)},
    { x: "2018-19", y: Math.ceil(Math.random()*100)},
    { x: "2019-20", y: Math.ceil(Math.random()*100)},
    { x: "2020-21", y: Math.ceil(Math.random()*100)},
  ];

  return (
    <>
      <h2 className="mobile-center">Career</h2>

      <div className="grid-layout">

        <div className="align-center justify-center card grid-item span-1-v box">
          <div className="relative">
            <div className="number">
              5:32
            </div>
            <div className="number-label">FASTEST MILE</div>
          </div>
        </div>



        <div className="align-center justify-center card grid-item span-1-v box">
          <div className="relative">
            <div className="number">
              432
            </div>
            <div className="number-label">TOTAL MILES</div>
          </div>
        </div>

        <div className="align-center justify-center card grid-item span-1-v box">
          <div className="relative">
            <div className="number">
              $1,340
            </div>
            <div className="number-label">TOTAL EARNED</div>
          </div>
        </div>

        <div className="align-center justify-center card no-padding grid-item box span-6-h">
            <div>
            <div className="number-label">MILES BY YEAR</div>
           
            <VictoryChart
              theme={VictoryTheme.grayscale}
              domainPadding={10}
              containerComponent={<VictoryContainer style={{width: 'auto'}} />}
              
            >
              <VictoryAxis crossAxis />
              <VictoryAxis crossAxis dependentAxis tickFormat={(t) => `${t}`} />

              <VictoryAxis dependentAxis crossAxis style={{
                grid: {stroke: "rgba(0,0,0,.2)"},
              }} />
              <VictoryBar
                style={{ data: { fill: "#53b3d5" } }}
                cornerRadius={4}
                data={barData}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 }
                }}
              />
            </VictoryChart>    
            </div>
          </div>
      </div>

      {/* <div className="margin-bottom-2"></div>

      {loading &&
        <div className="spinner"></div>
      }

      {!loading && (!careers || careers.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-flag blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">You are not a member of any careers.</div>
          <div className="margin-top-2">
            <Link to="/career-search" className="button">Apply to a Career</Link>
          </div>
        </div>
      }
      

      {careers && careers.map( (career, careerIndex) => {
        return(
          <div className="card flex-cont align-center">
            <div className="margin-right-1 bold">{career.athlete_name}</div>
            <div className="flex-1 margin-right-1">{career.career_name}</div>

            <div>{career.status}</div>
            
          </div>
        )
      })}  */}
    </>
  )
}

export default ProfileActivity;