import { andyFetch } from '../../api';

export const getRunThemes = (params) => {
  return async (dispatch, getState) => {

    const url = "club/run_themes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRunThemes error", e);
    }
  }
};