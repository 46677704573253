

import React, { useEffect } from 'react';

import { NavLink, Link, Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import { useApi } from '../../api';
import AdminBottomNav from 'screens/admin/AdminBottomNav';


const AdminLayout = ({pages, currentUser, children}) => {

  const navigate = useNavigate();
  const [newCurrentUser, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    if (currentUser) {
      fetchCurrentUser();
    }  
  }, []);

  const fetchCurrentUser = () => {
    doFetch(reupdateUser);
  };

  useEffect(() => {
    if (loaded) {

      if (!newCurrentUser || newCurrentUser.role !== "admin") {
        navigate('/');
      }
    }
    
  }, [loaded]);


  if (loading) {
    return (
      <div className="padding-1 center flex-cont justify-center">
        <div className="spinner"></div> 
      </div>
    );
  }


  return (

    <>

    <AdminBottomNav />

    {loaded &&


      <div className="parent">

        <div className="content-cont flex-cont">

          
          
          {/* <div className="side-nav">
            <ul>
              <li >
                <NavLink to="">
                  <i className="fas fa-tachometer-alt"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li className="apex">
                <div className="title">
                  <i className="fas fa-running"></i>
                  <span>RACES</span> 
                </div>
                <ul>
                  <li><NavLink to="races" >Races</NavLink></li>
                  <li><NavLink to="race-files" >Race Files</NavLink></li>
                  <li><NavLink to="races-logs" >Race Logs</NavLink></li>
                  <li><NavLink to="tracks" >Tracks</NavLink></li>
                </ul>
              </li>


              <li className="apex">
                <div className="title">
                  <i className="fas fa-users"></i>
                  <span>USERS</span> 
                </div>
                <ul>
                  <li><NavLink to="users" >Users</NavLink></li>
                  <li><NavLink to="athletes" >Athletes</NavLink></li>
                  <li><NavLink to="purchases" >Purchases</NavLink></li>
                </ul>
              </li>


              <li className="apex">
                <div className="title">
                  <i className="fas fa-school"></i>
                  <span>CLUBS</span> 
                </div>
                <ul>
                  <li><NavLink to="clubs" >Clubs</NavLink></li>
                  <li><NavLink to="teams" >Teams</NavLink></li>
                  <li><NavLink to="charities" >Charities</NavLink></li>
                  <li><NavLink to="pledge-drives" >Pledge Drives</NavLink></li>
                  <li><NavLink to="pledge-drive-subs" >Pledge Drive Subs</NavLink></li>
                  <li><NavLink to="pledges" >Pledges</NavLink></li>
                </ul>
              </li>
              
            
              
              
              
              
              
              
              
              
              
            </ul>

            
          </div> */}

          <div className="narrow-2 content margin padding-top-1">
            {/* <Outlet /> */}
            {children}
          </div>
        </div>
      </div>
    }

    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    pages: state.pageState.pages,
  };
} 

export default connect(mapState)(AdminLayout);