import { andyFetch } from '../../api';

export const getMerchProducts = (params) => {
  return async (dispatch, getState) => {

    const url = "club/merch_products";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMerchProducts error", e);
    }
  }
};



export const createMerchProduct = (params) => {

  return async (dispatch, getState) => {

    const url = "club/merch_products";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createMerchProduct error.');
      }
    }
    catch (e) {
      console.log("createMerchProduct error", e);
    }
  }
};


export const updateMerchProduct = (params) => {

  return async (dispatch, getState) => {

    const url = `club/merch_products/${params.payload.merch_product.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateMerchProduct error.');
      }
    }
    catch (e) {
      console.log("updateMerchProduct error", e);
    }
  }
};

export const getMerchProduct = (params) => {
  return async (dispatch, getState) => {
    const url = `club/merch_products/${params.query.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getMerchProduct error.');
      }
    }
    catch (e) {
      console.log("getMerchProduct error", e);
    }
  }
};

export const deleteMerchProduct = (params) => {
  
  return async (dispatch, getState) => {

    const url = `club/merch_products/${params.query.id}`;

    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteMerchProduct error.');
      }
    }
    catch (e) {
      console.log("deleteMerchProduct error", e);
    }
  }
};

