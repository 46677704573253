

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const ClubPledgeDriveNav = ({id}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/school-year`}>Dashboard</NavLink></li>
        <li><NavLink end to={`/school-year/fee-payments`}>Fee Payments</NavLink></li>
        <li><NavLink end to={`/school-year/waivers`}>Fee Waivers</NavLink></li>
      </ul>
    </div>
  )
}

export default ClubPledgeDriveNav;