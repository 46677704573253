import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'styles/Noti.scss';
import chest from 'images/chest.png';
import CountUp from 'components/CountUp';
import { removeNotiPopup } from 'redux/noti/notiActions';
import store from 'redux/store';


const Noti = ({index, noti, toggleNoti, current}) => {

  const [started, setStarted] = useState(false);

  const [hidden, setHidden] = useState(false);

  // useEffect(() => {

  //   if (current) {
  //     setTimeout(() => {
  //       setStarted(true);
  //     }, 10);
  //   }
  //   else {
  //     setStarted(false);
  //   }

    
  // }, [current]);

  useEffect(() => {

    // alert('here');

    setStarted(true);
    // setTimeout(() => {
    //   setStarted(true);
    // }, 10);
  

    
  }, []);

  const hideNoti = () => {
    setStarted(false);


    setTimeout(() => {
      store.dispatch(removeNotiPopup(noti.id));
    }, 300);
    

    // store.dispatch(removeNotiPopup(noti.id))
  }




  return (
    <>

      <div className={`noti-master`}>
      
      <div className={`${started ? 'start' : ''} noti-cont`}>
        <div className="noti-content">

        <div className="padding-1">

          {/* <div>{JSON.stringify(current)}</div> */}
          <div className="noti-inner-content">
            {/* {children} */}
            

              <div className="flex-cont align-center justify-center">
                <div className="flex-cont column align-center">

                  
                  <div className="starburst-cont">
                    <div className="starburst"></div>
                    <div className="feather"></div>
                    <img src={chest} alt=""/>
                  </div>
                  
                  <div className="center">
                  <div className="bottom">
                    <div className="noti-number gold">
                      <div className={`${started ? 'start' : ''} plus-cont`}>+100</div>
                      <div className="flex-cont  align-baseline justify-center">
                        <i className="coins-icon font-2 fas fa-coins padding-right-1"></i>
                        <div>
                          <CountUp delay={1000} startNumber={2889} number={3024} decimalPlaces={0} />
                        </div>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  
                    <h1 className="margin-top-3">Logging in is the first step!</h1>
                    <h3>Here's your daily reward.</h3>
                    
               
                      <div className="noti-button">
                        <div onClick={hideNoti} className="font-1-5 wide button">Nice!</div>
                      </div>
                      
         
                    <div className="margin-top-2"></div>

                    
                  </div>
                  
                </div>
              </div>
              
              
              
            </div>
            
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

const Notis = ({currentUser, notiPopups}) => {

  const [currentNoti, setCurrentNoti] = useState(notiPopups?.[0]);

  const [startBG, setStartBG] = useState(false);
  const [hideBG, setHideBG] = useState(true);



  useEffect(() => {
    if (notiPopups) {
      setCurrentNoti(notiPopups[0]);
    }

    // alert(notiPopups.length);

    if (!notiPopups || notiPopups.length === 0) {
      setStartBG(false);

      setTimeout(() => {
        setHideBG(true);
      }, 300);
    }
    else {
      setStartBG(true);
      setHideBG(false);
    }
    
  }, [notiPopups]);


  return (
    <>

      {!hideBG &&
      <>
        <div className={`noti-guard`}></div>
        <div className={`noti-bg ${startBG ? "start" : ""}`}>
          {/* {JSON.stringify(notiPopups)}
          <br/>
          <br/>
          {JSON.stringify(currentNoti)} */}
        </div>
        </>
      }

      
      
      {currentNoti &&
        <Noti key={currentNoti.id} index={0} current={true} noti={currentNoti} />
      }
      {/* {notiPopups.map((noti, index) => {
        return <Noti index={index} current={currentNoti && currentNoti.id === noti.id} noti={noti} />
      })} */}

    </>
  )
}


const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    notiPopups: state.notiState.notiPopups
  };
} 

export default connect(mapState)(Notis);