import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'styles/Noti.scss';
import chest from 'images/chest.png';
import CountUp from 'components/CountUp';
import { removeNotiPopup, readListables, fetchListables } from 'redux/noti/notiActions';
import store from 'redux/store';



const Notifications = ({unreadNotiListables, readNotiListables}) => {

  useEffect(() => {

    store.dispatch(readListables());

    return(() => {
      store.dispatch(fetchListables());
    })
    
  }, []);


  return( 
    <div className="narrow-2 padding-top-1">
      <h1>Notifications</h1>



      {/* <div>
        {JSON.stringify(readNotiListables)}
      </div>

      <br/>
      <div>
        {JSON.stringify(unreadNotiListables)}
      </div> */}

      {/* {!unreadNotiListables && 
        <em>There was a problem gathering oyur notifications.</em>
      } */}
      {(readNotiListables && readNotiListables.length === 0) && (unreadNotiListables && unreadNotiListables.length === 0) &&
        <em>No notifications.</em>
      }

      {unreadNotiListables && unreadNotiListables.length !== 0 &&
        <ul className="list noti-list">
          {unreadNotiListables.map(noti => 

            <li className="noti-line flex-cont">
              <div className="pulser red margin-right-1"></div>
              <div className="flex-1 margin-right-1">{noti.description}</div>
              <div>
                {/* <div className="ghost button">DISMISS</div> */}
              </div>

            </li>
          )}
        </ul>
      }

      {readNotiListables && readNotiListables.length !== 0 &&
        <ul className="list noti-list">
          {readNotiListables.map(noti => 

            <li className="noti-line flex-cont">
              <div className="flex-1 margin-right-1">{noti.description}</div>
              <div>
                {/* <div className="ghost button">DISMISS</div> */}
              </div>

            </li>
          )}
        </ul>
      }


      
      

    </div>
  );
}

const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    unreadNotiListables: state.notiState.unreadNotiListables,
    readNotiListables: state.notiState.readNotiListables,
  };
} 

export default connect(mapState)(Notifications);