import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';
import { getPayments, createPayment, updatePayment, deletePayment } from 'redux/admin/paymentActions';


const PaymentSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
});


const PaymentForm = ({toggleModal, currentPayment}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PaymentSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      payment: {
        ...data
      }
    }
    if (currentPayment && currentPayment.id) {
      doFetch(updatePayment, {payload: d});
    }
    else {
      doFetch(createPayment, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentPayment ? 'Edit' : 'New'} Payment</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentPayment?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentPayment?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>





      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminPayments = ({currentUser}) => {

  const [payments, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedPayment, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPayment] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPayment, setEditingPayment] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPayments();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPayment(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!payments) {
      fetchPayments();
    }
  }, []);

  const fetchPayments = () => {
    doFetch(getPayments);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPayment, setDeletingPayment] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPayments();
    }
  }


  const editClicked = payment => {
    setEditingPayment(payment);
    toggleModal();
  }

  const deleteClicked = payment => {
    setDeletingPayment(payment);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPayment) {
      doDeleteFetch(deletePayment, {query: {id: deletingPayment.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <PaymentForm currentPayment={editingPayment} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Payments</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Payment</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {payments &&
        <table className="basic">
          {payments.map(payment => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div style={{fontSize: '3rem', backgroundImage: `url( ${payment?.avatar_url} )`}} className="avatar-cont smal">

                  </div>
                </td>
                <td className="bold fit nowrap">{payment.name}</td>

      

                <td></td>
                
                <td className="fit nowrap">
                  <div onClick={() => editClicked(payment)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(payment)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Payment</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingPayment?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!payments || payments.length === 0) &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminPayments;