
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { getInspos, createInspo, updateInspo, deleteInspo } from 'redux/club/inspoActions';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Masonry from 'react-masonry-css'
import InspoForm from './InspoForm';
import InspoCard from './InspoCard';

const ClubInspos = ({currentUser}) => {

  const [inspos, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedInspo, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedInspo] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingInspo, setEditingInspo] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingInspo, setViewingInspo] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);
    if (reload) {
      fetchInspos();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingInspo(null);
    }
  }, [modalOpen]);


  const toggleViewModal = (reload = false) => {
    setViewModalOpen(o => !o);
    // if (reload) {
    //   fetchInspos();
    // }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingInspo(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (!inspos) {
      fetchInspos();
    }
  }, []);

  const fetchInspos = () => {
    doFetch(getInspos);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingInspo, setDeletingInspo] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchInspos();
    }
  }


  const editClicked = inspo => {
    setEditingInspo(inspo);
    toggleModal();
  }

  const deleteClicked = inspo => {
    setDeletingInspo(inspo);
    toggleDeleteModal();
  }

  const viewClicked = (inspo) => {
    setViewingInspo(inspo);
    toggleViewModal();
  }

  const confirmDestroy = () => {
    if (deletingInspo) {
      doDeleteFetch(deleteInspo, {query: {id: deletingInspo.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  
  const myBreakpointsAndCols = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const andy = () => {
    setModalOpen(o => !o);
  }

  // const andy = "Help"
  return (
    <div className="padding-top-1">
      {modalOpen &&
        <Modal focusTrap={true} toggleModal={toggleModal}>
          <InspoForm currentInspo={editingInspo} toggleModal={toggleModal} />
        </Modal>
      }

      {viewModalOpen &&
        <Modal focusTrap={false} toggleModal={toggleViewModal}>

          
          <div className="flex-cont">
            <div className="flex-1">
              <img style={{width: '100%'}} src={viewingInspo?.image_url} alt="" />
            </div>

            <div className="flex-1 padding-3">
              <div className="flex-cont">
                <h2 className="flex-1">{viewingInspo?.title}</h2>
                <div onClick={toggleViewModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>
              </div>
              
              
              <div>
                {viewingInspo?.content}
              </div>
            </div>


          </div>
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Inspiration</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Inspiration</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {inspos?.map(inspo => {
            return (
              <InspoCard onViewClicked={viewClicked} onEditClicked={editClicked} inspo={inspo} url={inspo.image_url} title={inspo.title} content={inspo.content} />
            )
          })
        }
      </Masonry>
      

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Inspo</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingInspo?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !inspos &&
        <em>No results.</em>
      }


      


    </div>
  )
}

export default ClubInspos;