import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import sessionReducer from './session/sessionReducer';
import uiReducer from './ui/uiReducer';
import userReducer from './user/userReducer';
import clubReducer from './club/clubReducer';
import notiReducer from './noti/notiReducer';
import raceReducer from './club/raceReducer';
import pageReducer from './pages/pageReducer';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  uiState: uiReducer,
  clubState: clubReducer,
  notiState: notiReducer,
  raceState: raceReducer,
  pageState: pageReducer,
})


const store = createStore(rootReducer, compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  ) ); 
export default store;   