import { andyFetch } from '../../api';

export const getCharities = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/charities";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCharities error", e);
    }
  }
};


export const createCharity = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/charities";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createCharity error.');
      }
    }
    catch (e) {
      console.log("createCharity error", e);
    }
  }
};


export const updateCharity = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/charities/${params.payload.charity.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateCharity error.');
      }
    }
    catch (e) {
      console.log("updateCharity error", e);
    }
  }
};


export const deleteCharity = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/charities/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteCharity error.');
      }
    }
    catch (e) {
      console.log("deleteCharity error", e);
    }
  }
};

