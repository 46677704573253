import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import store from 'redux/store';

import { completeInvite, checkInviteToken } from 'redux/user/userActions';
import { logoutUser } from 'redux/session/sessionActions';

import { useApi } from '../api';

import Spinner from 'components/Spinner';
import SubmitButton from 'components/SubmitButton';

const queryString = require('query-string');



const PasswordResetSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
});

const NewPasswordSchema = yup.object().shape({
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
});


const UserInvite = ({currentUser}) => {
  const navigate = useNavigate();

  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }

  const { register, errors, handleSubmit } = useForm({
    validationSchema: NewPasswordSchema
  });

  let location = useLocation();
  const params = queryString.parse(location.search);

  const [result, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [logoutResult, logoutLoading, logoutLoaded, logoutError, doLogoutFetch, setLogoutResult] = useApi(); 

  const [checkResult, checkLoading, checkLoaded, checkError, doCheckFetch, setCheckResult] = useApi(); 

  useEffect(() => {
    if (checkError) {
      // navigate('/');
    }
  }, [checkError, checkLoaded]);

  useEffect(() => {
    if (loaded) {
      navigate('/login');
    }
  }, [loaded]);


  useEffect(() => {
    if (currentUser) {
      tryLogout();
    }

    tryCheckInvite();
    
    //tryCheckInvite();
  }, []);

  // useEffect(() => {
  //   if (logoutLoaded) {
  //     alert('tryCheckInvite')
  //     tryCheckInvite();
  //   }
  // }, [logoutLoaded])

  const tryLogout = () => {
    doLogoutFetch(logoutUser);
  }

  const tryCheckInvite = () => {
    doCheckFetch(checkInviteToken, {query: {email: params.email, token: params.token}});
  };

  const tryCompleteInvite = async data => {
    const payload = {
      ...data,
      token: params.token
    }
    doFetch(completeInvite, {query: {email: params.email}, payload: payload});
  }

  const paramsValid = params && params.email && params.token;

  return (
    <div className="center really-narrow">
      <h1>Invite</h1>

      {!paramsValid &&
        <h2>Invalid invite. Please try again.</h2>
      }

      {checkLoading &&
        <div className="center spinner"></div>
      }
      {!checkLoading && checkLoaded && paramsValid &&
        <>
          {/* <h2>You have been invited to Hero Run.</h2> */}
          <h3>Please enter a password to finish creating your account.</h3>
          <form onSubmit={handleSubmit(tryCompleteInvite)}>


            <div className="padding-1   text-left bold">{params.email}</div> 
            <div className="flex-cont">
              <label htmlFor="password"> 
                <input 
                  type="password" 
                  ref={register}
                  placeholder="Password"
                  name="password"
                />
                {<div className="field-error">{errors?.password?.message}</div>}
              </label>
            </div>
            <div className="flex-cont">   
              <label htmlFor="password_confirmation"> 
                <input 
                  type="password" 
                  ref={register}
                  placeholder="Retype Password"
                  name="password_confirmation"
                />
                {<div className="field-error">{errors?.password_confirmation?.message}</div>}
              </label>
            </div>


            <div className="margin-top-1 center">

              {/* <div>{JSON.stringify(errors)}</div> */}

              <button className="button" disabled={false || loading || hasErrors()} type="submit">
                {loading ? (
                  <Spinner />
                ) : (
                  <span>Submit</span>
                )
                }
                
              </button>
            </div>
          </form>
        </>
      }
    </div>
  )
}

const mapState = state => ({
  currentUser: state.sessionState.currentUser,
});

export default connect(mapState)(UserInvite);