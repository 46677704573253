import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubRaceNav from './ClubRaceNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getRace, changeAthlete } from 'redux/club/raceActions';


const ClubRace = ({}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchRace()
    }
  }, [id]);

  const [race, raceLoading, raceLoaded, raceError, doFetchRace, setRaceResults] = useApi();

  const fetchRace = () => {
    doFetchRace(getRace, {query: {id: id}})
  }


  const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  const changeAthleteClicked = () => {
    setAthleteModalOpened(true);
  }
  const toggleAthleteModal = (reload=false) => {

    setAthleteModalOpened(o => !o);
    if (reload) {
      fetchRace();
    }
  }
 
  return (
    <>

      {raceLoading && <div className="spinner"></div> }
      {race &&
        <>  
          <table className="vertical">

            
            <tr>
              <th>Students</th>
              <td>{race.race_log_count}</td>
            </tr>
            <tr>
              <th>Track</th>
              <td>{race.track_name}</td>
            </tr>
            <tr>
              <th>Lap Distance</th>
              <td>{race.lap_distance} mi.</td>
            </tr>
            <tr>
              <th>Total Distance</th>
              <td>{race.total_miles} mi.</td>
            </tr>
            <tr>
              <th>Earned</th>
              <td>{(race.cents_earned/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
            </tr>
            <tr>
              <th>Duration</th>
              <td>{race.duration_text}</td>
            </tr>
          </table>


          {false && race.laps &&
            <table className="basic margin-top-4">
              <tr>
                <th></th>
                <th>Name</th>
                <th>EPC</th>
                <th>Time</th>
                <th>Miles</th>
                <th></th>
                <th>Start</th>
                <th>End</th>
                <th></th>
                {/* <th>Name</th>
                <th>Name</th>
                <th>Name</th> */}
              </tr>
              {race.laps.map((lap, index) => {
                return (
                  <tr>
                    <td className="fit nowrap">{index + 1}</td>
                    <td className="fit bold nowrap">{lap.athlete_name}</td>
                    <td className="fit nowrap">{lap.epc}</td>
                    <td className="fit nowrap">{lap.time}</td>
                    <td className="fit nowrap">{lap.miles} mi.</td>
                    <td></td>
                    <td className="fit nowrap">{lap.start_readtime}</td>
                    <td className="fit nowrap">{lap.end_readtime}</td>
                    <td className="fit nowrap">
                      <div className="ghost button danger">
                        <i className="fas fa-trash danger"></i>
                      </div>
                      
                    </td>
                    {/* <td className="fit nowrap">{lap.start_readtime}</td>
                    <td className="fit nowrap">{lap.end_readtime}</td> */}
                  </tr>
                )
              })}
            </table>
          }

          {!raceLoading && (!race.laps || race.laps.length === 0) &&
            <em>No results.</em>
          }

          




        </>

      }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(race, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubRace;