import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubInspoNav from './ClubInspoNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getInspo, changeAthlete } from 'redux/club/inspoActions';


const ClubInspo = ({}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchInspo()
    }
  }, [id]);

  const [inspo, inspoLoading, inspoLoaded, inspoError, doFetchInspo, setInspoResults] = useApi();

  const fetchInspo = () => {
    doFetchInspo(getInspo, {query: {id: id}})
  }


  const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  const changeAthleteClicked = () => {
    setAthleteModalOpened(true);
  }
  const toggleAthleteModal = (reload=false) => {

    setAthleteModalOpened(o => !o);
    if (reload) {
      fetchInspo();
    }
  }
 
  return (
    <>


      {inspo &&
        <>  
          <table className="vertical">

            <tr>
              <th>Lap Distance</th>
              <td>{inspo.lap_distance} mi.</td>
            </tr>
          </table>

          




        </>

      }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(inspo, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubInspo;