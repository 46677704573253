

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getPledges } from 'redux/club/pledgeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import { connect } from 'react-redux';
import { createStripeAccount, createAccountLink, getAccount } from 'redux/club/clubActions';
import store from 'redux/store';


const ClubStripe = ({currentClub}) => {

  const [club, loading, loaded, error, doFetch, setResult] = useApi(); 

  const tryCreateStripeAccount = () => {
    doFetch(createStripeAccount);
  };

  const [accountLink, accountLinkLoading, accountLinkLoaded, accountLinkError, doAccountLinkFetch, setAccountLinkResult] = useApi(); 

  const tryCreateAccountLink = () => {
    doAccountLinkFetch(createAccountLink, {payload: {redirect_path: "account/stripe"}});
  };

  const [account, accountLoading, accountLoaded, accountError, doAccountFetch, setAccountResult] = useApi(); 

  useEffect(() => {

    if (!currentClub) {
      return
    }

    if (currentClub.stripe_account_id) {


      doAccountFetch(getAccount);
      doAccountLinkFetch(createAccountLink, {payload: {redirect_path: "account/stripe"}});
    }
    else {


      doFetch(createStripeAccount);
    }
  }, [currentClub]);

  const [somethingLoading, setSomethingLoading] = useState(false);

  useEffect(() => {
    setSomethingLoading(loading || accountLoading || accountLinkLoading)
  }, [loading, accountLoading, accountLinkLoading])


  const [status, setStatus] = useState(null);


  useEffect(() => {
    if (!currentClub?.stripe_account_id) {
      return
    }

    if (!account) {
      setStatus("no account");
    }
    else {
      if (account.charges_enabled) {
        setStatus("enabled");
      }
      else if (account.details_submitted) {
        setStatus("submitted");
      }
      else {
        setStatus("new");
      }
      
    }


  }, [account, currentClub])






  return (
    <>
      <div className="narrow-2 padding-top-1">


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Stripe Setup</h1>
        </div>
        <div>
          {/* <div onClick={toggleModal} className="button">New Pledge</div> */}
        </div>
      </div>

      
     

      


      <div className="button" onClick={tryCreateStripeAccount}>
        Create Account
      </div>
      {loading &&
        <div className="spinner"></div>
      }
      <div className="margin-bottom-2">
        {JSON.stringify(currentClub?.stripe_account_id)}
      </div>

      <div className="button" onClick={tryCreateAccountLink}>
        Create Link
      </div>
      {accountLinkLoading &&
        <div className="spinner"></div>
      }
      <div className="margin-bottom-2">
        {JSON.stringify(accountLink)}
      </div>

      <div className="margin-bottom-2">
        <Link to="/pledge/_S7yB-385WuEnMqWj0kLsg" className="button">
          Test Pledge Page
        </Link>
      </div>

      

      {somethingLoading &&
        <div className="spinner"></div>
      }
      {!somethingLoading &&
        <>
          {status === "no account" &&
            <></>
          }
          {status === "new" &&
            <>
              {accountLink &&
                <a className="button" href={accountLink}>Start Setup</a>
              }
            </>
          }
          {status === "submitted" &&
            <>
              {accountLink &&
                <a className="button" href={accountLink}>Resume Setup</a>
              }
            </>
          }
          {status === "enabled" &&
            <>
              <div className="flex-cont align-center">
                <i className="green font-1-5 fas fa-check margin-right-1"></i>
                <div>Your account is ready to accept donations.</div>
              </div>
              
            </>
          }
          
          
        </>
      }





      
      <div className="margin-top-2 margin-bottom-2">
        <pre>
          {JSON.stringify(account, null, 2)}
        </pre>
      </div>


      

      

      </div>
    </>
  )
}



function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(ClubStripe);