import { andyFetch } from '../../api';

export const getClubs = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/clubs";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubs error", e);
    }
  }
};

export const getClub = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/clubs/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClub error", e);
    }
  }
};


export const createClub = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/clubs";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createClub error.');
      }
    }
    catch (e) {
      console.log("createClub error", e);
    }
  }
};


export const updateClub = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/clubs/${params.payload.club.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateClub error.');
      }
    }
    catch (e) {
      console.log("updateClub error", e);
    }
  }
};


export const deleteClub = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/clubs/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteClub error.');
      }
    }
    catch (e) {
      console.log("deleteClub error", e);
    }
  }
};

export const acceptClub = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/clubs/accept`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('acceptClub error.');
      }
    }
    catch (e) {
      console.log("acceptClub error", e);
    }
  }
};

export const rejectClub = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/clubs/reject`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('rejectClub error.');
      }
    }
    catch (e) {
      console.log("rejectClub error", e);
    }
  }
};