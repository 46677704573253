

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getTeams } from 'redux/club/teamActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { API_URL } from '../../../api.js';
import Modal from 'components/Modal';

import { Link } from 'react-router-dom';

import { createTeam, updateTeam, deleteTeam } from 'redux/club/teamActions';



const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})





const TeamSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  grade: yup.string().required("Grade is required.").oneOf(["Pre-K", "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"]),
  avatar: yup.string(),
});


const TeamForm = ({toggleModal, currentTeam}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: TeamSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [teamsResult, teamsLoading, teamsLoaded, teamsError, doFetchTeams, setTeamResults] = useApi();

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);


  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);


  const getAvatarURL = () => {
    return fileURL ? fileURL : currentTeam?.avatar_url
  }



  const onSubmit = async data => {
    let d = {
      team: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentTeam && currentTeam.id) {
      doFetch(updateTeam, {payload: d});
    }
    else {
      doFetch(createTeam, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentTeam ? 'Edit' : 'New'} Team</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentTeam?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentTeam?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>

            <label htmlFor="grade"> 
              <select 
                ref={register}
                name="grade"
                defaultValue={currentTeam?.grade}
              >
                <option disabled selected>Select grade</option>
                {["Pre-K", "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"].map(grade => {
                  return (
                    <option value={grade}>{grade}</option>
                  )
                })}

              </select>
              {errors.grade && <div className="field-error">{errors.grade.message}</div>}
            </label>
          </div>


          <div className="margin-top-1 card uppy-dd-cont">
            <div className="avatar-cont margin-bottom-2" style={{backgroundImage: `url(${getAvatarURL()})`}}>
              {!getAvatarURL() &&
                <i className="fas fa-users"></i>
              }
              {uploadLoading ? (
                  <div className="flex-cont align-center justify-center" style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.6)', position: 'absolute'}}>
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <div style={{fontSize: '1rem'}} className="hover-overlay">
                    EDIT
                  </div>
                )
              }
            </div>
            
            <br/>

            <div className="button ghost">Edit Team Logo</div>

            <input ref={register} type="hidden" name="avatar" value={file} />
            <DragDrop
              className="andy"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: 'Drop here or %{browse}',
                  browse: 'browse'
                }
              }}
            />
        </div>
        <StatusBar uppy={uppy} />
        <div className="field-error">
          {errors?.avatar?.message}
        </div>


        

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const ClubTeams = ({currentUser}) => {

  const [teams, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedTeam, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedTeam] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchTeams();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingTeam(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!teams) {
      fetchTeams();
    }
  }, []);

  const fetchTeams = () => {
    doFetch(getTeams);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingTeam, setDeletingTeam] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchTeams();
    }
  }


  const editClicked = team => {
    setEditingTeam(team);
    toggleModal();
  }

  const deleteClicked = team => {
    setDeletingTeam(team);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingTeam) {
      doDeleteFetch(deleteTeam, {query: {id: deletingTeam.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      <div className="narrow-2x padding-top-1">
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <TeamForm currentTeam={editingTeam} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Teams</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Team</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {teams &&
        <table className="basic">
          {teams.map(team => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div style={{fontSize: '3rem', backgroundImage: `url( ${team?.avatar_url} )`}} className="team-logo small">
                  
                  </div>
                </td>
                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/teams/${team.slug}`}>
                    {team.name}
                  </Link>

                </td>

                <td className="fit">
                  {team.grade}
                </td>

                <td className="fit nowrap">
                  {team.enrollment_count} {team.enrollment_count === 1 ? "athlete" : "athletes"}
                </td>
                <td className="fit nowrap">
                  {team.team_ownerships.length} {team.team_ownerships.length === 1 ? "manager" : "managers"}
                </td>

                <td></td>
                
                {/* <td className="fit nowrap">
                  <div onClick={() => editClicked(team)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(team)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td> */}
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Team</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingTeam?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!teams || teams.length === 0) &&
        <em>No results.</em>
      }


      

      </div>
    </>
  )
}


export default ClubTeams;