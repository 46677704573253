

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAvatarParts } from 'redux/admin/avatarPartActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createAvatarPart, updateAvatarPart, deleteAvatarPart, getAvatarPart} from 'redux/admin/avatarPartActions';
import { getAvatarPartCats} from 'redux/admin/avatarPartCatActions';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import { getPalettes } from 'redux/admin/paletteActions';

import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput } from '@uppy/react'


import AvatarBase, { SVG } from '../avatar/AvatarBase';


import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import Item from './SVG';



const uppy2 = Uppy({
  meta: { type: 'test_svg[]' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowMultipleUploads: true,
    allowedFileTypes: ['.svg']
  },
  autoProceed: true
})

 
uppy2.use(AwsS3Multipart, { 
  companionUrl: API_URL
})




const AvatarPartSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  description: yup.string(),
  // gender: avatar_part.gender,
  layer: yup.string(),
  x_offset: yup.string(),
  y_offset: yup.string(),
  // is_body_part: avatar_part.is_body_part,
  default_color: yup.string(),
  location_type: yup.string(),
  price: yup.string(),
  scale: yup.string(),
  rotation: yup.string(),
  avatar_part_cat_id: yup.string(),
});


export const PaletteModal = ({toggleModal, setSelectedPalette}) => {
  const [palettes, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchPalettes();
  }, []);


  const fetchPalettes = () => {
    doFetch(getPalettes);
  }

  const paletteClicked = (palette) => {
    setSelectedPalette(palette);
    toggleModal();
  }

  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <div className='modal-header flex-cont align-center'>
          <h1 className="flex-1">Select Palette</h1>
          <div onClick={toggleModal} className="button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

      <div className="padding-1">
        {palettes &&
          <table className="basic">
            {palettes.map(palette => {
              return (
                <tr>

                <td className="fit nowrap">
                  <div onClick={() => paletteClicked(palette)} className="small button">
                    Select
                  </div>
                </td>

                  <td className="bold fit nowrap">
                    <div className="bold" to={`/palettes/edit/${palette.id}`}>
                      {palette.name}
                    </div>
                  </td>



                  <td>
                    {palette?.colors?.map(color =>
                    
                      <div
                        style={{marginRight: '5px', boxShadow: '0 3px 10px rgba(0,0,0,.25)', cursor: 'pointer', borderRadius: '50%', display:'inline-block', width: '30px', height: '30px', backgroundColor: color.hex}}>
                      </div>
                    )}   
                  </td>
                </tr>
              )
            })}
          </table> 
        }
      </div>

      <div className='modal-footer flex-cont align-items-center'>
        <div onClick={toggleModal} className="button ghost">Cancel</div>
      </div>
    </Modal>
  )
}

export const AvatarPartForm = ({toggleModal, currentAvatarPart}) => {
  const navigate = useNavigate();
  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: AvatarPartSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [avatarPartsResult, avatarPartsLoading, avatarPartsLoaded, avatarPartsError, doFetchAvatarParts, setAvatarPartResults] = useApi();
  const [cats, catsLoading, catsLoaded, catsError, doFetchCats, setCatResults] = useApi();

  const fetchCats = () => {
    doFetchCats(getAvatarPartCats);
  }

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [fileURL2, setFileURL2] = useState([]);
  const [file2, setFile2] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [uppy, setUppy] = useState(null);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        alert('here 1')
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy])

  useEffect(() => {
    uppy2.on('upload', (data) => {
      alert('here 2')
      setUploadLoading(true);
    });

    uppy2.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy2.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL2(old => [...old, response.uploadURL])
      setFile2(old => [...old, uploadedFileData]);
    })
  }, [uppy2])


  useEffect(() => {
    fetchCats();

    const u = Uppy({
      meta: { type: 'avatar' },
      restrictions: { 
        // maxNumberOfFiles: 2,
        allowedFileTypes: ['.svg']
      },
      autoProceed: true
    })
    
     
    u.use(AwsS3Multipart, { 
      companionUrl: API_URL
    });

    setUppy(u);
  }, []);

  const onSubmit = async data => {
    let d = {
      test_svg: data.test_svg,
      avatar_part: {
        ...data
      }
    }
    
    if (currentAvatarPart && currentAvatarPart.id) {
      doFetch(updateAvatarPart, {payload: d});
    }
    else {
      doFetch(createAvatarPart, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      navigate('/avatar-parts')
    }
  }, [loaded])

  const getAvatarURL = () => {
    return fileURL ? fileURL : currentAvatarPart?.avatar_url
  }

  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [scale, setScale] = useState(100);
  const [rotate, setRotate] = useState(0);
  const [layer, setLayer] = useState(1);
  
  const [avatarScale, setAvatarScale] = useState(1);


  const [selectedPalette, setSelectedPalette]= useState(null);

  const [color, setColor] = useState('gray');



  useEffect(() => {
    if (currentAvatarPart && currentAvatarPart.id) {
      setLeft(currentAvatarPart.x_offset);
      setTop(currentAvatarPart.y_offset);
      setScale(currentAvatarPart.scale);
      setRotate(currentAvatarPart.rotation);
      setLayer(currentAvatarPart.layer);

      // setSelectedCat(findCat(currentAvatarPart.avatar_part_cat_id))
    }
    
  }, [currentAvatarPart])



  useEffect(() => {
    setValue('scale', scale);
  }, [scale])
  
  const [paletteModalOpened, setPaletteModalOpened] = useState(false);

  const togglePaletteModal = () => {
    setPaletteModalOpened(o => !o);
  }


  const [selectedCat, setSelectedCat] = useState(null);
  
  const catSelectChanged = e => {
    const id = e.target.value;
    const t = cats.find(t => t.id.toString() === id.toString());
    setSelectedCat(t);
  }

  const findCat = id => {
    const t = cats.find(t => t.id.toString() === id.toString());
    return t;
  }

  useEffect(() => {
    if (cats && currentAvatarPart) {
      setValue('avatar_part_cat_id', currentAvatarPart?.avatar_part_cat_id);
      setSelectedCat(findCat(currentAvatarPart.avatar_part_cat_id));
    } 
  }, [cats, currentAvatarPart, setValue]);

  useEffect(() => {
    if (selectedCat?.palette?.colors) {
      setColor(selectedCat?.palette?.colors[0].hex)
    }
  }, [selectedCat])


  return (
    <>
      {paletteModalOpened &&
        <PaletteModal setSelectedPalette={setSelectedPalette} toggleModal={togglePaletteModal} />
      }
    <form onSubmit={handleSubmit(onSubmit)}>

      




      <input 
        type="hidden" 
        name="id" 
        ref={register}
        defaultValue={currentAvatarPart?.id}
      />
      

      <div className="flex-cont">
        <label htmlFor="name"> 
          <input 
            type="text" 
            ref={register}
            placeholder="Name"
            name="name"
            defaultValue={currentAvatarPart?.name}
          />
          {errors.name && <div className="field-error">{errors.name.message}</div>}
        </label>
      </div>

      {uppy &&
        <>
          <FileInput uppy={uppy} />
          <StatusBar uppy={uppy} />
        </>
      }    
      {uppy2 &&
        <StatusBar uppy={uppy2} />
      }
      

      
{/* 
      <div className="code">
        <pre>
          {JSON.stringify(currentAvatarPart, null, 2)}
        </pre>
      </div> */}


        <div className="flex-cont">
          <div>
            <div className="margin-1">
              <div className="flex-contx">
                <label>
                  <span>Layer</span>
                  <input ref={register} defaultValue={currentAvatarPart?.layer} value={layer} onChange={e => setLayer(e.target.value)}  type="number" step="1" name="layer" id=""/>
                </label>
                <label>
                  <span>SCALE</span>
                  <input ref={register} defaultValue={currentAvatarPart?.scale} onChange={e => setScale(e.target.value)}  type="number" step=".01" name="scale" id=""/>
                </label>
                <label>
                  <span>X OFFSET</span>
                  <input ref={register} defaultValue={currentAvatarPart?.x_offset} value={left} onChange={e => setLeft(e.target.value)} type="number" step=".1" name="x_offset" id=""/>
                </label>
                <label>
                  <span>Y OFFSET</span>
                  <input ref={register} defaultValue={currentAvatarPart?.y_offset} value={top} onChange={e => setTop(e.target.value)}  type="number" step=".1" name="y_offset" id=""/>
                </label>
                
                <label>
                  <span>ROTATION</span>
                  <input ref={register} value={rotate} onChange={e => setRotate(e.target.value)}  type="number" step="1" name="rotation" id=""/>
                </label>
                <label>
                  <span>CATEGORY</span>
                  {catsLoading &&
                    <div className="spinner"></div>
                  }
                  {/* <div className="code">
                    <pre>
                    selectedCat: {JSON.stringify(selectedCat, null, 2)}
                    </pre>
                  </div> */}
                  {cats &&
                    <select defaultValue={currentAvatarPart?.avatar_part_cat_id} onChange={catSelectChanged} ref={register} name="avatar_part_cat_id">
                      <option value="" disabled selected>Select your option</option>
                      {cats.map(cat => <option value={cat.id}>{cat.name}</option>)}
                    </select>
                  }
                  {selectedCat &&
                  
                    <div style={{marginTop: '1em', width: '10em'}}>
                      {selectedCat?.palette?.colors?.map(c =>
                      
                        <div onClick={() => setColor(c.hex)}
                          style={{
                            marginRight: '5px', 
                            boxShadow: '0 3px 10px rgba(0,0,0,.25)', 
                            cursor: 'pointer', 
                            borderRadius: '50%', 
                            display:'inline-block', 
                            width: '30px', 
                            height: '30px', 
                            backgroundColor: c.hex,

                            border: color === c.hex ? '3px solid rgba(255,255,255.75)' : 'none',
                          }}>
                        </div>
                      )}   
                    </div>
                  }
                </label>
                {/* <label>
                  <span>PALETTE</span>
                  <div>
                  {selectedPalette &&
                    <div className="margin-top-1 flex-contx align-center">
                      <div className="bold fit nowrap">
                        <div className="bold margin-bottom-half">
                          {selectedPalette.name}
                        </div>
                      </div>



                      <div style={{width: '10em'}}>
                        {selectedPalette?.colors?.map(c =>
                        
                          <div onClick={() => setColor(c.hex)}
                            style={{
                              marginRight: '5px', 
                              boxShadow: '0 3px 10px rgba(0,0,0,.25)', 
                              cursor: 'pointer', 
                              borderRadius: '50%', 
                              display:'inline-block', 
                              width: '30px', 
                              height: '30px', 
                              backgroundColor: c.hex,

                              border: color === c.hex ? '3px solid rgba(255,255,255.75)' : 'none',
                            }}>
                          </div>
                        )}   
                      </div>

                    </div>
                    
                  }
                  <div onClick={togglePaletteModal} className="button ghost">
                    {selectedPalette ? 'Change' : 'Select'} Palette
                  </div>
                  </div>
                </label> */}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="andy-cont">
              <AvatarBase />

              {fileURL &&
                <SVG uppy={uppy2} register={register} baseColor={color} layer={layer} url={fileURL} left={left} top={top} scale={scale} rotate={rotate} />
              }
              {!fileURL && currentAvatarPart?.images?.map(image => 
                <>
                  <Item 
                    baseColor={color}
                    left={left} 
                    top={top} 
                    scale={scale} 
                    rotate={rotate} 
                    layer={layer}
                    layerType={image.kind} 
                    url={image.image_url} 
                  /> 
                </> 
              )}
              

            </div>  
          </div>
        </div>

       
        
        
        <div className="field-error">
          {errors?.avatar?.message}
        </div>

        <div className="field-error">
          {errors?.test_svg?.message}
        </div>


      <div className="margin-top-3 align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
    </>
  )

}

export const AdminAvatarPartEdit= () => {

  let { id } = useParams();
  const [avatarPart, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAvatarPart();
  }, [id]);

  const fetchAvatarPart = () => {
    doFetch(getAvatarPart, {query: {id: id}});
  }

  return (
    <>
      <h1>Edit Avatar Part</h1>
      <AvatarPartForm currentAvatarPart={avatarPart} />
    </>
  )
}


const AdminAvatarPartNew = () => {

  return (
    <>
      <h1>New Avatar Part</h1>

      <AvatarPartForm />
    </>
  )
}




export default AdminAvatarPartNew;