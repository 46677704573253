

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getRaces } from 'redux/admin/raceActions';
import { createRace, updateRace, deleteRace } from 'redux/admin/raceActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';

import { getTracks } from 'redux/admin/trackActions';
import { getClubs } from 'redux/admin/clubActions';


import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";

import DatePicker from 'react-datepicker';
import { DateTime } from "luxon";

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'

import { API_URL } from '../../api.js';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

const RaceSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  start_date: yup.string().nullable().required("Date is required.").typeError("Invalid date."),
  start_hour: yup.string().required("Hour is required"),
  start_minute: yup.string().required("Hour is required"),
  start_ampm: yup.string().required("AM/PM is required"),
  track_id: yup.string().required("Track is required."),
  club_id: yup.string().required("Club is required."),
  race_file_attributes: yup.object().shape({
    file: yup.string().nullable(),
  })
});


const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    maxNumberOfFiles: 1,
    allowedFileTypes: ['.csv']
  },
  autoProceed: true
})


uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})

const RaceForm = ({toggleFormModal, currentRace}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: RaceSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const [tracks, tracksLoading, tracksLoaded, tracksError, doTracksFetch, setTracksResult] = useApi();
  const [clubs, clubsLoading, clubsLoaded, clubsError, doFetchClubs, setClubResults] = useApi();


  const [fileURL, setFileURL] = useState(currentRace ? currentRace.file_url : "");
  const [file, setFile] = useState(currentRace?.file ? JSON.stringify(JSON.parse(currentRace.file)) : "");
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    doTracksFetch(getTracks);
    doFetchClubs(getClubs);

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);

  const onSubmit = async data => {

    let ddd = `${data.start_date} ${data.start_hour}:${data.start_minute} ${data.start_ampm} PST`;

    let d = {
      race: {
        ...data,
        start_datetime: ddd
      }
    }




    if (currentRace && currentRace.id) {
      doFetch(updateRace, {payload: d});
    }
    else {
      doFetch(createRace, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleFormModal(true);
    }
  }, [loaded]);

  const [selectedTrack, setSelectedTrack] = useState(null);

  const trackSelectChanged = (e) => {

    const id = e.target.value;

    console.log(tracks[0].id.toString() === '8');

    const t = tracks.find(t => t.id.toString() === id.toString());

    setSelectedTrack(t);

    setValue('lap_distance', t?.lap_distance);

    // if (currentRace && currentRace.lap_distance) {

    // }
    // else {
    //   setValue('lap_distance', t?.lap_distance);
    // }

    
        
        // .filter(t => t.id === e.target.value)[0])
    
  }

  useEffect(() => {
    setValue('track_id', currentRace?.track_id);
  }, [tracks]);


  const [selectedClub, setSelectedClub] = useState(null);

  const clubSelectChanged = (e) => {
    const id = e.target.value;
    const t = clubs.find(t => t.id.toString() === id.toString());
    setSelectedClub(t);
  }

  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentRace ? 'Edit' : 'New'} Race</h2>
        <div onClick={() => toggleFormModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1 padding-bottom-200px">

        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentRace?.id}
        />

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentRace?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label className="flex-cont align-center" htmlFor="start_datetime"> 
            <div className="margin-right-1">
              <DayPickerInput 
                className="full" 
                inputProps={{placeholder: "Start Date YYYY-MM-DD", name: `start_date`, ref: register}} 
                type="text"
                value={currentRace?.start_datetime_iso}
                
              />
              {errors.start_datetime && <div className="field-error">{errors.start_datetime.message}</div>}
            </div>
{/* 
            <div className="padding-1">
              {JSON.stringify(DateTime.fromISO(currentRace?.start_datetime_iso))}
            </div> */}

            
            {/* <input 
              type="text"
              ref={register}
              name="start_hour"
              className="small"
              defaultValue="12"
            /> */}

            <select ref={register} name="start_hour" id="" className="small">
              {[1,2,3,4,5,6,7,8,9,10,11,12].map(i => <option>{i}</option>)}
            </select>

            <div className="bold font-1-5 margin-right-a1 margin-laeft-1">:</div>

            <select ref={register} name="start_minute" id="" className="small">
              {Array.from(Array(60).keys(), num => String(num).padStart(2, '0')).map(i => <option>{i}</option>)}
            </select>
            {/* <input 
              type="text"
              ref={register}
              name="start_minute"
              className="small"
              defaultValue="00"
            /> */}

            <select ref={register} name="start_ampm" className="margin-left-1 small">
              <option value="am">AM</option>
              <option value="pm">PM</option>
            </select>
            
          </label>
        </div>

        <div className="flex-cont align-end">
          <label htmlFor="club_id">
            <span>Club</span>
            {clubsLoading &&
              <div className="spinner"></div>
            }
            {clubs &&
              <select defaultValue={currentRace?.club_id} onChange={clubSelectChanged} ref={register} name="club_id">
                <option value="" disabled selected>Select your option</option>
                {clubs.map(club => <option value={club.id}>{club.name}</option>)}
              </select>
            }
            {errors.club_id && <div className="field-error">{errors.club_id.message}</div>}
          </label>
        </div>

        <div className="flex-cont align-end">
          <label htmlFor="track_id">
            <span>Track</span>
            {tracksLoading &&
              <div className="spinner"></div>
            }
            {tracks &&
              <select defaultValue={currentRace?.track_id} onChange={trackSelectChanged} ref={register} name="track_id">
                <option value="" disabled selected>Select your option</option>
                {tracks.map(track => <option value={track.id}>{track.name}</option>)}
              </select>
            }

          </label>

          <label htmlFor="lap_distance"> 
            <span>Lap Distance (miles)</span>
            <input 
              type="text" 
              ref={register}
              placeholder="Lap Distance"
              name="lap_distance"
              defaultValue={currentRace?.lap_distance}
            />
            {/* <div>{selectedTrack?.lap_distance}</div> */}
            {errors.lap_distance && <div className="field-error">{errors.lap_distance.message}</div>}
          </label>
        </div>

        <div className="margin-top-2">

          <div className="bold">Race File</div>

          <input ref={register} type="hidden" name="race_file_attributes.file" value={file ? file : ""} />
          <input ref={register} type="hidden" name="race_file_attributes.id" 
          value={currentRace?.race_file_id} />

          

          <DragDrop
            className="andy"
            uppy={uppy}
            height="150px"
            locale={{
              strings: {
                // Text to show on the droppable area.
                // `%{browse}` is replaced with a link that opens the system file selection dialog.
                dropHereOr: 'Drop here or %{browse}',
                // Used as the label for the link that opens the system file selection dialog.
                browse: 'browse'
              }
            }}
          />

          <StatusBar uppy={uppy} />


          <div className="margin-top-1">
            {file &&
              <>
                {JSON.parse(file)?.metadata?.filename}
              </>
            }
          </div>

          {fileURL &&
            <div className="margin-top-1">
              <a href={fileURL}>Download</a>
            </div>
          }

          <div className="field-error">
            {errors?.file?.message}
          </div>

          
        </div>


        {/* <div className="padding-1">
          {JSON.stringify(currentRace?.file)}
        </div> */}

        {/* <div>{JSON.stringify(errors)}</div> */}
        
      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}



const AdminRaces = ({currentUser}) => {

  const [races, loading, loaded, error, doFetch, setResult] = useApi(); 

  const [formModalOpen, setFormModalOpen] = useState(false);
  const [editingRace, setEditingRace] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRace, setDeletingRace] = useState(null);

  const [deletedRace, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedRace] = useApi(); 

  useEffect(() => {
    if (!races) {
      fetchRaces();
    }
  }, []);
  

  const fetchRaces = () => {
    doFetch(getRaces);
  }

  const toggleFormModal = (reload = false) => {
    setFormModalOpen(o => !o);

    if (reload) {
      fetchRaces();
    }
  }

  useEffect(() => {
    if (!formModalOpen) {
      setEditingRace(null);
    }
  }, [formModalOpen]);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchRaces();
    }
  }


  const editClicked = race => {
    setEditingRace(race);
    toggleFormModal(false);
  }

  const deleteClicked = race => {
    setDeletingRace(race);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingRace) {
      doDeleteFetch(deleteRace, {query: {id: deletingRace.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  return (
    <>
      <div className="flex-cont">
        <div className="flex-1"><h1>Races</h1></div>
        <div>
          <div onClick={() => toggleFormModal(false)} className="button">New Race</div>
        </div>


      </div>
      

      {loading &&
        <div className="spinner"></div>
      }

      {races &&
        <table className="basic">
          {races.map(race => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div style={{fontSize: '3rem', backgroundImage: `url( ${race?.club_avatar_url} )`}} className="avatar-cont smal">
                  </div>
                </td>
                <td className="bold fit nowrap">{race.name}</td>
                <td className="fit nowrap">{race.club_name}</td>
                <td className="fit nowrap">{race.track_name}</td>
                <td className="fit nowrap">{race.start_datetime}</td>
                <td className="fit nowrap">{race.lap_distance} mi/lap</td>
                <td className="fit nowrap">
                  {race.file_url &&
                    <a rel="noopener noreferrer" href={race.file_url} target="_blank">DOWNLOAD</a>
                  }
                </td>
                <td></td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(race)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(race)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Race</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingRace?.full_name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {formModalOpen &&
        <Modal toggleModal={toggleFormModal}>
          <RaceForm currentRace={editingRace} toggleFormModal={toggleFormModal} />
        </Modal>
      }

      {!loading && !races &&
        <em>No races.</em>
      }


      


    </>
  )
}


export default AdminRaces;