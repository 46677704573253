


import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, NavLink, Outlet, useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import SubmitButton from '../../components/SubmitButton';
import { forgotPledge } from '../../redux/user/pledgeActions';

const queryString = require('query-string');


const ForgotSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
});

const ForgotPledge = () => {
  const navigate = useNavigate("/");

  let location = useLocation();
  const params = queryString.parse(location.search);

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: ForgotSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    doFetch(forgotPledge, {query: {email: data.email}});
  }

  useEffect(() => {
    
  }, [loaded])

  if (result && loaded) {
    return (
      <div className="margin-top-4 center narrow">
        <div className="font-1-5 margin-bottom-1 padding-2 rounded-box">
          An email has been sent to 
          <div className="bold">
            {result.email}
          </div>
           with instructions to access your pledges.
        </div>

        {/* <div>
          <pre>
            {JSON.stringify(result, null, 2)}
          </pre>
        </div> */}
          
      </div>
    )
  }


  return (
    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>

        <div className="narrow relative">


          {true &&
            <>
              <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
                <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
                  <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="bold font-2 margin-bottom-1">
                      Enter the email you previously used to pledge
                    </div>

                    <div className="margin-bottom-2">
                      <Link to="/find-pledge" className="text-link">
                        Already know your pledge code?
                      </Link>

                    </div>

                    
                    <div className="flex-cont">
                      <label htmlFor="email">
                        <span>Email</span>
                        <input defaultValue={params?.email} ref={register} placeholder="" type="text" name="email" id=""/>
                        {errors.email && <div className="field-error">{errors.email.message}</div>}
                      </label>
                    </div>

                    <div className="margin-top-2 font-1-5 justify-center align-center flex-cont">

                      <SubmitButton label={
                        <React.Fragment>
                          <span>Continue</span>
                          <i className="font-1 fas fa-arrow-right margin-left-half"></i>
                        </React.Fragment>
                      } disabled={(errors && Object.keys(errors).length !== 0)} loading={loading} />
                    </div>
                  </form>
                </div>

              </div>
            </>
          }

        </div>

      </div>
    </>
  )
}

export default ForgotPledge;