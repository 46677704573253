import React, { useState, useEffect } from 'react';
import { useOutletContext, NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
// import { getPledgeDriveSubs } from 'redux/user/pledgeActions';
import { getPledges } from 'redux/athlete/pledgeActions';
import { useApi } from '../../api';
import Bar from 'components/Bar';
import CountUp from 'components/CountUp';
import { DateTime } from "luxon";
import SharingCard from './SharingCard';
import { connect } from 'react-redux';


const ProfileFundraising = ({currentAthlete}) => {
  const {toggleShareModal} = useOutletContext();
  const {hasActiveCampaign} = useOutletContext();
  const {campaignLoading} = useOutletContext();

  const [pledges, pledgesLoading, pledgesLoaded, pledgesError, doPledgesFetch, setPledgeResults] = useApi(); 

  useEffect(() => {
    fetchPledges();
  }, []);

  const fetchPledges = () => {
    doPledgesFetch(getPledges);
  };

  return (
    <>
      <h1>Fundraising</h1>

      <div className="margin-bottom-2"></div>

      <SharingCard campaignLoading={campaignLoading} hasActiveCampaign={hasActiveCampaign} toggleShareModal={toggleShareModal} />

      {pledgesLoading &&
        <div className="spinner"></div>
      }

      {pledgesLoaded && (!pledges || pledges.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-heart blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">Check back later to see your fundraising progress!</div>
        </div>
      }

      

      {pledges?.map(pledge => {
        return (
          <div className="card no-hover margin-bottom-1">
            <div className="flex-cont align-center">

              <div className="circle-cont font-5">
                <i className="fas fa-heart blue"></i>
              </div>

              <div className="flex-1 margin-left-1">

                <div className="flex-cont">
                  <div className="flex-1 bold font-1-25 margin-bottom-4px">{pledge.pledger_name}</div>
                  <div className="gray font-08">
                    {DateTime.fromISO(pledge.created_at).toLocaleString(DateTime.DATE_MED)}
                  </div>
                </div>
                
                {pledge.pledger_message &&
                  <div className="margin-bottom-half">"{pledge.pledger_message}"</div>
                }
                <div className="flex-cont align-center">
                  <div className="flex-1">
                    <Bar progress={(pledge.cents_earned / pledge.pledger_max_cents) * 100.00} />
                  </div>
                  <div className="bold blue font-08 margin-left-1">
                    {(pledge.pledger_max_cents/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </div>

                </div>

                <div className="margin-top-05">
                  {pledge.dollars_pledged}/mi.
                </div>
                

                {/* <pre>
                  {JSON.stringify(pledge, null, 2)}
                </pre> */}
              </div>
            </div>
          </div>
        )
      })}

      
    </>
  )
}


function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
  };
} 

export default connect(mapState)(ProfileFundraising);