

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getRaceLogs, deleteRaceLog } from 'redux/admin/raceLogActions';
import { DateTime } from "luxon";
import Modal from 'components/Modal';


const AdminRaceLogs = ({currentUser}) => {

  const [logs, loading, loaded, error, doFetch, setResult] = useApi(); 

  const [deletedRaceLog, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedRaceLog] = useApi(); 

  useEffect(() => {
    if (!logs) {
      fetchRaceLogs();
    }
  }, []);

  const fetchRaceLogs = () => {
    doFetch(getRaceLogs);
  }


  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchRaceLogs();
    }
  }

  

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRaceLog, setDeletingRaceLog] = useState(null);

  const deleteClicked = raceLog => {
    setDeletingRaceLog(raceLog);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingRaceLog) {
      doDeleteFetch(deleteRaceLog, {query: {id: deletingRaceLog.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  return (
    <>
      <h1>Race Logs</h1>

      {loading &&
        <div className="spinner"></div>
      }

      {logs &&
        <table className="basic">
          {logs.map(raceLog => {
            return (
              <tr>
                <td className="bold fit nowrap">{raceLog.athlete_name}</td>
                <td className="fit nowrap">{raceLog.total_miles}mi.</td>
                <td className="fit nowrap">{raceLog.total_time}</td>
                <td className="fit nowrap">{raceLog.total_laps} laps</td>
                
                <td>{raceLog.start_datetime && DateTime.fromISO(raceLog.start_datetime).toLocaleString(DateTime.DATETIME_MED)}</td>

                <td className="fit nowrap">
                  <div onClick={() => deleteClicked(raceLog)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Race Log</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete this race log?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {!loading && !logs &&
        <em>No logs.</em>
      }


      


    </>
  )
}


export default AdminRaceLogs;