import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { getPledgeDriveSubs } from 'redux/user/pledgeActions';
import { useApi } from '../../api';
import Bar from 'components/Bar';
import CountUp from 'components/CountUp';


const ProfileFundraising = () => {

  const [pledgeDriveSubs, pledgeDriveSubsLoading, pledgeDriveSubsLoaded, pledgeDriveSubsError, doPledgeDriveFetch, setPledgeDriveResults] = useApi(); 

  useEffect(() => {
    fetchPledgeDriveSubs();
  }, []);

  const fetchPledgeDriveSubs = async () => {
    doPledgeDriveFetch(getPledgeDriveSubs);
  };

  return (
    <>
      <h2>Fundraising</h2>

      <div className="margin-bottom-2"></div>

      {pledgeDriveSubsLoading &&
        <div className="spinner"></div>
      }

      {!pledgeDriveSubsLoading && (!pledgeDriveSubs || pledgeDriveSubs.length === 0) &&
        <div className="center card no-hover">
          <div className="font-5">
            <i className="fas fa-heart blue"></i>
          </div>
          <div className="margin-bottom-2"></div>
          <div className="font-1-5 gray">Check back later to see your fundraising progress!</div>
        </div>
      }

      {pledgeDriveSubs && pledgeDriveSubs.map(sub => 


          
      
            <div className="card no-hover" style={{overflow: 'auto'}}>

              <div className="margin-bottom-1 bold font-1-75">
                {sub.pledge_drive_name}
              </div>
              <div className="flex-cont">


                {/* <div className="center margin-right-1">
                  <div className="avatar-cont"></div>
                  <div className="margin-top-1">{sub.pledge_drive_name}</div>
                </div> */}

                <div className="flex-1">
                  <div style={{marginTop: '.5em', borderRadius: '8px', backgroundColor: 'rgba(0,0,0,.05)'}} className="padding-1 flex-cont flex-wrap align-center">
                    <div className="text-right margin-right-1 bold">SHAREABLE LINK:</div>
                    <a className="button" href={`https://herorun.now.sh/pledge/${sub.code}`} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </div>

                  <div className="margin-bottom-2"></div>

                  <div>
                    <div className="padding-1">
                      <div className="font-3 bold number">

                        <CountUp number={sub.cents_earned} type="money" />

                        {/* {sub.dollars_earned} */}
                      </div>
                      <div className="number-label">EARNED</div>
                    </div>
                    <div className="padding-1">
                      <div className="font-3 bold number">
                        <CountUp number={sub.cents_max_pledged} type="money" />
                        {/* {sub.dollars_pledged} */}
                      </div>
                      <div className="number-label">PLEDGED</div>
                    </div>
                  </div>

                  <div className="full">
                    <Bar height={32} progress={79} />
                  </div>

                  <div className="margin-bottom-2"></div>

                  <div className="font-1-5 margin-bottom-1 bold">Pledges</div>

                  {sub.pledges && 
                  
                    <table className="basic">
                      <tr>
                        <th>Name</th>
                        {/* <th>Email</th> */}
                        <th>Pledged</th>
                        {/* <th>Max</th> */}
                        {/* <th></th> */}
                      </tr>
                      {sub.pledges.map(pledge => 
                        <tr>
                          <td>{pledge.pledger_name}</td>
                          {/* <td className="fit nowrap">{pledge.pledger_email}</td> */}
                          <td>{pledge.dollars_pledged} mi/lap</td>
                          {/* <td className="fit nowrap">{pledge.dollars_max}</td> */}
                          {/* <td></td> */}
                        </tr>
                      )}
                    </table>
                  }

                  {(!sub.pledges || sub.pledges.length === 0) &&
                    <em>No pledges yet.</em>
                  }



                </div>
                

                
              </div>
              
            </div>

        
        
      )}
    </>
  )
}

export default ProfileFundraising;