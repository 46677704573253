import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getTrack } from 'redux/club/trackActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubTrackNav from './ClubTrackNav';

import TrackForm from './TrackForm';

const ClubTrackLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTrack()
    }
  }, [id]);

  const [track, trackLoading, trackLoaded, trackError, doFetchTrack, setTrackResults] = useApi();

  useEffect(() => {
    if (trackLoaded) {
      // alert('loaded')
    }
  }, [trackLoaded])

  const fetchTrack = () => {
    doFetchTrack(getTrack, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editTrackClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    // alert(reload);

    if (reload) {
      fetchTrack();
    }
  } 

  return (
    <>
      {track && editModalOpened &&
        <TrackForm toggleModal={toggleEditModal} currentTrack={track} />
      }
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {track?.avatar_url &&
            <div className="team-logo-cont small margin-right-2">
              <img src={track?.avatar_url} alt="" />
            </div>
          }
          {trackLoading && <div className="loading"></div> }
          <h1>
            {track?.name}
          </h1>

          <div className="flex-1"></div>

          <div>
            <i onClick={editTrackClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubTrackNav id={id} />

        {/* {children} */}
        {React.cloneElement(children, {track: track})}

        {/* <div className="code">
          <pre>
            {JSON.stringify(track, null, 2)}
          </pre>
        </div> */}


      </div>
    </>
  )

}



export default ClubTrackLayout;