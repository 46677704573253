

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAwards } from 'redux/admin/awardActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import coinGIF from 'images/coin.gif';
import coinPNG from 'images/herocoin.png';
import coinSVG from 'images/coin.svg';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import Modal from 'components/Modal';

import { createAward, updateAward, deleteAward } from 'redux/admin/awardActions';

const AwardSchema = yup.object().shape({
  

});


const AwardForm = ({toggleModal, currentAward}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: AwardSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [awardsResult, awardsLoading, awardsLoaded, awardsError, doFetchAwards, setAwardResults] = useApi();

  const onSubmit = async data => {
    let d = {
      award: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentAward && currentAward.id) {
      doFetch(updateAward, {payload: d});
    }
    else {
      doFetch(createAward, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentAward ? 'Edit' : 'New'} Award</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentAward?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentAward?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="street"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Street"
                name="street"
                defaultValue={currentAward?.street}
              />
              {errors.street && <div className="field-error">{errors.street.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="city"> 
              <input 
                type="text" 
                ref={register}
                placeholder="City"
                name="city"
                defaultValue={currentAward?.city}
              />
              {errors.city && <div className="field-error">{errors.city.message}</div>}
            </label>
            <label htmlFor="state"> 
              <input 
                type="text" 
                ref={register}
                placeholder="State"
                name="state"
                defaultValue={currentAward?.state}
              />
              {errors.state && <div className="field-error">{errors.state.message}</div>}
            </label>
            <label htmlFor="zipcode"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Zipcode"
                name="zipcode"
                defaultValue={currentAward?.zipcode}
              />
              {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="lap_distance"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Lap Distance (miles)"
                name="lap_distance"
                defaultValue={currentAward?.lap_distance}
              />
              {errors.lap_distance && <div className="field-error">{errors.lap_distance.message}</div>}
            </label>
          </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminAwards = ({currentUser}) => {
  let { tab } = useParams();
  const navigate = useNavigate();


  const [awards, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedAward, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedAward] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingAward, setEditingAward] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchAwards();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingAward(null);
    }
  }, [modalOpen])

  useEffect(() => {

    fetchAwards();
    
  }, [tab]);

  const fetchAwards = () => {
    doFetch(getAwards, {query: {tab: tab}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingAward, setDeletingAward] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchAwards();
    }
  }


  const editClicked = award => {
    setEditingAward(award);
    toggleModal();
  }

  const deleteClicked = award => {
    setDeletingAward(award);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingAward) {
      doDeleteFetch(deleteAward, {query: {id: deletingAward.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      <div className="flex-cont">
        <div className="flex-1">
          <h1>Awards</h1>
        </div>
        <div>
          <Link to={`new`} className="button">New {tab[0].toUpperCase() + tab.slice(1)} Award</Link>
        </div>
      </div>

      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink to={`/awards/login`}>Login Awards</NavLink></li>
          <li><NavLink to={`/awards/lap`}>Lap Awards</NavLink></li>
        </ul>
      </div>


      {loading &&
        <div className="spinner"></div>
      }
      {!loading && (!awards || awards.length === 0)  &&
        <div>No {tab} awards found.</div>
      }

      {awards &&
        <table className="basic">
          {awards.map(award => {
            return (
              <tr>
                <td className="fit">

                  <div className="flex-cont align-center">
                    <img style={{width: '32px'}} src={coinSVG} alt="" />
                    <i className="lightgray font-075  margin-left-half margin-right-half fas fa-times"></i>
                    
                    <span className="gold bold font-1-5">{award.coins}</span>
                  </div>
                  
                </td>
                <td className="fit">
                  <img style={{width: '50px'}} src={award.badge?.image_url} alt="" />
                </td>
                <td className="bold fit nowrap">{award.name}</td>
                <td>
                  <span className="gray font-075">{award.description}</span>
                </td>
                <td className="fit nowrap">
                  <Link to={`edit/${award?.award_ruleable_id}`} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </Link>

                  <div onClick={() => deleteClicked(award)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Award</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingAward?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !awards &&
        <em>No awards.</em>
      }


      


    </>
  )
}


export default AdminAwards;