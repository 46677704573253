

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'

import { createClubUser, getClubOwnerships} from 'redux/club/clubActions';

import { getTeams } from 'redux/club/teamActions';
import { getTeamOwnerships, createTeamOwnerships } from 'redux/club/teamOwnershipActions';
import { getTeachers } from 'redux/club/userActions';

import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"
import { resetIdCounter } from 'downshift';


const UserSchema = yup.object().shape({
  team_ownerships: yup.array().of(yup.object().shape({
    user_id: yup.string().required("Please select a teacher."),
    id: yup.string(),
    team_id: yup.string(),
  }))
});

const OnboardingAssignTeams = ({currentClub}) => {

  const navigate = useNavigate();

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [teams, teamsLoading, loaded2, error2, doTeamsFetch, setResult2] = useApi();
  const [teachers, loading3, teachersLoaded, error3, doFetch3, setResult3] = useApi();
  const [teamOwnerships, teamOwnershipsLoading, teamOwnershipsLoaded, teamOwnershipsError, doTeamOwnershipsFetch, setTeamOwnershipsResult] = useApi();


  const { reset, watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: UserSchema
  });

  useEffect(() => {
    fetchTeams();
    fetchTeachers();
    fetchTeamOwnerships();
  }, []);

  const fetchTeams = () => {
    doTeamsFetch(getTeams);
  }

  const fetchTeamOwnerships = () => {
    doTeamOwnershipsFetch(getTeamOwnerships);
  }

  const fetchTeachers = () => {
    doFetch3(getTeachers);
  }

  useEffect(() => {
    if (loaded) {
      navigate('/')
    }
  }, [loaded]);


  const onSubmit = (data) => {
    //alert(JSON.stringify(data, null, 2))
    const payload = data;
    doFetch(createTeamOwnerships, {payload: payload})
  }

  
  return(
    <>
      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">3</div>
        <h1 className="no-margin-bottom">Assign Teachers</h1>
        <h2 className="no-margin-top">Assign teachers to their classes</h2>
        


        <div className="margin-top-3"></div>
        {teamsLoading && <div className="spinner"></div>}

        
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card no-hover margin-top-3">

            <table className="basic">

              {teachersLoaded && teams?.map((team, i) => {
                return (
                  <tr>
                    <td className="fit nowrap">
                      {team.name} 
                      {/* {JSON.stringify(team.team_ownerships, null, 2)} */}
                    </td>
                    <td>
                      <input ref={register()} defaultValue={team.team_ownership_id} type="hidden" name={`team_ownerships[${i}].id`}/>
                      <input ref={register()} defaultValue={team.id} type="hidden" name={`team_ownerships[${i}].team_id`}/>
                      <select defaultValue={team.user_id} ref={register()} name={`team_ownerships[${i}].user_id`} id="">
                        <option value={""}>Select teacher:</option>
                        {teachers?.map(teacher => 
                        <option value={teacher.id}>{teacher.fullName}</option> 
                        )}
                      </select>
                      {<div className="field-error">{errors?.team_ownerships?.[i]?.user_id?.message}</div>}
                    </td>
                  </tr>
                )
              })}
            </table>

            

            </div>

            <div className="flex-cont margin-top-2">
              <div className="flex-1"></div>    
              <button disabled={loading} type="submit" className="button">Save</button>
            </div>
          </form>
      

        <div className="code">
          <pre>
            {JSON.stringify(teams, null, 2)}
          </pre>
        </div>
      </div>

     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingAssignTeams);