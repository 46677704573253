import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup";
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { useApi } from '../api';
import { setupAthletes } from 'redux/user/userActions';
import { connect } from 'react-redux';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';


import Modal from 'components/Modal';


import { DateTime } from "luxon";


import DayPickerInput from 'react-day-picker/DayPickerInput';
import "react-day-picker/lib/style.css";

import ClubSelect from 'components/ClubSelect';




const ParentSchema = yup.object().shape({
  athletes_attributes: yup.array().compact().of(
    yup.object().shape({
      // username: yup.string().required("Username is required."),
      first_name: yup.string().required("First name is required."),
      last_name: yup.string().required("Last name is required."),
      birthday: yup.date().nullable().required("Birthday is required.").typeError("Invalid birthday."),
      gender: yup.string().oneOf(['male', 'female']).required("Gender is required."),

      club_id: yup.string().required("Please select a club."),
      // password: yup.string().min(6, "Password must be at least 6 characters long."),
      // password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
    })
  )
});

const SignupSchema = yup.object().shape({
  athletes_attributes: yup.array().of(
    yup.object().shape({
      username: yup.string().required("Username is required."),
      birthday: yup.date().nullable().required("Birthday is required.").typeError("Invalid birthday."),
      gender: yup.string().oneOf(['male', 'female']).required("Gender is required."),
    })
  )
});


const AthleteRow = ({triggerValidation, setValue, hide, i, index, fieldName, q, currentUser, register, errors, birthdayChanged, removeAthlete}) => {



  const [focusedAthleteIndex, setFocusedAthleteIndex] = useState(null);
  
  const [selectedClubID, setSelectedClubID] = useState(null);

  

  useLayoutEffect(() => {
    triggerValidation();
  }, [selectedClubID]);

  const clubInputFocused = index => {
    // setFocusedAthleteIndex(index);
    setClubSelectOpened(true);
  }

  const clubInputBlurred = index => {
    setFocusedAthleteIndex(null);
    setClubSelectOpened(false);
  }

  const [clubSelectOpened, setClubSelectOpened] = useState(false);

  const toggleClubSelectModal = () => {
    setClubSelectOpened(o => !o);
  }



  return (
    <>

    {clubSelectOpened &&
        <Modal toggleModal={toggleClubSelectModal}>
          <div className="modal-header flex-cont align-center">

            <h2 className="flex-1">Select your athlete's club</h2>

            <div onClick={toggleClubSelectModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>

          </div>

          <div className="padding-1">
            <ClubSelect fieldName={fieldName} setValue={setValue} selectedClubID={selectedClubID} setSelectedClubID={setSelectedClubID} setClubSelectOpened={setClubSelectOpened} />
          </div>
          

          <div className="modal-footer align-center flex-cont"></div>
        </Modal>
      }
    <fieldset className={hide ? 'hidden' : ''} name={fieldName} key={fieldName}>
            {/* <div>{JSON.stringify(errors)}</div> */}
            <div className="card no-hover">
            <input 
              name={`${fieldName}[id]`} 
              type="hidden" 
              ref={register()}
              defaultValue={q.id}
            />
            <input 
              name={`${fieldName}[_destroy]`} 
              type="hidden" 
              ref={register()}
            />

            <div className="flex-cont flex-wrap">

            {/* </div> */}

            {currentUser.is_parent &&
              <>

              
            
              <label htmlFor="firstName"> 
                <input 
                  type="text" 
                  ref={register()}
                  placeholder="First Name"
                  name={`${fieldName}[first_name]`} 
                />
                {<div className="field-error">{errors?.athletes_attributes?.[i]?.first_name?.message}</div>}
              </label>
              <label htmlFor="lastName"> 
                <input 
                  type="text" 
                  ref={register()}
                  placeholder="Last Name"
                  name={`${fieldName}[last_name]`} 
                />
                {<div className="field-error">{errors?.athletes_attributes?.[i]?.last_name?.message}</div>}
              </label>
            </>
            }

              <label htmlFor="birthday"> 
                
                {/* <input 
                  type="text" 
                  ref={register()}
                  placeholder="Birthday"
                  name="birthday" 
                  // defaultValue={Math.random().toString(36).substring(7)}
                /> */}
                <DayPickerInput onDayChange={() => birthdayChanged()} className="full" inputProps={{placeholder: "Birthday YYYY-MM-DD", name: `${fieldName}[birthday]`, ref: register}} type="text"  />
                <div className="field-error">{errors?.athletes_attributes?.[i]?.birthday?.message}</div>
              </label>
              <label htmlFor="gender"> 
                <label>
                  <input type="radio" ref={register()} name={`${fieldName}[gender]`}  value="female" defaultChecked={true}  />
                  <span>Girl</span>
                </label>
                <br />
                <label>
                  <input type="radio" ref={register()} name={`${fieldName}[gender]`} value="male"  />
                  <span>Boy</span>
                </label>
                {<div className="field-error">{errors?.athletes_attributes?.[i]?.gender?.message}</div>}
              </label>


                
             <label htmlFor="club_id">
               <input 
                  type="text" 
                  ref={register()}
                  placeholder="Club"
                  name={`${fieldName}[club_name]`} 
                  onChange={() => clubInputFocused(index)}
                  onFocus={() => clubInputFocused(index)}
                  onClick={() => clubInputFocused(index)}
                  // onBlur={() => clubInputBlurred(index)}
                />
              </label>
              <input 
                type="hidden" 
                ref={register()}
                placeholder="Club"
                name={`${fieldName}[club_id]`} 
              />
            </div>

            {currentUser.is_parent &&
              <div className="flex-cont">
                <div className="flex-1"></div>
                {i !== 0 &&
                  <div className="text-link" onClick={() => removeAthlete(index)}>REMOVE</div>
                }
              </div>
            }
          </div>




      </fieldset>
      </>
  )

}





const AthleteSetup = ({currentUser, includeHeader=true, isJoin=true}) => {


  let navigate = useNavigate();
  let params = useParams();

  const { triggerValidation, watch, register, errors, handleSubmit, getValues, setValue, reset } = useForm({
    validationSchema: currentUser.is_parent ? ParentSchema : SignupSchema,
    mode: 'onChange'
  });


  const [indexes, setIndexes] = useState([0]);
  const [removedIndexes, setRemovedIndexes] = useState([]);
  const [counter, setCounter] = useState(1);
  
  const addAthlete = () => {
    if (currentUser.is_parent) {
      setIndexes(prevIndexes => [...prevIndexes, counter]);
      setCounter(prevCounter => prevCounter + 1);

      // reset();
    }
    
  };
  const removeAthlete = (index) => {
    if (currentUser.is_parent) {
      const fieldName = `athletes_attributes[${index}].`;
      setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
      setValue(`${fieldName}[_destroy]`, true);
      setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);

      // reset();
    }
  };


  const [loggedInUser, loading, loaded, error, doFetch, setResult] = useApi();
  const [disabled, setDisabled] = useState(true);

  const [clubModalOpened, setClubModalOpened] = useState(false);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    setDisabled(errors && Object.keys(errors).length !== 0);
  }, [errors]);


  const onSubmit = async data => {

    let d = {
      athlete: {
        ...data
      }
    }
    doFetch(setupAthletes, {payload: d});
  }

  useEffect(() => {
    if (currentUser && loaded) {

      if (isJoin) {
        navigate('/join/upgrade');
      }
      else {
        navigate('/');
      }
      
      // history.push("/profile");
    }
  }, [loaded]);


  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }

  const birthdayChanged = () => {
    triggerValidation();
  }






  return(
    <div className="narrow margin-top-4">


      {includeHeader &&
        <>
          <h1 className="margin-bottom-1 margin-top-2 center">Athlete Setup</h1>
          {/* <div className="margin-bottom-3 center gray">Step 2 of 3</div> */}
        </>
      }
      


      <div className="cardx">


        {/* {currentUser && currentUser.is_parent ? (
            <h1>PARENT</h1>
          ) : (
            <h1>ATHLETE</h1>
          )
        } */}

        <form onSubmit={handleSubmit(onSubmit)}>

          <input type="hidden" name="type" value={params.type}/>

          
          <>
      {indexes.map((index, i) => {
        const fieldName = `athletes_attributes[${index}].`;
        const q = {};
        const hide = removedIndexes.includes(index);

        return (
          
          <AthleteRow triggerValidation={triggerValidation} setValue={setValue} removeAthlete={removeAthlete} currentUser={currentUser} errors={errors} register={register} birthdayChanged={birthdayChanged} hasErrors={hasErrors} fieldName={fieldName} q={q} hide={hide} index={index} i={i} />

          
        );
      })}

      {currentUser.is_parent &&
        <div className="center margin-top-2">
          <div className="ghost button" onClick={addAthlete}>
            +
            ADD ATHLETE
          </div>

        </div>
      }

      
      <br />
      <br />

    </>









          
          <div className="margin-top-2 center">
            {/* <div className="margin-bottom-2">{JSON.stringify(errors)}</div> */}
            {/* <input type="submit" value="Finish" /> */}
            {/* <input type="submit" value="Finish" /> */}

            <button className="button" disabled={false || loading || hasErrors()} type="submit">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <span>Continue</span>
              )
              }
              
            </button>
          </div>
          
        </form>
      </div>
    </div>
    
  )
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(AthleteSetup);
