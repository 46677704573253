

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getPalettes } from 'redux/admin/paletteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createPalette, updatePalette, deletePalette, getPalette} from 'redux/admin/paletteActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput } from '@uppy/react'


import AvatarBase, { SVG } from '../avatar/AvatarBase';

import AdminPalettesForm from './AdminPalettesForm';


import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';


export const AdminPalettesEdit = () => {
  let { id } = useParams();
  const [palette, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAvatarPart();
  }, [id]);

  const fetchAvatarPart = () => {
    doFetch(getPalette, {query: {id: id}});
  }


  return (
    <>
      <h1>Edit Palette</h1>
      <AdminPalettesForm currentPalette={palette} />
    </>
  )
}

const AdminPaletteNew = () => {

  return (
    <>
      <h1>New Palette</h1>

      <AdminPalettesForm />
    </>
  )
}




export default AdminPaletteNew;