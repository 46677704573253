

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'

import { importRosterFile, previewRosterFile, confirmRosterFile } from 'redux/club/clubActions';
import { Link, useNavigate, Outlet, useLocation, useParams } from "react-router-dom"



const OnboardingConfirmRoster = ({currentClub}) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [rosterPreview, rosterPreviewLoading, rosterPreviewLoaded, rosterPreviewError, doRosterPreviewFetch, setRosterPreviewResult] = useApi();


  useEffect(() => {
    if (id) {
      doRosterPreviewFetch(previewRosterFile, {query: {id: id}})
    }
  }, [id])


  const [confirmedRoster, confirmedRosterLoading, confirmedRosterLoaded, confirmedRosterError, doConfirmedRosterFetch, setConfirmedRosterResult] = useApi();

  useEffect(() => {
    if (confirmedRosterLoaded) {
      navigate('/onboarding/roster-success');
    }
  }, [confirmedRosterLoaded]);

  const onSubmitClicked = () => {
    doConfirmedRosterFetch(confirmRosterFile, {query: {id: id}});
  }

  
  return(
    <>
      



      
      <div className="narrow-2 padding-top-1">
      <div className="circle-number">1</div>
      <h1 className="no-margin-bottom">Import Roster</h1>
        <h2 className="no-margin-top">Confirm Uploaded Roster</h2>

        <p>Please review the roster below and confirm that everything looks correct.</p>

        <div className="margin-top-3"></div>
        <div className="margin-bottom-3 flex-cont align-center">
          <div className="flex-1"></div>

          {confirmedRosterLoading &&
            <div className="spinner margin-right-1"></div>
          }
          
          <Link className="flex-cont align-center text-link margin-right-1" to="/onboarding/upload-roster">
            <i className="fas fa-chevron-left margin-right-half"></i>
            <div>Upload different file</div>
          </Link>
          <div onClick={onSubmitClicked} className={`${confirmedRosterLoading ? 'disabled' : ''} button`}>Confirm and Import Roster</div>
        </div>

      {rosterPreviewLoading &&
        <div className="spinner"></div>
      }
      {!rosterPreviewLoading && rosterPreview &&

      
        <div className="card no-hover">

          {/* <div className="code">
            <pre>
              {JSON.stringify(rosterPreview, null, 2)}
            </pre>
          </div> */}

        {/* <div className="code">
        <pre>
            {JSON.stringify(rosterPreview, null, 2)}
          </pre>
          <pre>
            {JSON.stringify(rosterPreview.slice(0), null, 2)}
          </pre>
        </div> */}
        <table className="basic">
          {rosterPreview.map(team => {
            return(
              <tbody>
                <tr><th className="header" colspan={8}>{team[0]}</th></tr>
                <tr className="low-key">
                  <th>Student First</th>
                  <th>Student Last</th>
                  <th>Parent 1 First</th>
                  <th>Parent 1 Last</th>
                  <th>Parent 1 Email</th>
                  <th>Parent 2 First</th>
                  <th>Parent 2 Last</th>
                  <th>Parent 2 Email</th>
                </tr>

                
                {team[1].map(row => 
                  <tr>
                    {row.slice(0, -5).map(cell => 
                      <td>{cell}</td>
                    )}
                  </tr>
                  
                )}
               
              </tbody>
            )
          })}
          </table>
        </div>
      }

      <div className="margin-top-3 flex-cont align-center">
        <div className="flex-1"></div>

        {confirmedRosterLoading &&
          <div className="spinner margin-right-1"></div>
        }
        <Link className="flex-cont align-center text-link margin-right-1" to="/onboarding/upload-roster">
          <i className="fas fa-chevron-left margin-right-half"></i>
          <div>Upload different file</div>
        </Link>
        <div onClick={onSubmitClicked} className={`${confirmedRosterLoading ? 'disabled' : ''} button`}>Confirm and Import Roster</div>
      </div>
      {/* {rosterPreview &&
        <div className="card no-hover">
        <table className="basic">
          <tr>
            {rosterPreview[0].map(cell => 
              <th>{cell}</th>
            )}
          </tr>
          {rosterPreview.slice(1).map(row => 
            <tr>
              {row.map(cell => 
                <td>{cell}</td>
              )}
            </tr>
          )}
       
        </table>
        </div>
      } */}

      </div>

     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingConfirmRoster);