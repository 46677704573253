import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getMerchRequest } from 'redux/admin/merchRequestActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubMerchRequestNav from './AdminMerchRequestNav';
import Modal from '../../../components/Modal';
import MerchRequestForm from './MerchRequestForm';

const ClubMerchRequestLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchMerchRequest()
    }
  }, [id]);

  const [merchRequest, merchRequestLoading, merchRequestLoaded, merchRequestError, doFetchMerchRequest, setMerchRequestResults] = useApi();

  const fetchMerchRequest = () => {
    doFetchMerchRequest(getMerchRequest, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editMerchRequestClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchMerchRequest();
    }
  } 

  return (
    <>
      {merchRequest && editModalOpened &&
        <Modal toggleModal={toggleEditModal}>
          <MerchRequestForm toggleModal={toggleEditModal} currentMerchRequest={merchRequest} />
        </Modal>
        
      }
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {merchRequest?.featured_image_url &&
            <div className="team-logo-cont small margin-right-2">
              <img src={merchRequest?.featured_image_url} alt="" />
            </div>
          }
          {merchRequestLoading && <div className="loading"></div> }
          <h1>
            {merchRequest?.name}
          </h1>

          <div className="flex-1"></div>

          <div>
            <i onClick={editMerchRequestClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubMerchRequestNav id={id} />

        {children}
        {/* {React.cloneElement(children, {merchRequest: merchRequest})} */}

        {/* <div className="code">
          <pre>
            {JSON.stringify(merchRequest, null, 2)}
          </pre>
        </div> */}


      </div>
    </>
  )

}



export default ClubMerchRequestLayout;