import React, { useEffect, useState, useMemo } from 'react';

import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useApi } from '../../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubCalendarNav from 'screens/club/calendar/ClubCalendarNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';


const ClubCalendarLayout = ({currentClub, currentUser, children}) => {

  return (

    <div className="padding-top-1">

      <h1>Calendar</h1>

  
      <div className="margin-bottom-2">
        <ClubCalendarNav />
      </div>

      {children}

    </div>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ClubCalendarLayout);