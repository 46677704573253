import { andyFetch } from '../../api';

export const getTeamEnrollments = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_enrollments";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeamEnrollments error", e);
    }
  }
};

export const createTeamEnrollment = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_enrollments";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTeamEnrollments error", e);
    }
  }
};

export const transferTeamEnrollment = (params) => {
  return async (dispatch, getState) => {
    const url = "club/team_enrollments/transfer";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("transferTeamEnrollment error", e);
    }
  }
};

export const destroyTeamEnrollment = (params) => {
  return async (dispatch, getState) => {
    const url = `club/team_enrollments/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "DELETE");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("destroyteamEnrollment error", e);
    }
  }
};


export const updateTeamEnrollment = (params) => {
  return async (dispatch, getState) => {
    const url = `club/team_enrollments/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("updateTeamEnrollment error", e);
    }
  }
};