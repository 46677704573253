

import React, { useEffect } from 'react';

import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";


const TimerLayout = ({currentUser, children}) => {

  const navigate = useNavigate();
  const [newCurrentUser, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    if (currentUser) {
      fetchCurrentUser();
    }  
  }, []);

  const fetchCurrentUser = () => {
    doFetch(reupdateUser);
  };

  useEffect(() => {
    if (loaded) {

      if (!newCurrentUser || !["admin", "timer"].includes(currentUser.role)) {
        navigate('/');
      }
    }
    
  }, [loaded]);


  if (loading) {
    return (
      <div className="padding-1 center flex-cont justify-center">
        <div className="spinner"></div> 
      </div>
    );
  }



  return (


    <div className="parent">
      <div className="content-cont flex-cont">
        
        <div className="side-nav">
          <ul>
            <li>
              <NavLink to="">
                <i className="fas fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="races">
                <i className="fas fa-running"></i>
                <span>Races</span>
              </NavLink>
            </li>
          </ul>          
        </div>

        <div className="narrow-2 content margin padding-top-1">
          {/* <Outlet /> */}
          {children}
        </div>
      </div>
    </div>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(TimerLayout);