import { andyFetch } from '../../api';

export const getUnreadPopupNotis = (params) => {
  return async (dispatch, getState) => {

    const url = "athlete/notis/unread_popup_notis";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        const data = json.data;


        dispatch({
          type: 'SET_NOTI_POPUPS', 
          notis: data
        });

        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
