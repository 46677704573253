

import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useApi } from '../../api';


const queryString = require('query-string');


const AccountEmailConf = ({currentUser}) => {

  let location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {

  }, []);


  return (
    <>
      <h1>Email Confirmation</h1>


      <div className="spinner"></div>

      <div>{JSON.stringify(params)}</div>
    </>
    
  )
}


export default AccountEmailConf;