

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getRaces } from 'redux/club/raceActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { API_URL } from '../../api.js';
import Modal from 'components/Modal';

import { createRace, updateRace, deleteRace, getLaps } from 'redux/club/raceActions';
import RaceTime from 'components/RaceTime';
import store from 'redux/store';
import { toggleStartRace } from 'redux/ui/uiActions';
import { connect, Provider } from 'react-redux';

import { Link, Outlet } from 'react-router-dom';




const ClubGeneralLaps = ({raceStarted, raceStartedAt}) => {

  return (
    <div className="narrow-2 padding-top-1">


      <div className="flex-cont">
        <div className="flex-1">
          <h1 className="flex-cont align-center">

            General Laps

            
            
          </h1>
        </div>
      </div>

      <RaceTime width={3} includeH={true} includeMS={true} color={{color: 'black'}} />
  

      <div className="margin-top-1">
        {!raceStarted &&
          <div className="button" onClick={() => store.dispatch(toggleStartRace("General Laps"))}>
            START RACE
          </div>
        }
        {raceStarted &&
          <div className="button" onClick={() => store.dispatch(toggleStartRace("General Laps"))}>
            STOP RACE
          </div>
        }
      </div>

      

      
      

      {/* <div>
        {JSON.stringify(raceStarted)}
      </div>
      <div>
        {JSON.stringify(raceStartedAt)}
      </div> */}
    </div>
  )
}


function mapState(state) {
  return { 
    raceStarted: state.uiState.raceStarted,
    raceStartedAt: state.uiState.raceStartedAt,
  };
} 

export default connect(mapState)(ClubGeneralLaps);
