import { andyFetch } from '../../api';

export const getClubOwnerships = (params) => {
  return async (dispatch, getState) => {
    const url = "club/club_ownerships";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return {
          json: json,
          fullResponse: response,
        }
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubOwnerships error", e);
    }
  }
};

export const getClubOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = `club/club_ownerships/${params?.query?.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubOwnerships error", e);
    }
  }
};

export const createClubOwnerships = (params) => {
  return async (dispatch, getState) => {
    const url = "club/club_ownerships/bulk_create";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubOwnerships error", e);
    }
  }
};

export const sendInvite = (params) => {
  return async (dispatch, getState) => {
    const url = "club/club_ownerships/send_invite";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubOwnerships error", e);
    }
  }
};

export const updateClubOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = `club/club_ownerships/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubOwnerships error", e);
    }
  }
};
export const destroyClubOwnership = (params) => {
  return async (dispatch, getState) => {
    const url = `club/club_ownerships/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "DELETE");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("destroyClubOwnership error", e);
    }
  }
};