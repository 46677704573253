

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getAwardRuleLap } from 'redux/admin/awardRuleLapActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import AdminAwardsLapForm from './AdminAwardsLapForm';


export const AdminAwardsLapEdit = () => {
  let { id } = useParams();
  const [awardRuleLap, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAwardRuleLap();
  }, [id]);

  const fetchAwardRuleLap = () => {
    doFetch(getAwardRuleLap, {query: {id: id}});
  }


  return (
    <>

      <h1>Edit Lap Award</h1>

      {/* <div className="code">
        <pre>
          {JSON.stringify(awardRuleLap, null, 2)}
        </pre>
      </div> */}
      <AdminAwardsLapForm currentAwardRuleLap={awardRuleLap} />
    </>
  )
}

const AdminAwardsLapNew = () => {

  return (
    <>
      <h1>New Lap Award</h1>

      <AdminAwardsLapForm />
    </>
  )
}




export default AdminAwardsLapNew;