import { andyFetch } from '../../api';

export const getCompetitions = (params) => {
  return async (dispatch, getState) => {

    const url = "club/competitions";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCompetitions error", e);
    }
  }
};


export const createCompetition = (params) => {

  return async (dispatch, getState) => {

    const url = "club/competitions";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createCompetition error.');
      }
    }
    catch (e) {
      console.log("createCompetition error", e);
    }
  }
};


export const updateCompetition = (params) => {

  return async (dispatch, getState) => {

    const url = `club/competitions/${params.payload.competition.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateCompetition error.');
      }
    }
    catch (e) {
      console.log("updateCompetition error", e);
    }
  }
};


export const deleteCompetition = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/competitions/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteCompetition error.');
      }
    }
    catch (e) {
      console.log("deleteCompetition error", e);
    }
  }
};

