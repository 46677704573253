
import React, { useState, useEffect } from 'react';
import logo7 from 'images/logo7.png';
import { Link, Outlet, useLocation, NavLink } from 'react-router-dom';
import store from 'redux/store';
import { connect } from 'react-redux';
import BottomNav from 'screens/BottomNav';

const PeTeacherBottomNav = ({currentUser, currentClub, raceStarted}) => {

  const [prefix, setPrefix] = useState('/');


  return (
    <BottomNav  type="club">
      <ul>
        <li className="margin-bottom-0">
          <NavLink end to={prefix}>
            <i className="fas fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        {currentClub?.onboarding_step === "done" && <>
          <li >
            <NavLink end to={`${prefix}calendar`}>
              <i className="fas fa-calendar"></i>
              <span>Calendar</span>
            </NavLink>
          </li>

          <li >
            <NavLink to={`${prefix}stats`}>
              <i className="fas fa-chart-line"></i>
              <span>Stats</span>
            </NavLink>
          </li>

          <li >
            <NavLink to={`${prefix}teams`}>
              <i className="fas fa-users"></i>
              <span>Teams</span>
            </NavLink>
          </li>

          <li >
            <NavLink to={`${prefix}students`}>
              <i className="fas fa-user"></i>
              <span>Students</span>
            </NavLink>
          </li>

          <li >
            <NavLink to={`${prefix}tracks`}>
              <i className="fas fa-circle-notch"></i>
              <span>Tracks</span>
            </NavLink>
          </li>

          

          <li >
            <NavLink to={`${prefix}hero-bands`}>
              <i className="fas fa-ring"></i>
              <span>Hero Bands</span>
            </NavLink>
          </li>

          {/* <li >
            <NavLink to={`${prefix}live-run`}>              
              <i>
                <div className={`pulser ${raceStarted ? 'blue' : 'standby'}`}></div>
              </i> 
              <span>
                Live
              </span>
            </NavLink>
          </li> */}

          
        </>}

      </ul>
    </BottomNav>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
    currentClub: state.clubState.currentClub,
    raceStarted: state.uiState.raceStarted,
  };
} 

export default connect(mapState)(PeTeacherBottomNav);