

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getPalettes } from 'redux/admin/paletteActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createPalette, updatePalette, deletePalette, getPalette} from 'redux/admin/paletteActions';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"


import { API_URL } from '../../api.js';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'

import SVG from './SVG';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';

const AdminPalettes = ({currentUser}) => {

  let { id } = useParams();
  const navigate = useNavigate();

  const [palettes, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedPalette, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPalette] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPalette, setEditingPalette] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPalettes();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPalette(null);
    }
  }, [modalOpen])

  useEffect(() => {
    fetchPalettes();
  }, [id]);


  const fetchPalettes = () => {
    doFetch(getPalettes, {query: {tab: id}});
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPalette, setDeletingPalette] = useState(null);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewingPalette, setViewingPalette] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPalettes();
    }
  }
  const editClicked = palette => {
    setEditingPalette(palette);
    toggleModal();
  }

  const deleteClicked = palette => {
    setDeletingPalette(palette);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPalette) {
      doDeleteFetch(deletePalette, {query: {id: deletingPalette.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);



  return (
    <>
      <div className="flex-cont">
        <div className="flex-1"><h1>Palettes</h1></div>
        <div>
          <Link to={`new`} className="button">New Palette</Link>
        </div>
      </div>


      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink exact to={`/palettes`}>All</NavLink></li>
        </ul>
      </div>


      {loading &&
        <div className="spinner"></div>
      }

      {palettes &&
        <table className="basic">
          {palettes.map(palette => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div className="andy-cont">
                    {palette?.images?.map(image => 
                      // <SVG url={image.image_url} /> 
                      <>
                        <SVG layerType={image.kind} url={image.image_url} /> 
                      </> 
                    )}
                  </div>
                </td>

                <td className="bold fit nowrap">
                  <Link className="text" to={`/palettes/edit/${palette.id}`}>
                    {palette.name}
                  </Link>
                </td>



                <td>
                  {palette?.colors?.map(color =>
                  
                    <Link to={`/palettes/edit/${palette.id}`}
                      style={{marginRight: '10px', boxShadow: '0 15px 50px rgba(0,0,0,.25)', cursor: 'pointer', borderRadius: '50%', display:'inline-block', width: '50px', height: '50px', backgroundColor: color.hex}}>
                    </Link>
                  )}   
                  
                </td>




                <td className="fit nowrap">
                  <Link to={`edit/${palette.id}`} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </Link>

                  <div onClick={() => deleteClicked(palette)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table> 
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Palette</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingPalette?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      {!loading && !palettes &&
        <em>No paletes.</em>
      }


      


    </>
  )
}



export default AdminPalettes;