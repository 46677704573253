import { andyFetch } from '../../api';


// export const updateCurrentUser = (params) => {
//   return dispatch => {
//     dispatch({
//       type: 'SET_CURRENT_USER', 
//       currentUser: params
//     })
//   }
  
// }

export const resendInvite = (params) => {
  return async (dispatch, getState) => {
    const url = "users/resend_invite";

    try {
      const response = await andyFetch(url, params, "POST");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("resendInvite error", e);
      return {error: "There was an error."}
    }
  }
};



export const completeInvite = (params) => {
  return async (dispatch, getState) => {
    const url = "users/complete_invite";

    try {
      const response = await andyFetch(url, params, "POST");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("resetPassword error", e);
      return {error: "There was an error."}
    }
  }
};
export const checkInviteToken = (params) => {
  return async (dispatch, getState) => {
    const url = "users/check_invite_token";

    try {
      // alert(JSON.stringify(params));
      const response = await andyFetch(url, params, "GET");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("checkInviteToken error", e);
      return {error: "There was an error."}
    }
  }
};


export const resetPassword = (params) => {
  return async (dispatch, getState) => {
    const url = "users/reset_password";

    try {
      const response = await andyFetch(url, params, "POST");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("resetPassword error", e);
      return {error: "There was an error."}
    }
  }
};

export const changePassword = (params) => {
  return async (dispatch, getState) => {
    const url = "users/change_password";

    try {
      const response = await andyFetch(url, params, "POST");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("changePassword error", e);
      return {error: "There was an error."}
    }
  }
};

export const checkResetToken = (params) => {

  
  return async (dispatch, getState) => {
    const url = "users/check_reset_token";

    try {
      // alert(JSON.stringify(params));
      const response = await andyFetch(url, params, "GET");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("checkResetToken error", e);
      return {error: "There was an error."}
    }
  }
};



export const confirmEmail = (params) => {
  return async (dispatch, getState) => {
    const url = "users/activate_account";

    try {
      const response = await andyFetch(url, params, "POST");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("confirmEmail error", e);
      return {error: "There was an error."}
    }
  }
};

export const resendConfirmEmail = (params) => {
  return async (dispatch, getState) => {
    const url = "users/resend_activate_account";

    try {
      const response = await andyFetch(url, params, "POST");  
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("confirmEmail error", e);
      return {error: "There was an error."}
    }
  }
};




export const createUser = (params) => {

  return async (dispatch, getState) => {

    const url = "users";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        const { authToken, data } = json;

        if (!data) {
          dispatch({ type: 'UNSET_CURRENT_USER' });
          throw new Error('Invalid user.');
        }
        localStorage.setItem('currentUser', JSON.stringify(data));
        localStorage.setItem('authToken', JSON.stringify(authToken));
        dispatch({ type: 'SET_CURRENT_USER', currentUser: data, authToken: authToken});

        return json.data;
      } else {
        dispatch({ type: 'UNSET_CURRENT_USER' });
        throw new Error('Login error.');
      }
    }
    catch (e) {
      console.log("loginUser error", e);
    }
  }
};







export const getMyRaceLogs = (params) => {
  return async (dispatch, getState) => {

    const url = "race_logs/my_race_logs";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCoaches error", e);
    }
  }
};

export const getMyClubs = (params) => {
  return async (dispatch, getState) => {

    const url = "clubs/my_memberships";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMyClubs error", e);
    }
  }
};

export const getAthlete = (params) => {
  return async (dispatch, getState) => {

    const url = "athletes/my_athlete";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAthlete error", e);
    }
  }
};

export const setupAthletes = (params) => {
  
  return async (dispatch, getState) => {

    const url = "athletes/my_setup_athletes";

    


    try {
      
      const response = await andyFetch(url, params, "POST");
      
      if (response.ok) {

        
        const json = await response.json();

        localStorage.setItem('currentUser', JSON.stringify(json.data));
        localStorage.setItem('authToken', JSON.stringify(json.authToken));
        dispatch({ type: 'SET_CURRENT_USER', currentUser: json.data, authToken: json.authToken});
        
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("setupAthletes error", e);

      return {error: "There was an error."}
    }
  }
};

export const updateMaterialFeePayment = (params) => {
  return async (dispatch, getState) => {

    const url = "users/update_material_fee_payment";

    try {
      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("updateMaterialFeePayment error", e);
    }
  }
};


export const getSubDetails = (params) => {
  return async (dispatch, getState) => {

    const url = "users/my_sub_details";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSubDetails error", e);
    }
  }
};


export const getMaterialFeeDetails = (params) => {
  return async (dispatch, getState) => {

    const url = "users/my_material_fee_details";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getSubDetails error", e);
    }
  }
};

