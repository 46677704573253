import { andyFetch } from '../../api';

export const getMaterialFeeWaivers = (params) => {
  return async (dispatch, getState) => {

    const url = `club/pledge_drives/${params?.query?.id}/material_fee_waivers`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDrive error", e);
    }
  }
};


export const getSponsors = (params) => {
  return async (dispatch, getState) => {

    const url = `club/pledge_drives/${params?.query?.id}/sponsors`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDrive error", e);
    }
  }
};



export const getCurrentPledgeDrive = (params) => {
  return async (dispatch, getState) => {

    const url = "club/pledge_drives/current";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDrives error", e);
    }
  }
};

export const getPledgeDrive = (params) => {
  return async (dispatch, getState) => {

    const url = `club/pledge_drives/${params?.query?.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDrive error", e);
    }
  }
};


export const getPledgeDrives = (params) => {
  return async (dispatch, getState) => {

    const url = "club/pledge_drives";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledgeDrives error", e);
    }
  }
};


export const createPledgeDrive = (params) => {

  return async (dispatch, getState) => {

    const url = "club/pledge_drives";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPledgeDrive error.');
      }
    }
    catch (e) {
      console.log("createPledgeDrive error", e);
    }
  }
};


export const updatePledgeDrive = (params) => {

  return async (dispatch, getState) => {

    const url = `club/pledge_drives/${params.payload.pledge_drive.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePledgeDrive error.');
      }
    }
    catch (e) {
      console.log("updatePledgeDrive error", e);
    }
  }
};


export const deletePledgeDrive = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/pledge_drives/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePledgeDrive error.');
      }
    }
    catch (e) {
      console.log("deletePledgeDrive error", e);
    }
  }
};

