import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi } from '../../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { DragDrop, StatusBar } from '@uppy/react'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'

import Modal from '../../../components/Modal';
import { API_URL } from '../../../api.js';
import { createTrack, updateTrack, deleteTrack } from 'redux/club/trackActions';


const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const TrackSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  // street: yup.string().required("Street is required."),
  // city: yup.string().required("City is required."),
  // state: yup.string().required("State is required."),
  // zipcode: yup.string().required("Zipcode is required."),
  // id: yup.string().nullable(),
  // how_many_laps: yup.number().required("Number of laps is required."),
  lap_distance: yup.number().required("Lap distance is required."),
  avatar: yup.string(),
  // type: yup.string().oneOf(['parent', 'track']).required(),

});

const TrackForm = ({currentTrack, toggleModal}) => {

  const navigate = useNavigate('/');

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: TrackSchema
  });

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deletingTrack, setDeletingTrack] = useState(null);

  const deleteTrackClicked = () => {
    setDeleteModalOpened(true);
  }

  useEffect(() => {
    return () => {
      setDeleteModalOpened(false);
    }
  }, []);

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [tracksResult, tracksLoading, tracksLoaded, tracksError, doFetchTracks, setTrackResults] = useApi();
  const [deletedTrack, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedTrack] = useApi(); 

  
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpened(o => !o);
  }

  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);


  const getAvatarURL = () => {
    return fileURL ? fileURL : currentTrack?.avatar_url
  }



  const onSubmit = async data => {
    let d = {
      track: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentTrack && currentTrack.id) {
      doFetch(updateTrack, {payload: d});
    }
    else {
      doFetch(createTrack, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const confirmDestroy = () => {
    if (currentTrack) {
      doDeleteFetch(deleteTrack, {query: {id: currentTrack.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleModal(true);
      navigate('/tracks')
    }
  }, [deleteLoaded]);

  const watchLapDistance = watch("lap_distance", currentTrack?.lap_distance);
  const parsedWatchLapDistance = parseFloat(watchLapDistance);


  const isNumber = num => {
    const float = parseFloat(num);
    if (float === undefined || float === null || float === "" || isNaN(float)) return false;
    return true;
  }

  const calculatedFullLaps = distance => {
    return Math.floor(1/parseFloat(distance))
  }

  const calculatedFullLapsDistance = distance => {
    return Math.round((calculatedFullLaps(watchLapDistance) * parseFloat(watchLapDistance)) * 100 + Number.EPSILON ) / 100
  }
  
  const calculatedLastLapDistance = distance => {
    return Math.round((1.000 - (calculatedFullLaps(parseFloat(distance)) * parseFloat(distance))) * 100 + Number.EPSILON ) / 100
  }
  const calculatedLastLapDecimal = distance => {
    return Math.round( 
      ((1.000 - (calculatedFullLaps(parseFloat(distance)) * parseFloat(distance))) / parseFloat(watchLapDistance)) * 100 + Number.EPSILON ) / 100
  }
  
  return (
    <Modal toggleModal={toggleModal}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentTrack ? 'Edit' : 'New'} Track</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">
          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentTrack?.id}
          />
          

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>
                Nickname
              </span>
              <input 
                type="text" 
                ref={register}
                placeholder="Nickname"
                name="name"
                defaultValue={currentTrack?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="lap_distance"> 
              <div className="flex-cont align-center">

                <div>
                  <span>
                    Distance of Lap
                  </span>
                  <br/>

                  <div className="relative flex-cont align-center">
                    <input 
                      type="text" 
                      ref={register}
                      placeholder="Lap Distance"
                      name="lap_distance"
                      defaultValue={currentTrack?.lap_distance}
                      className="auto"
                    />
                    <div className="bold font-075 inner-label raight">miles</div>
                  </div>
                    
                </div>
                
                <div className="flex-1"></div>
              </div>
            
              {errors.lap_distance && <div className="field-error">{errors.lap_distance.message}</div>}
            </label>
          </div>
        
          <div className="card no-hover">
            <div className="bold gray margin-bottom-1">TIMED MILE RUNS</div>

            

            {(isNumber(parsedWatchLapDistance) && parsedWatchLapDistance > 0 ) ? (
                <>
                  <div className="font-2 margin-bottom-half bold">1 mile = </div>
                  <div className="margin-left-1 font-1-5">
                    {calculatedFullLaps(parsedWatchLapDistance) !== 0 &&
                      <div>
                        {calculatedFullLaps(parsedWatchLapDistance)} {`${(calculatedFullLaps(parsedWatchLapDistance) === 1) ? 'full lap' : 'full laps'}`} <span className="gray">({calculatedFullLapsDistance(parsedWatchLapDistance)} mi)</span>
                      </div>
                    }

                    

                    {calculatedLastLapDecimal(parsedWatchLapDistance) !== 0 &&
                      <>
                        {calculatedFullLaps(parsedWatchLapDistance) !== 0 &&
                          <div className="gray">+</div>
                        }

                        <div>
                          {calculatedLastLapDecimal(parsedWatchLapDistance)} {`${(calculatedFullLaps(parsedWatchLapDistance) === 0) ? '' : 'final' }`} lap <span className="gray">({calculatedLastLapDistance(parsedWatchLapDistance)} mi)</span>
                          
                        </div>
                      
                      </>
                      
                    }
                  </div>
                </>
              ) : (
                <em>Please enter a lap distance above.</em>
              )
            }
          </div>




                


          <div className="margin-top-1 card no-hover uppy-dd-cont">
            <div className="margin-bottom-2">
              <img style={{height: '200px'}} src={getAvatarURL()} alt="" />
              {/* {uploadLoading ? (
                  <div className="flex-cont align-center justify-center" style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.6)', position: 'absolute'}}>
                    <div>
                      <div className="spinner"></div>
                    </div>
                    
                  </div>
                ) : (
                  <div style={{fontSize: '1rem'}} className="hover-overlay">
                    EDIT    
                  </div>
                )
              } */}
            </div>
            
            <br/>

            <div className="button ghost">Edit Track Image</div>

            <input ref={register} type="hidden" name="avatar" value={file} />
            <DragDrop
              className="andy"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: 'Drop here or %{browse}',
                  browse: 'browse'
                }
              }}
            />
        </div>
        <StatusBar uppy={uppy} />
        <div className="field-error">
          {errors?.avatar?.message}
        </div>


          

        </div>

        <div className="modal-footer align-center flex-cont">
          {/* <input type="submit" value="Save" className="button" /> */}

          <button disabled={uploadLoading || loading} className={`${(loading || uploadLoading) ? 'disabled' : ''} button`}>
            Save
            {loading &&
              <div className="spinner button-spinner"></div>
            }
          </button>
          <div className="flex-1"></div>
          <div onClick={deleteTrackClicked} className="ghost danger button">Remove</div>
        </div>

      </form>
      

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={toggleDeleteModal}>
          <div className="padding-2">
            Are you sure you want to delete {currentTrack.name}? This cannot be undone.
          </div>
          <div className="modal-footer flex-cont align-center">
            <div onClick={toggleDeleteModal} className="ghost button">Cancel</div>
            <div className="flex-1"></div>
            <div onClick={confirmDestroy} className="button danger">Yes, delete</div>
          </div>
        </Modal>
      }
    </Modal>
  )
}

export default TrackForm;