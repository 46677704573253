import React, { useState, useEffect } from 'react';
import logo7 from 'images/logo7.png';
import { Link, Outlet, useLocation, NavLink } from 'react-router-dom';
import store from 'redux/store';
import { connect } from 'react-redux';

const BottomNav = ({parentAuthed, type, currentUser, unreadNotiListables, children}) => {

  const [menuOpened, setMenuOpened] = useState(false); 

  const toggleMenu = () => {
    setMenuOpened(o => !o);
  }


   
  const [prefix, setPrefix] = useState('/');

  useEffect(() => {
    if (currentUser.role === "admin") {
      setPrefix('/profile/');
    }
  }, [currentUser]);

  useEffect(() => {
    return () => {
      setMenuOpened(false);
    };
  }, [])



  return(
    <>

    <div onClick={toggleMenu} className={`bottom-menu-bg ${menuOpened ? 'opened' : ''}`}></div>

    <div className={`side-nav ${menuOpened ? 'opened' : ''}`}>
      <div onClick={toggleMenu}>
        {children}
      </div>
      
    </div>


    <nav className="bottom">
      {/* <div className="item">
        {type}
      </div> */}

      {type === "parent" && 
        <>
          <div className="item">
            <NavLink to={`${prefix}`}>
              <i className="fas fa-tachometer-alt"></i>
            </NavLink>
          </div>
        </>
      }


      {type === "profile" && 
        <>
          <div className="item">
            <NavLink to={`${prefix}`}>
              <i className="fas fa-tachometer-alt"></i>
            </NavLink>
          </div>

          <div className="item">
            <NavLink to={`${prefix}awards`}>
              <i className="fas fa-medal"></i>
            </NavLink>
          </div>

          <div className="item">
            <NavLink to={`${prefix}activity`}>
              <i className="fas fa-stopwatch"></i>
            </NavLink>
          </div>


          <div className="item">
            <NavLink to={`${prefix}fundraising`}>
              <i className="fas fa-heart"></i>
            </NavLink>
          </div>

          <div className="item">
            <NavLink to={`${prefix}career`}>
              <i className="fas fa-flag"></i>
            </NavLink>
          </div>

{/* 
          <div className="item">
            <div onClick={toggleMenu} className="nav-link">
              
              <i className={`fas fa-${menuOpened ? 'times' : 'bars'}`}></i>
            </div>
          </div> */}
        </>
      }

      {(type === "club" || type === "admin") && 
        <>
          <div className="item">
            <NavLink to="/">
              <i className="fas fa-tachometer-alt"></i>
            </NavLink>
          </div>

          <div className="item">
            <div onClick={toggleMenu} className="nav-link">
              
              <i className={`fas fa-${menuOpened ? 'times' : 'bars'}`}></i>
            </div>
          </div>
        </>
      }


      
    </nav>


    </>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
    parentAuthed: state.sessionState.parentAuthed,
  };
} 

export default connect(mapState)(BottomNav);
