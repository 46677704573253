import { andyFetch } from '../../api';

export const getRaceLogs = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/race_logs";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRaceLogs error", e);
    }
  }
};


export const createRaceLog = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/race_logs";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createRaceLog error.');
      }
    }
    catch (e) {
      console.log("createRaceLog error", e);
    }
  }
};


export const updateRaceLog = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/race_logs/${params.payload.raceLog.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateRaceLog error.');
      }
    }
    catch (e) {
      console.log("updateRaceLog error", e);
    }
  }
};


export const deleteRaceLog = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/race_logs/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteRaceLog error.');
      }
    }
    catch (e) {
      console.log("deleteRaceLog error", e);
    }
  }
};

