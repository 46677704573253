import React from 'react';
import Spinner from './Spinner';

const SubmitButton = ({label="Submit", disabled, loading}) => {

  return(
    <button className="button" disabled={disabled || loading} type="submit">
      <span>{label}</span>
      {loading && 
        <div className="spinner button-spinner"></div>
      }
      
      
    </button>
  );
}
export default SubmitButton;