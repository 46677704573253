import React from 'react';


const MerchProductCard = ({imageUrl, merchProduct}) => {


  // if (!merchProduct) return null;


  return (
    <div className="card inline-block">

      <img style={{width: '200px'}} src={imageUrl} alt="" />

      
    </div>
  )
}

export default MerchProductCard;