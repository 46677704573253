import React, { useState, useEffect } from 'react';
import { useVisibility } from './useVisibility';
import running from 'images/running-ani.gif';
import Lottie from "lottie-react";
import { useLottie } from "lottie-react";
// import groovyWalkAnimation from "images/running.json";

// import Lottie from 'lottie-web';
import { replaceColor } from 'lottie-colorify';
import SomeAnimation from 'images/running.json';

// const animation = Lottie.loadAnimation({
//   container: container.current,
//   animationData: replaceColor('#000000', '#fe0088', SomeAnimation),
// });

const Bar = ({showBarCap=false, rightLabel, label, progress, gem, height, color, full=false}) => {

  const options = {
    animationData: replaceColor('#000000', '#0075B3', SomeAnimation),
    loop: true
  };

  const { View } = useLottie(options);


  

  const [startCount, setStartCount] = useState(false);
  const { currentElement, isVisible } = useVisibility(-50);

  useEffect(() => {
    if (progress !== null && progress !== undefined) {
      if (isVisible && !startCount) {
        setStartCount(true);
      } 
    }
  }, [progress, isVisible])

  // useEffect(() => {
  //   if (isVisible && !startCount) {
  //     setStartCount(true);
  //   } 
  // }, [isVisible]);


  const [pp, setPp] = useState(0);


  useEffect(() => {

    if (startCount) {
      setTimeout(() => { 
        setPp(progress);
      }, 250);
    } 

  }, [startCount]);


  return (
    <div style={{height: `${height}px`}}  className='flex-cont align-center'>
    <div ref={currentElement} style={{height: `${height}px`}} className={`${full ? 'full' : ''} bar-cont new`}>
      <div style={{width: `${pp}%`}} className={`relative bar ${gem} gradient-2`}>



      
      </div>
      
      <div style={{minWidth: `${pp}%`}} className="ddd">
        {rightLabel &&
          <div className={`right-bar-label-cont ${(pp > 75 && startCount) ? 'end' : ''}`}>
            <div className="right-bar-label">
              {rightLabel}
            </div>
          </div>
        }
      </div>

      {label &&
        <div className="bar-label-cont">
          <div className="bar-label">
            {label}
          </div>
        </div>
        
      }
      
    </div>

    {showBarCap &&
      <div style={{minWidth: `${pp}%`}} className="bar-cap-cont">

        <div style={{width:`${height+10}px`, height: `${height+10}px`}} className="bar-cap">
          {/* <i className="fas fa-running"></i> */}
          <>{View}</>
          {/* <img style={{width: '32px'}} src={running} alt="" /> */}
        </div>
    
      </div>
    }
    </div>
  )
}

export default Bar;