import { andyFetch } from '../../api';

export const getBands = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/bands";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getBands error", e);
    }
  }
};



export const createBand = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/bands";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createBand error.');
      }
    }
    catch (e) {
      console.log("createBand error", e);
    }
  }
};


export const updateBand = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/bands/${params.payload.band.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateBand error.');
      }
    }
    catch (e) {
      console.log("updateBand error", e);
    }
  }
};

export const getBand = (params) => {
  return async (dispatch, getState) => {
    const url = `admin/bands/${params.query.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getBand error.');
      }
    }
    catch (e) {
      console.log("getBand error", e);
    }
  }
};

export const deleteBand = (params) => {
  
  return async (dispatch, getState) => {

    const url = `admin/bands/${params.query.id}`;

    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteBand error.');
      }
    }
    catch (e) {
      console.log("deleteBand error", e);
    }
  }
};

