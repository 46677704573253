import { andyFetch } from '../../api';


export const getRace = (params) => {

  return async (dispatch, getState) => {

    const url = `club/races/show_race`;


    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getRace error.');
      }
    }
    catch (e) {
      console.log("getRace error", e);
    }
  }
};


export const getRaces = (params) => {
  return async (dispatch, getState) => {

    const url = "club/races";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        
        return {
          json: json,
          fullResponse: response,
        }

      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRaces error", e);
    }
  }
};


export const createRace = (params) => {

  return async (dispatch, getState) => {

    const url = "club/races";

    try {

      const response = await andyFetch(url, params, "POST");

      console.log("Response 2", response)


      if (response.ok) {
        console.log("R 1a");
        const json = await response.json();
        console.log("R 1b", json);
        return json.data;

      } else {
        console.log("R 2");
        throw new Error('createRace error.');
      }
    }
    catch (e) {
      console.log("R 3");
      // return {error: e.toString()}
      console.log("createRace error", e);
    }
  }
};


export const updateRace = (params) => {

  return async (dispatch, getState) => {

    const url = `club/races/${params.payload.race.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateRace error.');
      }
    }
    catch (e) {
      console.log("updateRace error", e);
    }
  }
};


export const deleteRace = (params) => {
  

  return async (dispatch, getState) => {

    const url = `club/races/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteRace error.');
      }
    }
    catch (e) {
      console.log("deleteRace error", e);
    }
  }
};


export const getLaps = (params) => {
  return async (dispatch, getState) => {

    const url = "club/races/get_my_laps";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getLaps error", e);
    }
  }
};

export const getTestLaps = (params) => {
  return async (dispatch, getState) => {

    const url = "club/races/get_test_laps";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getTestLaps error", e);
    }
  }
};

export const getRealLaps = (params) => {
  return async (dispatch, getState) => {

    const url = "club/races/get_real_laps";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRealLaps error", e);
    }
  }
};

export const addLap = (lap) => {
  return {
    type: 'ADD_LAP',
    lap
  }
}






// export const getRaceLogs = (params) => {
//   return async (dispatch, getState) => {

//     const url = "club/race_logs";

//     try {
//       const response = await andyFetch(url, params, "GET");

//       if (response.ok) {
//         const json = await response.json();
//         return json.data;
//       } else {
//         throw new Error('There was an error.');
//       }
//     }
//     catch (e) {
//       console.log("getRaceLogs error", e);
//     }
//   }
// };