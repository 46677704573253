

import React, { useState } from 'react';
import { useApi } from '../api';
import { confirmEmail, resendConfirmEmail } from '../redux/user/userActions';


const EmailConfBanner = ({currentUser}) => {


  const [resendResult, resendLoading, resendLoaded, resendError, doResendFetch, setResendResult] = useApi(); 
  const [resent, setResent] = useState(false);


  const resendClicked = () => {
    doResendFetch(resendConfirmEmail);
  }

  

  return(
    <>
      {false && currentUser && !currentUser.activated &&
        <div className="top-banner flex-cont justify-center align-center">
          {resendLoaded &&
            <div>
              Another confirmation email has been sent to {currentUser.email}.
            </div>
          }
          {!resendLoaded &&
            <>
              <div className="margin-right-2">Please confirm your email address by clicking the link we emailed to {currentUser.email}.</div>
              <div onClick={resendClicked} className="text-link">RESEND LINK</div>
            </>
          }
          
        </div>
      }
    </>
  )
}

export default EmailConfBanner;