import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import store from 'redux/store';

import { loginUser } from 'redux/session/sessionActions';

import { useApi } from '../api';
import SubmitButton from '../components/SubmitButton';
import Spinner from 'components/Spinner';
// import SubmitButton from 'components/SubmitButton';
const queryString = require('query-string');

const LoginSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address.").required("Email is required."),
  password: yup.string().required("Password is required."),
});

const Login = ({currentUser}) => {

  let location = useLocation();
  const params = queryString.parse(location.search);

  const navigate = useNavigate();

  const { register, errors, handleSubmit } = useForm({
    validationSchema: LoginSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [result2, loading2, loaded2, error2, doFetch2, setResult2] = useApi(); 



  const tryLogin = async data => {
    doFetch(loginUser, {payload: data});
  }

  useEffect(() => {
    if (currentUser) {
      navigate(`/`);
    }
  }, [currentUser]);

  useEffect(() => {
    if (loaded && currentUser) {
      navigate(`/`);
    }
  }, [loaded, navigate, currentUser]);

  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }



  // if (loading) {
  //   return <Spinner />
  // }

  // if (loading2) {
  //   return <Spinner />
  // }

  if (currentUser) {
    return <Spinner />
  }

  
  return(
    <div className="center flex-cont">

      {/* <div style={{backgroundColor: '#53b3d5'}} className="flex-1"></div> */}
      <div className="flex-1 really-narrow margin-top-2">
      <h1>Login</h1>
      {/* {loading &&
        <Spinner />
      }
      {loading2 &&
        <Spinner />
      } */}
      {/* <div>{JSON.stringify(currentUser)}</div> */}

      <div className="card no-hover">
        <form onSubmit={handleSubmit(tryLogin)}>

          <div className="flex-cont">
            <label htmlFor="email"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Email"
                name="email"
                defaultValue={params?.email}
              />
              {errors.email && <div className="field-error">{errors.email.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="pw"> 
              <input 
                type="password" 
                ref={register}
                placeholder="Password"
                name="password"
              />
              {errors.password && <div className="field-error">{errors.password.message}</div>}
            </label>
          </div>


          {/* <input type="submit" value="Submit" /> */}
          {/* <SubmitButton label="Log in" disabled={false} loading={loading} /> */}

          <div className="margin-top-1 center">

            <SubmitButton label="Continue" disabled={loading || hasErrors()} loading={loading}  />

            {/* <button className="full button" disabled={false || loading || hasErrors()} type="submit">
              {loading ? (
                <Spinner classes={['button-spinner']} />
              ) : (
                <span>Continue</span>
              )
              }
              
            </button> */}
          </div>

          <div className="flex-cont justify-center align-center margin-top-2 center">
            <div onClick={() => navigate('/password-reset')} className="margin-left-05 ghost button">Forgot password?</div>
          </div>

          <div className="flex-cont justify-center align-center margin-top-1 center">
            <div onClick={() => navigate('/resend-invite')} className="margin-left-05 ghost button">Resend invite</div>
          </div>


        </form>
      </div>

      </div>
    </div>
    
  )
};

const mapState = state => ({
  currentUser: state.sessionState.currentUser,
});

export default connect(mapState)(Login);