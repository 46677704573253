import React, { useState, useEffect } from 'react';
import ClubAccountNav from './ClubAccountNav';
import { getMachines, createMachine, updateMachine, deleteMachine } from 'redux/club/machineActions';
import { useApi } from '../../../api';
import { DateTime } from 'luxon';
import Modal from '../../../components/Modal';

const ClubAccountMachines = ({children}) => {


  const [machines, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchMachines();
  }, [])


  const fetchMachines = () => {
    doFetch(getMachines);
  }

  // const [modalOpened, setModalOpened] = useState(false);

  const toggleModal = () => {
    setCurrentMachine(null);
  }

  const [currentMachine, setCurrentMachine] = useState(null);



  return (
    <>
      {currentMachine &&
        <Modal focusTrap={false} toggleModal={toggleModal}>
          <div className="modal-header flex-cont align-center">
            <h2>Hero Base - {currentMachine.code}</h2>
            <div className="flex-1"></div>
            <div onClick={toggleModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="padding-1">
            {currentMachine.lease_info ||
              <em>No lease info found.</em>
            }
          </div>
          <div className="modal-footer flex-cont align-center">
            <div onClick={toggleModal} className="button">Close</div>
          </div>
        </Modal>
      }


      <div className="padding-top-1">

        {loading && 
          <div className="spinner"></div>
        }

        <table className="basic">
          <tr>
            <th>ID</th>
            {/* <th className="fit nowrap">In Use?</th> */}
            <th>Last Ping</th>
            <th>Lease Document</th>
            <th></th>
          </tr>
          {machines?.map(machine =>
            <tr>

              <td className="fit nowrap">
                {machine.code}
              </td>
              {/* <td className="center fit nowrap">{machine.in_use ? "YES" : "NO"}</td> */}
              <td className="fit nowrap">
                {
                  machine.last_heartbeat ? 
                  DateTime.fromISO(machine.last_heartbeat).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) 
                  : 
                  "-"
                }
              </td>
              <td>
                {/* <div onClick={() => setCurrentMachine(machine)} className="button outline">
                  Lease Info
                </div> */}
                <a target="_blank" className="text-link margin-right-1" href={machine.lease_url}>{machine.lease?.metadata?.filename}</a> 
              </td>
            </tr>  
          )}
        </table>
        

        {/* {JSON.stringify(machines, null, 2)} */}
      </div>
    </>
  )

}



export default ClubAccountMachines;