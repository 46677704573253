import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubTrackNav from './ClubTrackNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getTrack, changeAthlete } from 'redux/club/trackActions';


const ClubTrack = ({track}) => {

  const { id } = useParams();

  // useEffect(() => {
  //   if (id) {
  //     fetchTrack()
  //   }
  // }, [id]);

  // const [track, trackLoading, trackLoaded, trackError, doFetchTrack, setTrackResults] = useApi();

  // const fetchTrack = () => {
  //   doFetchTrack(getTrack, {query: {id: id}})
  // }


  const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  const changeAthleteClicked = () => {
    setAthleteModalOpened(true);
  }
  const toggleAthleteModal = (reload=false) => {

    setAthleteModalOpened(o => !o);
    if (reload) {
      // fetchTrack();
    }
  }
 
  return (
    <>


      {track &&
        <>  
          <table className="vertical">

            <tr>
              <th>Lap Distance</th>
              <td>{track.lap_distance} mi.</td>
            </tr>
            <tr>
              <th>Timed Mile</th>
              <td>
                {track.full_laps_count !== 0 &&
                  <div>
                    {track.full_laps_count} full {`${(track.full_laps_count === 1) ? 'lap' : 'laps'}`} ({track.full_laps_distance} mi)
                  </div>
                  
                }

                {track.last_lap_distance && 

                  <div>
                    {track.full_laps_count !== 0 &&
                      <div className="gray">+</div>
                    }
                    <div>
                      {track.last_lap_pct} {`${(track.full_laps_count === 0) ? '' : 'final' }`} lap ({track.last_lap_distance} mi)
                    </div>
                  </div>


                }

              </td>
            </tr>
          </table>

          




        </>

      }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(track, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubTrack;