

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getMerchRequests } from 'redux/admin/merchRequestActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { Link } from 'react-router-dom';
import { createMerchRequest, updateMerchRequest, deleteMerchRequest } from 'redux/admin/merchRequestActions';
import MerchRequestForm from './MerchRequestForm';


const AdminMerchRequests = ({currentUser}) => {

  const [merchRequests, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedMerchRequest, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedMerchRequest] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingMerchRequest, setEditingMerchRequest] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchMerchRequests();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingMerchRequest(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!merchRequests) {
      fetchMerchRequests();
    }
  }, []);

  const fetchMerchRequests = () => {
    doFetch(getMerchRequests);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingMerchRequest, setDeletingMerchRequest] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchMerchRequests();
    }
  }


  const editClicked = merchRequest => {
    setEditingMerchRequest(merchRequest);
    toggleModal();
  }

  const deleteClicked = merchRequest => {
    setDeletingMerchRequest(merchRequest);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingMerchRequest) {
      doDeleteFetch(deleteMerchRequest, {query: {id: deletingMerchRequest.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      <div className="padding-top-1">
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <MerchRequestForm currentTeam={null} toggleModal={toggleModal} />
        </Modal>
      }

      <div className="flex-cont">
        <div className="flex-1">
          <h1>Merch Requests</h1>
        </div>
        {/* <div>
          <div onClick={toggleModal} className="button">New Merch Request</div>
        </div> */}
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {merchRequests &&
        <table className="basic">
          {merchRequests.map(merchRequest => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div style={{fontSize: '3rem', backgroundImage: `url( ${merchRequest?.featured_image_url} )`}} className="team-logo small">
                  
                  </div>
                </td>
                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/merch-requests/${merchRequest.slug}`}>
                    {merchRequest.name}
                  </Link>
                </td>
                <td>
                  {merchRequest.price ? 
                    parseFloat(merchRequest.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    :
                    "-"
                  }
                </td>
                <td>
                  
                </td>
                <td></td>
              
              </tr>
            )
          })}
        </table>
      }


      {!loading && (!merchRequests || merchRequests.length === 0) &&
        <em>No results.</em>
      }


      

      </div>  
    </>
  )
}


export default AdminMerchRequests;