import { andyFetch } from '../../api';

export const getAvatarPartCats = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/avatar_part_cats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAvatarPartCats error", e);
    }
  }
};


export const getAvatarPartCat = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/avatar_part_cats/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAvatarPartCat error", e);
    }
  }
};


export const createAvatarPartCat = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/avatar_part_cats";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createAvatarPartCat error.');
      }
    }
    catch (e) {
      console.log("createAvatarPartCat error", e);
    }
  }
};


export const updateAvatarPartCat = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/avatar_part_cats/${params.payload.avatar_part_cat.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateAvatarPartCat error.');
      }
    }
    catch (e) {
      console.log("updateAvatarPartCat error", e);
    }
  }
};


export const deleteAvatarPartCat = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/avatar_part_cats/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteAvatarPartCat error.');
      }
    }
    catch (e) {
      console.log("deleteAvatarPartCat error", e);
    }
  }
};

