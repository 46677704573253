import React, { useState } from 'react';

const InspoCard = ({showTools=true, inspo, url, title, content, onEditClicked, onViewClicked}) => {

  const [expanded, setExpanded] = useState(false);

  return (
    <div className="inspo-cont-outer">
    <div  style={{backgroundImage: `url("${url}")`}} className={`inspo-cont ${expanded && 'expanded'}`}> 
  
      {showTools &&
        <div className="tools">
          <div onClick={() => onViewClicked(inspo)} className="tool">
            <i className="fas fa-eye"></i>
          </div>
          <div onClick={() => onEditClicked(inspo)} className="tool">
            <i className="fas fa-pencil-alt"></i>
          </div>
        </div>
      }
      <div className="glow"></div>
      <img src={url} alt="" className="dummy" />
      {/* <div className="title">
        {title}
      </div> */}
      
    </div>
      <div className="static-title flex-cont align-center">
        {inspo?.is_global && 
          <i className="margin-right-half font-08 fas fa-globe lightgray"></i>
        }
        {title}
      </div>
    </div>
  )
}

export default InspoCard;
