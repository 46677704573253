import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useApi } from '../../../api';
import { getCoinItem } from 'redux/club/coinItemActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubCoinItemNav from './ClubCoinItemNav';
import Modal from '../../../components/Modal';
import CoinItemForm from './CoinItemForm';

const ClubCoinItemLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchCoinItem()
    }
  }, [id]);

  const [coinItem, coinItemLoading, coinItemLoaded, coinItemError, doFetchCoinItem, setCoinItemResults] = useApi();

  const fetchCoinItem = () => {
    doFetchCoinItem(getCoinItem, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editCoinItemClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      // alert('here')
      fetchCoinItem();
    }
  } 

  return (
    <>
      {coinItem && editModalOpened &&
        <Modal toggleModal={toggleEditModal}>
          <CoinItemForm setCurrentCoinItem={setCoinItemResults} toggleModal={toggleEditModal} currentCoinItem={coinItem} />
        </Modal>
      }

      
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {coinItem?.featured_image_url &&
            <div className="team-logo-cont small margin-right-2">
              <img src={coinItem?.featured_image_url} alt="" />
            </div>
          }
          {coinItemLoading && <div className="loading"></div> }

          <div>
            <Link to={`/coin-items`} className="text-link">
              <i className="margin-right-half fas fa-angle-left"></i>
              All Items
            </Link>
            <h1>
              {coinItem?.name}
            </h1>
          </div>
          

          <div className="flex-1"></div>

          <div>
            <i onClick={editCoinItemClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubCoinItemNav id={id} />

        {/* {children} */}
        {React.cloneElement(children, {coinItem: coinItem})}

        {/* <div className="code">
          <pre>
            {JSON.stringify(coinItem, null, 2)}
          </pre>
        </div> */}


      </div>
    </>
  )

}



export default ClubCoinItemLayout;