
const initialState = {
  notiPopups: [],
  readNotiListables: [],
  unreadNotiListables: []
};

const notiReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "SET_NOTI_POPUPS":
      return {
        ...state, 
        notiPopups: action.notis
      }
    case "UNSET_NOTI_POPUPS":
      return {
        ...state, 
        notiPopups: [],
      }
    case "REMOVE_NOTI_POPUP":
      return {
        ...state, 
        notiPopups: state.notiPopups.filter( (noti) => noti.id !== action.id )
      }
    case "SET_NOTI_LISTABLES":

      // alert(JSON.stringify(action));
      return {
        ...state, 
        readNotiListables: action.readNotiListables ? action.readNotiListables : state.readNotiListables,
        unreadNotiListables: action.unreadNotiListables ? action.unreadNotiListables : state.unreadNotiListables,
      }
    case "REMOVE_NOTI_LISTABLE":
      return {
        ...state, 
        unreadNotiListables: state.unreadNotiListables.filter( (noti) => noti.id !== action.id )
      }
    default :
      return state
  }
}

export default notiReducer;