import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getTeam } from 'redux/club/teamActions';

import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 

import ClubTeamNav from './ClubTeamNav';

const ClubTeam = () => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTeam(id)
    }
  }, [id]);

  const [team, teamLoading, teamLoaded, teamError, doFetchTeam, setTeamResults] = useApi();

  const fetchTeam = id => {
    doFetchTeam(getTeam, {query: {id: id}})
  }
  

  return (
    <>

      {false &&
      <div className="flex-cont flex-wrap padding-1 flex-grid">

        <div className="flex-1">
          <div className="align-center justify-center card grid-item box">
            <div className="full">
              <div className="font-1-5 margin-bottom-1 bold">
                Distance Goal
              </div>
              {/* <Bar progress={57} /> */}
              <div className="number-label margin-top-1">
                570 / 1000 mi.
              </div>
            </div>
          </div>
      </div>
      <div className="flex-1">
          <div className="align-center justify-center card grid-item box">
            <div className="full">
              <div className="font-1-5 margin-bottom-1 bold">
                Distance Goal
              </div>
              {/* <Bar progress={57} /> */}
              <div className="number-label margin-top-1">
                570 / 1000 mi.
              </div>
            </div>
          </div>
      </div>
      <div className="flex-1">
          <div className="align-center justify-center card grid-item box">
            <div className="full">
              <div className="font-1-5 margin-bottom-1 bold">
                Distance Goal
              </div>
              {/* <Bar progress={57} /> */}
              <div className="number-label margin-top-1">
                570 / 1000 mi.
              </div>
            </div>
          </div>
      </div>
    </div>
    }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(team, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubTeam;