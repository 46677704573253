

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm, Controller } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'
import { createTrack, updateTrack } from 'redux/club/trackActions';
import { createPledgeDrive } from 'redux/club/pledgeDriveActions';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"
import DatePicker from 'react-date-picker';
import '../../../styles/Calendar.scss';


const TrackSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipcode: yup.string().required("Zipcode is required."),
  id: yup.string().nullable(),
  how_many_laps: yup.number().required("Number of laps is required."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});

const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const TrackForm = ({currentTrack}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: TrackSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [tracksResult, tracksLoading, tracksLoaded, tracksError, doFetchTracks, setTrackResults] = useApi();

  const onSubmit = async data => {
    let d = {
      track: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentTrack && currentTrack.id) {
      doFetch(updateTrack, {payload: d});
    }
    else {
      doFetch(createTrack, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      alert('nav')
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>



        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentTrack?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentTrack?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="street"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Street"
                name="street"
                defaultValue={currentTrack?.street}
              />
              {errors.street && <div className="field-error">{errors.street.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="city"> 
              <input 
                type="text" 
                ref={register}
                placeholder="City"
                name="city"
                defaultValue={currentTrack?.city}
              />
              {errors.city && <div className="field-error">{errors.city.message}</div>}
            </label>
            <label htmlFor="state"> 
              <input 
                type="text" 
                ref={register}
                placeholder="State"
                name="state"
                defaultValue={currentTrack?.state}
              />
              {errors.state && <div className="field-error">{errors.state.message}</div>}
            </label>
            <label htmlFor="zipcode"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Zipcode"
                name="zipcode"
                defaultValue={currentTrack?.zipcode}
              />
              {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="how_many_laps"> 
              <input 
                type="text" 
                ref={register}
                placeholder="How many laps equals a mile?"
                name="how_many_laps"
                defaultValue={currentTrack?.how_many_laps}
              />
              <span className="margin-left-1">laps</span>
              {errors.how_many_laps && <div className="field-error">{errors.how_many_laps.message}</div>}
            </label>
          </div>

      <div className="align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}

const OnboardingTracks = ({currentClub}) => {
  const currentTrack = null;
  const navigate = useNavigate();

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const { watch, register, errors, handleSubmit, getValues, control } = useForm({
    // validationSchema: TrackSchema
  });

  const onSubmit = (data) => {
    const payload = {
      track: {
        ...data
      }
    }
    doFetch(createTrack, {payload: payload})
  }

  const hasErrors = () => (errors && Object.keys(errors).length !== 0);
  const buttonDisabled = () => (hasErrors() || loading);

  useEffect(() => {
    if (loaded) {
      navigate('/');
    }
  }, [loaded]);

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);


  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);


  const getAvatarURL = () => {
    return fileURL ? fileURL : currentTrack?.avatar_url
  }


  const watchLapDistance = watch("lap_distance", currentTrack?.lap_distance);
  const parsedWatchLapDistance = parseFloat(watchLapDistance);


  const isNumber = num => {
    const float = parseFloat(num);
    if (float === undefined || float === null || float === "" || isNaN(float)) return false;
    return true;
  }

  const calculatedFullLaps = distance => {
    return Math.floor(1/parseFloat(distance))
  }

  const calculatedFullLapsDistance = distance => {
    return Math.round((calculatedFullLaps(watchLapDistance) * parseFloat(watchLapDistance)) * 100 + Number.EPSILON ) / 100
  }
  
  const calculatedLastLapDistance = distance => {
    return Math.round((1.000 - (calculatedFullLaps(parseFloat(distance)) * parseFloat(distance))) * 100 + Number.EPSILON ) / 100
  }
  const calculatedLastLapDecimal = distance => {
    return Math.round( 
      ((1.000 - (calculatedFullLaps(parseFloat(distance)) * parseFloat(distance))) / parseFloat(watchLapDistance)) * 100 + Number.EPSILON ) / 100
  }



  
  return(
    <>
      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">4</div>
        <h1 className="no-margin-bottom">Track</h1>
        <h2 className="no-margin-top">Add a track for your school.</h2>

        <div className="margin-top-3">
        <form onSubmit={handleSubmit(onSubmit)}>

          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentTrack?.id}
          />
          

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>
                Nickname
              </span>
              <input 
                type="text" 
                ref={register}
                placeholder="Nickname"
                name="name"
                defaultValue={currentTrack?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="lap_distance"> 
              <div className="flex-cont align-center">

                <div>
                  <span>
                    Distance of Lap
                  </span>
                  <br/>

                  <div className="relative flex-cont align-center">
                    <input 
                      type="text" 
                      ref={register}
                      placeholder="Lap Distance"
                      name="lap_distance"
                      defaultValue={currentTrack?.lap_distance}
                      className="auto"
                    />
                    <div className="bold font-075 inner-label raight">miles</div>
                  </div>
                    
                </div>
                
                <div className="flex-1"></div>
              </div>
            
              {errors.lap_distance && <div className="field-error">{errors.lap_distance.message}</div>}
            </label>
          </div>
        
          <div className="card no-hover">
            <div className="bold gray margin-bottom-1">FOR TIMED MILE RUNS</div>

            

            {(isNumber(parsedWatchLapDistance) && parsedWatchLapDistance > 0 ) ? (
                <>
                  <div className="font-2 margin-bottom-half bold">1 mile = </div>
                  <div className="margin-left-1 font-1-5">
                    {calculatedFullLaps(parsedWatchLapDistance) !== 0 &&
                      <div>
                        {calculatedFullLaps(parsedWatchLapDistance)} {`${(calculatedFullLaps(parsedWatchLapDistance) === 1) ? 'full lap' : 'full laps'}`} <span className="gray">({calculatedFullLapsDistance(parsedWatchLapDistance)} mi)</span>
                      </div>
                    }

                    

                    {calculatedLastLapDecimal(parsedWatchLapDistance) !== 0 &&
                      <>
                        {calculatedFullLaps(parsedWatchLapDistance) !== 0 &&
                          <div className="gray">+</div>
                        }

                        <div>
                          {calculatedLastLapDecimal(parsedWatchLapDistance)} {`${(calculatedFullLaps(parsedWatchLapDistance) === 0) ? '' : 'final' }`} lap <span className="gray">({calculatedLastLapDistance(parsedWatchLapDistance)} mi)</span>
                          
                        </div>
                      
                      </>
                      
                    }
                  </div>
                </>
              ) : (
                <em>Please enter a lap distance above.</em>
              )
            }
          </div>


        <div className="margin-top-3 align-center flex-cont">
          {/* <input type="submit" value="Save" className="button" /> */}

          <button disabled={uploadLoading || loading} className={`${(loading || uploadLoading) ? 'disabled' : ''} button`}>
            Save
            {loading &&
              <div className="spinner button-spinner"></div>
            }
          </button>
          <div className="flex-1"></div>
        </div>

        </form>
        </div>


      </div>
     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingTracks);