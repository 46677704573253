import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import iphone from 'images/iphone7.png';
import bg from 'images/web-bg-2.jpg';
import kid from 'images/kid.png';
import arrowLogo from 'images/arrow_logo1.svg';
import logo7 from 'images/herorun_words3.svg'; 
import miniLogo from 'images/hr_logo2.svg';
import { ReactComponent as ArrowLogo } from 'images/logo_large1.svg';
import { ReactComponent as Dial } from 'images/dial2.svg';
import useScrollPosition from 'hooks/useScrollPosition';

import 'styles/Home.scss';
// import Spinner from '.components/Spinner';

const NewHomeScreen = () => {

  const [scrollPosition, isTop] = useScrollPosition();
  

  return (
    <div className="HomeScreen full">

      {/* <div className="home-cont video slant">
        <video controls preload="metadata">
          <source src="https://fyzeak.s3-us-west-1.amazonaws.com/hrtest2.mp4#t=5" type="video/mp4" />
        </video>
      </div> */}


      {true &&
      <div className="home-splash slant">
      <div style={{backgroundImage: `url(${bg})`}} className="home-bg"></div>
        <div className="home-banner-cont flex-cont narrow-2">
          <div className="home-banner flex-1">
            <h1>
              Your<br/>school<br/>needs a<br/><span>hero.</span>
            </h1>
          </div>

          <div className="home-button-cont flex-1">
            <Link className="button" to='/login'>
              <div className="buttonxx">
                Get Started
                <i className="font-1-25  margin-left-1 fas fa-chevron-right"></i>
              </div>
            </Link>
          </div>

          {/* <div className="splash-cont">
            <div className="splash">
              <ArrowLogo className="arrow" />
              <Dial className="dial" />
            </div>
          </div> */}
        </div>
        {/* <div style={{backgroundImage: `url(${kid})`}} className="home-bg"></div> */}
    </div>
      }







      <div className="home-cont below-video slant">
       {/* <div style={{backgroundImage: `url(${bg})`}} className="home-bg"></div> */}
          <div className="slant-content">

          
        <div className="narrow-2 center">

        

        <div style={{marginBottom: '50px', marginTop: '50px'}}>
          <img style={{maxWidth: '400px'}} src={arrowLogo} alt="Logo" /> 
        </div>


        <div className="flex-cont flex-wrap-reverse">

          <div className="flex-1 phone-cont">
            <img src={iphone} />
          </div>

          <div className="padding-1 flex-1">
            <h1 className="text-left">Run.<br/>Track.<br/>Give back.</h1>

            <p className="text-left copy">
            
              Our mission is to equip schools with a powerful tool that addresses two essential needs: improve student fitness and raise crucial funds. We provide an effective, ethical and transparent fundraising tool combined with an engaging, metric based fitness platform to inspire students to improve their own personal fitness while helping their school or others in the process… like a Hero.
            </p>
          </div>
         

          </div>
          
          
        </div>

        </div>
        
      </div>

      <div className="center margin-top-4 margin-bottom-4">
          <img style={{width: '300px', maxWidth: '80%'}} src={miniLogo} alt="Logo" />
      </div>

      {false &&

        <div className="home-cont below-video slant" style={{background: '#ffffff'}}>
        
            <div className="slant-content">

            
          <div className="narrow-2 center">


          <div className="flex-cont flex-wrap">

            <img style={{width: '100%', alignSelf: 'flex-start'}} src="https://fyzeak.s3-us-west-1.amazonaws.com/hr-machine-front.jpg" alt=""/>
            <img style={{width: '100%', alignSelf: 'flex-start'}} src="https://fyzeak.s3-us-west-1.amazonaws.com/hr-machine-back.jpg" alt=""/>

            

            {/* <div className="padding-1 flex-1">
              <h1 className="text-right">Get<br/>active.</h1>

              <p className="text-left copy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>

            <div className="flex-1 phone-cont">
              <img src={iphone} />
            </div> */}
          

            </div>
            
            
          </div>

          </div>
    
        </div>
      }



    </div>
  )
}



const HomeScreen = props => {

  const [is3d, setIs3d] = useState(false);
  useEffect(() => {

    setTimeout(() => {
      setIs3d(true);
    }, 300);
    
  }, []);

  return <NewHomeScreen />


  return (
    <div className="HomeScreen full">

    
    <div className="home-splash">
      <div style={{backgroundImage: `url(${bg})`}} className="home-bg"></div>
        <div className="home-banner-cont flex-cont narrow-2">
          <div className="home-banner flex-1">
            <h1>
              Your<br/>school<br/>needs a<br/><span>hero.</span>
            </h1>
          </div>

          <div className="home-button-cont flex-1">
            <div className="button">
              Get Started
              <i className="font-1-25  margin-left-1 fas fa-chevron-right"></i>
            </div>
          </div>

          {/* <div className="splash-cont">
            <div className="splash">
              <ArrowLogo className="arrow" />
              <Dial className="dial" />
            </div>
          </div> */}
        </div>
        {/* <div style={{backgroundImage: `url(${kid})`}} className="home-bg"></div> */}
    </div>


    


      

     


      <div className="home-cont video slant">
        <video controls preload="metadata">
          <source src="https://fyzeak.s3-us-west-1.amazonaws.com/hrtest.mp4#t=5" type="video/mp4" />
        </video>
      </div>

      <div className="home-cont below-video slant">
      
          <div className="slant-content">

          
        <div className="narrow-2 center">


        <div className="flex-cont flex-wrap-reverse">

          <div className="flex-1 phone-cont">
            <img src={iphone} />
          </div>

          <div className="padding-1 flex-1">
            <h1 className="text-left">Run.<br/>Track.<br/>Give back.</h1>

            <p className="text-left copy">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
         

          </div>
          
          
        </div>

        </div>
        
      </div>

      <div className="home-cont below-video slant" style={{background: 'lightgray'}}>
      
          <div className="slant-content">

          
        <div className="narrow-2 center">


        <div className="flex-cont flex-wrap-reverse">

          

          <div className="padding-1 flex-1">
            <h1 className="text-right">Run.<br/>Track.<br/>Give back.</h1>

            <p className="text-left copy">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>

          <div className="flex-1 phone-cont">
            <img src={iphone} />
          </div>
         

          </div>
          
          
        </div>

        </div>
        
      </div>

      {/* <div className={`${is3d ? 'start' : ''} bg-block full`}></div> */}
      {/* <div className="center narrow margin-top-4">

        <div className="splash-cont">
          <div className="splash">
            <ArrowLogo className="arrow" />
            <Dial className="dial" />
          </div>

        </div>
          
     

        
        


        <Link to='/get-started' className="margin-top-4 font-1-5 wide button">Get Started</Link>
      </div> */}
      

      <div className={`${is3d ? 'start' : ''} skewedx`}></div>

      
      
    </div>
  );
};

export default HomeScreen;    