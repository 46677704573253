import React, {useState} from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';


const EventModal = ({clubEvent, toggleModal}) => {

  return (
    <Modal focusTrap={false} toggleModal={toggleModal}>
      <div className="modal-header flex-cont align-center">
        {/* <h2 className="flex-1">{currentTeam ? 'Edit' : 'New'} Team</h2> */}
        <div className="flex-1">
          <div className="bold font-2 margin-top-1">
            {clubEvent.title}
          </div>
        </div>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>
      <div className="padding-1">

        
        <div className="flex-cont align-center font-1-5">
          <i className="far fa-calendar gray font-1 margin-right-half"></i>
          {clubEvent.start_date && DateTime.fromISO(clubEvent.start_date).toLocaleString(DateTime.DATE_HUGE)}
          {/* {clubEvent.end_date &&
            <>
               - {DateTime.fromISO(clubEvent.start_date).toLocaleString(DateTime.DATE_HUGE)}
            </>
          } */}
        </div>
        <div className="flex-cont align-center font-1-5">
          <i className="far fa-clock gray font-1 margin-right-half"></i>
          {clubEvent.start_date && DateTime.fromISO(clubEvent.start_date).toLocaleString(DateTime.TIME_SIMPLE)}
        </div>

        <div className="margin-top-2 margin-bottom-4">
          {clubEvent.description}
        </div>
        {/* <pre>
          {JSON.stringify(clubEvent, null, 2)}
        </pre> */}
      </div>
    </Modal>
  )
}

const ProfileWeek = ({week}) => {

  const [clubEvent, setClubEvent] = useState(null);


  const eventClicked = event => {
    setClubEvent(event);
  }

  const toggleModal = () => {
    setClubEvent(null);
  }


  return (
    <>
      {clubEvent &&
        <EventModal toggleModal={toggleModal} focusTrap={false} clubEvent={clubEvent} />
      }
      <div className="flex-cont dash-week-cont card no-hover" style={{background: 'white', padding: '.5em'}}>
        {week && week.length !== 0 && week?.map(day =>

          <div className="day-cont flex-1">
            <div className="dates">
              <div>
                <span className="bold">{DateTime.fromISO(day.datetime_string)?.toFormat('ccc')} </span>
                <span> {DateTime.fromISO(day.datetime_string)?.toFormat('L/d')}  </span>
              </div>
            </div>
            <div  className="dash-day">
              <div>
                {day?.events?.map(event => 
                  <div onClick={() => eventClicked(event)} className="event clickable-event">{event.title || "Event"}</div>  
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ProfileWeek;