

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { Link } from 'react-router-dom';
import { getCoinItems, createCoinItem, updateCoinItem, deleteCoinItem } from 'redux/club/coinItemActions';

import ClubCoinItemsNav from './ClubCoinItemsNav';
import CoinItemForm from './CoinItemForm';
import coinPNG from 'images/herocoin.png';

const ClubCoinItems = ({currentUser}) => {

  const [coinItems, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedCoinItem, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedCoinItem] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingCoinItem, setEditingCoinItem] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchCoinItems();
    }
  }


  useEffect(() => {

    fetchCoinItems();
    
  }, []);

  const fetchCoinItems = () => {
    doFetch(getCoinItems);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingCoinItem, setDeletingCoinItem] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchCoinItems();
    }
  }


  const editClicked = coinItem => {
    setEditingCoinItem(coinItem);
    toggleModal();
  }

  const deleteClicked = coinItem => {
    setDeletingCoinItem(coinItem);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingCoinItem) {
      // doDeleteFetch(deleteCoinItem, {query: {id: deletingCoinItem.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);



  

  return (
    <>
      <div className="padding-top-1">
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <CoinItemForm setCurrentCoinItem={setEditingCoinItem} currentCoinItem={editingCoinItem} toggleModal={toggleModal} />
        </Modal>
      }

      <div className="flex-cont">
        <div className="flex-1">
          <h1>Coin Store</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Coin Item</div>
        </div>
      </div>

      <ClubCoinItemsNav />

      {loading &&
        <div className="spinner"></div>
      }

      {coinItems &&
        <table className="basic margin-top-3">
          <tr>
            <th></th>
            <th>Name</th>
            <th>Price</th>
            <th>Inventory</th>
            <th>Purchases</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
          {coinItems.map(coinItem => {
            return (
              <tr>
                <td className="fit nowrap center">
                  <Link className="text-link" to={`/coin-items/${coinItem.slug}`}>
                    <img style={{height: '3rem'}} src={coinItem?.featured_image_url} alt="" />
                  </Link>

                  {/* <div style={{fontSize: '3rem', backgroundImage: `url( ${coinItem?.featured_image_url} )`}} className="team-logo small">
                  
                  </div> */}
                </td>
                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/coin-items/${coinItem.slug}`}>
                    {coinItem.name}
                  </Link>
                </td>
                <td  className="fit nowrap">
                  <div className="flex-cont align-center">
                    <img className="margin-right-half" style={{height: '1em'}} src={coinPNG} alt="" />
                    <div className='gold bold'>{coinItem.price}</div>
                  </div>
                  
                  
                  {/* {coinItem.price ? 
                    parseFloat(coinItem.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    :
                    "-"
                  } */}
                </td>
                <td className="fit nowrap center">
                  {coinItem.inventory}
                </td>
                {/* <td className="fit nowrap center">
                  {coinItem.club_request_inventory_count}
                </td> */}
                <td  className="fit nowrap center">
                  {coinItem.purchase_count}
                </td>
                <td  className="fit nowrap center">
                  {coinItem.status?.toUpperCase()}
                </td>
                <td></td>
                <td>
                  {/* <div onClick={() => editClicked(coinItem)} className="button outline">
                    Edit
                  </div> */}
                  <Link to={`/coin-items/${coinItem.slug}`} className="button outline">
                    View
                  </Link>

                </td>
              
              </tr>
            )
          })}
        </table>
      }


      {!loading && (!coinItems || coinItems.length === 0) &&
        <em>No results.</em>
      }


      

      </div>  
    </>
  )
}


export default ClubCoinItems;