import { andyFetch } from '../../api';

export const getPages = (params) => {
  return async (dispatch, getState) => {

    const url = "pages";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();

        dispatch({type: 'SET_PAGES', pages: json.data });

        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPages error", e);
    }
  }
};

export const getPage = (params) => {
  return async (dispatch, getState) => {

    const url = `pages/${params.query.slug}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPage error", e);
    }
  }
};