import React from 'react';
import { NavLink } from 'react-router-dom';


const ClubAccountNav = ({}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/account`}>Account Details</NavLink></li>
        <li><NavLink end to={`/account/hero-bases`}>Hero Bases</NavLink></li>
        <li><NavLink end to={`/account/stripe`}>Stripe</NavLink></li>
      </ul>
    </div>
  )

}

export default ClubAccountNav;