

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { HexColorPicker } from "react-colorful";
import Modal from 'components/Modal';

import { getCoinItems, createCoinItem, updateCoinItem, deleteCoinItem } from 'redux/club/coinItemActions';

import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { API_URL } from '../../../api'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, FileInput, useUppy } from '@uppy/react'
import { useEditor, EditorDescription } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Masonry from 'react-masonry-css'
import CoinItemCard from './CoinItemCard'

import { useNavigate } from "react-router-dom"

const CoinItemSchema = yup.object().shape({
  name: yup.string(),
  description: yup.string(),
});


const CoinItemForm = ({toggleModal, currentCoinItem, setCurrentCoinItem}) => {

  const navigate = useNavigate();

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    // validationSchema: CoinItemSchema
  });

  const [color, setColor] = useState("#FFFFFF");
  const [pickerOpened, setPickerOpened] = useState(false);
  // const [uppy, setUppy] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [name, setName] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    description: "",
  });

  const removeImageClicked = () => {
    setFileURL(null);
    setFile("");
    setValue('coin_item_images_attributes.0._destroy', true);


    if (currentCoinItem) {
      setCurrentCoinItem(old => {
          return (
            {
              ...old,
              featured_image_url: null
            }
          );
        }
      );
    }
  }

  const uppy = useUppy(() => {
    return new Uppy({
      // meta: { type: 'image' },
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.png', '.jpg', '.jpeg']
      }
    }).use(AwsS3Multipart, { 
      companionUrl: API_URL
    });
  })

  useEffect(() => {
    
  }, []);

  useEffect(() => {

    if (uppy) {
      uppy.on('upload', (data) => {
        setUploadLoading(true);
      });
  
      uppy.on('complete', (result) => {
        setUploadLoading(false);
      });
      
      uppy.on('upload-success', function (file, response) {
        
        var uploadedFileData = JSON.stringify({
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: 'cache',
          metadata: {
            size:      file.size,
            filename:  file.name,
            mime_type: file.type,
          }
        })
  
        setFileURL(response.uploadURL);
  
        // console.log('file', file);
        // console.log('response', response);
        // console.log(uploadedFileData);
  
        setFile(uploadedFileData);
        
        uppy.reset()
        // ...
      })
    }
    
  }, [uppy]);
  


  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [coinItemsResult, coinItemsLoading, coinItemsLoaded, coinItemsError, doFetchCoinItems, setCoinItemResults] = useApi();

  const onSubmit = async data => {
    let d = {
      coin_item: {
        ...data
      }
    }

    // alert(JSON.stringify(currentCoinItem, null, 2))


    
    if (currentCoinItem && currentCoinItem.id) {
      doFetch(updateCoinItem, {payload: d});

    }
    else {
      doFetch(createCoinItem, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const finalUrl = fileURL ? fileURL : currentCoinItem?.featured_image_url;
  const featuredImage = currentCoinItem?.coin_item_images?.[0];

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const deleteClicked = () => {
    toggleDeleteModal();
  }
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteCoinItem, {query: {id: currentCoinItem.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      navigate('/coin-items');
    }
    
  }, [deleteLoaded]);


  const getCoinItemObj = () => {
    
    return getValues();

  }

  const statuses = ["active", "inactive"];

  return (
    <>
    {deleteModalOpened &&
      <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
        <div className="padding-2">
          Are you sure you want to delete this item?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div onClick={toggleDeleteModal} disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className={`${deleteLoading ? 'disabled' : ''} button danger`}>

            <span>Delete</span>
            {deleteLoading &&
              <div className="spinner button-spinner"></div>
            }
            
          </div>
        </div>
      </Modal>
    }
    



    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentCoinItem ? 'Edit' : 'New'} Coin Item</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentCoinItem?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <span>Name</span>
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              onChange={e => setName(e.target.value)}
              defaultValue={currentCoinItem?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        {/* <EditorDescription editor={editor} /> */}

        <div className="flex-cont">
          <label htmlFor="description"> 
            <span>Description</span>
            <textarea 
              ref={register}
              placeholder="Description"
              name="description"
              defaultValue={currentCoinItem?.description}
            />
            {errors.description && <div className="field-error">{errors.description.message}</div>}
          </label>
        </div>

        <div className="flex-cont align-center">
          <label className="" htmlFor="price"> 
            <span className="bold">
              Coins
            </span>
            <br />
            <input 
              type="number" 
              min={0}
              step={1}
              ref={register}
              placeholder="Price"
              className="auto"
              name="price"
              defaultValue={currentCoinItem?.price}
            />
            {errors.price && <div className="field-error">{errors.price.message}</div>}
          </label>
        </div>

        <div className="flex-cont flex-cont align-center">
          <label className="" htmlFor="inventory"> 
            <span className="bold">
              Inventory
            </span>
            <br />
            <input 
              type="text" 
              ref={register}
              placeholder="Inventory"
              className="auto"
              name="inventory"
              defaultValue={currentCoinItem?.inventory}
            />
            {errors.price && <div className="field-error">{errors.price.message}</div>}
          </label>
        </div>

        <pre>
          {JSON.stringify(currentCoinItem, null, 2)}
        </pre>

        <label className="margin-bottom-2">
            <span className="margin-right-half">
              Status
            </span>
            <select defaultValue={currentCoinItem?.status} ref={register({required: "Status is required"})} name="status" id="status">
              {/* <option value="">Select status:</option> */}
              {statuses.map(status =>
                <option value={status}>{status.toUpperCase()}</option>  
              )}


            </select>
          </label>

        <div className="flex-cont margin-top-1 margin-bottom-1">
          {uppy && 
            <>
              <FileInput 
                uppy={uppy} 
                className="outline button"
                inputName={"coin_item_images_attributes[][image]"}
                locale={{
                  strings: {
                    chooseFiles: "Choose image"
                  }
                }}
              />
              <StatusBar uppy={uppy} />
            </>
          }
        </div>

        {finalUrl && 
          <>
            <div className="margin-top-1 margin-bottom-1 gray bold font-075">
              IMAGE PREVIEW:
            </div>
            <div>
              <img style={{width: '200px'}} src={finalUrl || currentCoinItem?.featured_image_url} alt="" />
            </div>

            <div className="margin-top-1">
              <div onClick={removeImageClicked} className="button ghost">Remove Image</div>
            </div>
          </>
        }

        


        {/* <div>
          <CoinItemCard showTools={false} name={name} imageUrl={finalUrl} coinItem={getCoinItemObj()} getCoinItem={getCoinItemObj} />
        </div> */}


        {/* <div>
          {JSON.stringify(currentCoinItem, null, 2)}
        </div> */}



        <input ref={register} type="hidden" name="coin_item_images_attributes.0.image" value={file} />
        <input ref={register} type="hidden" name="coin_item_images_attributes.0.id" value={featuredImage?.id} />
        <input ref={register} type="hidden" name="coin_item_images_attributes.0._destroy"  />
      </div>

      <div className="modal-footer align-center flex-cont">
        {/* <input type="submit" value="Save" className={`${loading ? 'disabled' : ''} button`} /> */}

        <button className={`${loading ? 'disabled' : ''} button`}>
          Save
          {loading && <div className="spinner button-spinner"></div>}
        </button>


        <div className="flex-1"></div>

        {currentCoinItem &&
          <div onClick={deleteClicked} className={`${loading ? 'disabled' : ''} ghost button danger`}>
            Delete
            {deleteLoading && <div className="spinner button-spinner"></div>}
          </div>
        }
      </div>

    </form>
    </>
  )

}



export default CoinItemForm;