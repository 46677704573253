

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrentPledgeDrive } from '../../../redux/club/pledgeDriveActions';
import { getMyTeam } from '../../../redux/club/teamActions';
import { useApi } from '../../../api';
import ClubWeek from '../ClubWeek';
import { Link } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import coinPNG from 'images/herocoin.png';
import coinSVG from 'images/coin.svg';
import { getMyClubEvents } from 'redux/club/runMonitor/clubEventActions';

const RunMonitorHome = ({currentTeam, currentClub, currentUser, children}) => {

  const [clubEvents, clubEventsLoading, clubEventsLoaded, clubEventsError, doClubEventsFetch, setClubEventsResult] = useApi();

  const fetchEvents = () => {
    doClubEventsFetch(getMyClubEvents);
  }

  useEffect(() => {
    fetchEvents();
  }, []);



  return (

    <>
      <div className="uni-banner"></div>

        <div className="club-home-cont">
          <div className="flex-cont flex-wrap justify-center align-centerx">
            <div className="center margin-right-1">
              <div style={{backgroundImage: `url("${currentClub?.avatar_url}")`}} className="avatar-cont font-10 avatar-shadow ">
                {currentClub && !currentClub.avatar_url &&
                  <i className="fas fa-school"></i>
                }
              </div>
              <div style={{maxWidth: '15rem'}} className="avatar-name margin-top-1 bold font-1-25 margin-bottom-0 blue">{currentUser?.first_name} {currentUser?.last_name}</div>
              <div className="font-08 bold gray">RUN MONITOR</div>
            </div>
            <div className="flex-1 margin-top-1">
              <ClubWeek week={clubEvents ? clubEvents : []} />
            </div>
            
          </div>
      </div>
    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(RunMonitorHome);