import React from 'react'

const ProfileBadges = () => {
  return (
    <>
      <h1>Badges</h1>
    </>
  );
}
 
export default ProfileBadges;