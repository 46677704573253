import React from "react";
import { useEffect } from "react";
import Modal from "components/Modal";
import { updateCurrentSchoolYear } from "redux/club/schoolYearActions";
import { useForm } from 'react-hook-form';
import { useApi } from '../../../api';
import SubmitButton from "components/SubmitButton";

const ClubSchoolYearForm = ({toggleModal, currentSchoolYear}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({});

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    // alert('here')
    let d = {
      school_year: {
        ...data
      }
    }

    doFetch(updateCurrentSchoolYear, {payload: d});
    
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  
    return () => {
      
    }
  }, [loaded])
  

  return (
    <>
      <Modal toggleModal={toggleModal} >

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Edit {currentSchoolYear?.name} Details</h2>
            <div onClick={toggleModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">

            <div className="flex-cont">
              <label htmlFor="fee_amount"> 
                <span>Material Fee</span>
                <div className="flex-cont align-center relative">
                
                <div className="inner-label left">$</div>
                <input 
                  type="number" 
                  step="0.01"
                  min={0}
                  max={1000}
                  ref={register}
                  placeholder="Material Fee"
                  name="fee_amount"
                  className="left-padding"
                  defaultValue={currentSchoolYear?.fee_amount}
                />
                {errors.fee_amount && <div className="field-error">{errors.fee_amount.message}</div>}
                </div>
              </label>
            </div>
          

          </div>

          <div className="modal-footer align-center flex-cont">
            <SubmitButton label="Save" disabled={loading || loaded} loading={loading} />
          </div>


        </form>


      </Modal>

    </>
  )

}

export default ClubSchoolYearForm;