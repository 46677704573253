import { andyFetch } from '../../api';

export const getRaces = (params) => {
  return async (dispatch, getState) => {

    const url = "timer/races";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getRaces error", e);
    }
  }
};


export const createRace = (params) => {

  return async (dispatch, getState) => {

    const url = "timer/races";

    try {

      const response = await andyFetch(url, params, "POST");

      console.log("Response 2", response)


      if (response.ok) {
        console.log("R 1a");
        const json = await response.json();
        console.log("R 1b", json);
        return json.data;

      } else {
        console.log("R 2");
        throw new Error('createRace error.');
      }
    }
    catch (e) {
      console.log("R 3");
      // return {error: e.toString()}
      console.log("createRace error", e);
    }
  }
};


export const updateRace = (params) => {

  return async (dispatch, getState) => {

    const url = `timer/races/${params.payload.race.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateRace error.');
      }
    }
    catch (e) {
      console.log("updateRace error", e);
    }
  }
};