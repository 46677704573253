

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TeamAdminBottomNav from './TeamAdminBottomNav';
import { getMyTeam } from '../../../redux/club/teamActions';
import { useApi } from '../../../api';


const TeamAdminLayout = ({currentTeam, currentClub, currentUser, children}) => {

  const [team, currentTeamLoading, currentTeamLoaded, currentTeamError, doCurrentTeamFetch, setCurrentTeamResult] = useApi();

  useEffect(() => {
    doCurrentTeamFetch(getMyTeam);
  }, []);

  if (currentTeamLoading) {
    return (
      <div className="center">
        <div className="spinner"></div>
      </div>
    )
  }

  if (!currentTeam) {
    return (
      <div className="parent">
        <div className="content-cont flex-cont">
          <div className="narrow-2 content no-margin margin-top-3 center aligin center">
            {currentTeamLoading &&
              <div className="spinner"></div>
            }
            {!currentTeamLoading &&
              <h1>You have not been assigned to a team yet. Please try again later.</h1>
            }
          </div>
        </div>
      </div>
    )
  }

  return (

    <>
      <TeamAdminBottomNav />

      {currentClub && currentUser && currentTeam &&
        <div className="parent">
          <div className="content-cont flex-cont">
            <div className="narrow-2 content margin">

              
              {children}
            </div>

          </div>
        </div>
      }

    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(TeamAdminLayout);