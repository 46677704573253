

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/session/sessionActions';
import store from '../../redux/store';
import EmailConfBanner from '../../components/EmailConfBanner'
import ClubBottomNav from '../../screens/club/ClubBottomNav';

const AccountLayout = ({currentUser}) => {
  const navigate = useNavigate("/");

  const logoutClicked = async () => {

    alert('here');
    await store.dispatch(logoutUser());
    
    navigate("/");
  }

  useEffect(() => {
    if (!currentUser || !["admin", "customer", "club"].includes(currentUser.role)) {
      navigate('/');
    }
  }, []);

  if (!currentUser || !["admin", "customer", "club"].includes(currentUser.role)) {
    return (
      <>
      </>
    )
  }



  return (


    <div className="parent">

      <div className="content-cont flex-cont">
        
        <div className="side-nav">
          <ul>
            <li><Link to="" >Account Home</Link></li>
            <li><Link to="email-confirmation" >Email Confirmation</Link></li>
          </ul>



          <div className="margin-top-3 center">
            <div onClick={() => navigate(`/profile`)} className="ghost button">View Profile</div>

            <div onClick={logoutClicked} className="margin-top-1 ghost button">Logout</div>
          </div>
          
        </div>

        <div className="margin content">
          <EmailConfBanner currentUser={currentUser} />

          <div className="narrow-2  margin margin-top-1">
            <Outlet />
          </div>
          
        </div>
      </div>
    </div>
  )
}


function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(AccountLayout);