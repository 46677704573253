import { andyFetch } from '../../api';

export const getPledges = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/pledges";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPledges error", e);
    }
  }
};


export const createPledge = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/pledges";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPledge error.');
      }
    }
    catch (e) {
      console.log("createPledge error", e);
    }
  }
};


export const updatePledge = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/pledges/${params.payload.pledge.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePledge error.');
      }
    }
    catch (e) {
      console.log("updatePledge error", e);
    }
  }
};


export const deletePledge = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/pledges/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePledge error.');
      }
    }
    catch (e) {
      console.log("deletePledge error", e);
    }
  }
};

