

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { API_URL } from '../../api.js';
import Modal from 'components/Modal';

import { getLaps } from 'redux/club/raceActions';
import { connect } from 'react-redux';


const uppy = Uppy({
  meta: { type: 'avatar' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const ClubLaps = ({currentClub}) => {

  const [laps, loading, loaded, error, doFetch, setResult] = useApi(); 
 
  const [modalOpen, setModalOpen] = useState(false);
  const [editingLap, setEditingLap] = useState(null);

  const [fetchInterval, setFetchInterval] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchLaps();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingLap(null);
    }
  }, [modalOpen])

  useEffect(() => {
    fetchLaps();


    // const interval = setInterval(fetchLaps, 1000);
    // setFetchInterval(interval);

    return () => {

      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
      
    }
  }, []);

  const fetchLaps = () => {
    doFetch(getLaps);
  }

  useEffect(() => {
    if (loaded) {
      console.log('loaded');

      if (fetchInterval) {
        clearInterval(fetchInterval);
      }

      if (currentClub?.reader_is_on) {
        setFetchInterval(setInterval(fetchLaps, 1000));
      }
      
    }
    
  }, [loaded]);




  

  return (
    <>
      <div className="flex-cont">
        <div className="flex-1">
          <h1>Laps</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Lap</div>
        </div>
      </div>


      {currentClub?.reader_is_on &&

        <div className="margin-bottom-2 flex-cont align-center bold ruby">
          <div className="pulser red margin-right-half"></div>
          LIVE
        </div>
      }

      {/* <div style={{height: '2em'}}>
        {loading &&
          <div className="spinner"></div>
        }
      </div> */}

      

      {laps &&
        <table className="basic">
          <tr>
            <th>Name</th>
            <th>EPC</th>
            <th>Time</th>
            <th>Miles</th>
            <th></th>
            <th>Start</th>
            <th>End</th>
            {/* <th>Name</th>
            <th>Name</th>
            <th>Name</th> */}
          </tr>
          {laps.map(lap => {
            return (
              <tr>
                <td className="fit bold nowrap">{lap.athlete_name}</td>
                <td className="fit nowrap">{lap.epc}</td>
                <td className="fit nowrap">{lap.time}</td>
                <td className="fit nowrap">{lap.miles} mi.</td>
                <td></td>
                <td className="fit nowrap">{lap.start_readtime}</td>
                <td className="fit nowrap">{lap.end_readtime}</td>
                {/* <td className="fit nowrap">{lap.start_readtime}</td>
                <td className="fit nowrap">{lap.end_readtime}</td> */}
              </tr>
            )
          })}
        </table>
      }

      {!loading && (!laps || laps.length === 0) &&
        <em>No results.</em>
      }
    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(ClubLaps);