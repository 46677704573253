import { andyFetch } from '../../api';

export const removeAllNotiPopups = (params) => {
  return async (dispatch, getState) => {
    const url = "notis/read_all_popups";
    try {
     
      const response = await andyFetch(url, {}, "POST");

      if (response.ok) {
        const json = await response.json();
        dispatch({type: 'SET_NOTI_POPUPS', notis: [] });
        return json.data;
      } else {
        throw new Error('removeAllNotiPopups error.');
      }
    }
    catch (e) {
      console.log("removeAllNotiPopups error", e);
    }
  }
}

export const removeNotiPopup = (id) => {

  return async (dispatch, getState) => {

    const url = "notis/read_popup";

    try {

      const response = await andyFetch(url, {payload: {id: id}}, "POST");

      if (response.ok) {
        const json = await response.json();

        dispatch({type: 'REMOVE_NOTI_POPUP', id: id });


        return json.data;

      } else {
        throw new Error('removeNotiPopup error.');
      }
    }
    catch (e) {
      console.log("removeNotiPopup error", e);
    }
  }
}

export const readListables = () => {
  return async (dispatch, getState) => {

    const url = "notis/read_my_listables";

    try {

      const response = await andyFetch(url, {}, "POST");

      if (response.ok) {
        const json = await response.json();
        // dispatch({
        //   type: 'SET_NOTI_LISTABLES', 
        //   readNotiListables: json.data.read_listable_notis, 
        //   unreadNotiListables: json.data.unread_listable_notis, 
        // });
        return json.data;

      } else {
        throw new Error('readListables error.');
      }
    }
    catch (e) {
      console.log("readListables error", e);
    }
  }
}


export const fetchListables = () => {
  return async (dispatch, getState) => {

    const url = "notis/my_listables";

    try {

      const response = await andyFetch(url, {}, "GET");

      if (response.ok) {
        const json = await response.json();

        dispatch({
          type: 'SET_NOTI_LISTABLES', 
          readNotiListables: json.data.read_listable_notis, 
          unreadNotiListables: json.data.unread_listable_notis, 
        });

        return json.data;

      } else {
        throw new Error('fetchListables error.');
      }
    }
    catch (e) {
      console.log("fetchListables error", e);
    }
  }
}
