


import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, Outlet, useNavigate, useParams, useOutletContext, useLocation } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { getPledgeByCode } from 'redux/user/pledgeActions';
import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import SubmitButton from '../../components/SubmitButton';

const queryString = require('query-string');



const PledgeVerify = () => {

  let location = useLocation();
  const params = queryString.parse(location.search);


  const [pledgePage] = useOutletContext();

  let { code } = useParams();
  const navigate = useNavigate("/");

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = data => {

    // alert('here')
    // alert(JSON.stringify(data, null, 2))
    doFetch(getPledgeByCode, {query: {pledge_code: data.pledge_code}});
  }

  useEffect(() => {
    
    if (loaded && result?.code) {
      // navigate(`/pledge/edit?pledge_code=${result.code}`);
      navigate(`/pledge/progress/${result.code}`)
    }
    else {
      
    }
  }, [loaded, result])



  return (
    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>

        <div className="narrow relative">

          <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
            <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
              <form onSubmit={handleSubmit(onSubmit)}>

                {/* <div className="blue font-3 bold-200 margin-bottom-3">
                  Supporting {pledgePage.athlete_first_name} {pledgePage.athlete_last_name} and {pledgePage.club_name}
                </div> */}

                <div className="bold font-1-5 margin-bottom-2">
                  We have found an exististing pledge with this email address. Please enter your pledge code to access your pledge.
                </div>

                <div className="margin-bottom-2">
                  <Link to={`/forgot-pledge-code?email=${params?.email}`} className="text-link margin-right-2">
                    Forgot pledge code?
                  </Link>
                  
                </div>

                <div className="flex-cont">
                  <label htmlFor="pledger_name">
                    <span>Pledge Code</span>
                    <input  ref={register} placeholder="Pledge Code" type="text" name="pledge_code" id=""/>
                    {errors.pledge_code && <div className="field-error">{errors.pledge_code.message}</div>}
                  </label>
                </div>

                {/* <div className="margin-bottom-1">
                  We have found an exististing pledge for this email. Please enter your pledge code to access your pledge.
                </div> */}

              
                
                <div className="margin-top-2 font-1-5 justify-center align-center flex-cont">


                  <SubmitButton label={
                    <React.Fragment>
                      <span>Continue</span>
                      <i className="font-1 fas fa-arrow-right margin-left-half"></i>
                    </React.Fragment>
                  } disabled={(errors && Object.keys(errors).length !== 0)} loading={loading} />
                </div>
              </form>
            </div>

            <PledgeSidebar pledgePage={pledgePage}  isPreview={false} showButton={false} />
          </div>

        </div>

        

      </div>
    </>
  )
}

export default PledgeVerify;