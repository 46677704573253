

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { DateTime, Duration } from 'luxon';
import { AreaChart, ComposedChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {getMileTimes, getAthleteMileProgression, getAthleteMileTimes} from 'redux/club/statsActions';

 
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <div className="">{`${label}`}</div>
        <div className="bold margin-top-half">{Duration.fromMillis(payload[0].value*1000.00).toFormat('mm:ss')}</div>
      </div>
    );
  }

  return null;
};

const ProfileMileChart = ({mileData}) => {
  // const [mileDatas, loading, loaded, error, doFetch, setResult] = useApi(); 

  // const mileData = mileDatas?.[0];


  // useEffect(() => {
  //   fetchMyCareers();
  // }, []);

  // const fetchMyCareers = async () => {
  //   doFetch(getAthleteMileTimes);
  // };

  if (!mileData) return <></>;

  return (
    <>

      {/* <pre>
        {JSON.stringify(mileData, null, 2)}
      </pre> */}
      
      <div style={{minWidth: '300px', height: '100px'}}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={800}
            height={100}
            data={mileData}
            margin={{ top: 8, left: 8, right:8, bottom: 8}}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="#0075B3" stopOpacity={0.5}/>
                <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} stroke="#DDD" />
            <XAxis 
              dataKey="name" 
              tickFormatter={value => ""} 
              width={0}
              height={0}
            />
            <YAxis 
              domain={[240, 'auto']} 
              // tickFormatter={value => ""}
              // tickMargin={0}
              // tick={false}
              // margin={null} 
              axisLine={false}
              // padding={{left: 0, right: 0, top: 0, bottom: 0}}
              width={0}
              height={0}
            /> 
            <Tooltip 
              content={<CustomTooltip />}
              // formatter={value => Duration.fromMillis(value*1000.00).toFormat('mm:ss')}
              // formatter={(value, name, props) => ["formatted value", "formatted name"] 
            />  
            {/* <Legend /> */}
            <Area 
              type="monotone" 
              dataKey="time" 
              stroke={false} 
              strokeWidth={2} 
              fillOpacity={1} 
              fill="url(#colorUv)" 
              isAnimationActive={false}
            />
            <Line 
              type="monotone" 
              dataKey="time" 
              strokeWidth={6} 
              stroke="#0075B3" 
              dot={false} 
              legendType="none"
              activeDot={{ r: 8 }} 
              strokeLinecap="round"
              isAnimationActive={false}
            />
            
          </ComposedChart>
          
        </ResponsiveContainer>
      </div>

    </>
  );

}
export default ProfileMileChart;