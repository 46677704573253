

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'

import { API_URL } from '../../../api.js';
import 'styles/Uppy.scss';
import 'styles/UppyDragDrop.scss';
import 'styles/UppyStatusBar.scss';
import { useApi } from '../../../api';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { DragDrop, StatusBar } from '@uppy/react'

import { importRoster, previewRoster, confirmRoster } from 'redux/club/clubActions';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom"



const OnboardingSuccessRoster = ({currentClub}) => {

  const navigate = useNavigate();

  const [rosterPreview, rosterPreviewLoading, rosterPreviewLoaded, rosterPreviewError, doRosterPreviewFetch, setRosterPreviewResult] = useApi();

  useEffect(() => {
    if (currentClub?.roster_url) {
      doRosterPreviewFetch(previewRoster)
    }
  }, [currentClub])


  const [confirmedRoster, confirmedRosterLoading, confirmedRosterLoaded, confirmedRosterError, doConfirmedRosterFetch, setConfirmedRosterResult] = useApi();

  useEffect(() => {
    if (confirmedRosterLoaded) {
      navigate('/onboarding/roster-success');
    }
  }, [confirmedRosterLoaded]);

  const onSubmitClicked = () => {
    doConfirmedRosterFetch(confirmRoster);
  }

  
  return(
    <>
      {/* <div className="code">
        <pre>
          {JSON.stringify(rosterPreview, null, 2)}
        </pre>
      </div> */}



      
      <div className="narrow-2 padding-top-1">
        <div className="circle-number">1</div>
        <h1 className="no-margin-bottom">Import Roster</h1>
        <h2 className="no-margin-top">Import in progress.</h2>

        <p>Your roster is now being imported.</p>

        {/* <p>View your roster page to see the import progress.</p> */}
        
        <Link className="margin-top-3 text-link flex-cont align-center" to="/">
          Continue with onboarding
          <i className="fas fa-chevron-right margin-left-1"></i>
        </Link>
      </div>

     
    </>
  );
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingSuccessRoster);