

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import coinSVG from 'images/coin.svg';
import Modal from 'components/Modal';

import { getAthletes } from 'redux/club/teamAdmin/athleteActions';


const TeamAdminAthletes = ({currentTeam, currentClub, currentAthlete}) => {

  let { id } = useParams();
  const [paramsId, setParamsId] = useState(id);
  const [athletes, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchAthletes();
  }, [id]);

  const fetchAthletes = () => {
    if (id) {
      doFetch(getAthletes, {query: {team_id: currentTeam.id, tab: id}});
    }
    else {
      doFetch(getAthletes, {tab: id});
    }
    
  }



  return (
    <>
      <div className="narrow-2x padding-top-1">

      <div className="flex-cont">
        <h1 className="flex-1">
          Students
        </h1>
      </div>
      
      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink to={`/students`}>All</NavLink></li>
        </ul>
      </div>

      <div className="margin-bottom-2"></div>

      {loading &&
        <div className="spinner"></div>
      }


      {athletes &&
        <table className="basic">
          <tr>
            <th>Name</th>
            <th className="nowrap">Hero Band</th>
            <th className="nowrap">Coins</th>
            <th></th>
          </tr>
          {athletes.map(athlete => {
            return (
              <tr>
                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/students/${athlete.slug}`}> 
                    {athlete.last_name}, {athlete.first_name}
                  </Link>
                </td>
                <td className="fit nowrap">
                  {athlete.band_code &&
                    <Link className="text-link" to={`/hero-bands/${athlete.band_code}`}> 
                      {athlete.band_code}
                    </Link>
                  }
                </td>

                <td>
                  <div className="gold bold align-center flex-cont">
                    <img className="margin-right-half" style={{height: '1em'}} src={coinSVG} alt="" />
                    {athlete.coins}
                  </div>
                </td>
                
                <td>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {!loading && (!athletes || athletes.length === 0) &&
        <em>No results.</em>
      }
    </div>  
    </>
  )
}

function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(TeamAdminAthletes);