

import React, { useState, useEffect } from 'react';
import { NavLink, Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';

import Ring from 'components/Ring';
import Bar from 'components/Bar';
import coinSVG from 'images/coin.svg';
import 'styles/Profile.scss';

import store from 'redux/store';
import { connect } from 'react-redux';

import EmailConfBanner from 'components/EmailConfBanner';
import ProfileNoAthletes from './ProfileNoAthletes';
import Notis from './Notis';
import SelectProfile from './SelectProfile';
import ProfileBottomNav from 'screens/profile/ProfileBottomNav';
import { unsetCurrentAthlete, unauthParent } from '../../redux/session/sessionActions';
import Test3d from 'screens/Test3d';
import PortraitBox from 'screens/PortraitBox';
import { getBadges } from '../../redux/athlete/badgeActions';
import { getPledgeStats, getMyNotis } from '../../redux/athlete/athleteActions';

import { getUnreadPopupNotis } from '../../redux/athlete/notiActions';
import { reupdateUser, reupdateAthlete } from "redux/session/sessionActions";

import { useApi } from '../../api';
import Modal from '../../components/Modal';
import ShareModal from './ShareModal';


const AwardModal = ({awardAthlete, toggleModal}) => {

  const fixedContent = () => {
    if (awardAthlete?.badge) {
      return <img className="small badge-modal-icon"  style={{height: '100px'}}  src={awardAthlete?.badge?.image_url} />
    }
    else {
      return <i className="small font-6 badge-modal-icon  fas fa-certificate"></i>
    }
  }

  return (
    <Modal overflow='visible' fixedContent={null} size='small' bgColor="blue" toggleModal={toggleModal} focusTrap={false}>

      <div className="padding-1">

        <div style={{transform: 'translateZ(0)'}}  className="relative flex-cont column align-center justify-center">

          

          <div  style={{top: '-3em'}} className='relative flex-cont column align-center justify-center center'>
            {fixedContent()}

            <div className="font-1-5 bold margin-top-1">
              {awardAthlete?.award?.name}
            </div>
          </div>
          

        
          {/* <pre>
            {JSON.stringify(awardAthlete, null, 2)}
          </pre> */}
        </div>
        
      </div>
    </Modal>
  )
}


const BadgeModal = ({badge, toggleModal}) => {

  return (
    <Modal overflow='visible' size='small' bgColor="blue" toggleModal={toggleModal} focusTrap={false}>
      <div className="padding-1">
        <div className="flex-cont column align-center justify-center">
          <div style={{top: '-3em'}} className='relative flex-cont column align-center justify-center center'>
            <img className="small badge-modal-icon"  style={{height: '100px'}}  src={badge.image_url} />

            <div className="font-1-5 bold margin-top-1">
              {badge.name}
            </div>

            <div className="margin-top-half flex-cont align-center">
              <i style={{marginTop: '2px'}} className="blue font-08 fas fa-times margin-right-4px"></i>
              
              <div className="bold blue font-1-25">{badge.count}</div>
            </div>

            <div className="margin-top-1">
              {badge.description}
            </div>
            
          </div>
        </div>
      </div>
    </Modal>
  )
}



const ProfileLayout = ({parentAuthed, currentUser, currentAthlete, children}) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [badges, badgesLoading, badgesLoaded, badgesError, doBadgesFetch, setBadges] = useApi(); 
  const [pledgeStats, pledgeStatsLoading, pledgeStatsLoaded, pledgeStatsError, doPledgeStatsFetch, setPledgeStats] = useApi(); 
  const [notis, notisLoading, notisLoaded, notisError, doNotisFetch, setNotis] = useApi(); 
  const [athlete, athleteLoading, athleteLoaded, athleteError, doAthleteFetch, setAthlete] = useApi(); 
  const showAthleteProfile = (currentUser && currentAthlete && currentUser.athletes.length !== 0);



  const [newCurrentUser, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    if (currentUser) {
      // alert('ghe')
      fetchCurrentUser();
    }  
  }, []);

  const fetchCurrentUser = () => {
    // alert('reupdateUser')
    doFetch(reupdateUser);
  };

  const fetchCurrentAthlete = () => {
    // alert('reupdateUser')
    doAthleteFetch(reupdateAthlete);
  };


  useEffect(() => {
    // alert('here2')
  }, [])

  useEffect(() => {
    if (loaded && showAthleteProfile) {
      // alert('222')
      fetchCurrentAthlete();
      fetchBadges();
      fetchPledgeStats();
      fetchNotis();
    }
  }, [showAthleteProfile, loaded]);

  const fetchNotis =  () => {
    doNotisFetch(getUnreadPopupNotis);
  }; 


  const fetchBadges = async () => {
    doBadgesFetch(getBadges);
  }; 

  const fetchPledgeStats = async () => {
    doPledgeStatsFetch(getPledgeStats);
  };

  const [selectedBadge, setSelectedBadge] = useState(null);

  useEffect(() => {
    // alert(JSON.stringify(selectedBadge, null, 2))
    if (selectedBadge) {
    }
  }, [selectedBadge]);

  const [selectedAward, setSelectedAward] = useState(null);

  useEffect(() => {
    if (selectedAward) {
    }
  }, [selectedAward]);

  

  useEffect(() => {
    
    if (!currentUser || !["admin", "customer"].includes(currentUser.role)) {
      navigate('/');
    }

    if (currentUser && currentUser.role === "customer" && location.pathname === '/profile') {
      navigate('/');
    }
  }, [currentAthlete]);

  const [shareModalOpened, setShareModalOpened] = useState(false);

  const toggleShareModal = () => {
    setShareModalOpened(o => !o);
  }


  if (!currentUser || !["admin", "customer"].includes(currentUser.role)) {
    return (
      <>
      </>
    )
  }


  if (currentUser && (!currentUser.athletes || currentUser.athletes.length === 0)) {
    return (
      <ProfileNoAthletes />
    )
  }


  if (!currentAthlete) {
    return (
      <SelectProfile />
    )
  }

  const testAlert = msg => {
    alert(msg, null, 2)
  }

  const hasActiveCampaign = pledgeStatsLoaded === true && pledgeStats != null;
  const campaignLoading = !pledgeStatsLoaded;
  const pledgeDriveSubCode = pledgeStats?.code;






  return (
    <>

    
      <Notis notisLoaded={notisLoaded} />
      
      {shareModalOpened &&
        <ShareModal code={pledgeDriveSubCode} toggleModal={toggleShareModal} />
      }
      {selectedBadge &&
        <BadgeModal toggleModal={() => setSelectedBadge(null)} badge={selectedBadge} />
      }
      {selectedAward &&
        <AwardModal toggleModal={() => setSelectedAward(null)} awardAthlete={selectedAward} />
      }
    <div className="parent" style={{paddingBottom: '400px'}}>

      {/* <EmailConfBanner currentUser={currentUser} /> */}


      {notisLoading &&
        <div style={{
          padding: '2em',
          background: 'rgba(255,255,255,.8)',
          borderRadius: '2em',
          color: 'white',
          position: 'fixed',
          top: '2em',
          right: '2em',
          zIndex: 9999999
        }}>
          <div className="spinner" style={{color: 'white'}}></div>
        </div>
      }


        
      

      {/* {currentUser && currentUser.role==="admin" &&
        <ProfileBottomNav />
      } */}
      <ProfileBottomNav />

      <div style={{background: 'rgb(203, 240, 255)', position: 'absolute', left: 0, top: '65px', width: '100%', height: '225px'}}>
      </div>

      



      <div style={{background: ''}} className="relative narrow-2 padding-top-4">
    
          <div className="narrow-2">


            <div className="full flex-cont flex-wrap">

              <div style={{background: ''}} className="profile-avatar-column center column">
                <div className="athlete-avatar-cont margin-bottom-1">
                  <i className="fas fa-user"></i>
                </div>

                  <div className="margin-bottom-1 blue font-1-25 bold">{currentAthlete.first_name} {currentAthlete.last_name}</div>
  
                  {/* <div onClick={() => store.dispatch(unsetCurrentAthlete())} className="button">Switch Profile</div> */}

                  <Link to="coins" className="coins-cont margin-bottom-0">
                    <div  className="flex-cont align-center justify-center">
                      <img style={{width: '26px'}} src={coinSVG} alt="" />
                      {/* <i className="lightgray font-075  margin-left-half margin-right-half fas fa-times"></i> */}
                      
                      <span className="blue bold font-2 margin-left-half">{currentAthlete.coins?.toLocaleString()}</span>

                      <i className="fas fa-chevron-right margin-left-1 blue"></i>
                    </div>
                  </Link>
{/* 
                  <pre>
                    {JSON.stringify(currentAthlete, null, 2)}
                  </pre> */}
                
   

                <div className="border-top padding-top-1 margin-top-1">
                  <div style={{background: ''}} className="mobile-full full badge-cont">

                    {badges?.map(badge => {
                      return(
                        <>
                        <div onClick={() => setSelectedBadge(badge)} className="badge2-cont">
                          <div className="badge2 sidebar">
                            <img src={badge.image_url} />
                          </div>
                        </div>

                        </>
                        
                      )
                    })}

                    {/* <div className="margin-bottom-0 margin-top-1 bold gray font-075">BADGES</div>  */}

                    {badges &&
                      <div className="margin-top-1">
                        <Link to="awards" className="font-075 ghost button">
                          ALL BADGES
                          <i className="font-05 fas fa-chevron-right margin-left-half"></i>
                        </Link>
                      </div>
                    }
                    
                    
                  </div>
                </div>

              </div>

  

      



            
          <div style={{background: ''}}  className="full profile-right-column relative">

          {/* <div className="blue center bold font-09 absolute">
            {pledgeStats?.pledge_drive_name}
          </div> */}
          
          <div className="full flex-wrap pledge-bar-cont flex-cont justify-center align-center flex-1 margin-bottom-2 margin-top-2">
                    

            
            {/* <div className="margin-right-0 blue bold">
              <div className="font-1-5x">
                {pledgeStats ? (pledgeStats.earned/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
              </div>
              <div className="font-08">EARNED</div>
            </div> */}
            <div className="flex-1 margin-right-1 margin-left-1">
              <Bar 
                // label={pledgeStats?.pledge_drive_name}
                // pledgeStats ? (parseFloat(pledgeStats.earned)/parseFloat(pledgeStats.pledged))*100 : null
                showBarCap={true}
                height={40} 
                progress={pledgeStats ? (parseFloat(pledgeStats.earned)/parseFloat(pledgeStats.pledged))*100 : null} 
                gem="dark-blue-bar" 
                rightLabel={`${pledgeStats ? (pledgeStats.earned/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}  / ${pledgeStats ? (pledgeStats.pledged/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}`}
              />
            </div>
            {/* <div className="margin-right-0 blue bold">

              <div className="font-1-5x">
                {pledgeStats ? (pledgeStats.pledged/100.00).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "-"}
              </div>
              <div className="font-08">PLEDGED</div>
            </div> */}
          </div>

          {/* <div>
            {(parseFloat(pledgeStats?.earned)/parseFloat(pledgeStats?.pledged))*100 }
          </div> */}
            

            <div className="profile-content flex-1 full">
              <div className="full">
                <div className="flex-cont profile-nav desktop-only">
                  <ul >
                      <li>
                        <NavLink to={`/`}>
                          <i className="fas fa-th-large"></i>
                          <span>Dashboard</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/awards`}>
                          <i className="fas fa-medal"></i>
                          <span>Awards</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/activity`}>
                          <i className="fas fa-stopwatch"></i>
                          <span>Activities</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/fundraising`}>
                          <i className="fas fa-heart"></i>
                          <span>Fundraising</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`/career`}>
                          <i className="fas fa-flag"></i>
                          <span>Career</span>
                        </NavLink>
                      </li>
                    </ul>
                </div>
              </div>

              {/* <div>{JSON.stringify(hasActiveCampaign, null, 2)}</div> */}

              {/* <div className='code'>
                <pre>
                  {JSON.stringify(currentAthlete, null, 2)}
                </pre>
              </div> */}
              <Outlet  context={{campaignLoading, hasActiveCampaign, toggleShareModal, testAlert, setSelectedAward, setSelectedBadge}} />
              {/* {React.cloneElement(children, {setSelectedBadge: setSelectedBadge})} */}
            </div>
          </div>
        </div>
      </div> 
    </div>
    </div>
    </>

  );
};

const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
    parentAuthed: state.sessionState.parentAuthed,
  };
} 

export default connect(mapState)(ProfileLayout);