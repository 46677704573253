

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getTracks } from 'redux/admin/trackActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createTrack, updateTrack, deleteTrack } from 'redux/admin/trackActions';

const TrackSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipcode: yup.string().required("Zipcode is required."),
  id: yup.string().nullable(),
  lap_distance: yup.number().required("Lap distance is required."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});


const TrackForm = ({toggleModal, currentTrack}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: TrackSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [tracksResult, tracksLoading, tracksLoaded, tracksError, doFetchTracks, setTrackResults] = useApi();

  const onSubmit = async data => {
    let d = {
      track: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentTrack && currentTrack.id) {
      doFetch(updateTrack, {payload: d});
    }
    else {
      doFetch(createTrack, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentTrack ? 'Edit' : 'New'} Track</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentTrack?.id}
        />
        

          <div className="flex-cont">
            <label htmlFor="name"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentTrack?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="street"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Street"
                name="street"
                defaultValue={currentTrack?.street}
              />
              {errors.street && <div className="field-error">{errors.street.message}</div>}
            </label>
          </div>
          <div className="flex-cont">
            <label htmlFor="city"> 
              <input 
                type="text" 
                ref={register}
                placeholder="City"
                name="city"
                defaultValue={currentTrack?.city}
              />
              {errors.city && <div className="field-error">{errors.city.message}</div>}
            </label>
            <label htmlFor="state"> 
              <input 
                type="text" 
                ref={register}
                placeholder="State"
                name="state"
                defaultValue={currentTrack?.state}
              />
              {errors.state && <div className="field-error">{errors.state.message}</div>}
            </label>
            <label htmlFor="zipcode"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Zipcode"
                name="zipcode"
                defaultValue={currentTrack?.zipcode}
              />
              {errors.zipcode && <div className="field-error">{errors.zipcode.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="lap_distance"> 
              <input 
                type="text" 
                ref={register}
                placeholder="Lap Distance (miles)"
                name="lap_distance"
                defaultValue={currentTrack?.lap_distance}
              />
              {errors.lap_distance && <div className="field-error">{errors.lap_distance.message}</div>}
            </label>
          </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminTracks = ({currentUser}) => {

  const [tracks, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedTrack, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedTrack] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingTrack, setEditingTrack] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchTracks();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingTrack(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!tracks) {
      fetchTracks();
    }
  }, []);

  const fetchTracks = () => {
    doFetch(getTracks);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingTrack, setDeletingTrack] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchTracks();
    }
  }


  const editClicked = track => {
    setEditingTrack(track);
    toggleModal();
  }

  const deleteClicked = track => {
    setDeletingTrack(track);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingTrack) {
      doDeleteFetch(deleteTrack, {query: {id: deletingTrack.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <TrackForm currentTrack={editingTrack} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Tracks</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Track</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {tracks &&
        <table className="basic">
          {tracks.map(track => {
            return (
              <tr>
                <td className="bold fit nowrap">{track.name}</td>
                <td className="fit nowrap">{track.lap_distance} mi/lap</td>
                <td>{track.full_address}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(track)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(track)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Track</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingTrack?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !tracks &&
        <em>No tracks.</em>
      }


      


    </>
  )
}


export default AdminTracks;