


import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { NavLink, Outlet, useNavigate, useParams, useLocation, useOutletContext } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { updatePledge, createPledge, getExistingPledge } from 'redux/user/pledgeActions';
import { loadStripe } from "@stripe/stripe-js";
import { 
  Elements,
  CardElement,
  useStripe,
  useElements 
} from "@stripe/react-stripe-js";

import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import SubmitButton from '../../components/SubmitButton';
import PledgeTable from './PledgeTable';

const testIfNumber = (value) => {
  return !isNaN(value);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const PledgeSchema = yup.object().shape({
  pledger_message: yup.string().nullable(),
  pledge_amount: yup
  .string().trim().nullable()
  .test(`dollars-no-spaces`, `Please remove all spaces.`, v => !v.includes(' '))
  .test(`dollars-is-number`, `Please enter a valid dollar amount.`, value => testIfNumber(value))
  .test(`dollars-is-positive`, `Dollar amount must be greater than 0.`, val =>  !val || val > 0)
  .matches(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, {excludeEmptyString: true, message: `Dollar amount must not have more than two decimal places.`})
  .transform( (value, originalvalue) => {
    if (!value.replace(/\s/g, '').length) {
      return "0.00"
    }
    return (
      value
    );
  }),
  pledge_max: yup
  .string().trim().nullable()
  .test(`dollars-no-spaces`, `Please remove all spaces.`, v => !v.includes(' '))
  .test(`dollars-is-number`, `Please enter a valid dollar amount.`, value => testIfNumber(value))
  .test(`dollars-is-positive`, `Dollar amount must be greater than 0.`, val =>  !val || val > 0)
  .test(`max-is-more`, `Max amount must be greater than or equal to pledge amount.`, 
    function(item) {
      return (!item || parseFloat(item) >= parseFloat(this.parent.pledge_amount));
  })
  .matches(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, {excludeEmptyString: true, message: `Dollar amount must not have more than two decimal places.`})
  .transform( (value, originalvalue) => {
    if (!value.replace(/\s/g, '').length) {
      return "0.00"
    }
    return (
      value
    );
  })

});

const PledgePaymentSetupForm = ({pledger, clientSecret, stripeAccountID}) => {

  const [pledgePage] = useOutletContext();


  let query = useQuery();
  let pledgeCode = query?.get("pledge_code");


  let { code } = useParams();
  const navigate = useNavigate("/");

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PledgeSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [existingPledge, existingPledgeLoading, existingPledgeLoaded, existingPledgeError, doExistingPledgeFetch, setExistingPledge] = useApi();

  useEffect(() => {
    checkExistingPledge();
  }, [pledgeCode]);

  const checkExistingPledge = () => {
    doExistingPledgeFetch(getExistingPledge, {query: {pledge_code: pledgeCode}})
  }

  const [succeeded, setSucceeded] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  const [stripeConfirmResult, setStripeConfirmResult] = useState(null);

  const onSubmit = async data => {

    if (!existingPledge) return;

    // alert('sdfsdfsdf');
    setProcessing(true);

    if (!stripe || !elements || stripeError || error || !clientSecret || !stripeAccountID || !pledger) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const stripeResult = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: pledger.name,
          email: pledger.email
        },
      },
    });

    setStripeConfirmResult(stripeResult);

    if (stripeResult.error) {
      setStripeError(`Payment failed ${stripeResult.error.message}`);
      setProcessing(false);
      return
      // Display result.error.message in your UI.
    } else {
      setStripeError(null);
      setProcessing(false);
      setSucceeded(true);
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
    }


    const payloadData = {
      pledge: {
        ...data,
        pledger_cents_per_mile: parseFloat(data.pledge_amount * 100),
        pledger_max_cents: parseFloat(data.pledge_max * 100),
        stripe_payment_method_id: stripeResult?.setupIntent?.payment_method
      },
      email: query.get('email')
      
    }
    doFetch(updatePledge, {query: {pledge_code: pledgeCode}, payload: payloadData});
  }


  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setStripeError(event.error ? event.error.message : "");
  };


  useEffect(() => {
    if (succeeded && loaded && result) {
      // alert(JSON.stringify(result, null, 2))
      navigate(`/pledge/${code}/thanks?pledge_code=${result.pledge_code}`);
    }
  }, [loaded, succeeded, result]);

  const watchMax = Number(watch("pledge_max"));
  const watchPer = Number(watch("pledge_amount"));

  const sponsored = () => {

    if (!watchMax || !watchPer || isNaN(watchMax) || isNaN(watchPer)) {
      return "-"
    }

    return (Math.round( parseFloat(watchMax / watchPer) * 10) / 10)?.toLocaleString()
  }

  const sponsoredNumber = () => {

    if (!watchMax || !watchPer || isNaN(watchMax) || isNaN(watchPer)) {
      return null
    }

    return parseFloat(watchMax / watchPer)
  }

  // useEffect(() => {
  //   if (existingPledgeLoaded && existingPledge?.code) {
  //     navigate(`/pledge/progress/${existingPledge.code}`);
  //   }

  // }, [existingPledgeLoaded, existingPledge])

  if (existingPledgeLoading) {
    return (
      <>
        <div className="center margin-top-3">
          <div className="spinner"></div>
        </div>
      </>
    ) 
  }

  if (!existingPledge) {
    return (
      <>
        <div>
          There was a problem with your request. Please try again.
        </div>
      </>
    )
  }


  return (
    <>
    

        <div className="new">

          

          <div className="parent" style={{
            backgroundColor: 'white',
            paddingBottom: '500px',
            paddingTop: '50px',
            position: 'relative',
          }}>

  

          <div className="narrow relative">



            <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
              <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="margin-bottom-2"></div>
                  <div className="line-height-1 bold font-2 margin-bottom-4">
                    Edit your pledge for <br /> {pledgePage.athlete_first_name} {pledgePage.athlete_last_name}
                  </div>

                  <div className="margin-bottom-2 rounded-box padding-2">
                    <div className="bold margin-bottom-1">Existing pledge:</div>

                    {existingPledgeLoading && <div className="spinner"></div> }
                    {existingPledge &&
                      <PledgeTable pledge={existingPledge} />
                    }

                    
                  </div>

                  <div className="flex-cont justify-center center margin-bottom-2">
                    <div className="border-right flex-1 center">

                      <div className="margin-bottom-1">I want to pledge</div> 
                      $<input defaultValue={parseFloat(existingPledge.pledger_cents_per_mile) / 100.00} ref={register} className="andy-input" type="text" name="pledge_amount" id=""/>
                      <div className="margin-top-1">for every mile {pledgePage.athlete_first_name} runs.</div> 

                      {errors.pledge_amount && <div className="field-error not-absolute">{errors.pledge_amount.message}</div>}

                    </div>

                    <div className="flex-1 center">
                      <div className="margin-bottom-1">Up to</div> 
                      $<input defaultValue={parseFloat(existingPledge.pledger_max_cents) / 100.00} ref={register} className="andy-input" type="text" name="pledge_max" id=""/>
                      <div className="margin-top-1">(maximum amount you will be charged)</div> 
                      {errors.pledge_max && <div className="field-error not-absolute">{errors.pledge_max.message}</div>}
                    </div>
                  </div>


                  <div className="cool-gradient box section padding-2" style={{

                    }}>
                      <div className="font-1-25 bold">This equals</div>

                      <div className="margin-top-1 margin-bottom-1 bold-200 font-3">
                        {sponsored()} 
                        
                        {sponsoredNumber() !== null &&
                          <>
                            {sponsoredNumber() == 1 ? " mile" : " miles"}
                          </>
                        }
                        
                      </div>

                      <div className="font-1-25 bold">of sponsored running.</div>
                    </div>

                  

                  <div className="flex-cont">
                    <label htmlFor="pledger_name">
                      <span>Message to {pledgePage.athlete_first_name}</span>
                      <input defaultValue={existingPledge.pledger_message} ref={register} type="text" placeholder="" name="pledger_message" />
                    </label>
                  </div>


                  {true &&
                    <>

                      <div className="flex-cont">
                        <label htmlFor="pledger_name">
                          <span>Payment Details</span>
                          <div className="editable-cont">
                            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                          </div>
                        </label>
                      </div>
                      
                      <div className="margin-top-2"></div>
                      {/* <button
                        disabled={processing || disabled || succeeded}
                        id="submit"
                      >
                        <span id="button-text">
                          {processing ? (
                            <div className="spinner" id="spinner"></div>
                          ) : (
                            "Pay"
                          )}
                        </span>
                      </button> */}
                      {/* Show any error that happens when processing the payment */}
                      {stripeError && (
                        <div className="card-error field-error" role="alert">
                          {stripeError}
                        </div>
                      )}
                    </>
                  }


                  <div className="margin-top-4 padding-1">
                    I authorise Hero Run to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with Maranatha Christian Schools.
                  </div>

                  <div className="font-1-5 justify-center align-center flex-cont">
                    {/* <input type="submit" value="Continue" className="button" id="submit" 
                    // disabled={processing || disabled || succeeded} 
                    /> */}
                    {/* <button
                      disabled={false}
                      id="submit"
                    >
                      <span id="button-text">
                        {(loading || processing) ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          "Submit"
                        )}
                      </span>
                    </button> */}

                    <SubmitButton 
                      loading={loading || processing} 
                      disabled={(errors && Object.keys(errors).length !== 0) || loading || processing || disabled || succeeded}
                      label={
                        <React.Fragment>
                          <span>Submit Pledge</span>
                        </React.Fragment>
                      } />
                  
                  </div>
                </form>
              </div>

              <PledgeSidebar pledgePage={pledgePage} isPreview={false} showButton={false} />
            </div>

          </div>

          

        </div>
        </div>

    </>
  )
}




const EditPledge = () => {

  let query = useQuery();
  let pledgeCode = query.get('pledge_code');


  const [pledger, setPledger] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [stripeAccountID, setStripeAccountID] = useState('');

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch(`${process.env.REACT_APP_BACKEND_URL}/pledges/create_setup_intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            // email: query.get('email'),
            pledge_code: pledgeCode
          
          }
        )
      })
      .then(res => {
        return res.json();
      })
      .then(data => {

        setPledger(data.pledger);
        setClientSecret(data.clientSecret);
        setStripeAccountID(data.stripeAccountID);
      });
  }, []);

  useEffect(() => {
    if (stripeAccountID && !stripePromise) {
      
      setStripePromise(
        loadStripe("pk_test_51H3POlG6MXyb29gzTQP735E2Jf7LcS6A4cwmxeYCnxSghwGPSut4AIgbyz5YOoLVW45jlcUoc7KbWw9RXOTDl4UJ00mfKkl5nE", {
          stripeAccount: stripeAccountID,
        })
      )
    }
  }, [stripeAccountID, stripePromise])

  return (

    <>
      {stripePromise && clientSecret && clientSecret &&
        <Elements stripe={stripePromise}>
          <PledgePaymentSetupForm pledger={pledger} clientSecret={clientSecret} stripeAccountID={stripeAccountID} />
        </Elements>
      }
      {(!stripePromise || !clientSecret || !clientSecret || !pledger) &&
        <div className="center margin-top-3">
          <div className="font-3 spinner"></div>
        </div>
        
      }

    </>
  )
}

export default EditPledge;