import React, { useState, useEffect } from 'react';
import { useApi } from '../../../api';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import ClubRaceNav from './ClubRaceNav';
import Modal from 'components/Modal';
import AutoComplete from 'components/AutoComplete';
import { getAthletes } from 'redux/club/athleteActions';
import { getRace, changeAthlete } from 'redux/club/raceActions';
import { deleteLap } from 'redux/club/lapActions';


const ClubRaceLaps = ({}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchRace()
    }
  }, [id]);

  const [race, raceLoading, raceLoaded, raceError, doFetchRace, setRaceResults] = useApi();

  const fetchRace = () => {
    doFetchRace(getRace, {query: {id: id}})
  }


  const [athleteModalOpened, setAthleteModalOpened] = useState(false);
  
  const changeAthleteClicked = () => {
    setAthleteModalOpened(true);
  }
  const toggleAthleteModal = (reload=false) => {

    setAthleteModalOpened(o => !o);
    if (reload) {
      fetchRace();
    }
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const confirmDeleteClicked = () => {
    doDeleteFetch(deleteLap, {query: {id: deletingLap.id}})
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteLapModal();
      fetchRace();
      // toggleModal(true);
    }
    
  }, [deleteLoaded]);



  const [deletingLap, setDeletingLap] = useState(null);

  const deleteLapClicked = lap => {
    setDeletingLap(lap);
  }

  const toggleDeleteLapModal = () => {
    setDeletingLap(null);
  }
 
  return (
    <>
    {deletingLap &&
      <Modal size="small" toggleModal={toggleDeleteLapModal} focusTrap={false}>
        <div className="padding-1">
          <div className="bold margin-bottom-1">{deletingLap.athlete_name} #{deletingLap.position} - {deletingLap.total_time}</div>
          
          Are you sure you want to delete this lap?
        </div>
        <div className="modal-footer align-center flex-cont">
          <div onClick={toggleDeleteLapModal} disabled={deleteLoading} className="button ghost">
            Cancel
          </div>
          <div className="flex-1"></div>
          <div disabled={deleteLoading} onClick={confirmDeleteClicked} className="button danger">
            <span>Delete</span>
            {deleteLoading && 
              <div className="spinner button-spinner"></div>
            }
          </div>
      </div>
      </Modal>
    }

    {raceLoading && <div className="absolute spinner"></div>}


      {race &&
        <>  
          {race.laps &&
            <table className="basic margin-top-4">
              <tr>
                <th></th>
                <th>Name</th>
                <th>EPC</th>
                <th>Time</th>
                <th>Miles</th>
                <th></th>
                <th>Start</th>
                <th>End</th>
                <th></th>
                {/* <th>Name</th>
                <th>Name</th>
                <th>Name</th> */}
              </tr>
              {race.laps.map((lap, index) => {
                return (
                  <tr>
                    <td className="fit nowrap">{index + 1}</td>
                    <td className="fit bold nowrap">{lap.athlete_name}</td>
                    <td className="fit nowrap">{lap.epc}</td>
                    <td className="fit nowrap">{lap.time}</td>
                    <td className="fit nowrap">{lap.miles} mi.</td>
                    <td></td>
                    <td className="fit nowrap">{lap.start_readtime}</td>
                    <td className="fit nowrap">{lap.end_readtime}</td>
                    <td className="fit nowrap">
                      <div onClick={() => deleteLapClicked(lap)} className="ghost button danger">
                        <i className="fas fa-trash"></i>
                      </div>
                      
                    </td>
                    {/* <td className="fit nowrap">{lap.start_readtime}</td>
                    <td className="fit nowrap">{lap.end_readtime}</td> */}
                  </tr>
                )
              })}
            </table>
          }

          {!raceLoading && (!race.laps || race.laps.length === 0) &&
            <em>No results.</em>
          }

          




        </>

      }
      
      {/* <div className="code">
        <pre>
          {JSON.stringify(race, null, 2)}
        </pre>
      </div> */}
    </>
  )

}

export default ClubRaceLaps;