import { andyFetch } from '../../api';

export const getCoinItemPurchases = (params) => {
  return async (dispatch, getState) => {

    const url = "club/coin_item_purchases";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCoinItemPurchases error", e);
    }
  }
};



export const createCoinItemPurchase = (params) => {

  return async (dispatch, getState) => {

    const url = "club/coin_item_purchases";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createCoinItemPurchase error.');
      }
    }
    catch (e) {
      console.log("createCoinItemPurchase error", e);
    }
  }
};


export const updateCoinItemPurchase = (params) => {

  return async (dispatch, getState) => {

    const url = `club/coin_item_purchases/${params.payload.coin_item_purchase.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateCoinItemPurchase error.');
      }
    }
    catch (e) {
      console.log("updateCoinItemPurchase error", e);
    }
  }
};

export const getCoinItemPurchase = (params) => {
  return async (dispatch, getState) => {
    const url = `club/coin_item_purchases/${params.query.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getCoinItemPurchase error.');
      }
    }
    catch (e) {
      console.log("getCoinItemPurchase error", e);
    }
  }
};

export const deleteCoinItemPurchase = (params) => {
  
  return async (dispatch, getState) => {

    const url = `club/coin_item_purchases/${params.query.id}`;

    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteCoinItemPurchase error.');
      }
    }
    catch (e) {
      console.log("deleteCoinItemPurchase error", e);
    }
  }
};

