

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { createAwardRuleLogin, updateAwardRuleLogin } from 'redux/admin/awardRuleLoginActions';
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';

import { useNavigate } from "react-router-dom"
import AdminAwardFields from './AdminAwardFields';

const AwardSchema = yup.object().shape({
  // id: yup.string(),
  // title: yup.string().required("Title is required."),
  // content: yup.string().required("Content is required."),

  // active: yup.string(),
});

export const AdminAwardsLoginForm = ({toggleModal, currentAwardRuleLogin}) => {
  const navigate = useNavigate();
  const { control, watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: AwardSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    // const v = getValues();
    // alert(JSON.stringify(data, null, 2))
    
    let d = {
      award_rule_login: {
        ...data
      }
    }
    if (currentAwardRuleLogin && currentAwardRuleLogin.id) {
      
      doFetch(updateAwardRuleLogin, {payload: d});
    }
    else {
      doFetch(createAwardRuleLogin, {payload: d});
    }
  }


  useEffect(() => {
    
    if (loaded) {
      navigate('/awards/login')
    }
  }, [loaded]);

  const currentAward = currentAwardRuleLogin?.award;


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="code">
        <pre>
          {JSON.stringify(currentAward, null, 2)}
        </pre>
      </div> */}
      <input type="hidden" name="id" ref={register} defaultValue={currentAwardRuleLogin?.id} />

      <AdminAwardFields setValue={setValue} currentAward={currentAward} register={register} errors={errors} />
      

      <h2>Rules</h2>
      <h3>Give award to student when they login:</h3>

      <div className="flex-cont">
        
        <label className="fit" htmlFor="coins"> 
          <input 
            type="number" 
            ref={register}
            name="login_count"
            defaultValue={currentAwardRuleLogin?.login_count}
            className="small"
          />
          {errors.login_count && <div className="field-error">{errors.login_count.message}</div>}

          <span> time(s) in a </span>
        </label>

        <label className="fit"  htmlFor="login_count_period"> 
          {currentAwardRuleLogin &&
            <select 
                ref={register}
                name="login_count_period"
                defaultValue={currentAwardRuleLogin?.login_count_period}
              >
                <option value="">Select period:</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
                <option value="campaign">Campaign</option>

                <option value="days in a row">Days in a row</option>

            </select>
          }
          {!currentAwardRuleLogin &&
            <select 
                ref={register}
                name="login_count_period"
                defaultValue={""}
              >
                <option value="">Select period:</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
                <option value="campaign">Campaign</option>

                <option value="days in a row">Days in a row</option>

            </select>
          }

          {errors.login_count_period && <div className="field-error">{errors.login_count_period.message}</div>}
        </label>
      </div>




      {/* <div className="code">
        <pre>
          {JSON.stringify(errors, null, 2)}
        </pre>
      </div> */}


      <div className="margin-top-3 align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        {loading &&
          <div className="spinner"></div>
        }
      </div>
      

    </form>
  )

}

export default AdminAwardsLoginForm;