import React, { useRef, useEffect, useState } from 'react';
import { connect} from 'react-redux';

const RaceTime = ({includeH, includeMS, color, fontSize, width, currentUser, raceStartedAt, raceStarted, racingModalOpened, style, progress, children}) => {


  const [current, setCurrent] = useState(0);
  const [time, setTime] = useState(["00", "00", "00", "00"]);

  const [intervalID, setIntervalID] = useState(null);

  const timerInterval = () => {
    return(
      setInterval(() => {
        const elapsed = Date.now() - raceStartedAt;
        setCurrent(elapsed);
        setTime(elpasedToText(elapsed));
      }, 44)
    );
  };

  useEffect(() => {

    if (raceStarted) {
      setIntervalID(timerInterval());
    }
    else {
      clearInterval(intervalID);
    }

    return () => {
      clearInterval(intervalID);
    };

  }, [raceStarted]);

  useEffect(() => {
    return () => {
      clearInterval(intervalID);
    };
  }, []);



  const elpasedToText = (elapsed) => {

    let h = Math.floor(elapsed / (60*60*1000)).toString().padStart(2, '0');
    let m = Math.floor(elapsed / (60*1000) % 60).toString().padStart(2, '0');
    let s = Math.floor(elapsed / (1000) % 60).toString().padStart(2, '0');
    let ms = Math.floor((elapsed % 1000) / 10).toString().padEnd(2, '0');
    return [h, m, s, ms];
  }

  return (
    <>

        <div className="font-3 bold flex-cont align-center">

          {includeH &&
            <>
              <div>
                {time[0]}
              </div>
              <div>:</div>
            </>
          }
          <div>
            {time[1]}
          </div>

          <div>:</div>

          <div>
            {time[2]}
          </div>
          {includeMS &&
            <>
              <div>:</div>
              <div>
                {time[3]}
              </div>
            </>
          }
        </div>
    </>
  );
}

function mapState(state) {
  return { 
    raceStarted: state.uiState.raceStarted,
    raceStartedAt: state.uiState.raceStartedAt,
    currentUser: state.sessionState.currentUser,
    racingModalOpened: state.uiState.racingModalOpened,
    intervalID: state.uiState.intervalID,
  };
} 

export default connect(mapState)(RaceTime);

