import { andyFetch } from '../../api';

export const getPages = (params) => {
  return async (dispatch, getState) => {

    const url = "admin/pages";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPages error", e);
    }
  }
};

export const getPage = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/pages/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getPage error", e);
    }
  }
};


export const createPage = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/pages";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createPage error.');
      }
    }
    catch (e) {
      console.log("createPage error", e);
    }
  }
};


export const updatePage = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/pages/${params?.payload?.page?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updatePage error.');
      }
    }
    catch (e) {
      console.log("updatePage error", e);
    }
  }
};


export const deletePage = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/pages/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deletePage error.');
      }
    }
    catch (e) {
      console.log("deletePage error", e);
    }
  }
};

