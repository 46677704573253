import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';
import { getSubscriptions, createSubscription, updateSubscription, deleteSubscription } from 'redux/admin/subscriptionActions';


const SubscriptionSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
});


const SubscriptionForm = ({toggleModal, currentSubscription}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: SubscriptionSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      subscription: {
        ...data
      }
    }
    if (currentSubscription && currentSubscription.id) {
      doFetch(updateSubscription, {payload: d});
    }
    else {
      doFetch(createSubscription, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentSubscription ? 'Edit' : 'New'} Subscription</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentSubscription?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentSubscription?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>





      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminSubscriptions = ({currentUser}) => {

  const [subscriptions, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedSubscription, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedSubscription] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingSubscription, setEditingSubscription] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchSubscriptions();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingSubscription(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!subscriptions) {
      fetchSubscriptions();
    }
  }, []);

  const fetchSubscriptions = () => {
    doFetch(getSubscriptions);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingSubscription, setDeletingSubscription] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchSubscriptions();
    }
  }


  const editClicked = subscription => {
    setEditingSubscription(subscription);
    toggleModal();
  }

  const deleteClicked = subscription => {
    setDeletingSubscription(subscription);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingSubscription) {
      doDeleteFetch(deleteSubscription, {query: {id: deletingSubscription.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <SubscriptionForm currentSubscription={editingSubscription} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Subscriptions</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Subscription</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {subscriptions &&
        <table className="basic">
          {subscriptions.map(subscription => {
            return (
              <tr>
                <td className="fit nowrap">
                  <div style={{fontSize: '3rem', backgroundImage: `url( ${subscription?.avatar_url} )`}} className="avatar-cont smal">

                  </div>
                </td>
                <td className="bold fit nowrap">{subscription.name}</td>

      

                <td></td>
                
                <td className="fit nowrap">
                  <div onClick={() => editClicked(subscription)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(subscription)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Subscription</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingSubscription?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!subscriptions || subscriptions.length === 0) &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminSubscriptions;