

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getBands } from 'redux/club/bandActions';
import Modal from 'components/Modal';

import { Link } from 'react-router-dom';
import BandForm from './BandForm';
import { createBand, updateBand, deleteBand } from 'redux/club/bandActions';

const ClubBands = ({currentUser}) => {

  const [bands, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedBand, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedBand] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingBand, setEditingBand] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchBands();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingBand(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!bands) {
      fetchBands();
    }
  }, []);

  const fetchBands = () => {
    doFetch(getBands);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingBand, setDeletingBand] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchBands();
    }
  }


  const editClicked = band => {
    setEditingBand(band);
    toggleModal();
  }

  const deleteClicked = band => {
    setDeletingBand(band);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingBand) {
      doDeleteFetch(deleteBand, {query: {id: deletingBand.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  const statusColor = status => {
    if (status === "ACTIVE") {
      return "black"
    }
    else if (status === "INACTIVE") {
      return "red"
    }
    else if (status === "LOST") {
      return "lightgray"
    }

    else if (status === "INACTIVE") {
      return "red"
    }


  }

  return (
    <>
      <div className="narrow-2x padding-top-1">
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <BandForm currentBand={editingBand} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Hero Bands</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Band</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {bands &&
        <table className="basic">
          {bands.map(band => {
            return (
              <tr>

                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/hero-bands/${band.code}`}>
                    {band.code}
                  </Link>

                </td>
                <td className="nowrap fit">
                  {band.last_first}
                </td>
                <td className="nowrap fit">
                  {band.epc}
                </td>
                <td className={`nowrap fit bold ${statusColor(band.status?.toUpperCase())}-text`}>
                  {band.status?.toUpperCase()}
                </td>
                <td></td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Band</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingBand?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!bands || bands.length === 0) &&
        <em>No results.</em>
      }


      

      </div>
    </>
  )
}


export default ClubBands;