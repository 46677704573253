import React from "react";
import { connect } from "react-redux";
import { Link} from 'react-router-dom';


const MaterialFeeBox = ({currentUser}) => {

  if (currentUser?.material_fee_is_paid === true) {
    return null;
  }


  return (
    <div className="white-text text-left card no-hover margin-bottom-2 sharing-card">

        <div className="font-2 bold white-text margin-bottom-0">
          Please pay your material fee.
        </div>

        <Link to={'/material-fee'} className="shadow button white margin-top-3 font-1-25">
          Pay Now
        </Link>

        {/* <div className="font-2">
          {parseFloat(currentUser.material_fee).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
        </div> */}

        

      </div>
  )

}

const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(MaterialFeeBox);