
// import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

const currentAthlete = localStorage.getItem('currentAthlete');
const currentUser = localStorage.getItem('currentUser');
const authToken = localStorage.getItem('authToken');
const parentAuthed = localStorage.getItem('parentAuthed');


console.log('currentUser', currentUser);
console.log('authToken', authToken);


const initialState = {
  currentUser: currentUser ? JSON.parse(currentUser) : null,
  currentAthlete: currentAthlete ? JSON.parse(currentAthlete) : null,
  authToken: authToken ? authToken : null,
  parentAuthed: parentAuthed ? JSON.parse(parentAuthed) : null,
};

const sessionReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "AUTH_PARENT":
      return {
        ...state,
        parentAuthed: true,
      }
    case "UNAUTH_PARENT":
      return {
        ...state,
        parentAuthed: false,
      }
    case "SET_CURRENT_ATHLETE":
      localStorage.setItem('currentAthlete', action.currentAthlete ? JSON.stringify(action.currentAthlete)  : state.currentAthlete);
      return {
        ...state,
        currentAthlete: action.currentAthlete,
      }
    case "UNSET_CURRENT_ATHLETE":
      localStorage.removeItem('currentAthlete');
      return {
        ...state,
        currentAthlete: null,
      }
    case "SET_CURRENT_USER":

      localStorage.setItem('currentUser', action.currentUser ? JSON.stringify(action.currentUser)  : state.currentUser);
      localStorage.setItem('authToken',   action.authToken ? action.authToken  : state.authToken);
      return {
        ...state,
        currentUser: action.currentUser,
        authToken: action.authToken ? action.authToken : state.authToken,
      }
    case "UNSET_CURRENT_USER":
      localStorage.removeItem('currentAthlete');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('authToken');
      return {
        ...state,
        currentAthlete: null,
        currentUser: null,
        authToken: null,
      }
    default :
      return state
  }
}

export default sessionReducer;