

import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const AdminMerchRequestNav = ({id}) => {


  return (
    <div className="sub-nav margin-bottom-2">
      <ul>
        <li><NavLink end to={`/merch-requests/${id}`}>Dashboard</NavLink></li>
        <li><NavLink end to={`/merch-requests/${id}/order-requests`}>Order Requests</NavLink></li>
      </ul>
    </div>
  )
}

export default AdminMerchRequestNav;