
import React, { useState, useEffect } from 'react';
import logo7 from 'images/logo7.png';
import { Link, Outlet, useLocation, NavLink } from 'react-router-dom';
import store from 'redux/store';
import { connect } from 'react-redux';
import BottomNav from 'screens/BottomNav';

const ClubBottomNav = ({currentUser, currentClub, raceStarted}) => {

  const [prefix, setPrefix] = useState('/');

  useEffect(() => {
    if (currentUser.role === "admin") {
      setPrefix('/club-dashboard/');
    }
  }, [currentUser]);




  return (
    <BottomNav  type="club">
      <ul>

        <li className="margin-bottom-1">
          <NavLink to={`${prefix}account`}>
            <i className="fas fa-cog"></i>
            <span>Account</span>
          </NavLink>
        </li>
        

        <li className="margin-bottom-0">
          <NavLink end to={prefix}>
            <i className="fas fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        {currentClub?.onboarding_step === "done" &&
          <li >
            <NavLink end to={`${prefix}calendar`}>
              <i className="fas fa-calendar"></i>
              <span>Calendar</span>
            </NavLink>
          </li>
        }

      {currentClub?.onboarding_step === "done" &&
        <li >
          <NavLink to={`${prefix}school-year`}>
            <i className="fas fa-money-bill"></i>
            <span>Material Fee</span>
          </NavLink>
        </li>
      }

      {currentClub?.onboarding_faculty_done &&
        <li >
          <NavLink to={`${prefix}staff`}>
            <i className="fas fa-id-badge"></i>
            <span>Staff</span>
          </NavLink>
        </li>
      }
       
      {currentClub?.onboarding_roster_done &&
        <li >
          <NavLink to={`${prefix}teams`}>
            <i className="fas fa-users"></i>
            <span>Teams</span>
          </NavLink>
        </li>
      }
      {currentClub?.onboarding_roster_done &&     
        <li >
          <NavLink to={`${prefix}students`}>
            <i className="fas fa-user"></i>
            <span>Students</span>
          </NavLink>
        </li>
      }
      {currentClub?.onboarding_step === "done" &&
        <li >
          <NavLink to={`${prefix}hero-bands`}>
            <i className="fas fa-ring"></i>
            <span>Hero Bands</span>
          </NavLink>
        </li>
      }

        

      {currentClub?.onboarding_tracks_done &&     
        <li >
          <NavLink to={`${prefix}tracks`}>
            <i className="fas fa-circle-notch"></i>
            <span>Tracks</span>
          </NavLink>
        </li>
      } 

        {/* <li >
          <NavLink to={`${prefix}laps`}>
            
            <i className="fas fa-circle-notch"></i>
            <span>Laps</span>

            {currentClub?.reader_is_on &&
              <div className="pulser red margin-right-half"></div>
            }
          </NavLink>
        </li> */}

        

        

       

        {/* <li>
          <NavLink to={`${prefix}causes`}>
            <i className="fas fa-heart"></i>
            <span>Causes</span>
          </NavLink>
        </li> */}
      {currentClub?.onboarding_campaign_done &&     
        <li>
          <NavLink to={`${prefix}campaigns`}>
            <i className="fas fa-hand-holding-usd"></i>
            <span>Campaigns</span>
          </NavLink>
        </li>
      }

        {/* <li>
          <NavLink to={`${prefix}pledges`}>
            <i className="fas fa-hand-holding-usd"></i>
            <span>Pledges</span>
          </NavLink>
        </li> */}
      {currentClub?.onboarding_step === "done" &&  
        <> 
          <li>
            <NavLink to={`${prefix}inspiration`}>
              <i className="fas fa-lightbulb"></i>
              <span>Inspiration</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={`${prefix}notifications`}>
              <i className="fas fa-bell"></i>
              <span>Notifications</span>
            </NavLink>
          </li> */}

          <li >
            <NavLink to={`${prefix}merch-products`}>
              <i className="fas fa-tshirt"></i>
              <span>Merch</span>
            </NavLink>
          </li>


          <li >
            <NavLink to={`${prefix}coin-items`}>
              <i className="fas fa-coins"></i>
              <span>Coin Store</span>
            </NavLink>
          </li>

          <li >
            <NavLink to={`${prefix}activities`}>
              <i className="fas fa-running"></i>
              <span>Activities</span>
            </NavLink>
          </li>

          {/* <li >
            <NavLink to={`${prefix}live-run`}>              
                <i>
                  <div className={`pulser ${raceStarted ? 'blue' : 'standby'}`}></div>
                </i> 
                <span>
                  Live
                </span>

              
            </NavLink>
          </li> */}
        </>
        }

      </ul>
    </BottomNav>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
    authToken: state.sessionState.authToken,
    globalErrors: state.uiState.globalErrors,
    unreadNotiListables: state.notiState.unreadNotiListables,
    currentClub: state.clubState.currentClub,
    raceStarted: state.uiState.raceStarted,
  };
} 

export default connect(mapState)(ClubBottomNav);