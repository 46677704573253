import { andyFetch } from '../../api';

export const getMyClub = (params) => {
  return async (dispatch, getState) => {

    const url = `club/clubs/my_club`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();

        // alert(JSON.stringify(json.data));

        dispatch({ type: 'SET_CURRENT_CLUB', club: json.data});


        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMyClub error", e);
    }
  }
};

export const updateMyClub = (params) => {
  return async (dispatch, getState) => {

    const url = `club/clubs/update_my_club`;

    try {
      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();

        dispatch({ type: 'SET_CURRENT_CLUB', club: json.data});


        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMyClub error", e);
    }
  }
};


export const createStripeAccount = (params) => {
  return async (dispatch, getState) => {

    const url = `club/stripe/create_stripe_account`;

    try {
      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();

        dispatch({ type: 'SET_CURRENT_CLUB', club: json.data});

        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("createStripeAccount error", e);
    }
  }
};

export const createAccountLink = (params) => {
  return async (dispatch, getState) => {

    const url = `club/stripe/create_account_link`;

    try {
      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();

        // alert(JSON.stringify(json.data));

        // dispatch({ type: 'SET_CURRENT_CLUB', club: json.data});
        // alert(JSON.stringify(json.data));

        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getMyClub error", e);
    }
  }
};

export const getAccount = (params) => {
  return async (dispatch, getState) => {

    const url = `club/stripe/get_account`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();

        // alert(JSON.stringify(json.data));

        // dispatch({ type: 'SET_CURRENT_CLUB', club: json.data});


        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAccount error", e);
    }
  }
};


export const importRoster = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/import_roster`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("importRoster error", e);
    }
  }
};
export const importRosterFile = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/import_roster_file`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("importRoster error", e);
    }
  }
};

export const previewRosterFile = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/preview_roster_file`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("previewRosterFile error", e);
    }
  }
};

export const previewRoster = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/preview_roster`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("importRoster error", e);
    }
  }
};
export const confirmRosterFile = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/confirm_roster_file`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("confirmRoster error", e);
    }
  }
};

export const confirmRoster = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/confirm_roster`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("confirmRoster error", e);
    }
  }
};


export const createClubUser = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/create_user`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("createClubUser error", e);
    }
  }
};


export const getClubOwnerships = (params) => {
  return async (dispatch, getState) => {
    const url = `club/clubs/club_ownerships`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getClubOwnerships error", e);
    }
  }
};