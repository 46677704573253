

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar } from '@uppy/react'
import { API_URL } from '../../api.js';
import Modal from 'components/Modal';

import { getProducts, createProduct, updateProduct, deleteProduct } from 'redux/admin/productActions';

const PricesSchema = yup.object().shape({
  id: yup.string(),
  price_decimal: yup.number("Enter a number.").required("Price amount is required."),
});

const PriceFields = ({prices, register, setValue, reset, triggerValidation, errors}) => {

  const [indexes, setIndexes] = useState(prices ? prices.map((p, index) => index) : [0]);
  const [removedIndexes, setRemovedIndexes] = useState([]);
  const [counter, setCounter] = useState(prices ? prices.length : 0);
  
  const addPrice = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removePrice = (index) => {
    const fieldName = `prices_attributes[${index}].`;
    // setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setValue(`${fieldName}[_destroy]`, true);
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
  };


  const [loggedInUser, loading, loaded, error, doFetch, setResult] = useApi();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    setDisabled(errors && Object.keys(errors).length !== 0);
  }, [errors]);


  const hasErrors = () => {
    return errors && !(Object.keys(errors).length === 0 && errors.constructor === Object)
  }

  const birthdayChanged = () => {
    triggerValidation();
  }

  return(

    <>
      {indexes.map((index, i) => {
        const fieldName = `prices_attributes[${index}].`;
        const q = (prices && prices[index]) ? prices[index] : {}
        const hide = removedIndexes.includes(index);

        return (
          
          <fieldset className={hide ? 'hidden' : ''} name={fieldName} key={fieldName}>
            {/* <div>{JSON.stringify(errors)}</div> */}
            <div className="card no-hover">
              <input 
                name={`${fieldName}[id]`} 
                type="hidden" 
                ref={register()}
                defaultValue={q.id}
              />
              <input 
                name={`${fieldName}[_destroy]`} 
                type="hidden" 
                ref={register()}
              />

              <div className="flex-cont flex-wrap">
                <label className="flex-cont align-center" htmlFor="price_decimal"> 
                  $
                  <input 
                    type="text" 
                    ref={register()}
                    placeholder="Price Amount"
                    name={`${fieldName}[price_decimal]`} 
                    defaultValue={q.price_decimal}
                  />
                  {<div className="field-error">{errors?.prices_attributes?.[i]?.price_decimal?.message}</div>}
                </label>
              </div>

              <div className="flex-cont">
                <div className="flex-1"></div>
                {i !== 0 &&
                  <div className="text-link" onClick={() => removePrice(index)}>REMOVE</div>
                }
              </div>
            
            </div>
          </fieldset>
        );
      })}
      <div className="center margin-top-2">
        <div className="ghost button" onClick={addPrice}>
          +
          ADD PRICE
        </div>
      </div>
    </>
  );
}


const ProductSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required("Name is required."),
  prices_attributes: yup.array().of(PricesSchema)
});


const ProductForm = ({toggleModal, currentProduct}) => {

  const { triggerValidation, reset, watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: ProductSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    let d = {
      product: {
        ...data
      }
    }
    if (currentProduct && currentProduct.id) {
      doFetch(updateProduct, {payload: d});
    }
    else {
      doFetch(createProduct, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentProduct ? 'Edit' : 'New'} Product</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">
        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentProduct?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentProduct?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        <PriceFields prices={currentProduct ? currentProduct.prices : null} register={register} setValue={setValue} reset={reset} triggerValidation={triggerValidation} errors={errors}  />





      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminProducts = ({currentUser}) => {

  const [products, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedProduct, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedProduct] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchProducts();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingProduct(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!products) {
      fetchProducts();
    }
  }, []);

  const fetchProducts = () => {
    doFetch(getProducts);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingProduct, setDeletingProduct] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchProducts();
    }
  }


  const editClicked = product => {
    setEditingProduct(product);
    toggleModal();
  }

  const deleteClicked = product => {
    setDeletingProduct(product);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingProduct) {
      doDeleteFetch(deleteProduct, {query: {id: deletingProduct.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <ProductForm currentProduct={editingProduct} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Products</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Product</div>
        </div>
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      {products &&
        <table className="basic">
          {products.map(product => {
            return (
              <tr>
                <td className="bold fit nowrap">{product.name}</td>
                <td></td>            
                <td className="fit nowrap">
                  <div onClick={() => editClicked(product)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(product)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete Product</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingProduct?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && (!products || products.length === 0) &&
        <em>No results.</em>
      }


      


    </>
  )
}


export default AdminProducts;