import React from 'react';
import Modal from '../../components/Modal';
import { connect } from 'react-redux';
import { FRONTEND_URL } from '../../api';


const ShareModal = ({toggleModal, code}) => {

  return (
    <Modal size='medium' bgColor="blue" toggleModal={toggleModal} focusTrap={false}>
      <div className="modal-header">
        <h1>
          Share
        </h1>
        <div className="margin-bottom-2x margin-top-1 font-1-25 blue">
          Send the following link to your friends and family so they can make a pledge. 
        </div>
      </div>
      <div className="padding-1 ">

        
        <div className="url flex-cont align-center margin-bottom-3">
          <div className="bluex boldx padding-left-1 flex-1 padding-right-1">
            {FRONTEND_URL}/pledge/{code}
          </div>
          

          <a target="_blank" href={`${FRONTEND_URL}/pledge/${code}`} className="button">
            Preview
          </a>
        </div>


        {/* <div className="margin-top-1">
          <i className="fas fa-facebook"></i>
          <i className="fas fa-instagram"></i>
          <i className="fas fa-circle-envelope"></i>
        </div>
        <pre>
          {JSON.stringify(currentAthlete, null, 2)}
        </pre> */}
      </div>
    </Modal>
  )
}

const mapState = (state) => {
  return { 
    currentUser: state.sessionState.currentUser,
    currentAthlete: state.sessionState.currentAthlete,
    parentAuthed: state.sessionState.parentAuthed,
  };
} 

export default connect(mapState)(ShareModal);