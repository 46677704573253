

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"
import coinSVG from 'images/coin.svg';
import Modal from 'components/Modal';

import { getAthletes, createAthlete, updateAthlete, deleteAthlete, getAthlete, acceptMembership, rejectMembership } from 'redux/club/athleteActions';
import Pagination from 'components/Pagination';

import AthleteForm from './AthleteForm';

import { useDebounce } from "use-debounce";




const ClubAthletes = ({currentTeam, currentClub, currentAthlete}) => {

  let { id } = useParams();

  const [page, setPage] = useState(1);
  const [paramsId, setParamsId] = useState(id);
  const [athletes, loading, loaded, error, doFetch, setResult, pagy] = useApi(); 

  const [filtered, setFiltered] = useState(null);
  const [search, setSearch] = useState("");

  const [debouncedText] = useDebounce(search, 500);


  useEffect(() => {
    const controller = new AbortController();
    fetchAthletes(controller);
    
    return () => {
      controller.abort();
    };
    // fetchAthletes(controller);
  }, [id, page, debouncedText]);



  const fetchAthletes = (controller) => {
    if (id) {
      doFetch(getAthletes, {controller: controller, query: {search: search, team_id: currentTeam.id, tab: id, page: page}});
    }
    else {
      doFetch(getAthletes, {controller: controller, query: {search: search, tab: id, page: page}});
      // controller.abort();
    }
    
  }



  const changeTab = (tab) => {
    // setSearchParams({ q: tab });
  }

  const [acceptResult, acceptLoading, acceptLoaded, acceptError, doAcceptFetch, setAcceptResult] = useApi(); 
  const [rejectResult, rejectLoading, rejectLoaded, rejectError, doRejectFetch, setRejectResult] = useApi(); 

  const acceptClicked = id => {
    doAcceptFetch(acceptMembership, {payload: {id: id}})
  }

  useEffect(() => {
    if (acceptLoaded) {
      fetchAthletes();
    }
  }, [acceptLoaded]);

  const rejectClicked = id => {
    doRejectFetch(rejectMembership, {payload: {id: id}})
  }

  useEffect(() => {
    if (rejectLoaded) {
      fetchAthletes();
    }
  }, [rejectLoaded]);

  const [newModalOpen, setNewModalOpen] = useState(false);

  const toggleNewModal = (reload = false) => {
    setNewModalOpen(o => !o);

    if (reload) {
      fetchAthletes();
    }
  }

  const handlePageChange = (p) => {
    setPage(p);
  };

  const searchChanged = query => {
    setSearch(query);
  }

  return (
    <>
      <div className="narrow-2x padding-top-1">

          
      {newModalOpen &&
        <Modal toggleModal={toggleNewModal}>
          <AthleteForm toggleModal={toggleNewModal} />
        </Modal>
      }
      <div className="flex-cont">
        <h1 className="flex-1">
          Students
        </h1>

        {((currentClub && true) && (currentClub.current_user_role === "club-admin" || currentClub.current_user_role === "pe-teacher")) &&
          <div>
            <Link to={`import`} className="button">Import Students</Link>
          </div>
        }
      </div>
      
      <div className="sub-nav margin-bottom-2">
        <ul>
          <li><NavLink to={`/students`}>All</NavLink></li>
          {/* <li><NavLink to={`/students/pending`}>Pending</NavLink></li>
          <li><NavLink to={`/students/active`}>Active</NavLink></li>
          <li><NavLink to={`/students/inactive`}>Inactive</NavLink></li>
          <li><NavLink to={`/students/rejected`}>Rejected</NavLink></li> */}
        </ul>
      </div>

      <div className="margin-bottom-2"></div>


      {true &&
        <input value={search} className="margin-bottom-1" onChange={e => searchChanged(e.target.value)} type="text" name="" id="" placeholder="Search" />
      }

      <div>
        <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
      </div>

      {loading &&
        <div className="spinner"></div>
      }

      


      {athletes &&
        <table className="basic">
          <tr>
            <th>Name</th>
            <th>Team</th>
            <th className="nowrap">Hero Band</th>
            <th className="nowrap">Coins</th>
            <th></th>
          </tr>
          {athletes.map(athlete => {
            return (
              <tr>
                <td className="bold fit nowrap">
                  <Link className="text-link" to={`/students/${athlete.slug}`}> 
                    {athlete.last_name}, {athlete.first_name}
                  </Link>
                </td>
                <td className="fit nowrap">{athlete.team_name}</td>
                <td className="fit nowrap">
                  {athlete.band_code &&
                    <Link className="text-link" to={`/hero-bands/${athlete.band_code}`}> 
                      {athlete.band_code}
                    </Link>
                  }
                </td>

                <td>
                  <div className="gold bold align-center flex-cont">
                    <img className="margin-right-half" style={{height: '1em'}} src={coinSVG} alt="" />
                    {athlete.coins}
                  </div>
                </td>
                
                <td>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {!loading && (!athletes || athletes.length === 0) &&
        <em>No results.</em>
      }
    </div>  
    </>
  )
}

function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(ClubAthletes);