

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getPledgeDrives } from 'redux/admin/pledgeDriveActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createPledgeDrive, updatePledgeDrive, deletePledgeDrive } from 'redux/admin/pledgeDriveActions';
import { getCharities } from 'redux/admin/charityActions';

const PledgeDriveschema = yup.object().shape({
  name: yup.string().required("Name is required."),
  active: yup.boolean(),
  charity_id: yup.string().required("Must select a charity."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});


const PledgeDriveForm = ({toggleModal, currentPledgeDrive}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PledgeDriveschema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [charities, charitiesLoading, charitiesLoaded, charitiesError, doFetchCharities, setCharityResults] = useApi();



  const onSubmit = async data => {
    let d = {
      pledge_drive: {
        ...data
      }
    }

    if (currentPledgeDrive && currentPledgeDrive.id) {
      doFetch(updatePledgeDrive, {payload: d});
    }
    else {
      doFetch(createPledgeDrive, {payload: d});
    }
  }

  useEffect(() => {
    doFetchCharities(getCharities);
  }, []);

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [selectedCharity, setSelectedCharity] = useState(null);

  const charitySelectChanged = (e) => {
    const id = e.target.value;
    const t = charities.find(t => t.id.toString() === id.toString());
    setSelectedCharity(t);
  }


  useEffect(() => {
    setValue('charity_id', currentPledgeDrive?.charity_id);
  }, [charities]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentPledgeDrive ? 'Edit' : 'New'} Pledge Drive</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentPledgeDrive?.id}
        />
        

        <div className="flex-cont">
          <label htmlFor="name"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Name"
              name="name"
              defaultValue={currentPledgeDrive?.name}
            />
            {errors.name && <div className="field-error">{errors.name.message}</div>}
          </label>
        </div>

        <div className="flex-cont align-end">
          <label htmlFor="charity_id">
            <span>Charity</span>
            {charitiesLoading &&
              <div className="spinner"></div>
            }
            {charities &&
              <select defaultValue={currentPledgeDrive?.charity_id} onChange={charitySelectChanged} ref={register} name="charity_id">
                <option value="" disabled selected>Select your option</option>
                {charities.map(charity => <option value={charity.id}>{charity.name}</option>)}
              </select>
            }
            {errors.charity_id && <div className="field-error">{errors.charity_id.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="active">
            
            <input defaultChecked={currentPledgeDrive?.active} type="checkbox" name="active" ref={register} id=""/>
            <span className="margin-left-1">Active</span>

            {errors.active && <div className="field-error">{errors.active.message}</div>}
          </label>
        </div>


      </div>

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminPledgeDrives = ({currentUser}) => {

  const [pledgeDrives, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedPledgeDrive, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledgeDrive] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPledgeDrive, setEditingPledgeDrive] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPledgeDrives();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPledgeDrive(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!pledgeDrives) {
      fetchPledgeDrives();
    }
  }, []);

  const fetchPledgeDrives = () => {
    doFetch(getPledgeDrives);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPledgeDrive, setDeletingPledgeDrive] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPledgeDrives();
    }
  }


  const editClicked = pledgeDrive => {
    setEditingPledgeDrive(pledgeDrive);
    toggleModal();
  }

  const deleteClicked = pledgeDrive => {
    setDeletingPledgeDrive(pledgeDrive);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPledgeDrive) {
      doDeleteFetch(deletePledgeDrive, {query: {id: deletingPledgeDrive.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <PledgeDriveForm currentPledgeDrive={editingPledgeDrive} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Pledge Drives</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Pledge Drive</div>
        </div>
      </div>
    

      {loading &&
        <div className="spinner"></div>
      }

      {pledgeDrives &&
        <table className="basic">
          {pledgeDrives.map(pledgeDrive => {
            return (
              <tr>
                <td className="bold fit nowrap">{pledgeDrive.name}</td>
                <td className="fit nowrap">{pledgeDrive.charity_name}</td>
                <td>{pledgeDrive.active ? "ACTIVE" : "NOT ACTIVE"}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(pledgeDrive)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(pledgeDrive)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete PledgeDrive</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingPledgeDrive?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !pledgeDrives &&
        <em>No pledge drives.</em>
      }


      


    </>
  )
}


export default AdminPledgeDrives;