import { andyFetch } from 'api';

export const getAthletes = (params) => {

  return async (dispatch, getState) => {

    const url = "club/team_admin/athletes";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getAthletes error", e);
    }
  }
}; 