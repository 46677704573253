import { andyFetch } from '../../api';

export const getUsers = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/users";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getUsers error", e);
    }
  }
};

export const getUser = (params) => {
  return async (dispatch, getState) => {

    const url = `admin/users/${params.query.id}`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getUser error", e);
    }
  }
};


export const createUser = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/users";

    try {

      const response = await andyFetch(url, params, "POST");

      console.log("Response 2", response)


      if (response.ok) {
        console.log("R 1a");
        const json = await response.json();
        console.log("R 1b", json);
        return json.data;

      } else {
        console.log("R 2");
        throw new Error('createUser error.');
      }
    }
    catch (e) {
      console.log("R 3");
      // return {error: e.toString()}
      console.log("createUser error", e);
    }
  }
};


export const updateUser = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/users/${params.payload.user.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateUser error.');
      }
    }
    catch (e) {
      console.log("updateUser error", e);
    }
  }
};


export const deleteUser = (params) => {
  

  return async (dispatch, getState) => {

    const url = `admin/users/${params.query.id}`;


    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteUser error.');
      }
    }
    catch (e) {
      console.log("deleteUser error", e);
    }
  }
};

