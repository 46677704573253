import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';

import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { DragDrop, StatusBar } from '@uppy/react'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'

import Modal from '../../../components/Modal';
import { API_URL } from '../../../api.js';
import { createPledgeDriveSponsor, updatePledgeDriveSponsor, deletePledgeDriveSponsor } from 'redux/club/pledgeDriveSponsorActions';


const uppy = Uppy({
  meta: { type: 'image' },
  restrictions: { 
    // maxNumberOfFiles: 2,
    allowedFileTypes: ['.png', '.jpg', '.jpeg']
  },
  autoProceed: true
})

 
uppy.use(AwsS3Multipart, { 
  companionUrl: API_URL
})


const SponsorSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
});

const SponsorForm = ({pledgeDriveID, currentSponsor, toggleModal}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: SponsorSchema
  });

 

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [sponsorsResult, sponsorsLoading, sponsorsLoaded, sponsorsError, doFetchSponsors, setSponsorResults] = useApi();

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);


  useEffect(() => {

    uppy.on('upload', (data) => {
      setUploadLoading(true);
    });

    uppy.on('complete', (result) => {
      setUploadLoading(false);
    });
    
    uppy.on('upload-success', function (file, response) {
      
      var uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type,
        }
      })

      setFileURL(response.uploadURL);

      console.log('file', file);
      console.log('response', response);
      console.log(uploadedFileData);

      setFile(uploadedFileData);
      
    
      // ...
    })
  }, []);


  const getAvatarURL = () => {
    return fileURL ? fileURL : currentSponsor?.image_url
  }



  const onSubmit = async data => {
    let d = {
      pledge_drive_sponsor: {
        ...data
      }
    }

    // alert(JSON.stringify(d));
    if (currentSponsor && currentSponsor.id) {
      // alert(JSON.stringify(d));
      doFetch(updatePledgeDriveSponsor, {payload: d});
    }
    else {
      doFetch(createPledgeDriveSponsor, {payload: d});
    }
  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [deletedPledgeDrive, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledgeDrive] = useApi(); 
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deleteSponsorClicked = () => {
    setDeleteModalOpened(true);
  }

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpened(o => !o);
  }

  const confirmDestroy = () => {
    if (currentSponsor) {
      doDeleteFetch(deletePledgeDriveSponsor, {query: {id: currentSponsor.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleModal(true);
      // navigate('/campaigns')
    }
  }, [deleteLoaded]);

  useEffect(() => {
    return () => {
      setDeleteModalOpened(false);
    }
  }, []);

  
  return (
    <Modal toggleModal={toggleModal}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">{currentSponsor ? 'Edit' : 'New'} Sponsor</h2>
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">
          <input 
            type="hidden" 
            name="id" 
            ref={register}
            defaultValue={currentSponsor?.id}
          />

          <input 
            type="hidden" 
            name="pledge_drive_id" 
            ref={register}
            defaultValue={pledgeDriveID ? pledgeDriveID : currentSponsor?.pledge_drive_id}
          />  

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>Name</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Name"
                name="name"
                defaultValue={currentSponsor?.name}
              />
              {errors.name && <div className="field-error">{errors.name.message}</div>}
            </label>

            <label htmlFor="tagline"> 
              <span>Tagline</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Tagline"
                name="tagline"
                defaultValue={currentSponsor?.tagline}
              />
              {errors.tagline && <div className="field-error">{errors.tagline.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="name"> 
              <span>Description</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Description"
                name="content"
                defaultValue={currentSponsor?.content}
              />
              {errors.content && <div className="field-error">{errors.content.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="link_url"> 
              <span>Link URL</span>
              <input 
                type="text" 
                ref={register}
                placeholder="Description"
                name="link_url"
                defaultValue={currentSponsor?.link_url}
              />
              {errors.link_url && <div className="field-error">{errors.link_url.message}</div>}
            </label>
          </div>

          <div className="flex-cont">
            <label htmlFor="link_url"> 
              <span>Title Sponsor?</span>
              <input 
                type="checkbox" 
                ref={register}
                name="is_title"
                defaultChecked={currentSponsor?.is_title}
                className="margin-left-1"
              />
              {errors.is_title && <div className="field-error">{errors.is_title.message}</div>}
            </label>

            <label htmlFor="link_url"> 
              <span>Sponsor Amount</span>

              <div className="flex-cont align-center">
                <div>$</div>
                <input 
                  type="number" 
                  min={0}
                  step={0.01}
                  ref={register}
                  name="amount"
                  defaultValue={currentSponsor?.amount}
                />  
              </div>
             
              {errors.is_title && <div className="field-error">{errors.is_title.message}</div>}
            </label>
          </div>


          <div className="margin-top-1 card no-hover uppy-dd-cont">
            <div className="margin-bottom-2">
              <div className="button ghost">{getAvatarURL() ? "Edit" : "Add"} Sponsor Logo</div>
              <br />
              <img style={{height: '200px'}} src={getAvatarURL()} alt="" />
              {/* {uploadLoading ? (
                  <div className="flex-cont align-center justify-center" style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.6)', position: 'absolute'}}>
                    <div>
                      <div className="spinner"></div>
                    </div>
                    
                  </div>
                ) : (
                  <div style={{fontSize: '1rem'}} className="hover-overlay">
                    EDIT
                  </div>
                )
              } */}
            </div>
            

            

            <input ref={register} type="hidden" name="image" value={file} />
            <DragDrop
              className="andy"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: 'Drop here or %{browse}',
                  browse: 'browse'
                }
              }}
            />
        </div>
        <StatusBar uppy={uppy} />
        <div className="field-error">
          {errors?.image?.message}
        </div>

       


          

        </div>

        <div className="modal-footer align-center flex-cont">
          <input type="submit" value="Save" className="button" />
          <div className="flex-1"></div>
          {currentSponsor &&
            <div onClick={deleteSponsorClicked} className="ghost danger button">Remove</div>
          }
        </div>

      </form>
      

      {deleteModalOpened &&
        <Modal focusTrap={false} size="small" toggleModal={deleteModalOpened}>
          <div className="padding-1">
            Are you sure you want to remove this sponsor? This cannot be undone.
          </div>
          <div className="modal-footer flex-cont align-center">
            <div onClick={toggleDeleteModal} className="ghost button">Cancel</div>
            <div className="flex-1"></div>
            <div onClick={confirmDestroy} className="button danger">Yes, delete</div>
          </div>
        </Modal>
      }
    </Modal>
  )
}

export default SponsorForm;