import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup";
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { useApi } from '../api';
import { setupAthletes } from 'redux/user/userActions';
import { connect } from 'react-redux';

import { CheckoutForm } from './Checkout';


const UpgradeSetup = ({currentUser, includeHeader=true}) => {





  return(
    <div className="narrow-2">
      <h1 className="margin-bottom-1 margin-top-2 center">Upgrade</h1>

      <CheckoutForm />

    </div>
    
  )
};

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(UpgradeSetup);
