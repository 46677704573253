import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; 
import { useApi } from '../api';
import { getClub, applyToClub } from 'redux/clubs/clubActions';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';
import * as yup from "yup";



const ApplySchema = yup.object().shape({
  athlete_ids: yup.array().compact().of(
    yup.string()
  )
});




const ApplyForm = ({currentClub, currentUser, toggleModal}) => {

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: ApplySchema
  });
  const [updatedClub, loading, loaded, error, doFetch, setResult] = useApi();


  const [ids, setIds] = useState([]);

  const athleteClicked = (id) => {
    if (ids.includes(id.toString())) {
      setIds(oldIds => oldIds.filter(i => i !== id.toString()));
    }
    else {
      setIds(oldIds => [...oldIds, id.toString()]);
    }
    
  }


  const onSubmit = async data => {
    const d = {
      ...data,
      club_id: currentClub?.id
    };

    doFetch(applyToClub, {payload: d});
  }

  useEffect(() => {
    

    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="modal-header flex-cont align-center">

          {currentUser?.athletes_setup &&
            <h2 className="flex-1">Choose Athletes:</h2>
          }
          {!currentUser?.athletes_setup &&
            <h2 className="flex-1">Add an athlete profile to continue.</h2>
          }
          <div onClick={toggleModal} className="ghost button close">
            <i className="fas fa-times"></i>
          </div>
        </div>

        <div className="padding-1">
          {!currentUser?.athletes_setup &&
            <Link to="/" className="button">Setup Athletes</Link>
          }
          {currentUser && currentUser.athletes && currentUser.athletes.map((athlete, index) => 
            <div onClick={() => athleteClicked(athlete.id)} className={`${ids.includes(athlete.id.toString()) ? 'selected' : ''} no-grow card margin-bottom-2 pointer flex-cont align-center`}>
              <div className="flex-1">{athlete.first_name} {athlete.last_name}</div>

              <div className="font-2">
                {ids.includes(athlete.id.toString()) &&
                  <i className="fas fa-check"></i>
                }
              </div>
              {ids.includes(athlete.id.toString()) &&
                <input value={athlete.id.toString()} ref={register} type="hidden" name={`athlete_ids[${index}]`} />
              }
              
            </div>  
          )
          }

          
        </div>

        <div className="modal-footer align-center flex-cont">
          {currentUser?.athletes_setup &&
            <input type="submit" value="Apply" className="button" />
          }
        </div>
      </div>
    </form>
  )
}


const ClubPage = ({currentUser}) => {
  let { slug } = useParams();

  const [club, loading, loaded, error, doFetch, setClub] = useApi();

  const [startAni, setStartAni] = useState(false);

  useEffect(() => {
    fetchClub();

    setTimeout(() => {
      setStartAni(true);
    }, 300);


  }, []);

  const fetchClub = () => {
    doFetch(getClub, {query: {slug: slug}});
  }
  


  const [applyModalOpen, setApplyModalOpen] = useState(false);




  const toggleApplyModal = (reload = false) => {
    setApplyModalOpen(o => !o);

    if (reload === true) {
      fetchClub();
    }
  }

  const applicationButton = () => {
    if (club.application_status === "pending") {
      return <div className="disabled button font-1-5">Membership Pending</div>
    }
    else {
      return <div onClick={toggleApplyModal} className="button font-1-5">Apply for Membership</div>
    }
  }

  return (
    <>
      {applyModalOpen &&
        <Modal toggleModal={toggleApplyModal}>
          <ApplyForm toggleModal={toggleApplyModal} currentUser={currentUser} currentClub={club} />
        </Modal>
      }
      <div className={`${startAni ? 'start' : ''} banner-slant`}>

        <div style={{backgroundImage: `url(https://www.newhartfordschools.org/cms/lib/NY01913833/Centricity/ModuleInstance/55/large/Bradley_9_19.jpg?rnd=0.0243749385813134)`}} className="banner-cont bw"></div>
      </div>

      


      <div className="flex-cont column center narrow justify-center align-center">

        

        {loading &&
          <div className="center">
            <div className="spinner"></div>
          </div>
          
        }

        <div className="margin-top-4"></div>
        <div className="margin-top-4"></div>
        <div className="margin-top-4"></div>
        <div className="margin-top-2"></div>
        <div style={{position: 'relative', top: 0}} className="center">
          <div style={{backgroundImage: `url("${club?.avatar_url}")`}} className="avatar-cont">
            
            {loaded && club && !club.avatar_url &&
              <i className="fas fa-school"></i>
            }
          </div>

          

          

          {club &&
            <>
              <h1>{club.name}</h1>

              <div className="margin-top-1">
                <div>{club.street}</div>
                <div>{club.city}, {club.state} {club.zipcode}</div>
              </div>
            </>
          }
        </div>

        <div className="margin-top-3">

          {club &&
            <>
              {applicationButton()}
            </>
          }

          <div className="margin-top-3">
            {club && club.my_athletes && club.my_athletes.map(athlete => 
              <div className="no-hover inline-block card margin-right-1">
                <div className="avatar-cont margin-bottom-1"><i className="fas fa-user"></i></div>
                <div className="bold margin-bottom-1">{athlete.name}</div>
                <div>{athlete.status?.toUpperCase()}</div>
              </div>
            )}
          </div>
        </div>

      </div>

      
    </>
  )
}

function mapState(state) {
  return { 
    currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapState)(ClubPage);
