import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({pages}) => {

  const activeFooterPages = () => {
    return pages?.filter(page => page.active && page.on_footer)
  }


  return (
    <footer className="padding-1">

      {activeFooterPages()?.map(page => {
        return (
          <div>
            <Link className="ghost button" to={`/${page.slug}`}>{page.title}</Link>
          </div>
        )
      })}

      
      <div>
        <Link className="ghost button" to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div>
        <Link className="ghost button" to="/terms-of-service">Terms of Service</Link>
      </div>

      

      {/* <div>
        <pre>
          {JSON.stringify(pages, null, 2)}
        </pre>
      </div> */}

      
      {/* <pre style={{width: '90vw', wordWrap: "break-all"}}>
        {JSON.stringify(currentUser, null, 2)}
        
      </pre>

      <div>
        {process.env.NODE_ENV}
      </div> */}
    </footer>
  )
}

export default Footer;