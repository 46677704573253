

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getPledges } from 'redux/club/pledgeActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import { connect } from 'react-redux';
import { createStripeAccount, createAccountLink, getAccount } from 'redux/club/clubActions';
import store from 'redux/store';
import { limits } from 'chroma-js';



const OnboardingStripe = ({currentClub}) => {

  const [club, loading, loaded, error, doFetch, setResult] = useApi(); 

  const tryCreateStripeAccount = () => {
    doFetch(createStripeAccount);
  };

  const [accountLink, accountLinkLoading, accountLinkLoaded, accountLinkError, doAccountLinkFetch, setAccountLinkResult] = useApi(); 

  const tryCreateAccountLink = () => {
    doAccountLinkFetch(createAccountLink);
  };

  const [account, accountLoading, accountLoaded, accountError, doAccountFetch, setAccountResult] = useApi(); 

  useEffect(() => {

    if (!currentClub) {
      return
    }

    if (currentClub.stripe_account_id) {
      doAccountFetch(getAccount);
      doAccountLinkFetch(createAccountLink, {payload: {redirect_path: "onboarding/stripe"}});
    }
    else {
      doFetch(createStripeAccount);
    }
  }, [currentClub]);

  const [somethingLoading, setSomethingLoading] = useState(false);

  useEffect(() => {
    setSomethingLoading(loading || accountLoading || accountLinkLoading)
  }, [loading, accountLoading, accountLinkLoading])


  const [status, setStatus] = useState(null);


  useEffect(() => {
    if (!currentClub?.stripe_account_id) {
      return
    }

    if (!account) {
      setStatus("no account");
    }
    else {
      if (!account.email) {
        setStatus("new");
      }
      else if (account.charges_enabled) {
        setStatus("enabled");
      }
      else if (account.details_submitted && account.requirements?.currently_due?.length !== 0) {
        setStatus("need more info");
      }
      else if (account.details_submitted && account.requirements?.currently_due?.length === 0) {
        setStatus("submitted");
      }
      else {
        setStatus("started");
      }
      
    }


  }, [account, currentClub])






  return (
    <>
      <div className="padding-top-1">
      <div className="circle-number">5</div>
      <h1 className="no-margin-bottom">Fundraising</h1>
      <h2 className="no-margin-top">Connect to Stripe to start accepting pledges.</h2>

      {/* <div className="button" onClick={tryCreateStripeAccount}>
        Create Account
      </div>
      {loading &&
        <div className="spinner"></div>
      }
      <div className="margin-bottom-2">
        {JSON.stringify(currentClub?.stripe_account_id)}
      </div>

      <div className="button" onClick={tryCreateAccountLink}>
        Create Link
      </div>
      {accountLinkLoading &&
        <div className="spinner"></div>
      }
      <div className="margin-bottom-2">
        {JSON.stringify(accountLink)}
      </div>

      <div className="margin-bottom-2">
        <Link to="/pledge/_S7yB-385WuEnMqWj0kLsg" className="button">
          Test Pledge Page
        </Link>
      </div> */}

      

      {somethingLoading &&
        <div className="spinner"></div>
      }
      {!somethingLoading &&
        <>
          {status === "no account" &&
            <></>
          }
          {status === "new" &&
            <>
              {accountLink &&
                <a className="button" href={accountLink}>Start Stripe Setup</a>
              }
            </>
          }
          {status === "need more info" &&
            <>
              <div className="margin-bottom-2">
                Stripe needs more information they can approve your application.

                {account?.requirements?.errors &&
                  <ul>
                    {account?.requirements?.errors?.map(error => <li>{error.reason}</li>)}
                  </ul>
                
                }
              </div>
              {accountLink &&
                <a className="button" href={accountLink}>Manage Stripe Application</a>
              }
            </>
          }
          {status === "submitted" &&
            <>
              <div className="margin-bottom-2">Your details have been submitted to Stripe. Your application is currently being reviewed. Please check back later to view the status of your application.</div>
              {accountLink &&
                <a className="button" href={accountLink}>View Stripe Application</a>
              }
            </>
          }
          {status === "started" &&
            <>
              {accountLink &&
                <a className="button" href={accountLink}>Resume Stripe Setup</a>
              }
            </>
          }
          {status === "enabled" &&
            <>
              <div className="flex-cont align-center">
                <i className="green font-1-5 fas fa-check margin-right-1"></i>
                <div>Your account is ready to accept donations.</div>
              </div>


              <a target="_blank" href="https://dashboard.stripe.com/login" className="button margin-top-3 margin-right-half">
                Login to Stripe
                <i className="fas fa-external-link-alt margin-left-1 font-09"></i>
              </a> to manage your school's finances.
              
            </>
          }
          
          
        </>
      }





{/*       
      <div className="margin-top-2 margin-bottom-2">
        <pre>
          {JSON.stringify(account, null, 2)}
        </pre>
      </div> */}


      

      

      </div>
    </>
  )
}



function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
  };
} 

export default connect(mapState)(OnboardingStripe);