

import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { getUsers } from 'redux/club/userActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';

import { createUser, updateUser, deleteUser, getUser } from 'redux/club/userActions';
import { getClubOwnerships} from 'redux/club/clubOwnershipActions';
import ClubStaffNav from './ClubStaffNav';
import Pagination from 'components/Pagination';

import { useDebounce } from "use-debounce";

const ClubStaff = ({currentClub}) => {

  let { tab } = useParams();

  const [page, setPage] = useState(1);
  const [users, loading, loaded, error, doFetch, setResult, pagy] = useApi(); 
  const [filtered, setFiltered] = useState(null);
  const [search, setSearch] = useState("");

  const [debouncedText] = useDebounce(search, 500);

  const handlePageChange = (p) => {
    setPage(p);
  };


  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller);
    
    return () => {
      controller.abort();
    };
  }, [tab, page, debouncedText]);

  useEffect(() => {
    setFiltered(users);
  }, [users])

  const fetchUsers = (controller) => {
    doFetch(getClubOwnerships, {controller: controller, query: {search: search, tab: tab, page: page}});
  }


  const changeTab = (tab) => {
    // setSearchParams({ q: tab });
  }



  const searchChanged = query => {
    setSearch(query);
  }


  return (
    <>
      <div>


        {true &&
          <input value={search} className="margin-bottom-1" onChange={e => searchChanged(e.target.value)} type="text" name="" id="" placeholder="Search" />
        }

        {loading &&
          <div className="spinner"></div>
        }


        <div>
          <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        </div>
        

        {users &&
          <table className="basic">
            {users.map(user => {
              return (
                <tr>
                  <td className="fit nowrap">
                    <div className={`role-cont ${user.role}`}>
                      {user.friendly_role}
                    </div>
                    
                  </td>
                  <td className="bold fit nowrap">
                    <Link className="text-link" to={`/staff/view/${user.slug}`}>
                      {user.first_name} {user.last_name}
                    </Link>  
                  </td>
                  <td className="fit nowrap">{user.email}</td>
                  <td className="bold uppercase font-075">
                    {user.friendly_user_status}
                  </td>
                  <td className="fit nowrap">
                  </td>
                </tr>
              )
            })}
          </table>
        }

        {!loading && !filtered &&
          <em>No results.</em>
        }
      </div>
    </>
  )
}

export default ClubStaff;