

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { createAwardRuleLap, updateAwardRuleLap } from 'redux/admin/awardRuleLapActions';
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';

import { useNavigate } from "react-router-dom"
import AdminAwardFields from './AdminAwardFields';

const AwardSchema = yup.object().shape({
  // id: yup.string(),
  // title: yup.string().required("Title is required."),
  // content: yup.string().required("Content is required."),

  // active: yup.string(),
});

export const AdminAwardsLapForm = ({toggleModal, currentAwardRuleLap}) => {
  const navigate = useNavigate();
  const { reset, control, watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: AwardSchema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const [selectedKind, setSelectedKind] = useState('lap position');

  const onSubmit = async data => {
    // const v = getValues();
    // alert(JSON.stringify(data, null, 2))
    
    let d = {
      award_rule_lap: {
        ...data
      }
    }
    if (currentAwardRuleLap && currentAwardRuleLap.id) {
      
      doFetch(updateAwardRuleLap, {payload: d});
    }
    else {
      doFetch(createAwardRuleLap, {payload: d});
    }
  }

  useEffect(() => {
    if (currentAwardRuleLap) {
      setValue('lap_position_comparator', currentAwardRuleLap.lap_position_comparator)
      setSelectedKind(currentAwardRuleLap.kind)
    }
  }, [currentAwardRuleLap])


  useEffect(() => {
    
    if (loaded) {
      navigate('/awards/lap')
    }
  }, [loaded]);

  const currentAward = currentAwardRuleLap?.award;


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="code">
        <pre>
          {JSON.stringify(currentAward, null, 2)}
        </pre>
      </div> */}
      <input type="hidden" name="id" ref={register} defaultValue={currentAwardRuleLap?.id} />

      <AdminAwardFields setValue={setValue} currentAward={currentAward} register={register} errors={errors} />
      

      <h2>Rules</h2>
      <h3>Select type:</h3>

      <div className="flex-cont margin-bottom-3">
        {/* <div onClick={() => alert(JSON.stringify(getValues(), null, 2))} className="button">Get</div> */}
        <input value={selectedKind} type="hidden" name="kind" ref={register} />
        <div onClick={() => setSelectedKind('lap position')}  className={`bold blue center card pointer flex-1 margin-right-1 ${selectedKind === "lap position" ? 'selected' :''}`}>
          Lap Position within Run
        </div>
        <div onClick={() => setSelectedKind('overall lap count')} className={`bold blue center card pointer flex-1 margin-right-1 ${selectedKind === "overall lap count" ? 'selected' :''}`}>
          Overall Lap Count
        </div>
        <div onClick={() => setSelectedKind('personal achievement')}  className={`bold blue center card pointer flex-1 margin-right-1 ${selectedKind === "personal achievement" ? 'selected' :''}`}>
          Personal Achievement
        </div>
        
      </div>

      <h3>Earn award when you:</h3>

      {/* <div className="code">
      <pre>
        {JSON.stringify(currentAwardRuleLap, null, 2)}
      </pre>
      </div> */}

      
      

      <div onClick={() => alert(JSON.stringify(getValues(), null, 2))} className="button">

      </div>
      

      {selectedKind === "lap position" &&
        <div className="flex-cont">
          <label className="fit"  htmlFor="lap_position_comparator"> 
            <span>Complete lap </span>
            {true &&
              <select 
                  ref={register}
                  className="auto"
                  name="lap_position_comparator"
                  defaultValue={currentAwardRuleLap?.lap_position_comparator}
                >
                  <option value="equal to">EQUAL TO</option>
                  <option value="less than">LESS THAN</option>
                  <option value="greater than">GREATER THAN</option>

              </select>
            }
            {errors.lap_count_period && <div className="field-error">{errors.lap_count_period.message}</div>}
          </label>
          
          <label className="fit" htmlFor="lap_position"> 
            <span> position # </span>
            <input 
              type="number" 
              ref={register}
              name="lap_position"
              defaultValue={currentAwardRuleLap?.lap_position}
              className="small"
            />
            {errors.lap_position && <div className="field-error">{errors.lap_position.message}</div>}

            <span> in a run.</span>
          </label>

          
        </div>
      }
      {selectedKind === "overall lap count" &&
        <div className="flex-cont">     
          <label className="fit" htmlFor="overall_lap_count"> 
            <span>Complete lap # </span>
            <input 
              type="number" 
              ref={register}
              name="overall_lap_count"
              defaultValue={currentAwardRuleLap?.overall_lap_count}
              className="small"
            />
            {errors.overall_lap_count && <div className="field-error">{errors.overall_lap_count.message}</div>}
          </label>

          <label className="fit"  htmlFor="overall_lap_period"> 
            <span> within the current </span>
            {currentAwardRuleLap &&
              <select 
                ref={register}
                name="overall_lap_period"
                className="auto"
                defaultValue={currentAwardRuleLap?.overall_lap_period}
              >
                <option value="">Select period:</option>
                <option value="lap day">Day</option>
                <option value="lap week">Week</option>
                <option value="lap month">Month</option>
                <option value="lap year">Year</option>
                <option value="lap campaign">Campaign</option>
              </select>
            }
            {!currentAwardRuleLap &&
              <select 
                ref={register}
                name="overall_lap_period"
                defaultValue={""}
              >
                <option value="">Select period:</option>
                <option value="lap day">Day</option>
                <option value="lap week">Week</option>
                <option value="lap month">Month</option>
                <option value="lap year">Year</option>
                <option value="lap campaign">Campaign</option>
              </select>
            }

            {errors.overall_lap_period && <div className="field-error">{errors.overall_lap_period.message}</div>}
          </label>

          
        </div>
      }
      {selectedKind === "personal achievement" &&
        <div className="flex-cont">
          <label className="fit flex-cont align-center"  htmlFor="personal_record_period"> 
            <span>Run your fastest paced lap within the current </span>
            {currentAwardRuleLap &&
              <select 
                ref={register}
                name="personal_record_period"
                className="auto"
                defaultValue={currentAwardRuleLap?.personal_record_period}
              >
                <option value="">Select period:</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
                <option value="campaign">Campaign</option>
              </select>
            }
            {!currentAwardRuleLap &&
              <select 
                ref={register}
                name="personal_record_period"
                defaultValue={""}
              >
                <option value="">Select period:</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
                <option value="campaign">Campaign</option>
              </select>
            }

            {errors.personal_record_period && <div className="field-error">{errors.personal_record_period.message}</div>}
          </label>

          
        </div>
      }




      {/* <div className="code">
        <pre>
          {JSON.stringify(errors, null, 2)}
        </pre>
      </div> */}


      <div className="margin-top-3 align-center flex-cont">
        <input type="submit" value="Save" className="button" />
        {loading &&
          <div className="spinner"></div>
        }
      </div>
      

    </form>
  )

}

export default AdminAwardsLapForm;