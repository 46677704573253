

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrentPledgeDrive } from '../../../redux/club/pledgeDriveActions';
import { getMyTeam } from '../../../redux/club/teamActions';
import { useApi } from '../../../api';
import ClubWeek from '../ClubWeek';
import { Link } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import coinPNG from 'images/herocoin.png';
import coinSVG from 'images/coin.svg';


const TeamAdminHome = ({currentTeam, currentClub, currentUser, children}) => {

  const [currentCampaign, loading, loaded, error, doFetch, setResult] = useApi();
  const [team, currentTeamLoading, currentTeamLoaded, currentTeamError, doCurrentTeamFetch, setCurrentTeamResult] = useApi();

  useEffect(() => {
    doFetch(getCurrentPledgeDrive);
    doCurrentTeamFetch(getMyTeam);
  }, []);

  



  // const [currentTeam, currentTeamLoading, currentTeamLoaded, currentTeamError, doCurrentTeamFetch, setCurrentTeamResult] = useApi();

  // useEffect(() => {
  //   doFetch(getMyTeam);
  // }, []);

  return (

    <>
      <div className="uni-banner"></div>

        <div className="club-home-cont">
          <div className="flex-cont flex-wrap justify-center align-centerx">
            <div className="center margin-right-1">
              <div style={{backgroundImage: `url("${currentClub?.avatar_url}")`}} className="avatar-cont font-10 avatar-shadow ">
                {currentClub && !currentClub.avatar_url &&
                  <i className="fas fa-school"></i>
                }
              </div>
              <div style={{maxWidth: '15rem'}} className="avatar-name margin-top-1 bold font-1-25 margin-bottom-0 blue">{currentUser?.first_name} {currentUser?.last_name}</div>
              <div className="font-08 bold gray">PE TEACHER</div>
            </div>
            <div className="flex-1 margin-top-1">
              <ClubWeek week={currentClub?.week} />
            </div>
            
          </div>
      {/* <div>
        <pre>
          CurrentTeam: {JSON.stringify(currentTeam, null, 2)}
        </pre>
      </div> */}
      {/* <div className="margin-top-1 margin-bottom-3 center">
        <div className="circle-cont font-10">
          <img src={currentTeam?.avatar_url} alt="" />
        </div>
        
        <h1 className="no-margin-bottom">Welcome, {currentUser?.first_name}!</h1>

        <div className="margin-top-half bold font-1-5">{currentTeam?.name}</div>
        <div className="bold gray">TEAM ADMIN</div>
      </div>

      <div className="margin-bottom-3">
        <ClubWeek week={currentTeam?.week} />
      </div> */}

      
      
      {loading && 
        <div className="center margin-top-1">
          <div className="spinner"></div>
        </div>
        
      }
      

      
        <div>
        <div className="center no-margin margin-top-2 margin-bottom-3">
            <div className="text-linkx bold blue font-2">
              {currentTeam?.name}
            </div>
          </div>
          <div className='justify-center  center flex-cont flex-wrap'>
            {currentTeam?.athletes?.map(athlete => {
              return (
                <Link className="text-link" to={`/students/${athlete.slug}`}>
                <div className="hover-shadow  flex-cont column align-center justify-center inline-flex center padding-1">
                  <div className="circle-cont font-5 margin-bottom-half">
                    {athlete.avatar_url &&
                      <img src={athlete.avatar_url} alt="" />
                    }
                    {!athlete.avatar_url &&
                      <i className="fas fa-user lightgray"></i>
                    }
                  </div>
                  <div className="bold">
                    {athlete.full_name}
                  </div>

                  <div className="margin-top-half gold align-center flex-cont">
                    <img className="margin-right-half" style={{height: '1em'}} src={coinSVG} alt="" />
                    {athlete.coins}
                  </div>
                </div>
              </Link>
                
              )
            })}
          </div>
        </div>

      {currentCampaign && false &&
        <div>
          <div className="center no-margin margin-top-2">
            <div className="text-link font-1-5">
              {currentCampaign?.name}
            </div>
          </div>
          <div className="bold center no-margin margin-bottom-1" >
            {DateTime.fromISO(currentCampaign.start_date).toLocaleString(DateTime.DATE_FULL)} - {DateTime.fromISO(currentCampaign.end_date).toLocaleString(DateTime.DATE_FULL)}
          </div>




          <div className="flex-cont flex-wrap">
            <div className="flex-1 card margin-right-1 center margin-bottom-1">
              <div className="font-3 font-weight-300 font-narrow">{currentCampaign.total_distance}</div>
              <div className="bold gray margin-top-half">TOTAL MILES</div>
            </div>
            <div className="flex-1 card  margin-right-1 center margin-bottom-1">
              <div className="font-3 font-weight-300 font-narrow">{currentCampaign.avg_athlete_distance}</div>
              <div className="bold gray">AVG STUDENT MILES</div>
            </div>
            <div className="flex-1 card  margin-right-1 center margin-bottom-1">
              <div className="font-3 font-weight-300 font-narrow">{Duration.fromMillis(currentCampaign.avg_mile_time*1000.00).toFormat('mm:ss')}</div>
              <div className="bold gray">AVG MILE TIME</div>
            </div>
          </div>

          <div className="margin-top-1 center">
            <Link className="outline button" to={`/stats`}>
              View More Stats
              <i className="fas fa-chevron-right margin-left-1"></i>
            </Link>
          </div>

          {/* <div>
            CURRENT CLUB:
            <pre>
              {JSON.stringify(currentClub, null, 2)}
            </pre>
          </div> */}


        </div>
      }

      

      
      
      </div>
    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(TeamAdminHome);