

import React, { useEffect, useState } from 'react';
import { useApi } from '../../api';
import { getPledgeDriveSubs } from 'redux/admin/pledgeDriveSubActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';

import { createPledgeDriveSub, updatePledgeDriveSub, deletePledgeDriveSub } from 'redux/admin/pledgeDriveSubActions';
import { getPledgeDrives } from 'redux/admin/pledgeDriveActions';
import { getAthletes } from 'redux/admin/athleteActions';



const PledgeDriveSubschema = yup.object().shape({
  active: yup.boolean(),
  pledge_drive_id: yup.string().required("Must select a pledge drive."),
  athlete_id: yup.string().required("Must select an athlete."),
  // type: yup.string().oneOf(['parent', 'athlete']).required(),

});


const PledgeDriveSubForm = ({toggleModal, currentPledgeDriveSub}) => {

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
    validationSchema: PledgeDriveSubschema
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [pledgeDrives, pledgeDrivesLoading, pledgeDrivesLoaded, pledgeDrivesError, doFetchPledgeDrives, setPledgeDriveResults] = useApi();
  
  const [athletes, athletesLoading, athletesLoaded, athletesError, doFetchAthletes, setAthleteResults] = useApi();


  const onSubmit = async data => {
    let d = {
      pledge_drive_sub: {
        ...data
      }
    }

    if (currentPledgeDriveSub && currentPledgeDriveSub.id) {
      doFetch(updatePledgeDriveSub, {payload: d});
    }
    else {
      doFetch(createPledgeDriveSub, {payload: d});
    }
  }

  useEffect(() => {
    doFetchAthletes(getAthletes);
    doFetchPledgeDrives(getPledgeDrives);

  }, []);

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [selectedPledgeDrive, setSelectedPledgeDrive] = useState(null);

  const pledgeDriveSelectChanged = (e) => {
    const id = e.target.value;
    const t = pledgeDrives.find(t => t.id.toString() === id.toString());
    setSelectedPledgeDrive(t);
  }


  useEffect(() => {
    setValue('pledge_drive_id', currentPledgeDriveSub?.pledge_drive_id);
  }, [pledgeDrives]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{currentPledgeDriveSub ? 'Edit' : 'New'} Pledge Drive Subscription</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">


        <input 
          type="hidden" 
          name="id" 
          ref={register}
          defaultValue={currentPledgeDriveSub?.id}
        />
        


        <div className="flex-cont align-end">
          <label htmlFor="pledge_drive_id">
            <span>Pledge Drive</span>
            {pledgeDrivesLoading &&
              <div className="spinner"></div>
            }
            {pledgeDrives &&
              <select defaultValue={currentPledgeDriveSub?.pledge_drive_id} onChange={pledgeDriveSelectChanged} ref={register} name="pledge_drive_id">
                <option value="" disabled selected>Select your option</option>
                {pledgeDrives.map(pledgeDrive => <option value={pledgeDrive.id}>{pledgeDrive.name}</option>)}
              </select>
            }
            {errors.pledge_drive_id && <div className="field-error">{errors.pledge_drive_id.message}</div>}
          </label>
          <label htmlFor="pledge_drive_id">
            <span>Athlete</span>
            {athletesLoading &&
              <div className="spinner"></div>
            }
            {athletes &&
              <select defaultValue={currentPledgeDriveSub?.athlete_id} onChange={pledgeDriveSelectChanged} ref={register} name="athlete_id">
                <option value="" disabled selected>Select your option</option>
                {athletes.map(athlete => <option value={athlete.id}>{athlete.full_name}</option>)}
              </select>
            }
            {errors.athlete_id && <div className="field-error">{errors.athlete_id.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="active">
            
            <input defaultChecked={currentPledgeDriveSub?.active} type="checkbox" name="active" ref={register} id=""/>
            <span className="margin-left-1">Active</span>

            {errors.active && <div className="field-error">{errors.active.message}</div>}
          </label>
        </div>


      </div>

      {/* <div>{JSON.stringify(errors)}</div> */}

      <div className="modal-footer align-center flex-cont">
        <input type="submit" value="Save" className="button" />
      </div>

    </form>
  )

}




const AdminPledgeDriveSubs = ({currentUser}) => {

  const [pledgeDriveSubs, loading, loaded, error, doFetch, setResult] = useApi(); 
  const [deletedPledgeDriveSub, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeletedPledgeDriveSub] = useApi(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [editingPledgeDriveSub, setEditingPledgeDriveSub] = useState(null);

  const toggleModal = (reload = false) => {
    setModalOpen(o => !o);

    if (reload) {
      fetchPledgeDriveSubs();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingPledgeDriveSub(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!pledgeDriveSubs) {
      fetchPledgeDriveSubs();
    }
  }, []);

  const fetchPledgeDriveSubs = () => {
    doFetch(getPledgeDriveSubs);
  }

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingPledgeDriveSub, setDeletingPledgeDriveSub] = useState(null);

  const toggleDeleteModal = (reload = false) => {
    setDeleteModalOpen(o => !o);

    if (reload) {
      fetchPledgeDriveSubs();
    }
  }


  const editClicked = pledgeDriveSub => {
    setEditingPledgeDriveSub(pledgeDriveSub);
    toggleModal();
  }

  const deleteClicked = pledgeDriveSub => {
    setDeletingPledgeDriveSub(pledgeDriveSub);
    toggleDeleteModal();
  }

  const confirmDestroy = () => {
    if (deletingPledgeDriveSub) {
      doDeleteFetch(deletePledgeDriveSub, {query: {id: deletingPledgeDriveSub.id}});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
    }
  }, [deleteLoaded]);

  


  

  return (
    <>
      {modalOpen &&
        <Modal toggleModal={toggleModal}>
          <PledgeDriveSubForm currentPledgeDriveSub={editingPledgeDriveSub} toggleModal={toggleModal} />
        </Modal>
      }


      <div className="flex-cont">
        <div className="flex-1">
          <h1>Pledge Drive Subscriptions</h1>
        </div>
        <div>
          <div onClick={toggleModal} className="button">New Pledge Drive Subscription</div>
        </div>
      </div>
    

      {loading &&
        <div className="spinner"></div>
      }

      {pledgeDriveSubs &&
        <table className="basic">
          {pledgeDriveSubs.map(pledgeDriveSub => {
            return (
              <tr>
                <td className="bold fit nowrap">{pledgeDriveSub.athlete_name}</td>
                <td className="fit nowrap">{pledgeDriveSub.pledge_drive_name}</td>
                <td className="fit nowrap">{pledgeDriveSub.code}</td>
                <td>{pledgeDriveSub.active ? "ACTIVE" : "NOT ACTIVE"}</td>
                <td className="fit nowrap">
                  <div onClick={() => editClicked(pledgeDriveSub)} className="ghost button">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <div onClick={() => deleteClicked(pledgeDriveSub)} className="ghost button">
                    <i className="fas fa-trash"></i>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {deleteModalOpen &&
        <Modal toggleModal={toggleDeleteModal}>
         <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Delete PledgeDriveSub</h1>
              <div onClick={toggleDeleteModal} className="button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

          <div className="padding-1">

            

            Are you sure you want to delete {deletingPledgeDriveSub?.name}?
          </div>

          <div className='modal-footer flex-cont align-items-center'>
            <input onClick={confirmDestroy} type="submit" value="Delete" className="margin-right-1 button" />
            <div onClick={toggleDeleteModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      
      
      }

      {!loading && !pledgeDriveSubs &&
        <em>No pledge drives.</em>
      }


      


    </>
  )
}


export default AdminPledgeDriveSubs;