import { andyFetch } from '../../api';

export const getCoinItems = (params) => {
  return async (dispatch, getState) => {

    const url = "club/coin_items";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return json.data;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      console.log("getCoinItems error", e);
    }
  }
};



export const createCoinItem = (params) => {

  return async (dispatch, getState) => {

    const url = "club/coin_items";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('createCoinItem error.');
      }
    }
    catch (e) {
      console.log("createCoinItem error", e);
    }
  }
};


export const updateCoinItem = (params) => {

  return async (dispatch, getState) => {

    const url = `club/coin_items/${params.payload.coin_item.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('updateCoinItem error.');
      }
    }
    catch (e) {
      console.log("updateCoinItem error", e);
    }
  }
};

export const getCoinItem = (params) => {
  return async (dispatch, getState) => {
    const url = `club/coin_items/${params.query.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('getCoinItem error.');
      }
    }
    catch (e) {
      console.log("getCoinItem error", e);
    }
  }
};

export const deleteCoinItem = (params) => {
  
  return async (dispatch, getState) => {

    const url = `club/coin_items/${params.query.id}`;

    try {

      const response = await andyFetch(url, params, "DELETE");

      if (response.ok) {
        const json = await response.json();
        return json.data;

      } else {
        throw new Error('deleteCoinItem error.');
      }
    }
    catch (e) {
      console.log("deleteCoinItem error", e);
    }
  }
};

