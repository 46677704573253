import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api';
import { getMerchProduct } from 'redux/club/merchProductActions';
import { ReactComponent as Avatar } from 'images/avatar/avatar_full.svg'; 
import ClubMerchProductNav from './ClubMerchProductNav';
import Modal from '../../../components/Modal';
import MerchProductForm from './MerchProductForm';

const ClubMerchProductLayout = ({children}) => {

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchMerchProduct()
    }
  }, [id]);

  const [merchProduct, merchProductLoading, merchProductLoaded, merchProductError, doFetchMerchProduct, setMerchProductResults] = useApi();

  const fetchMerchProduct = () => {
    doFetchMerchProduct(getMerchProduct, {query: {id: id}})
  }

  const [editModalOpened, setEditModalOpened] = useState(false);
  

  const editMerchProductClicked = () => {
    toggleEditModal();
  }

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchMerchProduct();
    }
  } 

  return (
    <>
      {merchProduct && editModalOpened &&
        <Modal toggleModal={toggleEditModal}>
          <MerchProductForm toggleModal={toggleEditModal} currentMerchProduct={merchProduct} />
        </Modal>
        
      }
      <div className="padding-top-1">
        <div className="flex-cont align-center">
          {merchProduct?.featured_image_url &&
            <div className="team-logo-cont small margin-right-2">
              <img src={merchProduct?.featured_image_url} alt="" />
            </div>
          }
          {merchProductLoading && <div className="loading"></div> }
          <h1>
            {merchProduct?.name}
          </h1>

          <div className="flex-1"></div>

          <div>
            <i onClick={editMerchProductClicked} className="ghost button fas fa-pencil-alt"></i>
          </div>
        </div>
        

        <ClubMerchProductNav id={id} />

        {children}
        {/* {React.cloneElement(children, {merchProduct: merchProduct})} */}

        {/* <div className="code">
          <pre>
            {JSON.stringify(merchProduct, null, 2)}
          </pre>
        </div> */}


      </div>
    </>
  )

}



export default ClubMerchProductLayout;