
import React, { useEffect, useState } from 'react';
import Coin from './Coin';
import CountUp from '../../../components/CountUp';
import coinSVG from 'images/coin.svg';


const LoginNoti = ({noti, hideNoti, hideAllNotis}) => {


  return (
    <div className="noti-content">
      <div className="padding-1">
        <div className="noti-inner-content">
          <div className="flex-cont align-center justify-center">
            <div className="flex-cont column align-center">
              {/* <div className="starburst-cont">
                <div className="starburst"></div>
                <div className="feather"></div>
              </div> */}

              <Coin />

              <div className="center noti-body">
                {/* <pre>
                  {JSON.stringify(noti, null, 2)}
                </pre> */}

                
                <div className="font-3 bold">
                  {/* Daily Login */}
                  {noti.title}
                </div>

                <div className="bold margin-bottom-3 margin-top-1">
                  {/* Earn 10 Hero Coins when you login each day! */}
                  {noti.description}
                </div>

                <div>
                  <div className="margin-bottom-1 inline-block bold font-1 margin-right-1 gold" style={{
                      borderRadius: '99999px',
                      background: '#f6b904',
                      color: 'white',
                      padding: '.25em 1em'
                    }}>
                      +{noti.coins_after - noti.coins_before}
                  </div>
                </div>

                
                  
                
                <div style={{
                  // borderRadius: '99999px',
                  // background: 'gold',
                  // color: 'white'
                }} className="inline-flex bold font-2 gold flex-contx align-center justify-center margin-right-4x">
                  <div className="flex-1 flex-cont" style={{justifyContent: 'flex-end'}}>
                    <img src={coinSVG} style={{justifySelf: 'flex-end', height: '.75em'}} className="margin-right-half" />
                  </div>

                  

                  <div className="flex-1 flex-cont" style={{justifyContent: 'flex-start'}}>
                    <CountUp decimalPlaces={0} delay={500} startNumber={noti.coins_before} number={noti.coins_after} />
                  </div>
                  
                </div>

                <div className="bottom-buttons-cont">
                  <div className="noti-button">
                    <div onClick={hideNoti} className="font-1-5 wide button">Okay!</div>
                  </div>

                  <div className="dismiss-all">
                    <div onClick={hideAllNotis} className="margint-top- 1font-1-25 wide ghost white button">Dismiss all</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginNoti;