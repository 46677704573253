

import React, { useEffect, useState, useMemo } from 'react';
import { useApi } from '../../../api';
import { getUsers } from 'redux/club/userActions';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom"

import Modal from 'components/Modal';

import { getClubEvents } from 'redux/club/clubEventActions';
import ClubCalendarNav from './ClubCalendarNav';

import { Calendar, luxonLocalizer, Views } from 'react-big-calendar'
import { DateTime, Settings } from 'luxon'
import 'react-big-calendar/lib/sass/styles.scss'

import events from './events';

import ClubEventForm from './ClubEventForm';


let allViews = ["month", "week"]
// Object.keys(Views).map(k => Views[k])


const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

const defaultTZ = DateTime.local().zoneName
const defaultDateStr = DateTime.now().toISODate();

function getDate(str, DateTimeObj) {
  return DateTimeObj.fromISO(str).toJSDate()
}



const ClubCalendarHome = ({currentTeam, currentClub}) => {

  let { tab } = useParams();


  const [clubEvents, clubEventsLoading, clubEventsLoaded, clubEventsError, doClubEventsFetch, setClubEventsResult] = useApi(); 
  

  const [modalOpen, setModalOpen] = useState(false);
  const [editingClubEvent, setEditingEvent] = useState(null);

  const toggleModal = (reload = false) => {

    setModalOpen(o => !o);

    if (reload) {
      fetchEvents();
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      setEditingEvent(null);
    }
  }, [modalOpen])

  useEffect(() => {
    if (!clubEvents) {
      fetchEvents();
    }
  }, []);

  const fetchEvents = () => {
    doClubEventsFetch(getClubEvents, {query: {team_id: currentTeam?.id}});
  }

  
  useEffect(() => {
    // alert('loaded')
    if (clubEventsLoaded) {
      
    }
  }, [clubEventsLoaded])

  
  


  const editClicked = clubEvent => {
    setEditingEvent(clubEvent);
    toggleModal();
  }

  


  const [users, loading, loaded, error, doFetch, setResult] = useApi(); 

  useEffect(() => {
    fetchClubEvents();
  }, [tab]);

  const fetchClubEvents = () => {
    doFetch(getClubEvents);
  }


  const changeTab = (tab) => {
    // setSearchParams({ q: tab });
  }

  const [timezone, setTimezone] = useState(defaultTZ)

  const {
    localizer,
    defaultDate,
    scrollToTime,
    myEvents,
    getNow,
  } = useMemo(() => {
    Settings.defaultZone = timezone
    return {
      localizer: luxonLocalizer(DateTime),
      defaultDate: getDate(defaultDateStr, DateTime),
      scrollToTime: DateTime.local().toJSDate(),
      getNow: () => DateTime.local().toJSDate(),
      myEvents: [...events],
    }
  }, [timezone])

  useEffect(() => {
    return () => {
      Settings.defaultZone = defaultTZ // reset to browser TZ on unmount
    }
  }, []);


  const parsedEvents = () => {
    if (!clubEvents) return []

    return clubEvents.map(clubEvent => {

      let dt = DateTime.fromISO(clubEvent.start_date);
      return {
        ...clubEvent,
        id: clubEvent.id,
        start: DateTime.fromISO(clubEvent.start_date).toJSDate(),
        end: DateTime.fromISO(clubEvent.end_date).toJSDate(),
        title: clubEvent?.title || "Event",
        // clubEvent?.title?.length ? clubEvent.title : "Event",
        timezone: clubEvent.timezone,
        
      }
    })
  }
  
  const eventSelected = e => {
    editClicked(e);
  }

  return (
    <>
      <div style={{marginBottom: '200px'}}>

      {modalOpen &&
        <Modal focusTrap={false} toggleModal={toggleModal}>
          <ClubEventForm currentClubEvent={editingClubEvent} toggleModal={toggleModal} />
        </Modal>
      }


        {/* <div className="margin-bottom-2">
          <Link to={`/calendar/new`} className="button">New Event</Link>
        </div> */}

        <div className="button margin-bottom-2" onClick={toggleModal}>
          New Event
        </div>

        

        {/* <div>
            <pre>
              {JSON.stringify(parsedEvents(), null, 2)}
            </pre>
          {clubEvents?.map(clubEvent => 
            <div className="flex-cont">
              <div className="button" onClick={() => editClicked(clubEvent)}>
                Edit
              </div>
            </div>  
          )}
        </div> */}


        {loading &&
          <div className="spinner"></div>
        }

        <div style={{height: '100vh', minHeight: '1400px'}}>
        <Calendar
          events={parsedEvents()}
          views={allViews}
          // components={{
          //   timeSlotWrapper: ColoredDateCellWrapper,
          // }}
          popup
          selectable
          onSelectEvent={eventSelected}
          defaultView={Views.WEEK}
          defaultDate={defaultDate}
          scrollToTime={scrollToTime}
          localizer={localizer}
          getNow={getNow}
        />
        </div>



      


        {!loading && !users &&
          <em>No results.</em>
        }
      </div>
    </>
  )
}


function mapState(state) {
  return { 
    currentClub: state.clubState.currentClub,
    currentUser: state.sessionState.currentUser,
    currentTeam: state.clubState.currentTeam,
  };
} 

export default connect(mapState)(ClubCalendarHome);