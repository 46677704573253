import React from 'react';

// import { connect } from 'react-redux';


const Pagination = ({handlePageChangeFn, pagy, currentPage}) => {

  const handlePageChange = (page) => {
    handlePageChangeFn(page);
  };
  

  return(
    <>
      {pagy && pagy.totalPages !== 1 &&
          <div className="margin-bottom-1 flex-cont align-center">
            {currentPage > 1 ? (
                <div onClick={() =>handlePageChange(currentPage-1)} className="button ghost">
                  <i className="margin-0 fas fa-chevron-left"></i>
                </div>
              ) : (
                <div className="faded ghost button">
                  <i className="margin-0 fas fa-chevron-left"></i>
                </div>
              )
            }
            <div  className="font-1 bold margin-right-1 margin-left-1">
              {currentPage ? currentPage : "-"}
              
              <span className="gray">/{pagy.totalPages ? pagy.totalPages : "-"}</span>
            </div>
            {pagy.totalPages !== 1 && currentPage !== pagy.totalPages ? (
                <div onClick={() => handlePageChange(currentPage+1)} className="button ghost">
                  <i className="margin-0 fas fa-chevron-right"></i>
                </div>
              ) : (
                <div className="faded ghost button">
                  <i className="margin-0 fas fa-chevron-right"></i>
                </div>
              )
            }
          </div>
        }
    </>
  );
}

// const mapState = state => ({
//   pagy: state.periodState.pagy
// });

export default Pagination;