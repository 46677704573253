


import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { Link, NavLink, Outlet, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { useApi } from '../../api';
import { getMyClub } from 'redux/club/clubActions';
import { connect } from 'react-redux';
import { reupdateUser } from "redux/session/sessionActions";
import ClubBottomNav from 'screens/club/ClubBottomNav';
import { logoutUser } from 'redux/session/sessionActions';
import store from 'redux/store';
import { createPledger } from 'redux/user/pledgeActions';
import PledgeSidebar from '../club/pledgeDrives/PledgeSidebar';
import SubmitButton from '../../components/SubmitButton';
import { getPledgeByCode } from '../../redux/user/pledgeActions';




const FindPledge = () => {
  const navigate = useNavigate("/");

  const { watch, register, errors, handleSubmit, getValues, setValue } = useForm({
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    doFetch(getPledgeByCode, {query: {pledge_code: data.pledge_code}});
  }

  useEffect(() => {
    if (loaded && result?.code) {
      // navigate(`/pledge/edit?pledge_code=${result.code}`);
      navigate(`/pledge/progress/${result.code}`)
    }
    else {
      
    }
  }, [loaded, result])


  return (
    <>
      <div className="parent" style={{
        backgroundColor: 'white',
        paddingBottom: '500px',
        paddingTop: '50px',
        position: 'relative',
      }}>

        <div className="narrow relative">
          <div className="flex-cont flex-wrap-reverse" style={{flexDirection: 'row'}}>
            <div style={{minWidth: '400px'}} className="margin-top-1 col-1 flex-2 padding-2 margin-right-3">
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="bold font-2 margin-bottom-1">
                  Please enter your pledge code
                </div>

                <div className="margin-bottom-2">
                  <Link to="/forgot-pledge-code" className="text-link margin-bottom-2">
                    Forgot pledge code?
                  </Link>
                </div>

                <div className="flex-cont">
                  <label htmlFor="pledger_name">
                    <span>Pledge Code</span>
                    <input ref={register} placeholder="" type="text" name="pledge_code" id=""/>
                    {errors.pledge_code && <div className="field-error">{errors.pledge_code.message}</div>}
                  </label>
                </div>

                <div className="margin-top-2 font-1-5 justify-center align-center flex-cont">

                  <SubmitButton label={
                    <React.Fragment>
                      <span>Continue</span>
                      <i className="font-1 fas fa-arrow-right margin-left-half"></i>
                    </React.Fragment>
                  } disabled={(errors && Object.keys(errors).length !== 0)} loading={loading} />
                </div>
              </form>
            </div>

          </div>

        </div>

      </div>
    </>
  )
}

export default FindPledge;